import { clanstarsSeasonVersion } from '~/helpers/common';

const sawClanStarsRe = new RegExp('sawClanStars');
const sawClanStarsVersionRe = new RegExp(clanstarsSeasonVersion);

export const removeOldLocalStorageObjects = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const storageKey = localStorage.key(i);
    if (storageKey && sawClanStarsRe.test(storageKey) && !sawClanStarsVersionRe.test(storageKey)) {
      localStorage.removeItem(storageKey);
    }
  }
  localStorage.removeItem('sawUpdateClanStars');
};
