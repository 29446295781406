import classnames from 'classnames';
import React, { PureComponent } from 'react';
import {
  ClanTag,
  Currency,
  DivTooltip,
  IconTable,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
  TooltipBody,
} from '@wg/wows-react-uikit';

import { isoToFormattedLocalDateTime } from '~/helpers/datetime';

import styles from './InGroupClanRatingTable.scss';
import { header } from './header';

export type IInGroupClanData = {
  id?: number;
  name: string;
  tag: string;
  color?: string;
  result: number;
  lastWarBattleAt?: string;
  memberCount: number;
  place?: number;
  background?: boolean;
};

export type InGroupClanRatingTable_Props = {
  data: Array<IInGroupClanData>;
  selfId?: number;
  background?: boolean;
};

class InGroupClanRatingTable extends PureComponent<InGroupClanRatingTable_Props> {
  static defaultProps: {
    selfId: number;
    background: boolean;
  } = {
    selfId: 0,
    background: true,
  };

  renderTableHead() {
    return header.map((cell, key) => {
      return (
        <TableHeadCell key={`head-cell-${key}`} modify={cell.modify}>
          {cell.tooltipBody ? (
            <DivTooltip tooltipBody={<TooltipBody>{cell.tooltipBody}</TooltipBody>}>{cell.text}</DivTooltip>
          ) : cell.type === 'role' ? (
            <IconTable glyph="roles" />
          ) : (
            cell.text
          )}
        </TableHeadCell>
      );
    });
  }

  renderTableBody = () => {
    const sortedClans = this.props.data.sort((a, b) => {
      const selfPriority = (b.id === this.props.selfId ? 0.5 : 0) - (a.id === this.props.selfId ? 0.5 : 0);
      return selfPriority + b.result - a.result;
    });
    let place = 1;
    const clansWithPlaces = sortedClans.map((clan, index) => {
      if (index > 0 && clan.result !== sortedClans[index - 1].result) {
        place++;
      }
      return { ...clan, place };
    });
    return clansWithPlaces.map((clan, index) => {
      return (
        <TableRow isHovering key={`war-table-row-${index}`}>
          <TableBodyCell modify="small" isHovering={false}>
            {clan.place}
          </TableBodyCell>
          <TableBodyCell modify="basis" isHovering={false}>
            <ClanTag clanTag={clan.tag} clanColor={clan.color} />
            <span className={styles.clanName}>{clan.name}</span>
          </TableBodyCell>
          <TableBodyCell modify="center" isHovering={false}>
            <Currency isFlat type="cwars-star" amount={clan.result} />
          </TableBodyCell>
          <TableBodyCell modify="right" isHovering={false}>
            {isoToFormattedLocalDateTime(clan.lastWarBattleAt)}
          </TableBodyCell>
          <TableBodyCell modify="right" isHovering={false}>
            {clan.memberCount}
          </TableBodyCell>
        </TableRow>
      );
    });
  };

  render() {
    return (
      <Table>
        <TableHead className={classnames({ [styles.thead]: this.props.background })}>
          {this.renderTableHead()}
        </TableHead>
        <TableBody className={classnames({ [styles.tbody]: this.props.background })}>
          {this.renderTableBody()}
        </TableBody>
      </Table>
    );
  }
}

export default InGroupClanRatingTable;
