import classNames from 'classnames';
import React from 'react';
import { Interpolate, translate } from 'react-i18next';
import { DivTooltip, Message, ResourceImage, Tooltip, TooltipBody, TooltipHeader } from '@wg/wows-react-uikit';

import { isoToFormattedLocalDateTime } from '~/helpers/datetime';
import { t, x } from '~/helpers/localization';

import styles from './ProgressReward.scss';
import { REWARDS_GOAL_TO_DATA } from '../../../constants';

import type { IClanStarsReward, IClanStarsRewardInfo } from '~/Reducers/ReducerClanStars';

type IRewardProps = {
  accountRewards: IClanStarsReward[];
  className?: string;
  clanstarsCount: number;
  isObtained: boolean;
  items: IClanStarsRewardInfo[];
  rewardGoal: number;
};

const Reward: React.FC<IRewardProps> = ({
  accountRewards,
  className,
  rewardGoal,
  clanstarsCount,
  items,
  isObtained,
}) => {
  const getRewardClaimedAt = () => {
    for (const reward of accountRewards) {
      if (reward.clanstarsCount === rewardGoal && reward.claimedAt) {
        return isoToFormattedLocalDateTime(reward.claimedAt);
      }
    }
    return null;
  };

  const rewardData = REWARDS_GOAL_TO_DATA[rewardGoal];
  if (!rewardData) {
    return null;
  }

  const amount = items[0].amount;
  const { clan, description, resource, title } = rewardData;

  return (
    <DivTooltip
      className={className}
      tooltipBody={
        <Tooltip>
          <div className={styles.tooltipWrapper}>
            <TooltipHeader isBold={true}>
              <div className={styles.header}>
                <span>
                  <Interpolate t={t} className={styles.paragraphText} i18nKey={x('Награда за получение %(star)s')} />
                </span>
                <span className={styles.count}>{rewardGoal}</span>
                <div className={styles.star} />
              </div>
            </TooltipHeader>
            <TooltipBody>
              <div className={styles.tooltipBodyWrapper}>
                <ResourceImage {...resource} amount={amount} />
                <div className={styles.tooltipDescriptionWrapper}>
                  <div className={styles.tooltipHeader}>{title}</div>
                  <div className={styles.tooltipType}>{description}</div>
                </div>
              </div>
            </TooltipBody>
            {!!clan && (
              <TooltipBody>
                <Message type="error" style={'inline'}>
                  {t('Начисляется в казну клана')}
                </Message>
              </TooltipBody>
            )}
            {!isObtained ? (
              <>
                <TooltipBody>
                  <div className={styles.tooltipFooter}>
                    {t('Нужно еще:')}
                    <div className={styles.countStars}>
                      <span className={styles.count}>{rewardGoal - clanstarsCount}</span>
                      <div className={styles.star} />
                    </div>
                  </div>
                </TooltipBody>
                <TooltipBody>{t('Выполняйте задачи в составе отряда и получайте звезды.')}</TooltipBody>
              </>
            ) : (
              <TooltipBody>
                <div className={styles.tooltipFooterGot}>
                  {t('Получено')}
                  <p style={{ color: '#FFCC66' }}>{getRewardClaimedAt()}</p>
                </div>
              </TooltipBody>
            )}
          </div>
        </Tooltip>
      }
    >
      <div className={styles.reward}>
        <ResourceImage className={classNames({ [styles.isObtained]: isObtained })} {...resource} amount={amount} />
        {isObtained && <span className={styles.isObtainedTick} />}
      </div>
    </DivTooltip>
  );
};

// @TODO: update i18n so untyped `translate` HOC would not be needed
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export default React.memo(translate()(Reward) as React.FC<IRewardProps>);
