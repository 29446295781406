import { t } from '~/helpers/localization';

export const header = [
  {
    modify: 'small',
    type: 'role',
    tooltipBody: t('Должность'),
  },
  {
    modify: 'basis',
    type: 'nickname',
    text: t('Игрок'),
  },
  {
    modify: 'center',
    type: 'result',
    text: t('Результат'),
    tooltipBody: t('Количество взятых планок'),
  },
  {
    modify: 'right',
    type: 'claimedGrade',
    text: t('Лучшая планка'),
    tooltipBody: t('Наивысшая взятая планка'),
  },
  {
    modify: 'right',
    type: 'attempts',
    text: t('Попытки'),
    tooltipBody: t('Количество использованных попыток взятия планки'),
  },
  {
    modify: 'right',
    type: 'finishedAt',
    text: t('Дата боя'),
    tooltipBody: t('Дата последнего боя с попыткой взятия планки'),
  },
];
