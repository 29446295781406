import axios from 'axios';
import difference from 'lodash/difference';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEqualWith from 'lodash/isEqualWith';

import preloaded from '~/preloaded';
import { t } from '~/helpers/localization';
import { sendSendApplicationErrorNotification, sendSendApplicationNotification } from '~/web2ClientAPI/notifications';

import type { InferActionsType } from '~/Reducers';
import type { AppThunk, AppAsyncThunk } from '~/store';
import { IClanCommunityUrls } from '~/types/declaration';

export const GET_ACCOUNT_RECOMMENDATIONS_SETTINGS = 'GET_ACCOUNT_RECOMMENDATIONS_SETTINGS';
export const GET_ACCOUNT_RECOMMENDATIONS_SETTINGS_RESPONSE = 'GET_ACCOUNT_RECOMMENDATIONS_SETTINGS_RESPONSE';
export const GET_CLAN_RECOMMENDATIONS_SETTINGS = 'GET_CLAN_RECOMMENDATIONS_SETTINGS';
export const GET_CLAN_RECOMMENDATIONS_SETTINGS_RESPONSE = 'GET_CLAN_RECOMMENDATIONS_SETTINGS_RESPONSE';
export const GET_LEFT_APPLICATION_COUNT = 'GET_LEFT_APPLICATION_COUNT';
export const GET_LEFT_APPLICATION_COUNT_RESPONSE = 'GET_LEFT_APPLICATION_COUNT_RESPONSE';
export const GET_RECOMMENDATIONS = 'GET_RECOMMENDATIONS';
export const GET_RECOMMENDATIONS_RESPONSE = 'GET_RECOMMENDATIONS_RESPONSE';
export const SEND_APPLICATION = 'SEND_APPLICATION';
export const SEND_APPLICATION_RESPONSE = 'SEND_APPLICATION_RESPONSE';
export const SET_ACCOUNT_RECOMMENDATIONS_SETTINGS = 'SET_ACCOUNT_RECOMMENDATIONS_SETTINGS';
export const SET_ACCOUNT_RECOMMENDATIONS_SETTINGS_RESPONSE = 'SET_ACCOUNT_RECOMMENDATIONS_SETTINGS_RESPONSE';
export const SET_CLAN_RECOMMENDATIONS_SETTINGS = 'SET_CLAN_RECOMMENDATIONS_SETTINGS';
export const SET_CLAN_RECOMMENDATIONS_SETTINGS_RESPONSE = 'SET_CLAN_RECOMMENDATIONS_SETTINGS_RESPONSE';

export enum PURPOSES {
  NO_OBLIGATIONS = 'no_obligations',
  SQUAD_BATTLES = 'squad_battles',
  CLAN_BATTLES = 'clan_battles',
}

export enum CLANREC_APPLICABLE_PAGES {
  CATEGORIES = 'categories',
}

export const CLANREC_PAGES = {
  ...PURPOSES,
  ...CLANREC_APPLICABLE_PAGES,
} as const;

export const PURPOSE_TITLES = {
  [PURPOSES.NO_OBLIGATIONS]: t('Никаких обязательств'),
  [PURPOSES.SQUAD_BATTLES]: t('Игра в отряде'),
  [PURPOSES.CLAN_BATTLES]: t('Клановые бои'),
};

const PurposesMap = {
  [PURPOSES.NO_OBLIGATIONS]: 0,
  [PURPOSES.SQUAD_BATTLES]: 1,
  [PURPOSES.CLAN_BATTLES]: 2,
};
const PurposesMapReversed = {
  0: PURPOSES.NO_OBLIGATIONS,
  1: PURPOSES.SQUAD_BATTLES,
  2: PURPOSES.CLAN_BATTLES,
};

export type AccountRecommendationsSettings = {
  languages: string[];
  useVoiceChat: boolean;
  isVoiceRequired: boolean;
};

export type ClanRecommendationsSettings = {
  languages: Array<string>;
  purpose: PURPOSES;
  isRecommendable: boolean;
  isVoiceChatRequired: boolean;
};

const clanRecommendationsSettingsComparator = (a: any, b: any): boolean =>
  a === b || (isArray(a) && isArray(b) && !difference(a, b).length);

export const isClanRecommendationsSettingsEqual = (
  a: ClanRecommendationsSettings,
  b: ClanRecommendationsSettings,
): boolean => isEqualWith(a, b, clanRecommendationsSettingsComparator);

export type ClanRecommendation = {
  clanbaseCompletion: number;
  communityUrls: IClanCommunityUrls;
  color: string;
  description: string;
  doesSentAnInvite: boolean;
  id: number;
  isVoiceRequired: boolean;
  languages: Array<string>;
  maxMemberCount: number;
  memberCount: number;
  name: string;
  similarity: number;
  status: string;
  tag: string;
};

export type ActionsType = InferActionsType<typeof actionsRecommendation>;

const actionsRecommendation = {
  getRecommendations: () =>
    ({
      type: GET_RECOMMENDATIONS,
    }) as const,

  getRecommendationsResponse: (items: Array<ClanRecommendation>) =>
    ({
      type: GET_RECOMMENDATIONS_RESPONSE,
      payload: items,
    }) as const,

  getLeftApplicationCount: () =>
    ({
      type: GET_LEFT_APPLICATION_COUNT,
    }) as const,

  getAccountRecommendationsSettings: () =>
    ({
      type: GET_ACCOUNT_RECOMMENDATIONS_SETTINGS,
    }) as const,

  getClanRecommendationsSettings: () =>
    ({
      type: GET_CLAN_RECOMMENDATIONS_SETTINGS,
    }) as const,

  getLeftApplicationCountResponse: (leftApplicationCount: number) =>
    ({
      type: GET_LEFT_APPLICATION_COUNT_RESPONSE,
      payload: leftApplicationCount,
    }) as const,

  sendApplication: () =>
    ({
      type: SEND_APPLICATION,
    }) as const,

  sendApplicationResponse: (status: boolean, clan: ClanRecommendation) =>
    ({
      type: SEND_APPLICATION_RESPONSE,
      payload: { status, clan },
    }) as const,

  setClanRecommendationsSettings: () =>
    ({
      type: SET_CLAN_RECOMMENDATIONS_SETTINGS,
    }) as const,

  setClanRecommendationsSettingsResponse: (settings: ClanRecommendationsSettings) =>
    ({
      type: SET_CLAN_RECOMMENDATIONS_SETTINGS_RESPONSE,
      payload: settings,
    }) as const,

  getClanRecommendationsSettingsResponse: (settings: ClanRecommendationsSettings) =>
    ({
      type: GET_CLAN_RECOMMENDATIONS_SETTINGS_RESPONSE,
      payload: settings,
    }) as const,

  getAccountRecommendationsSettingsResponse: (settings: AccountRecommendationsSettings) =>
    ({
      type: GET_ACCOUNT_RECOMMENDATIONS_SETTINGS_RESPONSE,
      payload: settings,
    }) as const,

  setAccountRecommendationsSettings: () =>
    ({
      type: SET_ACCOUNT_RECOMMENDATIONS_SETTINGS,
    }) as const,

  setAccountRecommendationsSettingsResponse: (settings: AccountRecommendationsSettings) =>
    ({
      type: SET_ACCOUNT_RECOMMENDATIONS_SETTINGS_RESPONSE,
      payload: settings,
    }) as const,
};

export const getAccountRecommendationsSettingsThunk = (): AppThunk => (dispatch) => {
  dispatch(actionsRecommendation.getAccountRecommendationsSettings());

  type IApiResponse = {
    languages: string[];
    useVoiceChat: number;
  };

  void axios.get<IApiResponse>(preloaded.urls.recommendationsAccountSettings).then((response) => {
    if (response.status === 200 && response.data) {
      const settings = {
        languages: response.data.languages,
        useVoiceChat: response.data.useVoiceChat === 0,
      };
      dispatch(actionsRecommendation.getAccountRecommendationsSettingsResponse(settings));
    }
  });
};

export const setAccountRecommendationsSettingsThunk =
  (recommendationsSettings: AccountRecommendationsSettings): AppThunk =>
  (dispatch) => {
    const settings = {
      languages: recommendationsSettings.languages,
      useVoiceChat: recommendationsSettings.useVoiceChat ? 0 : 2,
    };

    dispatch(actionsRecommendation.setAccountRecommendationsSettings());

    void axios.put('/api/clanrec2/account_settings/', settings).then((response) => {
      if (response.status === 200 && response.data) {
        const settings = {
          languages: response.data.languages,
          useVoiceChat: response.data.useVoiceChat === 0,
        };
        dispatch(actionsRecommendation.setAccountRecommendationsSettingsResponse(settings));
      }
    });
  };

export const getClanRecommendationsSettingsThunk = (): AppAsyncThunk => (dispatch) => {
  dispatch(actionsRecommendation.getClanRecommendationsSettings());

  return axios.get('/api/clanrec2/clan_settings/').then((response) => {
    if (response.status === 200 && response.data) {
      const settings = {
        languages: response.data.languages,
        purpose: get(PurposesMapReversed, response.data.purpose, PURPOSES.NO_OBLIGATIONS),
        isRecommendable: response.data.isRecommendable,
        isVoiceChatRequired: response.data.isVoiceChatRequired,
      };
      dispatch(actionsRecommendation.getClanRecommendationsSettingsResponse(settings));
    }
  });
};

export const setClanRecommendationsSettingsThunk =
  (recommendationsSettings: ClanRecommendationsSettings): AppThunk =>
  (dispatch) => {
    const settings = {
      languages: recommendationsSettings.languages,
      purpose: get(PurposesMap, recommendationsSettings.purpose, 0),
      isRecommendable: recommendationsSettings.isRecommendable,
      isVoiceChatRequired: recommendationsSettings.isVoiceChatRequired,
    };

    dispatch(actionsRecommendation.setClanRecommendationsSettings());

    void axios.put('/api/clanrec2/clan_settings/', settings).then((response) => {
      if (response.status === 200 && response.data) {
        const settings = {
          languages: response.data.languages,
          purpose: get(PurposesMapReversed, response.data.purpose, PURPOSES.NO_OBLIGATIONS),
          isRecommendable: response.data.isRecommendable,
          isVoiceChatRequired: response.data.isVoiceChatRequired,
        };
        dispatch(actionsRecommendation.setClanRecommendationsSettingsResponse(settings));
      }
    });
  };

export const getRecommendationsThunk =
  (purpose: PURPOSES): AppThunk =>
  (dispatch) => {
    const purposeValue = PurposesMap[purpose];

    dispatch(actionsRecommendation.getRecommendations());

    void axios.get(`/api/clanrec2/?purpose=${purposeValue}`).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(actionsRecommendation.getRecommendationsResponse(response.data.items));
      }
    });
  };

export const getLeftApplicationCountThunk = (): AppThunk => (dispatch) => {
  dispatch(actionsRecommendation.getLeftApplicationCount());

  void axios.get('/api/clanrec2/left_application_count/').then((response) => {
    if (response.status === 200 && response.data) {
      dispatch(actionsRecommendation.getLeftApplicationCountResponse(response.data.leftApplicationCount));
    }
  });
};

export const sendApplicationThunk =
  (clan: ClanRecommendation, info: string): AppThunk =>
  (dispatch) => {
    dispatch(actionsRecommendation.sendApplication());

    void axios
      .post('/api/clanrec2/application/', { clanId: clan.id, comment: info })
      .then((response) => {
        if (response.status >= 200 && response.status < 300 && response.data) {
          sendSendApplicationNotification({
            name: clan.name,
            tag: clan.tag,
            color: clan.color,
          });

          dispatch(actionsRecommendation.sendApplicationResponse(true, clan));
        }
      })
      .catch((error) => {
        if (error.response.data) {
          const reason = get(error.response.data, 'title', '').toLowerCase();
          sendSendApplicationErrorNotification(reason, clan);
        }
        dispatch(actionsRecommendation.sendApplicationResponse(false, clan));
      });
  };
