import isEmpty from 'lodash/isEmpty';

import settings from '~/settings';
import { BATTLE_TYPES, SEASON_TYPES, TYPE_GLOBAL_SEARCH } from '~/constants';

import type { WowsLadderRatingTeam } from '~/Actions/ActionClanProfile';
import type { ILadderSeason } from '~/store/slices/settingsSlice';

export const lastSeason = settings.ladder.seasons[settings.ladder.lastSeasonId] || {};

export const getMajorLeagueNumber = (season: ILadderSeason) => {
  return season && season.leagues && season.leagues[0] ? 0 : 1;
};

export const isLeaguePresentInSeason = (season: ILadderSeason, leagueNumber: number) => {
  return !!(season && season.leagues && season.leagues[leagueNumber]);
};

export const getSeasonInfo = (seasonNumber: number) => {
  return settings.ladder.seasons[seasonNumber] || {};
};

export const getSeasonBattleType = (battleType: BATTLE_TYPES) => {
  if (battleType === BATTLE_TYPES.REGULAR_CVC) {
    return SEASON_TYPES.REGULAR;
  }
  if (battleType === BATTLE_TYPES.BRAWL_CVC) {
    return SEASON_TYPES.BRAWL;
  }

  return null;
};

export const getSeasonLeagues = (seasonNumber: number) => {
  return settings.ladder?.seasons[seasonNumber] ? settings.ladder.seasons[seasonNumber].leagues : {};
};

export const isQualifiedByBestSeasonTeam = (teams: WowsLadderRatingTeam[], season: number, realm: string) => {
  const seasonTeamsWithBestRating = teams.filter(
    (team) =>
      team.season_number === Number(season) &&
      team.is_best_season_rating &&
      (realm === TYPE_GLOBAL_SEARCH || team.realm === realm),
  );
  return !isEmpty(seasonTeamsWithBestRating);
};

export const getSeasonTeams = (teams: WowsLadderRatingTeam[], season: number) => {
  return teams.filter((team) => team.season_number === season);
};
