import * as React from 'react';
import { translate } from 'react-i18next';

import { t } from '~/helpers/localization';
import { useAppDispatch } from '~/store';
import { changeCommander } from '~/Actions/ActionClanProfile';

import MembersOperationDialog from './MembersOperationDialog';

import type { IClanMember, IDialog } from '~/types/declaration';

export type IChangeCommanderOperationDialogProps = IDialog<{
  newCommander: IClanMember;
}>;

const ChangeCommanderOperationDialog: React.FC<IChangeCommanderOperationDialogProps> = ({ data, hideDialog }) => {
  const { newCommander } = data;
  const dispatch = useAppDispatch();

  const onChangeClick = React.useCallback(() => {
    return dispatch(changeCommander(newCommander.id)).then((isSuccess: boolean) => {
      isSuccess && hideDialog();
    });
  }, [dispatch, newCommander, hideDialog]);

  return (
    <MembersOperationDialog
      buttonActionTitle={t('Передать')}
      buttonCloseTitle={t('Закрыть')}
      controlElement={null}
      membersForOperate={[newCommander]}
      spinnerLabel={t('Передаем управление')}
      textBeforeMembers={t('Передать управление игроку')}
      title={t('Передача управления кланом')}
      onActionClick={onChangeClick}
      onCloseClick={hideDialog}
    />
  );
};

export default React.memo(
  translate()(ChangeCommanderOperationDialog) as React.FC<IChangeCommanderOperationDialogProps>,
);
