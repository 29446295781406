import classNames from 'classnames';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { Interpolate, translate } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import { t, x } from '~/helpers/localization';
import { BONUS_CATEGORIES, getBuildingBonusesList } from '~/helpers/supply';
import useScrollShadows from '~/hooks/useScrollShadows';

import StepCSSTransitionGroup from '~/Components/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import styles from './ClanBonuses.scss';
import stylesBuilding from '../Building/Building.scss';

import type { AppStateType as State } from '~/Reducers';
import type { ISupplyBuilding } from '~/store/slices/settingsSlice';

interface IClanBuildingCardProps {
  building: ISupplyBuilding;
}

const ClanBuildingCard: React.FC<IClanBuildingCardProps> = ({ building }) => {
  const data = building?.levels?.[building?.level] || null;

  if (!data?.meta) {
    return null;
  }

  const isBuilt = building.level > 0;
  const bonuses = getBuildingBonusesList(building);

  return (
    <section className={styles.building}>
      <hgroup className={styles.buildingHeader}>
        <div
          className={classNames(
            styles.buildingIcon,
            stylesBuilding.icon,
            stylesBuilding[`icon_${building.name}`],
            !isBuilt && stylesBuilding.notBuilt,
          )}
        />
        <div className={styles.buildingTitles}>
          <h4 className={styles.buildingTitle}>{`${data.title}`}</h4>
          <Interpolate
            i18nKey={x('Улучшений: %(level)s из %(maxLevel)s')}
            level={building.level}
            maxLevel={building.maxLevel}
            parent={'p'}
            className={styles.buildingSubtitle}
            t={t}
          />
        </div>
      </hgroup>
      <div className={styles.buildingBonus}>
        {isBuilt && bonuses.length ? (
          bonuses.map((bonus, index) => {
            return (
              <div key={`bonus_${index}`}>
                <Highlighter
                  highlightClassName="HighlightPercent"
                  searchWords={window.localSettings.highlighterPercentRegexp}
                  textToHighlight={bonus}
                />
              </div>
            );
          })
        ) : (
          <p className={styles.inactive}>{building.levels[building.level].meta.function}</p>
        )}
      </div>
    </section>
  );
};

const stateSelector = (state: State) => {
  return {
    buildings: state.ReducerSupply.buildings,
  };
};

interface IClanBonuses {
  children?: React.ReactNode;
}

const ClanBonuses: React.FC<IClanBonuses> = ({ children }) => {
  const { buildings } = useSelector(stateSelector, shallowEqual);

  const containerRef = React.useRef<HTMLDivElement>(null);
  useScrollShadows(containerRef);

  return (
    <>
      <article className={styles.wrapper}>
        <div className={classNames(styles.container)} ref={containerRef}>
          {BONUS_CATEGORIES.map((group, i) => {
            return (
              <StepCSSTransitionGroup
                className={styles.group}
                level={i + 1}
                duration={0.3}
                distance={8}
                delay={0.1}
                key={`buildings_group_${i}`}
              >
                <h3 className={styles.groupHeader}>{group.title}</h3>
                <div className={styles.groupBuildings}>
                  {buildings &&
                    group.buildings.map((buildingName) => {
                      return (
                        <ClanBuildingCard building={buildings[buildingName]} key={`building_card_${buildingName}`} />
                      );
                    })}
                </div>
              </StepCSSTransitionGroup>
            );
          })}
        </div>
      </article>
      <StepCSSTransitionGroup level={BONUS_CATEGORIES.length + 1} duration={0.2} delay={0.15}>
        {children}
      </StepCSSTransitionGroup>
    </>
  );
};

// @TODO: update i18n
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export default React.memo(translate()(ClanBonuses) as React.FC<IClanBonuses>);
