import * as React from 'react';
import { StepCSSTransitionGroup } from '@wg/wows-react-uikit';

import styles from './ContentPage.scss';
import Columns from './widgets/Columns/Columns';
import Html from './widgets/Html/Html';
import Leagues from './widgets/Leagues/Leagues';
import Paragraph from './widgets/Paragraph/Paragraph';

const WIDGETS = {
  columns: Columns,
  ladder_leagues: Leagues,
  paragraph: Paragraph,
  html: Html,
};

type IProps = {
  content:
    | Array<{
        type: string;
        data: object | Array<object> | string | React.ReactNode;
      }>
    | [];
};

class ContentPage extends React.PureComponent<IProps> {
  renderWidgets() {
    const widgets = this.props.content;

    return widgets ? (
      <div>
        {widgets.map((widget, index) => {
          if (!WIDGETS[widget.type]) {
            return null;
          }

          const WidgetToRender = WIDGETS[widget.type];

          const delay = index + 1;

          return (
            <StepCSSTransitionGroup level={delay} key={index}>
              <WidgetToRender content={widget.data} />
            </StepCSSTransitionGroup>
          );
        })}
      </div>
    ) : null;
  }

  render() {
    return <div className={styles.ContentPageWrapper}>{this.renderWidgets()}</div>;
  }
}

export default ContentPage;
