import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { Interpolate, translate } from 'react-i18next';
import { playTabClickSound } from '@wg/web2clientapi/sound';
import {
  Checkbox,
  ClanMember,
  ContextMenu,
  DivTooltip,
  Spinner,
  TableBodyCell,
  TableRow,
  TooltipBody as UIKitTooltipBody,
} from '@wg/wows-react-uikit';

import { clanIsFull } from '~/helpers/clan';
import { getPositionY } from '~/helpers/contextMenu';
import {
  isoToFormattedLocalDate,
  isoToFormattedLocalTime,
  unixToFormattedLocalDate,
  unixToFormattedLocalTime,
} from '~/helpers/datetime';
import { floats, percent, thousands } from '~/helpers/formatting';
import { x } from '~/helpers/localization';

import { ButtonAction, RankCell, StatisticsValue, TooltipInfo } from '~/UIKit';
import { TooltipBody, TooltipFooter } from '~/UIKit/components';

import styles from '../Requests.scss';

import type { IApplication } from '~/Actions/ActionApp';

type PropsType = {
  application: IApplication;
  isProcessing: boolean;
  isChatDenied: boolean;
  isChecked?: boolean;
  clan: {
    members_count: number;
  };
  t: (arg: string, settings?: any) => string;
  onAcceptedApplication: (application: IApplication) => void;
  onDeclinedApplication: (application: IApplication) => void;
  openAccountProfile: (id: string | number) => void;
  openChatWindow: (id: string | number, name: string) => void;
  onApplicationsPlayerTick?: (id: string | number) => void;
};

type StateType = {
  isContextMenuActive: boolean;
  contextMenuContext: Array<any>;
  contextMenuPosition: {
    x: number;
    y: number;
  };
};

const getTooltipBody = (t, application) => {
  return (
    <TooltipBody>
      <Interpolate
        useDangerouslySetInnerHTML={true}
        i18nKey={x('Время для принятия решения по заявке истекает %(date)s&nbsp;в&nbsp;%(time)s.')}
        date={isoToFormattedLocalDate(application.expires_at)}
        time={isoToFormattedLocalTime(application.expires_at)}
        parent={'p'}
        t={t}
      />
      <p>
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Заявка отправлена %(date)s&nbsp;в&nbsp;%(time)s.')}
          date={isoToFormattedLocalDate(application.created_at)}
          time={isoToFormattedLocalTime(application.created_at)}
          t={t}
        />
      </p>
    </TooltipBody>
  );
};

const getDeclineBtnTooltipContent = (t, application) => {
  return (
    <div>
      {getTooltipBody(t, application)}
      <TooltipFooter mouseIcon="left">{t('Отклонить заявку')}</TooltipFooter>
    </div>
  );
};

class ApplicationsClanMemberTableItem extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      isContextMenuActive: false,
      contextMenuContext: [],
      contextMenuPosition: { x: 0, y: 0 },
    };

    this.onContextMenuItemClick = this.onContextMenuItemClick.bind(this);
    this.onContextMenu = this.onContextMenu.bind(this);
    this.onContextMenuStateChange = this.onContextMenuStateChange.bind(this);
    this.onTableRowClick = this.onTableRowClick.bind(this);
    this.onApplicationsPlayerTick = this.onApplicationsPlayerTick.bind(this);
  }

  onApplicationsPlayerTick() {
    this.props.onApplicationsPlayerTick(this.props.application.id);
  }

  onContextMenu(event: React.MouseEvent<any>) {
    const context = this.getContextMenuContext();
    const clientY = getPositionY(event, context);
    this.setState({
      contextMenuContext: context,
      contextMenuPosition: {
        x: event.clientX,
        y: clientY,
      },
    });
  }

  onContextMenuStateChange(isContextMenuActive) {
    this.setState({ isContextMenuActive });
  }

  getContextMenuContext() {
    const { t, isProcessing, application } = this.props;

    const hasPermanentBan = get(application.account, 'ban_info.type') === 'permanent' || !!application.is_banned;
    const banMsg = hasPermanentBan ? t('Аккаунт игрока заблокирован') : null;

    const acceptTooltip = this.getAcceptBtnTooltip();
    return [
      {
        name: null,
        items: [
          {
            name: t('Отправить сообщение'),
            value: 'ACCOUNT_MESSAGE',
            disabled: hasPermanentBan || this.props.isChatDenied,
            tooltip: banMsg,
          },
          {
            name: t('Профиль игрока'),
            value: 'ACCOUNT_PROFILE',
            disabled: hasPermanentBan,
            tooltip: banMsg,
          },
          {
            name: t('Принять в клан'),
            disabled: isProcessing || !!acceptTooltip,
            tooltip: acceptTooltip,
            value: 'ACCEPT_APPLICATION',
          },
          {
            name: t('Отклонить заявку'),
            disabled: isProcessing,
            value: 'DECLINE_APPLICATION',
            tooltip: null,
          },
        ],
      },
    ];
  }

  onContextMenuItemClick(action) {
    const application = this.props.application;
    switch (action) {
      case 'ACCOUNT_PROFILE':
        this.props.openAccountProfile(application.account.id);
        break;
      case 'ACCOUNT_MESSAGE': {
        if (!this.props.isChatDenied) {
          this.props.openChatWindow(application.account.id, application.account.name);
        }
        break;
      }
      case 'ACCEPT_APPLICATION':
        this.props.onAcceptedApplication(application);
        break;
      case 'DECLINE_APPLICATION':
        this.props.onDeclinedApplication(application);
        break;
    }
  }

  getAcceptBtnTooltip() {
    const { t, application, clan } = this.props;

    const account = application.account;
    const banInfo = application.account.ban_info;
    const hasBan = !!banInfo || !!application.is_banned;
    const hasClanCooldown = moment(account.in_clan_cooldown_till).isAfter(moment().utc());
    const isFullClan = clanIsFull(clan);
    let acceptTooltip = null;
    if (isFullClan) {
      acceptTooltip = t('В клане сейчас нет мест');
    } else if ((hasBan && banInfo && banInfo.type === 'permanent') || application.is_banned) {
      acceptTooltip = t('Аккаунт игрока заблокирован');
    } else if (hasBan && account.ban_info.type !== 'permanent') {
      acceptTooltip = t('Аккаунт игрока заблокирован до %(date)s&nbsp;%(time)s', {
        date: unixToFormattedLocalDate(account.ban_info.expired_at),
        time: unixToFormattedLocalTime(account.ban_info.expired_at),
      }).replace('&nbsp;', '\u00a0');
    } else if (hasClanCooldown) {
      acceptTooltip = t('Игрок недавно покинул клан. Вы сможете принять его в клан начиная с %(date)s&nbsp;%(time)s.', {
        date: isoToFormattedLocalDate(account.in_clan_cooldown_till),
        time: isoToFormattedLocalTime(account.in_clan_cooldown_till),
      }).replace('&nbsp;', '\u00a0');
    }
    return acceptTooltip;
  }

  renderAcceptBtnTooltip(t, application) {
    const acceptBtnTooltipContent = this.getAcceptBtnTooltip();
    if (acceptBtnTooltipContent) {
      return (
        <div>
          <TooltipInfo>{acceptBtnTooltipContent}</TooltipInfo>
          {getTooltipBody(t, application)}
        </div>
      );
    } else {
      return (
        <div>
          {getTooltipBody(t, application)}
          <TooltipFooter mouseIcon="left">{t('Принять в клан')}</TooltipFooter>
        </div>
      );
    }
  }

  onTableRowClick() {
    if (this.props.isChatDenied) {
      return;
    }
    void playTabClickSound();
    const account = this.props.application.account;
    this.props.openChatWindow(account.id, account.name);
  }

  render() {
    const { t, application, clan } = this.props;
    const account = application.account;
    const hasBan = !!account.ban_info || !!application.is_banned;

    let actionCell = null;
    if (this.props.isProcessing) {
      actionCell = (
        <TableBodyCell modify="right">
          <Spinner />
        </TableBodyCell>
      );
    } else {
      const isClanCooldown = moment(account.in_clan_cooldown_till).isAfter(moment().utc());
      const isFullClan = clanIsFull(clan);
      const isDisableAccept = isClanCooldown || hasBan || isFullClan;

      const acceptBtnTooltipContent = this.state.isContextMenuActive
        ? null
        : this.renderAcceptBtnTooltip(t, application);
      const declineBtnTooltipContent = this.state.isContextMenuActive
        ? null
        : getDeclineBtnTooltipContent(t, application);

      actionCell = (
        <TableBodyCell modify="right">
          <ButtonAction
            action="ok"
            isDisabled={isDisableAccept}
            onClick={() => {
              this.props.onAcceptedApplication(application);
            }}
            tooltipContent={acceptBtnTooltipContent}
          />
          <ButtonAction
            action="cancel"
            isDisabled={false}
            onClick={() => {
              this.props.onDeclinedApplication(application);
            }}
            tooltipContent={declineBtnTooltipContent}
          />
        </TableBodyCell>
      );
    }

    const statistics = application.statistics;
    const comment = get(this.props.application, 'comment', null);
    const isHiddenStatistics = application.is_hidden_statistics;

    return (
      <TableRow
        isHovering
        isActive={this.state.isContextMenuActive}
        onContextMenu={this.onContextMenu}
        onClick={this.onTableRowClick}
      >
        <TableBodyCell modify="check">
          <Checkbox onChange={this.onApplicationsPlayerTick} isChecked={this.props.isChecked} />
        </TableBodyCell>
        <ContextMenu
          context={this.state.contextMenuContext}
          position={this.state.contextMenuPosition}
          onItemClick={this.onContextMenuItemClick}
          onContextMenuStateChange={this.onContextMenuStateChange}
          isDemo={null}
        />
        <TableBodyCell modify="basis">
          <ClanMember
            id={account.id}
            name={account.name}
            rank={statistics.rank}
            seasonId={statistics.season_id}
            seasonRank={statistics.season_rank}
            hasPermanentBan={get(account, 'ban_info.type') === 'permanent' || !!application.is_banned}
            disableTooltip={this.state.isContextMenuActive}
            leftMouseAction="send_message"
            hasContextMenu
          />
        </TableBodyCell>
        <TableBodyCell modify="small">
          {comment ? (
            <DivTooltip tooltipBody={<UIKitTooltipBody>{comment}</UIKitTooltipBody>}>
              <span className={styles.letterIcon} />
            </DivTooltip>
          ) : null}
        </TableBodyCell>
        <TableBodyCell modify="small">
          <RankCell
            id={account.id}
            rank={statistics.rank}
            seasonId={statistics.season_id}
            seasonRank={statistics.season_rank}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            value={thousands(statistics.btl)}
            disableTooltip={this.state.isContextMenuActive}
            isHidden={isHiddenStatistics}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            value={percent(statistics.wb, undefined)}
            disableTooltip={this.state.isContextMenuActive}
            isHidden={isHiddenStatistics}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            value={thousands(statistics.aeb)}
            disableTooltip={this.state.isContextMenuActive}
            isHidden={isHiddenStatistics}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            value={thousands(statistics.admg)}
            disableTooltip={this.state.isContextMenuActive}
            isHidden={isHiddenStatistics}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            value={floats(statistics.afb, 2)}
            disableTooltip={this.state.isContextMenuActive}
            isHidden={isHiddenStatistics}
          />
        </TableBodyCell>
        {actionCell}
      </TableRow>
    );
  }
}

export default translate()(ApplicationsClanMemberTableItem);
