import debounce from 'lodash/debounce';
import React from 'react';

import useMount from './useMount';

const useScrollShadows = (
  ref: React.MutableRefObject<Nullish<HTMLElement>> | React.RefObject<Nullish<HTMLElement>>,
) => {
  const updateShadows = React.useCallback((targetEl: Nullish<HTMLElement>) => {
    if (!targetEl) {
      return;
    }
    const scrollPosition = Math.ceil(targetEl.scrollTop);
    const scrollHeight = targetEl.scrollHeight - targetEl.clientHeight;

    const shadowTop = scrollPosition > 0;
    const shadowBottom = scrollPosition < scrollHeight;

    const shadows: string[] = [];
    if (shadowTop) {
      shadows.push('inset 0 15px 20px -20px rgba(0,0,0,0.75)');
    } else {
      shadows.push('inset 0 15px 20px -20px rgba(0,0,0,0)');
    }
    if (shadowBottom) {
      shadows.push('inset 0 -15px 20px -20px rgba(0,0,0,0.75)');
    } else {
      shadows.push('inset 0 -15px 20px -20px rgba(0,0,0,0)');
    }
    targetEl.style.boxShadow = shadows.length ? shadows.join(',') : 'none';
  }, []);

  const debouncedUpdateShadows = debounce(() => {
    updateShadows(ref.current);
  }, 50);

  const handleUpdateShadows = React.useCallback(debouncedUpdateShadows, [debouncedUpdateShadows]);

  useMount(() => {
    if (!ref?.current) {
      return;
    }

    const target = ref.current;

    updateShadows(target);

    target.addEventListener('scroll', handleUpdateShadows);
    window.addEventListener('resize', handleUpdateShadows);

    return () => {
      target?.removeEventListener('scroll', handleUpdateShadows);
      window.removeEventListener('resize', handleUpdateShadows);
    };
  });
};

export default useScrollShadows;
