import settings, { seasonSettingsClanstars } from '~/settings';

// Clanrec
export const ClanRec2IsEnabled = settings.clanrec2?.isEnabled || false;

// Darwin
export const darwinIsEnabled = settings.darwin?.isEnabled || false;

// Clanstars
const isClanstarsEnabled = settings.clanstars?.isEnabled || false;
const isSeasonEnabled = seasonSettingsClanstars?.seasonIsEnabled || false;
export const clanstarsIsEnabled = isClanstarsEnabled && isSeasonEnabled;
export const clanstarsSeasonVersion = seasonSettingsClanstars?.seasonVersion || '0.0';
