import classNames from 'classnames';
import React, { PureComponent } from 'react';
import { Interpolate } from 'react-i18next';
import { EntityTypes } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { Button, Spinner } from '@wg/wows-react-uikit';

import { localizedDateTime } from '~/helpers/internalization';
import { t, x } from '~/helpers/localization';

import { currencyNamesToWE } from '~/UIKit/Currency/Currency';

import styles from './TaskRow.scss';
import TaskRowBase, { renderCheckedSpan } from './TaskRowBase';

import { TASK_DESCRIPTIONS } from '../constants';

import type { TaskDescription } from '../constants';
import type { ClanTask } from '~/Actions/ActionClanTasks';

export interface PropsType {
  isClaiming: boolean;
  task: ClanTask;
  canClaimRewards: boolean;
  onClaim: () => void;
}

type StateType = {
  animated: boolean;
};

class TaskRow extends PureComponent<PropsType, StateType> {
  static defaultProps: {
    onClaim: () => void;
    canClaimRewards: boolean;
    isClaiming: boolean;
  };
  constructor(props) {
    super(props);

    this.state = {
      animated: false,
    };
  }

  public animate() {
    this.setState({ ...this.state, animated: true });
  }

  render() {
    const task = this.props.task;
    if (!(task.name in TASK_DESCRIPTIONS)) {
      return null;
    }

    const taskDescription = TASK_DESCRIPTIONS[task.name] as TaskDescription;
    let taskIconTooltip = t('Задача не выполнена');

    if (task.isFinished) {
      const finishedTill = task.finishedTill ? (
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Задача выполнена до %(date)s')}
          date={localizedDateTime(new Date(task.finishedTill))}
          t={t}
        />
      ) : (
        t('Задача выполнена')
      );
      taskIconTooltip = renderCheckedSpan(finishedTill);
    }

    return (
      <TaskRowBase
        {...this.props.task}
        title={taskDescription.title}
        description={taskDescription.description}
        binary={taskDescription.binary}
        total={taskDescription.total}
        taskIconTooltip={taskIconTooltip}
        animated={this.state.animated}
      >
        <WoWSEntity
          type={EntityTypes.CURRENCY}
          id={task.reward.oil ? currencyNamesToWE['oil'] : currencyNamesToWE['coal']}
          amount={task.reward.oil ? task.reward.oil : task.reward.coal}
          presentation={{
            bindTooltip: true,
            withTooltip: true,
            hideReceiveText: true,
            hideSpendText: true,
          }}
          customisation={{
            rewardReceiptDate: task.isClaimed ? localizedDateTime(new Date(task.claimedAt)) : '',
          }}
        >
          <div className={styles.rewardResourceWrapper}>
            {task.isClaimed ? <span className={classNames(styles.checkIcon, styles.rewardHasBeenClaimed)} /> : null}
            {task.reward.coal ? (
              <span
                className={classNames(styles.coal, {
                  [styles.finished]: task.isFinished,
                })}
              >
                {task.reward.coal}
              </span>
            ) : null}
            {task.reward.oil ? (
              <span
                className={classNames(styles.oil, {
                  [styles.finished]: task.isFinished,
                })}
              >
                {task.reward.oil}
              </span>
            ) : null}
          </div>
          {task.isFinished && !task.isClaimed && this.props.canClaimRewards ? (
            <div className={styles.rewardButtonWrapper}>
              {this.props.isClaiming ? (
                <Spinner label="" />
              ) : (
                <Button isFlat isSmaller onClick={this.props.onClaim}>
                  {t('Забрать')}
                </Button>
              )}
            </div>
          ) : null}
        </WoWSEntity>
      </TaskRowBase>
    );
  }
}

TaskRow.defaultProps = {
  isClaiming: false,
  canClaimRewards: false,
  onClaim: () => {},
};

export default TaskRow;
