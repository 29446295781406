import get from 'lodash/get';
import map from 'lodash/map';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { Interpolate, translate } from 'react-i18next';
import { ProgressBar, Tooltip, TooltipBody, TooltipFooter, TooltipHeader } from '@wg/wows-react-uikit';

import { t, x } from '~/helpers/localization';

import styles from '~/Components/ViewSupply/ViewSupply.scss';

type PropsType = {
  battlesStats: any;
  building: any;
};

const BuildingTooltip: React.FC<PropsType> = ({ battlesStats, building }) => {
  const meta = building.levels[building.level].meta;
  const hasNextLevel = building.levels[building.level + 1] !== undefined;
  const nextLevelMeta = hasNextLevel ? building.levels[building.level + 1].meta : meta;
  const isShipsAndVessels = ['ships', 'vessels'].includes(building.name);
  const isSuperShipsHome = building.name === 'superships_home';

  const battleType = get(nextLevelMeta, 'levelRequirements.type', undefined);
  const currentBattles = battleType && battlesStats[battleType] !== null ? battlesStats[battleType] : 0;
  const needBattles = get(nextLevelMeta, 'levelRequirements.battleCount', 0);
  const battleProgress = Math.min(currentBattles / needBattles, 1);

  return (
    <Tooltip>
      <TooltipHeader isBold={false} isHelpIcon={false}>
        <div className={styles.tooltipInlineHeader}>
          <div className={styles[`${building.name}Icon`]} />
          <div className={styles.buildingTooltipHeaderText}>
            <div className={styles.buildingTooltipModifierTitle}>{`${building.levels[building.level].title}`}</div>
            <div>
              <Interpolate
                i18nKey={x('Улучшений: %(level)s из %(maxLevel)s')}
                level={building.level}
                maxLevel={building.maxLevel}
                parent={'p'}
                t={t}
              />
            </div>
          </div>
        </div>
      </TooltipHeader>
      <TooltipBody>{`${meta.function}`}</TooltipBody>
      {isSuperShipsHome && meta.bonus ? (
        <TooltipBody key="bonus" bgColor="dark">
          {map(building.levels, (item, idx) => {
            if (idx <= building.level && item.meta.bonus && item.meta.bonus !== '') {
              return (
                <div key={`bonus-${idx}`} className={styles.topWrapperTooltip}>
                  <Highlighter
                    highlightClassName="HighlightPercent"
                    searchWords={window.localSettings.highlighterPercentRegexp}
                    textToHighlight={item.meta.bonus}
                  />
                </div>
              );
            }
          })}
        </TooltipBody>
      ) : (
        meta.bonus && [
          <TooltipBody key="bonus" bgColor="dark">
            <Highlighter
              highlightClassName="HighlightPercent"
              searchWords={window.localSettings.highlighterPercentRegexp}
              textToHighlight={meta.totalBonus}
            />
          </TooltipBody>,
        ]
      )}

      {isShipsAndVessels && (
        <>
          <TooltipBody bgColor="dark">
            <div className={styles.tooltipProgressRow}>
              {t('Общее количество боев:')}
              <Interpolate
                i18nKey={x('%(currentBattles)s/%(needBattles)s')}
                currentBattles={currentBattles}
                needBattles={needBattles}
                t={t}
              />
            </div>
            <ProgressBar size={'small'} completed={battleProgress} />
          </TooltipBody>
        </>
      )}

      <TooltipFooter mouseIcon="left">{t('Перейти к строению')}</TooltipFooter>
    </Tooltip>
  );
};

export default translate()(BuildingTooltip);
