import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { DivTooltip, Tooltip, TooltipBody } from '@wg/wows-react-uikit';

import { playButtonSound } from '~/web2ClientAPI/sounds';

import styles from './Back.scss';

export type IProps = {
  prevPathList: Array<any>;
  t: (str: string) => string;
};

const contextTypes = {
  router: PropTypes.object.isRequired,
};

class Back extends PureComponent<IProps, any> {
  onClick = (event) => {
    event.stopPropagation();

    playButtonSound();

    if (this.props.prevPathList.length > 1) {
      this.context.router.go(-this.props.prevPathList.length);
    } else {
      this.context.router.goBack();
    }
  };

  render() {
    const t = this.props.t;

    return (
      <div className={styles.base} onClick={this.onClick}>
        <DivTooltip
          tooltipBody={
            <Tooltip>
              <TooltipBody>{t('Вернуться назад:tooltip')}</TooltipBody>
            </Tooltip>
          }
        >
          {t('Назад')}
        </DivTooltip>
      </div>
    );
  }
}

Back.contextTypes = contextTypes;

const mapStateToProps = (state) => ({
  prevPathList: state.ReducerSupply.prevPathList,
});

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Back));
