import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { Interpolate } from 'react-i18next';
import { Button, TooltipBody } from '@wg/wows-react-uikit';

import { clanIsFull } from '~/helpers/clan';
import { isoToFormattedLocalDate, isoToFormattedLocalTime } from '~/helpers/datetime';
import { t, x } from '~/helpers/localization';

import type { ClanInfoType } from '~/Actions/ActionClanProfile';
import type { Invite } from '~/Actions/ActionInvites';
import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';

type IProps = {
  clan: ClanInfoType;
  currentAccount: ICurrentAccountState;
  isEllipsis?: boolean;
  onAcceptInvite: (invite: Invite | { id: number; clan: ClanInfoType }) => void;
  onClick?: () => void;
  onSendApplication: (clan: ClanInfoType) => void;
  size?: 'isSmaller' | 'isDefault';
};

class ButtonJoinClan extends React.PureComponent<IProps> {
  render() {
    const { currentAccount, size } = this.props;

    const isAlreadySentApplication = !isEmpty(this.props.clan.active_applications);
    const activeInviteId =
      this.props.clan.active_invites?.length &&
      parseInt(this.props.clan.active_invites[0].match(/(\d+)/)?.[0] || '', 10);
    const isFullClan = clanIsFull(this.props.clan);

    let isDisabled = false;
    let tooltipContent: React.ReactNode = null;
    let joinClanButtonLabel = t('Вступить в клан');
    let onClick = () => {
      this.props.onClick?.();
      this.props.onSendApplication(this.props.clan);
    };
    const isSmaller = size && size === 'isDefault' ? false : true;

    if (isAlreadySentApplication) {
      joinClanButtonLabel = t('Заявка отправлена');
      isDisabled = true;
    } else if (activeInviteId && isFullClan) {
      tooltipContent = <TooltipBody>{t('Вам отправлено приглашение, но в клане сейчас нет мест.')}</TooltipBody>;
      isDisabled = true;
    } else if (currentAccount.inClanCooldownTill) {
      const i18nKey = activeInviteId
        ? x('Вы недавно покинули клан. Вы сможете вступить в клан начиная с %(date)s&nbsp;%(time)s.')
        : x('Вы недавно покинули клан. Вы сможете подать заявку в клан начиная с %(date)s&nbsp;%(time)s.');

      tooltipContent = (
        <TooltipBody>
          <Interpolate
            useDangerouslySetInnerHTML={true}
            i18nKey={i18nKey}
            date={isoToFormattedLocalDate(currentAccount.inClanCooldownTill)}
            time={isoToFormattedLocalTime(currentAccount.inClanCooldownTill)}
            parent={'p'}
            t={t}
          />
        </TooltipBody>
      );
      isDisabled = true;
    } else if (activeInviteId) {
      onClick = () => {
        this.props.onAcceptInvite({
          id: activeInviteId,
          clan: this.props.clan,
        });
      };
    }

    return (
      <Button
        isFlat
        isSmaller={isSmaller}
        onClick={onClick.bind(this)}
        isDisabled={isDisabled}
        tooltipContent={tooltipContent}
        isEllipsis={this.props.isEllipsis}
        isJustified
      >
        {joinClanButtonLabel}
      </Button>
    );
  }
}

export default React.memo(ButtonJoinClan);
