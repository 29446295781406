import settings from '~/settings';

import { BATTLE_TYPES } from '~/constants';
import { get } from '~/helpers/api';
import { fetchWrapper as fetch } from '~/helpers/fetch';
import { getLastSeasonOfType } from '~/helpers/ladder';
import Loader from '~/helpers/loader';
import { t } from '~/helpers/localization';
import { getSeasonBattleType } from '~/helpers/seasons';
import { getCurrentClan } from '~/store/selectors/currentAccountSelector';

import { holdScrollPosition } from '~/utils';
import * as web2ClientAPI from '~/web2ClientAPI/web2ClientAPI';

import { actionsAccount } from './ActionAccount';
import { actionsClanProfile } from './ActionClanProfile';
import { actionsRequests } from './ActionRequests';

import type { ClanInfoType } from './ActionClanProfile';
import type { ClanRecommendation } from './ActionRecommendations';
import type { ClanType, Statistic } from '~/Actions/ActionInvites';
import type { InferActionsType } from '~/Reducers';
import type { ROLE_NAMES } from '~/roles';
import type { AppAsyncThunk, IAppDispatch } from '~/store';
import type { IApiGetApplicationsResponse, IApiError, IApiGetApplicationsPayload } from '~/types/api';

export const APPLICATIONS_CHANGE_ORDER = 'APPLICATIONS_CHANGE_ORDER';
export const APPLICATIONS_CHANGE_PAGE = 'APPLICATIONS_CHANGE_PAGE';
export const APPLICATIONS_DROP_SELECTION = 'APPLICATIONS_DROP_SELECTION';
export const APPLICATIONS_JOIN_CLAN_FETCHING = 'APPLICATIONS_JOIN_CLAN_FETCHING';
export const APPLICATIONS_PROCESSING_FAILED = 'APPLICATIONS_PROCESSING_FAILED';
export const APPLICATIONS_PROCESSING_SUCCESS = 'APPLICATIONS_PROCESSING_SUCCESS';
export const APPLICATIONS_START_PROCESSING = 'APPLICATIONS_START_PROCESSING';
export const APPLICATIONS_TOGGLE_ALL_PLAYERS_TICK = 'APPLICATIONS_TOGGLE_ALL_PLAYERS_TICK';
export const APPLICATIONS_TOGGLE_FETCHING = 'APPLICATIONS_TOGGLE_FETCHING';
export const APPLICATIONS_TOGGLE_PLAYER_TICK = 'APPLICATIONS_TOGGLE_PLAYER_TICK';
export const APPLICATIONS_UPDATE_APPLICATIONS = 'APPLICATIONS_UPDATE_APPLICATIONS';
export const APPLICATIONS_UPDATE_ERROR = 'APPLICATIONS_UPDATE_ERROR';
export const UPDATE_ACCOUNT_INFO = 'UPDATE_ACCOUNT_INFO';

export type IApplication = {
  clan: ClanType;
  comment: string | null;
  created_at: string;
  expires_at: string;
  game: string;
  id: number;
  is_hidden_statistics: boolean;
  statistics: Statistic;
  status: string;
  status_changer: { id: number };
  updated_at: string;
  account?: {
    name: string;
  };
};

export type Meta = {
  collection?: string;
  offset?: number;
  total?: number;
  total_clans?: number;
};

export type ActionsType = InferActionsType<typeof actionsApp>;

export const actionsApp = {
  changePage: (page: number) =>
    ({
      type: APPLICATIONS_CHANGE_PAGE,
      page,
    }) as const,

  changeOrder: (order: string, isAsc: boolean) =>
    ({
      type: APPLICATIONS_CHANGE_ORDER,
      order,
      isAsc,
    }) as const,

  toggleFetching: () =>
    ({
      type: APPLICATIONS_TOGGLE_FETCHING,
    }) as const,

  updateApplications: (updateApplication: IApiGetApplicationsResponse) =>
    ({
      type: APPLICATIONS_UPDATE_APPLICATIONS,
      data: updateApplication,
    }) as const,

  updateError: (error: IApiError) =>
    ({
      type: APPLICATIONS_UPDATE_ERROR,
      error,
    }) as const,

  startProcessing: (ids: number[]) =>
    ({
      type: APPLICATIONS_START_PROCESSING,
      ids,
    }) as const,

  processingSuccess: (ids: number[]) => {
    holdScrollPosition();
    return {
      type: APPLICATIONS_PROCESSING_SUCCESS,
      ids,
    } as const;
  },

  processingFailed: (ids: number[]) => {
    holdScrollPosition();
    return {
      type: APPLICATIONS_PROCESSING_FAILED,
      ids,
    } as const;
  },

  toggleJoinClanFetching: () =>
    ({
      type: APPLICATIONS_JOIN_CLAN_FETCHING,
    }) as const,

  toggleApplicationsPlayerTick: (selectedApplicationsPlayersId: number) =>
    ({
      type: APPLICATIONS_TOGGLE_PLAYER_TICK,
      selectedApplicationsPlayersId,
    }) as const,

  toggleAllApplicationsPlayersTick: (selectedApplicationsPlayersIds: number[] = []) =>
    ({
      type: APPLICATIONS_TOGGLE_ALL_PLAYERS_TICK,
      selectedApplicationsPlayersIds,
    }) as const,

  dropSelection: () =>
    ({
      type: APPLICATIONS_DROP_SELECTION,
    }) as const,

  updateAccountInfo: (info: Partial<{ clanId: Nullable<number>; role: ROLE_NAMES; activeApplicationsCount: number }>) =>
    ({
      type: UPDATE_ACCOUNT_INFO,
      info,
    }) as const,
};

const processAcceptError = (dispatch: IAppDispatch, json: JSON, application: IApplication, currentClan: ClanType) => {
  const additionalInfo = json.additional_info || {};
  const reason = (json.title || '').toLowerCase();

  const playerName = application.account.name;
  switch (reason) {
    case 'account_banned':
      web2ClientAPI.sendAcceptApplicationPlayerHasPermamentBanErrorNotification(playerName);
      break;

    case 'account_in_cooldown':
      web2ClientAPI.sendAcceptApplicationPlayerInClanCooldown(playerName, additionalInfo.expired_at);
      break;

    case 'application_is_not_active':
      web2ClientAPI.sendAcceptApplicationWithExpiredStatusErrorNotification(playerName);
      dispatch(actionsAccount.decreaseActiveEntriesCount());
      return dispatch(actionsApp.processingSuccess([application.id]));

    case 'account_already_in_clan':
      web2ClientAPI.sendAcceptApplicationPlayerInOtherClanErrorNotification(playerName);
      break;

    case 'insufficient_permissions':
      web2ClientAPI.sendAcceptApplicationPermissionErrorNotification(playerName);
      break;

    case 'clan_is_full':
      web2ClientAPI.sendAcceptApplicationClanIsFullErrorNotification(playerName, currentClan);
      break;

    default:
      web2ClientAPI.sendAcceptApplicationErrorWithPlayerInfo(playerName);
  }

  return dispatch(actionsApp.processingFailed([application.id]));
};

export const acceptApplication =
  (application: IApplication): AppAsyncThunk =>
  (dispatch, getState) => {
    const state = getState();
    const url = `${state.urls.applications}${application.id}/`;
    const options = {
      method: 'PATCH',
      body: { status: 'accepted' },
    };

    dispatch(actionsApp.startProcessing([application.id]));
    return fetch(url, options)
      .then((json) => {
        const state = getState();
        const currentClan = getCurrentClan(state);
        if (json.title || json.status === 'error') {
          return processAcceptError(dispatch, json, application, currentClan);
        }

        const player = {
          name: application.account.name,
          roleName: 'private',
        };
        web2ClientAPI.sendAcceptApplicationNotification(player, currentClan);

        if (
          state.applications.page === Math.ceil(state.applications.meta.total / settings.entries.limit) &&
          state.applications.applications.length === 1 &&
          state.applications.page - 1 !== 0
        ) {
          dispatch(actionsApp.changePage(state.applications.page - 1));
        }

        void dispatch(fetchApplications());

        dispatch(actionsAccount.decreaseActiveEntriesCount());
        dispatch(actionsClanProfile.increaseClanMembersCount(currentClan.id, 1));
        return dispatch(actionsApp.processingSuccess([application.id]));
      })
      .catch(() => {
        return processAcceptError(dispatch, null, application, getCurrentClan(getState()));
      });
  };

const processDeclineError = (dispatch: IAppDispatch, json: JSON, application: IApplication, currentClan: ClanType) => {
  const reason = json.additional_info?.reason;
  if (reason === 'application_is_not_active') {
    const applicationStatus = json.additional_info?.status;
    if (applicationStatus === 'accepted') {
      const playerName = application.account.name;
      web2ClientAPI.sendDeclineApplicationAlreadyAcceptedErrorNotification(playerName, currentClan);
    }
    dispatch(actionsAccount.decreaseActiveEntriesCount());
    return dispatch(actionsApp.processingSuccess([application.id]));
  } else if (reason === 'account_already_in_clan') {
    const clanId = json.additional_info?.clan_id;
    if (clanId === currentClan.id) {
      const playerName = application.account.name;
      web2ClientAPI.sendDeclineApplicationAlreadyAcceptedErrorNotification(playerName, currentClan);
    }
  } else {
    web2ClientAPI.sendDeclineApplicationErrorNotification();
  }

  return dispatch(actionsApp.processingFailed([application.id]));
};

export const declineApplications = (): AppAsyncThunk => (dispatch, getState) => {
  const state = getState();
  const applicationsIds = state.applications.selectedApplicationsPlayersIds;
  const url = state.urls.applicationsManage;
  const options = {
    method: 'POST',
    body: { ids: applicationsIds },
  };

  dispatch(actionsApp.startProcessing(applicationsIds));

  return fetch(url, options)
    .then((json) => {
      if (json.status === 'error') {
        web2ClientAPI.sendDeclineApplicationErrorNotification();
        return dispatch(actionsApp.processingFailed(applicationsIds));
      }

      web2ClientAPI.sendDeclineApplicationsNotification();

      if (
        state.applications.page === Math.ceil(state.applications.meta.total / settings.entries.limit) &&
        state.applications.applications.length === applicationsIds.length &&
        state.applications.page - 1 !== 0
      ) {
        dispatch(actionsApp.changePage(state.applications.page - 1));
      }

      void dispatch(fetchApplications());

      dispatch(actionsApp.toggleAllApplicationsPlayersTick());
      return dispatch(actionsApp.processingSuccess(applicationsIds));
    })
    .catch(() => {
      web2ClientAPI.sendDeclineApplicationErrorNotification();
      return dispatch(actionsApp.processingFailed(applicationsIds));
    });
};

export const declineApplication =
  (application: IApplication): AppAsyncThunk =>
  (dispatch, getState) => {
    const state = getState();
    const url = `${state.urls.applications}${application.id}/`;
    const options = {
      method: 'PATCH',
      body: { status: 'declined' },
    };

    dispatch(actionsApp.startProcessing([application.id]));

    return fetch(url, options)
      .then((json) => {
        if (json.status === 'error') {
          return processDeclineError(dispatch, json, application, getCurrentClan(getState()));
        }

        web2ClientAPI.sendDeclineApplicationNotification(application.account.name, getCurrentClan(getState()));

        if (
          state.applications.page === Math.ceil(state.applications.meta.total / settings.entries.limit) &&
          state.applications.applications.length === 1 &&
          state.applications.page - 1 !== 0
        ) {
          dispatch(actionsApp.changePage(state.applications.page - 1));
        }

        void dispatch(fetchApplications());
        dispatch(actionsAccount.decreaseActiveEntriesCount());
        return dispatch(actionsApp.processingSuccess([application.id]));
      })
      .catch(() => {
        return processDeclineError(dispatch, null, application, getCurrentClan(getState()));
      });
  };

export const fetchApplications =
  (withGlobalSpinner?: boolean): AppAsyncThunk =>
  async (dispatch, getState) => {
    const state = getState();
    if (state.applications.isFetching) {
      return;
    }

    const battleType = state.requests.requestsBattleType;
    const clanSeasonType = getSeasonBattleType(battleType);

    let currentSeason: number = state.requests.requestsSeason;

    dispatch(actionsApp.toggleFetching());

    if (clanSeasonType && state.requests.requestsSeasonType !== clanSeasonType) {
      dispatch(actionsRequests.changeRequestsSeasonType(clanSeasonType));
      const seasonTypeId = getLastSeasonOfType(clanSeasonType)?.id;
      if (seasonTypeId) {
        currentSeason = seasonTypeId;
        dispatch(actionsRequests.changeRequestsSeason(currentSeason));
      }
    }

    const currentAccount = state.currentAccount;
    const url = currentAccount.clanId ? state.urls.applicationsClanList : state.urls.applicationsAccountList;
    const limit = settings.entries.limit;
    const order = state.applications.sort.order;

    const payload: IApiGetApplicationsPayload = {
      battle_type: clanSeasonType ? BATTLE_TYPES.REGULAR_CVC : state.requests.requestsBattleType,
      order: state.applications.sort.isAsc ? order : `-${order}`,
      offset: (state.applications.page - 1) * limit,
      season_id: clanSeasonType ? currentSeason : undefined,
      limit,
    };

    const loader = withGlobalSpinner ? new Loader(t('Загружаем заявки')) : null;

    try {
      loader?.start(dispatch);

      const result = await get<IApiGetApplicationsResponse>(url, payload);
      if (result.error) {
        dispatch(actionsApp.updateError(result.error));
        return;
      }

      const activeApplicationsCount = result?._meta_?.total ?? currentAccount.activeApplicationsCount;
      dispatch(actionsApp.updateAccountInfo({ activeApplicationsCount }));
      dispatch(actionsApp.updateApplications(result));
    } catch (error: unknown) {
      dispatch(
        actionsApp.updateError({
          error: t('Произошла ошибка. Повторите попытку позже'),
        }),
      );
    } finally {
      loader?.stop(dispatch);
    }
  };

const ENTRY_SOURCES = settings.entrySources;

const processSendError = (dispatch: IAppDispatch, json: JSON, clan: ClanType, shouldFetchApplications?: boolean) => {
  const reason = json.additional_info?.reason;
  if (reason === 'clan_does_not_meet_requirements') {
    web2ClientAPI.sendSendApplicationNotification(clan);
    dispatch(actionsClanProfile.updateClanActiveApplications(clan.id, null));
    if (shouldFetchApplications) {
      void dispatch(fetchApplications(true));
    }
  } else {
    web2ClientAPI.sendSendApplicationErrorNotification(reason, clan);
  }
};

export const sendApplication =
  (
    clan: ClanInfoType | ClanRecommendation,
    sourceName: keyof typeof ENTRY_SOURCES,
    shouldFetchApplications = false,
    info = '',
  ): AppAsyncThunk =>
  (dispatch, getState) => {
    const url = getState().urls.applications;

    dispatch(actionsApp.toggleJoinClanFetching());
    const source = ENTRY_SOURCES[sourceName] || ENTRY_SOURCES.clan_profile;
    return fetch(url, {
      method: 'POST',
      body: {
        recipient_id: clan.id,
        source: source,
        comment: info,
      },
    })
      .then((json) => {
        if (json.status === 'error' || json.error) {
          processSendError(dispatch, json, clan, shouldFetchApplications);
        } else {
          web2ClientAPI.sendSendApplicationNotification(clan);

          dispatch(actionsClanProfile.updateClanActiveApplications(clan.id, json.application.id));

          if (shouldFetchApplications) {
            void dispatch(fetchApplications(true));
          }
        }
        dispatch(actionsApp.toggleJoinClanFetching());
      })
      .catch(() => {
        processSendError(dispatch, null, clan);
        dispatch(actionsApp.toggleJoinClanFetching());
      });
  };
