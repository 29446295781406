import * as React from 'react';

import Members from '~/Containers/ContainerMembersRoute';

type MembersRouteType = {
  params: {
    clanId: string;
  };
};

const MembersRoute: React.FC<MembersRouteType> = ({ params }) => {
  return <Members clanId={params.clanId ? Number(params.clanId) : null} />;
};

export default MembersRoute;
