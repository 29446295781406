import preloaded from '~/preloaded';

import { CLANSTARS_TABS } from '~/constants';
import { CHANGE_TAB, UPDATE_REWARDS, UPDATE_STATE } from '~/Actions/ActionClanStars';

import type { ActionsType } from '~/Actions/ActionClanStars';
import type { CLANSTARS_QUEST_TYPES, CLANSTARS_REWARD_STATUSES } from '~/constants';

type ClanClanStarsCountType = {
  [key: string]: number;
};

export interface IClanStar {
  clanId: number;
  questId: string;
  spaId: number;
}

export interface IClanStarsReward {
  claimedAt?: string;
  clanId: number;
  clanstarsCount: number;
  createdAt: string;
  id: number;
  spaId: number;
  status?: CLANSTARS_REWARD_STATUSES;
  updatedAt: string;
}

export type IClanStarsRewardInfo = {
  amount: number;
  customisation: Nullable<string>;
  id: Nullable<number>;
  type: string;
};

export type IClanStarsQuest = {
  id: string;
  maxCompletions: number;
  name: string;
  order: number;
  type: CLANSTARS_QUEST_TYPES;
} & (
  | Record<string, never>
  | {
      data: {
        achievement: number;
      };
      type: CLANSTARS_QUEST_TYPES.EARN_ACHIEVEMENT;
    }
);

export type IClanStarsSeasonSettings = {
  seasonDateFinish?: number;
  seasonDateStart?: number;
  seasonIsEnabled: boolean;
  seasonQuests: IClanStarsQuest[];
  seasonVersion: string;
};

export type IClanStarsRewardsInfo = {
  [key: string]: IClanStarsRewardInfo[];
};

export type IClanStarsState = {
  accountClanstars: IClanStar[];
  accountRewards: IClanStarsReward[];
  clanClanstarsCount: ClanClanStarsCountType;
  rewardsInfo: IClanStarsRewardsInfo;
  seasonQuests: IClanStarsQuest[];
  seasonVersionInfo: string;
  selectedTab: CLANSTARS_TABS;
};

const initialState: IClanStarsState = {
  accountClanstars: preloaded.clanStars?.accountClanstars || [],
  accountRewards: preloaded.clanStars?.accountRewards || [],
  clanClanstarsCount: preloaded.clanStars?.clanClanstarsCount || {},
  rewardsInfo: preloaded.clanStars?.rewardsInfo || {},
  seasonQuests: preloaded.clanStarsSeasonSettings?.seasonQuests?.sort((a, b) => a.order - b.order) || [],
  seasonVersionInfo: preloaded.clanStarsSeasonSettings?.seasonVersion || '',
  selectedTab: CLANSTARS_TABS.DIVISION_STAR,
};

export const ReducerClanStars = (state: IClanStarsState = initialState, action: ActionsType): IClanStarsState => {
  switch (action.type) {
    case CHANGE_TAB: {
      return {
        ...state,
        selectedTab: action.payload,
      };
    }

    case UPDATE_REWARDS: {
      return {
        ...state,
        accountRewards: action.payload,
      };
    }

    case UPDATE_STATE: {
      return {
        ...state,
        accountClanstars: action.payload.accountClanstars,
        accountRewards: action.payload.accountRewards,
        clanClanstarsCount: action.payload.clanClanstarsCount,
        rewardsInfo: action.payload.rewardsInfo,
      };
    }

    default:
      return state;
  }
};
