import { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { playButtonClickSound } from '@wg/web2clientapi/sound';

import { DIALOGS_NAMES, DIALOGS_THEMES } from '~/constants';
import {
  closeAllDialogs,
  showCWarsCalendarDialog,
  showCWarsStatsDialog,
  showCWarsAttemptsDialog,
} from '~/helpers/dialogs';
import { ROUTES_MAP } from '~/routes';
import { showDialog, hideDialog } from '~/store/slices/dialogsSlice';
import {
  actionsClanWars,
  clearSearchThunk,
  getRatings,
  hideLastRoundResultsThunk,
  init,
  loadHistory,
  refreshHistoryModal,
  setIsParticipant,
  setUseAttemptsThunk,
  loadHistoryModalData,
  showOwnClanPosition,
  showSeasonLeaders,
  toggleAutoParticipationThunk,
} from '~/Actions/ActionClanWars';

import ViewClanWars from '~/Components/ViewClanWars/ViewClanWars';

import type { ROUND_TYPE } from '~/Reducers/ReducerClanWars';
import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';
import type { IAppDispatch, RootState } from '~/store';
import type {
  IBattle,
  ICommonInfo,
  IDictionary,
  IHistoryRound,
  IStages,
  IWarSettings,
  Ship,
} from '~/types/declaration';

export interface ContainerClanWars_Props {
  clanId: number | null;
  isOnline: boolean;
  selectedTab: number;
  prepageStageTabIndex: number;
  gradesTableTabIndex: number;
  autoParticipation: boolean;
  allPlayersInRatingTable: boolean;
  currentAccount: ICurrentAccountState;
  settings: any;
  clanInfo: {
    color: string;
    createdAt: string;
    id: number;
    isPreloadedData: boolean;
    maxMembersCount: number;
    members_count: number;
    name: string;
    rawDescription: string;
    status: string;
    tag: string;
  };

  commonInfo: ICommonInfo;
  stages: IStages;
  roundType: ROUND_TYPE;
  warSettings: IWarSettings;
  groupedBattles: any;
  enemyGrades: IDictionary<any>;

  selectedClanRatingLeague: number | null;
  searchInputValue: string;
  clanRatingList: {
    data: Array<any>;
    meta: any;
  };
  clanRatingSearchList: {
    data: Array<any>;
    meta: any;
  };
  clanRatingAutocomplete: {
    data: Array<any>;
    meta: any;
  };
  selectedClanId: number | null;
  root: string;
  isReady: boolean;
  historyModalSlide: number;
  restriction: null | number;
  nextWeekStartDate: string;
  lastRoundResults: null;
  lastRoundResultsReady: boolean;
  lastRoundResultsHidden: boolean;
  useAttempts: boolean;
  isFromPort: boolean;
  router?: any;
  hasErrors: boolean;
  ships: {
    [shipId: number]: Ship;
  };
  roundsHistory: Array<IHistoryRound>;
  roundsHistoryCount: number;
  roundsHistoryOffset: number;
  roundsHistoryLimit: number;
  isRoundsHistoryLoading: boolean;

  init: (isReload: boolean) => void;
  setSelectedTab: (selectedTab: number) => void;
  setPrepareStageTab: (prepageStageTabIndex: number) => void;
  closeAllDialogs: () => void;
  showNationShipTypeStatistic: (nation: string, type: string) => void;
  showAttemptsModal: (id: number, battles: IBattle[]) => void;
  hideAttemptsModal: () => void;
  toggleAutoParticipation: () => void;
  setGradesTableTab: (index: number) => void;
  setIsParticipant: (value: boolean) => void;
  selectRatingLeague: (league: number) => void;
  searchInputChange: (value: string) => void;
  getRatings: (clanId?: number, league?: number) => void;
  ratingsSearch: (query: string) => void;
  clearSearch: () => void;
  showOwnClanPosition: () => void;
  showSeasonLeaders: () => void;
  showCalendarModal: () => void;
  togglePlayersCountInRating: () => void;
  hideLastRoundResults: () => void;
  showLastRoundResults: () => void;
  setUseAttempts: (val: boolean) => void;
  showHistoryModal: () => void;
  hideHistoryModal: () => void;
  slideHistoryModal: (direction: 'left' | 'right') => void;
  loadHistory: () => void;
  refreshHistoryModal: () => void;
}

export interface ContainerClanWars_State {}

class ContainerClanWars extends Component<ContainerClanWars_Props, ContainerClanWars_State> {
  componentDidMount() {
    this.props.init(false);
  }

  componentWillUnmount() {
    this.props.closeAllDialogs();
  }

  navigateToClan = (clanId: string) => {
    if (clanId) {
      this.props.router.push(`${this.props.root}clan-profile/${clanId}`);
    }
  };

  navigateToRecommendations = () => {
    this.props.router.push(this.props.root + ROUTES_MAP.SEARCH);
  };

  setSelectedTab = (selectedTab: number) => {
    switch (selectedTab) {
      case 0: {
        this.props.router.push(`${this.props.root}clan-wars/war`);
        break;
      }
      case 1: {
        this.props.router.push(`${this.props.root}clan-wars/rating`);
        break;
      }
      case 2: {
        this.props.router.push(`${this.props.root}clan-wars/rules`);
        break;
      }
      default: {
        break;
      }
    }
  };

  render() {
    return (
      <ViewClanWars
        topGradesIncrement={null}
        root={null}
        clanId={this.props.clanId}
        settings={this.props.settings}
        selectedTab={this.props.selectedTab}
        prepageStageTabIndex={this.props.prepageStageTabIndex}
        gradesTableTabIndex={this.props.gradesTableTabIndex}
        autoParticipation={this.props.autoParticipation}
        isOnline={this.props.isOnline}
        allPlayersInRatingTable={this.props.allPlayersInRatingTable}
        currentAccount={this.props.currentAccount}
        clanInfo={this.props.clanInfo}
        commonInfo={this.props.commonInfo}
        stages={this.props.stages}
        warSettings={this.props.warSettings}
        roundType={this.props.roundType}
        groupedBattles={this.props.groupedBattles}
        enemyGrades={this.props.enemyGrades}
        restriction={this.props.restriction}
        nextWeekStartDate={this.props.nextWeekStartDate}
        selectedClanRatingLeague={this.props.selectedClanRatingLeague}
        searchInputValue={this.props.searchInputValue}
        clanRatingList={this.props.clanRatingList}
        clanRatingSearchList={this.props.clanRatingSearchList}
        clanRatingAutocomplete={this.props.clanRatingAutocomplete}
        selectedClanId={this.props.selectedClanId}
        isReady={this.props.isReady}
        historyModalSlide={this.props.historyModalSlide}
        lastRoundResults={this.props.lastRoundResults}
        lastRoundResultsReady={this.props.lastRoundResultsReady}
        lastRoundResultsHidden={this.props.lastRoundResultsHidden}
        isFromPort={this.props.isFromPort}
        hasErrors={this.props.hasErrors}
        ships={this.props.ships}
        roundsHistory={this.props.roundsHistory}
        roundsHistoryCount={this.props.roundsHistoryCount}
        roundsHistoryOffset={this.props.roundsHistoryOffset}
        roundsHistoryLimit={this.props.roundsHistoryLimit}
        isRoundsHistoryLoading={this.props.isRoundsHistoryLoading}
        setSelectedTab={this.setSelectedTab}
        setGradesTableTab={this.props.setGradesTableTab}
        setPrepareStageTab={this.props.setPrepareStageTab}
        closeAllDialogs={this.props.closeAllDialogs}
        showNationShipTypeStatistic={this.props.showNationShipTypeStatistic}
        useAttempts={this.props.useAttempts}
        setUseAttempts={this.props.setUseAttempts}
        showAttemptsModal={this.props.showAttemptsModal}
        hideAttemptsModal={this.props.hideAttemptsModal}
        toggleAutoParticipation={this.props.toggleAutoParticipation}
        setIsParticipant={this.props.setIsParticipant}
        selectRatingLeague={this.props.selectRatingLeague}
        searchInputChange={this.props.searchInputChange}
        getRatings={this.props.getRatings}
        ratingsSearch={this.props.ratingsSearch}
        clearSearch={this.props.clearSearch}
        showOwnClanPosition={this.props.showOwnClanPosition}
        showSeasonLeaders={this.props.showSeasonLeaders}
        navigateToClan={this.navigateToClan}
        showCalendarModal={this.props.showCalendarModal}
        togglePlayersCountInRating={this.props.togglePlayersCountInRating}
        hideLastRoundResults={this.props.hideLastRoundResults}
        showLastRoundResults={this.props.showLastRoundResults}
        init={this.props.init}
        navigateToRecommendations={this.navigateToRecommendations}
        showHistoryModal={this.props.showHistoryModal}
        slideHistoryModal={this.props.slideHistoryModal}
        loadHistory={this.props.loadHistory}
        hideHistoryModal={this.props.hideHistoryModal}
        refreshHistoryModal={this.props.refreshHistoryModal}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const clanId = state.currentAccount.clanId;

  return {
    clanId: clanId,
    settings: state.settings,
    currentAccount: state.currentAccount,
    clanInfo: state.clans.items[clanId],
    selectedTab: state.ReducerClanWars.selectedTab,
    prepageStageTabIndex: state.ReducerClanWars.prepageStageTabIndex,
    gradesTableTabIndex: state.ReducerClanWars.gradesTableTabIndex,
    autoParticipation: state.ReducerClanWars.autoParticipation,
    isOnline: state.ReducerClanWars.isOnline,
    commonInfo: state.ReducerClanWars.commonInfo,
    stages: state.ReducerClanWars.stages,
    warSettings: state.ReducerClanWars.warSettings,
    roundType: state.ReducerClanWars.roundType,
    groupedBattles: state.ReducerClanWars.groupedBattles,
    enemyGrades: state.ReducerClanWars.enemyGrades,
    selectedClanRatingLeague: state.ReducerClanWars.selectedClanRatingLeague,
    searchInputValue: state.ReducerClanWars.searchInputValue,
    clanRatingList: state.ReducerClanWars.clanRatingList,
    clanRatingSearchList: state.ReducerClanWars.clanRatingSearchList,
    clanRatingAutocomplete: state.ReducerClanWars.clanRatingAutocomplete,
    selectedClanId: state.ReducerClanWars.selectedClanId,
    root: state.urls.root,
    isReady: state.ReducerClanWars.isReady,
    allPlayersInRatingTable: state.ReducerClanWars.allPlayersInRatingTable,
    restriction: state.ReducerClanWars.restriction,
    nextWeekStartDate: state.ReducerClanWars.nextWeekStartDate,
    lastRoundResults: state.ReducerClanWars.lastRoundResults,
    lastRoundResultsReady: state.ReducerClanWars.lastRoundResultsReady,
    lastRoundResultsHidden: state.ReducerClanWars.lastRoundResultsHidden,
    useAttempts: state.ReducerClanWars.useAttempts,
    isFromPort: state.ReducerClanWars.isFromPort,
    hasErrors: state.ReducerClanWars.hasErrors,
    ships: state.ReducerClanWars.ships,
    historyModalSlide: state.ReducerClanWars.historyModalSlide,
    roundsHistory: state.ReducerClanWars.roundsHistory,
    roundsHistoryCount: state.ReducerClanWars.roundsHistoryCount,
    roundsHistoryLimit: state.ReducerClanWars.roundsHistoryLimit,
    roundsHistoryOffset: state.ReducerClanWars.roundsHistoryOffset,
    isRoundsHistoryLoading: state.ReducerClanWars.isRoundsHistoryLoading,
  };
};

const mapDispatchToProps = (dispatch: IAppDispatch) => {
  return {
    setSelectedTab: (selectedTab: number) => {
      dispatch(actionsClanWars.setSelectedTab(selectedTab));
    },
    setPrepareStageTab: (prepageStageTabIndex: number) => {
      dispatch(actionsClanWars.setPrepareStageTab(prepageStageTabIndex));
    },
    init: (isReload: boolean) => {
      dispatch(init(isReload));
    },
    closeAllDialogs: () => {
      dispatch(closeAllDialogs());
    },
    showNationShipTypeStatistic: (...[nation, type]: string[]) => {
      dispatch(showCWarsStatsDialog(nation, type));
    },
    showAttemptsModal: (id: number, battles: IBattle[]) => {
      dispatch(showCWarsAttemptsDialog(id, battles));
    },
    hideAttemptsModal: () => {
      dispatch(hideDialog());
    },
    toggleAutoParticipation: () => {
      dispatch(toggleAutoParticipationThunk());
    },
    setGradesTableTab: (index: number) => {
      dispatch(actionsClanWars.setGradesTableTab(index));
    },
    setIsParticipant: (value: boolean) => {
      dispatch(setIsParticipant(value));
    },
    selectRatingLeague: (league: number) => {
      const data = league ? league : {};
      dispatch(actionsClanWars.selectRatingLeague(data));
      dispatch(clearSearchThunk());
    },
    searchInputChange: (value: string) => {
      dispatch(actionsClanWars.searchInputChange(value));
      if (value && value.length >= 2) {
        dispatch(actionsClanWars.ratingsAutocomplete(value));
      }
    },
    getRatings: (clanId?: number, league?: number) => {
      dispatch(getRatings(clanId, league));
    },
    ratingsSearch: (query: string) => {
      if (query && query.length > 1) {
        dispatch(actionsClanWars.ratingsSearch(query));
      }
    },
    clearSearch: () => {
      dispatch(clearSearchThunk());
    },
    showOwnClanPosition: () => {
      void playButtonClickSound();
      dispatch(showOwnClanPosition());
    },
    showSeasonLeaders: () => {
      void playButtonClickSound();
      showSeasonLeaders();
    },
    showCalendarModal: () => {
      void playButtonClickSound();
      dispatch(showCWarsCalendarDialog());
    },
    togglePlayersCountInRating: () => {
      dispatch(actionsClanWars.togglePlayersCountInRating());
    },
    hideLastRoundResults: () => {
      dispatch(hideLastRoundResultsThunk());
    },
    showLastRoundResults: () => {
      dispatch(actionsClanWars.showLastRoundResults());
    },
    setUseAttempts: (val: boolean) => {
      dispatch(setUseAttemptsThunk(val));
    },
    showHistoryModal: () => {
      void playButtonClickSound();
      dispatch(loadHistoryModalData());
      dispatch(
        showDialog({
          name: DIALOGS_NAMES.CWARS_HISTORY_DIALOG,
          data: {
            id: 'ClanWarsHistory',
          },
          options: {
            theme: DIALOGS_THEMES.BLUE,
            size: 'extra-large',
          },
        }),
      );
    },
    hideHistoryModal: () => {
      dispatch(actionsClanWars.unsetHistoryModalData());
      dispatch(hideDialog());
    },
    slideHistoryModal: (direction: 'left' | 'right') => {
      dispatch(actionsClanWars.slideHistoryModal(direction));
    },
    loadHistory: (limit: number, offset: number) => {
      dispatch(loadHistory(limit, offset));
    },
    refreshHistoryModal: () => {
      dispatch(refreshHistoryModal());
    },
  };
};

export default translate()(connect(mapStateToProps, mapDispatchToProps)(ContainerClanWars));
