import React from 'react';

import AboutClansSlider from '~/Components/AboutClansSlider/AboutClansSlider';

import styles from './About.scss';

const About: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <AboutClansSlider source={'about'} />
    </div>
  );
};

export default React.memo(About);
