import React, { Fragment, PureComponent } from 'react';
import { Currency, Divider, Input, Menu } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import { currencyNames } from '~/UIKit/Currency/Currency';

import styles from './Calculator.scss';

import type { CurrencyType } from '@wg/wows-react-uikit/components/Currency/Currency';
import type { ItemValue } from '@wg/wows-react-uikit/components/Menu/Menu';
import type { Balance } from '~/types/declaration';

export type PropsType = {
  distributionSum: number;
  userCount: number;
  totalBalanceList: Balance | number;
  selectedDistributionCurrency: number | CurrencyType;
  treasuryMinDistributionSumm: number;
  selectDistributionCurrency: (type: CurrencyType) => void;
  setDistributionSum: (sum: number) => void;
};

class Calculator extends PureComponent<PropsType, any> {
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = Number(e.target.value);
    if (val < 0 || isNaN(val)) {
      val = 0;
    }
    this.props.setDistributionSum(val);
  };

  onCurrencySelect = (value: ItemValue) => {
    const cur = `${value}` as CurrencyType;

    this.props.selectDistributionCurrency(cur);
  };

  render() {
    let selectedCurrency = this.props.totalBalanceList.filter((item) => {
      return item.type === this.props.selectedDistributionCurrency;
    })[0];
    if (!selectedCurrency) {
      selectedCurrency = this.props.totalBalanceList[0] ? this.props.totalBalanceList[0] : { type: 'oil', amount: 0 };
    }
    const isSingle = this.props.userCount === 1;
    const totalSum = Math.round(this.props.userCount * this.props.distributionSum);
    const total = selectedCurrency.amount;
    const rest = total - totalSum;
    if (rest < 0) {
      this.props.setDistributionSum(Math.floor(total / this.props.userCount));
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.row}>
          <div className={styles.label}>{t('Распределяемый ресурс')}</div>
          <div className={`${styles.resourse} ${styles.menuWrapper}`}>
            <Menu
              isSmallPadding={true}
              items={this.props.totalBalanceList
                .filter((item) => {
                  return item.canDistribute;
                })
                .map((item) => {
                  return {
                    value: item.type,
                    content: currencyNames[item.type],
                  };
                })}
              itemSelected={this.props.selectedDistributionCurrency}
              onItemChange={this.onCurrencySelect}
              dropdownPosition={null}
            >
              <div className={styles.menuCurrencyWrapper}>
                <Currency isFlat isBold amount={total} type={selectedCurrency.type} />
              </div>
            </Menu>
          </div>
        </div>
        <Divider type={'minor'} />
        {isSingle ? (
          <div className={styles.row}>
            <div className={styles.label}>{t('Начислить игроку')}</div>
            <div className={styles.resourseInput}>
              <Input
                error={''}
                help={''}
                info={''}
                label={''}
                maxLength={10}
                placeholder={''}
                value={`${this.props.distributionSum}`}
                width={'64'}
                noimesupport={false}
                isValidating={false}
                isCounted={false}
                isInlined={false}
                isTextCentered={false}
                isFocusOn={false}
                isDisabled={false}
                t={t}
                onBlur={() => {}}
                onChange={this.onChange}
                onFocus={() => {}}
                onKeyPress={() => {}}
              />
              <Currency isFlat isBold amount={0} type={selectedCurrency.type} onlyIcon />
            </div>
          </div>
        ) : (
          <>
            <div className={styles.row}>
              <div className={styles.label}>{t('Начислить каждому игроку')}</div>
              <div className={styles.resourseInput}>
                <Input
                  error={''}
                  help={''}
                  info={''}
                  label={''}
                  maxLength={10}
                  placeholder={''}
                  value={`${this.props.distributionSum}`}
                  width={'64px'}
                  noimesupport={false}
                  isValidating={false}
                  isCounted={false}
                  isInlined={false}
                  isTextCentered={false}
                  isFocusOn={false}
                  isDisabled={false}
                  t={t}
                  onBlur={() => {}}
                  onChange={this.onChange}
                  onFocus={() => {}}
                  onKeyPress={() => {}}
                />
                <Currency isFlat isBold amount={0} type={selectedCurrency.type} onlyIcon />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>{t('Будет распределено')}</div>
              <div className={styles.resourse}>
                <Currency isFlat isBold amount={totalSum} type={selectedCurrency.type} />
              </div>
            </div>
          </>
        )}

        <Divider type={'minor'} />
        <div className={styles.row}>
          <div className={styles.label}>{t('Остаток после распределения')}</div>
          <div className={styles.resourse}>
            <Currency isFlat isBold amount={rest} type={selectedCurrency.type} />
          </div>
        </div>
      </div>
    );
  }
}

export default Calculator;
