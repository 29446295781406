import { connect } from 'react-redux';

import { syncAccountInfo } from '~/Actions/ActionAccount';
import { waitForClanCreation } from '~/Actions/ActionClanCreate';

import { AccountInfoSyncManager } from '~/UIKit';

import type { IAppDispatch, RootState } from '~/store';

const mapStateToProps = (state: RootState) => ({
  isFetching: state.fetchingCounter.counter > 0,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({
  syncAccountInfo: () => dispatch(syncAccountInfo()),
  waitForClanCreation: () => dispatch(waitForClanCreation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfoSyncManager);
