export const snakeToCamel = (string: string) => {
  return string.replace(/(_[a-z])/g, (item) => item.toUpperCase().replace('_', ''));
};

export const trimAndRemoveSpaces = (value?: string) => {
  if (typeof value === 'string') {
    return value.trim().replace(/[ \t]{2,}/g, ' ');
  }
  return value;
};
