import * as React from 'react';

import { t } from '~/helpers/localization';

import styles from '~/UIKit/ClansRating/ClansRating.scss';
import LadderSeasonsDropdown from '~/UIKit/LadderSeasonsDropdown/LadderSeasonsDropdown';
import RealmDropdown from '~/UIKit/RealmDropdown/RealmDropdown';

type PropsType = {
  currentSeasonType?: string;
  currentSeason?: number;
  currentRealm?: string;
  onSeasonChange: (season, seasonType?: string) => void;
  isBrawl: boolean;
  onRealmChange: (realm: string) => void;
  clanHomeRealm?: string;
  clanBattleRealm?: string;
};

const SeasonsMenu = (props: PropsType) => {
  const {
    onSeasonChange,
    currentSeasonType,
    currentSeason,
    clanHomeRealm,
    clanBattleRealm,
    currentRealm,
    onRealmChange,
  } = props;
  const currentSeasonId = parseInt(`${currentSeason}`, 10);
  return (
    <div className={styles.season}>
      <div className={styles.clanRegularBadge}>
        <span>{t('Клановые бои')}</span>
      </div>
      <LadderSeasonsDropdown
        selectedSeasonId={currentSeasonId}
        selectedSeasonType={currentSeasonType}
        onSeasonChange={onSeasonChange}
      />
      <RealmDropdown
        currentSeasonId={currentSeasonId}
        clanHomeRealm={clanHomeRealm}
        clanBattleRealm={clanBattleRealm}
        itemSelected={currentRealm}
        onRealmChange={onRealmChange}
        isBrawl={false}
      />
    </div>
  );
};

export default SeasonsMenu;
