import filter from 'lodash/filter';
import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import React, { PureComponent } from 'react';
import { Currency, Dialog, ErrorLoad, Sticky, StickyContainer, Tabs } from '@wg/wows-react-uikit';

import settings from '~/settings';

import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { t } from '~/helpers/localization';
import { log } from '~/helpers/logging';

import { IS_DEV_MODE } from '~/Reducers/ReducerSupply';

import { getModifiersMap } from '~/Components/ViewClanWars/helpers';
import { COMPETITION_METRIC } from '~/UIKit';

import CWarsDebug from './CWarsDebug/CWarsDebug';
import ClanWarsWelcome from './ClanWarsWelcome';
import ClassNationHistoryTable from './ClassNationHistoryTable/ClassNationHistoryTable';
import LastRoundResults from './LastRoundResults/LastRoundResults';
import ResultsHeader from './ResultsHeader/ResultsHeader';
import ClanRatingTab from './Tabs/ClanRatingTab/ClanRatingTab';
import MyWarTab from './Tabs/MyWarTab/MyWarTab';
import RulesTab from './Tabs/RulesTab/RulesTab';
import styles from './ViewClanWars.scss';

import type { Tab } from '@wg/wows-react-uikit/components/Tabs/Tabs';
import type { ContainerClanWars_Props } from '~/Containers/ContainerClanWars';
import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';
import type { CompetitionMetric, IBattle } from '~/types/declaration';

export const getReachedGradeTooltipBody = (competitionMetric: CompetitionMetric) => {
  switch (competitionMetric) {
    case COMPETITION_METRIC.EXP: {
      return t('Количество чистого опыта, обеспечивающее увеличение результата клана');
    }
    case COMPETITION_METRIC.DAMAGE: {
      return t('Количество урона, обеспечивающее увеличение результата клана');
    }
    case COMPETITION_METRIC.RIBBONS: {
      return t('Количество лент, обеспечивающее увеличение результата клана');
    }
    default: {
      return '';
    }
  }
};

export const getBattleMeticText = (competitionMetric: CompetitionMetric) => {
  switch (competitionMetric) {
    case COMPETITION_METRIC.EXP: {
      return t('Опыт боя');
    }
    case COMPETITION_METRIC.DAMAGE: {
      return t('Урон за бой');
    }
    case COMPETITION_METRIC.RIBBONS: {
      return t('Лент за бой');
    }
    default: {
      return '';
    }
  }
};

export const getBattleMeticTooltipBody = (competitionMetric: CompetitionMetric) => {
  switch (competitionMetric) {
    case COMPETITION_METRIC.EXP: {
      return t('Чистый опыт полученный в бою, при попытке взять планку');
    }
    case COMPETITION_METRIC.DAMAGE: {
      return t('Количество урона, нанесенного в бою, при попытке взять планку');
    }
    case COMPETITION_METRIC.RIBBONS: {
      return t('Количество лент, полученных во время боя, при попытке взять планку');
    }
    default: {
      return '';
    }
  }
};

interface ViewClanWars_Props extends ContainerClanWars_Props {
  navigateToRecommendations: () => void;
  navigateToClan: (clanId: string) => void;
  topGradesIncrement: number;
  currentAccount: ICurrentAccountState;
}

class ViewClanWars extends PureComponent<ViewClanWars_Props> {
  componentDidMount() {
    dwhExport.push(DWH_EVENTS.NAVAL_BATTLES.OPEN_PAGE);
    log('naval_battles.open_page');
  }

  renderTab() {
    switch (this.props.selectedTab) {
      case 0: {
        if (!this.props.commonInfo.clan.settings.isParticipating || !this.props.clanId) {
          return (
            <ClanWarsWelcome
              autoParticipation={this.props.commonInfo.clan.settings.joinRoundAutomatically}
              toggleAutoParticipation={this.props.toggleAutoParticipation}
              setIsParticipant={this.props.setIsParticipant}
              currentAccount={this.props.currentAccount}
              clanId={this.props.clanId}
              navigateToRecommendations={this.props.navigateToRecommendations}
            />
          );
        } else {
          return (
            <MyWarTab
              currentAccount={this.props.currentAccount}
              prepageStageTabIndex={this.props.prepageStageTabIndex}
              gradesTableTabIndex={this.props.gradesTableTabIndex}
              setGradesTableTab={this.props.setGradesTableTab}
              setPrepareStageTab={this.props.setPrepareStageTab}
              autoParticipation={this.props.autoParticipation}
              toggleAutoParticipation={this.props.toggleAutoParticipation}
              closeAllDialogs={this.props.closeAllDialogs}
              showNationShipTypeStatistic={this.props.showNationShipTypeStatistic}
              roundType={this.props.roundType}
              showAttemptsModal={this.props.showAttemptsModal}
              hideAttemptsModal={this.props.hideAttemptsModal}
              showCalendarModal={this.props.showCalendarModal}
              togglePlayersCountInRating={this.props.togglePlayersCountInRating}
              setUseAttempts={this.props.setUseAttempts}
              allPlayersInRatingTable={this.props.allPlayersInRatingTable}
              clanInfo={this.props.clanInfo}
              commonInfo={this.props.commonInfo}
              stages={this.props.stages}
              warSettings={this.props.warSettings}
              groupedBattles={this.props.groupedBattles}
              enemyGrades={this.props.enemyGrades}
              restriction={this.props.restriction}
              nextWeekStartDate={this.props.nextWeekStartDate}
              useAttempts={this.props.useAttempts}
              isFromPort={this.props.isFromPort}
              showLastRoundResults={this.props.showLastRoundResults}
              lastRoundResults={this.props.lastRoundResults}
              ships={this.props.ships}
              showHistoryModal={this.props.showHistoryModal}
              hideHistoryModal={this.props.hideHistoryModal}
              roundsHistory={this.props.roundsHistory}
            />
          );
        }
      }
      case 1: {
        return (
          <ClanRatingTab
            settings={this.props.settings}
            currentAccount={this.props.currentAccount}
            selectedClanRatingLeague={this.props.selectedClanRatingLeague}
            clanRatingLeagues={this.props.warSettings.leagues}
            selectRatingLeague={this.props.selectRatingLeague}
            searchInputValue={this.props.searchInputValue}
            searchInputChange={this.props.searchInputChange}
            getRatings={this.props.getRatings}
            ratingsSearch={this.props.ratingsSearch}
            clanRatingList={this.props.clanRatingList}
            clanRatingSearchList={this.props.clanRatingSearchList}
            clanRatingAutocomplete={this.props.clanRatingAutocomplete}
            selectedClanId={this.props.selectedClanId}
            clearSearch={this.props.clearSearch}
            showOwnClanPosition={this.props.showOwnClanPosition}
            showSeasonLeaders={this.props.showSeasonLeaders}
            navigateToClan={this.props.navigateToClan}
            commonInfo={this.props.commonInfo}
          />
        );
      }
      case 2: {
        return (
          <RulesTab
            stages={this.props.stages}
            warSettings={this.props.warSettings}
            clanId={this.props.clanId}
            navigateToRecommendations={this.props.navigateToRecommendations}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  getNationShipTypeNextGrade(battles) {
    const { grades, topGradesIncrement } = this.props.warSettings.stages.war;
    let reachedGrade = null;
    let reachedGradeIndex = null;
    let nextGrade = null;

    battles.sort((a, b) => {
      return a.claimedGrade - b.claimedGrade;
    });

    if (!isEmpty(battles)) {
      reachedGrade = battles[battles.length - 1].claimedGrade;
      reachedGradeIndex = indexOf(grades, reachedGrade);

      nextGrade =
        reachedGradeIndex !== -1 && !isUndefined(grades[reachedGradeIndex + 1])
          ? grades[reachedGradeIndex + 1]
          : reachedGrade + this.props.topGradesIncrement;

      return nextGrade ? nextGrade : reachedGrade + topGradesIncrement;
    } else {
      return grades[0];
    }
  }

  renderNationShipTypeModalContent(battles) {
    const battlesWithReachedGrade = filter(battles, (battle) => {
      return battle.isReachedGrade || (battle.startedAt && !battle.finishedAt);
    });

    const sortedBattles = !isEmpty(battlesWithReachedGrade)
      ? battlesWithReachedGrade
          .filter((battle) => !!battle.ship)
          .sort((a, b) => {
            if (a.claimedGrade < b.claimedGrade) {
              return -1;
            }
            if (a.claimedGrade > b.claimedGrade) {
              return 1;
            }
            return 0;
          })
      : null;

    const lastBattle = sortedBattles ? sortedBattles[sortedBattles.length - 1] : null;

    return (
      <div>
        <ResultsHeader
          medalData={null}
          withMedal={false}
          params={[
            {
              value: this.getTotalStarsCount(battlesWithReachedGrade),
              label: t('Результат'),
              type: 'cwars-star',
              tooltipBody: t('Количество взятых планок'),
            },
            {
              value: lastBattle ? lastBattle.claimedGrade : '—',
              label: t('Лучшая планка'),
              type: Currency.metricIconName[this.props.warSettings.stages.preparation.competitionMetric],
              tooltipBody: t('Наивысшая взятая планка'),
            },
            {
              value: this.getNationShipTypeNextGrade(battlesWithReachedGrade),
              label: t('Следующая планка'),
              type: Currency.metricIconName[this.props.warSettings.stages.preparation.competitionMetric],
              tooltipBody: t('Ближайшая невзятая планка'),
            },
          ]}
        />
        <h2 className={styles.dialogHeader}>{t('Результативные попытки')}</h2>

        <ClassNationHistoryTable
          battles={battlesWithReachedGrade}
          grades={this.props.warSettings.stages.war.grades}
          topGradesIncrement={this.props.warSettings.stages.war.topGradesIncrement}
          ships={this.props.ships}
          competitionMetric={this.props.warSettings.stages.preparation.competitionMetric}
        />
      </div>
    );
  }

  getTotalStarsCount = (reachedBattles: Array<IBattle>) => {
    const modifiersMap = getModifiersMap(this.props.stages.war.modifiers);
    let starsCount = 0;
    reachedBattles.forEach((battle) => {
      const hasModifier = modifiersMap[battle.class + '::' + battle.nation] !== undefined;
      starsCount += hasModifier ? 2 : 1;
    });
    return starsCount;
  };

  getLastRoundModalContent() {
    return (
      <LastRoundResults
        data={this.props.lastRoundResults}
        hide={this.props.hideLastRoundResults}
        warSettings={this.props.warSettings}
        commonInfo={this.props.commonInfo}
      />
    );
  }

  reload = () => {
    this.props.init(true);
  };

  render() {
    if (this.props.hasErrors) {
      return (
        <ErrorLoad
          isFlat
          key="error-load"
          message={t('Произошла ошибка. Повторите попытку позже')}
          onReloadClick={this.reload}
        />
      );
    }

    if (!this.props.isReady && this.props.clanId) {
      return null;
    }

    const tabsItems: Array<Tab> = [{ value: '0', content: t('Морское сражение') }];

    tabsItems.push({ value: '1', content: t('Рейтинг кланов') });

    if (settings.cwars.isRulesShown) {
      tabsItems.push({ value: '2', content: t('Правила') });
    }

    return (
      <StickyContainer id={'stickyClanWars'}>
        <div className={styles.wrapper}>
          <div>
            <Sticky appContainerId={''} scrollContainerId={''} usedStickyContainerId={'stickyClanWars'} isActive={true}>
              <div className={`${styles.blurableTabs} ${this.props.isFromPort ? styles.isBluredTabs : ''}`}>
                <Tabs
                  isUppercase
                  smallHeight
                  tabSelected={`${this.props.selectedTab}`}
                  tabs={tabsItems}
                  onToggleTab={(tab: string) => {
                    this.props.setSelectedTab(parseInt(tab, 10));
                  }}
                />
              </div>
            </Sticky>
            {this.renderTab()}
          </div>

          {/* @TODO: move to dialogs */}
          <Dialog
            content={this.getLastRoundModalContent()}
            size={'extra-large'}
            isOverlay={false}
            isVisible={!isEmpty(this.props.lastRoundResults) && !this.props.lastRoundResultsHidden}
            hideDialog={this.props.hideLastRoundResults}
          />
        </div>
        {IS_DEV_MODE && <CWarsDebug />}
      </StickyContainer>
    );
  }
}

export default ViewClanWars;
