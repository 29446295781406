import classNames from 'classnames';
import get from 'lodash/get';
import some from 'lodash/some';

import * as React from 'react';
import { DivTooltip, Menu } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { TYPE_GLOBAL_SEARCH } from '~/constants';
import { excludedRealms, isGlobalSearchEnabled } from '~/helpers/ladder';
import { t } from '~/helpers/localization';

import { TooltipBody } from '~/UIKit/components';

import styles from './RealmDropdown.scss';

import type PropTypes from 'prop-types';

type PropTypes = {
  currentSeasonId?: number;
  clanHomeRealm: string;
  clanBattleRealm: string;
  onRealmChange: (realm: string) => void;
  itemSelected: string;
  isBrawl: boolean;
};

class RealmDropdown extends React.Component<PropTypes> {
  componentDidUpdate(prevProps: Readonly<PropTypes>): void {
    const realms = this._getAvailableRealms();

    if (prevProps.currentSeasonId !== this.props.currentSeasonId && !realms.includes(this.props.itemSelected)) {
      const recommendedRealm = realms.length > 1 ? TYPE_GLOBAL_SEARCH : realms[0];
      if (recommendedRealm) {
        this.props.onRealmChange(recommendedRealm);
      }
    }
  }

  _getItemContent(realm, tooltip = true) {
    const isGlobalRegion = realm === TYPE_GLOBAL_SEARCH;
    const isHomeRegion = realm === this.props.clanHomeRealm;
    const isBattleRegion = realm === this.props.clanBattleRealm && this.props.isBrawl;
    const isHomeNBattle = isHomeRegion && isBattleRegion;

    const name = isGlobalRegion ? t('Глобальный рейтинг') : settings.realmsMap[realm].name;

    const iconStyles = {};
    iconStyles[styles['icon-globe']] = isGlobalRegion;
    iconStyles[styles['icon-home']] = isHomeRegion;
    iconStyles[styles['icon-fight']] = isBattleRegion;
    iconStyles[styles['icon-home-fight']] = isHomeNBattle;
    iconStyles[styles['has-icon']] = some(Object.keys(iconStyles), (k) => iconStyles[k]);
    const className = classNames(styles['icon-wrapper'], iconStyles);

    const innerContent = <span className={className}>{t(`${name}`)}</span>;

    let tooltipContent = '';
    if (isHomeNBattle) tooltipContent = t('Домашний и боевой регион вашего клана');
    else if (isHomeRegion) tooltipContent = t('Домашний регион вашего клана');
    else if (isBattleRegion) tooltipContent = t('Боевой регион вашего клана');

    // TODO: Add disabled parameter to DivTooltip
    return tooltip && tooltipContent !== '' ? (
      <DivTooltip tooltipBody={<TooltipBody>{tooltipContent}</TooltipBody>}>{innerContent}</DivTooltip>
    ) : (
      innerContent
    );
  }

  _getAvailableRealms(): string[] {
    const currentSeasonId = this.props.currentSeasonId;
    const availableRealmsForSeason = get(settings, `ladder.seasons.${currentSeasonId}.realms`, []);

    return availableRealmsForSeason.filter((r) => !excludedRealms.includes(r));
  }

  _getItems() {
    const realmsList = [];

    Object.keys(settings.realmsMap).forEach((realm) => {
      if (!this._getAvailableRealms().includes(realm)) {
        return;
      }

      realmsList.push({
        value: realm,
        content: this._getItemContent(realm),
      });
    });

    if (isGlobalSearchEnabled && realmsList.length > 1) {
      realmsList.unshift({
        value: TYPE_GLOBAL_SEARCH,
        content: this._getItemContent(TYPE_GLOBAL_SEARCH),
      });
    }

    return realmsList;
  }

  render() {
    const realmList = this._getItems();

    return realmList.length > 1 ? (
      <div className={styles.container}>
        <Menu items={realmList} itemSelected={this.props.itemSelected} onItemChange={this.props.onRealmChange}>
          <div className={styles.label}>{this._getItemContent(this.props.itemSelected, false)}</div>
        </Menu>
      </div>
    ) : null;
  }
}

export default RealmDropdown;
