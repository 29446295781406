import classNames from 'classnames';
import * as React from 'react';

import styles from './ButtonScroll.scss';

interface IButtonToTop {
  onClick: () => void;
  isShowScrollToTop: boolean;
}

const ButtonScrollToTop: React.FC<IButtonToTop> = ({ onClick, isShowScrollToTop }) => {
  return (
    <div
      className={classNames(styles.button, {
        [styles.visible]: isShowScrollToTop,
      })}
      onClick={onClick}
    />
  );
};

export default ButtonScrollToTop;
