import * as React from 'react';

export enum KEYS_CODE {
  F5 = 'F5',
  ESC = 'Escape',
  ENTER = 'Enter',
  RIGHT = 'ArrowRight',
  LEFT = 'ArrowLeft',
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
}

const useKeyDown = function (
  callback: (keyCode: KEYS_CODE, event: KeyboardEvent) => void,
  keyCodes: KEYS_CODE[] = [],
  isPassive: boolean = false,
) {
  React.useEffect(() => {
    function _callback(event: KeyboardEvent) {
      const eventKeyCode = event.key as KEYS_CODE;
      if (keyCodes.includes(eventKeyCode) || !keyCodes.length) {
        isPassive && event.preventDefault();
        callback?.(eventKeyCode, event);
      }
    }

    document.addEventListener('keydown', _callback, isPassive);

    return () => {
      document.removeEventListener('keydown', _callback);
    };
  });
};

export default useKeyDown;
