import * as React from 'react';
import { translate } from 'react-i18next';
import Link from 'react-router/lib/Link';

import { playButtonSound } from '~/web2ClientAPI/web2ClientAPI';

import styles from './NavClanProfile.scss';

type PropsType = {
  root: string;
  clanId: string;
  t: (text: string) => string;
};

class NavClanProfile extends React.PureComponent<PropsType> {
  constructor(props) {
    super(props);
  }

  onRouteClick() {
    playButtonSound();
  }

  render() {
    const t = this.props.t;

    return (
      <nav className={styles.nav}>
        <Link
          to={`${this.props.root}clan-profile/${this.props.clanId}${window.location.search}`}
          className={styles.link}
          activeClassName={styles.linkCurrent}
          onClick={this.onRouteClick.bind(this)}
        >
          {t('Военная база')}
        </Link>
        <Link
          to={`${this.props.root}clan-profile/${this.props.clanId}/members${window.location.search}`}
          className={styles.link}
          activeClassName={styles.linkCurrent}
          onClick={this.onRouteClick.bind(this)}
        >
          {t('Состав клана')}
        </Link>
      </nav>
    );
  }
}

export default translate()(NavClanProfile);
