import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { PureComponent } from 'react';
import {
  ClanRole,
  DivTooltip,
  IconTable,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
  TooltipBody,
} from '@wg/wows-react-uikit';

import { isoToFormattedLocalDateTime } from '~/helpers/datetime';
import { thousands, toShipLevel } from '~/helpers/formatting';
import { t } from '~/helpers/localization';

import styles from './ClassNationHistoryTable.scss';
import { getBattleMeticText, getBattleMeticTooltipBody, getReachedGradeTooltipBody } from '../ViewClanWars';

import type { CompetitionMetric, Ship } from '~/Reducers/ReducerClanWars';

const GRADE_INCREMENT_COUNT = 2;

const CLASS_NATION_HISTORY_TABLE_HEAD_DATA = (competitionMetric) => {
  return [
    {
      modify: 'small',
      type: 'role',
      tooltipBody: t('Должность'),
    },
    {
      modify: 'basis',
      type: 'nick',
      text: t('Игрок'),
    },
    {
      modify: 'right',
      type: 'goal',
      text: t('Планка'),
      tooltipBody: getReachedGradeTooltipBody(competitionMetric),
    },
    {
      modify: 'right',
      type: 'battleExp',
      text: getBattleMeticText(competitionMetric),
      tooltipBody: getBattleMeticTooltipBody(competitionMetric),
    },
    {
      modify: 'right',
      type: 'shipCd',
      text: t('Корабль'),
      tooltipBody: t('Корабль, на котором была сделана попытка взять планку'),
    },
    {
      modify: 'right',
      type: 'date',
      text: t('Дата боя'),
      tooltipBody: t('Дата боя с попыткой взять планку'),
    },
  ];
};

type Rows = Array<{
  role?: string;
  nick: string;
  claimedGrade: number;
  isReachedGrade?: boolean;
  rawValue?: number;
  ship: {
    level: number;
    cd: number;
  } | null;
  finishedAt?: string;
  startedAt?: string;
  accountRole?: string;
}>;

export type Props = {
  battles: Rows;
  grades: Array<any>;
  topGradesIncrement: number;
  ships: {
    [shipId: number]: Ship;
  };
  competitionMetric: CompetitionMetric;
};

class ClassNationHistoryTable extends PureComponent<Props> {
  renderTableHead() {
    return map(CLASS_NATION_HISTORY_TABLE_HEAD_DATA(this.props.competitionMetric), (cell, key) => {
      return (
        <TableHeadCell key={`head-cell-${key}`} modify={cell.modify}>
          {cell.tooltipBody ? (
            <DivTooltip tooltipBody={<TooltipBody>{cell.tooltipBody}</TooltipBody>}>
              {cell.type === 'role' ? <IconTable glyph="roles" /> : cell.text}
            </DivTooltip>
          ) : cell.type === 'role' ? (
            <IconTable glyph="roles" />
          ) : (
            cell.text
          )}
        </TableHeadCell>
      );
    });
  }

  renderTableBody() {
    const { grades, topGradesIncrement, battles } = this.props;

    const lastGrade = grades[grades.length - 1];
    const lastReachedGrade = !isEmpty(battles) ? battles[battles.length - 1].claimedGrade : 0;

    forEach(grades, (grade) => {
      if (grade > lastReachedGrade) {
        battles.push({
          nick: '—',
          ship: null,
          claimedGrade: grade,
        });
      }
    });

    if (topGradesIncrement !== 0 && lastReachedGrade >= lastGrade) {
      battles.push({
        nick: '—',
        ship: null,
        claimedGrade: lastReachedGrade + topGradesIncrement,
      });
    }

    battles.sort((a, b) => {
      return b.claimedGrade - a.claimedGrade;
    });

    const highestGrade = battles[0].claimedGrade;

    for (let index = 0; index < GRADE_INCREMENT_COUNT; index++) {
      const i = index + 1;
      battles.unshift({
        nick: '—',
        ship: null,
        claimedGrade: highestGrade + topGradesIncrement * i,
      });
    }

    return map(battles, (row, index) => {
      const isInBattle = row.startedAt && !row.finishedAt;

      return (
        <TableRow isDisabled={!row.isReachedGrade} key={`war-table-row-${index}`}>
          <TableBodyCell modify="small" isHovering={false} onClick={() => {}}>
            <ClanRole role={row.accountRole} />
          </TableBodyCell>
          <TableBodyCell modify="basis" isHovering={false} onClick={() => {}}>
            <div>{row.nick}</div>
          </TableBodyCell>
          <TableBodyCell modify="right" isHovering={false} onClick={() => {}}>
            {thousands(row.claimedGrade)}
          </TableBodyCell>
          <TableBodyCell modify="right" isHovering={false} onClick={() => {}}>
            {thousands(row.rawValue)}
          </TableBodyCell>
          <TableBodyCell modify="right" isHovering={false} onClick={() => {}}>
            {row.ship ? (
              <div className={styles.shipLevelName}>
                <span className={styles.shipLevel}>{toShipLevel(row.ship.level)}</span>
                {this.props.ships && this.props.ships[row.ship.cd]
                  ? this.props.ships[row.ship.cd].short_name
                  : row.ship.cd}
              </div>
            ) : (
              '—'
            )}
          </TableBodyCell>
          <TableBodyCell modify="right" isHovering={false} onClick={() => {}}>
            {isInBattle ? t('Сейчас в бою') : isoToFormattedLocalDateTime(row.finishedAt)}
          </TableBodyCell>
        </TableRow>
      );
    });
  }

  render() {
    return (
      <Table>
        <TableHead>{this.renderTableHead()}</TableHead>
        <TableBody>{this.renderTableBody()}</TableBody>
      </Table>
    );
  }
}

export default ClassNationHistoryTable;
