import cssmediaQueries from './cssMediaQueries';

const VIEWPORT_SIZE_TYPES = Object.freeze({
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRA: 'extra',
});

const dimensionsByViewportTypes = Object.freeze({
  [VIEWPORT_SIZE_TYPES.SMALL]: [cssmediaQueries.smallBottomBound, cssmediaQueries.smallUpperBound],
  [VIEWPORT_SIZE_TYPES.MEDIUM]: [cssmediaQueries.mediumBottomBound, cssmediaQueries.mediumUpperBound],
  [VIEWPORT_SIZE_TYPES.LARGE]: [cssmediaQueries.largeBottomBound, cssmediaQueries.largeUpperBound],
  [VIEWPORT_SIZE_TYPES.EXTRA]: [cssmediaQueries.extraBottomBound, cssmediaQueries.extraUpperBound],
});

type ViewportType = keyof typeof dimensionsByViewportTypes;

export const getTypeOfViewportSize = (): ViewportType | undefined => {
  const winWidth = window.innerWidth;

  for (const sizeType of Object.keys(dimensionsByViewportTypes) as ViewportType[]) {
    const dimensions = dimensionsByViewportTypes[sizeType];
    if (winWidth >= dimensions[0] && winWidth <= dimensions[1]) {
      return sizeType;
    }
  }
};
