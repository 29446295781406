import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Interpolate, translate } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { ErrorLoad } from '@wg/wows-react-uikit';

import { t, x } from '~/helpers/localization';
import { useAppDispatch, useAppSelector } from '~/store';
import { fetchMembers } from '~/Actions/ActionClanProfile';

import styles from './CStarsProgress.scss';
import ProgressBar from './ProgressBar/ProgressBar';

import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    accountClanstars: state.ReducerClanStars.accountClanstars,
    accountRewards: state.ReducerClanStars.accountRewards,
    clanId: state.currentAccount.clanId,
    rewardsInfo: state.ReducerClanStars.rewardsInfo,
  };
};

const CStarsProgress: React.FC = () => {
  const { accountClanstars, accountRewards, clanId, rewardsInfo } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const retryFetchMembers = React.useCallback(() => {
    if (clanId) {
      void dispatch(fetchMembers(clanId, true));
    }
  }, [clanId, dispatch]);

  return (
    <div>
      <h2 className={styles.title}>{t('Получайте награды')}</h2>
      <h3 className={styles.text}>
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('За заработанные звёзды вы будете получать награды. Звёзд получено: %(stars)s')}
          stars={<span className={styles.star}>{accountClanstars.length}</span>}
          parent={'span'}
          t={t}
        />
      </h3>
      {isEmpty(rewardsInfo) ? (
        <ErrorLoad
          isFlat
          key="error-load"
          message={t('Произошла ошибка. Повторите попытку позже')}
          onReloadClick={retryFetchMembers}
        />
      ) : (
        <ProgressBar
          accountRewards={accountRewards}
          clanstarsCount={accountClanstars.length}
          rewardsInfo={rewardsInfo}
        />
      )}
    </div>
  );
};

// @TODO: update i18n so untyped `translate` HOC would not be needed
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export default React.memo(translate()(CStarsProgress) as React.FC);
