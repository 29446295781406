import classNames from 'classnames';
import React, { PureComponent } from 'react';

import settings from '~/settings';

import styles from './CookieButton.scss';

const isInGame = window.navigator.userAgent.indexOf(settings.ingameUserAgent) !== -1;

class CookieButton extends PureComponent {
  render() {
    return isInGame ? null : (
      <span id="ot-sdk-btn" className={classNames('ot-sdk-show-settings', styles.cookieButton)} />
    );
  }
}

export default CookieButton;
