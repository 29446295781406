import type { InferActionsType } from '~/Reducers';

export const START_FETCHING = 'START_FETCHING';
export const STOP_FETCHING = 'STOP_FETCHING';

export type ActionsType = InferActionsType<typeof actionsProcessing>;

export const actionsProcessing = {
  startFetching: (text?: string) =>
    ({
      type: START_FETCHING,
      text,
    }) as const,
  stopFetching: (text?: string) =>
    ({
      type: STOP_FETCHING,
      text,
    }) as const,
};
