import { hasCrossRealm } from '~/settings';
import { t } from '~/helpers/localization';

const br = '<br/><br/>';

export default function (isCrossRealm, isOld, isBrawl) {
  return [
    {
      modify: 'small',
      name: 'rank',
      // text: t('Место'),
      glyph: 'rank',
      tooltipText: t('Место клана в выбранном рейтинге и сезоне:tooltip'),
    },
    {
      modify: 'compact',
      name: 'realm',
      isHidden: !hasCrossRealm,
      text: t('Реалм'),
    },
    {
      modify: 'basis',
      name: 'clan',
      text: t('Клан'),
    },
    {
      isHidden: isBrawl,
      glyph: 'rating',
      modify: 'right',
      name: 'rating',
      text: 'CR',
      tooltipParam: 'CR &mdash; Clan Rating',
      tooltipText: t('Рейтинг клана в выбранном дивизионе:tooltip'),
    },
    {
      isHidden: isBrawl,
      glyph: 'battles',
      modify: 'right',
      name: 'battlesCount',
      text: 'BTL',
      tooltipParam: 'BTL &mdash; Battles',
      tooltipText: t('Количество боев в выбраном сезоне клановых боев:tooltip'),
    },
    {
      isHidden: isOld || isBrawl,
      glyph: 'last-battle-time',
      modify: 'right',
      name: 'lastBattle',
      text: 'LBT',
      tooltipParam: 'LBT &mdash; Last Battle Time',
      tooltipText: t('Время последнего боя в режиме клановых боев:tooltip'),
    },
    {
      isHidden: !isBrawl,
      modify: 'right',
      name: 'winsCount',
      text: 'WINS',
      tooltipParam: '',
      tooltipText:
        t('Количество побед') + br + t('Выигрывает клан, набравший больше всех побед. Поражения не учитываются.'),
    },
    {
      isHidden: !isBrawl,
      glyph: 'last-win-time',
      modify: 'right',
      name: 'lastWin',
      text: 'LWT',
      tooltipParam: 'LWT &mdash; Last Win Time',
      tooltipText:
        t('Время последней победы в режиме клановых боев:tooltip') +
        br +
        t(
          'Если несколько кланов набрали одинаковое количество побед, то учитывается время выигрышей. Первое место в рейтинге получает клан, победивший раньше остальных.',
        ),
    },
    {
      isHidden: isOld || isBrawl,
      glyph: 'members-count',
      modify: 'right',
      name: 'members',
      text: 'PLR',
      tooltipParam: 'PLR &mdash; Players',
      tooltipText: t('Количество игроков в клане:tooltip'),
    },
  ];
}
