import classNames from 'classnames';
import React from 'react';
import { Button, CheckboxWithLabel, Spinner, Toggle } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';
import { isChinaRealm } from '~/helpers/realm';
import { PURPOSES } from '~/Actions/ActionRecommendations';

import Flags from '~/Components/RecommendationsCards/Flag/Flags';

import styles from './CategoryCard.scss';

type ICategoryCardProps = {
  cardClassName?: string;
  isFetching: boolean;
  banner: PURPOSES;
  title: string;
  text: string;
  languages: string[];
  isSettingsEnabled: boolean;
  useVoiceChat: boolean;
  updateUseVoiceChat: (useVoiceChat: boolean) => void;
  modifyLanguageSettings: () => void;
  onButtonClick: () => void;
};

const CategoryCard: React.FC<ICategoryCardProps> = ({
  banner = PURPOSES.NO_OBLIGATIONS,
  cardClassName = undefined,
  isFetching = false,
  isSettingsEnabled = true,
  languages = [],
  text = '',
  title = '',
  useVoiceChat = false,
  modifyLanguageSettings = () => {},
  onButtonClick = () => {},
  updateUseVoiceChat = () => {},
}) => {
  const handleModifyLanguageSettings = (event?: React.MouseEvent<Element, MouseEvent>) => {
    event?.stopPropagation();

    modifyLanguageSettings();
  };

  const renderSettings = () => {
    if (!isSettingsEnabled) {
      return null;
    }

    const voiceCheckbox = isFetching ? (
      <span>
        <Spinner />
        {' ' + t('Сохранение настроек')}
      </span>
    ) : (
      <CheckboxWithLabel
        isChecked={useVoiceChat}
        onChange={updateUseVoiceChat}
        labelText={t('Готов использовать голосовой чат')}
      />
    );

    return (
      <>
        <h6 className={styles.settingsTitle}>{t('Общение')}</h6>
        <div className={classNames(styles.settingsInputGroup, styles.settingsLanguages)}>
          {isChinaRealm() ? null : (
            <>
              <Flags onClick={(e) => handleModifyLanguageSettings(e)} languages={languages} maxDisplayCount={4} />
              <div className={styles.modifyButton}>
                <Toggle onClick={(e) => handleModifyLanguageSettings(e)} caption={t('Изменить')} />
              </div>
            </>
          )}
        </div>
        <div className={styles.settingsInputGroup}>{voiceCheckbox}</div>
      </>
    );
  };

  return (
    <div className={classNames(styles.card, cardClassName)}>
      <div className={classNames(styles.banner, styles['banner_' + banner])} />
      <div className={styles.content}>
        <header>
          <h5 className={styles.title}>{title}</h5>
          <p className={styles.text}>{text}</p>
        </header>
        <div className={styles.settings}>
          {renderSettings()}
          <div className={styles.submit}>
            <Button isLarger isJustified isFlat onClick={onButtonClick}>
              {t('Подобрать клан')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CategoryCard);
