import settings from '~/settings';

const getSeasonIconUrl = (id: number): string => {
  const mediaPath = settings.mediaPath;
  const achievementsData = settings.achievements?.data;
  const iconId = settings.ladder?.seasons?.[id]?.ladder_settings?.first_place_achievement_id || 0;

  return mediaPath + achievementsData?.[iconId]?.icons?.normal || 'unknown';
};

export default getSeasonIconUrl;
