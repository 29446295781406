import React from 'react';
import Divider from '@wg/wows-react-uikit/components/Divider/Divider';

import { t } from '~/helpers/localization';

import Countdown from '~/Components/UIKit/Countdown/Countdown';

import styles from './EventCountdown.scss';
import Icon from './images/icon_countdown.svg';

type ICountdown = {
  date: number;
};

const EventCountdown: React.FC<ICountdown> = ({ date }) => {
  return (
    <div className={styles.wrapper}>
      <Divider type={'major_light'} />
      <div className={styles.message}>
        <img className={styles.icon} src={Icon} width={13} height={13} />
        {t('Ends in:')}
        &nbsp;
        <Countdown date={date} />
      </div>
      <Divider type={'major_light'} />
    </div>
  );
};

export default EventCountdown;
