import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { playButtonClickSound } from '@wg/web2clientapi/sound';

import { SEASON_TYPES } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { t } from '~/helpers/localization';
import { actionsAutocomplete } from '~/Actions/ActionAutocomplete';
import { toggleCurrentSeason } from '~/Actions/ActionClanRating';
import { actionsSearch } from '~/Actions/ActionSearch';

import ButtonScrollToBottom from '~/Components/ViewClanBattles/ClansBattlesHallFame/ButtonScrollToBottom';
import ButtonScrollToTop from '~/Components/ViewClanBattles/ClansBattlesHallFame/ButtonScrollToTop';
import TopWinners from '~/Components/ViewClanBattles/ClansBattlesHallFame/TopWinners/TopWinners';
import { Title } from '~/UIKit';

import styles from './ClansBattlesHallFame.scss';

import type { OwnClan, Winner } from '~/Reducers/ReducerHallFame';

const heightPadding = 100;
const countShowSeason = 1;
const whenShowScrollToTop = 3;

interface ClansBattlesHallFame_Props {
  winners: {
    [season: string]: Array<Winner>;
  };
  ratings: {
    [season: string]: Array<OwnClan>;
  };
  seasonName: {
    [season: string]: string;
  };
  setSelectedTab: (selectedTab: string) => void;
  onSeasonChange: (numberSeason: number) => void;
  ownClansTag: string;
  ownClansName: string;
  realmsMap: {
    [realm: string]: {
      [name: string]: string;
    };
  };
}

interface ClansBattlesHallFame_State {
  blockHeight: number;
  lastPosition: number;
  scrolledSeason: number;
  countSeasons: number;
  scrollerHeight: number;
  shiftY: number;
  isHideIconScroll: boolean;
  isShowScrollToTop: boolean;
  isMoving: boolean;
  timer: number;
  top: number;
}

class ClansBattlesHallFame extends PureComponent<ClansBattlesHallFame_Props, ClansBattlesHallFame_State> {
  private scrollList: React.RefObject<HTMLDivElement>;
  private scroller: React.RefObject<HTMLDivElement>;
  private scrollBar: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.scrollList = React.createRef();
    this.scroller = React.createRef();
    this.scrollBar = React.createRef();

    this.state = {
      blockHeight: 0,
      scrolledSeason: 0,
      shiftY: 0,
      scrollerHeight: 0,
      timer: 0,
      lastPosition: 0,
      top: 0,
      isHideIconScroll: false,
      isShowScrollToTop: false,
      isMoving: false,
      countSeasons: Object.keys(this.props.seasonName).length,
    };
  }

  componentDidMount() {
    this.updateDimensions();
    this.setState({
      countSeasons: Object.keys(this.props.seasonName).length,
    });
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
    document.addEventListener('wheel', this.onWheel);
    window.addEventListener('resize', this.updateDimensions);

    dwhExport.push(DWH_EVENTS.CLAN_BATTLES.OPEN_HALL_OF_FAME);
  }

  componentDidUpdate(prevProps: Readonly<ClansBattlesHallFame_Props>) {
    if (!isEqual(prevProps.seasonName, this.props.seasonName)) {
      const countSeasons = Object.keys(this.props.seasonName).length;
      this.setState({
        countSeasons,
        scrollerHeight: this.scrollBar.current.clientHeight / countSeasons,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('wheel', this.onWheel);
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({
      blockHeight: this.scrollList.current.scrollHeight,
      scrollerHeight: this.scrollBar.current.clientHeight / this.state.countSeasons,
    });
  };

  animationScroll = (top: number, srollerTop: number): void => {
    this.scrollList.current.style.transform = `translateY(${top}px)`;

    let moveScroller = srollerTop;

    if (srollerTop > this.scrollBar.current.clientHeight - this.state.scrollerHeight) {
      moveScroller = this.scrollBar.current.clientHeight - this.state.scrollerHeight;
    }

    if (srollerTop <= 0) {
      moveScroller = 0;
    }

    this.scroller.current.style.top = `${moveScroller}px`;
  };

  onWheel = (event) => {
    const delta = event.wheelDeltaY > 0 ? 180 : -180;
    const { countSeasons, blockHeight, lastPosition, scrollerHeight } = this.state;
    const { clientHeight } = this.scrollBar.current;

    let top: number = this.state.top + delta;
    if (top < -blockHeight + blockHeight / countSeasons) {
      top = -blockHeight + blockHeight / countSeasons;
    }

    if (top > 0) {
      top = 0;
    }
    const srollerTop = (-top / (blockHeight - this.scrollList.current.clientHeight)) * (clientHeight - scrollerHeight);
    this.animationScroll(top, srollerTop);

    const directionDown = delta < 0;
    let scrolledSeason = -lastPosition / (blockHeight / countSeasons);
    scrolledSeason = directionDown ? Math.ceil(scrolledSeason) : Math.floor(scrolledSeason);
    scrolledSeason = scrolledSeason <= countSeasons ? scrolledSeason : countSeasons - 1;

    const isHideIconScroll = scrolledSeason > countShowSeason;
    const isShowScrollToTop = scrolledSeason >= whenShowScrollToTop;

    clearTimeout(this.state.timer);
    const timer = setTimeout(() => {
      const top = scrolledSeason * (-blockHeight / countSeasons);
      const srollerTop = scrolledSeason * (clientHeight / countSeasons);
      this.animationScroll(top, srollerTop);
    }, 1000);

    this.setState({
      lastPosition: top,
      isHideIconScroll,
      timer: Number(timer),
      isShowScrollToTop,
      top,
    });
  };

  onMouseMove = (event) => {
    if (this.state.isMoving) {
      let srollerTop = event.clientY - this.state.shiftY - this.scrollBar.current.getBoundingClientRect().top;
      const { blockHeight, countSeasons } = this.state;
      const { scrollHeight } = this.scrollList.current;
      const topEdge = this.scrollBar.current.clientHeight - heightPadding;

      if (srollerTop < 0) {
        srollerTop = 0;
      }
      if (srollerTop > topEdge) {
        srollerTop = topEdge;
      }

      const percentScroll = srollerTop / topEdge;
      const top = (-blockHeight + scrollHeight / countSeasons) * percentScroll;
      this.animationScroll(top, srollerTop);

      this.setState({
        lastPosition: top,
        top,
      });
    }
  };

  onMouseUp = () => {
    if (this.state.isMoving) {
      this.scroller.current.style.transition = 'top 0.8s';
      this.scrollList.current.style.transition = `transform 1000ms`;
      const { countSeasons, blockHeight, lastPosition } = this.state;

      let scrolledSeason = Math.round(-lastPosition / (this.scrollList.current.scrollHeight / countSeasons));
      scrolledSeason = scrolledSeason <= countSeasons ? scrolledSeason : countSeasons - 1;
      const isHideIconScroll = scrolledSeason > countShowSeason;
      const isShowScrollToTop = scrolledSeason >= whenShowScrollToTop;

      const top = scrolledSeason * (-blockHeight / countSeasons);
      const srollerTop = scrolledSeason * (this.scrollBar.current.clientHeight / countSeasons);

      this.animationScroll(top, srollerTop);
      this.setState({
        isMoving: false,
        scrolledSeason,
        isHideIconScroll,
        isShowScrollToTop,
        lastPosition: top,
        top,
      });
    }
  };

  onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.scroller.current.style.transition = 'none';
    this.scrollList.current.style.transition = `transform 0ms`;

    this.setState({
      shiftY: event.clientY - this.scroller.current.getBoundingClientRect().top,
      isMoving: true,
    });
  };

  clickIcon = () => {
    const { countSeasons, blockHeight, lastPosition } = this.state;
    let scrolledSeason = Math.round(-lastPosition / (this.scrollList.current.scrollHeight / countSeasons)) + 1;
    scrolledSeason = scrolledSeason < countSeasons ? scrolledSeason : countSeasons - 1;
    const top = scrolledSeason * (-blockHeight / countSeasons);
    const srollerTop = scrolledSeason * (this.scrollBar.current.clientHeight / countSeasons);
    const isHideIconScroll = scrolledSeason > countShowSeason;
    const isShowScrollToTop = scrolledSeason >= whenShowScrollToTop;
    this.animationScroll(top, srollerTop);
    this.setState({
      scrolledSeason,
      isHideIconScroll,
      isShowScrollToTop,
      lastPosition: top,
      top,
    });
  };

  scrollToTop = () => {
    void playButtonClickSound();
    this.animationScroll(0, 0);
    this.setState({
      scrolledSeason: 0,
      isHideIconScroll: false,
      isShowScrollToTop: false,
      lastPosition: 0,
      top: 0,
    });
  };

  render() {
    const { seasonName, ownClansTag, ownClansName, ratings, setSelectedTab, realmsMap, onSeasonChange } = this.props;
    const { scrollerHeight, isHideIconScroll, isShowScrollToTop } = this.state;
    const winnersByArray = [];
    for (const seasonWinner in this.props.winners) {
      winnersByArray.push(this.props.winners[seasonWinner]);
    }

    const sliderStyles = {
      height: scrollerHeight,
    };

    return (
      <>
        <video className={styles.video} autoPlay muted loop id="myVideo">
          <source src="https://wows-media-content.gcdn.co/wsclans/3009/sparks.webm" type="video/mp4" />
          <source src="https://wows-media-content.gcdn.co/wsclans/3009/sparks.mp4" type="video/mp4" />
        </video>
        <Title className={styles.title}>{t('Поздравляем лидеров сезона в своих регионах!')}</Title>
        <div className={styles.wrapper}>
          <div className={styles.scrollbar} ref={this.scrollBar}>
            <div className={styles.slider} style={sliderStyles} ref={this.scroller} onMouseDown={this.onMouseDown} />
          </div>
          <div className={styles.seasonList} ref={this.scrollList}>
            {winnersByArray.reverse().map((season) => (
              <TopWinners
                key={season[0].season_id}
                season={season}
                seasonName={seasonName}
                ownClansTag={ownClansTag}
                ownClansName={ownClansName}
                ratings={ratings[season[0].season_id]}
                setSelectedTab={setSelectedTab}
                onSeasonChange={onSeasonChange}
                realmsMap={realmsMap}
              />
            ))}
          </div>
          <ButtonScrollToBottom onClick={this.clickIcon} isHideIconScroll={isHideIconScroll} />
        </div>
        <ButtonScrollToTop onClick={this.scrollToTop} isShowScrollToTop={isShowScrollToTop} />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const localization = get(state, ['settings', 'realm', 'languageCodeGloss']);
  const clanId = get(state, ['currentAccount', 'clanId']);
  const seasonName = {};
  const seasons = get(state, ['settings', 'ladder', 'seasons']);

  const winnersList = get(state, ['ReducerHallFame', 'winnersList']);
  const hasStatisticsForSeason = (seasonNumber) => winnersList[Number(seasonNumber)] !== undefined;

  for (const seasonNumber in seasons) {
    const season = seasons[seasonNumber];
    if (hasStatisticsForSeason(seasonNumber) && get(season, 'kind') === SEASON_TYPES.REGULAR) {
      seasonName[seasonNumber] = get(seasons, [`${seasonNumber}`, 'localization', 'title', `${localization}`]);
    }
  }

  return {
    winners: get(state, ['ReducerHallFame', 'winnersList']),
    ratings: get(state, ['ReducerHallFame', 'ratings']),
    seasonName,
    ownClansTag: get(state, ['clans', 'items', `${clanId}`, 'tag']),
    ownClansName: get(state, ['clans', 'items', `${clanId}`, 'name']),
    realmsMap: get(state, ['settings', 'realmsMap']),
    setSelectedTab: ownProps.setSelectedTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSeasonChange: (season) => {
      dispatch(actionsSearch.changeTerm(''));
      dispatch(toggleCurrentSeason(season));
      dispatch(actionsAutocomplete.clearAutocompleteState());
      dispatch(actionsSearch.clearSearchState());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClansBattlesHallFame);
