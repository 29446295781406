import * as React from 'react';

import { t } from '~/helpers/localization';
import { useAppDispatch } from '~/store';
import { removeMembers } from '~/Actions/ActionClanProfile';

import MembersOperationDialog from './MembersOperationDialog';

import type { IClanMember, IDialog } from '~/types/declaration';

type IRemoveMembersOperationDialog = IDialog<{
  membersToRemove: IClanMember[];
}>;

const RemoveMembersOperationDialog: React.FC<IRemoveMembersOperationDialog> = ({ data, hideDialog }) => {
  const { membersToRemove } = data;
  const dispatch = useAppDispatch();

  const onRemoveClick = React.useCallback(() => {
    return dispatch(removeMembers(membersToRemove.map((m) => m.id))).then(() => {
      hideDialog();
    });
  }, [dispatch, membersToRemove, hideDialog]);

  return (
    <MembersOperationDialog
      buttonActionTitle={t('Исключить')}
      buttonCloseTitle={t('Закрыть')}
      controlElement={null}
      membersForOperate={membersToRemove}
      spinnerLabel={t('Исключаем из клана')}
      textBeforeMembers={t('Исключить игроков из клана:')}
      title={t('Исключение из клана')}
      onActionClick={onRemoveClick}
      onCloseClick={hideDialog}
    />
  );
};

export default React.memo(RemoveMembersOperationDialog);
