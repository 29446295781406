import classNames from 'classnames';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import * as React from 'react';
import { translate } from 'react-i18next';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import { ErrorLoad, Link, StepCSSTransitionGroup } from '@wg/wows-react-uikit';

import { SEASON_TYPES } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { getLastBrawlSeason, getLastRegularSeason } from '~/helpers/ladder';
import { getLocalizationSafe, t } from '~/helpers/localization';
import { isQualifiedByBestSeasonTeam } from '~/helpers/seasons';

import { ClansRatingSearchResult } from '~/UIKit';
import ClansRatingData from '~/UIKit/ClansRating/ClanRatingData';
import RatingTableNav from '~/UIKit/ClansRating/RatingTabNav';
import SeasonsMenu from '~/UIKit/ClansRating/SeasonsMenu';
import { ClanSearchInput } from '~/UIKit/components';

import styles from './ClansRating.scss';

import type { ClanInfoType, WowsLadderType } from '~/Actions/ActionClanProfile';
import type { LeagueDivisionRatingType } from '~/Actions/ActionClanRating';
import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';
import type { IApiError } from '~/types/api';
import type { IClanData } from '~/types/declaration';

export type RatingInterface = IClanData & {
  disbanded: boolean;
  division_rating: boolean;
  last_win_at: string;
  public_rating: number;
  rank: number;
  rating_realm: string;
  season_number: number;
};

type PropsType = {
  urlParams: any;
  hasError?: boolean;
  autocomplete: {
    error: IApiError;
    foundByTerm: string;
    isFetching: boolean;
    items: IClanData[];
    meta: null;
    request: XMLHttpRequest;
  };
  currentAccount: ICurrentAccountState;
  clanInfo: ClanInfoType;
  wowsLadder: WowsLadderType;
  clanId?: number;
  currentClanId?: number;
  leagues: {
    [league: string]: {
      color: string;
      divisions: Array<{
        icons: {
          [size: string]: string;
        };
        number: number;
        points: Array<number>;
        title: string;
      }>;
      icons: {
        [size: string]: string;
      };
      localization: {
        [type: string]: {
          [language: string]: string;
        };
      };
      number: number;
      points: Array<number>;
      progress_color: string;
    };
  };
  seasonType?: string;
  seasonInfo: {
    icons: any;
    name: string;
  };
  onRealmChange: (realm: string) => void;
  currentSeasonType?: string;
  currentRealm?: string;
  currentLeague?: number;
  currentDivision?: number;
  isCrossRealmLadder?: boolean;
  currentSeason?: number;
  ratingLists: {
    [rating: string]: RatingInterface[];
  };
  isError?: boolean;
  isFetching?: boolean;
  errorMessage?: string;
  searchResult: {
    battleTypeSelected: string;
    error: IApiError;
    isFetchedBefore: boolean;
    isFetching: boolean;
    items: Array<any>;
    meta: any;
    page: number;
    term: string;
  };
  root: string;
  onDivisionChange: (division: number) => void;
  onLeagueChange: (league: number) => void;
  onLeagueAndDivisionChange: (arg: LeagueDivisionRatingType) => void;
  onSeasonTypeChange: (type: SEASON_TYPES) => void;
  onSeasonChange: (season: number) => void;
  onRender: (clanId: number) => void;
  setCurrentClanId: (clanId: number) => void;

  clanHomeRealm?: string;
  clanBattleRealm?: string;

  clearState: () => void;
  onAutocompleteItemClick: () => void;
  onSearchInputChange: (term: string) => void;
  onReloadClick: () => void;
  onPageChanged: () => void;
  onCrossRealmChange?: (flag: boolean) => void;
};

type StateType = {
  leagues: Array<any>;
  removeSearchState: boolean;
  promoSlide: number;
  goToClan: boolean;
  currentSeasonType: SEASON_TYPES;
  currentSeason: number;
  currentRealm: string;
  request: XMLHttpRequest;
  inputValue: string;
};

class ClansRating extends React.Component<PropsType, StateType> {
  constructor(props) {
    super(props);
    const leagues = this.setLeagues(this.props.leagues);

    this.state = {
      leagues,
      removeSearchState: true,
      promoSlide: 0,
      goToClan: false,
      currentSeasonType: '',
      currentSeason: 1,
      currentRealm: '',
      request: null,
      inputValue: '',
    };

    this.onLeagueChange = this.onLeagueChange.bind(this);
    this.onDivisionChange = this.onDivisionChange.bind(this);

    this.onLeagueAndDivisionChange = this.onLeagueAndDivisionChange.bind(this);
    this.onAutocompleteItemClick = this.onAutocompleteItemClick.bind(this);
    this.onClanClick = this.onClanClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.onSeasonTypeChange = this.onSeasonTypeChange.bind(this);
    this.onSeasonChange = this.onSeasonChange.bind(this);
    this.onRealmChange = this.onRealmChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.changeRatingsByUrl();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.leagues) {
      return;
    }
    this.setState(() => {
      return { leagues: this.setLeagues(nextProps.leagues) };
    });

    let nextLeagueDivision;

    if (isUndefined(nextProps.wowsLadder)) return;

    if (!this.props.wowsLadder && nextProps.wowsLadder) {
      this.props.onLeagueAndDivisionChange({
        clanId: nextProps.wowsLadder.id,
      });
      return;
    }

    if (isUndefined(this.props.wowsLadder) && nextProps.wowsLadder === null) {
      nextLeagueDivision = this.getLeagueDivision(nextProps);

      this.props.onLeagueAndDivisionChange({
        league: nextLeagueDivision.leagueNumber,
        division: nextLeagueDivision.divisionNumber,
      });
      return;
    }

    if (!this.props.currentLeague && !this.props.currentDivision) return;

    nextLeagueDivision = this.getLeagueDivision(nextProps);

    if (this.props.currentLeague !== nextLeagueDivision.leagueNumber)
      this.props.onLeagueChange(nextLeagueDivision.leagueNumber);

    if (this.props.currentDivision !== nextLeagueDivision.divisionNumber)
      this.props.onDivisionChange(nextLeagueDivision.divisionNumber);
  }

  componentDidMount() {
    dwhExport.push(DWH_EVENTS.CLAN_BATTLES.VIEW_CLAN_RATING);

    let ladderLeague;
    let ladderDivision;

    if (this.props.clanId && isUndefined(this.props.wowsLadder)) {
      this.props.onRender(this.props.clanId);
    } else if (this.props.wowsLadder) {
      ladderLeague = this.props.wowsLadder.league;
      ladderDivision = this.props.wowsLadder.division;
      this.props.onLeagueAndDivisionChange({
        league: this.props.currentLeague || ladderLeague,
        division: this.props.currentDivision || ladderDivision,
        clanId: this.props.currentClanId ? this.props.currentClanId : this.props.clanId,
        withGlobalSpinner: true,
        ownClan: false,
      });
    } else {
      this.props.onLeagueAndDivisionChange({
        withGlobalSpinner: true,
      });
    }
  }

  componentWillUnmount() {
    if (!this.state.goToClan) {
      this.props.clearState();
      this.props.setCurrentClanId(this.props.clanId);
    }
    this.abortRequest();
  }

  onPageChanged = (...args) => this.wrapFetchResultMethod('onPageChanged')(...args);
  onReloadClick = (...args) => this.wrapFetchResultMethod('onReloadClick')(...args);
  // onSubmit = (...args) => this.wrapFetchResultMethod('onSubmit')(...args);

  setLeagues(leagues) {
    if (this.props.hasError) {
      return [];
    }

    const sortLeagues = Object.keys(leagues).map((el) => {
      const league = leagues[el];

      return {
        content: getLocalizationSafe(league, 'mark_secondary') || getLocalizationSafe(league, 'mark'),
        divisions: league.divisions,
        value: league.number,
        icons: league.icons,
        range: league.range,
      };
    });

    sortBy(sortLeagues, ['value']);

    return sortLeagues;
  }

  getLeagueDivision(props) {
    let league;
    let division;
    let leagueNumber;
    let divisionNumber;

    if (props.currentLeague === undefined) {
      league = props.leagues[1];
      leagueNumber = league.number;
    } else {
      league = props.leagues[props.currentLeague];
      leagueNumber = props.currentLeague;
    }

    if (!props.currentDivision) {
      division = league.divisions[league.divisions.length - 1];
      divisionNumber = division.number;
    } else {
      divisionNumber = props.currentDivision;
    }

    return {
      leagueNumber,
      divisionNumber,
    };
  }

  onLeagueChange(league) {
    this.props.onLeagueChange(league);
  }

  onDivisionChange(division) {
    this.props.onDivisionChange(division);
  }

  onLeagueAndDivisionChange({ league, division, clanId, ownClan }: LeagueDivisionRatingType = {}) {
    this.props.onLeagueAndDivisionChange({
      league,
      division,
      clanId,
      ownClan,
    });
    this.setState({ removeSearchState: true });
  }

  onAutocompleteItemClick(item) {
    this.onLeagueAndDivisionChange({
      ownClan: false,
      clanId: item.id,
      league: item.league,
      division: item.division,
    });
    this.setState({ removeSearchState: true });
  }

  onClanClick(clanId) {
    this.setState(
      {
        removeSearchState: false,
        goToClan: true,
      },
      () => {
        void playButtonClickSound();
        this.context.router.push(`${this.props.root}clan-profile/${clanId}`);
      },
    );
  }

  isBrawl() {
    return this.state.currentSeasonType === SEASON_TYPES.BRAWL || this.props.currentSeasonType === SEASON_TYPES.BRAWL;
  }

  abortRequest() {
    if (this.state.request) this.state.request.abort();
  }

  handleClearClick() {
    this.abortRequest();
    this.props.clearState();
    this.setState({ removeSearchState: true });
  }

  onSeasonTypeChange(seasonType) {
    this.handleClearClick();
    this.props.onSeasonTypeChange(seasonType);
    this.setState(
      {
        currentSeasonType: seasonType,
      },
      () => {
        const season = seasonType === SEASON_TYPES.REGULAR ? getLastRegularSeason() : getLastBrawlSeason();
        if (season['id'] && this.state.currentSeason !== season['id']) {
          this.onSeasonChange(season['id'], seasonType);
        }
      },
    );
  }

  onSeasonChange(season, seasonType: string | null | undefined = null) {
    this.handleClearClick();
    this.props.onSeasonChange(season);

    this.setState(
      {
        currentSeason: parseInt(season, 10),
      },
      () => {
        if (seasonType === null) seasonType = this.props.currentSeasonType;
        this.context.router.push(
          `${this.props.root}clan-battles/rating/${seasonType}/${this.state.currentSeason}/${this.state.currentRealm}`,
        );
      },
    );
  }

  onRealmChange(realm) {
    this.handleClearClick();
    this.props.onRealmChange(realm);

    this.setState(
      {
        currentRealm: realm,
      },
      () => {
        this.context.router.push(
          // eslint-disable-next-line max-len
          `${this.props.root}clan-battles/rating/${this.props.currentSeasonType}/${this.state.currentSeason}/${this.state.currentRealm}`,
        );
      },
    );
  }

  changeRatingsByUrl() {
    const { seasonType, season, realm } = this.props.urlParams;

    // ORDER of parameters updates is important! season -> seasonType -> realm
    if (season) {
      this.props.onSeasonChange(season);
    }

    if (seasonType) {
      this.props.onSeasonTypeChange(seasonType);
    }

    if (realm) {
      this.props.onRealmChange(realm);
    }

    this.setState({
      currentSeasonType: seasonType,
      currentSeason: parseInt(season || this.props.currentSeason, 10),
      currentRealm: realm || this.props.currentRealm,
    });
  }

  wrapFetchResultMethod(methodName) {
    return (...args) => {
      this.abortRequest();
      this.setState({
        request: this.props[methodName](...args),
        removeSearchState: false,
      });
    };
  }

  showLeaders = () => {
    void playButtonClickSound();
    this.onLeagueAndDivisionChange();
  };

  goToMyPosition = () => {
    const seasonData = find(this.props.wowsLadder.ratings, {
      season_number: this.state.currentSeason || this.props.currentSeason,
    });
    if (seasonData) {
      void playButtonClickSound();
      this.onLeagueAndDivisionChange({
        league: seasonData.league,
        division: seasonData.division,
        clanId: this.props.clanId,
      });
    }
  };

  onSearchInputChange = (value: string) => {
    this.setState({
      inputValue: value,
    });
    this.props.onSearchInputChange(value);
  };

  onClear = () => {
    this.handleClearClick();
    this.setState({
      inputValue: '',
    });
    this.props.onSearchInputChange('');
  };

  onSearch = (value: string) => {
    const item = this.props.autocomplete.items.find((item) => item.name.includes(value) || item.tag.includes(value));
    if (item) {
      this.onLeagueAndDivisionChange({
        ownClan: false,
        clanId: item.id,
        league: item.league,
        division: item.division,
      });
    }
    this.setState({ removeSearchState: true });
  };

  render() {
    const {
      hasError,
      currentDivision,
      wowsLadder,
      currentSeason,
      currentRealm,
      clanBattleRealm,
      clanHomeRealm,
      clanId,
      isCrossRealmLadder,
      searchResult,
      currentSeasonType,
      currentLeague,
      ratingLists,
      currentClanId,
      seasonType,
      errorMessage,
      isError,
      isFetching,
      currentAccount,
    } = this.props;

    if (hasError) {
      return (
        <StepCSSTransitionGroup level={1}>
          <ErrorLoad
            isFlat
            key="error-load"
            message={t('Произошла ошибка. Повторите попытку позже')}
            onReloadClick={() => {}}
          />
        </StepCSSTransitionGroup>
      );
    }

    let isQualified: boolean;
    if (!currentDivision) {
      return null;
    }
    if (wowsLadder) {
      isQualified = isQualifiedByBestSeasonTeam(wowsLadder.ratings, currentSeason, currentRealm);
    } else {
      isQualified = false;
    }

    const hideTableMenu = this.state.leagues && this.state.leagues.length === 1;

    return (
      <div className={styles.container}>
        <StepCSSTransitionGroup level={1}>
          <SeasonsMenu
            clanBattleRealm={clanBattleRealm}
            clanHomeRealm={clanHomeRealm}
            currentRealm={currentRealm}
            onRealmChange={this.onRealmChange}
            isBrawl={this.isBrawl()}
            currentSeasonType={this.state.currentSeasonType || currentSeasonType}
            currentSeason={this.state.currentSeason || currentSeason}
            onSeasonChange={this.onSeasonChange}
          />
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={2}>
          {!hideTableMenu && this.state.removeSearchState && (
            <RatingTableNav
              currentLeague={currentLeague}
              leagues={this.state.leagues}
              currentDivision={currentDivision}
              onDivisionChange={this.onDivisionChange}
              onLeagueChange={this.onLeagueChange}
            />
          )}
          <div className={styles.ratingTableNav}>
            <div>
              <Link isActionLink onClick={this.showLeaders}>
                {t('Лидеры сезона')}
              </Link>
              <div
                className={classNames(styles.clanPresent, {
                  [styles.isShow]: isQualified,
                })}
              >
                <Link isActionLink arrow="down" onClick={this.goToMyPosition}>
                  {t('Перейти к позиции моего клана')}
                </Link>
              </div>
              <div
                className={classNames(styles.clanAbsent, {
                  [styles.isShow]: !isQualified && clanId,
                })}
              >
                {t('В выбранном регионе ваш клан отсутствует в рейтинге')}
              </div>
            </div>
            <div className={styles.searchInputWrap} data-staff-name="ratings_search-input">
              <ClanSearchInput
                key="search-input"
                searchType={'clansRating'}
                onHistoryItemClick={this.onAutocompleteItemClick}
                onSubmit={function () {
                  this.setState({ removeSearchState: false });
                }.bind(this)}
              />
            </div>
          </div>
        </StepCSSTransitionGroup>
        {!this.state.removeSearchState && (
          <StepCSSTransitionGroup level={3}>
            <ClansRatingSearchResult
              key="search-result"
              searchType={'clansRating'}
              currentAccount={currentAccount}
              searchResult={searchResult}
              currentSeason={currentSeason}
              isCrossRealmLadder={isCrossRealmLadder}
              onItemClick={this.onLeagueAndDivisionChange}
              onPageChanged={this.onPageChanged}
              onReloadClick={this.onReloadClick}
            />
          </StepCSSTransitionGroup>
        )}
        {this.state.removeSearchState && (
          <ClansRatingData
            ratingLists={ratingLists}
            currentLeague={currentLeague}
            onLeagueChange={this.onLeagueChange}
            onClanClick={this.onClanClick}
            onDivisionChange={this.onDivisionChange}
            currentClanId={currentClanId}
            currentDivision={currentDivision}
            currentSeason={currentSeason}
            seasonType={seasonType}
            isCrossRealmLadder={isCrossRealmLadder}
            errorMessage={errorMessage}
            isError={isError}
            isFetching={isFetching}
          />
        )}
      </div>
    );
  }
}

const contextTypes = {
  router: PropTypes.object.isRequired,
};

ClansRating.contextTypes = contextTypes;

export default translate()(ClansRating);
