import get from 'lodash/get';
import React, { PureComponent } from 'react';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import {
  Autocomplete,
  ClanTag,
  DivTooltip,
  Link,
  Menu,
  Realm,
  StepCSSTransitionGroup,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableRow,
  TooltipBody,
} from '@wg/wows-react-uikit';

import settings, { hasCrossRealm } from '~/settings';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { MEDALS_MAP } from '~/helpers/cwars';
import { isoToFormattedLocalDateTime } from '~/helpers/datetime';
import { t } from '~/helpers/localization';
import { log } from '~/helpers/logging';

import styles from './ClanRatingTab.scss';

import type { ICommonInfo } from '~/types/declaration';

type ClanRatingTab_Props = {
  settings: any;
  currentAccount: any;
  selectedClanRatingLeague: number;
  clanRatingLeagues: Array<{
    icons: {
      default: string;
    };
    number: number;
    points: Array<number>;
    title: string;
  }>;
  searchInputValue: string;
  clanRatingList: {
    data: Array<any>;
    meta: any;
  };
  clanRatingSearchList: {
    data: Array<any>;
    meta: any;
  };
  clanRatingAutocomplete: {
    data: Array<any>;
    meta: any;
  };
  selectedClanId: number | null;
  commonInfo: ICommonInfo;

  selectRatingLeague: (league: string | number) => void;
  showSeasonLeaders: () => void;
  showOwnClanPosition: () => void;
  searchInputChange: (value: string, onlySet?: boolean) => void;
  getRatings: (clanId?: number, league?: number) => void;
  ratingsSearch: (query: string) => void;
  clearSearch: () => void;
  navigateToClan: (clanId: string) => void;
};

class ClanRatingTab extends PureComponent<ClanRatingTab_Props> {
  componentDidMount() {
    dwhExport.push(DWH_EVENTS.NAVAL_BATTLES.VIEW_RATING);
    log('naval_battles.view_rating');
    this.props.getRatings(null, parseInt(`${this.props.selectedClanRatingLeague}`, 10));
    document.addEventListener('keydown', this.onKeyDown, true);
  }

  componentWillUnmount() {
    this.props.clearSearch();
    document.removeEventListener('keydown', this.onKeyDown, true);
  }

  onKeyDown = (e) => {
    if (e && e.keyCode === 27) {
      this.props.clearSearch();
    }
  };

  onAutocompleteSelect = (item: any) => {
    this.props.getRatings(item.clanId, item.league);
    this.props.searchInputChange(item.clan.name);
  };

  onTableRowClick = (item, isSearchResult: boolean) => () => {
    if (isSearchResult) {
      this.props.clearSearch();
      this.props.searchInputChange(item.clan.name);
      this.props.getRatings(item.clanId);
    } else {
      void playButtonClickSound();
      this.props.navigateToClan(item.clanId);
    }
  };

  onSearch = (value: string | null) => {
    void playButtonClickSound();
    this.props.ratingsSearch(value);
  };

  onClearSearch = () => {
    void playButtonClickSound();
    this.props.clearSearch();
  };

  renderTableRows = (data, isSearchResult: boolean) => {
    const rows = [];
    const currentRealm = get(this.props, ['settings', 'realm', 'code'], null);
    if (data) {
      let prevRank = null;
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const item = data[key];
          const index = key;
          const needSeparator = prevRank && item.rank - prevRank > 1 && !isSearchResult;
          const itemRealm = get(item, ['clan', 'realm'], null);
          const clanStatus = get(item, ['clan', 'status'], null);
          const isDisbanded = clanStatus === 'disbanded';
          const isClanAvailable = currentRealm && itemRealm && itemRealm === currentRealm && !isDisbanded;

          const getRow = (hasHover?: boolean) => {
            return (
              <TableRow
                isHovering
                isOwn={hasHover ? false : undefined}
                key={`RatingRow${index}`}
                isActive={item.clanId === this.props.selectedClanId}
                onClick={isClanAvailable ? this.onTableRowClick(item, isSearchResult) : undefined}
              >
                <TableBodyCell modify={'left'} isHidden={!hasCrossRealm}>
                  <Realm realm={itemRealm ? itemRealm : ''} realmMap={settings.realmsMap} />
                </TableBodyCell>
                <TableBodyCell modify={'basis'}>
                  <div className={styles.clanNameWrapper}>
                    <ClanTag clanTag={item.clan.tag} clanColor={item.clan.color} />
                    <span className={styles.clanName}>{`${item.clan.name}`}</span>
                  </div>
                </TableBodyCell>
                <TableBodyCell modify={'right'}>{`${item.rating}`}</TableBodyCell>
                <TableBodyCell modify={'right'}>{`${item.roundsCount}`}</TableBodyCell>
                <TableBodyCell modify={'right'}>
                  <span className={styles.starCount}>{`${item.maxStarsCount}`}</span>
                </TableBodyCell>
                <TableBodyCell modify={'right'}>
                  {item.lastBattleAt ? isoToFormattedLocalDateTime(item.lastBattleAt) : '-'}
                </TableBodyCell>
                <TableBodyCell modify={'right'}>{`${item.membersCount}`}</TableBodyCell>
              </TableRow>
            );
          };

          if (needSeparator) {
            rows.push(
              <TableRow key={`RatingRowSpacer${index}`}>
                <TableBodyCell modify={'center'}>
                  <span>{'...'}</span>
                </TableBodyCell>
              </TableRow>,
            );
          }

          if (isClanAvailable) {
            rows.push(getRow(true));
          } else {
            rows.push(
              <DivTooltip
                key={`RatingRow${index}`}
                tooltipBody={
                  <TooltipBody>
                    {isDisbanded
                      ? t('Невозможно открыть профиль клана. Клан распущен')
                      : t('Невозможно открыть профиль клана находящегося не в вашем регионе')}
                  </TooltipBody>
                }
              >
                {getRow()}
              </DivTooltip>,
            );
          }
          prevRank = item.rank;
        }
      }
    }

    return rows;
  };

  render() {
    let data = this.props.clanRatingList.data;
    let isSearchResult = false;
    if (this.props.clanRatingSearchList.data.length > 0) {
      data = this.props.clanRatingSearchList.data;
      isSearchResult = true;
    }

    const roundsCount = get(this.props.commonInfo, 'clan.roundsCount', 0);
    const hasBattles = roundsCount > 0;

    return (
      <div className={styles.wrapper}>
        <StepCSSTransitionGroup className={styles.leaguePickerRow} level={1}>
          <DivTooltip tooltipBody={<TooltipBody>{t('Выбрать лигу для отображения статистики')}</TooltipBody>}>
            <Menu
              items={this.props.clanRatingLeagues.map((item) => {
                return {
                  value: item.number,
                  content: MEDALS_MAP[item.number].name,
                };
              })}
              itemSelected={this.props.selectedClanRatingLeague}
              onItemChange={this.props.selectRatingLeague}
            >
              {/* <div className={styles.menuMedal}>
                 <Medal
                     size="extra-small"
                     league={this.props.selectedClanRatingLeague}
                     withoutTooltip={true}
                 />
              </div> */}
              <span className={styles.menuLeagueTitle}>
                {get(MEDALS_MAP[this.props.selectedClanRatingLeague], 'name', null)}
              </span>
            </Menu>
          </DivTooltip>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup className={styles.autocompleteRow} level={2}>
          <div className={styles.actionLinks}>
            <Link arrow={null} isActionLink onClick={this.props.showSeasonLeaders}>
              {t('Лидеры сезона')}
            </Link>
            {hasBattles ? (
              <Link arrow={null} isActionLink onClick={this.props.showOwnClanPosition}>
                {t('Перейти к позиции моего клана')}
              </Link>
            ) : (
              <div className={styles.noClanLabel}>
                {this.props.currentAccount.clanId ? t('Ваш клан отсутствует в рейтинге кланов') : null}
              </div>
            )}
          </div>
          <Autocomplete
            value={this.props.searchInputValue}
            width={'330px'}
            maxLength={30}
            minLength={2}
            onChange={this.props.searchInputChange}
            onSearch={this.onSearch}
            onSelect={this.onAutocompleteSelect}
            onClear={this.onClearSearch}
            placeholder={t('Введите тег или название клана')}
            suggestionCount={5}
            autocomplete={this.props.clanRatingAutocomplete.data}
            renderItem={(item) => {
              return (
                <div
                  // Du to bug in wows-react-uikit
                  onMouseDown={() => {
                    this.onAutocompleteSelect(item);
                  }}
                  className={styles.autocompleteItem}
                >
                  <ClanTag clanTag={item.clan.tag} clanColor={item.clan.color} />
                  <div className={styles.clanName}>{`${item.clan.name}`}</div>
                </div>
              );
            }}
          />
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={3}>
          <Table>
            <TableHead>
              <TableBodyCell modify={'left'} isHidden={!hasCrossRealm}>
                <DivTooltip>{t('Реалм')}</DivTooltip>
              </TableBodyCell>
              <TableBodyCell modify={'basis'}>
                <DivTooltip>{t('Клан')}</DivTooltip>
              </TableBodyCell>
              <TableBodyCell modify={'right'}>
                <DivTooltip tooltipBody={<TooltipBody>{t('Рейтинг клана в режиме Морское Сражение')}</TooltipBody>}>
                  {t('Рейтинг')}
                </DivTooltip>
              </TableBodyCell>
              <TableBodyCell modify={'right'}>
                <DivTooltip
                  tooltipBody={<TooltipBody>{t('Количество битв, в которых клан принял участие')}</TooltipBody>}
                >
                  {t('Сражения')}
                </DivTooltip>
              </TableBodyCell>
              <TableBodyCell modify={'right'}>
                <DivTooltip
                  tooltipBody={
                    <TooltipBody>{t('Максимальное количество планок взятых кланом в течение одной Битвы')}</TooltipBody>
                  }
                >
                  {t('Рекорд')}
                </DivTooltip>
              </TableBodyCell>
              <TableBodyCell modify={'right'}>
                <DivTooltip
                  tooltipBody={<TooltipBody>{t('Дата последнего боя с попыткой взятия планки')}</TooltipBody>}
                >
                  {t('Дата боя')}
                </DivTooltip>
              </TableBodyCell>
              <TableBodyCell modify={'right'}>
                <DivTooltip tooltipBody={<TooltipBody>{t('Количество игроков в клане')}</TooltipBody>}>
                  {t('Игроки')}
                </DivTooltip>
              </TableBodyCell>
            </TableHead>
            <TableBody>{this.renderTableRows(data, isSearchResult)}</TableBody>
          </Table>
        </StepCSSTransitionGroup>
        <div className={styles.spacer} />
      </div>
    );
  }
}

export default ClanRatingTab;
