import '~/styles/leaflet.scss';

import React from 'react';
import { FeatureGroup, Map } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

import { log } from '~/helpers/logging';
import { searchToObject } from '~/utils/index';

import styles from './ViewSupply.scss';

import type { LatLngBoundsExpression, LatLngExpression, LeafletEvent, Circle } from 'leaflet';

type PropsType = {
  SetMapRef: (ref: Map) => void;
  children?: React.ReactNode;
  initialZoom: number;
  isSidebarOpened: boolean;
  maxBounds: LatLngBoundsExpression;
  maxZoom: number;
  minZoom: number;
  onClick: () => void;
  onMapMove: () => void;
  onMoveEnd: () => void;
  onZoomEnd: (e: LeafletEvent) => void;
  onZoomStart: () => void;
  position: LatLngExpression;
};

const searchParams = searchToObject();

const IS_DEV_MODE = Object.keys(searchParams).indexOf('devmode') !== -1 && searchParams.devmode !== false;

const MapWrapper: React.FC<PropsType> = (props) => {
  const {
    SetMapRef,
    children,
    initialZoom,
    isSidebarOpened,
    maxBounds,
    maxZoom,
    minZoom,
    onClick,
    onMapMove,
    onMoveEnd,
    onZoomEnd,
    onZoomStart,
    position,
  } = props;
  const onCreated = (e: { layer: Circle; layerType: 'circle' }) => {
    if (e.layer && e.layerType === 'circle') {
      const b = e.layer.getBounds();
      const topRight = b.getNorthWest().lng;

      const x = e.layer.getLatLng().lng;
      const y = e.layer.getLatLng().lat;

      let r = topRight - x;
      if (r < 0) {
        r = -r;
      }

      const pos = {
        center: {
          x: x,
          y: y,
        },
        radius: r,
      };

      e.layer.on('click', () => {
        log('Circle position', pos);
      });
    }
  };
  return (
    <Map
      inertia={false}
      ref={SetMapRef}
      key={`Map_${minZoom}`}
      className={`${styles.viewMap} ${isSidebarOpened ? styles.viewMapOpened : ''}`}
      maxBoundsViscosity={1}
      minZoom={minZoom}
      maxZoom={maxZoom}
      touchZoom={false}
      bounceAtZoomLimits={false}
      scrollWheelZoom={true}
      doubleClickZoom={false}
      center={position}
      zoom={initialZoom}
      maxBounds={maxBounds}
      onmove={onMapMove}
      onmoveend={onMoveEnd}
      onzoomstart={onZoomStart}
      onzoomend={onZoomEnd}
      onclick={onClick}
    >
      {children}

      {IS_DEV_MODE && (
        <FeatureGroup>
          <EditControl
            position="bottomright"
            onCreated={onCreated}
            draw={{
              polyline: false,
              polygon: false,
              rectangle: false,
              marker: false,
              circlemarker: false,
            }}
          />
        </FeatureGroup>
      )}
    </Map>
  );
};

export default MapWrapper;
