import { debounce } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { Interpolate, translate } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { ErrorLoad, Message, Paginator, Spinner, Table, TableBody } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { SEARCHABLE_BATTLE_TYPES } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { scrollToY } from '~/helpers/animate';
import { t, x } from '~/helpers/localization';
import useMount from '~/hooks/useMount';
import { useAppDispatch, useAppSelector } from '~/store';
import { joinClan } from '~/Actions/ActionInvites';
import { actionsSearch, fetchSearchResult } from '~/Actions/ActionSearch';

import StepCSSTransitionGroup from '~/Components/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';
import { BattleSelector } from '~/UIKit';

import styles from './SearchResult.scss';
import SearchResultTableHead from './SearchResultTableHead';
import SearchResultTableItem from './SearchResultTableItem';

import type { ClanType, Invite } from '~/Actions/ActionInvites';
import type { BATTLE_TYPES } from '~/constants';
import type { RootState } from '~/store';
import type { IClanData } from '~/types/declaration';

const battleTypes = settings.battleTypes.filter((battleType) => SEARCHABLE_BATTLE_TYPES.includes(battleType.value));

const stateSelector = (state: RootState) => {
  return {
    currentAccount: state.currentAccount,
    searchResult: state.searchResult,
  };
};

type ISearchResult = {
  onItemClick: (clan: IClanData) => void;
  searchType?: string;
};

const SearchResult: React.FC<ISearchResult> = ({ onItemClick, searchType }) => {
  const { currentAccount, searchResult } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const abortController = React.useRef<AbortController>(new AbortController());
  const currentPage = React.useRef(searchResult.page);

  const appRef = React.useRef(document.getElementById('app') as HTMLDivElement);
  const stickyContainerRef = React.useRef<Nullable<HTMLElement>>(null);

  useMount(() => {
    return () => {
      abortController.current?.abort();
    };
  });

  React.useEffect(() => {
    const isPageChanged = currentPage.current !== searchResult.page;
    if (!isPageChanged) {
      return;
    }

    currentPage.current = searchResult.page;

    if (!stickyContainerRef.current) {
      stickyContainerRef.current = document.getElementById('search-result-sticky-container');
    }
    scrollToY(appRef.current, (stickyContainerRef.current?.offsetTop || 0) - appRef.current.offsetTop);
  }, [searchResult]);

  const onReloadClick = () => {
    void dispatch(fetchSearchResult({ type: searchType, abortSignal: abortController.current.signal }));
  };

  const onPageChanged = (page: number) => {
    dispatch(actionsSearch.changePage(page));
    void dispatch(fetchSearchResult({ type: searchType, abortSignal: abortController.current.signal }));
  };

  const battleTypeChangePushLog = debounce((battleType: string) => {
    dwhExport.push(DWH_EVENTS.SEARCH.SELECT_BATTLE_TYPE, { battleType });
  }, 1000);

  const onJoinClan = (clan: ClanType, invite: Invite) => {
    void dispatch(joinClan(clan, invite, 'clan_search'));
  };

  const onBattleTypeChange = (battleType: BATTLE_TYPES) => {
    battleTypeChangePushLog(battleType);
    dispatch(actionsSearch.changeBattleType(battleType));
    void dispatch(fetchSearchResult({ abortSignal: abortController.current.signal }));
  };

  if (searchResult.isFetching && searchResult.items.length === 0) {
    return (
      <div className={styles.spinner}>
        <Spinner label={t('Ищем кланы')} />
      </div>
    );
  }

  if (searchResult.error) {
    return (
      <StepCSSTransitionGroup level={1}>
        <ErrorLoad
          isFlat
          key="error-load"
          message={t('Не удалось получить информацию о кланах.')}
          onReloadClick={onReloadClick}
        />
      </StepCSSTransitionGroup>
    );
  }

  const items = searchResult.items;
  if (isEmpty(items) && !searchResult.isFetching) {
    return (
      <StepCSSTransitionGroup level={1}>
        <Message type="empty" style={'fullsize'}>
          {t('Кланы с таким названием или тегом не найдены')}
        </Message>
      </StepCSSTransitionGroup>
    );
  }

  let paginator: React.ReactNode = null;
  let displayedTotal: string = '0';
  if (searchResult.meta) {
    displayedTotal =
      searchResult.meta.total && searchResult.meta.total > settings.search.searchResultMaxItems
        ? `${settings.search.searchResultMaxItems}+`
        : `${searchResult.meta.total || 0}`;

    paginator = (
      <StepCSSTransitionGroup level={items.length || 0} duration={0.1} delay={0.05}>
        <Paginator
          key="paginator"
          page={searchResult.page}
          total={searchResult.meta.total || 0}
          limit={settings.search.limit}
          isFetching={searchResult.isFetching}
          isDisabled={searchResult.isFetching}
          onSelectedPage={onPageChanged}
        />
      </StepCSSTransitionGroup>
    );
  }

  return (
    <StepCSSTransitionGroup level={0}>
      <BattleSelector
        key="battle-selector"
        items={battleTypes}
        selected={searchResult.battleTypeSelected}
        onBattleTypeChange={onBattleTypeChange}
      />
      <div key="total" className={styles.total}>
        <Interpolate
          i18nKey={x('Найденные кланы: %(totalCount)s')}
          totalCount={<span className={styles.totalValue}>{displayedTotal}</span>}
          t={t}
        />
      </div>
      <div className={styles.tableWrapper}>
        <Table key="table" isFetching={searchResult.isFetching} stickyContainerId="search-result-sticky-container">
          <SearchResultTableHead stickyContainerId="search-result-sticky-container" />
          <TableBody>
            {items.map((clan, i) => (
              <StepCSSTransitionGroup level={i} duration={0.1} delay={0.05} key={`clansearch_result_${clan.id}`}>
                <SearchResultTableItem
                  key={clan.id}
                  clan={clan}
                  term={searchResult.term}
                  currentAccount={currentAccount}
                  onClick={onItemClick}
                  joinClan={onJoinClan}
                  t={t}
                />
              </StepCSSTransitionGroup>
            ))}
          </TableBody>
        </Table>
      </div>
      {paginator}
    </StepCSSTransitionGroup>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export default React.memo(translate()(SearchResult) as React.FC<ISearchResult>);
