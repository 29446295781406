import * as React from 'react';

import settings from '~/settings';
import { t } from '~/helpers/localization';
import { hasSeasonRank } from '~/helpers/rank';

type IProps = {
  rank: number;
  seasonId: number;
  seasonRank: number;
};

const TooltipRank = (props: IProps) => {
  if (!hasSeasonRank(props.seasonRank, props.seasonId)) {
    return <div>{t('%(rank)s уровень аккаунта:tooltip', { rank: props.rank })}</div>;
  }

  const seasonName = settings.seasonNames[props.seasonId];
  return (
    <div>
      {t('%(rank)s ранг в сезоне %(seasonName)s:tooltip', {
        rank: props.seasonRank,
        seasonName: seasonName,
      })}
    </div>
  );
};

export default React.memo(TooltipRank);
