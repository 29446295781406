import Alert from 'react-s-alert';

import type { SendNotificationProps } from './base';

export const sendWebNotification = (params: SendNotificationProps) => {
  Alert.info('', {
    customFields: params,
    position: 'bottom-right',
    effect: 'stackslide',
    html: true,
  });
};
