import isNumber from 'lodash/isNumber';
import * as React from 'react';

import { thousands } from '~/helpers/formatting';

import { StatisticsValue } from '~/UIKit';

import styles from './ResourceCell.scss';

class ResourceCell extends React.PureComponent<{
  memberId: number;

  value?: number;
  isHidden?: boolean;
  canViewLeveling?: boolean;
  isBonusActivated?: boolean;
  isHighlightedAdmittance?: boolean;
  disableTooltip?: boolean;
}> {
  isNeedTooltip() {
    return (
      !this.props.disableTooltip &&
      isNumber(this.props.value) &&
      this.props.canViewLeveling &&
      !this.props.isBonusActivated
    );
  }

  render() {
    let dataTip = null;
    let tooltipId = null;
    if (this.isNeedTooltip()) {
      dataTip = this.props.memberId;
      tooltipId = 'accumulative-clan-resource-tooltip';
    }

    const classNameResourceCell = !this.props.isHighlightedAdmittance ? null : styles.isHighlightedAdmittance;

    return (
      <div data-tip={dataTip} data-for={tooltipId} className={classNameResourceCell}>
        <StatisticsValue
          value={thousands(this.props.value)}
          isHidden={this.props.isHidden}
          disableTooltip={this.props.disableTooltip}
        />
      </div>
    );
  }
}

export default ResourceCell;
