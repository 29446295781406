import PropTypes from 'prop-types';
import * as React from 'react';
import { Interpolate, translate } from 'react-i18next';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import { ContextMenu, MemberName, Spinner, TableBodyCell, TableRow } from '@wg/wows-react-uikit';

import { clanIsFull } from '~/helpers/clan';
import { getPositionY } from '~/helpers/contextMenu';
import { isoToFormattedLocalDate, isoToFormattedLocalTime } from '~/helpers/datetime';
import { floats, percent, thousands } from '~/helpers/formatting';
import { x } from '~/helpers/localization';

import { ButtonAction, Clan, StatisticsValue, TooltipInfo } from '~/UIKit';
import { TooltipBody, TooltipFooter } from '~/UIKit/components';

import type { Invite } from '~/Actions/ActionInvites';

type PropsType = {
  invite: Invite;
  isInProcessCancelation: boolean;
  currentAccount: {
    inClanCooldownTill?: string;
  };
  root: string;
  t: (arg: string, settings?: any) => string;
  onAcceptedInvite: (invite: Invite) => void;
  onDeclinedInvite: (invite: Invite) => void;
  router?: any;
};

type StateType = {
  isContextMenuActive: boolean;
  contextMenuContext: Array<any>;
  contextMenuPosition: {
    x: number;
    y: number;
  };
};

const getSenderTooltipPart = (t, invite) => {
  return (
    <Interpolate
      useDangerouslySetInnerHTML={true}
      i18nKey={x('Приглашение отправил %(senderMember)s %(date)s&nbsp;в&nbsp;%(time)s')}
      senderMember={invite ? <MemberName role={invite.sender.role} name={invite.sender.name} isInline /> : <div />}
      date={isoToFormattedLocalDate(invite.created_at)}
      time={isoToFormattedLocalTime(invite.created_at)}
      parent={'p'}
      t={t}
    />
  );
};

const getDeclineBtnTooltipContent = (t, invite) => {
  const expiresAtDate = isoToFormattedLocalDate(invite.expires_at);
  const expiresAtTime = isoToFormattedLocalTime(invite.expires_at);
  return (
    <div>
      <TooltipBody>
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Время для принятия решения по приглашению истекает %(date)s&nbsp;в&nbsp;%(time)s')}
          date={expiresAtDate}
          time={expiresAtTime}
          parent={'p'}
          t={t}
        />
        {getSenderTooltipPart(t, invite)}
      </TooltipBody>
      <TooltipFooter mouseIcon="left">{t('Отклонить приглашение')}</TooltipFooter>
    </div>
  );
};

const getAcceptBtnTooltipContent = (t, currentAccount, invite) => {
  const isFullClan = clanIsFull(invite.clan);

  let acceptBtnTooltipContent = null;
  if (currentAccount.inClanCooldownTill) {
    acceptBtnTooltipContent = (
      <div>
        <TooltipBody>
          <Interpolate
            useDangerouslySetInnerHTML={true}
            i18nKey={x('Вы недавно покинули клан. Вступление в клан станет доступно с %(date)s&nbsp;%(time)s')}
            date={isoToFormattedLocalDate(currentAccount.inClanCooldownTill)}
            time={isoToFormattedLocalTime(currentAccount.inClanCooldownTill)}
            parent={'p'}
            t={t}
          />
          <Interpolate
            useDangerouslySetInnerHTML={true}
            i18nKey={x('Время для принятия решения по приглашению истекает %(date)s&nbsp;в&nbsp;%(time)s')}
            date={isoToFormattedLocalDate(invite.expires_at)}
            time={isoToFormattedLocalTime(invite.expires_at)}
            parent={'p'}
            t={t}
          />
          {getSenderTooltipPart(t, invite)}
        </TooltipBody>
      </div>
    );
  } else if (isFullClan) {
    acceptBtnTooltipContent = (
      <div>
        <TooltipInfo>{t('В клане сейчас нет мест')}</TooltipInfo>
        <TooltipBody>
          <Interpolate
            useDangerouslySetInnerHTML={true}
            i18nKey={x('Время для принятия решения по приглашению истекает %(date)s&nbsp;в&nbsp;%(time)s')}
            date={isoToFormattedLocalDate(invite.expires_at)}
            time={isoToFormattedLocalTime(invite.expires_at)}
            parent={'p'}
            t={t}
          />
          {getSenderTooltipPart(t, invite)}
        </TooltipBody>
      </div>
    );
  } else if (!invite.clan.is_active) {
    acceptBtnTooltipContent = (
      <div>
        <TooltipInfo>{t('Клан распущен')}</TooltipInfo>
        <TooltipBody>
          <Interpolate
            useDangerouslySetInnerHTML={true}
            i18nKey={x('Время для принятия решения по приглашению истекает %(date)s&nbsp;в&nbsp;%(time)s')}
            date={isoToFormattedLocalDate(invite.expires_at)}
            time={isoToFormattedLocalTime(invite.expires_at)}
            parent={'p'}
            t={t}
          />
          {getSenderTooltipPart(t, invite)}
        </TooltipBody>
      </div>
    );
  } else {
    acceptBtnTooltipContent = (
      <div>
        <TooltipBody>
          <Interpolate
            useDangerouslySetInnerHTML={true}
            i18nKey={x('Время для принятия решения по приглашению истекает %(date)s&nbsp;в&nbsp;%(time)s')}
            date={isoToFormattedLocalDate(invite.expires_at)}
            time={isoToFormattedLocalTime(invite.expires_at)}
            parent={'p'}
            t={t}
          />
          {getSenderTooltipPart(t, invite)}
        </TooltipBody>
        <TooltipFooter mouseIcon="left">
          <p>{t('Вступить в клан')}</p>
        </TooltipFooter>
      </div>
    );
  }
  return acceptBtnTooltipContent;
};

class InvitesNonClanMemberTableItem extends React.Component<PropsType, StateType> {
  constructor(props) {
    super(props);

    this.state = {
      isContextMenuActive: false,
      contextMenuContext: [],
      contextMenuPosition: { x: 0, y: 0 },
    };

    this.onClanClick = this.onClanClick.bind(this);
    this.onContextMenu = this.onContextMenu.bind(this);
    this.onContextMenuItemClick = this.onContextMenuItemClick.bind(this);
    this.onContextMenuStateChange = this.onContextMenuStateChange.bind(this);
  }

  onContextMenu(event) {
    const context = this.getContextMenuContext();
    const clientY = getPositionY(event, context);
    this.setState({
      contextMenuContext: context,
      contextMenuPosition: {
        x: event.clientX,
        y: clientY,
      },
    });
  }

  isAcceptDisabled() {
    const { invite, currentAccount } = this.props;
    const isFullClan = clanIsFull(invite.clan);
    return !!(currentAccount.inClanCooldownTill || isFullClan || !invite.clan.is_active);
  }

  getContextMenuContext() {
    const { t, invite, currentAccount } = this.props;
    let acceptInviteTooltip = null;
    if (currentAccount.inClanCooldownTill) {
      acceptInviteTooltip = t('Вы недавно покинули клан. Вступление в клан станет доступно %(date)s %(time)s', {
        date: isoToFormattedLocalDate(currentAccount.inClanCooldownTill),
        time: isoToFormattedLocalTime(currentAccount.inClanCooldownTill),
      });
    } else if (clanIsFull(invite.clan)) {
      acceptInviteTooltip = t('В клане сейчас нет мест');
    } else if (!invite.clan.is_active) {
      acceptInviteTooltip = t('Клан распущен');
    }
    return [
      {
        name: null,
        items: [
          invite.clan.is_active
            ? {
                name: t('Профиль клана'),
                value: 'CLAN_PROFILE',
              }
            : null,
          invite.clan.is_active
            ? {
                name: t('Вступить в клан'),
                disabled: this.props.isInProcessCancelation || this.isAcceptDisabled(),
                tooltip: acceptInviteTooltip,
                value: 'ACCEPT_INVITE',
              }
            : null,
          {
            name: t('Отклонить приглашение'),
            disabled: this.props.isInProcessCancelation,
            value: 'DECLINE_INVITE',
          },
        ],
      },
    ];
  }

  onContextMenuItemClick(action) {
    const invite = this.props.invite;
    switch (action) {
      case 'CLAN_PROFILE':
        this.context.router.push(`${this.props.root}clan-profile/${invite.clan.id}`);
        break;
      case 'ACCEPT_INVITE':
        this.props.onAcceptedInvite(invite);
        break;
      case 'DECLINE_INVITE':
        this.props.onDeclinedInvite(invite);
        break;
    }
  }

  onContextMenuStateChange(isContextMenuActive) {
    this.setState({ isContextMenuActive });
  }

  onClanClick() {
    const clan = this.props.invite.clan;
    if (clan.is_active) {
      void playButtonClickSound();
      this.context.router.push(`${this.props.root}clan-profile/${clan.id}`);
    }
  }

  render() {
    const { t, invite, currentAccount } = this.props;
    const isHiddenStatistics = invite.is_hidden_statistics;
    const clan = invite.clan;

    let actionCell = null;
    if (this.props.isInProcessCancelation) {
      actionCell = (
        <TableBodyCell modify="right">
          <Spinner />
        </TableBodyCell>
      );
    } else {
      const acceptBtnTooltipContent = this.state.isContextMenuActive
        ? null
        : getAcceptBtnTooltipContent(t, currentAccount, invite);
      const declineBtnTooltipContent = this.state.isContextMenuActive ? null : getDeclineBtnTooltipContent(t, invite);

      actionCell = (
        <TableBodyCell modify="buttons">
          <ButtonAction
            action="ok"
            isDisabled={this.isAcceptDisabled()}
            onClick={() => {
              this.props.onAcceptedInvite(invite);
            }}
            tooltipContent={acceptBtnTooltipContent}
          />
          <ButtonAction
            action="cancel"
            onClick={() => {
              this.props.onDeclinedInvite(invite);
            }}
            tooltipContent={declineBtnTooltipContent}
          />
        </TableBodyCell>
      );
    }

    return (
      <TableRow
        isHovering
        isActive={this.state.isContextMenuActive}
        onContextMenu={this.onContextMenu}
        onClick={this.onClanClick}
      >
        <ContextMenu
          context={this.state.contextMenuContext}
          position={this.state.contextMenuPosition}
          onItemClick={this.onContextMenuItemClick}
          onContextMenuStateChange={this.onContextMenuStateChange}
        />
        <TableBodyCell modify="basis">
          <Clan
            clanTag={clan.tag}
            clanColor={clan.color}
            clanName={clan.name}
            isDisbanded={!clan.is_active}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="small">{clan.members_count}</TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            isClan
            value={thousands(invite.statistics.abtl)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            isClan
            value={percent(invite.statistics.awb, undefined)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            isClan
            value={thousands(invite.statistics.aeb)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            isClan
            value={thousands(invite.statistics.admg)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            isClan
            value={floats(invite.statistics.afb, 2)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        {actionCell}
      </TableRow>
    );
  }
}

const contextTypes = {
  router: PropTypes.object.isRequired,
};
InvitesNonClanMemberTableItem.contextTypes = contextTypes;

export default translate()(InvitesNonClanMemberTableItem);
