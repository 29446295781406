import React, { PureComponent } from 'react';
import AnimatedNumber from 'react-animated-number';
import { EntityTypes } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { Message } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import styles from './Result.scss';

export type Result_IProps = {
  title?: string;
  result?: string;
  rating: number;
  ratingDelta: number;
  ready?: boolean;
  reward: number;
};

export const RESULTS = {
  WIN: 'WIN',
  DEFEAT: 'DEFEAT',
  DRAW: 'DRAW',
  FAILED_PREPARATION: 'FAILED_PREPARATION',
};

class Result extends PureComponent<Result_IProps> {
  getHighLightClass = () => {
    const result = this.props.result ? this.props.result : '';
    switch (result) {
      case RESULTS.WIN: {
        return styles.winHighlight;
      }

      case RESULTS.DEFEAT: {
        return styles.defeatHighlight;
      }

      case RESULTS.DRAW: {
        return styles.drawHighlight;
      }

      default: {
        return '';
      }
    }
  };

  render() {
    return (
      <div className={styles.results}>
        <div className={`${styles.highlight} ${this.getHighLightClass()}`} />
        <span className={styles.resultsHeader}>
          {this.props.title ? this.props.title : t('Получено за морское сражение')}
        </span>
        {this.props.ready === false && ( // === false is important!
          <span className={styles.infoMessage}>
            <Message type="info" style="inline">
              {t('Награда будет начислена в ближайшее время')}
            </Message>
          </span>
        )}
        <div className={styles.resultSides}>
          <div className={styles.resultSide}>
            <div className={styles.resultRow}>
              <span className={styles.resultValue}>{this.props.rating}</span>
              <div className={styles.resultDelta}>
                {'+'}
                <AnimatedNumber
                  component="span"
                  value={this.props.ratingDelta ? this.props.ratingDelta : 0}
                  duration={600}
                  formatValue={(n) => parseInt(n)}
                />
              </div>
            </div>
            <span className={styles.resultLabel}>{t('Рейтинг')}</span>
          </div>
          <div className={styles.resultSide}>
            <WoWSEntity
              type={EntityTypes.CURRENCY}
              id={'clan_resource'}
              amount={this.props.reward}
              presentation={{
                bindTooltip: true,
                withTooltip: true,
                hideReceiveText: true,
                hideSpendText: true,
              }}
            >
              <div className={styles.resultRow}>
                <span className={styles.resultValue}>{this.props.reward}</span>
                <div className={styles.resultIcon} />
              </div>
              <span className={styles.resultLabel}>{t('Награда')}</span>
            </WoWSEntity>
          </div>
        </div>
      </div>
    );
  }
}

export default Result;
