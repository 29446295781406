import classnames from 'classnames';
import get from 'lodash/get';
import maxBy from 'lodash/maxBy';
import React, { PureComponent } from 'react';
import { Interpolate } from 'react-i18next';
import { playCheckboxClickSound } from '@wg/web2clientapi/sound';
import {
  CountDown,
  ButtonIcon,
  ButtonSwitch,
  Currency,
  Divider,
  DivTooltip,
  StepCSSTransitionGroup,
  Tabs,
  Tooltip,
  TooltipBody,
} from '@wg/wows-react-uikit';

import settings from '~/settings';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { scrollToY } from '~/helpers/animate';
import { getCompetitionMetricPoints, getLeagueData } from '~/helpers/cwars';
import { isoToFormattedLocalDateTime } from '~/helpers/datetime';
import { thousands } from '~/helpers/formatting';
import { t, x } from '~/helpers/localization';
import { log } from '~/helpers/logging';
import { ROUND_TYPE } from '~/Reducers/ReducerClanWars';

import AttemptsBadge from '~/Components/ViewClanWars/AttemptsBadge/AttemptsBadge';
import InGroupClanRatingTable from '~/Components/ViewClanWars/InGroupClanRatingTable/InGroupClanRatingTable';
import { COMPETITION_METRIC } from '~/UIKit';

import styles from './MyWarTab.scss';
import MyWarTable from './MyWarTable/MyWarTable';
import PlayersRatingTable from './PlayersRatingTable/PlayersRatingTable';
import MagnifyingGlass from '../../MagnifyingGlass/MagnifyingGlass';
import ProgressBar from '../../ProgressBar/ProgressBar';
import ResultsHeader from '../../ResultsHeader/ResultsHeader';
import VersusHeader from '../../VersusHeader/VersusHeader';
import { getSelfAttemptsData } from '../../helpers';

import type { IInGroupClanData } from '~/Components/ViewClanWars/InGroupClanRatingTable/InGroupClanRatingTable';
import type { EnemyGradeData } from '~/Reducers/ReducerClanWars';
import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';
import type {
  IBattle,
  IClanInfo,
  ICommonInfo,
  IDictionary,
  IEnemy,
  IHistoryRound,
  IStages,
  IWarSettings,
  Ship,
} from '~/types/declaration';

type MyWarTab_Props = {
  currentAccount: ICurrentAccountState;
  prepageStageTabIndex: number;
  gradesTableTabIndex: number;
  autoParticipation: boolean;
  allPlayersInRatingTable: boolean;
  clanInfo: IClanInfo;
  commonInfo: ICommonInfo;
  warSettings: IWarSettings;
  stages: IStages;
  groupedBattles: [];
  roundType: ROUND_TYPE;
  enemyGrades: IDictionary<Array<EnemyGradeData>>;
  restriction: null | number;
  nextWeekStartDate: string;
  useAttempts: boolean;
  isFromPort: boolean;
  lastRoundResults: any;
  ships: {
    [shipId: number]: Ship;
  };
  roundsHistory: Array<IHistoryRound>;

  setPrepareStageTab: (prepageStageTabIndex: number) => void;
  toggleAutoParticipation: () => void;
  closeAllDialogs: () => void;
  showNationShipTypeStatistic: (nation: string, type: string) => void;
  showAttemptsModal: (id: number, battles: IBattle[]) => void;
  hideAttemptsModal: () => void;
  showCalendarModal: () => void;
  showHistoryModal: () => void;
  hideHistoryModal: () => void;
  setGradesTableTab: (index: number) => void;
  togglePlayersCountInRating: () => void;
  setUseAttempts: (val: boolean) => void;
  showLastRoundResults: () => void;
};

class MyWarTab extends PureComponent<MyWarTab_Props> {
  public app: HTMLElement;
  private scrollContainer;

  constructor(props) {
    super(props);
    this.app = document.getElementById('app');
  }

  componentDidMount() {
    switch (this.props.stages.currentStage) {
      case 'war':
      case 'matchmaking':
        this.setWarStageTab();
        break;
      case 'preparation':
      default:
        this.setPrepareStageTab();
    }
  }

  setPrepareStageTab = () => {
    dwhExport.push(DWH_EVENTS.NAVAL_BATTLES.VIEW_PREPARATION);
    log('naval_battles.view_preparation');
    this.props.setPrepareStageTab(0);
  };

  setWarStageTab = () => {
    dwhExport.push(DWH_EVENTS.NAVAL_BATTLES.VIEW_BATTLE);
    log('naval_battles.view_battle');
    this.props.setPrepareStageTab(1);
  };

  getProgressTarget = (maxClanProgress: number) => {
    switch (this.props.warSettings.stages.preparation.competitionMetric) {
      case COMPETITION_METRIC.EXP: {
        return (
          <Interpolate
            i18nKey={x('Заработайте %(maxClanProgress)s чистого опыта.')}
            maxClanProgress={thousands(maxClanProgress)}
            t={t}
          />
        );
      }

      case COMPETITION_METRIC.DAMAGE: {
        return (
          <Interpolate
            i18nKey={x('Нанесите %(maxClanProgress)s урона.')}
            maxClanProgress={thousands(maxClanProgress)}
            t={t}
          />
        );
      }

      case COMPETITION_METRIC.RIBBONS: {
        return (
          <Interpolate
            i18nKey={x('Заработайте %(maxClanProgress)s лент.')}
            maxClanProgress={thousands(maxClanProgress)}
            t={t}
          />
        );
      }

      default: {
        return (
          <Interpolate
            i18nKey={x('Заработайте %(maxClanProgress)s чистого опыта.')}
            maxClanProgress={thousands(maxClanProgress)}
            t={t}
          />
        );
      }
    }
  };

  renderPreparationContent() {
    const { warSettings, stages } = this.props;
    const settingsPreparation = warSettings.stages.preparation;

    const maxPersonalProgress = settingsPreparation.maxPersonalProgress;
    const maxClanProgress = settingsPreparation.maxClanProgress;
    const clanProgressMilestones = settingsPreparation.clanProgressMilestones;

    const personalProgress = stages.preparation.personalProgress;
    const clanProgress = stages.preparation.clanProgress;
    const restrictionMessage = this.getRestrictionMessage();
    return (
      <StepCSSTransitionGroup level={1}>
        <div
          className={classnames(styles.preparationBackground, {
            [styles.groupPreparationBack]: this.props.roundType !== ROUND_TYPE.PAIR,
          })}
        />
        <div className={styles.progress}>
          <div className={styles.progressBar}>
            <ProgressBar
              title={t('Задача клана')}
              target={this.getProgressTarget(maxClanProgress)}
              mPoints={getCompetitionMetricPoints(this.props.warSettings.stages.preparation.competitionMetric)}
              myValue={personalProgress}
              myValueMax={maxPersonalProgress}
              totalValue={clanProgress}
              maxValue={maxClanProgress}
              breakpoints={clanProgressMilestones}
              competitionMetric={this.props.warSettings.stages.preparation.competitionMetric}
              additionalAttemptsForProgress={this.props.warSettings.stages.preparation.additionalAttemptsForProgress}
            />
          </div>
        </div>
        {this.props.stages.currentStage === 'war' ? (
          <div className={styles.preparationDisabled}>
            <h3>{t('Этап Подготовки завершен')}</h3>
            <h4>
              {restrictionMessage === null ? (
                <Interpolate
                  i18nKey={x('Участвуйте в %(link_to_war_tab)s и зарабатывайте звезды для вашего клана')}
                  link_to_war_tab={<a onClick={this.setWarStageTab}>{t('Битве:link_to_war_tab')}</a>}
                  t={t}
                />
              ) : (
                restrictionMessage
              )}
            </h4>
          </div>
        ) : null}
      </StepCSSTransitionGroup>
    );
  }

  renderMatchmakingContent() {
    return (
      <div className={styles.countdown}>
        <StepCSSTransitionGroup level={1}>
          <div className={styles.peaceBackground} />
          <div className={styles.countdownDescriptionWrapper}>
            <span className={styles.countdownDescription}>
              {this.props.roundType === ROUND_TYPE.PAIR
                ? t('Этап подготовки завершился и для начала битвы мы должны подобрать вам соперника')
                : t('Этап подготовки завершился и для начала битвы мы должны подобрать вам соперников')}
            </span>
            <span className={styles.countdownDescription}>
              {t('А пока вы можете подготовиться к решающей схватке')}
            </span>
          </div>
          <div className={styles.magnifyingGlass}>
            <MagnifyingGlass />
          </div>
          <CountDown date={this.props.warSettings.stages.war.startDate} t={t} />
          <span className={styles.countdownLabel}>{t('До начала битвы')}</span>
        </StepCSSTransitionGroup>
      </div>
    );
  }

  renderRewardingContent() {
    return (
      <div className={styles.rewarding}>
        <StepCSSTransitionGroup level={1}>
          <div className={styles.peaceBackground} />
          <span className={styles.rewardingText}>{t('Битва завершена. ')}</span>
          <span className={styles.rewardingText}>
            {t('Идет подведение итогов состязания, подсчет рейтингов и начисление наград. ')}
          </span>
        </StepCSSTransitionGroup>
      </div>
    );
  }

  renderWarPreparationContent() {
    const { warSettings, stages } = this.props;
    const settingsPreparation = warSettings.stages.preparation;

    const maxClanProgress = settingsPreparation.maxClanProgress;
    const clanProgress = stages.preparation.clanProgress;

    const preparationProgressIsDone = clanProgress >= maxClanProgress;

    return (
      <div className={styles.countdown}>
        <StepCSSTransitionGroup level={1}>
          <div className={styles.peaceBackground} />
          <div className={styles.countdownDescriptionWrapper} style={{ margin: '0 0 50px 0' }}>
            <span className={styles.countdownDescription}>
              {preparationProgressIsDone
                ? t('Ваш клан успешно выполнил задачу клана на этапе Подготовки')
                : t('Для участия в этапе Битва необходимо выполнить задачу клана на этапе Подготовки')}
            </span>
          </div>
          <CountDown date={warSettings.stages.war.startDate} t={t} />
          <span className={styles.countdownLabel}>{t('До начала битвы')}</span>
        </StepCSSTransitionGroup>
      </div>
    );
  }

  renderWarContent() {
    const selfId = this.props.clanInfo.id;

    const enemies: IDictionary<IEnemy> = get(this.props, ['stages', 'war', 'enemies'], null);
    const enemiesIds = Object.keys(enemies);

    const enemy = enemies && enemiesIds && get(enemies, enemiesIds[0], null);
    const enemyGrades = enemies && enemiesIds && get(this.props.enemyGrades, enemiesIds[0], []);

    const maxPersonalAttempts = this.props.warSettings.stages.war.maxPersonalAttempts;
    const modifiers = this.props.stages.war.modifiers;

    const warPageTabs = [
      {
        content: t('Результаты моего клана'),
        value: '0',
      },
    ];

    warPageTabs.push(
      this.props.roundType === ROUND_TYPE.PAIR
        ? {
            content: t('Результаты соперника'),
            value: '1',
          }
        : {
            content: t('Групповые результаты'),
            value: '2',
          },
    );

    const allClans = Object.entries(enemies).map(([enemyId, enemy]): IInGroupClanData => {
      let lastWarBattleAt = get(this.props.stages, ['war', 'enemies', enemyId, 'lastWarBattleAt'], null);
      if (lastWarBattleAt === null) {
        const lastGrade = maxBy(get(this.props.enemyGrades, enemyId, []), (o) => new Date(o.reachedAt).getTime());
        lastWarBattleAt = lastGrade !== undefined ? lastGrade.reachedAt : null;
      }
      return {
        id: parseInt(enemyId, 10),
        color: enemy.color,
        name: enemy.name,
        result: enemy.starsCount,
        tag: enemy.tag,
        memberCount: enemy.membersCount,
        lastWarBattleAt,
      };
    });

    const lastBattle = maxBy(this.props.stages.war.battles, (o) => new Date(o.finishedAt).getTime());
    const lastWarBattleAt = lastBattle !== undefined ? lastBattle.finishedAt : null;

    allClans.push({
      id: this.props.clanInfo.id,
      color: this.props.clanInfo.color,
      name: this.props.clanInfo.name,
      result: this.props.commonInfo.clan.starsCount,
      tag: this.props.clanInfo.tag,
      memberCount: this.props.clanInfo.members_count,
      lastWarBattleAt,
    });

    const myClanData = {
      tag: this.props.clanInfo.tag,
      color: this.props.clanInfo.color,
      realm: settings.realm.code,
      score: this.props.commonInfo.clan.starsCount,
      leagueData: getLeagueData(this.props.warSettings.leagues, this.props.commonInfo.clan.league),
    };

    const firstEnemyData = {
      tag: enemy && enemy.tag ? enemy.tag : '-',
      color: enemy && enemy.color ? enemy.color : '#fff',
      realm: enemy && enemy.realm ? enemy.realm : '-',
      score: enemy && enemy.starsCount ? enemy.starsCount : 0,
      leagueData:
        enemy && enemy.league
          ? getLeagueData(this.props.warSettings.leagues, enemy.league)
          : getLeagueData(this.props.warSettings.leagues, 1), // @TODO for medal img
    };

    const enemyClansSideData = Object.entries(this.props.stages.war.enemies).map(([enemyId, enemyClan]) => {
      return {
        id: enemyId,
        tag: enemyClan.tag,
        color: enemyClan.color,
        realm: enemyClan.realm,
        score: enemyClan.starsCount,
        leagueData: 1,
      };
    });

    const versusHeader =
      this.props.roundType === ROUND_TYPE.PAIR ? (
        <VersusHeader left={myClanData} right={firstEnemyData} />
      ) : (
        <VersusHeader left={myClanData} group={enemyClansSideData} />
      );

    return (
      <div>
        <StepCSSTransitionGroup className={styles.attempts} level={1}>
          <div
            className={classnames(styles.warBackground, {
              [styles.warGroupBackground]: this.props.roundType !== ROUND_TYPE.PAIR,
            })}
          />
          <AttemptsBadge
            attemptsData={getSelfAttemptsData(this.props.stages)}
            warSettings={this.props.warSettings}
            showTitle={true}
          />
          <ButtonIcon
            type="clan-info"
            isFlat
            onClick={() => {
              this.props.showAttemptsModal(this.props.currentAccount.id, this.props.stages.war.battles);
            }}
          />
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup className={styles.switchWrapper} level={1}>
          <span className={styles.useAttempts}>{t('Зачет попыток')}</span>
          <ButtonSwitch
            value={this.props.commonInfo.personal.settings.isAttemptsCount}
            onChange={(val) => {
              void playCheckboxClickSound();
              dwhExport.push(DWH_EVENTS.NAVAL_BATTLES.USE_ATTEMPTS, {
                use_attempts: val ? 1 : 0,
              });
              log('naval_battles.set_use_attempts', {
                use_attempts: val ? 1 : 0,
              });
              this.props.setUseAttempts(val);
            }}
          />
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup
          className={`${styles.versusHeader} ${styles.blurable} ${this.props.isFromPort ? styles.isBlured : ''}`}
          level={2}
        >
          {versusHeader}
          {settings.cwars.features.enemyResults || this.props.roundType === ROUND_TYPE.GROUP ? (
            <div className={styles.tableTabs}>
              <Tabs
                isUppercase
                tabSelected={this.props.gradesTableTabIndex + ''}
                onToggleTab={(value: string) => {
                  scrollToY(this.app, 0, 2000);
                  this.props.setGradesTableTab(parseInt(value, 10));
                }}
                tabs={warPageTabs}
              />
            </div>
          ) : null}
        </StepCSSTransitionGroup>

        {this.props.gradesTableTabIndex === 0 && (
          <React.Fragment>
            <StepCSSTransitionGroup
              className={`${styles.blurable} ${this.props.isFromPort ? styles.isBlured : ''}`}
              level={3}
            >
              <div>
                <MyWarTable
                  size={null}
                  nations={this.props.warSettings.stages.war.nations}
                  grades={this.props.warSettings.stages.war.grades}
                  topGradesIncrement={this.props.warSettings.stages.war.topGradesIncrement}
                  unavailableCells={this.props.warSettings.stages.war.unavailableCells}
                  data={this.props.groupedBattles}
                  competitionMetric={this.props.warSettings.stages.preparation.competitionMetric}
                  modifiers={modifiers}
                  isEnemy={false}
                  onCellClick={this.props.showNationShipTypeStatistic}
                />
                <Interpolate
                  i18nKey={x('За каждые %(stars)s клан получает %(oil)s')}
                  stars={
                    <Currency
                      isFlat
                      amount={this.props.warSettings.stages.war.rewardedStarsIncrement}
                      isBold={true}
                      type="cwars-star"
                    />
                  }
                  oil={
                    <Currency
                      isFlat
                      amount={this.props.warSettings.stages.war.starsIncrementReward.amount}
                      isBold={true}
                      type={this.props.warSettings.stages.war.starsIncrementReward.type}
                    />
                  }
                  t={t}
                />
              </div>
            </StepCSSTransitionGroup>
            <StepCSSTransitionGroup
              level={4}
              className={`${styles.playersRatingTableWrap} ${styles.blurable} ${
                this.props.isFromPort ? styles.isBlured : ''
              }`}
            >
              <div className={styles.divider}>
                <Divider type="major" />
              </div>

              <h2 className={styles.subTitle}>{t('Участники битвы')}</h2>
              <PlayersRatingTable
                battles={this.props.stages.war.battles}
                maxPersonalAttempts={maxPersonalAttempts}
                allPlayersInRatingTable={this.props.allPlayersInRatingTable}
                togglePlayersCountInRating={this.props.togglePlayersCountInRating}
                onClick={this.props.showAttemptsModal}
                stages={this.props.stages}
                warSettings={this.props.warSettings}
                modifiers={this.props.stages.war.modifiers}
              />
            </StepCSSTransitionGroup>
          </React.Fragment>
        )}
        {this.props.gradesTableTabIndex === 1 && (
          <StepCSSTransitionGroup
            className={`${styles.blurable} ${this.props.isFromPort ? styles.isBlured : ''}`}
            level={2}
          >
            <div>
              <MyWarTable
                size={null}
                nations={this.props.warSettings.stages.war.nations}
                grades={this.props.warSettings.stages.war.grades}
                topGradesIncrement={this.props.warSettings.stages.war.topGradesIncrement}
                unavailableCells={this.props.warSettings.stages.war.unavailableCells}
                data={{ useGrades: true, grades: enemyGrades }}
                competitionMetric={this.props.warSettings.stages.preparation.competitionMetric}
                modifiers={modifiers}
                isEnemy={true}
                onCellClick={() => {}}
              />
            </div>
          </StepCSSTransitionGroup>
        )}
        {this.props.gradesTableTabIndex === 2 && (
          <StepCSSTransitionGroup
            className={`${styles.blurable} ${this.props.isFromPort ? styles.isBlured : ''}`}
            level={2}
          >
            <div>
              <InGroupClanRatingTable data={allClans} selfId={selfId} />
            </div>
          </StepCSSTransitionGroup>
        )}
        <div className={styles.spacer} />
      </div>
    );
  }

  getRestrictionMessage = () => {
    switch (this.props.restriction) {
      case 0: {
        // ACCOUNT_NOT_IN_CLAN
        return t('Вы не в клане и поэтому не можете участвовать в текущей Битве');
      }
      case 1: {
        // ACCOUNT_MISSED_PREPARATION
        return t('Вы пропустили этап Подготовка и поэтому не можете участвовать в текущей Битве');
      }
      case 2: {
        // CLAN_MISSED_PREPARATION
        return t('Ваш клан не участвовал в этапе Подготовка и поэтому не может участвовать в текущей Битве');
      }
      case 3: {
        // CLAN_FAILED_PREPARATION
        return t(
          'Ваш клан не выполнил задачу клана на этапе Подготовка и поэтому не может участвовать в текущей Битве',
        );
      }
      case 4: {
        // CLAN_DOES_NOT_PARTICIPATE
        return t('Ваш клан не участвует в битве');
      }
      default: {
        return null;
      }
    }
  };

  renderRestrictionMessage = () => {
    switch (this.props.restriction) {
      case 0: {
        // ACCOUNT_NOT_IN_CLAN
        return (
          <StepCSSTransitionGroup level={1}>
            <div className={styles.restrictionContentWrapper}>
              <div className={styles.restrictionBackground} />
              <div className={styles.restrictionContent}>
                <span className={styles.restrictionText}>
                  {t('Вы не в клане и поэтому не можете участвовать в текущей Битве')}
                </span>
              </div>
            </div>
          </StepCSSTransitionGroup>
        );
      }
      case 1: {
        // ACCOUNT_MISSED_PREPARATION
        return (
          <StepCSSTransitionGroup level={1}>
            <div className={styles.restrictionContentWrapper}>
              <div className={styles.restrictionBackground} />
              <div className={styles.restrictionContent}>
                <span className={styles.restrictionText}>
                  {t('Вы пропустили этап Подготовка и поэтому не можете участвовать в текущей Битве')}
                </span>
              </div>
            </div>
          </StepCSSTransitionGroup>
        );
      }
      case 2: {
        // CLAN_MISSED_PREPARATION
        return (
          <StepCSSTransitionGroup level={1}>
            <div className={styles.restrictionBackground} />
            <div className={styles.restrictionContent}>
              <span className={styles.restrictionText}>
                {t('Ваш клан не участвовал в этапе Подготовка и поэтому не может участвовать в текущей Битве')}
              </span>
              <div className={styles.nextWeekTimer}>
                <CountDown date={this.props.warSettings.stages.rewarding.endDate} t={t} />
              </div>
              <span className={styles.timerLabel}>{t('До начала следующего морского сражения')}</span>
            </div>
          </StepCSSTransitionGroup>
        );
      }
      case 3: {
        // CLAN_FAILED_PREPARATION
        return (
          <StepCSSTransitionGroup level={1}>
            <div className={styles.restrictionBackground} />
            <div className={styles.restrictionContent}>
              <span className={styles.restrictionText}>
                {t(
                  'Ваш клан не выполнил задачу клана на этапе Подготовка и поэтому не может участвовать в текущей Битве',
                )}
              </span>
              <div className={styles.nextWeekTimer}>
                <CountDown date={this.props.warSettings.stages.rewarding.endDate} t={t} />
              </div>
              <span className={styles.timerLabel}>{t('До начала следующего морского сражения')}</span>
            </div>
          </StepCSSTransitionGroup>
        );
      }
      case 4: {
        // CLAN_DOES_NOT_PARTICIPATE
        return (
          <StepCSSTransitionGroup level={1}>
            <div className={styles.restrictionContentWrapper}>
              <div className={styles.restrictionBackground} />
              <div className={styles.restrictionContent}>
                <span className={styles.restrictionText}>{t('Ваш клан не участвует в битве')}</span>
              </div>
            </div>
          </StepCSSTransitionGroup>
        );
      }
      default: {
        return null;
      }
    }
  };

  renderTabContent() {
    let tabContent = null;

    switch (this.props.prepageStageTabIndex) {
      case 0: {
        tabContent = this.renderPreparationContent();
        break;
      }
      case 1: {
        if (this.props.restriction) {
          tabContent = <div>{this.renderRestrictionMessage()}</div>;
        } else {
          if (this.props.stages.currentStage === 'war') {
            tabContent = this.renderWarContent();
          }

          if (this.props.stages.currentStage === 'matchmaking') {
            tabContent = this.renderMatchmakingContent();
          }

          if (this.props.stages.currentStage === 'preparation') {
            tabContent = this.renderWarPreparationContent();
          }
          if (this.props.stages.currentStage === 'rewarding') {
            tabContent = this.renderRewardingContent();
          }
        }

        break;
      }

      default: {
        return null;
      }
    }

    return tabContent;
  }

  renderResultsHeader() {
    return (
      <div className={styles.header}>
        <ResultsHeader
          medalData={getLeagueData(this.props.warSettings.leagues, this.props.commonInfo.clan.league)}
          params={[
            {
              value: thousands(this.props.commonInfo.clan.rating),
              label: t('Рейтинг'),
              tooltipBody: t('Рейтинг клана в режиме Морское Сражение'),
            },
            {
              value: thousands(this.props.commonInfo.clan.roundsCount),
              label: t('Морские Сражения'),
              tooltipBody: t('Количество битв в которых клан принял участие'),
            },
            {
              value: thousands(this.props.commonInfo.clan.maxStarsCount),
              label: t('Рекорд'),
              type: 'cwars-star',
              tooltipBody: t('Максимальное количество планок взятых кланом в течение одной битвы'),
            },
            {
              value: thousands(this.props.commonInfo.clan.totalOilEarned),
              label: t('Награда'),
              type: 'oil',
              tooltipBody: t('Награда полученная кланом за все Морские Сражения'),
              isCurrency: true,
              amount: this.props.commonInfo.clan.totalOilEarned,
            },
          ]}
        />
      </div>
    );
  }

  renderDateCalendar() {
    return (
      <div>
        <Divider type={'major'} />
        <div className={styles.dateRow}>
          <div className={styles.permanentLinkWrapper}>
            <span className={styles.date}>
              <Interpolate
                i18nKey={x('Морское сражение %(startDate)s - %(endDate)s')}
                startDate={isoToFormattedLocalDateTime(this.props.warSettings.startDate)}
                endDate={isoToFormattedLocalDateTime(this.props.warSettings.endDate)}
                t={t}
              />
            </span>
          </div>
          <div className={styles.actions}>
            <DivTooltip
              onClick={this.props.showHistoryModal}
              className={styles.historyButton}
              tooltipBody={
                <Tooltip>
                  <TooltipBody>{t('История прошедших раундов')}</TooltipBody>
                </Tooltip>
              }
            >
              {t('История')}
            </DivTooltip>
            <DivTooltip
              onClick={this.props.showCalendarModal}
              className={styles.calendarButton}
              tooltipBody={
                <Tooltip>
                  <TooltipBody>{t('Расписание этапов морского сражения')}</TooltipBody>
                </Tooltip>
              }
            >
              {t('Календарь')}
            </DivTooltip>
          </div>
        </div>
      </div>
    );
  }

  renderNavTabs() {
    const isPrepareStage = this.props.prepageStageTabIndex === 0;

    return (
      <div className={styles.navTabs}>
        <div className={styles.tabs}>
          <span className={`${styles.navTab} ${isPrepareStage ? styles.active : ''}`} onClick={this.setPrepareStageTab}>
            {t('Подготовка')}
          </span>
          <span className={`${styles.navTab} ${!isPrepareStage ? styles.active : ''}`} onClick={this.setWarStageTab}>
            {this.props.roundType === ROUND_TYPE.PAIR ? t('Битва') : t('Групповой этап Битвы')}
          </span>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.stages.currentStage === 'lull') {
      return (
        <div className={styles.wrapper}>
          <StepCSSTransitionGroup
            className={`${styles.blurable} ${this.props.isFromPort ? styles.isBlured : ''}`}
            level={1}
          >
            {this.renderResultsHeader()}
          </StepCSSTransitionGroup>
          <StepCSSTransitionGroup
            className={`${styles.blurable} ${this.props.isFromPort ? styles.isBlured : ''}`}
            level={2}
          >
            <span className={styles.lullTitle}>{t('В данный момент этот вид состязания не доступен')}</span>
          </StepCSSTransitionGroup>
        </div>
      );
    }

    return (
      <div
        className={styles.wrapper}
        ref={(c) => {
          if (c) {
            this.scrollContainer = c;
          }
        }}
      >
        <StepCSSTransitionGroup
          className={`${styles.blurable} ${this.props.isFromPort ? styles.isBlured : ''}`}
          level={1}
        >
          {this.renderResultsHeader()}
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup
          className={`${styles.blurable} ${this.props.isFromPort ? styles.isBlured : ''}`}
          level={2}
        >
          {this.renderDateCalendar()}
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup
          className={`${styles.blurable} ${this.props.isFromPort ? styles.isBlured : ''}`}
          level={3}
        >
          {this.renderNavTabs()}
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={4}>
          <div className={styles.content}>{this.renderTabContent()}</div>
        </StepCSSTransitionGroup>
      </div>
    );
  }
}

export default MyWarTab;
