export const viewport = () => {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  return { width, height };
};

export const holdScrollPosition = (delay?: number) => {
  if (typeof window !== undefined) {
    window.holdScrollPosition = true;
    setTimeout(
      () => {
        window.holdScrollPosition = false;
      },
      delay ? delay : 2000,
    );
  }
};

interface IUrlParameters {
  devmode?: boolean;
  purpose?: string;
  page?: string;
  source?: string;
  utm_source?: string;
}

export const searchToObject = () => {
  const pairs = window.location.search.substring(1).split('&');
  const obj: IUrlParameters = {};

  for (const pair of pairs) {
    if (pair === '') {
      continue;
    }

    const [key, value] = pair.split('=');
    const decodedKey = decodeURIComponent(key);
    const decodedValue = value ? decodeURIComponent(value) : true;

    if (decodedValue === 'true') {
      obj[decodedKey] = true;
    } else if (decodedValue === 'false') {
      obj[decodedKey] = false;
    } else {
      obj[decodedKey] = decodedValue;
    }
  }

  return obj;
};
