import PropTypes from 'prop-types';
import * as React from 'react';
import { translate } from 'react-i18next';
import { Realm, TableBodyCell, TableRow } from '@wg/wows-react-uikit';

import settings, { hasCrossRealm } from '~/settings';
import { isoToFormattedLocalDateTime } from '~/helpers/datetime';
import { thousands } from '~/helpers/formatting';
import { playButtonSound } from '~/web2ClientAPI/web2ClientAPI';

import { Clan } from '~/UIKit';

const propTypes = {
  clan: PropTypes.object.isRequired,
  term: PropTypes.string.isRequired,
  currentAccountClanId: PropTypes.number,
  currentSeason: PropTypes.number,

  onClick: PropTypes.func.isRequired,
};

class ClansRatingSearchResultTableItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onRowClick = this.onRowClick.bind(this);
  }

  onRowClick() {
    playButtonSound();

    this.props.onClick({
      league: this.props.clan.league,
      division: this.props.clan.division,
      clanId: this.props.clan.id,
      ownClan: false,
    });
  }

  renderRealm() {
    return (
      hasCrossRealm && (
        <TableBodyCell modify="left">
          <Realm
            tooltipId={`item-clan-search-realm-tooltip-${this.props.clan.id}`}
            realm={this.props.clan.realm}
            realmMap={settings.realmsMap}
          />
        </TableBodyCell>
      )
    );
  }

  render() {
    const clan = this.props.clan;
    const isOwn = this.props.currentAccountClanId === clan.id;

    return (
      <TableRow isHovering isOwn={isOwn} onClick={this.onRowClick}>
        {this.renderRealm()}
        <TableBodyCell modify="basis">
          <Clan
            clanName={clan.name}
            clanTag={clan.tag}
            clanColor={clan.hex_color}
            highlight={this.props.term}
            isOwn={isOwn}
            disableTooltip={true}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">{thousands(clan.battles_count)}</TableBodyCell>
        <TableBodyCell modify="right">{isoToFormattedLocalDateTime(clan.last_battle_at)}</TableBodyCell>
        <TableBodyCell modify="right">{clan.members_count}</TableBodyCell>
      </TableRow>
    );
  }
}

ClansRatingSearchResultTableItem.propTypes = propTypes;

export default translate()(ClansRatingSearchResultTableItem);
