import React, { Component } from 'react';
import Alert from 'react-s-alert';
import { Currency } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import styles from './NotificationTemplate.scss';

import type { CurrencyType } from '@wg/wows-react-uikit/components/Currency/Currency';

export type IProps = {
  id: number;
  customFields: {
    hasHeader: boolean;
    header: string;
    message: string;
    cost: number;
    currency: CurrencyType;
    isError?: boolean;
  };
  classNames: string;
  styles: any;
  t?: (arg0: string) => string;
};

const ROLE_PLACEHOLDERS = {
  '[commander]': '<div class="notification_inline_icon notification_commander_icon"></div>',
  '[exec_officer]': '<div class="notification_inline_icon notification_exec_officer_icon"></div>',
  '[recr_officer]': '<div class="notification_inline_icon notification_recr_officer_icon"></div>',
  '[comm_officer]': '<div class="notification_inline_icon notification_commissioned_officer_icon"></div>',
  '[officer]': '<div class="notification_inline_icon notification_officer_icon"></div>',
  '[private]': '<div class="notification_inline_icon notification_private_icon"></div>',
};

class NotificationTemplate extends Component<IProps> {
  close = () => {
    Alert.close(this.props.id);
  };

  parseRoles = (msg: any) => {
    if (typeof msg !== 'string') {
      return msg;
    } else {
      let message = msg;
      for (const key in ROLE_PLACEHOLDERS) {
        if (Object.prototype.hasOwnProperty.call(ROLE_PLACEHOLDERS, key)) {
          const icon = ROLE_PLACEHOLDERS[key];
          if (message.indexOf(key) !== -1) {
            message = message.split(key).join(icon);
          }
        }
      }
      return message;
    }
  };

  render() {
    const Msg = this.parseRoles(this.props.customFields.message);
    const isError = !!this.props.customFields.isError;
    let currencyType = this.props.customFields.currency ? this.props.customFields.currency : 'dublon';
    if (currencyType === 'gold') {
      currencyType = 'dublon';
    }

    return (
      <div
        className={`${this.props.classNames} ${styles.wrapper}`}
        id={this.props.id}
        style={this.props.styles}
        onClick={this.close}
      >
        {this.props.customFields.hasHeader ? (
          <div className={styles.header} dangerouslySetInnerHTML={{ __html: this.props.customFields.header }}></div>
        ) : null}

        <div
          className={`${styles.body} ${isError ? styles.errorBody : ''} ${
            !this.props.customFields.hasHeader && !isError ? styles.taggedBody : ''
          }`}
        >
          {typeof Msg === 'string' ? (
            <div className={styles.bodyContent} dangerouslySetInnerHTML={{ __html: Msg }} />
          ) : (
            <Msg />
          )}
        </div>
        {this.props.customFields.cost ? (
          <div className={styles.footer}>
            <div className={styles.footerTitle}>{t('Стоимость')}</div>
            <div className={styles.spacer} />
            <Currency isFlat amount={this.props.customFields.cost} type={currencyType} t={t} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default NotificationTemplate;
