import classNames from 'classnames';
import * as React from 'react';
import { ButtonIcon } from '@wg/wows-react-uikit';

import styles from './ButtonAction.scss';

export type IProps = {
  tooltipContent?: any;
  action: 'ok' | 'cancel';
  isDisabled?: boolean;
  onClick: (event: React.MouseEvent<EventTarget>) => void;
};

class ButtonAction extends React.PureComponent<IProps, any> {
  render() {
    const classNameContainer = classNames(styles.container, {
      [styles.isDisabled]: this.props.isDisabled,
    });

    return (
      <div className={classNameContainer}>
        <ButtonIcon
          isFlat
          type={this.props.action == 'ok' ? 'check' : 'cross'}
          isDisabled={this.props.isDisabled}
          onClick={this.props.onClick}
          tooltipContent={this.props.tooltipContent}
        />
      </div>
    );
  }
}

export default ButtonAction;
