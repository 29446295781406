import settings from '~/settings';
import { delay } from '~/helpers/delay';
import { actionsProcessing } from '~/Actions/ActionProcessing';

import type { IAppDispatch } from '~/store';

class Loader {
  private started: boolean = false;
  private stopped: boolean = false;
  private loadingText: string | undefined;

  constructor(text?: string) {
    this.loadingText = text;
  }

  public start = (dispatch: IAppDispatch) => {
    if (!this.stopped) {
      dispatch(actionsProcessing.startFetching(this.loadingText));
    }
    this.started = true;
  };

  public stop = (dispatch: IAppDispatch) => {
    if (this.started) {
      dispatch(actionsProcessing.stopFetching(this.loadingText));
    }
    this.stopped = true;
  };

  public static connectPromise = (
    promise: Promise<unknown>,
    fetchingText: string | undefined = undefined,
    dispatch: IAppDispatch,
  ) => {
    const loader = new Loader(fetchingText);

    promise.then(
      () => loader.stop(dispatch),
      () => loader.stop(dispatch),
    );

    void delay(settings.fetchSpinnerDelay).then(() => loader.start(dispatch));
  };
}

export default Loader;
