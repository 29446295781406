import moment from 'moment';
import * as React from 'react';
import { translate } from 'react-i18next';
import { HiddenStatisticsIcon } from '@wg/wows-react-uikit';

import { DEFAULT_ERROR } from '~/helpers/formatting';
import { t } from '~/helpers/localization';

export type IProps = {
  date: number | string;
  isHidden: boolean;
  t: (s?: any, o?: any) => any;
  disableTooltip: any;
};

class FormattedLastBattleTime extends React.PureComponent<IProps, any> {
  getDays() {
    if (!this.props.date) return null;
    const today = moment().endOf('day');
    return Math.floor(moment.duration(today.diff(moment.unix(this.props.date))).asDays());
  }

  getContent(days) {
    const t = this.props.t;

    if (days === null) {
      return DEFAULT_ERROR;
    }

    if (days === 0) {
      return t('Сегодня');
    } else if (days === 1) {
      return t('Вчера');
    }

    return t('%(N)s д', { N: days });
  }

  render() {
    if (this.props.isHidden) {
      return <HiddenStatisticsIcon disableTooltip={this.props.disableTooltip} t={t} />;
    }

    const days = this.getDays();
    const content = this.getContent(days);

    return <div>{content}</div>;
  }
}

export default translate()(FormattedLastBattleTime);
