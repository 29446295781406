import React from 'react';
import { shallowEqual } from 'react-redux';

import { PERMISSIONS } from '~/constants';
import { useAppDispatch, useAppSelector } from '~/store';
import { navigateToTreasuryBuilding } from '~/Actions/ActionTreasury';

import RegularRewards from './RegularRewards/RegularRewards';

import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    permissions: state.currentAccount.permissions,
  };
};

const TreasuryRewards: React.FC = () => {
  const { permissions } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const canDistribute = permissions && permissions.indexOf(PERMISSIONS.DIVIDE_PROFITS) !== -1;

  const handleNavigateToTreasuryBuilding = () => {
    dispatch(navigateToTreasuryBuilding());
  };

  return <RegularRewards navigateToTreasuryBuilding={handleNavigateToTreasuryBuilding} canDistribute={canDistribute} />;
};

export default TreasuryRewards;
