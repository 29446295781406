import * as React from 'react';
import { Page as RulesPage } from '@wg/wows-react-uikit';

import dwhExport, { DWH_EVENTS } from '~/dwhExport';

import { clanBattlesRules } from '../rules';

class ClanBattlesRulesPage extends React.PureComponent {
  componentDidMount() {
    dwhExport.push(DWH_EVENTS.CLAN_BATTLES.VIEW_RULES);
  }

  render() {
    return [
      <RulesPage key={'ClanBattlesRulesPage'} startAnimationIndex={1} rules={clanBattlesRules} />,
      <div key={'ClanBattlesRulesScroller'} style={{ height: '100px' }} />, // this block is designed for scrolling to work properly
    ];
  }
}

export default ClanBattlesRulesPage;
