import classNames from 'classnames';
import filter from 'lodash/filter';
import get from 'lodash/get';
import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import React, { Component } from 'react';
import {
  Currency,
  DivTooltip,
  Highlighter,
  IconTable,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Tooltip,
  TooltipBody,
  TooltipHeader,
} from '@wg/wows-react-uikit';

import settings from '~/settings';
import { thousands } from '~/helpers/formatting';
import { t } from '~/helpers/localization';
import {
  getLocalizationShipTypeName,
  getNationUrl,
  getShipTypes,
  getShipTypeUrl,
  NATIONS_SHIPS_TEXTS_MAP,
} from '~/helpers/nationsAndShips';

import { getModifiersMap } from '~/Components/ViewClanWars/helpers';

import styles from './MyWarTable.scss';

import type { IWarModifier, CompetitionMetric } from '~/types/declaration';

export type Props = {
  shipClass: Array<string>;
  nations: Array<string>;
  data: any;
  grades: Array<number>;
  topGradesIncrement: number;
  isEnemy: boolean;
  unavailableCells: Array<{
    nation: string;
    class: string;
  }>;
  competitionMetric: CompetitionMetric;
  modifiers: Array<IWarModifier>;
  size: 'small' | 'normal';

  onCellClick: (nation: string, type: string) => void;
};

class MyWarTable extends Component<Props> {
  getWarTableHeader(): Array<{ modify: string; type: string }> {
    const nationCell = {
      modify: 'middle',
      type: 'nation',
    };
    let shipClassCells = [];
    if (settings.cwars.war_table.columns.includes('*')) {
      shipClassCells = getShipTypes().map((vehicle) => {
        return { modify: 'center', type: vehicle['name'] };
      });
    } else {
      shipClassCells = settings.cwars.war_table.columns.map((columnName: string) => {
        return { modify: 'center', type: columnName };
      });
    }

    return [nationCell, ...shipClassCells];
  }

  renderTableHead() {
    return map(this.getWarTableHeader(), (cell, key) => {
      const isNotNation = cell.type !== 'nation';
      const cellTextClasses = classNames(styles.headerCell, cell.type);
      const idKey = `head-cell-${key}`;
      if (isNotNation) {
        const shipImage = <img className={styles.shipTypeIcon} src={getShipTypeUrl(cell.type, false, false)} alt="" />;
        const shipTypeName = <span>{getLocalizationShipTypeName(cell.type)}</span>;
        return (
          <TableHeadCell key={idKey} modify={cell.modify}>
            <DivTooltip
              tooltipBody={
                <Tooltip>
                  <TooltipBody>{shipTypeName}</TooltipBody>
                </Tooltip>
              }
            >
              <span className={cellTextClasses}>
                {shipImage}
                {shipTypeName}
              </span>
            </DivTooltip>
          </TableHeadCell>
        );
      }
      return (
        <TableHeadCell key={idKey} modify={cell.modify}>
          <span className={cellTextClasses} />
        </TableHeadCell>
      );
    });
  }

  getNationShipTypeGradeInfo(nation: string, type: string) {
    const battlesArray = get(this.props.data, `${nation}.${type}`, []);

    const battlesWithReachedGrade = filter(battlesArray, (battle) => {
      return battle.isReachedGrade;
    });

    battlesWithReachedGrade.sort((a, b) => {
      return a.claimedGrade - b.claimedGrade;
    });

    let reachedGrade = null;
    let reachedGradeIndex = null;
    let nextGrade = null;

    if (!isEmpty(battlesWithReachedGrade)) {
      reachedGrade = battlesWithReachedGrade[battlesWithReachedGrade.length - 1].claimedGrade;
      reachedGradeIndex = indexOf(this.props.grades, reachedGrade);
      nextGrade =
        reachedGradeIndex !== -1 && !isUndefined(this.props.grades[reachedGradeIndex + 1])
          ? this.props.grades[reachedGradeIndex + 1]
          : reachedGrade + this.props.topGradesIncrement;
      return {
        nextGrade,
        reachedGrade,
        reachedGradeBattlesCount: battlesWithReachedGrade.length,
      };
    } else {
      return {
        nextGrade: this.props.grades[0],
        reachedGrade: '-',
        reachedGradeBattlesCount: 0,
      };
    }
  }

  getActiveBattle = (nation: string, shipClass: string) => {
    const battles = get(this.props.data, `${nation}.${shipClass}`, []);

    return battles.filter((item) => {
      return !item.finishedAt && item.startedAt;
    });
  };

  getGradeInfo = (value: number) => {
    const lastGrade = this.props.grades[this.props.grades.length - 1];
    const grades = [
      ...this.props.grades,
      ...Array(100)
        .fill(lastGrade)
        .map((e, i) => e + (i + 1) * this.props.topGradesIncrement),
    ];

    const position = indexOf(grades, value);
    const nextGradePosition = position + 1;
    return {
      position,
      value: grades[position],
      nextGradePosition,
      nextGradeValue: grades[nextGradePosition],
    };
  };

  getEnemyNationShipTypeGradeInfo() {
    const gradesMap = {};
    const grades = get(this.props.data, 'grades', []);
    const defaultGradeInfo = {
      nextGrade: this.props.grades[0],
      reachedGrade: 0,
      reachedGradeBattlesCount: 0,
    };
    grades.map((grade) => {
      const key = grade.shipClass + '::' + grade.shipNation;
      const prevGradeData = get(gradesMap, key, defaultGradeInfo);
      const gradeInfo = this.getGradeInfo(grade.value);
      gradesMap[key] = {
        nextGrade: Math.max(prevGradeData.nextGrade, gradeInfo.nextGradeValue),
        reachedGrade: Math.max(prevGradeData.reachedGrade, gradeInfo.value),
        reachedGradeBattlesCount: Math.max(prevGradeData.reachedGradeBattlesCount, gradeInfo.position + 1),
      };
    });

    return (nation: string, type: string) => {
      let gradesInfo = gradesMap[type + '::' + nation];
      if (gradesInfo === undefined) {
        gradesInfo = defaultGradeInfo;
      }
      gradesInfo.reachedGrade = gradesInfo.reachedGrade === 0 ? '-' : gradesInfo.reachedGrade;
      return gradesInfo;
    };
  }

  renderTableBody() {
    const modifiersMap = getModifiersMap(this.props.modifiers);
    const useGrades = get(this.props.data, 'useGrades', false);
    let getNationShipTypeGradeInfo = this.getNationShipTypeGradeInfo.bind(this);
    if (useGrades) {
      getNationShipTypeGradeInfo = this.getEnemyNationShipTypeGradeInfo();
    }

    return this.props.nations.map((nation) => {
      return (
        <TableRow key={`war-table-row-${nation}`}>
          {map(this.getWarTableHeader(), (cell) => {
            const isDenied = filter(this.props.unavailableCells, {
              shipNation: nation,
              shipClass: cell.type,
            }).length;

            // TODO: how to get real nations from gloss?
            const nationData = NATIONS_SHIPS_TEXTS_MAP[nation];

            if (!isDenied) {
              const nationShipTypeGradeInfo = getNationShipTypeGradeInfo(nation, cell.type);
              let activeBattles = [];
              let isInBattle = false;
              let activePlayers = '';

              if (!useGrades) {
                activeBattles = this.getActiveBattle(nation, cell.type);
                isInBattle = activeBattles.length > 0;
                activePlayers = activeBattles.map((item) => item.nick).join(', ');
              }

              const hasModifier = modifiersMap[cell.type + '::' + nation] !== undefined;
              const modifierFactor = hasModifier ? 2 : 1;

              return (
                <TableBodyCell
                  key={`war-table-body-cell-${nation}-${cell.type}`}
                  modify={cell.modify}
                  isHovering={cell.type !== 'nation'}
                  className={classNames(styles.bodyTd, {
                    [styles.bodyCellWrapperModifier]: hasModifier,
                  })}
                  onClick={() => {
                    cell.type !== 'nation' && this.props.onCellClick(nation, cell.type);
                  }}
                >
                  {cell.type !== 'nation' ? (
                    <DivTooltip
                      className={styles.cellWrap}
                      tooltipBody={
                        <Tooltip>
                          <TooltipHeader isBold={false} isHelpIcon={false}>
                            <div className={styles.title}>{t('Текущие результаты')}</div>
                          </TooltipHeader>
                          {hasModifier && (
                            <React.Fragment>
                              <TooltipBody>
                                <span className={styles.activeHint}>
                                  {t(
                                    'Играя на кораблях этой нации и класса, вы получаете больше звёзд за взятые планки.',
                                  )}
                                </span>
                              </TooltipBody>
                            </React.Fragment>
                          )}
                          <TooltipBody>
                            <div className={styles.tooltipRaw}>
                              {t('Результат:')}
                              <Currency
                                isFlat
                                isBold
                                type="cwars-star"
                                amount={nationShipTypeGradeInfo.reachedGradeBattlesCount * modifierFactor}
                              />
                            </div>
                            {isInBattle && (
                              <div className={styles.tooltipRaw}>
                                {t('Сейчас в бою:')}
                                <span className={styles.activePlayers}>{activePlayers}</span>
                              </div>
                            )}
                          </TooltipBody>
                          <TooltipBody>
                            <div className={styles.tooltipRaw}>
                              {t('Последняя взятая планка:')}
                              &nbsp;
                              <Currency
                                isFlat
                                isBold
                                type={Currency.metricIconName[this.props.competitionMetric]}
                                amount={nationShipTypeGradeInfo.reachedGrade}
                              />
                            </div>
                            <div className={styles.tooltipRaw}>
                              {t('Следующая планка:')}
                              &nbsp;
                              <Currency
                                isFlat
                                isBold
                                type={Currency.metricIconName[this.props.competitionMetric]}
                                amount={nationShipTypeGradeInfo.nextGrade}
                              />
                            </div>
                          </TooltipBody>
                        </Tooltip>
                      }
                    >
                      <div className={`${styles.bodyCell} ${styles.bodyCellInline}`}>
                        <div className={styles.inlineRelative}>
                          {isInBattle ? (
                            <div className={styles.activeBattleIcon}>
                              <IconTable glyph={'battles'} />
                            </div>
                          ) : null}
                          {this.props.isEnemy ? (
                            thousands(nationShipTypeGradeInfo.nextGrade)
                          ) : (
                            <Highlighter value={thousands(nationShipTypeGradeInfo.nextGrade)} />
                          )}
                          <Currency
                            isFlat
                            amount={0}
                            type={Currency.metricIconName[this.props.competitionMetric]}
                            onlyIcon
                          />
                        </div>
                        {hasModifier && (
                          <span className={styles.bonus}>
                            {t('Бонус')}: <strong>x2</strong>
                          </span>
                        )}
                      </div>
                    </DivTooltip>
                  ) : (
                    <DivTooltip
                      className={classNames(styles.bodyCell, styles.nation)}
                      tooltipBody={
                        <Tooltip>
                          <TooltipBody>{nationData.name}</TooltipBody>
                        </Tooltip>
                      }
                    >
                      <img src={getNationUrl(nation, undefined)} alt="" />
                    </DivTooltip>
                  )}
                </TableBodyCell>
              );
            } else {
              return (
                <TableBodyCell
                  key={`war-table-body-cell-${nation}-${cell.type}`}
                  modify={cell.modify}
                  isHovering={false}
                  onClick={() => {}}
                >
                  <div className={styles.emptyCell} />
                </TableBodyCell>
              );
            }
          })}
        </TableRow>
      );
    });
  }

  render() {
    return (
      <div className={styles.container}>
        <Table
          className={classNames({
            [styles.smallTable]: this.props.size === 'small',
          })}
        >
          <TableHead className={styles.thead}>{this.renderTableHead()}</TableHead>
          <TableBody
            className={classNames(styles.table, {
              [styles.enemyTable]: this.props.isEnemy,
            })}
          >
            {this.renderTableBody()}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default MyWarTable;
