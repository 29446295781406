import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import * as React from 'react';
import { shallowEqual } from 'react-redux';
import { ClanName, Realm, Spinner } from '@wg/wows-react-uikit';

import settings, { hasCrossRealm } from '~/settings';
import { t } from '~/helpers/localization';
import { useAppSelector } from '~/store';
import { playDropdownSound } from '~/web2ClientAPI/web2ClientAPI';

import { getAccountSearchHistory } from '~/Reducers/ReducerSearchHistory';

import styles from './ClanSearchAutocomplete.scss';

import type { RootState } from '~/store';
import type { IClanData } from '~/types/declaration';

const MAX_VISIBLE_RESULTS = 10;

const stateSelector = createSelector(
  [
    (state: RootState) => state.autocomplete,
    (state: RootState) => state.searchHistory,
    (state: RootState) => state.currentAccount,
  ],
  (autocomplete, searchHistory, currentAccount) => {
    return {
      autocomplete,
      searchHistory: getAccountSearchHistory(searchHistory, currentAccount.id),
      totalClans: (autocomplete.meta && autocomplete.meta.total_clans) || 0,
    };
  },
);

type IClanSearchAutocomplete = {
  isHistoryHidden?: boolean;
  term: string;
  onHistoryItemClick: (item: IClanData) => void;
  onItemClick: (item: IClanData) => void;
  onSubmit: () => void;
};

const ClanSearchAutocomplete: React.FC<IClanSearchAutocomplete> = ({
  term,
  isHistoryHidden,
  onHistoryItemClick,
  onItemClick,
  onSubmit,
}) => {
  const { autocomplete, searchHistory, totalClans } = useAppSelector(stateSelector, shallowEqual);

  const autocompleteRef = React.useRef<HTMLDivElement>(null);

  const renderRealm = (item: IClanData) => {
    return (
      hasCrossRealm &&
      item.realm && (
        <span className={styles.realmWrap}>
          <Realm realm={item.realm} realmMap={settings.realmsMap} />
        </span>
      )
    );
  };

  const renderList = (items: IClanData[], term?: string) => {
    return items.slice(0, MAX_VISIBLE_RESULTS).map((item) => (
      <div
        className={styles.item}
        key={item.id}
        onClick={() => {
          onListItemClick(item);
        }}
      >
        {renderRealm(item)}
        <div className={styles.clanNameWrap}>
          <ClanName clanName={item.name} clanTag={item.tag} clanColor={item.hex_color} highlight={term} />
        </div>
      </div>
    ));
  };

  const hasSearchHistory = () => {
    return searchHistory.items.length && !term?.length;
  };

  const onListItemClick = (item: IClanData) => {
    if (!hasSearchHistory()) {
      onItemClick(item);
    }
    onHistoryItemClick(item);
    playDropdownSound();
  };

  const renderMessage = (message: string) => {
    return <div className={styles.message}>{message}</div>;
  };

  const renderSearchHistory = () => {
    return (
      <div>
        <div className={styles.title} id="search-history-list">
          {t('Последние посещённые')}
        </div>
        {renderList(searchHistory.items)}
      </div>
    );
  };

  const onShowAllLinkClick = () => {
    onSubmit();
    playDropdownSound();
  };

  const renderShowAllLink = () => {
    if (
      totalClans <= settings.search.autocompleteMaxResult ||
      term.length < settings.search.minChars ||
      autocomplete.isFetching
    ) {
      return null;
    }

    const displayedTotal =
      totalClans > settings.search.searchResultMaxItems ? `${settings.search.searchResultMaxItems}+` : totalClans;

    return (
      <div className={styles.item} id="show-all" onClick={onShowAllLinkClick}>
        {t('Показать все (%(total)s)', { total: displayedTotal })}
      </div>
    );
  };

  const renderSpinner = () => {
    return (
      <div className={styles.spinner}>
        <Spinner label={t('Ищем кланы')} />
      </div>
    );
  };

  if (!term?.length && !searchHistory?.items?.length) {
    return null;
  }

  let autocompleteMessage: Nullable<string> = null;
  if ((term?.length || 0) < settings.search.minChars) {
    autocompleteMessage = t('Для поиска клана нужно ввести хотя бы %(count)s символа', {
      count: settings.search.minChars,
    });
  } else if (term === autocomplete.foundByTerm && !autocomplete.items?.length && !autocomplete.isFetching) {
    autocompleteMessage = t('Кланы с таким названием или тегом не найдены');
  }

  let content: React.ReactNode = null;
  const contentStyle: React.CSSProperties = {};

  if (autocomplete.isFetching) {
    if (autocompleteRef.current) {
      contentStyle.height = autocompleteRef.current.clientHeight;
    }
    content = renderSpinner();
  } else if (hasSearchHistory() && !isHistoryHidden) {
    content = renderSearchHistory();
  } else if (autocompleteMessage) {
    content = renderMessage(autocompleteMessage);
  } else if (autocomplete.items.length > 0) {
    content = renderList(autocomplete.items, term);
  } else if (autocomplete.items.length === 0 && autocompleteRef.current) {
    contentStyle.height = autocompleteRef.current.clientHeight;
  }
  const classNameAutocomplete = classNames(styles.autocomplete, {
    [styles.showHistory]: hasSearchHistory(),
  });

  return (
    <div className={classNameAutocomplete}>
      <div className={styles.inner} style={contentStyle} ref={autocompleteRef}>
        {content}
        {renderShowAllLink()}
      </div>
    </div>
  );
};

export default ClanSearchAutocomplete;
