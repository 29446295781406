import 'react-dates/initialize';

import moment from 'moment';
import React from 'react';
import { DateRangePicker } from 'react-dates';
import { shallowEqual } from 'react-redux';
import { playCardClickSound, playDropdownSound } from '@wg/web2clientapi/sound';

import { TREASURY_TABS } from '~/constants';
import { showDistributionResultDialog } from '~/helpers/dialogs';
import { t } from '~/helpers/localization';
import useScrollShadows from '~/hooks/useScrollShadows';
import useScrolledToBottom from '~/hooks/useScrolledToBottom';
import { useAppDispatch, useAppSelector } from '~/store';
import { appendTransactionsThunk, setFilterDatesThunk } from '~/Actions/ActionTreasury';

import StepCSSTransitionGroup from '~/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import HistoryTable from './HistoryTable/HistoryTable';
import TotalBalance from './TotalBalance/TotalBalance';
import styles from './TreasuryHistory.scss';

import type { Moment } from 'moment';
import type { RouteComponentProps } from 'react-router';
import type { RootState } from '~/store';
import type { Transaction } from '~/types/declaration';

type IMonth = string[];

const months: IMonth = [
  t('Январь'),
  t('Февраль'),
  t('Март'),
  t('Апрель'),
  t('Май'),
  t('Июнь'),
  t('Июль'),
  t('Август'),
  t('Сентябрь'),
  t('Октябрь'),
  t('Ноябрь'),
  t('Декабрь'),
];

const stateSelector = (state: RootState) => {
  return {
    filterEndDate: state.ReducerTreasury.filterEndDate,
    filterStartDate: state.ReducerTreasury.filterStartDate,
    formats: state.settings.formats,
    isAppending: state.ReducerTreasury.isAppending,
    isTotalTransactionsSumUpdating: state.ReducerTreasury.isTotalTransactionsSumUpdating,
    isTransactionListUpdating: state.ReducerTreasury.isTransactionListUpdating,
    regularRewards: state.ReducerTreasury.regularRewards,
    totalTransactionsSum: state.ReducerTreasury.totalTransactionsSum,
    transactions: state.ReducerTreasury.transactions,
  };
};

type ITreasuryHistoryProps = {
  animationLevelNested?: number;
};
type ITreasuryHistoryRouteProps = ITreasuryHistoryProps & RouteComponentProps<ITreasuryHistoryProps, unknown>;

const TreasuryHistory: React.FC<ITreasuryHistoryRouteProps> = ({ route, animationLevelNested = 0 }) => {
  const {
    filterEndDate,
    filterStartDate,
    formats,
    isAppending,
    isTotalTransactionsSumUpdating,
    isTransactionListUpdating,
    regularRewards,
    totalTransactionsSum,
    transactions,
  } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const [focusedInput, setFocusedInput] = React.useState<Nullable<'endDate' | 'startDate'>>(null);
  const tableRef = React.useRef<HTMLDivElement | null>(null);

  const isSpend = route.path === TREASURY_TABS.EXPENSES;

  let animationLevel = animationLevelNested;

  useScrollShadows(tableRef);
  useScrolledToBottom({
    ref: tableRef,
    onScrollToBottom: () => {
      if (!isAppending) {
        appendTransactions();
      }
    },
  });

  const handleShowDistributionResultDialog = (transaction: Transaction) => {
    dispatch(showDistributionResultDialog(transaction));
  };

  const appendTransactions = () => {
    dispatch(appendTransactionsThunk());
  };

  const setFilterDates = (start: Moment, end: Moment) => {
    dispatch(setFilterDatesThunk(start, end));
  };

  const onDatesChange = ({ startDate, endDate }: { startDate: Moment; endDate: Moment }) => {
    if (startDate === null && endDate === null) {
      void playDropdownSound();
    }
    setFilterDates(startDate, endDate);
  };

  const onClearFilter = () => {};

  const onFocusChange = (_focusedInput: Nullable<'startDate' | 'endDate'>) => {
    if (_focusedInput !== focusedInput) {
      void playDropdownSound();
      setFocusedInput(_focusedInput);
    }
  };

  const renderMonthText = (month: Moment) => {
    const monthIndex = parseInt(month.format('M'), 10) - 1;
    return months[monthIndex];
  };

  const isOutsideRange = () => false;

  const isDayBlocked = (day: Moment) => day.isAfter(moment(), 'day');

  const isDayHighlighted = (day: Moment) => {
    let isHighlighted = false;
    const d = day.format('YYYY-MM-DD');

    transactions.forEach((transaction) => {
      if (d === moment(transaction.date).format('YYYY-MM-DD')) {
        isHighlighted = true;
      }
    });

    return isHighlighted;
  };

  const renderCloseIcon = () => <div className={styles.clearButton}>{t('Очистить')}</div>;

  return (
    <div className={styles.wrapper}>
      <StepCSSTransitionGroup level={animationLevel} duration={0.1} delay={0.05}>
        <div className={styles.tableHeader}>
          <div className={styles.spendLabel}>{isSpend ? t('Расходы за период:') : t('Доходы за период:')}</div>
          <DateRangePicker
            startDate={filterStartDate}
            startDateId="filterPerionStart"
            endDate={filterEndDate}
            endDateId="filterPerionEnd"
            focusedInput={focusedInput}
            showClearDates
            small
            numberOfMonths={1}
            hideKeyboardShortcutsPanel
            noBorder
            startDatePlaceholderText={t('Начало')}
            endDatePlaceholderText={t('Конец')}
            isOutsideRange={isOutsideRange}
            isDayBlocked={isDayBlocked}
            isDayHighlighted={isDayHighlighted}
            customCloseIcon={renderCloseIcon()}
            renderMonthText={renderMonthText}
            displayFormat={formats.date}
            minimumNights={0}
            onClose={onClearFilter}
            onDatesChange={onDatesChange}
            onFocusChange={onFocusChange}
            onPrevMonthClick={playCardClickSound}
            onNextMonthClick={playCardClickSound}
          />
          <div className={styles.spacer} />
          <TotalBalance currencyList={totalTransactionsSum} isUpdating={isTotalTransactionsSumUpdating} />
        </div>
      </StepCSSTransitionGroup>
      <div className={styles.tableWrapper}>
        <div className={styles.table} ref={tableRef}>
          {
            <HistoryTable
              transactions={transactions}
              formats={formats}
              isTransactionListUpdating={isTransactionListUpdating}
              isSpend={isSpend}
              showDistributionResultDialog={handleShowDistributionResultDialog}
              regularRewards={regularRewards}
              animationLevelNested={animationLevel++}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default React.memo(TreasuryHistory);
