import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom/client';
import { translate } from 'react-i18next';
import { EntityTypes } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';

import { t } from '~/helpers/localization';

import styles from './ClanWallet.scss';

export type IProps = {
  clanResource: number;
  accumulativeResource: number;
};

class ClanWallet extends PureComponent<IProps, any> {
  render() {
    const amount = this.props.clanResource;
    const totalAmount = this.props.accumulativeResource;
    return (
      <div className={styles.base}>
        <div className={styles.header}>{t('Клановый ресурс')}</div>
        <WoWSEntity
          key={`we_oil_${amount}_${totalAmount}`}
          type={EntityTypes.CURRENCY}
          id={'clan_resource'}
          amount={amount}
          presentation={{
            withTooltip: true,
            hideTooltipAmount: true,
          }}
          customisation={{
            afterHeaderTooltipSlot(node) {
              ReactDOM.createRoot(node)?.render(
                <div>
                  {t('В наличии:')}&nbsp;
                  <span style={{ display: 'inline-block' }}>
                    <WoWSEntity
                      type={EntityTypes.CURRENCY}
                      id={'clan_resource'}
                      amount={amount}
                      presentation={{
                        withTooltip: false,
                        renderWithoutFade: true,
                      }}
                    />
                  </span>
                  <br />
                  {t('Всего получено:')}&nbsp;
                  <span style={{ display: 'inline-block' }}>
                    <WoWSEntity
                      type={EntityTypes.CURRENCY}
                      id={'clan_resource'}
                      amount={totalAmount}
                      presentation={{
                        withTooltip: false,
                        renderWithoutFade: true,
                      }}
                    />
                  </span>
                </div>,
              );
            },
          }}
        />
      </div>
    );
  }
}

export default translate()(ClanWallet);
