import cloneDeep from 'lodash/cloneDeep';

import { SEARCH_APPEND_TERM_TO_HISTORY } from '~/Actions/ActionSearch';

import type { ActionsType } from '~/Actions/ActionSearch';
import type { IClanData } from '~/types/declaration';

const HISTORY_ITEMS_LENGTH = 5;

const initialState = {
  accounts: {},
};

type ISearchHistoryState = {
  accounts: {
    [clanId: string]: {
      items: IClanData[];
    };
  };
};

export const searchHistory = (state: ISearchHistoryState = initialState, action: ActionsType): ISearchHistoryState => {
  switch (action.type) {
    case SEARCH_APPEND_TERM_TO_HISTORY: {
      const newState = cloneDeep(state);
      const history = getAccountSearchHistory(newState, action.accountId);

      if (history.items.find((item) => item.id === action.item.id)) {
        history.items = history.items.filter((item) => item.id !== action.item.id);
        history.items.unshift(action.item);
      } else {
        history.items.unshift(action.item);
        history.items = history.items.slice(0, HISTORY_ITEMS_LENGTH);
      }

      newState.accounts[action.accountId] = history;
      return newState;
    }

    default:
      return state;
  }
};

export const getAccountSearchHistory = (state: ISearchHistoryState, clanId: Nullable<number>) => {
  return (clanId && state.accounts[clanId]) || { items: [] };
};
