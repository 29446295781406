import * as React from 'react';
import { translate } from 'react-i18next';
import { Menu } from '@wg/wows-react-uikit';

import { getSeasonTeams } from '~/helpers/seasons';
import { TEAMS_NAMES } from '~/helpers/teams';

import styles from './LadderTeamsDropdown.scss';

export type IProps = {
  statistics: object;
  season: number;
  selectedTeamNumber: number;
  onTeamNumberChange: () => void;
};

class LadderTeamsDropdown extends React.PureComponent<IProps> {
  render() {
    if (!this.props.statistics.ratings) return null;
    const seasonTeams = getSeasonTeams(this.props.statistics.ratings, this.props.season);
    if (seasonTeams.length < 2) return null;
    const teamsMap = seasonTeams.map((team) => {
      return {
        value: team.team_number,
        content: TEAMS_NAMES[team.team_number],
      };
    });
    teamsMap.sort((teamA, teamB) => {
      return teamA.value - teamB.value;
    });
    const currentTeamNumber = this.props.selectedTeamNumber || this.props.statistics.leadingTeamNumber;
    const dropdownTeamLabel = <div className={styles.label}>{TEAMS_NAMES[currentTeamNumber]} </div>;
    return (
      <div className={styles.container}>
        <Menu
          items={teamsMap}
          itemSelected={currentTeamNumber}
          onItemChange={(val) => {
            this.props.onTeamNumberChange(val);
          }}
        >
          {dropdownTeamLabel}
        </Menu>
      </div>
    );
  }
}

export default translate()(LadderTeamsDropdown);
