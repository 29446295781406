import React from 'react';

import AboutClansSlider from '~/Components/AboutClansSlider/AboutClansSlider';

import type { IDialog } from '~/types/declaration';

type IClansInformerDialog = IDialog<{
  clanId: number;
  source: string;
}>;

const ClansInformerDialog: React.FC<IClansInformerDialog> = ({ data, hideDialog }) => {
  const { clanId, source } = data;

  return <AboutClansSlider clanId={clanId} source={source} hideDialog={hideDialog} />;
};

export default React.memo(ClansInformerDialog);
