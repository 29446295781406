import wgcm from '@wg/wowscm';

import preloaded from '~/preloaded';

const inGameUserAgentKeyword = 'WOWS/1.0';

const appDiv = document.getElementById('app');
const cmWrapper = document.getElementById('cm-wrapper');

export function isIngame() {
  return navigator.userAgent.includes(inGameUserAgentKeyword);
}

export function initCommonmenu() {
  const userId = preloaded.currentAccount?.id || null;
  const userName = preloaded.currentAccount?.nickname || null;

  if (isIngame() && !!window.cm_settings?.menuElementId) {
    document.getElementById(window.cm_settings.menuElementId)?.remove();
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    wgcm(userId, userName);
    if (cmWrapper) {
      cmWrapper.classList.add('cm-wrapper-visible');
    }
    if (appDiv) {
      appDiv.classList.add('not-ingame');
    }
  }
}
