import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import * as React from 'react';
import { translate } from 'react-i18next';
import { ContextMenu, TableBodyCell, TableRow } from '@wg/wows-react-uikit';

import { clanIsFull } from '~/helpers/clan';
import { getPositionY } from '~/helpers/contextMenu';
import { floats, percent, thousands } from '~/helpers/formatting';
import { playButtonSound } from '~/web2ClientAPI/web2ClientAPI';

import { Clan, StatisticsValue } from '~/UIKit';

const propTypes = {
  clan: PropTypes.object.isRequired,
  term: PropTypes.string.isRequired,

  currentAccount: PropTypes.object.isRequired,

  onClick: PropTypes.func.isRequired,
  joinClan: PropTypes.func.isRequired,

  t: PropTypes.func.isRequired,
};

class SearchResultTableItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isContextMenuActive: false,
      contextMenuContext: [],
      contextMenuPosition: { x: 0, y: 0 },
    };

    this.onRowClick = this.onRowClick.bind(this);
    this.onContextMenu = this.onContextMenu.bind(this);
    this.onContextMenuItemClick = this.onContextMenuItemClick.bind(this);
    this.onContextMenuStateChange = this.onContextMenuStateChange.bind(this);
  }

  onRowClick() {
    playButtonSound();
    this.props.onClick(this.props.clan);
  }

  onContextMenu(event) {
    const context = this.getContextMenuContext();
    const clientY = getPositionY(event, context);
    this.setState({
      contextMenuContext: context,
      contextMenuPosition: {
        x: event.clientX,
        y: clientY,
      },
    });
  }

  getContextMenuContext() {
    const { t, clan, currentAccount } = this.props;
    const hasActiveApplication = isEmpty(clan.invite) && !!clan.has_application;
    return [
      {
        name: null,
        items: [
          {
            name: t('Профиль клана'),
            value: 'CLAN_PROFILE',
          },
          !this.props.currentAccount.clanId
            ? {
                name: t('Вступить в клан'),
                value: 'JOIN_CLAN',
                disabled: currentAccount.inClanCooldownTill || clanIsFull(clan) || hasActiveApplication,
                tooltip: hasActiveApplication ? t('Заявка уже отправлена') : null,
              }
            : null,
        ],
      },
    ];
  }

  onContextMenuStateChange(isContextMenuActive) {
    this.setState({ isContextMenuActive });
  }

  onContextMenuItemClick(action) {
    const clan = this.props.clan;
    if (action === 'CLAN_PROFILE') {
      this.props.onClick(clan.id);
    } else if (action === 'JOIN_CLAN') {
      this.props.joinClan(
        {
          id: clan.id,
          name: clan.name,
          tag: clan.tag,
          color: clan.hex_color,
          leveling: clan.leveling,
        },
        clan.invite,
      );
    }
  }

  render() {
    const clan = this.props.clan;
    const table_fields = clan && clan.table_fields ? clan.table_fields : {};
    let isHiddenStatistics = table_fields.is_hidden_statistics;
    const isOwn = this.props.currentAccount.clanId === clan.id;
    if (!isHiddenStatistics && clan.members_count === 1) {
      isHiddenStatistics = !!(clan && clan.is_hidden_by_account);
    }

    return (
      <TableRow
        isActive={this.state.isContextMenuActive}
        isHovering
        isOwn={isOwn}
        onContextMenu={this.onContextMenu}
        onClick={this.onRowClick}
      >
        <ContextMenu
          context={this.state.contextMenuContext}
          position={this.state.contextMenuPosition}
          onItemClick={this.onContextMenuItemClick}
          onContextMenuStateChange={this.onContextMenuStateChange}
        />
        <TableBodyCell modify="basis">
          <Clan
            clanName={clan.name}
            clanTag={clan.tag}
            clanColor={clan.hex_color}
            highlight={this.props.term}
            isOwn={isOwn}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="small">{clan.members_count}</TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            isClan
            value={thousands(table_fields.average_battles)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            isClan
            value={percent(table_fields.average_wins_percent)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            isClan
            value={thousands(table_fields.average_xp)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            isClan
            value={thousands(table_fields.average_damage)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            isClan
            value={floats(table_fields.average_frags, 2)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
      </TableRow>
    );
  }
}

SearchResultTableItem.propTypes = propTypes;

export default translate()(SearchResultTableItem);
