import {
  SET_CLAN_BATTLES,
  TOGGLE_BATTLES_ERROR,
  TOGGLE_CLAN_BATTLES_FETCH,
  TOGGLE_SELECTED_BATTLE,
} from '~/Actions/ActionClanBattles';

import type { ActionsType } from '~/Actions/ActionClanBattles';

type IBattlesState = {
  battlesList: unknown[];
  errorMessage: string;
  isError: boolean;
  isFetching: boolean;
  selectedBattle: number;
  teamNumber?: number;
};

const initialState = {
  battlesList: [],
  errorMessage: '',
  isError: false,
  isFetching: true,
  selectedBattle: 0,
};

export const clanBattles = (state: IBattlesState = initialState, action: ActionsType): IBattlesState => {
  switch (action.type) {
    case SET_CLAN_BATTLES:
      return {
        ...state,
        battlesList: action.payload.battles,
      };

    case TOGGLE_BATTLES_ERROR:
      return {
        ...state,
        isError: action.isError,
        errorMessage: action.errorMessage,
      };

    case TOGGLE_SELECTED_BATTLE:
      return {
        ...state,
        selectedBattle: action.selectedBattle,
      };

    case TOGGLE_CLAN_BATTLES_FETCH: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }

    default:
      return state;
  }
};
