import { START_CLAN_EDIT, UPDATE_CLAN_EDIT_ERROR, UPDATE_CLAN_EDIT_STATE } from '~/Actions/ActionClanEdit';

import type { ActionsType } from '~/Actions/ActionClanEdit';
import type { SOCIAL_NETWORKS } from '~/constants';
import type { IClanCommunityUrls } from '~/types/declaration';

// @TODO: refactor errors schema's hell
type IClanEditFieldError =
  | string
  | null
  | undefined
  | {
      message?: string;
      system_error?: boolean;
      socials_error?: IClanCommunityUrls;
    };

type IClanEditErrorsCommon = Nullable<{
  errors: {
    [key in keyof IClanEditStateFields]?: string[];
  };
  socials_error?: string[];
}>;

type IClanEditDescription = {
  error: IClanEditFieldError;
  message?: string;
  isFetching: boolean;
  isValidationError: boolean;
  savedDescription: string;
};

export type IClanEditStateFields = {
  clanDescription?: IClanEditDescription;
} & {
  [key in SOCIAL_NETWORKS]?: {
    savedDescription: string;
    error: IClanEditFieldError;
  };
};

type IClanEditState = IClanEditStateFields & {
  clanColor?: string;
  error?: IClanEditErrorsCommon;
};

export const clanEdit = (state: IClanEditState = {}, action: ActionsType): IClanEditState => {
  switch (action.type) {
    case START_CLAN_EDIT: {
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          isFetching: true,
        },
      };
    }

    case UPDATE_CLAN_EDIT_STATE:
      return {
        ...state,
        [action.field]: {
          ...action.data,
          isFetching: false,
        },
      };

    case UPDATE_CLAN_EDIT_ERROR: {
      let error: IClanEditFieldError = null;

      if (!action.data) {
        error = null;
      } else {
        error = action.data.current || action.data.errors || { message: action.data.error };
        error.system_error = error.system_error || action.data?.system_error;
        error.socials_error = error.socials_error || action.data?.socials_error;
      }

      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          isFetching: false,
          error,
        },
      };
    }

    default:
      return state;
  }
};
