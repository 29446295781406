import classNames from 'classnames';
import get from 'lodash/get';
import React, { PureComponent } from 'react';
import { EntityTypes } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';

import settings from '~/settings';
import { areAllTasksClaimed, getClaimedTasks, lastTaskClaimedAt } from '~/helpers/clanTasks';
import { localizedDateTime } from '~/helpers/internalization';
import { t } from '~/helpers/localization';

import styles from './TaskRow.scss';

import TaskRowBase, { renderCheckedSpan } from './TaskRowBase';

import type { ClanTask } from '~/Actions/ActionClanTasks';

export interface PropsType {
  tasks: Array<ClanTask>;
}

type StateType = {
  animated: boolean;
};

class TaskRowAchievement extends PureComponent<PropsType, StateType> {
  static defaultProps: { tasks: any[] };
  constructor(props) {
    super(props);

    this.state = {
      animated: false,
    };
  }

  public animate() {
    this.setState({ ...this.state, animated: true });
  }

  render() {
    const tasks = this.props.tasks;

    const achievementGranted = areAllTasksClaimed(tasks);
    const lastTaskClaimedAtLocalized = achievementGranted ? localizedDateTime(new Date(lastTaskClaimedAt(tasks))) : 0;
    const claimedTasks = getClaimedTasks(tasks);
    const achievementDescription = t('Выполните все задачи клана и соберите награды');
    const achievementIconTooltip = achievementGranted
      ? renderCheckedSpan(t('Все задачи клана выполнены и награды собраны'))
      : achievementDescription;

    const mediaPath = settings.mediaPath;
    const achievementsData = settings.achievements.data;
    const grandFleetAchievementCd = settings.darwin.grandFleetAchievementCd;
    const grandFleetAchievementSource =
      mediaPath + get(achievementsData, `[${grandFleetAchievementCd}].icons.normal`, 'unknown');

    return (
      <TaskRowBase
        title={t('Выполните все задачи клана')}
        description={achievementDescription}
        binary={false}
        progress={Math.min(claimedTasks.length / tasks.length, 1.0)}
        total={tasks.length}
        isFinished={achievementGranted}
        isClaimed={achievementGranted}
        taskIconTooltip={achievementIconTooltip}
      >
        <WoWSEntity
          type={EntityTypes.ACHIEVEMENT}
          id={grandFleetAchievementCd}
          presentation={{
            bindTooltip: true,
            withTooltip: true,
            hideReceiveText: true,
            hideSpendText: true,
          }}
          customisation={{
            rewardReceiptDate: achievementGranted && lastTaskClaimedAtLocalized ? lastTaskClaimedAtLocalized : '',
          }}
        >
          <div className={styles.achievementResourceWrapper}>
            {achievementGranted ? <span className={classNames(styles.checkIcon, styles.checkIconLarge)} /> : null}
            <div
              className={classNames(styles.achievementIcon, {
                [styles.achievementActiveIcon]: achievementGranted,
              })}
              style={{ backgroundImage: `url('${grandFleetAchievementSource}')` }}
            />
          </div>
        </WoWSEntity>
      </TaskRowBase>
    );
  }
}

TaskRowAchievement.defaultProps = {
  tasks: [],
};

export default TaskRowAchievement;
