import { isCIS } from '~/settings';
import { t } from '~/helpers/localization';

export const clanBrawlsDescription = {
  title: t('Клановый блиц'),
  items: [
    t('Клановый блиц — это сражения, которые проводятся в рамках одного дня.'),
    t('В режиме "Клановый блиц" вы можете сразиться с кланами из других регионов.'),
    t('Итоговое место клана в рейтинге определяется только количеством побед.'),
    t('Побеждая в боях, вы получите различные награды.'),
    t('За высокие места в итоговом рейтинге кланы получают награды в клановую казну.'),
  ],
};

export const clanBattlesDescription = {
  title: t('Клановые бои'),
  items: [
    t('Клановый бой проводится между двумя отрядами на кораблях одного уровня.'),
    t('В течение сезона клановые бои проводятся в среду, четверг, субботу и воскресенье в определённое время.'),
    ...isCIS([], [t('Участвуя в клановых боях, вы можете сразиться с игроками из других регионов.')]),
    t('Существует 5 лиг: Шквала, Бури, Шторма, Тайфуна и Урагана. Кланы распределены в лигах по рейтингу.'),
    t('Побеждая в клановых боях, вы повышаете рейтинг вашего клана, проигрывая - понижаете.'),
    t('За победы в клановых боях вы будете получать сталь и другие ценные награды.'),
    isCIS(
      t(
        'С завершением сезона, кланы, занявшие определённые места в рейтинге региона, получат соответствующие награды в клановую казну.',
      ),
      t(
        'С завершением сезона, кланы, занявшие определённые места в глобальном рейтинге, получат соответствующие награды в клановую казну.',
      ),
    ),
  ],
};
