import { isoToFormattedLocalDate, isoToFormattedLocalTime } from '~/helpers/datetime';
import { t } from '~/helpers/localization';

import { ROLE_NAMES } from '~/roles';

import { sendErrorNotification, sendNotification } from './base';
import {
  formatBold,
  formatClan,
  formatPlayer,
  formatRoleIcon,
  formatRoleWithIcon,
  getMessage,
  getMessageOfMemberNotInClanNotification,
  getMessageOfMembersNotInClanNotification,
  getMessageOfYouAreNotInClanNotification,
  wrapInP,
} from './formatting';

import type { IClanDisplay } from '~/Reducers/ReducerSupply';
import type { IClanMember } from '~/types/declaration';

export const sendClanCreationNotification = (clan: IClanDisplay, cost: number) => {
  sendNotification({
    hasHeader: true,
    header: t('Клан создан:notification_header'),
    message: t('Вы создали клан %(clan)s', {
      clan: formatClan(clan),
    }),
    cost,
  });
};

export const sendClanCreationErrorNotification = (message?: string) => {
  const header = t('Не удалось создать клан:notification_header');
  sendErrorNotification({
    header,
    message,
  });
};

export const sendClanCreationNotEnoughGoldNotification = () => {
  sendClanCreationErrorNotification(t('Недостаточно средств'));
};

export const sendClanCreationTagOrNameNotAvailableNotification = () => {
  sendClanCreationErrorNotification(t('Указанные тег или название заняты'));
};

export const sendClanCreationAlreadyInClanNotification = () => {
  const message = t('Вы уже состоите в клане');
  sendClanCreationErrorNotification(message);
};

// Change Role Notification
export interface SendChangeRoleNotificationProps {
  names: Array<string>;
  roleName: ROLE_NAMES;
  clan: IClanDisplay;
}
export const sendChangeRoleNotification = ({ names, roleName, clan }: SendChangeRoleNotificationProps) => {
  const players = names.map((name) => formatPlayer(name));

  const message = t('Вы назначили %(players)s на должность %(role)s в клане %(clan)s', {
    players: players.join(', '),
    role: formatRoleWithIcon(roleName),
    clan: formatClan(clan),
  });
  sendNotification({
    header: t('Назначение на новую должность:notification_header'),
    message,
  });
};

export const getMessageChangeRoleLowRankError = (members: IClanMember[]) =>
  t('Недостаточно полномочий для изменения должности %(players)s', {
    players: members.map((member) => formatPlayer(member.name, member.roleName)).join(', '),
  });

const CHANGE_ROLE_ERRORS = {
  insufficient_permissions: t('Недостаточно полномочий'),
  excluded_from_clan: getMessageOfYouAreNotInClanNotification,
  members_not_in_clan: getMessageOfMembersNotInClanNotification,
  role_rank_low: getMessageChangeRoleLowRankError,
};
export const sendChangeRoleErrorNotification = (
  reason?: keyof typeof CHANGE_ROLE_ERRORS,
  ...extra: [IClanDisplay | string[], IClanDisplay | IClanMember[]]
) => {
  sendErrorNotification({
    header: t('Не удалось изменить должность:notification_header'),
    message: getMessage(CHANGE_ROLE_ERRORS, reason, ...extra),
  });
};

// Remove Members Notification
export const sendRemoveMembersNotification = (names: string[], clan: IClanDisplay) => {
  const players = names.map((name: string) => formatPlayer(name));
  const message = t('Вы исключили %(players)s из клана %(clan)s', {
    players: players.join(', '),
    clan: formatClan(clan),
  });

  sendNotification({
    header: t('Исключение из клана:notification_header'),
    message,
  });
};

export const getMessageRemoveMembersLowRankError = (members: IClanMember[]) =>
  t('Недостаточно полномочий для исключения %(players)s', {
    players: members.map((member) => formatPlayer(member.name, member.roleName)).join(', '),
  });

const REMOVE_MEMBERS_ERRORS = {
  insufficient_permissions: t('Недостаточно полномочий'),
  excluded_from_clan: getMessageOfYouAreNotInClanNotification,
  members_not_in_clan: getMessageOfMembersNotInClanNotification,
  role_rank_low: getMessageRemoveMembersLowRankError,
};
export const sendRemoveMembersErrorNotification = (
  reason?: keyof typeof REMOVE_MEMBERS_ERRORS,
  ...extra: [IClanDisplay | string[], IClanDisplay | IClanMember[]]
) => {
  sendErrorNotification({
    header: t('Не удалось исключить из клана:notification_header'),
    message: getMessage(REMOVE_MEMBERS_ERRORS, reason, ...extra),
  });
};

export const sendNewCommanderNotification = (member: IClanMember, clan: IClanDisplay) => {
  sendNotification({
    header: t('Назначение командующего флотом:notification_header'),
    message: t('%(name)s стал командующим флотом клана %(clan)s', {
      name: formatPlayer(member.name, member.roleName),
      clan: formatClan(clan),
    }),
  });
};

type ISendChangeCommanderErrorNotification =
  | ((isLeaving: boolean) => void)
  | ((isLeaving: boolean, reason: string, memberName: string, clan: IClanDisplay) => void);
export const sendChangeCommanderErrorNotification: ISendChangeCommanderErrorNotification = (
  isLeaving,
  reason,
  memberName,
  clan,
) => {
  const header = isLeaving
    ? t('Не удалось передать управление и покинуть клан:notification_header')
    : t('Не удалось передать управление:notification_header');

  let message: string | undefined;
  if (reason === 'account_not_in_clan' && memberName && clan) {
    message = getMessageOfMemberNotInClanNotification(memberName, clan);
  }
  sendErrorNotification({
    header,
    message,
  });
};

export const sendCurrentAccountRoleChangedNotification = (roleName: ROLE_NAMES, clan: IClanDisplay) => {
  if (roleName === ROLE_NAMES.COMMANDER) {
    const commandRole = t('Командующим:notification_role');
    sendNotification({
      header: t('Назначение командующего флотом:notification_header'),
      message: t('Вы стали %(role)s флотом клана %(clan)s', {
        role: `${formatRoleIcon(ROLE_NAMES.COMMANDER)}&nbsp;${formatBold(commandRole)}`,
        clan: formatClan(clan),
      }),
    });
    return;
  }

  sendNotification({
    header: t('Назначение на новую должность:notification_header'),
    message: t('Вас назначили на должность %(role)s в клане %(clan)s', {
      role: formatRoleWithIcon(roleName),
      clan: formatClan(clan),
    }),
  });
};

export const sendCurrentAccountTransferredLeadershipNotification = (roleName: ROLE_NAMES, clan: IClanDisplay) => {
  sendNotification({
    header: t('Вы назначены на новую должность:notification_header'),
    message: t('Вы назначены на должность %(role)s в клане %(clan)s', {
      role: formatRoleWithIcon(roleName),
      clan: formatClan(clan),
    }),
  });
};

// Clan Leave Notification
export const sendCurrentAccountRemovedFromClanNotification = (clan: IClanDisplay) => {
  sendNotification({
    hasHeader: true,
    header: t('Вы покинули клан:notification_header'),
    message: t('Вас исключили из клана %(clan)s', {
      clan: formatClan(clan),
    }),
  });
};

export const sendClanLeaveNotification = (clan: IClanDisplay) => {
  sendNotification({
    header: t('Вы покинули клан:notification_header'),
    message: t('Вы покинули клан %(clan)s', {
      clan: formatClan(clan),
    }),
  });
};

export const sendClanDisbandNotification = (clan: IClanDisplay) => {
  sendNotification({
    header: t('Вы распустили клан:notification_header'),
    message: t('Вы распустили клан %(clan)s', {
      clan: formatClan(clan),
    }),
  });
};

export const sendClanLeaveErrorNotification = (reason?: string) => {
  let message: string | undefined;
  if (reason === 'lead_cannot_leave_clan') {
    message = t('Чтобы покинуть клан, вам необходимо передать управление кланом другому игроку');
  }
  sendErrorNotification({
    header: t('Не удалось покинуть клан:notification_header'),
    message,
  });
};

// Accept Application Notification
export const sendAcceptApplicationNotification = (
  player: {
    name: string;
    roleName: ROLE_NAMES;
  },
  clan: IClanDisplay,
) => {
  const message = t('Вы приняли %(player)s в клан %(clan)s', {
    player: formatPlayer(player.name, player.roleName),
    clan: formatClan(clan),
  });
  sendNotification({
    header: t('В клане пополнение:notification_header'),
    message,
  });
};

export const sendAcceptApplicationErrorNotification = (message: string) => {
  const header = t('Не удалось принять в клан:notification_header');
  sendErrorNotification({
    header,
    message,
  });
};

export const sendAcceptApplicationPlayerInOtherClanErrorNotification = (name: string) => {
  const message = t('%(player)s уже состоит в клане', {
    player: formatPlayer(name),
  });
  sendAcceptApplicationErrorNotification(message);
};

export const sendAcceptApplicationClanIsFullErrorNotification = (name: string, clan: IClanDisplay) => {
  const subMessage = t('В клане %(clan)s нет мест', {
    clan: formatClan(clan),
  });
  const player = formatPlayer(name);
  const message = wrapInP(player, subMessage);
  sendAcceptApplicationErrorNotification(message);
};

export const sendAcceptApplicationPlayerHasPermamentBanErrorNotification = (name: string) => {
  const message = t('%(player)s заблокирован', {
    player: formatPlayer(name),
  });
  sendAcceptApplicationErrorNotification(message);
};

export const sendAcceptApplicationPlayerInClanCooldown = (name: string, inClanCooldownTill: string) => {
  const message = t('%(player)s недавно покинул клан. Вы сможете принять его начиная с %(date)s %(time)s', {
    player: formatPlayer(name),
    date: isoToFormattedLocalDate(inClanCooldownTill),
    time: isoToFormattedLocalTime(inClanCooldownTill),
  });
  sendAcceptApplicationErrorNotification(message);
};

export const sendAcceptApplicationPermissionErrorNotification = (name: string) => {
  const subMessage = t('Недостаточно полномочий');
  const player = formatPlayer(name);
  const message = wrapInP(player, subMessage);
  sendAcceptApplicationErrorNotification(message);
};

export const sendAcceptApplicationWithExpiredStatusErrorNotification = (name: string) => {
  const subMessage = t('Время для принятия решения истекло');
  const player = formatPlayer(name);
  const message = wrapInP(player, subMessage);
  sendAcceptApplicationErrorNotification(message);
};

export const sendAcceptApplicationErrorWithPlayerInfo = (name: string) => {
  const subMessage = t('Техническая ошибка');
  const player = formatPlayer(name);
  const message = wrapInP(player, subMessage);
  sendAcceptApplicationErrorNotification(message);
};

export const sendCurrentAccountJoinedClanNotification = (clan: IClanDisplay) => {
  const message = t('Вас приняли в клан %(clan)s', {
    clan: formatClan(clan),
  });
  sendNotification({
    hasHeader: true,
    header: t('Вы вступили в клан:notification_header'),
    message,
  });
};

// Accept Invite Notification
export const sendAcceptInviteNotification = (clan: IClanDisplay) => {
  const message = t('Вы вступили в клан %(clan)s', {
    clan: formatClan(clan),
  });
  sendNotification({
    header: t('Вы вступили в клан:notification_header'),
    message,
  });
};

export const sendAcceptInviteErrorNotification = (message: string) => {
  const header = t('Не удалось вступить в клан:notification_header');
  sendErrorNotification({
    header,
    message,
  });
};

export const sendAcceptInviteYouAreInOtherClanErrorNotification = (clan: IClanDisplay) => {
  const subMessage = t('Вы уже состоите в клане');
  const formattedClan = formatClan(clan);
  const message = wrapInP(formattedClan, subMessage);
  sendAcceptInviteErrorNotification(message);
};

export const sendAcceptInviteClanIsFullErrorNotification = (clan: IClanDisplay) => {
  const message = t('В клане %(clan)s нет мест', {
    clan: formatClan(clan),
  });
  sendAcceptInviteErrorNotification(message);
};

export const sendAcceptInviteClanIsDisbandedErrorNotification = (clan: IClanDisplay) => {
  const message = t('Клан %(clan)s был распущен', {
    clan: formatClan(clan),
  });
  sendAcceptInviteErrorNotification(message);
};

export const sendAcceptInviteYouAreInClanCooldownErrorNotification = (
  clan: IClanDisplay,
  inClanCooldownTill: string,
) => {
  const subMessage = t('Вы недавно покинули клан. Вступление в клан станет доступно %(date)s %(time)s', {
    date: isoToFormattedLocalDate(inClanCooldownTill),
    time: isoToFormattedLocalTime(inClanCooldownTill),
  });
  const formattedClan = formatClan(clan);
  const message = wrapInP(formattedClan, subMessage);
  sendAcceptInviteErrorNotification(message);
};

export const sendAcceptInviteWithExpiredStatusNotification = (clan: IClanDisplay) => {
  const subMessage = t('Время для принятия решения истекло');
  const formattedClan = formatClan(clan);
  const message = wrapInP(formattedClan, subMessage);
  sendAcceptInviteErrorNotification(message);
};

export const sendAcceptInviteDeletedStatusNotification = (clan: IClanDisplay) => {
  const subMessage = t('Приглашение было отозвано');
  const formattedClan = formatClan(clan);
  const message = wrapInP(formattedClan, subMessage);
  sendAcceptInviteErrorNotification(message);
};

export const sendAcceptInviteErrorWithClanInfoNotification = (clan: IClanDisplay) => {
  const subMessage = t('Техническая ошибка');
  const formattedClan = formatClan(clan);
  const message = wrapInP(formattedClan, subMessage);
  sendAcceptInviteErrorNotification(message);
};

// Decline Invite Notification
export const sendDeclineInviteNotification = (clan: IClanDisplay) => {
  const header = t('Вы отклонили приглашение в клан:notification_header');
  const message = t('Вы отклонили приглашение в клан %(clan)s', {
    clan: formatClan(clan),
  });
  sendNotification({
    header,
    message,
  });
};

export const sendDeclineInviteErrorNotification = () => {
  const header = t('Не удалось отклонить приглашение:notification_header');
  sendErrorNotification({ header });
};

// Decline Application Notification
export const sendDeclineApplicationNotification = (name: string, clan: IClanDisplay) => {
  const header = t('Вы отклонили заявку в клан:notification_header');
  const message = t('Вы отклонили заявку %(player)s на вступление в клан %(clan)s', {
    player: formatPlayer(name),
    clan: formatClan(clan),
  });
  sendNotification({
    header,
    message,
  });
};

export const sendDeclineApplicationsNotification = () => {
  const header = t('Заявки отклонены');
  const message = t('Вы отклонили все выбранные заявки в клан');
  sendNotification({ header, message });
};

export const sendDeclineApplicationAlreadyAcceptedErrorNotification = (playerName: string, clan: IClanDisplay) => {
  const header = t('Не удалось отклонить заявку:notification_header');
  const message = t('%(player)s уже принят в клан %(clan)s', {
    player: formatPlayer(playerName),
    clan: formatClan(clan),
  });
  sendErrorNotification({
    header,
    message,
  });
};

export const sendDeclineApplicationErrorNotification = () => {
  const header = t('Не удалось отклонить заявку:notification_header');
  sendErrorNotification({ header });
};

// Send Application Notification
export const sendSendApplicationNotification = (clan: IClanDisplay) => {
  const header = t('Отправлена заявка в клан:notification_header');
  const message = t('Вы отправили заявку на вступление в клан %(clan)s', {
    clan: formatClan(clan),
  });
  sendNotification({
    header,
    message,
  });
};

const SEND_APPLICATION_ERRORS = {
  clan_already_disbanded: (clan: IClanDisplay) => t('Клан %(clan)s был распущен', { clan: formatClan(clan) }),
  account_already_in_clan: (clan: IClanDisplay) => [formatClan(clan), t('Вы уже состоите в клане')],
  too_many_applications: (clan: IClanDisplay) => [
    formatClan(clan),
    t('Вы отправили максимальное количество заявок на вступление в клан в сутки'),
  ],
  default: (clan: IClanDisplay) => [formatClan(clan), t('Техническая ошибка')],
};
export const sendSendApplicationErrorNotification = (
  reason: keyof typeof SEND_APPLICATION_ERRORS,
  clan: IClanDisplay,
) => {
  sendErrorNotification({
    header: t('Не удалось отправить заявку в клан:notification_header'),
    message: getMessage(SEND_APPLICATION_ERRORS, reason, clan),
  });
};

// Send Invite Notification
export const sendSendInviteNotification = (name: string, clan: IClanDisplay) => {
  const header = t('Отправлено приглашение в клан:notification_header');
  const message = t('Вы пригласили %(player)s в клан %(clan)s', {
    player: formatPlayer(name),
    clan: formatClan(clan),
  });
  sendNotification({
    header,
    message,
  });
};

export const sendSendInviteAlreadySentNotification = (name: string, clan: IClanDisplay) => {
  const header = t('Отправлено приглашение в клан:notification_header');
  const message = t('%(player)s был приглашён в клан %(clan)s', {
    player: formatPlayer(name),
    clan: formatClan(clan),
  });
  sendNotification({
    header,
    message,
  });
};

export const sendSendInviteErrorNotification = (message: string) => {
  const header = t('Не удалось отправить приглашение:notification_header');
  sendErrorNotification({
    header,
    message,
  });
};

export const sendSendInviteDailyInvitesLimitReachedErrorNotification = (name: string) => {
  const formattedPlayer = formatPlayer(name);
  const subMessage = t('Отправлено максимальное количество приглашений в сутки');
  const message = wrapInP(formattedPlayer, subMessage);
  sendSendInviteErrorNotification(message);
};

export const sendSendInvitePlayerInOtherClanErrorNotification = (name: string) => {
  const message = t('%(player)s уже состоит в клане', {
    player: formatPlayer(name),
  });
  sendSendInviteErrorNotification(message);
};

export const sendSendInviteClanIsFullErrorNotification = (name: string, clan: IClanDisplay) => {
  const formattedPlayer = formatPlayer(name);
  const subMessage = t('В клане %(clan)s нет мест', {
    clan: formatClan(clan),
  });
  const message = wrapInP(formattedPlayer, subMessage);
  sendSendInviteErrorNotification(message);
};

export const sendSendInvitePlayerHasPermanentBanErrorNotification = (name: string) => {
  const message = t('%(player)s заблокирован', {
    player: formatPlayer(name),
  });
  sendSendInviteErrorNotification(message);
};

export const sendSendInvitePermissionsErrorNotification = (name: string) => {
  const formattedPlayer = formatPlayer(name);
  const subMessage = t('Недостаточно полномочий');
  const message = wrapInP(formattedPlayer, subMessage);
  sendSendInviteErrorNotification(message);
};

export const sendSendInviteYouAreNotInClanNotification = (name: string, clan: IClanDisplay) => {
  const formattedPlayer = formatPlayer(name);
  const subMessage = getMessageOfYouAreNotInClanNotification(clan);
  const message = wrapInP(formattedPlayer, subMessage);
  sendSendInviteErrorNotification(message);
};

export const sendSendInviteErrorWithPlayerInfoNotification = (name: string) => {
  const formattedPlayer = formatPlayer(name);
  const subMessage = t('Техническая ошибка');
  const message = wrapInP(formattedPlayer, subMessage);
  sendSendInviteErrorNotification(message);
};

// Cancel Invite Notifications
export const sendCancelInviteNotification = () => {
  const header = t('Приглашение удалено');
  const message = t('Приглашение удалено');
  sendNotification({ header, message });
};

export const sendCancelInviteErrorNotification = () => {
  const header = t('Не удалось удалить приглашение');
  sendErrorNotification({ header });
};

// Clan Edit Recommendation Notification
export const sendClanRecommendationCompleteNotification = () => {
  sendNotification({
    header: t('Требования клана сохранены:notification_header'),
    message: t('Требования клана сохранены'),
  });
};

// Clan Edit Description Notification
export const sendClanEditDescriptionPreModerationNotification = () => {
  sendNotification({
    header: t('Описание вашего клана обновится, как только оно будет одобрено модератором:notification_header'),
    message: t('Описание вашего клана обновится, как только оно будет одобрено модератором.'),
  });
};

export const sendClanEditDescriptionCompleteNotification = () => {
  sendNotification({
    header: t('Описание клана сохранено:notification_header'),
    message: t('Описание клана сохранено'),
  });
};

const CLAN_EDIT_DESCRIPTION_ERRORS = {
  insufficient_permissions: t('Недостаточно полномочий'),
  excluded_from_clan: getMessageOfYouAreNotInClanNotification,
};
export const sendClanEditDescriptionErrorNotification = (
  reason?: keyof typeof CLAN_EDIT_DESCRIPTION_ERRORS,
  extra?: IClanDisplay,
) => {
  sendErrorNotification({
    header: t('Не удалось сохранить описание:notification_header'),
    message: getMessage(CLAN_EDIT_DESCRIPTION_ERRORS, reason, extra),
  });
};

// Clan Edit Color Notification
export const sendClanEditColorCompleteNotification = () => {
  sendNotification({
    header: t('Цвет тега клана изменён:notification_header'),
    message: t('Цвет тега клана изменён'),
  });
};

const CLAN_EDIT_COLOR_ERRORS = {
  insufficient_permissions: t('Недостаточно полномочий'),
  excluded_from_clan: getMessageOfYouAreNotInClanNotification,
};
export const sendClanEditColorErrorNotification = (reason?: string, extra?: IClanDisplay) => {
  sendErrorNotification({
    header: t('Не удалось изменить цвет тега:notification_header'),
    message: getMessage(CLAN_EDIT_COLOR_ERRORS, reason, extra),
  });
};

// Clan Rename Notification
export const sendClanRenamePreModerationNotification = () => {
  sendNotification({
    header: t('Название и тег вашего клана обновятся, как только они будут одобрены модератором:notification_header'),
    message: t('Название и тег вашего клана обновятся, как только они будут одобрены модератором.'),
  });
};

export const sendClanRenameCompleteNotification = (
  oldClan: IClanDisplay,
  newTagAndName: Pick<IClanDisplay, 'tag' | 'name'>,
  cost: number,
) => {
  const oldClanLine = t('Старые: %(oldClan)s', {
    oldClan: formatClan(oldClan),
  });
  const newClanLine = t('Новые: %(newClan)s', {
    newClan: formatClan({ color: oldClan.color, ...newTagAndName }),
  });
  const message = wrapInP(oldClanLine, newClanLine);
  sendNotification({
    hasHeader: true,
    header: t('Тег и/или название клана изменены:notification_header'),
    message,
    cost,
  });
};

const CLAN_RENAME_ERRORS = {
  insufficient_permissions: t('Недостаточно полномочий'),
  no_gold: t('Недостаточно средств'),
  rename_in_cooldown: t('Тег и название недавно были изменены'),
  clan_already_exist: t('Указанные тег или название заняты'),
  excluded_from_clan: getMessageOfYouAreNotInClanNotification,
  restriction_error: (message: string) => message,
};

export const sendClanRenameErrorNotification = (
  reason: keyof typeof CLAN_RENAME_ERRORS,
  extra?: ExtractFunctionArgumentTypes<typeof CLAN_RENAME_ERRORS>,
) => {
  sendErrorNotification({
    header: t('Не удалось изменить тег и название:notification_header'),
    message: getMessage(CLAN_RENAME_ERRORS, reason, extra),
  });
};

export const distributeResourcesError = () => {
  sendErrorNotification({
    header: t('Распределение ресурсов'),
    message: t('Произошла ошибка!'),
  });
};
