import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import React from 'react';
import { translate } from 'react-i18next';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import {
  ClanName,
  DivTooltip,
  ErrorLoad,
  PageTitle,
  Spinner,
  StepCSSTransitionGroup,
  Tooltip,
  TooltipBody,
  TooltipHeader,
  Guidingtip,
  GUIDINGTIP_POSITIONS,
  GUIDINGTIP_ALIGNS,
  GUIDINGTIP_THEMES,
  useOnboarding,
} from '@wg/wows-react-uikit';

import settings from '~/settings';
import { isIngame } from '~/commonmenu';
import { ONBOARDING_GUIDES, ONBOARDING_GUIDES_CONFIG } from '~/const/onboarding';
import { BATTLE_TYPES, SOCIAL_NETWORKS } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { isoToFormattedLocalDate } from '~/helpers/datetime';
import { getLastRegularSeason } from '~/helpers/ladder';
import { t } from '~/helpers/localization';
import { getSeasonTeams } from '~/helpers/seasons';
import { hasPermission, PERMISSIONS } from '~/roles';

import ContainerSupply from '~/Containers/ContainerSupply';
import {
  Back,
  BattleSelector,
  ButtonJoinClan,
  ClanLeagueSign,
  ClanTitle,
  ClanWallet,
  LadderSeasonsDropdown,
  LadderTeamsDropdown,
  OwnClanTitle,
  Ratings,
} from '~/UIKit';

import styles from './ViewClanProfile.scss';

import type { WowsLadderRatingTeam, WowsLadderType } from '~/Actions/ActionClanProfile';
import type { ClanType } from '~/Actions/ActionInvites';
import type { IClansState } from '~/Reducers/ReducerClanProfile';
import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';
import type { IClan } from '~/Reducers/ReducerSupply';

const inGame = isIngame();

export type ViewClanProfile_Props = {
  clanId: number;
  currentAccount: ICurrentAccountState;
  currentSeasonType: string;
  currentSeason?: number;
  clan: IClan;
  statistics: {
    totalMembers: number;
    maxMembersCount: number;
    averageBattlesCounts: number;
    averageWinsPercentage: number;
    averageExperiencePerBattle: number;
    averageDamagePerBattle: number;
    winsCount: number;
  };
  selectedBattleType: BATTLE_TYPES;
  membersError: string;
  isHiddenStatistics: boolean;
  isMissingStatistics: boolean;
  isFetchingJoinClan: boolean;
  isOwn: boolean;
  isSidebarOpened: boolean;
  wowsLadder: IClansState['wowsLadder'];
  supplySelectedBuilding: any;
  isMapZoomed: boolean;
  isRenameInProcess: boolean;
  clanRulesContent: any;
  allBonusesActivated: boolean;
  lastRegularSeasonData: WowsLadderType;

  onSeasonChange: (season: number, clanId: number) => void;
  onTeamNumberChange: (selectedTeamNumber: number, clanId: number) => void;
  onBattleTypeChange: (battleType: any, clanId?: number) => void;
  onClanReloadClick: () => void;
  onClanInfoClick: (clanId: number) => void;
  onClanInfoEditClick: (clanId: number) => void;
  onAcceptInvite: () => void;
  onSendApplication: (clan: ClanType) => void;
  onRulesDialogClick: () => void;
  onLeaveClick: () => void;
  onNameEditClick: () => void;
  onRecommendationsClanSettingsClick: () => void;
  accumulativeResource: number;
  selectedTeamNumber: number;
  hideLeagueIcon: boolean;
};

const ViewClanProfile: React.FC<ViewClanProfile_Props> = (props) => {
  const {
    clanId,
    currentAccount,
    currentSeasonType,
    currentSeason,
    clan,
    statistics,
    selectedBattleType,
    membersError,
    isHiddenStatistics,
    isMissingStatistics,
    isFetchingJoinClan,
    isOwn,
    isSidebarOpened,
    wowsLadder,
    supplySelectedBuilding,
    isMapZoomed,
    isRenameInProcess,
    clanRulesContent,
    allBonusesActivated,
    lastRegularSeasonData,
    onSeasonChange,
    onTeamNumberChange,
    onBattleTypeChange,
    onClanReloadClick,
    onClanInfoClick,
    onClanInfoEditClick,
    onAcceptInvite,
    onSendApplication,
    onRulesDialogClick,
    onLeaveClick,
    onNameEditClick,
    onRecommendationsClanSettingsClick,
    accumulativeResource,
    selectedTeamNumber,
    hideLeagueIcon,
  } = props;

  const profileRatings = React.useRef(null);
  const profileHead = React.useRef(null);

  const isCanEditSocials = isOwn && hasPermission(currentAccount.roleName, PERMISSIONS.CHANGE_SETTINGS);
  const hasClanSocials = clan?.communityUrls && Object.keys(clan.communityUrls)?.length > 0;

  const hasDiscordService = settings.communityServices?.includes?.(SOCIAL_NETWORKS.DISCORD);
  // Complete onboarding step if social links already set
  const { isCurrentStepCompleted, completeCurrentStep } = useOnboarding({
    stepName: ONBOARDING_GUIDES.CLAN_ABOUT_DISCORD,
  });

  if (!isCurrentStepCompleted && isCanEditSocials && hasClanSocials) {
    completeCurrentStep();
  }

  const renderClanName = () => {
    return clan ? <ClanName clanTag={clan.tag} clanColor={clan.color} clanName={clan.name} /> : null;
  };

  const handleClanInfoClick = () => {
    dwhExport.push(DWH_EVENTS.SUPPLY.ABOUT_CLICK);
    void playButtonClickSound();
    onClanInfoClick(clanId);
  };

  const handleClanInfoEditClick = () => {
    onClanInfoEditClick(clanId);
  };

  const handleBattleTypeChange = (battleType: BATTLE_TYPES) => {
    if (isOwn) {
      let battleTypeForDwh: string = battleType;
      if (battleType === BATTLE_TYPES.COOPERATIVE) {
        battleTypeForDwh = 'coop';
      }
      dwhExport.push(DWH_EVENTS.MEMBERS.SELECT_BATTLE_TYPE, {
        battle_type: battleTypeForDwh,
      });
    }
    onBattleTypeChange(battleType, clanId);
  };

  const handleSeasonChange = (season: number) => {
    if (isOwn) {
      dwhExport.push(DWH_EVENTS.MEMBERS.SELECT_SEASON, {
        season: season,
      });
    }
    onSeasonChange(season, clanId);
  };

  const handleTeamNumberChange = (selectedTeamNumber: number) => {
    if (currentAccount.clanId === clanId) {
      dwhExport.push(DWH_EVENTS.MEMBERS.SELECT_TEAM_NUMBER, {
        teamNumber: selectedTeamNumber,
      });
    }
    onTeamNumberChange(selectedTeamNumber, clanId);
  };

  const renderDisbandedClan = () => (
    <div>
      <div className={styles.back}>
        <Back />
      </div>
      <StepCSSTransitionGroup level={1}>
        {/* @TODO: fix react kit: optional subtitle type */}
        {/* @ts-ignore */}
        <PageTitle key="page-title" title={renderClanName()} subTitle={null} />
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup level={2}>
        <div className={styles.message}>
          <p className={styles.messageText}>{t('Информация недоступна: клан распущен')}</p>
        </div>
      </StepCSSTransitionGroup>
    </div>
  );

  const renderTeamsMenu = () => (
    <div className={styles.seasonMenu}>
      <LadderTeamsDropdown
        selectedTeamNumber={selectedTeamNumber}
        season={currentSeason}
        statistics={statistics}
        onTeamNumberChange={handleTeamNumberChange}
      />
    </div>
  );

  const renderSeasonsMenu = () => {
    if (currentSeason === null) {
      return null;
    }

    return (
      <div className={styles.seasonMenu}>
        <LadderSeasonsDropdown
          selectedSeasonId={currentSeason}
          selectedSeasonType={currentSeasonType}
          onSeasonChange={handleSeasonChange}
          dropdownPosition="top"
        />
      </div>
    );
  };

  const renderPreModerationHelp = () => {
    const preModeration = clan.preModeration;
    if (preModeration && (preModeration.includes('name') || preModeration.includes('tag'))) {
      return (
        <div className={styles.ownPreModerationWrapper}>
          <DivTooltip
            tooltipBody={
              <Tooltip>
                <TooltipHeader isBold={true}>{t('Профиль вашего клана находится на премодерации')}</TooltipHeader>
                <TooltipBody>
                  {t('Название и тег вашего клана обновятся, как только они будут одобрены модератором.')}
                </TooltipBody>
              </Tooltip>
            }
          >
            <div className={styles.ownPreModeration}></div>
          </DivTooltip>
        </div>
      );
    }
    return null;
  };

  const renderActiveClan = () => {
    const league = lastRegularSeasonData ? lastRegularSeasonData.league : 0;
    const isIssetSeasonDataLeague = isNumber(league);
    const wowsLadderInfo = wowsLadder ? wowsLadder[clan.id] : null;
    const LastRegularSeason = getLastRegularSeason();
    let seasonTeams: Nullable<WowsLadderRatingTeam[]> = null;
    if (wowsLadderInfo && LastRegularSeason) {
      seasonTeams = !isEmpty(wowsLadderInfo) ? getSeasonTeams(wowsLadderInfo.ratings, LastRegularSeason.id) : null;
    }

    let clanLeagueSign: React.ReactNode = null;

    if (!hideLeagueIcon && isIssetSeasonDataLeague) {
      let clanLeagueSignProps: { [arg: string]: any } = {
        clanDivisionRating: lastRegularSeasonData.division_rating,
        clanMaxDivisionRating: lastRegularSeasonData.division_rating_max,
        clanDivision: lastRegularSeasonData.division,
        clanLeague: lastRegularSeasonData.league,
        clanStage: lastRegularSeasonData.stage,
      };

      if (isOwn) {
        clanLeagueSignProps = {
          leadingTeamNumber: lastRegularSeasonData.team_number,
          teamsRating: seasonTeams,
          clanDivisionRating: lastRegularSeasonData.division_rating,
          clanMaxDivisionRating: lastRegularSeasonData.division_rating_max,
          clanDivision: lastRegularSeasonData.division,
          clanLeague: lastRegularSeasonData.league,
          clanStage: lastRegularSeasonData.stage,
          isFullSize: false,
        };
      }

      clanLeagueSign = (
        <div className={styles.clanLeagueSignWrapper}>
          <ClanLeagueSign {...clanLeagueSignProps} />
        </div>
      );
    }

    // Guidingtips
    const guidingtipClansAboutDiscord =
      isCanEditSocials && !hasClanSocials && hasDiscordService
        ? ONBOARDING_GUIDES_CONFIG.CLAN_ABOUT_DISCORD_MEMBER
        : ONBOARDING_GUIDES_CONFIG.CLAN_ABOUT_DISCORD_GUEST;

    const hasClanDescription = !!(clan.rawDescription && clan.rawDescription.trim());

    const clanTitle = isOwn ? (
      <div className={styles.ownClanTitleWrap}>
        {renderPreModerationHelp()}
        <OwnClanTitle
          needMenu
          key="clan-title"
          clanTag={clan.tag}
          clanColor={clan.color}
          clanLevel={clan.leveling}
          accumulativeClanResource={clan.accumulativeResource}
          clanName={clan.name}
          hasClanDescription={hasClanDescription}
          isRenameInProcess={isRenameInProcess}
          roleName={currentAccount.roleName}
          allBonusesActivated={allBonusesActivated}
          clanRulesContent={clanRulesContent}
          onClanInfoEditClick={handleClanInfoEditClick}
          onRecommendationsClanSettingsClick={onRecommendationsClanSettingsClick}
          onNameEditClick={onNameEditClick}
          onLeaveClick={onLeaveClick}
          onRulesDialogClick={onRulesDialogClick}
        />
      </div>
    ) : (
      <div className={styles.clanTitleWrap}>
        <ClanTitle
          key="clan-title"
          clan={clan}
          hasClanDescription={hasClanDescription}
          currentAccount={currentAccount}
          title={renderClanName()}
          subTitle={t('Клан создан %(date)s', {
            date: isoToFormattedLocalDate(clan.createdAt),
          })}
          isOwn={isOwn}
          clanId={clanId}
          isFetchingJoinClan={isFetchingJoinClan}
          onAcceptInvite={onAcceptInvite}
          onSendApplication={onSendApplication}
        />
      </div>
    );

    const clanActions = (
      <div
        className={classNames(styles.clanActions, {
          [styles.clanActionsOwn]: isOwn,
        })}
      >
        {isFetchingJoinClan ? (
          <Spinner />
        ) : (
          <>
            {currentAccount.id && !currentAccount.clanId ? (
              <ButtonJoinClan
                clan={clan}
                currentAccount={currentAccount}
                onAcceptInvite={onAcceptInvite}
                onSendApplication={onSendApplication}
                onClick={() => {
                  dwhExport.push(DWH_EVENTS.CLAN_BASE.OPEN_APPLICATION_MODAL, {
                    clan_id: clan.id,
                  });
                }}
              />
            ) : null}
            <div className={styles.clanActionBtnWrapper}>
              <Guidingtip
                name={guidingtipClansAboutDiscord.name}
                header={guidingtipClansAboutDiscord.header}
                content={guidingtipClansAboutDiscord.content}
                position={GUIDINGTIP_POSITIONS.BOTTOM}
                align={{
                  0: GUIDINGTIP_ALIGNS.LEFT,
                  1800: GUIDINGTIP_ALIGNS.CENTER,
                }}
                theme={GUIDINGTIP_THEMES.CLIENT}
                delay={1000}
                withBeacon={true}
              >
                <button className={styles.clanActionBtn} onClick={handleClanInfoClick}>
                  {t('About clan')}
                </button>
              </Guidingtip>
            </div>
          </>
        )}
      </div>
    );

    let isHeaderFooterHidden = false;

    if (supplySelectedBuilding || isMapZoomed) {
      isHeaderFooterHidden = true;
    }

    const battleTypes = [];

    settings.battleTypes.forEach((e) => {
      battleTypes.push(e);
    });

    return (
      <div>
        <div className={styles.supply}>
          <ContainerSupply clanId={clanId} />
        </div>

        <div className={`${styles.profileHead} ${isHeaderFooterHidden ? styles.isHidden : ''}`} ref={profileHead}>
          <StepCSSTransitionGroup level={1}>
            <div className={styles.profileHeadTitle}>
              {clanLeagueSign}
              <div>
                {clanTitle}
                {clanActions}
              </div>
            </div>
          </StepCSSTransitionGroup>
        </div>
        <div
          className={`${styles.profileRatings} ${isHeaderFooterHidden || isSidebarOpened ? styles.isHidden : ''}`}
          ref={profileRatings}
        >
          <StepCSSTransitionGroup level={2}>
            <div className={styles.battleSelectorWrap}>
              <BattleSelector
                clanId={clan.id}
                key="battle-selector"
                items={battleTypes}
                selected={selectedBattleType}
                onBattleTypeChange={handleBattleTypeChange}
                dropdownPosition="top"
              />
            </div>
            {selectedBattleType === BATTLE_TYPES.REGULAR_CVC || selectedBattleType === BATTLE_TYPES.BRAWL_CVC
              ? renderSeasonsMenu()
              : null}
            {selectedBattleType === BATTLE_TYPES.REGULAR_CVC || selectedBattleType === BATTLE_TYPES.BRAWL_CVC
              ? renderTeamsMenu()
              : null}
            <Ratings
              key="ratings"
              clanId={clan.id}
              currentSeason={currentSeason}
              membersError={!!membersError || isMissingStatistics}
              statistics={statistics}
              isHiddenStatistics={isHiddenStatistics}
            />
          </StepCSSTransitionGroup>
        </div>
        {isOwn ? (
          <div className={inGame ? styles.clanWallet : styles.clanWalletOffset}>
            <ClanWallet clanResource={clan.personalResource} accumulativeResource={accumulativeResource} />
          </div>
        ) : null}
      </div>
    );
  };

  const renderError = () => (
    <ErrorLoad
      isFlat
      key="error-load"
      message={clan.error || t('Произошла ошибка. Повторите попытку позже')}
      onReloadClick={onClanReloadClick}
    />
  );

  if (!clan) {
    return null;
  }

  if (clan.error) {
    return <StepCSSTransitionGroup level={1}>{renderError()}</StepCSSTransitionGroup>;
  }

  if (clan.isDisbanded) {
    return renderDisbandedClan();
  }

  return renderActiveClan();
};

// @TODO: update i18n
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export default React.memo(translate()(ViewClanProfile) as React.FC<ViewClanProfile_Props>);
