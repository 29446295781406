import L from 'leaflet';
import moment from 'moment';
import { type Map } from 'react-leaflet';

import settings from '~/settings';

import { SupplyNotifications } from '~/web2ClientAPI/web2ClientAPI';

import { t } from './localization';

import type { IBuildings, ExtraBuildingsDataInterface, BUILDINGS } from '~/Actions/ActionSupply';
import type { RootState } from '~/store';
import type { ISupplyBuilding, ISupplyBuildings } from '~/store/slices/settingsSlice';

export const BUILDING_TYPES = Object.freeze({
  NAVY_YARD: 'navy_yard',
  DRY_DOCK: 'dry_dock',
  ENGINEERING: 'engineering',
  BARRACKS: 'barracks',
  MONUMENT: 'monument',
});

export const getClassClanLeverWrapper = (clanLevel: number) => {
  if (clanLevel > 11) {
    return 3;
  }
  if (clanLevel > 8) {
    return 2;
  }
  if (clanLevel > 4) {
    return 1;
  }
  if (clanLevel > 0) {
    return 0;
  }

  return null;
};

export const needShowIntroduction = (state: RootState) => {
  const { isEnabled, releaseDate } = settings.supply;
  const { clanId, joinedAtClan } = state.currentAccount;
  const { isShown } = state.introduction;

  return isEnabled && !isShown && !!clanId && joinedAtClan && moment(joinedAtClan).isBefore(releaseDate);
};

export const layersOrder = {
  vessels: 2000,
  ships: 1000,
  superships_home: 90,
  steel_yard: 80,
  coal_yard: 70,
  dry_dock: 60,
  university: 50,
  design_department: 40,
  shipbuilding_factory: 30,
  headquarters: 20,
  treasury: 20,
  academy: 10,
  monument: 5,
  paragon_yard: 0,
};

export const OWN_BASE_ACTIONS = ['open_treasury'];

export const getSoundPositions = (buildings: Nullable<ISupplyBuildings>) => {
  const soundPositions: {
    name: keyof ExtraBuildingsDataInterface;
    x: string;
    y: string;
  }[] = [];
  if (!buildings) {
    return soundPositions;
  }

  for (const key in buildings) {
    if (Object.prototype.hasOwnProperty.call(buildings, key)) {
      let coords = buildings[key as BUILDINGS]?.markerPosition;

      if (!coords) {
        coords = [-100, -100];
        console.error('didn`t get buildings coordinates');
      }

      soundPositions.push({
        name: key as BUILDINGS,
        x: coords[1].toFixed(5),
        y: coords[0].toFixed(5),
      });
    }
  }

  return soundPositions;
};

export const navigateToPrevPosition = (
  prevPosition: Nullable<L.LatLngBoundsExpression>,
  Map?: Map['leafletElement'],
) => {
  if (!Map) {
    return;
  }

  Map.dragging.enable();
  Map.scrollWheelZoom.enable();

  if (prevPosition) {
    Map.fitBounds(prevPosition, { animate: false });
  }
};

export const navigateToBuilding = (
  buildingId: BUILDINGS,
  isSidebarOpened: boolean,
  Map: Map['leafletElement'],
  buildings: Nullable<ISupplyBuildings>,
) => {
  if (window.tooltipProvider) {
    window.tooltipProvider.hide(true);
  }

  if (Map !== null && buildings !== null) {
    const level = buildings[buildingId].level;
    const geo = buildings[buildingId].levels[level].geo?.contour;
    if (geo) {
      const polygon = L.polygon(geo);
      const polyBounds = polygon.getBounds();

      if (isSidebarOpened) {
        Map.fitBounds(polyBounds, {
          animate: false,
          paddingTopLeft: [200, 150],
          paddingBottomRight: [500, 150],
        });
      } else {
        Map.fitBounds(polyBounds, {
          animate: false,
          paddingTopLeft: [200, 150],
          paddingBottomRight: [200, 150],
        });
      }
    }
    Map.dragging.disable();
    Map.scrollWheelZoom.disable();
  }
};

export const sendBuildingUpgradeNotification = (buildings: ISupplyBuildings, buildingId?: keyof IBuildings) => {
  const building = buildingId && buildings[buildingId];
  if (!building) {
    return;
  }

  const meta = building.levels[building.level].meta;

  SupplyNotifications.sendBuildSuccessNotification({
    title: building.levels[building.level].title,
    message: meta.bonus,
    cost: meta.cost,
    level: building.level,
    maxLevel: building.maxLevel,
  });
};

export const getBuildingBonusesList = (building: ISupplyBuilding) => {
  const isSuperShipsHome = building.name === 'superships_home';
  const bonuses: string[] = [];
  let bonus = '';

  const level = building.levels[building.level];

  if (isSuperShipsHome) {
    for (const idx in building.levels) {
      if (Number(idx) <= building.level) {
        bonus = building.levels?.[idx]?.meta?.totalBonus;
        if (bonus || bonus !== '') {
          bonuses.push(bonus);
        }
      }
    }
  } else {
    bonuses.push(!level.meta.totalBonus || level.meta.totalBonus === '' ? level.meta.function : level.meta.totalBonus);
  }

  return bonuses;
};

type IBonusCategories = Array<{
  title: string;
  buildings: BUILDINGS[];
}>;

export const BONUS_CATEGORIES: IBonusCategories = [
  {
    title: t('Корабли'),
    buildings: ['dry_dock', 'shipbuilding_factory', 'superships_home'],
  },
  {
    title: t('Ресурсы'),
    buildings: ['coal_yard', 'steel_yard', 'paragon_yard'],
  },
  {
    title: t('Опыт'),
    buildings: ['university', 'design_department', 'academy'],
  },
  {
    title: t('Клан'),
    buildings: ['treasury', 'headquarters'],
  },
  {
    title: t('Достижения'),
    buildings: ['monument', 'vessels', 'ships'],
  },
];
