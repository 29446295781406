import L from 'leaflet';

import academyImage from './images/markers/view-supply-map-academy.svg';
import coal_yardImage from './images/markers/view-supply-map-coal_yard.svg';
import design_departmentImage from './images/markers/view-supply-map-design_department.svg';
import dry_dockImage from './images/markers/view-supply-map-dry_dock.svg';
import headquartersImage from './images/markers/view-supply-map-headquarters.svg';
import monumentImage from './images/markers/view-supply-map-monument.svg';
import paragonYardImage from './images/markers/view-supply-map-paragon_yard.svg';
import shipbuilding_factoryImage from './images/markers/view-supply-map-shipbuilding_factory.svg';
import shipsImage from './images/markers/view-supply-map-ships.svg';
import steel_yardImage from './images/markers/view-supply-map-steel_yard.svg';
import supershipHomeImage from './images/markers/view-supply-map-superships_home.svg';
import treasuryImage from './images/markers/view-supply-map-treasury.svg';
import universityImage from './images/markers/view-supply-map-university.svg';
import vesselsImage from './images/markers/view-supply-map-vessels.svg';

const iconParams = {
  5: {
    iconSize: [20, 26],
    iconAnchor: [10, 26],
  },
  6: {
    iconSize: [32, 42],
    iconAnchor: [16, 42],
  },
  7: {
    iconSize: [32, 42],
    iconAnchor: [16, 42],
  },
  8: {
    iconSize: [32, 42],
    iconAnchor: [16, 42],
  },
};

const markers = (id, zoom, isDraggable) => {
  const markerList = {
    headquarters: L.icon({
      iconUrl: headquartersImage,
      className: `${isDraggable ? '' : 'markerIcon'} headquartersMarkerIcon`,
      ...iconParams[zoom],
    }),
    academy: L.icon({
      iconUrl: academyImage,
      className: `${isDraggable ? '' : 'markerIcon'} academyMarkerIcon`,
      ...iconParams[zoom],
    }),
    monument: L.icon({
      iconUrl: monumentImage,
      className: `${isDraggable ? '' : 'markerIcon'} monumentMarkerIcon`,
      ...iconParams[zoom],
    }),
    university: L.icon({
      iconUrl: universityImage,
      className: `${isDraggable ? '' : 'markerIcon'} universityMarkerIcon`,
      ...iconParams[zoom],
    }),
    design_department: L.icon({
      iconUrl: design_departmentImage,
      className: `${isDraggable ? '' : 'markerIcon'} design_departmentMarkerIcon`,
      ...iconParams[zoom],
    }),
    shipbuilding_factory: L.icon({
      iconUrl: shipbuilding_factoryImage,
      className: `${isDraggable ? '' : 'markerIcon'} shipbuilding_factoryMarkerIcon`,
      ...iconParams[zoom],
    }),

    dry_dock: L.icon({
      iconUrl: dry_dockImage,
      className: `${isDraggable ? '' : 'markerIcon'} dry_dockMarkerIcon`,
      ...iconParams[zoom],
    }),
    coal_yard: L.icon({
      iconUrl: coal_yardImage,
      className: `${isDraggable ? '' : 'markerIcon'} coal_yardMarkerIcon`,
      ...iconParams[zoom],
    }),
    steel_yard: L.icon({
      iconUrl: steel_yardImage,
      className: `${isDraggable ? '' : 'markerIcon'} steel_yardMarkerIcon`,
      ...iconParams[zoom],
    }),
    treasury: L.icon({
      iconUrl: treasuryImage,
      className: 'markerIcon treasuryMarkerIcon',
      ...iconParams[zoom],
    }),
    paragon_yard: L.icon({
      iconUrl: paragonYardImage,
      className: 'markerIcon paragonYardMarkerIcon',
      ...iconParams[zoom],
    }),
    ships: L.icon({
      iconUrl: shipsImage,
      className: 'markerIcon shipsMarkerIcon',
      ...iconParams[zoom],
    }),
    superships_home: L.icon({
      iconUrl: supershipHomeImage,
      className: 'markerIcon superships_homeMarkerIcon',
      ...iconParams[zoom],
    }),
    vessels: L.icon({
      iconUrl: vesselsImage,
      className: 'markerIcon vesselsMarkerIcon',
      ...iconParams[zoom],
    }),
  };

  return markerList[id];
};

export default markers;
