import { t } from '~/helpers/localization';
import { isChinaRealm } from '~/helpers/realm';

import credits from './images/credits_icon.png';
import lootBoxFifteenLevel from './images/icon_reward_15.png';
import lootBoxFifteenLevelCn from './images/icon_reward_cn_15.png';

import type {
  IResourceImageCustomization,
  ResourceType,
} from '@wg/wows-react-uikit/components/ResourceImage/ResourceImage';

type ResourceData = { type: ResourceType } | IResourceImageCustomization;

interface RewardData {
  title: string;
  description: string;
  resource: ResourceData;
  clan?: unknown;
}

export const REWARDS_GOAL_TO_DATA: Record<number, RewardData> = {
  1: {
    title: t('Кредиты'),
    description: '',
    resource: { image: credits },
  },
  3: {
    title: t('Малый контейнер'),
    description: '',
    resource: { type: 'lootbox' },
  },
  7: {
    title: t('Sierra Mike'),
    description: t('Особый сигнал'),
    resource: { type: 'signals' },
  },
  10: {
    title: t('Элитный опыт'),
    description: '',
    resource: { type: 'cap-exp' },
  },
  15: {
    title: isChinaRealm() ? '超级补给箱' : t('Контейнер «Дальние странствия»'),
    description: '',
    resource: {
      image: isChinaRealm() ? lootBoxFifteenLevelCn : lootBoxFifteenLevel,
    },
  },
  20: {
    title: t('Сталь'),
    description: '',
    resource: { type: 'steel' },
  },
  25: {
    title: t('Сталь'),
    description: '',
    resource: { type: 'steel' },
  },
  30: {
    title: t('Нефть'),
    description: '',
    resource: { type: 'oil' },
  },
  35: {
    title: t('Нефть'),
    description: '',
    resource: { type: 'oil' },
  },
  40: {
    title: t('Нефть'),
    description: '',
    resource: { type: 'oil' },
  },
  45: {
    title: t('Нефть'),
    description: '',
    resource: { type: 'oil' },
  },
  60: {
    title: t('Нефть'),
    description: '',
    resource: { type: 'oil' },
  },
  75: {
    title: t('Нефть'),
    description: '',
    resource: { type: 'oil' },
  },
};
