import { connect } from 'react-redux';

import { Layout } from '~/UIKit';

import type { Location } from 'history';
import type { RootState } from '~/store';

const mapStateToProps = (state: RootState, ownProps: { location: Location }) => {
  const needShowOverlay = state.fetchingCounter?.counter > 0;

  return {
    currentAccount: state.currentAccount,
    location: ownProps.location,
    needShowOverlay,
    root: state.urls.root,
    routePath: state.routing.locationBeforeTransitions.pathname,
  };
};

export default connect(mapStateToProps)(Layout);
