import * as React from 'react';
import { Interpolate } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import {
  Button,
  ButtonGroup,
  ClanResource,
  DialogBody,
  DialogContainer,
  DialogFooter,
  DialogHeader,
  Divider,
  HelperIcon,
  Icon,
  Textarea,
} from '@wg/wows-react-uikit';

import settings from '~/settings';
import dwhExport from '~/dwhExport';
import { sendEventToGTM } from '~/helpers/analytics';
import { t, x } from '~/helpers/localization';
import { useAppDispatch, useAppSelector } from '~/store';
import { searchToObject } from '~/utils';
import { sendApplication as sendApplicationAction } from '~/Actions/ActionApp';

import Terms from '~/Components/Terms/Terms';

import styles from './SendApplicationDialog.scss';

import type { ClanInfoType } from '~/Actions/ActionClanProfile';
import type { ClanRecommendation } from '~/Actions/ActionRecommendations';
import type { AppStateType as RootState } from '~/Reducers';
import type { IDialog } from '~/types/declaration';

const SOURCE_MAP = {
  clan_recommendations: 'Recommendations page',
  profile: 'Profile page',
  about: 'About clans page',
  clan_profile: 'Clan profile page',
  clan_search: 'Search page',
  player_search: 'Player search page',
};

const stateSelector = (state: RootState) => {
  return {
    isFetchingJoinClan: state.applications.isFetchingJoinClan,
    isHiddenAccount: state.currentAccount.isHiddenAccount,
    leftApplicationCount: state.ReducerRecommendations.leftApplicationCount,
  };
};

type ISendApplicationDialog = IDialog<{
  clan: ClanInfoType | ClanRecommendation;
  source: string;
  shouldFetchApplications?: boolean;
  onSend?: (info: string) => void;
}>;

const SendApplicationDialog: React.FC<ISendApplicationDialog> = ({ data, hideDialog }) => {
  const { clan, source, shouldFetchApplications, onSend } = data;
  const clanId = clan.id;
  // @TODO: check clan rec
  const clanLeveling = clan.leveling;

  const { isFetchingJoinClan, isHiddenAccount, leftApplicationCount } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const [info, setInfo] = React.useState('');

  const updateInfo = (newInfo: string) => {
    setInfo(newInfo);
  };

  const sendApplication = () => {
    const query = searchToObject();
    if (query.source && query.purpose) {
      dwhExport.push(`${query.source}.${query.purpose}.open_clan.send_request`);
    }
    sendEventToGTM({
      category: SOURCE_MAP[source as keyof typeof SOURCE_MAP] || '',
      action: 'Click: Send application join clan dialog',
      label: 'Button send application in join clan dialog',
      clanId: clanId,
    });

    if (onSend) {
      onSend(info);
      hideDialog();
    } else {
      void dispatch(sendApplicationAction(clan, source, shouldFetchApplications, info)).then(() => {
        hideDialog();
      });
    }
  };

  const onHideDialog = () => {
    sendEventToGTM({
      category: SOURCE_MAP[source as keyof typeof SOURCE_MAP],
      action: 'Click: Hide join clan dialog',
      label: 'Button close in join clan dialog',
      clanId: clanId,
    });

    hideDialog();
  };

  const renderHiddenAccountConfirm = () => {
    return isHiddenAccount ? (
      <div className={styles.hiddenAccountMessage}>
        <span className={styles.infoLabel}>
          <Icon glyph="info" />{' '}
          {t(
            'Игроки клана, которые рассмотрят вашу заявку, получат доступ к вашей статистике по следующим показателям: количество боёв, процент побед, средний опыт, урон и количество уничтоженных кораблей за бой.',
          )}
        </span>
      </div>
    ) : null;
  };

  const needResource = settings.supply.clanLevelingToAccountLeveling[clanLeveling];

  const clanLevelingContent = (
    <React.Fragment>
      <Divider type="major" />
      <div className={styles.info}>
        <Interpolate
          i18nKey={x('Чтобы пользоваться клановыми бонусами, необходимо заработать %(resource)s')}
          resource={<ClanResource isFlat amount={needResource} />}
          t={t}
        />
        <span className={styles.helperIcon}>
          <HelperIcon header={t('Допуск к клановым бонусам')}>
            <p>
              {t(
                'Чтобы пользоваться клановыми бонусами, необходимо заработать определённое количество баррелей нефти.',
              )}
            </p>
            <p>{t('Количество баррелей нефти, необходимое для получения допуска, зависит от уровня клана.')}</p>
            <p>
              {t('Чем сильнее клан, тем больше баррелей нефти необходимо заработать для получения допуска к бонусам.')}
            </p>
          </HelperIcon>
        </span>
      </div>
    </React.Fragment>
  );

  const leftApplicationsMessage =
    leftApplicationCount === 0
      ? t('На сегодня у вас не осталось заявок в кланы')
      : t('Оставшееся количество заявок в кланы на сегодня: %(count)s', {
          count: leftApplicationCount,
        });

  return (
    <DialogContainer>
      <DialogHeader>{t('Вступление в клан')}</DialogHeader>
      <DialogBody>
        <div className={styles.wrapper}>
          <Textarea
            label={t('Почему клан должен выбрать вас?')}
            onChange={updateInfo}
            value={info}
            width="100%"
            height="130px"
            maxLength={500}
            isCounted
          />
          <span className={styles.infoLabel}>
            <Icon glyph="info" /> {t('Заявку будут рассматривать офицеры клана')}
          </span>
          <Terms />
          {leftApplicationCount !== null && (
            <span className={styles.leftApplicationsLabel}>{leftApplicationsMessage}</span>
          )}
          <br />
          {renderHiddenAccountConfirm()}
          {needResource ? clanLevelingContent : null}
        </div>
      </DialogBody>
      <DialogFooter>
        <ButtonGroup isLoading={isFetchingJoinClan}>
          <div
            data-staff-name={
              source === 'clan_recommendations' ? 'recommendations_join-popover_join' : 'join-popover_join'
            }
            data-staff-clan-id={clanId}
          >
            <Button isJustified isFlat onClick={sendApplication}>
              {t('Вступить в клан')}
            </Button>
          </div>
          <div
            data-staff-name={
              source === 'clan_recommendations' ? 'recommendations_join-popover_close' : 'join-popover_close'
            }
            data-staff-clan-id={clanId}
          >
            <Button isJustified isFlat onClick={onHideDialog}>
              {t('Отменить')}
            </Button>
          </div>
        </ButtonGroup>
      </DialogFooter>
    </DialogContainer>
  );
};

export default React.memo(SendApplicationDialog);
