import React from 'react';
import { Link, StepCSSTransitionGroup, Tabs } from '@wg/wows-react-uikit';

import { REQUESTS_TABS } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { ClanRec2IsEnabled } from '~/helpers/common';
import { isClanBattleType } from '~/helpers/ladder';
import { t } from '~/helpers/localization';
import useMount from '~/hooks/useMount';
import { hasPermission, PERMISSIONS } from '~/roles';

import BattleSelector, {
  type Item as IBattleSelectorType,
  type BattleType,
} from '~/UIKit/BattleSelector/BattleSelector';
import NonOperationalEntries from '~/UIKit/Entries/NonOperationalEntries';
import OperationalEntries from '~/UIKit/Entries/OperationalEntries';
import LadderSeasonsDropdown from '~/UIKit/LadderSeasonsDropdown/LadderSeasonsDropdown';

import headersAccountApplications from './Applications/headers.account.settings.applications';
import headersClanApplications from './Applications/headers.clan.settings.applications';
import headersAccountInvites from './Invites/headers.account.settings.invites';
import headersClanInvites from './Invites/headers.clan.settings.invites';
import styles from './Requests.scss';

import type { Tab } from '@wg/wows-react-uikit/components/Tabs/Tabs';
import type { IApplication } from '~/Actions/ActionApp';
import type { Invite } from '~/Actions/ActionInvites';
import type { SEASON_TYPES } from '~/constants';
import type { ROLE_NAMES } from '~/roles';

type propTypes = {
  battleTypes: IBattleSelectorType[];
  requestsBattleType: string;
  currentAccount: {
    id: number;
    clanId: number;
    roleName: ROLE_NAMES;
    inClanCooldownTill: string;
  };
  clan: {
    members_count: number;
  };

  clanLoaded: boolean;
  clanRulesContent: object;
  currentRequestsTab: REQUESTS_TABS;
  isCurrentClanFull: boolean;
  root: string;

  invitesEntries: {
    sort: {
      order: string;
      isAsc: boolean;
    };
    isFetching: boolean;
    page: number;
    invites: Array<Invite>;
    meta: object;
    error: object;
  };

  applicationsEntries: {
    sort: {
      order: string;
      isAsc: boolean;
    };
    isFetching: boolean;
    page: number;
    applications: IApplication[];
    meta: object;
    error: object;
  };
  selectedApplicationsPlayersIds: Array<number>;

  fetchEntriesByCurrentState: (withGlobalSpinner: boolean, currentRequestsTab: REQUESTS_TABS) => void;
  onBattleTypeChange: (battleType: BattleType, currentRequestsTab: REQUESTS_TABS) => void;
  onReloadClick: () => void;
  onRender: (clanId: number) => void;
  onSendInvite: () => void;
  onShowCancelInvite: () => void;
  sendApplication: () => void;

  onAcceptedEntryApplications: () => void;
  onAcceptedEntryInvites: () => void;
  onAllApplicationsPlayersTick: () => void;
  onApplicationsPlayerTick: () => void;
  onDeclineApplications: () => void;
  onDeclinedEntryApplication: () => void;
  onDeclinedEntryInvites: () => void;

  fetchEntriesByPageApplications: () => void;
  fetchEntriesByPageInvites: () => void;
  onSortClickApplications: () => void;
  onSortClickInvites: () => void;

  dropSelection: () => void;
  onRecommendationsClanSettingsClick: () => void;
  onRulesDialogClick: () => void;
  onSeasonChange: (selectedSeason: number, tab: string) => void;
  onToggleTab: (value: REQUESTS_TABS, canHandleInvites: boolean) => void;
  openAccountProfile: () => void;
  openChatWindow: () => void;
  requestsSeason?: number;
  requestsSeasonType?: SEASON_TYPES;
};

const Requests: React.FC<propTypes> = (props) => {
  useMount(() => {
    const currentAccount = props.currentAccount;
    const canHandleInvites = hasPermission(currentAccount.roleName, PERMISSIONS.HANDLE_INVITES);

    dwhExport.push(DWH_EVENTS.REQUESTS.OPEN_PAGE);
    if (props.currentRequestsTab === REQUESTS_TABS.INVITES) {
      if (canHandleInvites) {
        dwhExport.push(DWH_EVENTS.REQUESTS.CLAN.VIEW_INVITES);
      } else {
        dwhExport.push(DWH_EVENTS.REQUESTS.PLAYER.VIEW_INVITES);
      }
    } else {
      if (canHandleInvites) {
        dwhExport.push(DWH_EVENTS.REQUESTS.CLAN.VIEW_REQUESTS);
      } else {
        dwhExport.push(DWH_EVENTS.REQUESTS.PLAYER.VIEW_REQUESTS);
      }
    }

    if (props.currentAccount.clanId && !props.clanLoaded) {
      props.onRender(props.currentAccount.clanId);
    }
  });

  const fetchEntriesByCurrentState = (withGlobalSpinner: boolean) => {
    props.fetchEntriesByCurrentState(withGlobalSpinner, props.currentRequestsTab);
  };

  const onBattleTypeChange = (battleType: BattleType) => {
    props.onBattleTypeChange(battleType, props.currentRequestsTab);
  };

  const renderRecommendationClanSettings = () => {
    if (!props.currentAccount.clanId) {
      return null;
    }

    const hasPermissionChangeSettings = hasPermission(props.currentAccount.roleName, PERMISSIONS.CHANGE_SETTINGS);
    const canSeeClanRec2Settings = ClanRec2IsEnabled && hasPermissionChangeSettings;

    if (!canSeeClanRec2Settings) {
      return null;
    }

    return (
      <Link
        arrow={''}
        key="clanrec2"
        isActionLink
        onClick={() => {
          props.onRecommendationsClanSettingsClick();
        }}
      >
        {t('Изменить требования клана')}
      </Link>
    );
  };

  const renderSeasonsMenu = () => {
    if (props.requestsSeason === null) {
      return null;
    }

    return (
      <div className={styles.seasonsMenu}>
        <LadderSeasonsDropdown
          dropdownPosition="bottom"
          onSeasonChange={(selectedSeason: number) => props.onSeasonChange(selectedSeason, props.currentRequestsTab)}
          selectedSeasonId={props.requestsSeason}
          selectedSeasonType={props.requestsSeasonType}
        />
      </div>
    );
  };

  const currentAccount = props.currentAccount;
  let invites: React.ReactNode;
  let applications: React.ReactNode;
  let tabsData: Tab[];

  if (currentAccount.clanId) {
    tabsData = [
      { value: REQUESTS_TABS.APPLICATION, content: t('Заявки от игроков') },
      { value: REQUESTS_TABS.INVITES, content: t('Приглашения') },
    ];

    if (hasPermission(currentAccount.roleName, PERMISSIONS.HANDLE_INVITES)) {
      invites = (
        <NonOperationalEntries
          currentAccount={props.currentAccount}
          entries={props.invitesEntries}
          entryType="invites"
          fetchEntriesByCurrentState={fetchEntriesByCurrentState}
          fetchEntriesByPage={props.fetchEntriesByPageInvites}
          headers={headersAccountInvites}
          isCurrentClanFull={props.isCurrentClanFull}
          openAccountProfile={props.openAccountProfile}
          openChatWindow={props.openChatWindow}
          root={props.root}
          sendApplication={props.sendApplication}
          onReloadClick={props.onReloadClick}
          onSendInvite={props.onSendInvite}
          onShowCancelInvite={props.onShowCancelInvite}
          onSortClick={props.onSortClickInvites}
        />
      );
    }
    if (props.clanLoaded && hasPermission(currentAccount.roleName, PERMISSIONS.HANDLE_INVITES)) {
      applications = (
        <OperationalEntries
          clan={props.clan}
          clanRulesContent={props.clanRulesContent}
          currentAccount={props.currentAccount}
          dropSelection={props.dropSelection}
          entries={props.applicationsEntries}
          entryType={REQUESTS_TABS.APPLICATION}
          fetchEntriesByCurrentState={fetchEntriesByCurrentState}
          fetchEntriesByPage={props.fetchEntriesByPageApplications}
          headers={headersAccountApplications}
          openAccountProfile={props.openAccountProfile}
          openChatWindow={props.openChatWindow}
          root={props.root}
          selectedApplicationsPlayersIds={props.selectedApplicationsPlayersIds}
          onAcceptedEntry={props.onAcceptedEntryApplications}
          onAllApplicationsPlayersTick={props.onAllApplicationsPlayersTick}
          onApplicationsPlayerTick={props.onApplicationsPlayerTick}
          onDeclineApplications={props.onDeclineApplications}
          onDeclinedEntry={props.onDeclinedEntryApplication}
          onReloadClick={props.onReloadClick}
          onRulesDialogClick={props.onRulesDialogClick}
          onSortClick={props.onSortClickApplications}
        />
      );
    }
  } else {
    tabsData = [
      { value: REQUESTS_TABS.INVITES, content: t('Приглашения от кланов') },
      { value: REQUESTS_TABS.APPLICATION, content: t('Мои заявки') },
    ];

    invites = (
      <OperationalEntries
        clan={props.clan}
        clanRulesContent={props.clanRulesContent}
        currentAccount={props.currentAccount}
        dropSelection={props.dropSelection}
        entries={props.invitesEntries}
        entryType={REQUESTS_TABS.INVITES}
        fetchEntriesByCurrentState={fetchEntriesByCurrentState}
        fetchEntriesByPage={props.fetchEntriesByPageInvites}
        headers={headersClanInvites}
        openAccountProfile={props.openAccountProfile}
        openChatWindow={props.openChatWindow}
        root={props.root}
        onAcceptedEntry={props.onAcceptedEntryInvites}
        onDeclinedEntry={props.onDeclinedEntryInvites}
        onReloadClick={props.onReloadClick}
        onRulesDialogClick={props.onRulesDialogClick}
        onSortClick={props.onSortClickInvites}
      />
    );

    applications = (
      <NonOperationalEntries
        currentAccount={props.currentAccount}
        entries={props.applicationsEntries}
        entryType={REQUESTS_TABS.APPLICATION}
        fetchEntriesByCurrentState={fetchEntriesByCurrentState}
        fetchEntriesByPage={props.fetchEntriesByPageApplications}
        headers={headersClanApplications}
        isCurrentClanFull={props.isCurrentClanFull}
        openAccountProfile={props.openAccountProfile}
        openChatWindow={props.openChatWindow}
        root={props.root}
        sendApplication={props.sendApplication}
        onReloadClick={props.onReloadClick}
        onSendInvite={props.onSendInvite}
        onSortClick={props.onSortClickApplications}
      />
    );
  }

  return (
    <div>
      <StepCSSTransitionGroup level={1}>
        <div data-staff-name="requests_change-battle-type" className={styles.battleSelectorWrap}>
          <BattleSelector
            items={props.battleTypes}
            key="battle-selector"
            selected={props.requestsBattleType}
            onBattleTypeChange={onBattleTypeChange}
          />
        </div>
        {isClanBattleType(props.requestsBattleType) ? renderSeasonsMenu() : null}
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup level={2}>
        <div className={styles.tablesMenuWrap}>
          <Tabs
            tabs={tabsData}
            tabSelected={props.currentRequestsTab}
            onToggleTab={(value) => {
              const currentAccount = props.currentAccount;
              const canHandleInvites = hasPermission(currentAccount.roleName, PERMISSIONS.HANDLE_INVITES);
              props.onToggleTab(value as REQUESTS_TABS, canHandleInvites);
            }}
          />
          {renderRecommendationClanSettings()}
        </div>
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup level={3}>
        {props.currentRequestsTab === REQUESTS_TABS.INVITES ? invites : applications}
      </StepCSSTransitionGroup>
    </div>
  );
};

export default React.memo(Requests);
