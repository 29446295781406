import * as React from 'react';
import { DivTooltip, IconTable, TableHead, TableHeadCell, TooltipBody } from '@wg/wows-react-uikit';

import styles from './SearchResult.scss';
import headers from './headers.settings';

interface ISearchResultTableHeadProps {
  stickyContainerId: string;
}

const SearchResultTableHead: React.FC<ISearchResultTableHeadProps> = ({ stickyContainerId }) => {
  const renderHeadersCells = () => {
    return (
      <>
        {headers.map((item, index: number) => {
          if (item.isHidden) {
            return null;
          }

          return (
            <TableHeadCell key={index} modify={item.modify || 'middle'} isSortable={false} isActive={false}>
              <DivTooltip
                className={styles.headerCellText}
                tooltipBody={item.tooltipText ? <TooltipBody>{item.tooltipText}</TooltipBody> : null}
              >
                {item.glyph ? <IconTable glyph={item.glyph} /> : null}
                {item.text}
              </DivTooltip>
            </TableHeadCell>
          );
        })}
      </>
    );
  };

  return <TableHead stickyContainerId={stickyContainerId}>{renderHeadersCells()}</TableHead>;
};

export default React.memo(SearchResultTableHead);
