import { translate } from 'react-i18next';
import { Gold } from '@wg/wows-react-uikit';

import { thousands } from '~/helpers/formatting';
import { t } from '~/helpers/localization';

import styles from './ErrorDublon.scss';

type IErrorDublon = {
  caption: string;
  amount: number;
  deficit: number;
  t: (arg0: string, arg1: { deficit: (arg0: number) => number }) => string;
};

const ErrorDublon = (props: IErrorDublon) => (
  <div className={styles.error}>
    <div>
      <span className={styles.caption}>{props.caption}</span>
      <Gold amount={props.amount} />
    </div>
    <div className={styles.message}>
      {t('Не хватает: %(deficit)s', {
        deficit: thousands(props.deficit),
      })}
    </div>
  </div>
);

export default translate()(ErrorDublon);
