import axios from 'axios';
import moment from 'moment';
import { push } from 'react-router-redux';

import preloaded from '~/preloaded';
import { TREASURY_TABS } from '~/constants';
import { hideDistributionResultDialog, showDistributionDialog } from '~/helpers/dialogs';
import { ROUTES_MAP } from '~/routes';
import { distributeResourcesError } from '~/web2ClientAPI/notifications';

import { scrollToBuiding } from '~/Components/ViewSupply/ViewSupply';

import { syncAccountInfo } from './ActionAccount';
import { fetchClan, fetchMembers } from './ActionClanProfile';
import { actionsProcessing } from './ActionProcessing';
import { selectBuildingThunk } from './ActionSupply';

import type { Moment } from 'moment';
import type { InferActionsType } from '~/Reducers';
import type { RegularRewardsOffersCooldown, RegularRewardsType } from '~/Reducers/ReducerTreasury';
import type { AppThunk, AppAsyncThunk } from '~/store';
import type {
  Balance,
  CurrencyType,
  DayTransactions,
  IClanMember,
  Transaction,
  Transactions,
  TransactionUpdatingStatus,
} from '~/types/declaration';

export const APPEND_TRANSACTIONS = 'APPEND_TRANSACTIONS';
export const DISTRIBUTE_RESOURCES = 'DISTRIBUTE_RESOURCES';
export const FILTER_TRANSACTIONS = 'FILTER_TRANSACTIONS';
export const GET_TOTAL_BALANCE = 'GET_TOTAL_BALANCE';
export const GET_TOTAL_TRANSACTIONS_SUM = 'GET_TOTAL_TRANSACTIONS_SUM';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TREASURY_CONFIG = 'GET_TREASURY_CONFIG';
export const SET_DETAIL_VIEW_TRANSACTION = 'SET_DETAIL_VIEW_TRANSACTION';
export const UNSET_DETAIL_VIEW_TRANSACTION = 'UNSET_DETAIL_VIEW_TRANSACTION';
export const LISTEN_TRANSACTIONS_STATUS_UPDATES = 'LISTEN_TRANSACTIONS_STATUS_UPDATES';
export const SELECT_DISTRIBUTION_CURRENCY = 'SELECT_DISTRIBUTION_CURRENCY';
export const SET_DISTRIBUTION_FILTER = 'SET_DISTRIBUTION_FILTER';
export const SET_DISTRIBUTION_SUM = 'SET_DISTRIBUTION_SUM_DIRECTION';
export const SET_FILTER_DATES = 'SET_FILTER_DATES';
export const SET_IS_TRANSACTION_LIST_UPDATING = 'SET_IS_TRANSACTION_LIST_UPDATING';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB_TREASURY';
export const SET_SORT_FIELD = 'SET_SORT_FIELD';
export const SET_TRANSACTION_SUM_UPDATING = 'SET_TRANSACTION_SUM_UPDATING';
export const TOGGLE_DISTRIBUTION_MEMBERS = 'TOGGLE_DISTRIBUTION_MEMBERS';
export const TOGGLE_IS_DISTRIBUTION_FILTER_VISIBLE = 'TOGGLE_IS_DISTRIBUTION_FILTER_VISIBLE';
export const UPDATE_TREASURY_BOXES_COOLDOWN = 'UPDATE_TREASURY_BOXES_COOLDOWN';

export const TRANSACTIONS_STATUSES = {
  COMPLETED: 'completed',
  PENDING: 'pending',
  FAILED: 'failed',
} as const;

export const TRANSACTIONS_STATUSES_MAP = {
  completed: 'completed',
  pending: 'pending',
  failed: 'failed',
  committed: 'completed',
  rolled_back: 'failed',
  in_progress: 'pending',
} as const;

export type ActionsType = InferActionsType<typeof actionsTreasury>;

export const actionsTreasury = {
  setTransactionSumUpdating: () =>
    ({
      type: SET_TRANSACTION_SUM_UPDATING,
    }) as const,

  distributeResources: () =>
    ({
      type: DISTRIBUTE_RESOURCES,
    }) as const,

  filterTransactions: (transactions: Transactions) =>
    ({
      type: FILTER_TRANSACTIONS,
      payload: { transactions },
    }) as const,

  selectDistributionCurrency: (type: CurrencyType) =>
    ({
      type: SELECT_DISTRIBUTION_CURRENCY,
      payload: { type },
    }) as const,

  setDistributionFilter: (value: string) =>
    ({
      type: SET_DISTRIBUTION_FILTER,
      payload: {
        value,
      },
    }) as const,

  setDistributionSum: (sum: number) =>
    ({
      type: SET_DISTRIBUTION_SUM,
      payload: {
        sum,
      },
    }) as const,

  toggleDistributionMembers: (members: number[], toggleAll: boolean) =>
    ({
      type: TOGGLE_DISTRIBUTION_MEMBERS,
      payload: {
        members,
        toggleAll,
      },
    }) as const,

  setIsTransactionsUpdating: (isUpdating: boolean) =>
    ({
      type: SET_IS_TRANSACTION_LIST_UPDATING,
      payload: {
        isUpdating,
      },
    }) as const,

  setDetailViewTransaction: (transaction: Transaction) =>
    ({
      type: SET_DETAIL_VIEW_TRANSACTION,
      payload: {
        transaction: transaction,
      },
    }) as const,

  unsetDetailViewTransaction: () =>
    ({
      type: UNSET_DETAIL_VIEW_TRANSACTION,
    }) as const,

  toggleIsDistributionFilterVisible: () =>
    ({
      type: TOGGLE_IS_DISTRIBUTION_FILTER_VISIBLE,
    }) as const,

  setSortField: (field: Nullable<keyof IClanMember>) =>
    ({
      type: SET_SORT_FIELD,
      payload: {
        sortField: field,
      },
    }) as const,

  getTotalTransactionsSum: (data: Balance) =>
    ({
      type: GET_TOTAL_TRANSACTIONS_SUM,
      payload: {
        totalTransactionsSum: data
          .filter((item) => item.amount > 0)
          .map((item) => {
            return {
              type: item.type,
              amount: item.amount,
            };
          }),
      },
    }) as const,

  getTotalBalance: (totalBalanceList: Balance) =>
    ({
      type: GET_TOTAL_BALANCE,
      payload: { totalBalanceList },
    }) as const,

  setFilterDates: (start: Moment | null, end: Moment | null) =>
    ({
      type: SET_FILTER_DATES,
      payload: { start, end },
    }) as const,

  setSelectedTab: (index: TREASURY_TABS) =>
    ({
      type: SET_SELECTED_TAB,
      payload: { index },
    }) as const,

  getTransactions: (transactions: DayTransactions[], lastOffet: number) =>
    ({
      type: GET_TRANSACTIONS,
      payload: { transactions, lastOffet },
    }) as const,

  listenTransactionsStatusUpdates: (data: TransactionUpdatingStatus[]) =>
    ({
      type: LISTEN_TRANSACTIONS_STATUS_UPDATES,
      payload: { data },
    }) as const,

  appendTransactions: () =>
    ({
      type: APPEND_TRANSACTIONS,
      payload: { isAppending: true },
    }) as const,

  getTreasuryConfig: (config: RegularRewardsType) =>
    ({
      type: GET_TREASURY_CONFIG,
      payload: config,
    }) as const,

  updateTreasuryBoxesCoolDown: (data: RegularRewardsOffersCooldown) =>
    ({
      type: UPDATE_TREASURY_BOXES_COOLDOWN,
      payload: data,
    }) as const,
};

const getTotalTransactionsSumThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const { filterStartDate, filterEndDate, selectedTab } = state.ReducerTreasury;
  if (state.urls.clanTreasuryOperationsTotals) {
    let url = state.urls.clanTreasuryOperationsTotals;

    const connector = url.indexOf('?') !== -1 ? '&' : '?';

    url = `${url}${connector}type=${getTransactiontype(selectedTab)}`;

    if (filterStartDate && filterEndDate) {
      const start = filterStartDate.clone().utc().format();
      const end = filterEndDate.clone().utc().format();
      url = `${url}&since=${start}&until=${end}`;
    }

    void axios.get<Balance>(url).then((response) => {
      if (response.status === 200 && response.data && response.data.length) {
        dispatch(actionsTreasury.getTotalTransactionsSum(response.data));
      }
    });
  }
};

export const getTotalBalanceThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const clanId = state.currentAccount.clanId;

  const clan = state.clans.items[String(clanId)];
  const personalResource = clan && clan.personalResource ? clan.personalResource : 0;

  const personalResourceBalanceOItem = {
    type: 'oil' as CurrencyType,
    amount: personalResource,
    canDistribute: false,
  };

  if (state.urls.clanTreasuryBalance) {
    const url = state.urls.clanTreasuryBalance;
    axios
      .get<Balance>(url)
      .then((response) => {
        const totalBalanceList: Balance = response.data.map((item) => {
          return {
            type: item.type,
            amount: item.amount,
            canDistribute: item.type !== 'oil',
          };
        });
        totalBalanceList.push(personalResourceBalanceOItem);

        dispatch(actionsTreasury.getTotalBalance(totalBalanceList));
      })
      .catch(() => {
        dispatch(actionsTreasury.getTotalBalance([]));
      });
  } else {
    console.error('no clanTreasuryBalance url');
  }
};

export const setFilterDatesThunk =
  (start: Moment, end: Moment): AppThunk =>
  (dispatch) => {
    if (start) {
      start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }

    if (end) {
      end.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
    }

    dispatch(actionsTreasury.setFilterDates(start, end));
    dispatch(filterTransactionsThunk());
  };

const filterTransactionsThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const startDate = state.ReducerTreasury.filterStartDate;
  const endDate = state.ReducerTreasury.filterEndDate;

  if ((startDate && endDate) || (!startDate && !endDate)) {
    dispatch(getTransactionsThunk(false));
  }
};

export const setSelectedTabThunk =
  (index: TREASURY_TABS, userRouter = false): AppThunk =>
  (dispatch) => {
    if (userRouter) {
      switch (index) {
        case TREASURY_TABS.EXPENSES:
          dispatch(push(`${preloaded.urls.root}${ROUTES_MAP.TREASURY_EXPENSES}`));
          break;
        case TREASURY_TABS.INCOME:
          dispatch(push(`${preloaded.urls.root}${ROUTES_MAP.TREASURY_INCOME}`));
          break;
        case TREASURY_TABS.DISTRIBUTION:
          dispatch(push(`${preloaded.urls.root}${ROUTES_MAP.TREASURY_DISTRIBUTION}`));
          break;
        case TREASURY_TABS.REWARDS:
          dispatch(push(`${preloaded.urls.root}${ROUTES_MAP.TREASURY_REWARDS}`));
          break;
        default:
          break;
      }
    } else {
      dispatch(actionsTreasury.setSelectedTab(index));
    }

    if (index !== TREASURY_TABS.DISTRIBUTION) {
      dispatch(getTransactionsThunk(false));
    }
  };

export const init = (): AppAsyncThunk => async (dispatch, getState) => {
  const state = getState();
  const clanId = state.currentAccount.clanId;

  if (!clanId) {
    return;
  }

  void dispatch(fetchMembers(clanId, false, 'cvc_or_brawl'));
  await dispatch(fetchClan(clanId, false));
  dispatch(getTreasuryConfigThunk());
  dispatch(getTransactionsThunk(false));
};

const sortByDateString = (left: { date: string }, right: { date: string }) => {
  return -moment.utc(left.date).diff(moment.utc(right.date));
};

const getTransactiontype = (index: TREASURY_TABS): string => {
  switch (index) {
    case TREASURY_TABS.INCOME: {
      return 'debit';
    }
    default: {
      return 'credit';
    }
  }
};

const getTransactionsThunk =
  (isFromAppend: boolean): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const { selectedTab, filterStartDate, filterEndDate, isAppending, transactionsOffset, lastOffet } =
      state.ReducerTreasury;
    const clanId = state.currentAccount.clanId;

    if (!clanId) {
      return;
    }

    if (lastOffet === transactionsOffset && isAppending) {
      return;
    }

    if (!isFromAppend) {
      dispatch(getTotalBalanceThunk());
    }

    if (state.urls.clanTreasuryOperations) {
      if (!isAppending) {
        dispatch(actionsTreasury.setIsTransactionsUpdating(true));
      }

      let url = state.urls.clanTreasuryOperations;

      const connector = url.indexOf('?') !== -1 ? '&' : '?';

      url = `${url}${connector}type=${getTransactiontype(selectedTab)}`;

      if (filterStartDate && filterEndDate) {
        const start = filterStartDate.clone().utc().format();
        const end = filterEndDate.clone().utc().format();
        url = `${url}&since=${start}&until=${end}`;
      }

      if (isAppending) {
        url = `${url}&offset=${transactionsOffset}`;
      }

      axios
        .get<Transaction[]>(url)
        .then((response) => {
          const data = response.data;

          const transactionsListRaw: Record<string, Transaction[]> = {};

          if (data && data.length) {
            const innerTransactions: Record<number, Transaction[]> = {};

            data.forEach((transaction) => {
              if (transaction.data.parentId) {
                if (innerTransactions[transaction.data.parentId]) {
                  innerTransactions[transaction.data.parentId].push(transaction);
                } else {
                  innerTransactions[transaction.data.parentId] = [transaction];
                }
              }
            });

            data.forEach((item) => {
              const dateKey = item && item.date ? moment(item.date).format('DD_MM_YYYY') : null;
              if (dateKey) {
                if (!item.data.parentId) {
                  const mergedItem = {
                    ...item,
                  };
                  if (innerTransactions[item.id]) {
                    mergedItem.childTransactions = innerTransactions[item.id];
                  }
                  if (!transactionsListRaw[dateKey]) {
                    transactionsListRaw[dateKey] = [mergedItem];
                  } else {
                    transactionsListRaw[dateKey].push(mergedItem);
                  }
                }
              }
            });
          }

          const transactionRaw: DayTransactions[] = [];

          for (const key in transactionsListRaw) {
            if (Object.prototype.hasOwnProperty.call(transactionsListRaw, key)) {
              const oneDayTransactions = transactionsListRaw[key];
              const oneDaySorted = oneDayTransactions.sort(sortByDateString);
              transactionRaw.push({
                date: moment.utc(key, 'DD_MM_YYYY').toISOString(),
                transactions: oneDaySorted,
              });
            }
          }

          const transactionsSorted = transactionRaw.sort(sortByDateString);

          dispatch(actionsTreasury.getTransactions(transactionsSorted, transactionsOffset));
          dispatch(actionsTreasury.setIsTransactionsUpdating(false));
          dispatch(getTotalTransactionsSumThunk());
          dispatch(listenTransactionsStatusUpdatesThunk());
        })
        .catch((err) => {
          console.error(err);
          dispatch(actionsTreasury.setIsTransactionsUpdating(false));
        });
    } else {
      console.error('no url to get transactions');
    }
  };

export const distributeResources = (): AppThunk => (dispatch, getState) => {
  dispatch(actionsProcessing.startFetching());
  const state = getState();
  const clanId = state.currentAccount.clanId;
  const url = state.urls.clanTreasury.replace('{clan_id}', String(clanId));

  axios
    .put(url, {
      currency: state.ReducerTreasury.selectedDistributionCurrency,
      delta: state.ReducerTreasury.distributionSum,
      initiator_id: state.currentAccount.id,
      receivers: state.ReducerTreasury.distributionList,
    })
    .then(() => {
      dispatch(actionsProcessing.stopFetching());
      dispatch(setSelectedTabThunk(TREASURY_TABS.EXPENSES, true));
    })
    .catch(() => {
      dispatch(actionsProcessing.stopFetching());
      distributeResourcesError();
    });
};

const listenTransactionsStatusUpdatesThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const transactions = state.ReducerTreasury.transactions;

  const listeningIds: number[] = [];

  transactions.forEach((dayTransactions) => {
    if (dayTransactions && dayTransactions.transactions) {
      dayTransactions.transactions.forEach((singleTransaction) => {
        if (singleTransaction?.status === TRANSACTIONS_STATUSES.PENDING) {
          if (singleTransaction.data?.gameTransactionId) {
            listeningIds.push(singleTransaction.data.gameTransactionId);
          }
        }
        if (singleTransaction.childTransactions?.length) {
          singleTransaction.childTransactions.forEach((childTransaction) => {
            if (childTransaction?.status === TRANSACTIONS_STATUSES.PENDING) {
              if (childTransaction.data?.gameTransactionId) {
                listeningIds.push(childTransaction.data.gameTransactionId);
              }
            }
          });
        }
      });
    }
  });

  if (listeningIds.length > 0) {
    const url = `${state.urls.clanTransactions}${
      state.urls.clanTransactions.indexOf('?') !== -1 ? '&' : '?'
    }ids=${listeningIds.join(',')}`;

    axios
      .get<TransactionUpdatingStatus[]>(url)
      .then((response) => {
        if (response.status === 200) {
          dispatch(actionsTreasury.listenTransactionsStatusUpdates(response.data));

          setTimeout(() => {
            dispatch(listenTransactionsStatusUpdatesThunk());
          }, 1000);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  } else {
    dispatch(updateTreasuryBoxesCoolDownThunk());
    void dispatch(syncAccountInfo());
  }
};

export const appendTransactionsThunk = (): AppThunk => (dispatch) => {
  dispatch(actionsTreasury.appendTransactions());
  dispatch(getTransactionsThunk(true));
};

export const repeatFailedDistribution = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const transaction = { ...state.ReducerTreasury.detailViewTransaction };
  const firstReceiver =
    transaction?.data?.receivers && transaction?.data.receivers[0] ? transaction?.data.receivers[0] : null;
  if (firstReceiver) {
    const currencyType = firstReceiver.delta.type;
    const distributionSumm = firstReceiver.delta.amount;
    const members = transaction.data?.failedReceivers || [];

    dispatch(hideDistributionResultDialog());
    dispatch(actionsTreasury.selectDistributionCurrency(currencyType));
    dispatch(actionsTreasury.setDistributionSum(distributionSumm));
    dispatch(actionsTreasury.toggleDistributionMembers(members, false));
    dispatch(showDistributionDialog());
  }
};

export const navigateToTreasuryBuilding = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  dispatch(push(`${state.urls.root}profile`));

  setTimeout(() => {
    if (!state.currentAccount.clanId) {
      return;
    }
    scrollToBuiding('treasury');
    dispatch(selectBuildingThunk('treasury', state.currentAccount.clanId, false));
  }, 200);
};

const getTreasuryConfigThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  if (state.settings.treasury) {
    dispatch(actionsTreasury.getTreasuryConfig(state.settings.treasury));
    dispatch(updateTreasuryBoxesCoolDownThunk());
  }
};

export const buyTreasuryBox =
  (offerId: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    dispatch(actionsProcessing.startFetching());

    if (state.urls.clanTreasuryBuyReward) {
      axios
        .put(state.urls.clanTreasuryBuyReward, {
          offer: offerId,
        })
        .then(() => {
          dispatch(actionsProcessing.stopFetching());
          dispatch(getTransactionsThunk(false));
          dispatch(updateTreasuryBoxesCoolDownThunk());
          void dispatch(syncAccountInfo());
        })
        .catch((error) => {
          dispatch(actionsProcessing.stopFetching());
          console.error(error);
        });
    }
  };

const updateTreasuryBoxesCoolDownThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();

  if (state.urls.clanTreasuryRewards) {
    axios
      .get<RegularRewardsOffersCooldown>(state.urls.clanTreasuryRewards)
      .then((response) => {
        dispatch(actionsTreasury.updateTreasuryBoxesCoolDown(response.data));
        dispatch(actionsProcessing.stopFetching());
      })
      .catch((error) => {
        dispatch(actionsProcessing.stopFetching());
        console.error(error);
      });
  }
};

export const repeatTreasuryBoxTransaction =
  (id: number): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    if (!state.urls?.clanTreasuryBuyRewardRepeat) {
      return;
    }

    axios
      .post(state.urls.clanTreasuryBuyRewardRepeat, {
        treasury_transaction_id: id,
      })
      .then(() => {
        dispatch(getTransactionsThunk(false));
      })
      .catch((error) => {
        console.error(error);
      });
  };
