import get from 'lodash/get';
import has from 'lodash/has';

import { SOCIAL_NETWORKS_LINKS_FORMATS } from '~/constants';
import { promiseWithSpinner, fetchWrapper as fetch } from '~/helpers/fetch';
import { t } from '~/helpers/localization';
import { isPreModerateDescription } from '~/helpers/moderation';
import { getCurrentClan } from '~/store/selectors/currentAccountSelector';

import * as web2ClientAPI from '~/web2ClientAPI/web2ClientAPI';
import { actionsApp } from '~/Actions/ActionApp';
import { actionsClanProfile, type ClanAttributeType } from '~/Actions/ActionClanProfile';

import { ATTRIBUTES_VALIDATION_STATE } from '~/UIKit/ClanCreate/Constants';

import { syncAccountInfo } from './ActionAccount';
import { actionsClanRename } from './ActionClanRename';

import type { ClanType } from '~/Actions/ActionInvites';
import type { InferActionsType } from '~/Reducers';
import type { IClanEditStateFields } from '~/Reducers/ReducerClanEdit';
import type { IAppDispatch, AppAsyncThunk } from '~/store';
import type { IApiError } from '~/types/api';
import type { IClanCommunityUrls } from '~/types/declaration';

export const START_CLAN_EDIT = 'START_CLAN_EDIT';
export const UPDATE_CLAN_EDIT_STATE = 'UPDATE_CLAN_EDIT_STATE';
export const UPDATE_CLAN_EDIT_ERROR = 'UPDATE_CLAN_EDIT_ERROR';

export type ActionsType = InferActionsType<typeof actionsClanEdit>;

type ClanEditStateType = {
  savedDescription: string;
  isValidationError: boolean;
  error: IApiError;
};

type ClanEditErrorType = {
  error?: string;
  errors?: unknown;
  current?: unknown;
  system_error?: boolean;
  socials_error?: IClanCommunityUrls;
};

export const actionsClanEdit = {
  startClanEdit: (field: keyof IClanEditStateFields) =>
    ({
      type: START_CLAN_EDIT,
      field,
    }) as const,

  updateClanEditState: (field: keyof IClanEditStateFields, data: ClanEditStateType) =>
    ({
      type: UPDATE_CLAN_EDIT_STATE,
      field,
      data,
    }) as const,

  updateClanEditError: (field: keyof IClanEditStateFields, data: ClanEditErrorType) =>
    ({
      type: UPDATE_CLAN_EDIT_ERROR,
      field,
      data,
    }) as const,
};

const processExcludedFromClanError = (dispatch: IAppDispatch, root, sendErrorNotification, currentClan: ClanType) => {
  sendErrorNotification('excluded_from_clan', currentClan);
  promiseWithSpinner(dispatch, dispatch(syncAccountInfo()));
};

export const processError = (
  dispatch: IAppDispatch,
  json: JSON,
  sendErrorNotification,
  currentClan: ClanType,
  root?,
) => {
  const reason = get(json, 'additional_info.reason');
  if (reason === 'insufficient_permissions') {
    const role = get(json, 'additional_info.role', null);
    if (role) {
      dispatch(actionsApp.updateAccountInfo({ role }));
      sendErrorNotification(reason);
    } else {
      processExcludedFromClanError(dispatch, root, sendErrorNotification, currentClan);
    }
  } else if (reason === 'excluded_from_clan') {
    processExcludedFromClanError(dispatch, root, sendErrorNotification, currentClan);
  } else if (reason === 'clan_already_exist') {
    sendErrorNotification(reason);
    // TODO WGCP-3104 add to response reason not_enought_gold
  } else if (get(json, 'error') === 'NO_GOLD') {
    sendErrorNotification('no_gold');
  } else if (reason === 'rename_in_cooldown') {
    sendErrorNotification(reason);
  } else if (get(json, 'error_code') === 'restriction_error') {
    // TODO WGCP-3104 add to response reason restriction_error or something similar
    sendErrorNotification('restriction_error', get(json, 'error'));
  } else if (reason === 'err_bad_words') {
    const messages = {
      name: t('Название содержит запрещенные слова.'),
      tag: t('Тег содержит запрещенные слова.'),
    };
    const field = get(json, 'additional_info.field');
    dispatch(
      actionsClanRename.finishCheckAvailabilityRenamingClan({
        field: field,
        error: messages[field],
        available: ATTRIBUTES_VALIDATION_STATE.NOT_AVAILABLE,
      }),
    );
  } else {
    sendErrorNotification();
  }
};

export const shouldHideDialogOnError = (json) => {
  return ['insufficient_permissions', 'excluded_from_clan'].includes(get(json, 'additional_info.reason'));
};

export const changeClanAttributes =
  (
    data: {
      description: string;
      rawDescription?: string;
      communityUrls?: IClanCommunityUrls;
    },
    disableSuccessfulNotification = false,
  ): AppAsyncThunk =>
  (dispatch, getState) => {
    const state = getState();
    const postChangeAttributesUrl = state.urls.changeAttributes;
    const clanId = state.currentAccount.clanId;

    dispatch(actionsClanEdit.startClanEdit('clanDescription'));
    return fetch(postChangeAttributesUrl, {
      method: 'POST',
      body: data,
    }).then(
      (json) => {
        if (json.status === 'error' || json.errors || !!json.error) {
          processError(
            dispatch,
            json,
            web2ClientAPI.sendClanEditDescriptionErrorNotification,
            getCurrentClan(state),
            state.urls.root,
          );
          dispatch(actionsClanEdit.updateClanEditError('clanDescription', json));
          return shouldHideDialogOnError(json);
        }

        if (!disableSuccessfulNotification) {
          if (isPreModerateDescription) {
            web2ClientAPI.sendClanEditDescriptionPreModerationNotification();
          } else {
            web2ClientAPI.sendClanEditDescriptionCompleteNotification();
          }
        }

        dispatch(actionsClanEdit.updateClanEditError('clanDescription', null));

        if (has(data, 'description')) {
          data.rawDescription = data.description;
          delete data.description;
        }
        dispatch(actionsClanEdit.updateClanEditState('clanDescription', null));
        dispatch(actionsClanProfile.changeClanAttributesSuccessed({ ...data, clanId }));
        return true;
      },
      (json) => {
        web2ClientAPI.sendClanEditDescriptionErrorNotification();

        let socialsError;
        const socialsErrorPrefix = 'Community urls are invalid:';
        const errorDescription = json?.response?.body?.description;
        if (typeof errorDescription === 'string' && errorDescription.indexOf(socialsErrorPrefix) >= 0) {
          const regexPattern = `${socialsErrorPrefix}\\s*(.+)`;
          const regex = new RegExp(regexPattern);
          const match = errorDescription.match(regex);

          if (match && match[1]) {
            socialsError = match[1].split(',').reduce((acc, social) => {
              return {
                ...acc,
                [social]: t('Correct link format must be: %(linkFormat)s', {
                  linkFormat: SOCIAL_NETWORKS_LINKS_FORMATS[social],
                }),
              };
            }, {});
          }
        }

        dispatch(
          actionsClanEdit.updateClanEditError('clanDescription', {
            error: t('Произошла ошибка. Повторите попытку позже'),
            socials_error: socialsError,
          }),
        );
        return false;
      },
    );
  };

export const changeClanColor =
  (data: ClanAttributeType): AppAsyncThunk =>
  (dispatch, getState) => {
    const state = getState();
    const postChangeSymbolicsUrl = state.urls.changeSymbolics;
    const clanId = state.currentAccount.clanId;

    dispatch(actionsClanEdit.startClanEdit('clanColor'));
    return fetch(postChangeSymbolicsUrl, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(
      (json) => {
        if (json.status === 'error' || json.errors || !!json.error) {
          processError(
            dispatch,
            json,
            web2ClientAPI.sendClanEditColorErrorNotification,
            getCurrentClan(state),
            state.urls.root,
          );
          dispatch(actionsClanEdit.updateClanEditError('clanColor', json));
          return shouldHideDialogOnError(json);
        }
        web2ClientAPI.sendClanEditColorCompleteNotification();
        dispatch(actionsClanEdit.updateClanEditError('clanColor', null));
        dispatch(actionsClanEdit.updateClanEditState('clanColor', null));
        dispatch(actionsClanProfile.changeClanAttributesSuccessed({ ...data, clanId }));
        return true;
      },
      () => {
        web2ClientAPI.sendClanEditColorErrorNotification();
        dispatch(
          actionsClanEdit.updateClanEditError('clanColor', {
            error: t('Произошла ошибка. Повторите попытку позже'),
            system_error: true,
          }),
        );
        return false;
      },
    );
  };
