import classNames from 'classnames';
import React from 'react';
import { DivTooltip, ProgressBar, Tooltip, TooltipBody, TooltipHeader } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { t } from '~/helpers/localization';

import styles from './TaskRow.scss';

import type { ClanTaskReward } from '~/Actions/ActionClanTasks';

export interface TaskRowBase_Props {
  taskId?: number;
  clanId?: number;
  name?: string;
  order?: number;
  claimedAt?: string;
  finishedTill?: string;
  progress?: number;
  isFinished: boolean;
  isClaimed: boolean;
  reward?: ClanTaskReward;

  title: string;
  description: string;
  binary: boolean;
  total: number;
  taskIconTooltip: any;
  animated?: boolean;

  children?: React.ReactNode;
}

export const renderCheckedSpan = (children: any) => {
  return (
    <span className={styles.active}>
      <span className={styles.checkIcon} />
      &nbsp;&nbsp;{children}
    </span>
  );
};

const TaskRowBase: React.FC<TaskRowBase_Props> = (props) => {
  const descriptionTooltip = props.description ? (
    <Tooltip>
      <TooltipHeader>
        <strong>{props.title}</strong>
      </TooltipHeader>
      <TooltipBody>{props.description}</TooltipBody>
    </Tooltip>
  ) : (
    <TooltipBody>
      <strong>{props.title}</strong>
    </TooltipBody>
  );
  const withoutProgress = props.isClaimed || props.binary;

  const progress = Math.floor(props.progress * props.total);

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.animated]: props.animated,
      })}
    >
      <div className={styles.background} />
      <div className={styles.focus} />
      <div className={styles.content}>
        <DivTooltip tooltipBody={<TooltipBody>{props.taskIconTooltip}</TooltipBody>}>
          <div className={styles.iconWrapper}>
            <div
              className={classNames(styles.taskIcon, {
                [styles.finishedTaskIcon]: props.isFinished,
                [styles.claimedTaskIcon]: props.isClaimed,
              })}
            />
          </div>
        </DivTooltip>
        <div
          className={classNames(styles.descriptionWrapper, {
            [styles.descriptionWrapperBig]: withoutProgress,
          })}
        >
          <div className={styles.titleWrapper}>
            <DivTooltip tooltipBody={descriptionTooltip} className={styles.divTooltipTitle}>
              <span
                className={classNames(styles.title, {
                  [styles.active]: props.isFinished,
                })}
              >
                {props.title}
                <span className={styles.infoIcon} />
              </span>
            </DivTooltip>
          </div>
          {!withoutProgress ? (
            <div className={styles.progress}>
              <DivTooltip
                tooltipBody={
                  <Tooltip>
                    <TooltipHeader>
                      <strong>{t('Прогресс выполнения задач')}</strong>
                    </TooltipHeader>
                    <TooltipBody>
                      {t(
                        'Информация о прогрессе выполнения задач обновляется  ежедневно в {update_time} по серверному времени',
                      ).replace('{update_time}', settings.darwin.syncTime)}
                    </TooltipBody>
                  </Tooltip>
                }
              >
                <ProgressBar completed={progress / props.total} size="small" color="#ffcc66" />
                <span className={styles.progressCaption}>
                  {progress}
                  <span>/{props.total}</span>
                </span>
              </DivTooltip>
            </div>
          ) : null}
        </div>
        <div className={styles.rewardWrapper}>{props.children}</div>
      </div>
    </div>
  );
};

TaskRowBase.defaultProps = {
  title: '',
  description: '',
  binary: true,
  total: 0,
  taskIconTooltip: '',
  animated: false,
};

export default TaskRowBase;
