import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import settings from '~/settings';

import { getLocalizationSafe } from './localization';
import { getSeasonLeagues } from './seasons';
import { SEASON_TYPES, TYPE_GLOBAL_SEARCH } from '../constants';

export const getLeagueNameByNumber = (league: string, seasonNumber: number) => {
  const leagues = getSeasonLeagues(seasonNumber);

  if (isEmpty(leagues) || !leagues[league]) {
    return null;
  }

  return getLocalizationSafe(leagues[league], 'mark');
};

export function getLastSeason() {
  const seasons = settings.ladder?.seasons || {};
  const lastSeasonId = settings.ladder?.lastSeasonId || null;
  if (lastSeasonId && seasons[lastSeasonId]) {
    return seasons[lastSeasonId];
  }
  return null;
}

export function getSeasonById(id: number) {
  return settings.ladder?.seasons?.[id];
}

export const isLastSeasonRegular = () => {
  const season = getLastSeason();
  return season?.kind === SEASON_TYPES.REGULAR;
};

export const getLastRegularSeason = () => {
  return getLastSeasonOfType(SEASON_TYPES.REGULAR);
};

export const getLastBrawlSeason = () => {
  return getLastSeasonOfType(SEASON_TYPES.BRAWL);
};

export const getLastSeasonOfType = (seasonType: SEASON_TYPES) => {
  const seasons = settings.ladder?.seasons || {};
  const now = parseInt(`${Date.now() / 1000}`, 10);
  const lastSeasonId = get(settings, 'ladder.lastSeasonId', null);
  const lastSeasonIgnoreTimeCondition = get(settings, 'ladder.lastSeasonIgnoreTimeCondition', false);

  if (seasons) {
    if (lastSeasonId && seasons[lastSeasonId] && seasons[lastSeasonId].kind === seasonType) {
      return seasons[lastSeasonId];
    }
    const sortedFilteredSeasons = map(seasons)
      .filter((season) => season.kind === seasonType && (lastSeasonIgnoreTimeCondition || season.start_time < now))
      .sort((a, b) => a.id - b.id);
    return sortedFilteredSeasons.pop();
  }

  return null;
};

export const isClanBattleType = (battleType: string) => battleType === 'cvc' || battleType === 'brawl';

export const excludedRealms = settings.excludedRealms || [];
export const isGlobalSearchEnabled = !excludedRealms.includes(TYPE_GLOBAL_SEARCH);
