import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { Interpolate, translate } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import Truncate from 'react-truncate';
import {
  Button,
  Currency,
  DialogBody,
  DialogFooter,
  DialogHeader,
  DivTooltip,
  Link,
  TooltipBody,
} from '@wg/wows-react-uikit';

import { t, x } from '~/helpers/localization';
import { useAppDispatch, useAppSelector } from '~/store';
import { actionsTreasury, distributeResources } from '~/Actions/ActionTreasury';

import UserNames from '~/pages/Treasury/DistributionDialog/UserNames';

import styles from './DistributionDialog.scss';
import Calculator from '../Calculator/Calculator';

import type { AppStateType as RootState } from '~/Reducers';
import type { CurrencyType, IDialog } from '~/types/declaration';

const stateSelector = createSelector(
  [
    (state: RootState) => state.ReducerTreasury,
    (state: RootState) => state.settings.treasuryMinDistributionSumm,
    (state: RootState) => state.members.clans[state.currentAccount.clanId],
  ],
  (
    { distributionList, distributionSum, selectedDistributionCurrency, totalBalanceList },
    treasuryMinDistributionSumm,
    clan,
  ) => {
    const personalResource = clan && clan.personalResource ? clan.personalResource : 0;

    const balance = totalBalanceList.filter((resource) => resource.type !== 'oil');
    balance.push({ type: 'oil', amount: personalResource, canDistribute: false });

    return {
      distributionList,
      distributionSum,
      selectedDistributionCurrency,
      treasuryMinDistributionSumm,
      members: clan?.members || [],
      totalBalanceList: balance,
    };
  },
);

export type IDistributionDialog = IDialog;

const DistributionDialog: React.FC<IDistributionDialog> = ({ hideDialog }) => {
  const {
    distributionList,
    distributionSum,
    treasuryMinDistributionSumm,
    members,
    totalBalanceList,
    selectedDistributionCurrency,
  } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const [truncate, setTruncate] = React.useState(true);
  const truncRef = React.useRef(null);

  const isDistributeButtonDisabled = distributionSum < treasuryMinDistributionSumm;

  const setDistributionSum = React.useCallback(
    (distributionSum: number) => {
      dispatch(actionsTreasury.setDistributionSum(distributionSum));
    },
    [dispatch],
  );

  const selectDistributionCurrency = React.useCallback(
    (type: CurrencyType) => {
      dispatch(actionsTreasury.selectDistributionCurrency(type));
    },
    [dispatch],
  );

  const handleDistributeResources = React.useCallback(() => {
    dispatch(distributeResources());
    hideDialog();
  }, [dispatch, hideDialog]);

  const toggleShowUsers = () => {
    setTruncate(!truncate);
  };

  return (
    <>
      <DialogHeader>{t('Распределение ресурсов')}</DialogHeader>
      <DialogBody className={styles.dialogBodyWrapper}>
        <div className={`${styles.dialogRow} ${styles.userNamesList}`}>
          <Truncate
            ref={truncRef}
            trimWhitespace={false}
            lines={truncate ? 2 : false}
            ellipsis={
              <>
                &nbsp;
                <Link isActionLink={false} arrow={''} onClick={toggleShowUsers}>
                  {t('показать всех')}
                </Link>
              </>
            }
          >
            <UserNames
              distributionList={distributionList}
              members={members}
              toggleShowUsers={toggleShowUsers}
              truncate={truncate}
            />
          </Truncate>
        </div>
        <Calculator
          userCount={distributionList.length}
          distributionSum={distributionSum}
          setDistributionSum={setDistributionSum}
          totalBalanceList={totalBalanceList}
          selectedDistributionCurrency={selectedDistributionCurrency}
          selectDistributionCurrency={selectDistributionCurrency}
          treasuryMinDistributionSumm={treasuryMinDistributionSumm}
        />
      </DialogBody>
      <DialogFooter className={styles.dialogFooterWrapper}>
        <div className={styles.dialogActionRow}>
          <DivTooltip
            tooltipBody={
              isDistributeButtonDisabled && (
                <TooltipBody>
                  <Interpolate
                    i18nKey={x('Минимальная сумма для распределения на одного игрока %(currency)s')}
                    currency={
                      <Currency
                        isFlat
                        isBold
                        amount={treasuryMinDistributionSumm}
                        type={Currency.types[selectedDistributionCurrency] as CurrencyType}
                      />
                    }
                    t={t}
                  />
                </TooltipBody>
              )
            }
          >
            <Button isFlat onClick={handleDistributeResources} isDisabled={isDistributeButtonDisabled}>
              {t('Распределить')}
            </Button>
          </DivTooltip>
          <Button isFlat onClick={hideDialog}>
            {t('Закрыть')}
          </Button>
        </div>
      </DialogFooter>
    </>
  );
};

export default React.memo(DistributionDialog);
