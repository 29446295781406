import get from 'lodash/get';
import moment from 'moment';
import * as React from 'react';
import { Interpolate, translate } from 'react-i18next';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import { ClanMember, ContextMenu, Icon, MemberName, TableBodyCell, TableRow } from '@wg/wows-react-uikit';

import { getPositionY } from '~/helpers/contextMenu';
import { isoToFormattedLocalDate, isoToFormattedLocalDateTime, isoToFormattedLocalTime } from '~/helpers/datetime';
import { floats, percent, thousands } from '~/helpers/formatting';
import { t, x } from '~/helpers/localization';

import { ButtonAction, RankCell, StatisticsValue } from '~/UIKit';
import { Tooltip, TooltipBody } from '~/UIKit/components';

import type { IconType } from '@wg/wows-react-uikit/components/Icon/Icon';
import type { Invite } from '~/Actions/ActionInvites';

type PropsType = {
  isChatDenied: boolean;
  isCurrentClanFull?: boolean;
  invite: Invite;
  hasActiveInviteForAccount: boolean;
  onSendInvite: (account: any) => void;
  onShowCancelInvite: (invite: Invite) => void;
  openAccountProfile: (id: number) => void;
  openChatWindow: (id: number, name: string) => void;
  t: (arg: string) => string;
};

type StateType = {
  isConextMenuActive: boolean;
  contextMenuContext: Array<any>;
  isContextMenuActive: boolean;
  contextMenuPosition: {
    x: number;
    y: number;
  };
};

const getInviteStatusTooltipContent = (invite) => {
  let inviteStatusTooltip = null;

  const senderTooltipPart = (
    <Interpolate
      useDangerouslySetInnerHTML={true}
      i18nKey={x('%(senderMember)s отправил приглашение %(date)s&nbsp;в&nbsp;%(time)s')}
      senderMember={invite ? <MemberName role={invite.sender.role} name={invite.sender.name} isInline /> : <div />}
      date={isoToFormattedLocalDate(invite.created_at)}
      time={isoToFormattedLocalTime(invite.created_at)}
      parent={'p'}
      t={t}
    />
  );

  const inClanCooldownTill = moment(invite.account.in_clan_cooldown_till).isAfter(moment().utc());
  if (inClanCooldownTill) {
    const cooldownAtDateTime = isoToFormattedLocalDateTime(invite.account.in_clan_cooldown_till);

    inviteStatusTooltip = (
      <div>
        {senderTooltipPart}
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Игрок недавно покинул клан и сможет вступить в клан начиная c %(datetime)s')}
          datetime={cooldownAtDateTime}
          parent={'p'}
          t={t}
        />
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Время для принятия решения по приглашению истекает %(date)s&nbsp;в&nbsp;%(time)s')}
          date={isoToFormattedLocalDate(invite.expires_at)}
          time={isoToFormattedLocalTime(invite.expires_at)}
          parent={'p'}
          t={t}
        />
      </div>
    );
  } else if (invite.status === 'active') {
    inviteStatusTooltip = (
      <div>
        {senderTooltipPart}
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Время для принятия решения по приглашению истекает %(date)s&nbsp;в&nbsp;%(time)s')}
          date={isoToFormattedLocalDate(invite.expires_at)}
          time={isoToFormattedLocalTime(invite.expires_at)}
          parent={'p'}
          t={t}
        />
      </div>
    );
  } else if (invite.status === 'expired') {
    inviteStatusTooltip = (
      <div>
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Время для принятия решения истекло %(date)s&nbsp;в&nbsp;%(time)s')}
          date={isoToFormattedLocalDate(invite.expires_at)}
          time={isoToFormattedLocalTime(invite.expires_at)}
          parent={'p'}
          t={t}
        />
        {senderTooltipPart}
      </div>
    );
  } else if (invite.status === 'accepted') {
    inviteStatusTooltip = (
      <div>
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Приглашение одобрено %(date)s&nbsp;в&nbsp;%(time)s')}
          date={isoToFormattedLocalDate(invite.updated_at)}
          time={isoToFormattedLocalTime(invite.updated_at)}
          parent={'p'}
          t={t}
        />
        {senderTooltipPart}
      </div>
    );
  } else if (invite.status === 'declined') {
    inviteStatusTooltip = (
      <div>
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Приглашение отклонено %(date)s&nbsp;в&nbsp;%(time)s')}
          date={isoToFormattedLocalDate(invite.updated_at)}
          time={isoToFormattedLocalTime(invite.updated_at)}
          parent={'p'}
          t={t}
        />
        {senderTooltipPart}
      </div>
    );
  }
  return inviteStatusTooltip;
};

const getCancelBtnTooltipContent = (t, invite) => {
  return (
    <TooltipBody>
      <Interpolate
        useDangerouslySetInnerHTML={true}
        i18nKey={x('Отменить приглашение игрока %(recipientName)s')}
        recipientName={<MemberName name={invite.account.name} isInline />}
        parent={'p'}
        t={t}
      />
    </TooltipBody>
  );
};

class InvitesClanMemberTableItem extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      isConextMenuActive: false,
      contextMenuContext: [],
      contextMenuPosition: { x: 0, y: 0 },
      isContextMenuActive: false,
    };

    this.onContextMenuItemClick = this.onContextMenuItemClick.bind(this);
    this.onContextMenu = this.onContextMenu.bind(this);
    this.onContextMenuStateChange = this.onContextMenuStateChange.bind(this);
    this.onTableRowClick = this.onTableRowClick.bind(this);
  }

  onContextMenu(event) {
    const context = this.getContextMenuContext();
    const clientY = getPositionY(event, context);
    this.setState({
      contextMenuContext: context,
      contextMenuPosition: {
        x: event.clientX,
        y: clientY,
      },
    });
  }

  onContextMenuStateChange(isContextMenuActive) {
    this.setState({ isContextMenuActive });
  }

  getContextMenuContext() {
    const t = this.props.t;
    const account = this.props.invite.account;
    const hasPermanentBan = get(account, 'ban_info.type') === 'permanent' || !!this.props.invite.is_banned;
    const banMsg = hasPermanentBan ? t('Аккаунт игрока заблокирован') : null;
    const canSendInvite = !hasPermanentBan && !this.props.hasActiveInviteForAccount && !this.props.isCurrentClanFull;
    let sendInviteTooltip = null;
    if (hasPermanentBan) {
      sendInviteTooltip = banMsg;
    } else if (this.props.hasActiveInviteForAccount) {
      sendInviteTooltip = t('Приглашение уже отправлено');
    } else if (this.props.isCurrentClanFull) {
      sendInviteTooltip = t('В клане сейчас нет мест');
    }
    return [
      {
        name: null,
        items: [
          {
            name: t('Отправить сообщение'),
            value: 'ACCOUNT_MESSAGE',
            disabled: hasPermanentBan || this.props.isChatDenied,
            tooltip: banMsg,
          },
          {
            name: t('Профиль игрока'),
            value: 'ACCOUNT_PROFILE',
            disabled: hasPermanentBan,
            tooltip: banMsg,
          },
          {
            name: t('Пригласить в клан'),
            disabled: !canSendInvite,
            tooltip: sendInviteTooltip,
            value: 'SEND_INVITE',
          },
        ],
      },
    ];
  }

  onContextMenuItemClick(action) {
    const account = this.props.invite.account;
    switch (action) {
      case 'ACCOUNT_PROFILE':
        this.props.openAccountProfile(account.id);
        break;
      case 'ACCOUNT_MESSAGE': {
        if (!this.props.isChatDenied) {
          this.props.openChatWindow(account.id, account.name);
        }
        break;
      }
      case 'SEND_INVITE':
        this.props.onSendInvite(account);
        break;
    }
  }

  onTableRowClick() {
    if (this.props.isChatDenied) {
      return;
    }
    void playButtonClickSound();
    const account = this.props.invite.account;
    this.props.openChatWindow(account.id, account.name);
  }

  render() {
    const { invite } = this.props;
    const isHiddenStatistics = invite.is_hidden_statistics;

    const inviteStatusTooltipId = `invite-status-tooltip-${invite.id}`;
    const inviteStatusTooltip = getInviteStatusTooltipContent(invite);

    const cancelBtnTooltipContent = getCancelBtnTooltipContent(t, invite);

    const glyph = `status-${invite.status}` as IconType;

    const tooltipStatus =
      inviteStatusTooltip && !this.state.isContextMenuActive ? (
        <Tooltip id={inviteStatusTooltipId}>
          <TooltipBody>{inviteStatusTooltip}</TooltipBody>
        </Tooltip>
      ) : null;

    return (
      <TableRow
        isHovering
        isActive={this.state.isContextMenuActive}
        onContextMenu={this.onContextMenu}
        onClick={this.onTableRowClick}
      >
        <ContextMenu
          isDemo={false}
          context={this.state.contextMenuContext}
          position={this.state.contextMenuPosition}
          onItemClick={this.onContextMenuItemClick}
          onContextMenuStateChange={this.onContextMenuStateChange}
        />
        <TableBodyCell modify="basis">
          <ClanMember
            id={invite.account.id}
            name={invite.account.name}
            rank={invite.statistics.rank}
            seasonId={invite.statistics.season_id}
            seasonRank={invite.statistics.season_rank}
            hasPermanentBan={get(invite, 'account.ban_info.type') === 'permanent' || !!invite.is_banned}
            disableTooltip={this.state.isContextMenuActive}
            leftMouseAction="send_message"
            hasContextMenu
          />
        </TableBodyCell>
        <TableBodyCell modify="small">
          <RankCell
            id={invite.account.id}
            rank={invite.statistics.rank}
            seasonId={invite.statistics.season_id}
            seasonRank={invite.statistics.season_rank}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            value={thousands(invite.statistics.btl)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            value={percent(invite.statistics.wb, undefined)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            value={thousands(invite.statistics.aeb)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            value={thousands(invite.statistics.admg)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            value={floats(invite.statistics.afb, 2)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <div data-for={inviteStatusTooltipId} data-tip>
            <Icon glyph={glyph} />
            {tooltipStatus}
          </div>
        </TableBodyCell>

        <TableBodyCell modify="center">
          {invite.status === 'active' ? (
            <ButtonAction
              action="cancel"
              onClick={() => this.props.onShowCancelInvite(invite)}
              tooltipContent={cancelBtnTooltipContent}
            />
          ) : null}
        </TableBodyCell>
      </TableRow>
    );
  }
}

export default translate()(InvitesClanMemberTableItem);
