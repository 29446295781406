import classNames from 'classnames';
import shuffle from 'lodash/shuffle';
import React from 'react';
import { DivTooltip, TooltipBody } from '@wg/wows-react-uikit';

import Flag from '~/Components/RecommendationsCards/Flag/Flag';
import styles from '~/Components/RecommendationsCards/Flag/Flag.scss';

interface IFlagsProps {
  className?: string;
  floating?: string;
  languages: string[];
  maxDisplayCount: number;
  needsShuffling?: boolean;
  onClick?: (e?: React.MouseEvent<Element>) => void;
}

const Flags: React.FC<IFlagsProps> = ({
  className = '',
  floating = 'left',
  languages = [],
  maxDisplayCount = 5,
  needsShuffling = false,
  onClick = undefined,
}) => {
  let content = languages.map((language) => <Flag onClick={onClick} key={language} language={language} />);

  if (needsShuffling) {
    content = shuffle(content);
  }

  if (content.length > maxDisplayCount) {
    const languageCount = maxDisplayCount - 1;

    const ellipsisLanguagesArr = languages.slice(languageCount, content.length);
    const ellipsisLanguages = ellipsisLanguagesArr.map((x, index) => (
      <span key={x} className={styles.tooltipLanguageWrapper}>
        <Flag language={x} isTooltipEnabled={false} isTitleEnabled={true} />
        {index % 2 === 1 ? <br /> : null}
      </span>
    ));

    content = content.slice(0, languageCount);

    const flagEllipsis = (
      <div key={-1} className={styles.languageWrapper}>
        <DivTooltip
          className={styles.languageEllipsis}
          tooltipBody={<TooltipBody className={styles.languagesWrapper}>{ellipsisLanguages}</TooltipBody>}
        >
          ...
        </DivTooltip>
      </div>
    );
    content.push(flagEllipsis);
  }
  return (
    <div className={classNames(styles.languages, { [styles.right]: floating === 'right' }, className)}>{content}</div>
  );
};

export default Flags;
