import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import React from 'react';
import { translate } from 'react-i18next';
import { ClanLeagueSignInline, DivTooltip, HiddenStatisticsIcon, Tooltip, TooltipBody } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { DEFAULT_ERROR, percent, thousands } from '~/helpers/formatting';
import { isClanBattleType } from '~/helpers/ladder';
import { getLocalizationSafe, t } from '~/helpers/localization';
import { getSeasonLeagues, lastSeason } from '~/helpers/seasons';

import { ClanLeagueSign } from '~/UIKit';

import styles from './Ratings.scss';

export type IRatings_Props = {
  clanId: number;
  membersError: boolean;
  currentSeason: number;
  statistics: {
    averageBattlesCounts: number;
    averageWinsPercentage: number;
    totalMembers: number;
    maxMembersCount: number;
    averageExperiencePerBattle: number;
    averageDamagePerBattle: number;
    ladderBattlesCount: number;
    ladderWinsCount: number;
    ladderWinsPercent: number;
    ladderMaxLeague: number;
    ladderMaxDivision: number;
    ladderMaxDivisionRating: number;
    ladderWinStreak: number;
    ladderLastWinAt: string;
    currentBattleType: string;
    isBrawl: boolean;
    withLeagueSign: boolean;
    league: number;
    division: number;
    stage: any;
    divisionRating: number;
    maxDivisionRating: number;
  };
  isHiddenStatistics: boolean;
};

class Ratings extends React.PureComponent<IRatings_Props> {
  membersErrorTooltip = t('Не удалось получить информацию об игроках клана:tooltip');

  renderWinsCounts = () => {
    if (this.props.statistics.currentBattleType !== 'brawl') {
      return null;
    }
    const averageBattlesCountsRender = this.props.isHiddenStatistics ? (
      <HiddenStatisticsIcon isLarger isClan t={t} />
    ) : (
      <span>{thousands(this.props.statistics.ladderWinsCount)}</span>
    );

    const tooltipAverageBattlesCounts = !this.props.isHiddenStatistics ? (
      <Tooltip id={`wcount-rating-tooltip-${this.props.clanId}`}>
        <TooltipBody>{this.props.membersError ? this.membersErrorTooltip : t('Количество побед')}</TooltipBody>
      </Tooltip>
    ) : null;

    return (
      <dl className={styles.item}>
        <DivTooltip tooltipBody={tooltipAverageBattlesCounts}>
          <dd className={styles.value}>{averageBattlesCountsRender}</dd>
          <dt>{t('Количество побед')}</dt>
        </DivTooltip>
      </dl>
    );
  };

  renderAverageBattlesCounts() {
    if (this.props.statistics.currentBattleType === 'brawl') {
      return null;
    }

    const averageBattlesCountsRender = this.props.isHiddenStatistics ? (
      <HiddenStatisticsIcon isLarger isClan />
    ) : (
      <span>{thousands(this.props.statistics.averageBattlesCounts)}</span>
    );

    const tooltipAverageBattlesCounts = !this.props.isHiddenStatistics ? (
      <Tooltip id={`abtl-rating-tooltip-${this.props.clanId}`}>
        <TooltipBody>
          {this.props.membersError
            ? this.membersErrorTooltip
            : t('Среднее количество боёв на игрока клана в выбранном типе боев за всё время:tooltip')}
        </TooltipBody>
      </Tooltip>
    ) : null;

    return !isClanBattleType(this.props.statistics.currentBattleType) ? (
      <dl className={styles.item}>
        <dl className={styles.item}>
          <DivTooltip tooltipBody={tooltipAverageBattlesCounts}>
            <dd className={styles.value}>{averageBattlesCountsRender}</dd>
            <dt>{t('Количество боёв')}</dt>
          </DivTooltip>
        </dl>
      </dl>
    ) : null;
  }

  renderAverageWins() {
    const averageWinsPercentageRender = this.props.isHiddenStatistics ? (
      <HiddenStatisticsIcon isLarger isClan t={t} />
    ) : (
      <span>{percent(this.props.statistics.averageWinsPercentage)}</span>
    );

    const tooltipAverageWinsPercentage = !this.props.isHiddenStatistics ? (
      <Tooltip id={`aw-rating-tooltip-${this.props.clanId}`}>
        <TooltipBody>
          {this.props.membersError
            ? this.membersErrorTooltip
            : t('Средний процент побед на игрока клана в выбранном типе боев за всё время:tooltip')}
        </TooltipBody>
      </Tooltip>
    ) : null;

    return !isClanBattleType(this.props.statistics.currentBattleType) ? (
      <dl className={styles.item}>
        <DivTooltip tooltipBody={tooltipAverageWinsPercentage}>
          <dd className={styles.value}>{averageWinsPercentageRender}</dd>
          <dt>{t('Победы')}</dt>
        </DivTooltip>
      </dl>
    ) : null;
  }

  renderClanBattlesCount() {
    if (this.props.statistics.currentBattleType === 'brawl') {
      return null;
    }

    const tooltipClanBattles = !this.props.isHiddenStatistics ? (
      <Tooltip id={`clan-battles-rating-tooltip-${this.props.clanId}`}>
        <TooltipBody>{t('Общее количество боев клана за сезон:tooltip')}</TooltipBody>
      </Tooltip>
    ) : null;

    return isClanBattleType(this.props.statistics.currentBattleType) ? (
      <dl className={styles.item}>
        <DivTooltip tooltipBody={tooltipClanBattles}>
          <dd className={styles.value}>{thousands(this.props.statistics.ladderBattlesCount)}</dd>
          <dt>{t('Количество боёв')}</dt>
        </DivTooltip>
      </dl>
    ) : null;
  }

  renderClanWins() {
    const tooltipClanWins = !this.props.isHiddenStatistics ? (
      <Tooltip id={`clan-wins-rating-tooltip-${this.props.clanId}`}>
        <TooltipBody>{t('Процент побед клана за сезон:tooltip')}</TooltipBody>
      </Tooltip>
    ) : null;

    return isClanBattleType(this.props.statistics.currentBattleType) ? (
      <dl className={styles.item}>
        <DivTooltip tooltipBody={tooltipClanWins}>
          <dd className={styles.value}>{percent(this.props.statistics.ladderWinsPercent)}</dd>
          <dt>{t('Победы')}</dt>
        </DivTooltip>
      </dl>
    ) : null;
  }

  renderMembersRating() {
    const totalMembers = isNumber(this.props.statistics.totalMembers)
      ? this.props.statistics.totalMembers
      : DEFAULT_ERROR;
    const maxMembersCount = isNumber(this.props.statistics.maxMembersCount)
      ? this.props.statistics.maxMembersCount
      : DEFAULT_ERROR;

    return !this.props.statistics.withLeagueSign ? (
      <dl className={styles.item}>
        <DivTooltip
          tooltipBody={
            <Tooltip id={`members-rating-tooltip-${this.props.clanId}`}>
              <TooltipBody>
                {this.props.membersError && !isNumber(this.props.statistics.totalMembers)
                  ? this.membersErrorTooltip
                  : t('Количество игроков в клане / Максимальная численность клана:tooltip')}
              </TooltipBody>
            </Tooltip>
          }
        >
          <dd>
            <span className={styles.value}>{totalMembers}</span>
            <span className={styles.total}>&nbsp;/&nbsp;</span>
            <span className={styles.total}>{maxMembersCount}</span>
          </dd>
          <dt>{t('Игроков в клане')}</dt>
        </DivTooltip>
      </dl>
    ) : null;
  }

  renderLeagueSign() {
    const defaultMaxDivisionRating = 100;

    return !isEmpty(lastSeason) && this.props.statistics.withLeagueSign ? (
      <dl className={styles.medalWrapper}>
        <ClanLeagueSign
          clanDivisionRating={this.props.statistics.divisionRating || 0}
          clanMaxDivisionRating={this.props.statistics.maxDivisionRating || defaultMaxDivisionRating}
          clanDivision={this.props.statistics.division}
          clanLeague={this.props.statistics.league}
          clanStage={this.props.statistics.stage}
          isFullSize={true}
        />
      </dl>
    ) : null;
  }

  renderAverageExpPerBattle() {
    const averageExperiencePerBattleRender = this.props.isHiddenStatistics ? (
      <HiddenStatisticsIcon isLarger isClan t={t} />
    ) : (
      <span>{thousands(this.props.statistics.averageExperiencePerBattle)}</span>
    );

    const tooltipAverageExperiencePerBattle = !this.props.isHiddenStatistics ? (
      <Tooltip id={`ae-rating-tooltip-${this.props.clanId}`}>
        <TooltipBody>
          {this.props.membersError
            ? this.membersErrorTooltip
            : t('Средний опыт за бой на игрока клана в выбранном типе боев за всё время:tooltip')}
        </TooltipBody>
      </Tooltip>
    ) : null;

    return !isClanBattleType(this.props.statistics.currentBattleType) ? (
      <dl className={styles.item}>
        <DivTooltip tooltipBody={tooltipAverageExperiencePerBattle}>
          <dd className={styles.value}>{averageExperiencePerBattleRender}</dd>
          <dt>{t('Опыт за бой')}</dt>
        </DivTooltip>
      </dl>
    ) : null;
  }

  renderAverageDamagePerBattle() {
    const averageDamagePerBattleRender = this.props.isHiddenStatistics ? (
      <HiddenStatisticsIcon isLarger isClan t={t} />
    ) : (
      <span>{thousands(this.props.statistics.averageDamagePerBattle)}</span>
    );

    const tooltipAverageDamagePerBattle = !this.props.isHiddenStatistics ? (
      <Tooltip id={`admg-rating-tooltip-${this.props.clanId}`}>
        <TooltipBody>
          {this.props.membersError
            ? this.membersErrorTooltip
            : t('Средний урон за бой на игрока клана в выбранном типе боев за всё время:tooltip')}
        </TooltipBody>
      </Tooltip>
    ) : null;

    return !isClanBattleType(this.props.statistics.currentBattleType) ? (
      <dl className={styles.item}>
        <DivTooltip tooltipBody={tooltipAverageDamagePerBattle}>
          <dd className={styles.value}>{averageDamagePerBattleRender}</dd>
          <dt>{t('Урон за бой')}</dt>
        </DivTooltip>
      </dl>
    ) : null;
  }

  renderInlineSign() {
    if (this.props.statistics.currentBattleType !== 'cvc') {
      return null;
    }
    let tooltipInlineSign = null;
    let leagueColor = '';
    let leagueDivisionInfoTitle = '';
    let leagueTitle = '';

    if (!isClanBattleType(this.props.statistics.currentBattleType) || this.props.currentSeason === null) {
      return null;
    }

    if (!this.props.isHiddenStatistics && !isEmpty(settings.ladder.seasons)) {
      const leaguesInfo = getSeasonLeagues(this.props.currentSeason);
      const currentLeague = leaguesInfo[this.props.statistics.ladderMaxLeague];

      if (!currentLeague) return;

      const leagueDivisionsLength = currentLeague.divisions.length;

      leagueDivisionInfoTitle = currentLeague.divisions[leagueDivisionsLength - this.props.statistics.ladderMaxDivision]
        ? currentLeague.divisions[leagueDivisionsLength - this.props.statistics.ladderMaxDivision].title
        : '';
      leagueColor = currentLeague.color;
      leagueTitle = getLocalizationSafe(currentLeague, 'mark');

      tooltipInlineSign = (
        <Tooltip>
          <TooltipBody>
            <div>
              {t('Максимальное значение рейтинга клана за сезон:tooltip')}
              <br />
              {t('Лига:')} {leagueTitle},&nbsp;
              {t('Дивизион:')} {leagueDivisionInfoTitle}
              <br />
              {t('Рейтинг в дивизионе:')} {this.props.statistics.ladderMaxDivisionRating}
            </div>
          </TooltipBody>
        </Tooltip>
      );

      return (
        <dl className={styles.item}>
          <DivTooltip tooltipBody={tooltipInlineSign}>
            <dd className={styles.value}>
              <span className={styles.leagueInlineSign}>
                <ClanLeagueSignInline color={leagueColor} title={leagueDivisionInfoTitle} />
              </span>
              <span>{this.props.statistics.ladderMaxDivisionRating}</span>
            </dd>
            <dt>{t('Рекорд сезона')}</dt>
          </DivTooltip>
        </dl>
      );
    } else {
      return null;
    }
  }

  renderWinStreak() {
    const tooltipClanWinStreak = !this.props.isHiddenStatistics ? (
      <Tooltip id={`clan-win-streak-tooltip-${this.props.clanId}`}>
        <TooltipBody>{t('Максимальная длина череды побед клана за сезон:tooltip')}</TooltipBody>
      </Tooltip>
    ) : null;

    return isClanBattleType(this.props.statistics.currentBattleType) ? (
      <dl className={styles.item}>
        <DivTooltip tooltipBody={tooltipClanWinStreak}>
          <dd className={styles.value}>{thousands(this.props.statistics.ladderWinStreak)}</dd>
          <dt>{t('Победная серия')}</dt>
        </DivTooltip>
      </dl>
    ) : null;
  }

  render() {
    return (
      <div
        className={classNames(styles.ratings, {
          [styles.center]: this.props.statistics.withLeagueSign,
        })}
      >
        {this.renderLeagueSign()}
        {this.renderAverageBattlesCounts()}
        {this.renderWinsCounts()}
        {this.renderAverageWins()}
        {this.renderClanBattlesCount()}
        {this.renderClanWins()}
        {this.renderMembersRating()}
        {this.renderAverageExpPerBattle()}
        {this.renderAverageDamagePerBattle()}
        {this.renderInlineSign()}
        {this.renderWinStreak()}
      </div>
    );
  }
}

export default translate()(Ratings);
