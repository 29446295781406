import * as React from 'react';
import { I18nextProvider } from 'react-i18next';

import { OldBrowserStub } from '~/UIKit';

import i18n from './i18n';

const oldBrowserApplication: React.FC = () => (
  <I18nextProvider i18n={i18n}>
    <OldBrowserStub />
  </I18nextProvider>
);

export default React.memo(oldBrowserApplication);
