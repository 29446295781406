import '~/styles/global.scss';

import ReactDOM from 'react-dom/client';

import preloaded from '~/preloaded';

import StaticPage from '~/Components/StaticPage/StaticPage';

const STATIC_PAGE = 'static';
const ROOT = preloaded.urls?.root;
const showStaticPage = window.location.pathname.replace(ROOT, '') === STATIC_PAGE;

if (showStaticPage) {
  const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);
  root.render(<StaticPage />);
} else {
  require('./app');
}
