import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import * as React from 'react';
import { translate } from 'react-i18next';
import { Checkbox, ClanMember, ClanRole, ContextMenu, TableBodyCell, TableRow } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { isInGame } from '~/constants';
import { isChatDenied } from '~/helpers/account';
import { getPositionY } from '~/helpers/contextMenu';
import { floats, percent, thousands } from '~/helpers/formatting';
import { getRank, hasPermission, PERMISSIONS, ROLE_NAMES, getLocalizedName } from '~/roles';
import { MemberIsBannedTooltip } from '~/tooltips';
import { playCheckboxSound } from '~/web2ClientAPI/web2ClientAPI';

import { FormattedLastBattleTime, ResourceCell, StatisticsValue } from '~/UIKit';

const propTypes = {
  clanId: PropTypes.number.isRequired,
  currentAccount: PropTypes.shape({
    id: PropTypes.number.isRequired,
    clanId: PropTypes.number,
    roleName: PropTypes.oneOf(Object.values(ROLE_NAMES)),
    accumulativeClanResource: PropTypes.number,
    isBonusActivated: PropTypes.bool,
  }).isRequired,
  member: PropTypes.object.isRequired,

  isContextMenuEnabled: PropTypes.bool,
  displayCheckboxes: PropTypes.bool,
  disabledCheckbox: PropTypes.bool,
  isMember: PropTypes.bool,
  isSelectedMember: PropTypes.bool,
  currentBattleType: PropTypes.string,

  openAccountProfile: PropTypes.func.isRequired,
  openChatWindow: PropTypes.func.isRequired,

  onTickMember: PropTypes.func,
  onChangeCommanderClick: PropTypes.func,
  onChangeRoleClick: PropTypes.func,
  onRemoveMembersClick: PropTypes.func,

  t: PropTypes.func.isRequired,
};

const noop = () => {};

const defaultProps = {
  displayCheckboxes: false,
  isMember: false,
  onTickMember: noop,
};

const CLAN_BATTLES_TYPE = 'cvc';

class MembersTableItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isContextMenuActive: false,
      contextMenuContext: [],
      contextMenuPosition: { x: 0, y: 0 },
    };

    this.onContextMenu = this.onContextMenu.bind(this);
    this.onContextMenuitemClick = this.onContextMenuitemClick.bind(this);
    this.onTableRowClick = this.onTableRowClick.bind(this);
    this.onTickMember = this.onTickMember.bind(this);
    this.onContextMenuStateChange = this.onContextMenuStateChange.bind(this);
    this.isChatDenied = this.isChatDenied.bind(this);
  }

  isChatDenied() {
    return isChatDenied(this.props.currentAccount, this.props.member);
  }

  onContextMenuStateChange(isContextMenuActive) {
    this.setState({ isContextMenuActive });
  }

  getContextMenuItemsContextForOwnClanMember() {
    const member = this.props.member;
    const t = this.props.t;

    const group = {
      isNeedDivider: false,
      items: [],
    };

    const accountRole = this.props.currentAccount.roleName;
    if (getRank(accountRole) > getRank(member.roleName)) {
      if (this.props.onChangeRoleClick && hasPermission(accountRole, PERMISSIONS.CHANGE_ROLE)) {
        group.items.push({
          name: t('Изменить должность'),
          value: 'CHANGE_ROLE',
        });
      }

      if (
        this.props.onChangeCommanderClick &&
        !member.isBanned &&
        hasPermission(accountRole, PERMISSIONS.CHANGE_COMMANDER)
      ) {
        group.items.push({
          name: t('Передать управление'),
          value: 'CHANGE_COMMANDER',
        });
      }

      if (this.props.onRemoveMembersClick && hasPermission(accountRole, PERMISSIONS.REMOVE_MEMBER)) {
        group.items.push({
          name: t('Исключить из клана'),
          value: 'REMOVE_MEMBER',
        });
      }

      group.isNeedDivider = group.items.length > 0;
    }

    return group.items.length > 0 ? group : null;
  }

  getContextMenuContext() {
    const isOwn = this.props.currentAccount.id === this.props.member.id;
    const t = this.props.t;
    const isBanned = this.props.member.isBanned;
    const banMsg = isBanned ? t('Аккаунт игрока заблокирован') : null;

    const items =
      !isOwn && isInGame
        ? [
            {
              name: t('Отправить сообщение'),
              value: 'OPEN_CHAT_WINDOW',
              disabled: isBanned || this.isChatDenied(),
              tooltip: banMsg,
            },
          ]
        : [];

    const context = [
      {
        isNeedDivider: false,
        items: items.concat([
          {
            name: t('Профиль игрока'),
            value: 'OPEN_ACCOUNT_PROFILE',
            disabled: isBanned,
            tooltip: banMsg,
          },
          {
            name: t('Копировать никнейм'),
            value: 'COPY_NICKNAME',
            disabled: false,
            tooltip: null,
          },
        ]),
      },
    ];

    const isClanMember = this.props.currentAccount.clanId === this.props.clanId;
    if (isClanMember) {
      const group = this.getContextMenuItemsContextForOwnClanMember();
      group && context.push(group);
    }

    return context;
  }

  onContextMenu(event) {
    const context = this.getContextMenuContext();
    const clientY = getPositionY(event, context);
    this.setState({
      contextMenuContext: context,
      contextMenuPosition: {
        x: event.clientX,
        y: clientY,
      },
    });
  }

  onContextMenuitemClick(action) {
    const member = this.props.member;
    switch (action) {
      case 'CHANGE_COMMANDER':
        this.props.onChangeCommanderClick && this.props.onChangeCommanderClick(member);
        break;
      case 'REMOVE_MEMBER':
        this.props.onRemoveMembersClick && this.props.onRemoveMembersClick([member]);
        break;
      case 'CHANGE_ROLE':
        this.props.onChangeRoleClick && this.props.onChangeRoleClick(member.id);
        break;
      case 'OPEN_ACCOUNT_PROFILE':
        this.props.openAccountProfile(member.id);
        break;
      case 'OPEN_CHAT_WINDOW': {
        if (!this.isChatDenied()) {
          this.props.openChatWindow(member.id, member.name);
        }
        break;
      }
      case 'COPY_NICKNAME': {
        copy(member.name);
        break;
      }
    }
  }

  onTableRowClick() {
    const isOwn = this.props.currentAccount.id === this.props.member.id;
    if (isOwn || this.state.isContextMenuActive) {
      return;
    }
    if ((!this.props.displayCheckboxes || this.props.disabledCheckbox) && !this.isChatDenied()) {
      const member = this.props.member;
      this.props.openChatWindow(member.id, member.name);
    } else {
      this.onTickMember();
      playCheckboxSound();
    }
  }

  onTickMember() {
    this.props.onTickMember(this.props.member.id);
  }

  render() {
    const t = this.props.t;
    const member = this.props.member;
    const isHiddenStatistics = member.isHiddenStatistics;

    let tableCellWithCheckboxes = null;
    if (this.props.displayCheckboxes) {
      let tooltipContent = null;
      if (this.props.disabledCheckbox) {
        tooltipContent = t('Вы не можете совершать действий с игроками равными по должности или выше.');
      }

      tableCellWithCheckboxes = (
        <TableBodyCell modify="check">
          <Checkbox
            isDisabled={this.props.disabledCheckbox}
            isChecked={this.props.isSelectedMember}
            tooltipContent={tooltipContent}
            onChange={this.onTickMember}
          />
        </TableBodyCell>
      );
    }

    const isOwn = this.props.currentAccount.id === member.id;
    const hideStatistics = !isOwn && isHiddenStatistics;

    const contextMenu = this.props.isContextMenuEnabled ? (
      <ContextMenu
        context={this.state.contextMenuContext}
        position={this.state.contextMenuPosition}
        onItemClick={this.onContextMenuitemClick}
        onContextMenuStateChange={this.onContextMenuStateChange}
      />
    ) : null;

    const onContextMenu = this.props.isContextMenuEnabled ? this.onContextMenu : null;

    const leftMouseAction = isOwn
      ? null
      : !this.props.displayCheckboxes || this.props.disabledCheckbox
        ? 'send_message'
        : 'select';

    let isHighlightedAdmittance = false;
    let gainedResourceCell = null;
    if (settings.supply.isEnabled && this.props.isMember) {
      const accumulativeClanResource = isOwn
        ? this.props.currentAccount.accumulativeClanResource
        : member.accumulativeClanResource;
      const isBonusActivated = isOwn ? this.props.currentAccount.isBonusActivated : member.isBonusActivated;

      const accountRole = this.props.currentAccount.roleName;
      const canViewSupplyAdmittance = hasPermission(accountRole, PERMISSIONS.SUPPLY_VIEW_CLAN_ADMITTANCE);
      const canViewLeveling = canViewSupplyAdmittance || isOwn;
      const isVisibleResource = !isHiddenStatistics || canViewLeveling;
      isHighlightedAdmittance = !isOwn && canViewSupplyAdmittance && !isBonusActivated;
      gainedResourceCell = (
        <TableBodyCell modify="gainedResource">
          <ResourceCell
            value={accumulativeClanResource}
            memberId={member.id}
            isHidden={!isVisibleResource}
            canViewLeveling={canViewLeveling}
            isBonusActivated={isBonusActivated}
            isHighlightedAdmittance={isHighlightedAdmittance}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
      );
    }

    return (
      <TableRow
        isHovering
        isActive={this.props.isSelectedMember || this.state.isContextMenuActive}
        isOwn={isOwn}
        onContextMenu={onContextMenu}
        onClick={this.onTableRowClick}
      >
        {contextMenu}
        {tableCellWithCheckboxes}
        <TableBodyCell modify="role">
          <ClanRole role={member.roleName} isLarger />
        </TableBodyCell>
        <TableBodyCell modify="basis">
          <ClanMember
            id={member.id}
            name={member.name}
            role={getLocalizedName(member.roleName)}
            rank={member.rank}
            seasonId={member.seasonId}
            seasonRank={member.seasonRank}
            isOwn={isOwn}
            isHighlightedAdmittance={isHighlightedAdmittance}
            isHiddenStatistics={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
            leftMouseAction={leftMouseAction}
            hasContextMenu={this.props.isContextMenuEnabled && !isOwn}
            memberIsBannedTooltipId={MemberIsBannedTooltip.id}
          />
        </TableBodyCell>
        {gainedResourceCell}
        <TableBodyCell modify="right">
          <StatisticsValue
            value={thousands(member.battlesCount)}
            isHidden={hideStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            value={percent(member.winsPercentage)}
            isHidden={hideStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right" isHidden={this.props.currentBattleType === CLAN_BATTLES_TYPE}>
          <StatisticsValue
            value={thousands(member.expPerBattle)}
            isHidden={hideStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right" isHidden={this.props.currentBattleType === CLAN_BATTLES_TYPE}>
          <StatisticsValue
            value={thousands(member.damagePerBattle)}
            isHidden={hideStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right" isHidden={this.props.currentBattleType === CLAN_BATTLES_TYPE}>
          <StatisticsValue
            value={floats(member.fragsPerBattle, 2)}
            isHidden={hideStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">{thousands(member.daysInClan)}</TableBodyCell>
        <TableBodyCell modify="right">
          <FormattedLastBattleTime
            date={member.lastBattleTime}
            isHidden={hideStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
      </TableRow>
    );
  }
}

MembersTableItem.propTypes = propTypes;
MembersTableItem.defaultProps = defaultProps;

export default translate()(MembersTableItem);
