import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';

import styles from './Tooltip.scss';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  withMouseClick: PropTypes.bool,
};

const TooltipBody = (props) => {
  const classNameBody = classNames(styles.body, {
    [styles.withMouseClick]: props.withMouseClick,
  });

  return <div className={classNameBody}>{props.children}</div>;
};

TooltipBody.propTypes = propTypes;

export default TooltipBody;
