import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';

import styles from './Tooltip.scss';

const propTypes = {
  children: PropTypes.node.isRequired,

  isHelpIcon: PropTypes.bool,
  isBold: PropTypes.bool,
};

const TooltipHeader = (props) => {
  const classNameHeader = classNames(styles.header, {
    [styles.isHelpIcon]: props.isHelpIcon,
    [styles.isBold]: props.isBold,
  });

  return <div className={classNameHeader}>{props.children}</div>;
};

TooltipHeader.propTypes = propTypes;

export default TooltipHeader;
