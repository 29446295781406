import settings from '~/settings';
import { t } from '~/helpers/localization';
import { hasSeasonRank } from '~/helpers/rank';

import { TooltipMemberContent } from '~/UIKit';

import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';
import type { IClanMember } from '~/types/declaration';

export const getMemberTooltipContent = (
  member: IClanMember,
  currentAccount: ICurrentAccountState,
  isContextMenuEnabled: boolean,
  displayCheckboxes: boolean = false,
  disabledIds: number[] = [],
) => {
  let hasSeason = false;
  let memberRank: Nullable<number> = member.rank;
  let memberAchievement = '';

  if (hasSeasonRank(member.seasonRank, member.seasonId)) {
    hasSeason = true;
    memberRank = member.seasonRank;
    const seasonName = settings.seasonNames[Number(member.seasonId)];
    memberAchievement = t('%(rank)s ранг в сезоне %(seasonName)s', {
      rank: Number(member.seasonRank),
      seasonName: seasonName,
    });
  }

  const isOwn = currentAccount.id === member.id;
  const disabledCheckbox = displayCheckboxes && disabledIds.indexOf(member.id) !== -1;
  const leftMouseAction = isOwn ? null : !displayCheckboxes || disabledCheckbox ? 'send_message' : 'select';

  let needResource = 0;
  const isBonusActivated = isOwn ? currentAccount.isBonusActivated : member.isBonusActivated;
  const leveling = isOwn ? currentAccount.leveling : member.leveling;
  if (leveling && !isBonusActivated) {
    needResource = settings.supply.clanLevelingToAccountLeveling[leveling];
  }

  return (
    <TooltipMemberContent
      name={member.name}
      rank={memberRank}
      hasSeason={hasSeason}
      achievement={memberAchievement}
      needResource={needResource}
      showSendMessage={leftMouseAction === 'send_message'}
      showSelect={leftMouseAction === 'select'}
      showRightMouse={isContextMenuEnabled && !isOwn}
    />
  );
};
