import PropTypes from 'prop-types';
import * as React from 'react';
import { IconTable, TableHead } from '@wg/wows-react-uikit';

import { SEASON_TYPES } from '~/constants';
import { isChinese } from '~/helpers/realm';

import headers from './headers.settings';
import TableHeadCell from '../Table/TableHeadCell';

class ClansRatingTableHead extends React.PureComponent {
  static propTypes = {
    isCrossRealmLadder: PropTypes.bool,
    isOldSeason: PropTypes.bool,
    seasonType: PropTypes.string,
    stickyContainerId: PropTypes.string.isRequired,
  };

  render() {
    const isBrawl = this.props.seasonType === SEASON_TYPES.BRAWL;

    return (
      <TableHead stickyContainerId={this.props.stickyContainerId} isActiveSticky={false}>
        {headers(this.props.isCrossRealmLadder, this.props.isOldSeason, isBrawl).map((item, index) => {
          return (
            <TableHeadCell
              key={index}
              isHidden={item.isHidden}
              modify={item.modify}
              tooltipText={item.tooltipText}
              tooltipParam={!isChinese() ? item.tooltipParam : ''}
            >
              {item.glyph ? <IconTable glyph={item.glyph} /> : null}
              {item.text}
            </TableHeadCell>
          );
        })}
      </TableHead>
    );
  }
}

export default ClansRatingTableHead;
