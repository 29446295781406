import settings from '~/settings';

import type { ClanInfoType } from '~/Actions/ActionClanProfile';

export const clanIsFull = (clan: ClanInfoType) => {
  return clan.members_count >= clan.maxMembersCount;
};

export const clanCost = () => {
  return settings.clanCreate.cost - settings.clanCreate.discount;
};
