import * as React from 'react';
import { Rank } from '@wg/wows-react-uikit';

import { DEFAULT_ERROR } from '~/helpers/formatting';
import { hasAnyRank, hasSeasonRank } from '~/helpers/rank';
import { MemberInfoMissingTooltip } from '~/tooltips';

type RankCellType = {
  id?: number;
  rank?: number;
  seasonId?: number;
  seasonRank?:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24
    | 25
    | 26
    | 27
    | 28
    | 29
    | 30;
  disableTooltip?: boolean;
};

const RankCell: React.FC<RankCellType> = (props) => {
  let tooltipId;
  let rank = null;
  let dataTip: number | boolean = true;

  if (hasAnyRank(props.rank, props.seasonRank, props.seasonId)) {
    tooltipId = !props.disableTooltip ? 'rank-tooltip' : null;
    dataTip = props.id;

    rank = hasSeasonRank(props.seasonRank, props.seasonId) ? <Rank level={props.seasonRank} /> : props.rank;
  } else {
    tooltipId = !props.disableTooltip ? MemberInfoMissingTooltip.id : null;

    rank = DEFAULT_ERROR;
  }

  return (
    <div data-tip={dataTip} data-for={tooltipId}>
      {rank}
    </div>
  );
};

export default RankCell;
