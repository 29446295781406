import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';

import { createPortal } from 'react-dom';

import { getPositionOnViewport } from '~/helpers/position';

import styles from './Tooltip.scss';

import type { ReactNode } from 'react';

const CURSOR_WIDTH = 18;
const CURSOR_HEIGHT = 27;
const ELEMENT_OFFSET_X = 4;
const ELEMENT_OFFSET_Y = 2;
const TOOLTIP_SHOW_DELAY = 50;

class TooltipManager {
  tooltipTarget: any;

  constructor() {
    this.tooltips = Object.create(null);
    this.activeTooltip = null;

    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onEscape = this.onEscape.bind(this);
    this.destroyActiveTooltip = this.destroyActiveTooltip.bind(this);
  }

  addTooltip(id, tooltip) {
    if (this.tooltips[id]) throw `duplicate tooltip id - ${id}`;
    if (isEmpty(this.tooltips)) document.addEventListener('mouseover', this.onMouseOver);
    this.tooltips[id] = tooltip;
  }

  removeTooltip(id) {
    const tooltip = this.tooltips[id];
    if (!tooltip) {
      /* eslint-disable no-console */
      console.warn(`try delete tooltip id "${id}" that does not exist`);
      return;
    }
    if (tooltip === this.activeTooltip) this.destroyActiveTooltip();
    delete this.tooltips[id];
    if (isEmpty(this.tooltips)) document.removeEventListener('mouseover', this.onMouseOver);
  }

  onMouseLeave(e) {
    const relatedTarget = e.relatedTarget && this.getClosestParentWithTooltip(e.relatedTarget);
    if (
      relatedTarget &&
      e.target.dataset.for === relatedTarget.dataset.for &&
      e.target.dataset.tip === relatedTarget.dataset.tip
    ) {
      this.removeListeners();
      this.addListeners(relatedTarget);
    } else {
      this.destroyActiveTooltip();
    }
  }

  onMouseOver(e) {
    const target = this.getClosestParentWithTooltip(e.target);
    if (target && target.dataset.for) {
      const tooltip = this.tooltips[target.dataset.for];
      if (tooltip && this.activeTooltip !== tooltip) {
        this.activateTooltip(tooltip, target);
      }
    }
  }

  onMouseMove(e) {
    this.activeTooltip.move(e);
  }

  onEscape(e) {
    if (e.keyCode === 27 && this.activeTooltip) {
      e.preventDefault();
      this.destroyActiveTooltip();
    }
  }

  activateTooltip(tooltip, target) {
    if (this.activeTooltip) this.destroyActiveTooltip();
    this.activeTooltip = tooltip;
    this.activeTooltip.activate(target.dataset.tip);
    this.addListeners(target);
    document.addEventListener('keydown', this.onEscape, true);
    document.getElementById('app').addEventListener('scroll', this.destroyActiveTooltip);
  }

  destroyActiveTooltip() {
    if (!this.activeTooltip) return;
    this.activeTooltip.hide();
    this.activeTooltip = null;
    document.removeEventListener('keydown', this.onEscape, true);
    document.getElementById('app').removeEventListener('scroll', this.destroyActiveTooltip);
    this.removeListeners();
  }

  addListeners(target) {
    this.tooltipTarget = target;
    this.tooltipTarget.addEventListener('contextmenu', this.destroyActiveTooltip);
    this.tooltipTarget.addEventListener('mousemove', this.onMouseMove, true);
    this.tooltipTarget.addEventListener('mouseleave', this.onMouseLeave);
  }

  removeListeners() {
    this.tooltipTarget.removeEventListener('contextmenu', this.destroyActiveTooltip);
    this.tooltipTarget.removeEventListener('mousemove', this.onMouseMove, true);
    this.tooltipTarget.removeEventListener('mouseleave', this.onMouseLeave);
    this.tooltipTarget = null;
  }

  updateActiveTooltip() {
    if (!this.activeTooltip) return;
    const target = this.tooltipTarget;
    const tooltip = this.tooltips[target.dataset.for];
    if (tooltip !== this.activeTooltip) this.activateTooltip(tooltip, target);
  }

  getClosestParentWithTooltip(target) {
    while (!target.dataset.for) {
      target = target.parentNode;
      if (target === document) return;
    }
    return target;
  }
}

type TooltipPropsType = {
  children?: ReactNode;
  id: string;
  width?: string;
  isDemo?: boolean;
  getContent?: (arg: ReactNode) => ReactNode;
};

type TooltipStateType = {
  isVisible: boolean;
  top: number;
  left: number;
  tooltipTip: ReactNode;
  timeoutId: ReturnType<typeof setTimeout>;
};

class Tooltip extends React.PureComponent<TooltipPropsType, TooltipStateType> {
  static manager = new TooltipManager();
  private tooltip: HTMLDivElement | null = null;

  constructor(props: TooltipPropsType) {
    super(props);

    this.state = {
      isVisible: false,
      top: 0,
      left: 0,
      tooltipTip: null,
      timeoutId: null,
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    Tooltip.manager.addTooltip(this.props.id, this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.props.id) {
      Tooltip.manager.addTooltip(nextProps.id, this);
      Tooltip.manager.removeTooltip(this.props.id);
    }
  }

  componentWillUnmount() {
    Tooltip.manager.removeTooltip(this.props.id);
  }

  activate(tooltipTip: ReactNode) {
    this.setState({
      tooltipTip,
      timeoutId: setTimeout(this.show, TOOLTIP_SHOW_DELAY),
    });
  }

  move(e) {
    this.setPosition(e);
  }

  show() {
    this.setState({ isVisible: true });
  }

  hide() {
    this.clearTimeouts();
    this.setState({
      isVisible: false,
      tooltipTip: null,
    });
  }

  setPosition(e) {
    const initialPosition = {
      top: e.clientY,
      left: e.clientX,
    };
    const position = getPositionOnViewport(this.tooltip, initialPosition, {
      CURSOR_WIDTH,
      CURSOR_HEIGHT,
      ELEMENT_OFFSET_X,
      ELEMENT_OFFSET_Y,
    });
    this.setState(position);
  }

  clearTimeouts() {
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }
  }

  render() {
    const classNameTooltip = classNames(styles.tooltip, {
      [styles.isVisible]: this.state.isVisible,
      [styles.isDemo]: this.props.isDemo,
    });

    const style = {
      top: this.state.top,
      left: this.state.left,
      maxWidth: this.props.width ? this.props.width : null,
    };

    const content = this.props.children || (this.state.tooltipTip && this.props.getContent(this.state.tooltipTip));

    const tooltip = (
      <div className={classNameTooltip} style={style} ref={(c) => (this.tooltip = c)}>
        {content}
      </div>
    );

    return this.props.isDemo ? tooltip : createPortal(tooltip, document.querySelector('body'));
  }
}

export default Tooltip;
