import { hasCrossRealm } from '~/settings';
import { t } from '~/helpers/localization';

export default function () {
  return [
    {
      isHidden: !hasCrossRealm,
      modify: 'left',
      name: 'realm',
      text: t('Реалм'),
    },
    {
      modify: 'basis',
      text: t('Клан'),
    },
    {
      modify: 'right',
      text: t('Боёв'),
    },
    {
      modify: 'right',
      text: t('Последний бой'),
    },
    {
      modify: 'right',
      text: t('Игроков'),
    },
  ];
}
