import classNames from 'classnames';
import React from 'react';
import { DivTooltip, TooltipBody, TooltipHeader } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import styles from './ClanBaseCompletion.scss';

type IClanBaseCompletionProps = {
  completion: number;
  onClick?: () => void;
};

const ClanBaseCompletion: React.FC<IClanBaseCompletionProps> = ({ completion = 0, onClick }) => {
  return (
    <DivTooltip
      tooltipBody={
        <>
          <TooltipHeader className={styles.tooltipHeader}>{t('Уровень развития базы')}</TooltipHeader>
          <TooltipBody>{t('Военная база завершена на %(count)s%', { count: completion })}</TooltipBody>
          <TooltipBody withMouseClick>
            <div className={styles.tooltipFooter}>{t('Show all active bonuses')}</div>
          </TooltipBody>
        </>
      }
      onClick={onClick}
    >
      <div
        className={classNames(styles.baseCompletion, {
          [styles.highCompletion]: completion > 80,
        })}
      >
        {completion}%
        <span className={styles.baseCompletionIcon} />
      </div>
    </DivTooltip>
  );
};

export default React.memo(ClanBaseCompletion);
