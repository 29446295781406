import classNames from 'classnames';
import * as React from 'react';

import { toShipLevel } from '~/helpers/formatting';

import styles from './BattleTeamsList.scss';

type PropsType = {
  userTeam: any;
  enemyTeam: any;
  isShow: boolean;
};

const BattleTeamsList: React.FC<PropsType> = ({ isShow, userTeam, enemyTeam }) => {
  const renderShip = (icons, survived = false) => {
    if (!icons) {
      return null;
    }

    const icon = survived ? icons.alive : icons.dead;
    return <img className={styles.shipImage} src={icon} alt="" />;
  };

  const renderTeam = (players, isEnemy = false) => {
    const teamList = players.map((player, index) => {
      let shipName;
      let shipLevel;
      let shipIcon;
      if (player.ship) {
        shipName = player.ship.name;
        shipLevel = toShipLevel(player.ship.level);
        shipIcon = renderShip(player.ship.icons, player.survived);
      } else {
        shipName = shipLevel = shipIcon = '';
      }
      return (
        <div
          key={index}
          className={classNames(styles.player, {
            [styles.isSurvived]: player.survived,
          })}
        >
          <div className={styles.nickname}>{player.nickname}</div>
          {shipIcon}
          <div>{shipLevel}</div>
          <div className={styles.shipName}>{shipName}</div>
        </div>
      );
    });

    return <div className={classNames(styles.team, { [styles.enemyTeam]: isEnemy })}>{teamList}</div>;
  };
  return (
    <div className={classNames(styles.container, { [styles.isShow]: isShow })}>
      {renderTeam(userTeam.players)}
      {renderTeam(enemyTeam.players, true)}
    </div>
  );
};

export default BattleTeamsList;
