import styles from '~/stylesheets/core/_settings.animation.scss';

const parseCSSTime = (time: string) => {
  if (!time) {
    return 0;
  }

  if (time.indexOf('ms') >= 1) {
    return parseInt(time, 10);
  }

  if (time.indexOf('s') >= 1) {
    return parseInt(time, 10) / 1000;
  }

  return 0;
};

export const renameSpinnerAnimationEnterDuration =
  parseCSSTime(styles.renameSpinnerAnimationDuration) + parseCSSTime(styles.renameSpinnerAnimationDelay);

export const renameSpinnerAnimationLeaveDuration = parseCSSTime(styles.renameSpinnerAnimationDuration);
