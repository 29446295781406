import classNames from 'classnames';
import React from 'react';
import { translate } from 'react-i18next';
import { ButtonIcon } from '@wg/wows-react-uikit';

import { isIngame } from '~/commonmenu';
import { DIALOGS_NAMES, DIALOGS_THEMES } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { showClansInformerDialog } from '~/helpers/dialogs';
import { t } from '~/helpers/localization';
import { useAppDispatch } from '~/store';
import { showDialog } from '~/store/slices/dialogsSlice';

import { TooltipBody } from '~/UIKit/components';

import styles from './ClansInformerButton.scss';

export type IProps = {
  routePath: string;
  clanId: number;
  source: string;
};

const ClansInformerButton = ({ routePath, clanId, source }: IProps) => {
  const dispatch = useAppDispatch();

  const inGame = isIngame();

  const tooltip = React.useMemo(() => {
    return <TooltipBody>{t('Clan info')}</TooltipBody>;
  }, []);

  const onClick = React.useCallback(() => {
    dwhExport.push(DWH_EVENTS.SUPPLY.CLANINFO_CLICK);
    dispatch(showClansInformerDialog(clanId, source));
  }, [clanId, dispatch, source]);

  if (!(routePath.includes('profile') || routePath.includes('members'))) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.inGame]: inGame,
      })}
    >
      <ButtonIcon isFlat type={'info'} onClick={onClick} tooltipContent={tooltip} />
    </div>
  );
};

export default translate()(ClansInformerButton);
