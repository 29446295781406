import { clanstarsSeasonVersion } from '~/helpers/common';
import { getValueFromLocalStorage } from '~/helpers/localStorage';

// @TODO: refactor battle types
export enum BATTLE_TYPES {
  BRAWL_CVC = 'brawl',
  CLUB = 'club',
  COOPERATIVE = 'pve',
  PVP = 'pvp',
  REGULAR_CVC = 'cvc',
  SOLO_RANK = 'rank_solo',
}

export enum CLIENT_BATTLE_TYPES {
  BRAWL = 'brawl',
  CLAN = 'clan',
  COOPERATIVE = 'cooperative',
  EVENT = 'event',
  PVE = 'pve',
  PVE_PREMADE = 'pve_premade',
  PVP = 'pvp',
  RANKED = 'ranked',
}

export const SEARCHABLE_BATTLE_TYPES = [
  BATTLE_TYPES.COOPERATIVE,
  BATTLE_TYPES.PVP,
  BATTLE_TYPES.SOLO_RANK,
  BATTLE_TYPES.REGULAR_CVC,
];

export enum CLANSTARS_QUEST_TYPES {
  WIN_BATTLE = 'win_battle',
  EARN_ACHIEVEMENT = 'earn_achievement',
}

export enum CLANSTARS_REWARD_STATUSES {
  NEW = 'New',
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
}

export const isInGame = window.navigator.userAgent.includes('WOWS/1');

export const LANGUAGES = {
  az: 'Azərbaycanca',
  be: 'Беларуская',
  bg: 'Български',
  bs: 'Bosanski',
  cs: 'Čeština',
  da: 'Dansk',
  de: 'Deutsch',
  el: 'Ελληνικά',
  en: 'English',
  'en-us': 'English (US)',
  es: 'Español',
  'es-mx': 'Español (México)',
  et: 'Eesti',
  fi: 'Suomi',
  fil: 'Filipino',
  fr: 'Français',
  hr: 'Hrvatski',
  hu: 'Magyar',
  hy: 'Հայերեն',
  id: 'Indonesia',
  it: 'Italiano',
  ja: '日本語',
  ka: 'ქართული',
  kk: 'Қазақша',
  ko: '한국어',
  ky: 'Кыргызча',
  lt: 'Lietuvių',
  lv: 'Latviešu',
  mol: 'Moldovenească',
  ms: 'Bahasa Melayu',
  nl: 'Nederlands',
  no: 'Norsk',
  pl: 'Polski',
  pt: 'Português',
  'pt-br': 'Português (Brasil)',
  ro: 'Română',
  ru: 'Русский',
  sk: 'Slovenčina',
  sq: 'Shqip',
  sr: 'Српски',
  sv: 'Svenska',
  tg: 'Тоҷикӣ',
  th: 'ไทย',
  tk: 'Türkmençe',
  tr: 'Türkçe',
  uk: 'Українська',
  uz: 'Oʻzbekcha',
  vi: 'Tiếng Việt',
  'zh-cn': '简体中文',
  'zh-tw': '繁體中文',
};

export enum SEASON_TYPES {
  REGULAR = 'regular',
  BRAWL = 'brawl',
}

export const TYPE_GLOBAL_SEARCH = 'global';

export const sawClanStars: Record<string, boolean> = getValueFromLocalStorage(`sawClanStars_${clanstarsSeasonVersion}`);

export const SUPER_SHIP_ICON = '★';

export enum REQUESTS_TABS {
  APPLICATION = 'applications',
  INVITES = 'invites',
}

export enum CLANSTARS_TABS {
  DIVISION_STAR = 'division_star',
  RESULTS = 'results',
  RULES = 'rules',
}

export enum TREASURY_TABS {
  EXPENSES = 'expenses',
  INCOME = 'income',
  DISTRIBUTION = 'distribution',
  REWARDS = 'regularRewards',
}

export enum SOCIAL_NETWORKS {
  DISCORD = 'discord',
}

export const SOCIAL_NETWORKS_LINKS_VALIDATION = {
  [SOCIAL_NETWORKS.DISCORD]: /^(https?:\/\/)?(discord(\.gg|((\.com|app\.com)\/invite))\/)([a-zA-Z0-9-]{2,100}\/?)$/i,
};

export const SOCIAL_NETWORKS_LINKS_FORMATS = {
  [SOCIAL_NETWORKS.DISCORD]: 'https://discord.gg/',
};

export enum DIALOGS_THEMES {
  DEFAULT = 'default',
  BLUE = 'blue',
}

export enum DIALOGS_NAMES {
  CLANS_INFORMER_DIALOG = 'CLANS_INFORMER_DIALOG',
  CLAN_INFO_DIALOG = 'CLAN_INFO_DIALOG',
  CLAN_LEAVE_DIALOG = 'CLAN_LEAVE_DIALOG',
  CLAN_NAME_EDIT_DIALOG = 'CLAN_NAME_EDIT_DIALOG',
  CSTARS_REWARDS_DIALOG = 'CSTARS_REWARDS_DIALOG',
  CSTARS_REFINED_INFO_DIALOG = 'CSTARS_REFINED_INFO_DIALOG',
  CWARS_ATTEMPTS_DIALOG = 'CWARS_ATTEMPTS_DIALOG',
  CWARS_CALENDAR_DIALOG = 'CWARS_CALENDAR_DIALOG',
  CWARS_HISTORY_DIALOG = 'CWARS_HISTORY_DIALOG',
  CWARS_STATS_DIALOG = 'CWARS_STATS_DIALOG',
  DISTRIBUTION_DIALOG = 'DISTRIBUTION_DIALOG',
  DISTRIBUTION_RESULT_DIALOG = 'DISTRIBUTION_RESULT_DIALOG',
  DISTRIBUTION_REWARD_CONFIRM_DIALOG = 'DISTRIBUTION_REWARD_CONFIRM_DIALOG',
  EXTERNAL_NAVIGATION_DIALOG = 'EXTERNAL_NAVIGATION_DIALOG',
  INVITE_ACCEPT_DIALOG = 'INVITE_ACCEPT_DIALOG',
  INVITE_CANCEL_DIALOG = 'INVITE_CANCEL_DIALOG',
  LANGUAGES_DIALOG = 'LANGUAGES_DIALOG',
  MEMBERS_OPERATION_CHANGE_COMMANDER = 'MEMBERS_OPERATION_CHANGE_COMMANDER',
  MEMBERS_OPERATION_CHANGE_ROLE = 'MEMBERS_OPERATION_CHANGE_ROLE',
  MEMBERS_OPERATION_REMOVE = 'MEMBERS_OPERATION_REMOVE',
  MONUMENT_ACHIEVEMENTS_DIALOG = 'MONUMENT_ACHIEVEMENTS_DIALOG',
  RECOMMENDATIONS_CLAN_SETTINGS_DIALOG = 'RECOMMENDATIONS_CLAN_SETTINGS_DIALOG',
  RULES_DIALOG = 'RULES_DIALOG',
  SEND_APPLICATION_DIALOG = 'SEND_APPLICATION_DIALOG',
}

export enum CWARS_RESTRICTIONS {
  ACCOUNT_MISSED_PREPARATION = 'ACCOUNT_MISSED_PREPARATION',
  ACCOUNT_NOT_IN_CLAN = 'ACCOUNT_NOT_IN_CLAN',
  CLAN_DOES_NOT_PARTICIPATE = 'CLAN_DOES_NOT_PARTICIPATE',
  CLAN_FAILED_PREPARATION = 'CLAN_FAILED_PREPARATION',
  CLAN_MISSED_PREPARATION = 'CLAN_MISSED_PREPARATION',
}

export enum VORTEX_ENDPOINT {
  glossary = '/api/graphql/glossary/',
  version = '/api/v2/graphql/glossary/version/',
}

export enum PERMISSIONS {
  INVITE_PLAYER = 'invite_players',
  MANAGE_APPLICATIONS = 'manage_applications',
  CHANGE_ROLES = 'change_roles',
  REMOVE_MEMBERS = 'remove_members',
  DISBAND_CLAN = 'disband_clan',
  MODIFY_CLAN = 'modify_clan',
  DIVIDE_PROFITS = 'divide_profits',
  EXCHANGE_MONEY = 'exchange_money',
  MODIFY_RECRUITING_RESTRICTIONS = 'modify_recruiting_restrictions',
  READ_MESSAGES = 'read_messages',
  CREATE_MESSAGES = 'create_messages',
  EDIT_MESSAGES = 'edit_messages',
  BUILDER = 'builder',
}

export enum ACCOUNT_BAN_TYPES {
  CHAT_DENIED = 'chat_denied',
}
