import debounce from 'lodash/debounce';
import * as React from 'react';

import settings from '~/settings';

type PropsType = {
  isFetching: boolean;
  syncAccountInfo: () => Promise<any>;
  waitForClanCreation: () => void;
};

class AccountInfoSyncManager extends React.Component<PropsType> {
  private syncAccountInfoDebounce = debounce(
    this.syncAccountInfo.bind(this),
    settings.ajaxTools.accountInfoSyncTimeout,
  );

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.waitForClanCreation();
    this.syncAccountInfoDebounce();
  }

  syncAccountInfo() {
    if (this.props.isFetching) {
      this.syncAccountInfoDebounce();
    } else {
      this.props.syncAccountInfo().then(this.syncAccountInfoDebounce).catch(this.syncAccountInfoDebounce);
    }
  }

  render() {
    return null;
  }
}

export default AccountInfoSyncManager;
