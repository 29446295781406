import { Tuple, type Middleware } from '@reduxjs/toolkit';
import Raven from 'raven-js';
import browserHistory from 'react-router/lib/browserHistory';
import { routerMiddleware } from 'react-router-redux';

import settings from '~/settings';

import ravenMiddleware from './RavenMiddleware';
import prevPathMiddleware from './prevPathMiddleware';
// import { createLogger } from 'redux-logger';

// @TODO: migrate to modern router
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const reactRouterMiddleware = routerMiddleware(browserHistory) as Middleware;

let middlewares: Tuple<Middleware[]> = new Tuple(reactRouterMiddleware, prevPathMiddleware);

// if (process.env.NODE_ENV !== 'production') {
//   middlewares.push(createLogger());
// }

// if (process.env.NODE_ENV !== 'production') {
//   const { whyDidYouUpdate } = require('why-did-you-update')
//   whyDidYouUpdate(React)
//   whyDidYouUpdate(React, { include: [/^pure/], exclude: [/^Connect/] })
// }

if (settings.sentryUrl) {
  //  Untyped dependency
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  Raven.config(settings.sentryUrl).install();
  middlewares = middlewares.concat(ravenMiddleware);
}

export default middlewares;
