import './UpdatingLayer.scss';

import React, { Component } from 'react';
import { TileLayer as TileLayerDefault } from 'react-leaflet';

import TileLayer from '../../TileLayer/TileLayer';

export type IProps = {
  url: string;
  className: any;
  contour: any;
  hasDifferentLayers: boolean;
};
export type IState = {
  layers: Array<IProps>;
  layer?: IProps;
};

class UpdatingLayer extends Component<IProps, IState> {
  nextLayer = null;

  constructor(props: IProps) {
    super(props);
    this.state = {
      layers: [],
      layer: null,
    };
  }

  componentDidMount() {
    this.setState({ layers: [this.props], layer: this.props });
  }

  shouldComponentUpdate(nextProps: IProps) {
    if (nextProps.url !== this.props.url) {
      const newLayers = this.state.layers.slice(0);
      newLayers.push(nextProps);
      this.setState({ layers: newLayers, layer: nextProps });
      return true;
    }
    return true;
  }

  render() {
    if (this.state.layers.length === 0) {
      return null;
    }

    if (this.props.hasDifferentLayers) {
      const layer = this.state.layer;
      const index = 0;

      return (
        <TileLayerDefault
          {...layer}
          key={`Layer_${index}`}
          className={`PreviewLayer PreviewLayer_${index} ${layer.className ? layer.className : ''}`}
          contour={this.props.contour}
          isSelected={true}
          ref={(ref) => {
            if (index === this.state.layers.length - 1 && ref) {
              ref.leafletElement.on('load', () => {
                const domLayer = document.querySelector(`.PreviewLayer_${index - 1}`) as HTMLElement;
                if (domLayer) {
                  setTimeout(() => {
                    domLayer.style.opacity = '0';
                  }, 150);
                }
              });
            }
          }}
        />
      );
    } else {
      return this.state.layers.map((layer, index): React.ReactElement => {
        if (index === this.state.layers.length - 1 || index === this.state.layers.length - 2) {
          return (
            <TileLayer
              {...layer}
              key={`Layer_${index}`}
              className={`PreviewLayer PreviewLayer_${index} ${layer.className ? layer.className : ''}`}
              contour={this.props.contour}
              isSelected={true}
              ref={(ref) => {
                if (index === this.state.layers.length - 1 && ref) {
                  const domLayer = document.querySelector(`.PreviewLayer_${index - 1}`);
                  if (domLayer) {
                    setTimeout(() => {
                      domLayer.style.opacity = '0';
                    }, 200);
                  }
                }
              }}
            />
          );
        } else {
          return null;
        }
      });
    }
  }
}

export default UpdatingLayer;
