import { DIALOGS_NAMES, DIALOGS_THEMES } from '~/constants';
import { showDialog, hideDialog } from '~/store/slices/dialogsSlice';

export { closeAllDialogs } from '~/store/slices/dialogsSlice';

import type { Action } from '@reduxjs/toolkit';
import type { ClanInfoType } from '~/Actions/ActionClanProfile';

import type { Invite } from '~/Actions/ActionInvites';
import type { ClanRecommendation } from '~/Actions/ActionRecommendations';
import type { IClanStarsReward } from '~/Reducers/ReducerClanStars';
import type { RegularReward as IRegularReward } from '~/Reducers/ReducerTreasury';
import type { CLAN_INFO_TABS } from '~/UIKit/ClanInfoDialog/ClanInfoDialog';
import type { IBattle, IClanMember, Transaction } from '~/types/declaration';

export const showSendApplicationDialog = (
  clan: ClanInfoType | ClanRecommendation,
  sourceName: string,
  shouldFetchApplications: boolean = false,
  onSend: ((info: string) => void) | undefined = undefined,
): Action =>
  showDialog({
    name: DIALOGS_NAMES.SEND_APPLICATION_DIALOG,
    data: {
      clan: clan,
      source: sourceName,
      shouldFetchApplications: shouldFetchApplications,
      onSend: onSend,
    },
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'small',
    },
  });

export const showInviteAcceptDialog = (invite: Invite | { id: number; clan: ClanInfoType }): Action =>
  showDialog({
    name: DIALOGS_NAMES.INVITE_ACCEPT_DIALOG,
    data: {
      invite: invite,
    },
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'small',
    },
  });

export const showInviteCancelDialog = (invite: Invite): Action =>
  showDialog({
    name: DIALOGS_NAMES.INVITE_CANCEL_DIALOG,
    data: {
      invite: invite,
    },
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'small',
    },
  });

export const showMonumentAchievementsDialog = (clanId: number): Action =>
  showDialog({
    name: DIALOGS_NAMES.MONUMENT_ACHIEVEMENTS_DIALOG,
    data: {
      clanId: clanId,
    },
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'overlay',
      isOverlay: true,
    },
  });

export const showMembersOperationRemoveDialog = (membersToRemove: IClanMember[]): Action =>
  showDialog({
    name: DIALOGS_NAMES.MEMBERS_OPERATION_REMOVE,
    data: {
      membersToRemove: membersToRemove,
    },
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'small',
    },
  });

export const showMembersOperationChangeCommanderDialog = (newCommander: IClanMember): Action =>
  showDialog({
    name: DIALOGS_NAMES.MEMBERS_OPERATION_CHANGE_COMMANDER,
    data: {
      newCommander: newCommander,
    },
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'small',
    },
  });

export const showMembersOperationChangeRoleDialog = (memberToChangeId: number): Action =>
  showDialog({
    name: DIALOGS_NAMES.MEMBERS_OPERATION_CHANGE_ROLE,
    data: {
      memberToChangeId: memberToChangeId,
    },
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'small',
    },
  });

export const showRulesDialog = (): Action =>
  showDialog({
    name: DIALOGS_NAMES.RULES_DIALOG,
    data: {},
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'large',
    },
  });

export const showClanLeaveDialog = (): Action =>
  showDialog({
    name: DIALOGS_NAMES.CLAN_LEAVE_DIALOG,
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'small',
    },
  });

export const showRecommendationsClanSettingsDialog = (): Action =>
  showDialog({
    name: DIALOGS_NAMES.RECOMMENDATIONS_CLAN_SETTINGS_DIALOG,
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'large',
    },
  });

export const showNameEditDialog = (): Action =>
  showDialog({
    name: DIALOGS_NAMES.CLAN_NAME_EDIT_DIALOG,
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'large',
    },
  });

export const showDistributionDialog = (): Action =>
  showDialog({
    name: DIALOGS_NAMES.DISTRIBUTION_DIALOG,
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'small',
    },
  });

export const showDistributionResultDialog = (transaction: Transaction): Action =>
  showDialog({
    name: DIALOGS_NAMES.DISTRIBUTION_RESULT_DIALOG,
    data: {
      transaction,
    },
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'small',
    },
  });

export const hideDistributionResultDialog = (): Action => {
  return hideDialog([DIALOGS_NAMES.DISTRIBUTION_RESULT_DIALOG]);
};

export const showRegularRewardConfirmDialog = (reward: IRegularReward): Action =>
  showDialog({
    name: DIALOGS_NAMES.DISTRIBUTION_REWARD_CONFIRM_DIALOG,
    data: {
      reward,
    },
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'small',
    },
  });

export const showCWarsCalendarDialog = (): Action =>
  showDialog({
    name: DIALOGS_NAMES.CWARS_CALENDAR_DIALOG,
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'extra-large',
    },
  });

export const showCWarsStatsDialog = (...[nation, type]: string[] | undefined[]): Action =>
  showDialog({
    name: DIALOGS_NAMES.CWARS_STATS_DIALOG,
    data:
      nation && type
        ? {
            selectedStatisticNation: nation,
            selectedStatisticType: type,
          }
        : undefined,
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'extra-large',
    },
  });

export const showCWarsAttemptsDialog = (selectedAttemptsUserId: number, battles: IBattle[]): Action => {
  const userBattles = battles.some((battle) => {
    return battle.spaId === selectedAttemptsUserId;
  });

  return showDialog({
    name: DIALOGS_NAMES.CWARS_ATTEMPTS_DIALOG,
    data: {
      selectedAttemptsUserId: selectedAttemptsUserId,
    },
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: userBattles ? 'extra-large' : 'small',
    },
  });
};

export const showClansInformerDialog = (clanId: number, source: string): Action =>
  showDialog({
    name: DIALOGS_NAMES.CLANS_INFORMER_DIALOG,
    data: {
      clanId: clanId,
      source: source,
    },
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'fullscreen',
    },
  });

export const showExternalNavigationDialog = (link: string): Action => {
  return showDialog({
    name: DIALOGS_NAMES.EXTERNAL_NAVIGATION_DIALOG,
    data: {
      externalLink: link,
    },
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'large',
    },
  });
};

type IShowClanInfoDialog = {
  clanId: number;
  initialTab?: CLAN_INFO_TABS;
  source?: string;
  isInEditMode?: boolean;
  isOpenedFromClanBase?: boolean;
};
export const showClanInfoDialog = ({
  clanId,
  initialTab,
  isInEditMode = false,
  isOpenedFromClanBase = false,
  source = undefined,
}: IShowClanInfoDialog): Action => {
  return showDialog({
    name: DIALOGS_NAMES.CLAN_INFO_DIALOG,
    data: {
      clanId,
      initialTab,
      isInEditMode,
      isOpenedFromClanBase,
      source,
    },
    options: {
      theme: DIALOGS_THEMES.BLUE,
    },
  });
};

export const showCStarsRewardsDialog = (newRewards: IClanStarsReward[]): Action =>
  showDialog({
    name: DIALOGS_NAMES.CSTARS_REWARDS_DIALOG,
    data: {
      newRewards,
    },
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'worship',
    },
  });

export const showCStarsRefinedInfoDialog = (): Action =>
  showDialog({
    name: DIALOGS_NAMES.CSTARS_REFINED_INFO_DIALOG,
    options: {
      theme: DIALOGS_THEMES.BLUE,
    },
  });

export const showClanRecLanguagesDialog = (
  languagesMap: Record<string, boolean>,
  updateLanguages: (languages: string[]) => void,
): Action =>
  showDialog({
    name: DIALOGS_NAMES.LANGUAGES_DIALOG,
    data: {
      languagesMap,
      updateLanguages,
    },
    options: {
      theme: DIALOGS_THEMES.BLUE,
      size: 'worship',
    },
  });
