import isFunction from 'lodash/isFunction';

import { KEYS_CODE } from '~/hooks/useKeyDown';

import { closeBrowser } from '~/web2ClientAPI/web2ClientAPI';

/**
 * Clear searchHistory with old structure before store create
 */
export const checkReduxStorage = () => {
  const reduxStorageItem = localStorage.getItem('redux');
  if (!reduxStorageItem) {
    return;
  }

  type IReduxStorageOldStructure = {
    searchHistory?: {
      items?: unknown;
    };
  };
  const reduxStorage = JSON.parse(reduxStorageItem) as IReduxStorageOldStructure;

  if (reduxStorage?.searchHistory?.items) {
    localStorage.clear();
  }
};

export const initEventListeners = () => {
  const TAB_KEYCODE = 9;
  let lastKeyCode: number;

  document.body.addEventListener(
    'keydown',
    (e: KeyboardEvent) => {
      // work around in-game browser sending two keydown events for each keypress,
      // one with invalid keycode
      // @TODO: check if it is fixed in client and refactor
      if (e.keyCode === 0 && lastKeyCode === TAB_KEYCODE) {
        e.preventDefault();
      } else {
        lastKeyCode = e.keyCode;
      }
    },
    true,
  );

  document.addEventListener('keydown', (e: KeyboardEvent) => {
    if (e.key === (KEYS_CODE.ESC as string) && !e.defaultPrevented) {
      closeBrowser();
    }
  });

  document.body.onselectstart = (event) => {
    const target = event.target as HTMLInputElement;
    if (!target) {
      return false;
    }

    return ['INPUT', 'TEXTAREA'].includes(target.nodeName) && ['text', 'textarea'].includes(target.type);
  };
};

export const isBrowserSupportsFeatures = () => {
  return isFunction(window.Promise) && isFunction(window.matchMedia);
};
