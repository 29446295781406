const CLIENT_OFFSET = 10;

const defaultConstants = {
  CURSOR_HEIGHT: 0,
  CURSOR_WIDTH: 0,
  ELEMENT_OFFSET_Y: 0,
  ELEMENT_OFFSET_X: 0,
};

export const getPositionOnViewport = (
  element: HTMLElement,
  cursorPosition: { top: number; left: number },
  constants = defaultConstants,
) => {
  const elementHeight = element.offsetHeight;
  const elementWidth = element.offsetWidth;

  const clientHeight = document.body.clientHeight - CLIENT_OFFSET;
  const clientWidth = document.body.clientWidth - CLIENT_OFFSET;

  const fitsTop = cursorPosition.top - elementHeight - constants.ELEMENT_OFFSET_Y >= 0;
  const fitsBottom =
    cursorPosition.top + constants.ELEMENT_OFFSET_Y + constants.CURSOR_HEIGHT + elementHeight <= clientHeight;

  let top: number;
  let left: number;

  if (fitsTop || fitsBottom) {
    if (!fitsBottom) {
      top = cursorPosition.top - elementHeight - constants.ELEMENT_OFFSET_Y;
    } else {
      top = cursorPosition.top + constants.ELEMENT_OFFSET_Y + constants.CURSOR_HEIGHT;
    }
    const fitsRight =
      cursorPosition.left + constants.ELEMENT_OFFSET_X + constants.CURSOR_WIDTH / 2 + elementWidth <= clientWidth;
    if (!fitsRight) {
      left = cursorPosition.left - elementWidth - constants.ELEMENT_OFFSET_X;
    } else {
      left = cursorPosition.left + constants.ELEMENT_OFFSET_X + constants.CURSOR_WIDTH / 2;
    }
  } else {
    const fitsRight =
      cursorPosition.left + constants.ELEMENT_OFFSET_X + constants.CURSOR_WIDTH + elementWidth <= clientWidth;
    top = cursorPosition.top - elementHeight / 2 + constants.CURSOR_HEIGHT / 2;
    if (!fitsRight) {
      left = cursorPosition.left - elementWidth - constants.ELEMENT_OFFSET_X;
    } else {
      left = cursorPosition.left + constants.ELEMENT_OFFSET_X + constants.CURSOR_WIDTH;
    }
  }

  return {
    left,
    top,
  };
};

export const getElementOffsetTop = (el: HTMLElement) => {
  let offsetTop = el.offsetTop;
  while (el.offsetParent) {
    el = el.offsetParent as HTMLElement;
    offsetTop += el.offsetTop;
  }
  return offsetTop;
};
