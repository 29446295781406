import get from 'lodash/get';
import sampleSize from 'lodash/sampleSize';
import moment from 'moment';
import React, { Fragment, PureComponent } from 'react';
import { Interpolate } from 'react-i18next';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import { ButtonPromo, ButtonSwitch, Currency, Divider, Link, StepCSSTransitionGroup } from '@wg/wows-react-uikit';

import settings from '~/settings';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { getCompetitionMetricPoints } from '~/helpers/cwars';
import { isoToFormattedLocalDateTime } from '~/helpers/datetime';
import { t, x } from '~/helpers/localization';
import { log } from '~/helpers/logging';

import { COMPETITION_METRIC } from '~/UIKit';

import styles from './RulesTab.scss';
import Calendar from '../../Calendar/Calendar';
import ProgressBar from '../../ProgressBar/ProgressBar';
import MyWarTable from '../MyWarTab/MyWarTable/MyWarTable';

import type { CurrencyType } from '@wg/wows-react-uikit/components/Currency/Currency';
import type { IDictionary, IStages, IWarSettings, WarModifiers } from '~/types/declaration';

export type RulesTab_Props = {
  stages: IStages;
  warSettings: IWarSettings;
  clanId?: number;

  navigateToRecommendations: () => void;
};
export type RulesTab_State = {
  progressPersonalProgress: number;
  progressClanProgress: number;
  progressMaxClanProgress: number;
  progressMaxPersonalProgress: number;
  additionalAttemptsForProgress: IDictionary<number>;
  modifiers: WarModifiers;
  useAttempts: boolean;
  isFullRules: boolean;
};

const clanProgressMilestones = [
  {
    reward: {
      title: 'string',
      type: 'oil',
      description: 'description',
      imageUrl: null,
      amount: 50,
    },
    value: 10000,
  },
  {
    reward: {
      title: 'string',
      type: 'oil',
      description: 'description',
      imageUrl: null,
      amount: 150,
    },
    value: 25000,
  },
  {
    reward: {
      title: 'string',
      type: 'oil',
      description: 'description',
      imageUrl: null,
      amount: 250,
    },
    value: 50000,
  },
  {
    reward: {
      title: 'string',
      type: 'oil',
      description: 'description',
      imageUrl: null,
      amount: 350,
    },
    value: 100000,
  },
];

const additionalAttemptsForProgress = {
  25: 1,
  50: 2,
  100: 4,
};

const maxClanReward = 800;

class RulesTab extends PureComponent<RulesTab_Props, RulesTab_State> {
  state = {
    progressPersonalProgress: 0,
    progressClanProgress: 0,
    progressMaxClanProgress: 100000,
    progressMaxPersonalProgress: 40000,
    additionalAttemptsForProgress: {},
    modifiers: [],
    useAttempts: false,
    isFullRules: false,
  };

  _isMounted = false;

  showFullRules = () => {
    void playButtonClickSound();

    let modifiers: WarModifiers = [];
    if (settings.cwars.features.bonusCells) {
      modifiers = sampleSize(this.generateModifiers(), 5);
    }

    this.setState({
      modifiers,
      isFullRules: true,
    });
  };

  animateProgress = () => {
    const newPersonal = Math.floor(this.state.progressPersonalProgress + this.state.progressMaxClanProgress / 135);
    const newClan = Math.floor(this.state.progressClanProgress + this.state.progressMaxClanProgress / 50);
    const newAdditionalAttempts = settings.cwars.features.additionalAttemptsPreparation
      ? additionalAttemptsForProgress
      : {};

    if (this._isMounted) {
      this.setState(
        {
          progressPersonalProgress: newPersonal,
          progressClanProgress: newClan,
          additionalAttemptsForProgress: newAdditionalAttempts,
        },
        () => {
          if (this.state.progressClanProgress < this.state.progressMaxClanProgress) {
            setTimeout(() => {
              this.animateProgress();
            }, 750);
          } else {
            setTimeout(() => {
              this.setState(
                {
                  progressPersonalProgress: 0,
                  progressClanProgress: 0,
                },
                this.animateProgress,
              );
            }, 3500);
          }
        },
      );
    }
  };

  setUseAttempts = () => {
    if (this._isMounted) {
      this.setState(
        {
          useAttempts: !this.state.useAttempts,
        },
        () => {
          setTimeout(() => {
            this.setUseAttempts();
          }, 3000);
        },
      );
    }
  };

  componentDidMount() {
    this._isMounted = true;
    dwhExport.push(DWH_EVENTS.NAVAL_BATTLES.VIEW_RULES);
    log('naval_battles.view_rules');
    this.animateProgress();
    this.setUseAttempts();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getPreparationRewardSumm = () => {
    const milestones = get(this.props.warSettings, 'stages.preparation.clanProgressMilestones', null);
    let sum = 0;
    if (milestones) {
      milestones.forEach((milestone) => {
        const amount = milestone.reward && milestone.reward.amount ? milestone.reward.amount : 0;
        sum += amount;
      });
    }

    return sum;
  };

  getProgressTarget = (maxClanProgress: number) => {
    switch (this.props.warSettings.stages.preparation.competitionMetric) {
      case COMPETITION_METRIC.EXP: {
        return (
          <Interpolate
            i18nKey={x('Заработайте %(maxClanProgress)s чистого опыта.')}
            maxClanProgress={maxClanProgress}
            t={t}
          />
        );
      }

      case COMPETITION_METRIC.DAMAGE: {
        return (
          <Interpolate i18nKey={x('Нанесите %(maxClanProgress)s урона.')} maxClanProgress={maxClanProgress} t={t} />
        );
      }

      case COMPETITION_METRIC.RIBBONS: {
        return (
          <Interpolate i18nKey={x('Получите %(maxClanProgress)s лент.')} maxClanProgress={maxClanProgress} t={t} />
        );
      }

      default: {
        return (
          <Interpolate
            i18nKey={x('Заработайте %(maxClanProgress)s чистого опыта.')}
            maxClanProgress={maxClanProgress}
            t={t}
          />
        );
      }
    }
  };

  getFullBattleDescrText = () => {
    const description = t(
      'Чтобы получить право сразиться с другими кланами, ваш клан должен выполнить боевую задачу. Условия задачи зависят от общей численности клана, а выполнить её можно в первые дни Морского сражения. Максимальный вклад каждого игрока ограничен. Чем больше в вашем клане активных игроков, тем проще выполнить общую боевую задачу.',
    );
    return (
      description +
      (settings.cwars.features.additionalAttemptsPreparation
        ? ' ' +
          t(
            'На этапе Подготовки каждый игрок, внёсший вклад в выполнение общей задачи, может увеличить количество попыток, которые используются для этапа Битвы.',
          )
        : '')
    );
  };

  getFullStartBattleDescrText = () => {
    switch (this.props.warSettings.stages.preparation.competitionMetric) {
      case COMPETITION_METRIC.EXP: {
        return t(
          'С началом Битвы игроки клана получат доступ к специальной таблице, каждая ячейка которой является отдельной категорией, представляющей ветку исследуемых кораблей. Для каждой ветки кораблей доступна цепочка боевых задач, выполнять которую можно на исследуемых и премиумных кораблях. Условие для всех задач одинаковое: заработать определённое количество чистого опыта в случайных, кооперативных и ранговых боях. Награда — звезда для вашего клана.',
        );
      }

      case COMPETITION_METRIC.DAMAGE: {
        return t(
          'С началом Битвы игроки клана получат доступ к специальной таблице, каждая ячейка которой является отдельной категорией, представляющей ветку исследуемых кораблей. Для каждой ветки кораблей доступна цепочка боевых задач, выполнять которую можно на исследуемых и премиумных кораблях. Условие для всех задач одинаковое: нанести определённое количество урона в случайных, кооперативных и ранговых боях. Награда — звезда для вашего клана.',
        );
      }

      case COMPETITION_METRIC.RIBBONS: {
        return t(
          'С началом Битвы игроки клана получат доступ к специальной таблице, каждая ячейка которой является отдельной категорией, представляющей ветку исследуемых кораблей. Для каждой ветки кораблей доступна цепочка боевых задач, выполнять которую можно на исследуемых и премиумных кораблях. Условие для всех задач одинаковое: получить определённое количество лент в случайных, кооперативных и ранговых боях. Награда — звезда для вашего клана.',
        );
      }

      default: {
        return '';
      }
    }
  };

  getFullDetailsText = () => {
    switch (this.props.warSettings.stages.preparation.competitionMetric) {
      case COMPETITION_METRIC.EXP: {
        return t(
          'Если игроки одного клана выйдут отрядом на кораблях одного класса и нации, результаты будут засчитываться в порядке возрастания. Например, в ячейке таблицы с советскими эсминцами стоит задача заработать 1000 единиц чистого опыта, а после усложнения задачи — 1100. Игроки сыграли в отряде из советских эсминцев «Удалой» и «Ташкент». Игрок на «Удалом» получил за бой 1000 единиц чистого опыта, а его товарищ — 1050. Игрок на «Удалом» принёс звезду своему клану и открыл доступ к усложненной задаче. Результат «Ташкента» ниже, чем требуется по условиям усложненной задачи, и получается, что он потратил попытку впустую. Однако, если бы он заработал 1100 чистого опыта или больше, то оба игрока принесли бы звезды в зачёт клана.',
        );
      }

      case COMPETITION_METRIC.DAMAGE: {
        return t(
          'Если игроки одного клана выйдут отрядом на кораблях одного класса и нации, результаты будут засчитываться в порядке возрастания. Например, в ячейке таблицы с советскими эсминцами стоит задача нанести 10000 урона, а после усложнения задачи — 20000. Игроки сыграли в отряде из советских эсминцев «Удалой» и «Ташкент». Игрок на «Удалом» нанёс за бой 10000 урона, а его товарищ — 15000. Игрок на «Удалом» принёс звезду своему клану и открыл доступ к усложненной задаче. Результат «Ташкента» ниже, чем требуется по условиям усложненной задачи, и получается, что он потратил попытку впустую. Однако, если бы он нанёс 20000 урона или больше, то оба игрока принесли бы звезды в зачёт клана.',
        );
      }

      case COMPETITION_METRIC.RIBBONS: {
        return t(
          'Если игроки одного клана выйдут отрядом на кораблях одного класса и нации, результаты будут засчитываться в порядке возрастания. Например, в ячейке таблицы с советскими эсминцами стоит задача получить 100 лент, а после усложнения задачи — 200. Игроки сыграли в отряде из советских эсминцев «Удалой» и «Ташкент». Игрок на «Удалом» получил за бой 100 лент, а его товарищ — 150. Игрок на «Удалом» принёс звезду своему клану и открыл доступ к усложненной задаче. Результат «Ташкента» ниже, чем требуется по условиям усложненной задачи, и получается, что он потратил попытку впустую. Однако, если бы он получил 200 лент или больше, то оба игрока принесли бы звезды в зачёт клана.',
        );
      }

      default: {
        return '';
      }
    }
  };

  generateModifiers = () => {
    const nations = this.props.warSettings.stages.war.nations;
    const classes = ['destroyer', 'battleship', 'cruiser', 'aircarrier'];

    const unavailable = {};
    this.props.warSettings.stages.war.unavailableCells.forEach((u) => {
      unavailable[u.shipClass + ':' + u.shipNation] = 1;
    });

    const modifiers = [];

    nations.forEach((nation) => {
      classes.forEach((klass) => {
        if (unavailable[klass + ':' + nation] === undefined) {
          modifiers.push({
            type: 'x2',
            class: klass,
            nation: nation,
            priority: 1,
          });
        }
      });
    });

    return modifiers;
  };

  getStageInterval = (stage) => {
    const startDate = this.props.warSettings.stages[stage].startDate;
    const endDate = this.props.warSettings.stages[stage].endDate;
    return {
      startDate: moment.utc(startDate),
      endDate: moment.utc(endDate),
      startDateComponent: (
        <strong className={styles.stageDate}>&nbsp;{isoToFormattedLocalDateTime(startDate)}&nbsp;</strong>
      ),
      endDateComponent: (
        <strong className={styles.stageDate}>&nbsp;{isoToFormattedLocalDateTime(endDate)}&nbsp;</strong>
      ),
    };
  };

  renderStageInterval = (stage, descriptionInPast, descriptionInPresent, descriptionInFuture) => {
    const interval = this.getStageInterval(stage);
    const now = moment.utc();

    const description =
      interval.endDate < now
        ? descriptionInPast
        : interval.startDate > now
          ? descriptionInFuture
          : descriptionInPresent;

    return (
      <Interpolate
        className={styles.listItem}
        parent={'li'}
        i18nKey={description}
        startDate={interval.startDateComponent}
        endDate={interval.endDateComponent}
        t={t}
      />
    );
  };

  renderFullRules = () => {
    let oilReward = this.getPreparationRewardSumm();
    if (oilReward === 0) {
      oilReward = 800;
    }

    return (
      <Fragment>
        <Divider type="major" />
        <StepCSSTransitionGroup level={1}>
          <div className={styles.fullRulesTitleWrapper}>
            <h1 className={styles.bigTitle}>{t('Расширенный вариант правил')}</h1>
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={1}>
          <div className={styles.paragraph}>
            <span className={styles.paragraphTitle}>{t('Период проведения')}</span>
            <p className={styles.paragraphText}>
              {t(
                'Морское сражение — это соревнование для кланов. Оно проходит каждую неделю и состоит из нескольких этапов.',
              )}
            </p>
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={2}>
          <div className={styles.demoComponent}>
            <Calendar
              currentWeek={2}
              weeksCount={3}
              currentStage={'war'}
              currentRound={1}
              stages={this.props.warSettings.stages}
            />
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={2}>
          <div className={styles.paragraph}>
            <p className={`${styles.paragraphText} ${styles.descriptionText}`}>
              {t(
                'В разделе «Морское сражение» есть календарь, в котором вы всегда можете увидеть расписание и текущий этап соревнования.',
              )}
            </p>
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={3}>
          <div className={styles.paragraph}>
            <span className={styles.paragraphTitle}>{t('Как принять участие?')}</span>
            <p className={styles.paragraphText}>
              {t(
                'Участвовать в Морском сражении могут только клановые игроки. Командующий кланом и заместители командующего могут зарегистрировать клан для участия в соревновании.',
              )}
            </p>
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={4}>
          <div className={styles.paragraph}>
            <span className={styles.paragraphTitle}>{t('Подготовка')}</span>
            <p className={styles.paragraphText}>{this.getFullBattleDescrText()}</p>
            <Interpolate
              className={styles.paragraphText}
              i18nKey={x(
                'За выполнение задачи клан получает не только допуск к Битве, но и до %(reward)s на счет клана.',
              )}
              reward={
                <Currency
                  isFlat
                  amount={maxClanReward}
                  type={clanProgressMilestones[clanProgressMilestones.length - 1].reward.type as CurrencyType}
                  isBold={true}
                />
              }
              t={t}
            />
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={5}>
          <div className={styles.demoComponent}>
            <ProgressBar
              title={t('Задача клана')}
              target={this.getProgressTarget(this.state.progressMaxClanProgress)}
              mPoints={getCompetitionMetricPoints(this.props.warSettings.stages.preparation.competitionMetric)}
              myValue={this.state.progressPersonalProgress}
              myValueMax={this.state.progressMaxPersonalProgress}
              totalValue={this.state.progressClanProgress}
              maxValue={this.state.progressMaxClanProgress}
              breakpoints={clanProgressMilestones}
              additionalAttemptsForProgress={this.state.additionalAttemptsForProgress}
              competitionMetric={'exp'}
            />
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={5}>
          <div className={styles.paragraph}>
            <p className={`${styles.paragraphText} ${styles.descriptionText}`}>
              {t(
                'Чтобы увидеть свой вклад, прогресс выполнения общей задачи и награды на этапе Подготовки, перейдите на вкладку «Подготовка» в разделе «Морское сражение».',
              )}
            </p>
          </div>
        </StepCSSTransitionGroup>

        <div className={styles.paragraph}>
          <span className={styles.paragraphTitle}>{t('Битва')}</span>
          <p className={styles.paragraphText}>
            {t(
              'Основной этап Морского сражения, который проходит в конце недели. Все кланы, которые выполнили подготовительную задачу, соревнуются 1 на 1. Для Битвы подбираются кланы со схожей активностью игроков. ',
            )}
          </p>
          <p className={styles.paragraphText}>{t('Задача вашего клана — заработать как можно больше звёзд.')}</p>
          <p className={styles.paragraphText}>{this.getFullStartBattleDescrText()}</p>
          {settings.cwars.features.bonusCells && (
            <p className={styles.paragraphText}>
              {t(
                'Участие в боях и выполнение боевых задач на кораблях определенных классов (они выделены в таблице) и наций может приносить дополнительные звёзды. При этом в таблице выделены одни и те же ячейки как вашего клана, так и клана противника.',
              )}
            </p>
          )}
        </div>
        <div className={styles.demoComponent}>
          <MyWarTable
            nations={this.props.warSettings.stages.war.nations}
            grades={this.props.warSettings.stages.war.grades}
            topGradesIncrement={this.props.warSettings.stages.war.topGradesIncrement}
            unavailableCells={this.props.warSettings.stages.war.unavailableCells}
            data={[]}
            onCellClick={() => {}}
            competitionMetric={this.props.warSettings.stages.preparation.competitionMetric}
            modifiers={this.state.modifiers}
            isEnemy={false}
            size="small"
          />
        </div>
        <div className={styles.paragraph}>
          <p className={styles.paragraphText}>
            {t('Для взятия планок у каждого игрока есть ограниченное количество попыток. ')}
          </p>
          {settings.cwars.features.additionalAttemptsPreparation && (
            <p className={styles.paragraphText}>
              {t(
                'На этапе Подготовки каждый игрок, внёсший вклад в выполнение общей задачи, может увеличить количество попыток, которые используются для этапа Битвы.',
              )}
            </p>
          )}
          {settings.cwars.features.additionalAttempts && (
            <p className={styles.paragraphText}>
              {t(
                'Во второй день Битвы появляется дополнительная попытка за каждые пять попыток, использованных в первый день. В третий день Битвы появляется дополнительная попытка за каждые четыре попытки, использованные в предыдущие дни. Таким образом, играя в течение всех дней Битвы, вы можете использовать больше попыток.',
              )}
            </p>
          )}
          <p className={styles.paragraphText}>
            {t(
              'Чтобы приступить к выполнению боевых задач, перейдите в раздел «Морское сражение» во вкладке клана и нажмите соответствующую кнопку.',
            )}
          </p>
        </div>

        <div className={styles.demoComponent}>
          <div className={styles.switchWrapper}>
            <ButtonSwitch
              value={this.state.useAttempts}
              onChange={() => {}}
            />
          </div>
        </div>

        <div className={styles.paragraph}>
          <p className={`${styles.paragraphText} ${styles.descriptionText}`}>
            {t(
              'Нажимая эту кнопку, игрок соглашается с участием в Битве, а его бои идут в зачёт и учитываются в общем результате клана.',
            )}
          </p>
        </div>

        <div className={styles.paragraph}>
          <p className={styles.paragraphText}>
            {t(
              'Если вы выполнили боевую задачу, то полученная звезда попадёт в актив вашего клана. После удачного выполнения боевая задача усложняется. За каждый бой, независимо от его результата, тратится попытка, а их число ограничено для каждого игрока.',
            )}
          </p>
          <p className={styles.paragraphText}>
            {t(
              ' Итогом Битвы клана может быть победа, ничья или поражение. При этом от результата зависит количество заработанной нефти и очков специального рейтинга, которые получит ваш клан.',
            )}
          </p>
          {settings.cwars.features.groupRounds && (
            <p className={styles.paragraphText}>
              <strong>
                {t(
                  'Каждую четвертую неделю кланы соревнуются по тем же правилам, но не 1 на 1, а за первенство в группе кланов.',
                )}
              </strong>
            </p>
          )}
        </div>

        <div className={styles.paragraph}>
          <span className={styles.paragraphTitle}>{t('Подведение итогов')}</span>
          <p className={styles.paragraphText}>
            {t(
              'После Битвы наступает этап Подведения итогов, во время которого определяется победитель Морского сражения и начисляются награды за этап Битвы. Во время подведения итогов попытки игроков не учитываются.',
            )}
          </p>
        </div>

        <div className={styles.paragraph}>
          <span className={styles.paragraphTitle}>
            {settings.cwars.features.groupRounds ? t('Награды в сражении кланов 1 на 1') : t('Награды')}
          </span>
          <p className={styles.paragraphText}>
            {t(
              'Нефть — основная награда Морского сражения, которую получат все участвующие кланы в зависимости от активности их участников.',
            )}
          </p>
          <ul className={styles.rewardList}>
            <li className={styles.rewardListItem}>
              <Interpolate
                i18nKey={x('За этап Подготовки клан получит до %(reward)s')}
                reward={<Currency isFlat amount={oilReward} type={'oil'} isBold={true} />}
                t={t}
              />
            </li>
            <li className={styles.rewardListItem}>
              <Interpolate
                i18nKey={x('За победу над противником клан получит %(reward)s')}
                reward={
                  <Currency
                    isFlat
                    amount={this.props.warSettings.stages.rewarding.roundResultRewards.victory.amount}
                    type={this.props.warSettings.stages.rewarding.roundResultRewards.victory.type}
                    isBold={true}
                  />
                }
                t={t}
              />
            </li>
            <li className={styles.rewardListItem}>
              <Interpolate
                i18nKey={x('За ничью -  %(reward)s')}
                reward={
                  <Currency
                    isFlat
                    amount={this.props.warSettings.stages.rewarding.roundResultRewards.draw.amount}
                    type={this.props.warSettings.stages.rewarding.roundResultRewards.draw.type}
                    isBold={true}
                  />
                }
                t={t}
              />
            </li>
            <li className={styles.rewardListItem}>
              <Interpolate
                i18nKey={x('За поражение -  %(reward)s')}
                reward={
                  <Currency
                    isFlat
                    amount={this.props.warSettings.stages.rewarding.roundResultRewards.defeat.amount}
                    type={this.props.warSettings.stages.rewarding.roundResultRewards.defeat.type}
                    isBold={true}
                  />
                }
                t={t}
              />
            </li>
            <li className={styles.rewardListItem}>
              <Interpolate
                i18nKey={x('За каждые %(stars)s клан получает %(oil)s')}
                stars={
                  <Currency
                    isFlat
                    amount={this.props.warSettings.stages.war.rewardedStarsIncrement}
                    isBold={true}
                    type="cwars-star"
                  />
                }
                oil={
                  <Currency
                    isFlat
                    amount={this.props.warSettings.stages.war.starsIncrementReward.amount}
                    isBold={true}
                    type={'oil'}
                  />
                }
                t={t}
              />
            </li>
          </ul>
          <p className={styles.paragraphText}>
            {t('Заработанная на этапе Подготовки нефть будет начисляться сразу, а за Битву - после её окончания.')}
          </p>
        </div>

        {settings.cwars.features.groupRounds && (
          <div className={styles.paragraph}>
            <span className={styles.paragraphTitle}>{t('Награды в групповом соревновании')}</span>
            <p className={styles.paragraphText}>
              {t(
                'Нефть — основная награда «Морского сражения», которую получат все участвующие кланы в зависимости от активности их участников.',
              )}
            </p>
            <ul className={styles.rewardList}>
              <li className={styles.rewardListItem}>
                <Interpolate
                  i18nKey={x('За этап Подготовки клан получит до %(reward)s')}
                  reward={<Currency isFlat amount={oilReward} type={'oil'} isBold={true} />}
                  t={t}
                />
              </li>
              <li className={styles.rewardListItem}>
                {t('Количество нефти зависит от количества заработанных звёзд и места вашего клана в группе.')}
              </li>
              <li className={styles.rewardListItem}>
                <Interpolate
                  i18nKey={x('За каждые %(stars)s клан получает %(oil)s')}
                  stars={
                    <Currency
                      isFlat
                      amount={this.props.warSettings.stages.war.rewardedStarsIncrement}
                      isBold={true}
                      type="cwars-star"
                    />
                  }
                  oil={
                    <Currency
                      isFlat
                      amount={this.props.warSettings.stages.war.starsIncrementReward.amount}
                      isBold={true}
                      type={'oil'}
                    />
                  }
                  t={t}
                />
              </li>
            </ul>
            <p className={styles.paragraphText}>
              {t('Заработанная на этапе Подготовки нефть будет начисляться сразу, а за Битву - после её окончания.')}
            </p>
          </div>
        )}

        <div className={styles.paragraph}>
          <span className={styles.paragraphTitle}>{t('Рейтинг')}</span>
          <p className={styles.paragraphText}>
            {t(
              'Соперники для Битвы подбираются с учётом активности кланов, а также специального рейтинга, посмотреть который можно в соответствующей вкладке раздела «Морское сражение». Попасть в рейтинг можно в случае успешного прохождения кланом Битвы. Клан не может покинуть рейтинг, если однажды уже попал в него.',
            )}
          </p>
        </div>

        <div className={styles.paragraph}>
          <span className={styles.paragraphTitle}>{t('Некоторые детали')}</span>
          <p className={styles.paragraphText}>
            {t(
              'Игроки, вступившие в клан после окончания этапа Подготовки, уже не смогут принять участие в Битве — для этого им необходимо дождаться следующей недели.',
            )}
          </p>
          <p className={styles.paragraphText}>
            {t(
              'Помните, что если игрок покинет клан после того, как нажмёт кнопку «Засчитывать попытки», то звёзды, которые он получил, останутся в клане.',
            )}
          </p>
          <p className={styles.paragraphText}>{this.getFullDetailsText()}</p>
        </div>
      </Fragment>
    );
  };

  getPreparationDescrText = () => {
    return settings.cwars.features.additionalAttemptsPreparation
      ? x(
          'В первые %(dayCount)s дня Морского сражения вы участвуете в привычных боях. Выполните клановую задачу, чтобы получить доступ к Битве и %(reward)s. При этом можно также увеличить количество попыток для этапа Битвы.',
        )
      : x(
          'В первые %(dayCount)s дня Морского сражения вы участвуете в привычных боях. Выполните клановую задачу, чтобы получить доступ к Битве и %(reward)s',
        );
  };

  getBattleDescrText = () => {
    switch (this.props.warSettings.stages.preparation.competitionMetric) {
      case COMPETITION_METRIC.EXP: {
        return x(
          'В следующие %(dayCount)s дня вы можете соревноваться с другим кланом в получении чистого опыта на кораблях разных классов и наций. Для этого у каждого игрока есть не менее %(attemptCount)s попыток.',
        );
      }

      case COMPETITION_METRIC.DAMAGE: {
        return x(
          'В следующие %(dayCount)s дня вы можете соревноваться с другим кланом в нанесении урона на кораблях разных классов и наций. Для этого у каждого игрока есть не менее %(attemptCount)s попыток.',
        );
      }

      case COMPETITION_METRIC.RIBBONS: {
        return x(
          'В следующие %(dayCount)s дня вы можете соревноваться с другим кланом в получении лент на кораблях разных классов и наций. Для этого у каждого игрока есть не менее %(attemptCount)s попыток.',
        );
      }

      default: {
        return '';
      }
    }
  };

  renderShortRules = () => {
    let oilReward = this.getPreparationRewardSumm();
    if (oilReward === 0) {
      oilReward = 800;
    }

    return (
      <Fragment>
        <StepCSSTransitionGroup level={1}>
          <h1 className={styles.bigTitle}>{t('Как играть в морское сражение')}</h1>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={2}>
          <div className={styles.step}>
            <div className={`${styles.stepImage} ${styles.stepImageFirst}`} />
            <span className={styles.paragraphTitle}>{t('Подготовка')}</span>
            <Interpolate
              parent={'p'}
              className={styles.paragraphText}
              i18nKey={this.getPreparationDescrText()}
              dayCount={4}
              reward={<Currency isFlat amount={oilReward} type={'oil'} isBold={true} />}
              t={t}
            />
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={3}>
          <div className={styles.step}>
            <div className={`${styles.stepImage} ${styles.stepImageSecond}`} />
            <span className={styles.paragraphTitle}>{t('Битва')}</span>
            <p>
              <Interpolate
                parent={'span'}
                className={styles.paragraphText}
                i18nKey={this.getBattleDescrText()}
                dayCount={3}
                attemptCount={10}
                t={t}
              />
              &nbsp;
              {settings.cwars.features.additionalAttemptsPreparation &&
                t('Вы можете увеличить количество попыток на этапе Подготовки.') + ' '}
              {settings.cwars.features.additionalAttempts &&
                t(
                  'Часть израсходованных попыток обновляется и может быть снова использована во время Битвы. Таким образом, играя в течение всех дней Битвы, вы можете использовать больше попыток.',
                ) + ' '}
            </p>
            <p>
              <strong>
                {t(
                  'Перед выходом в бой необходимо включить счётчик использования своих попыток, расположенный над таблицей с результатами клана.',
                )}
              </strong>
            </p>
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={4}>
          <div className={styles.step}>
            <div className={`${styles.stepImage} ${styles.stepImageThird}`} />
            <span className={styles.paragraphTitle}>{t('Награды и рейтинг')}</span>
            <p className={styles.paragraphText}>
              {t(
                'Все кланы, участвующие в Морском сражении, получают нефть в качестве награды и продвижение в рейтинге.',
              )}
            </p>
          </div>
        </StepCSSTransitionGroup>
      </Fragment>
    );
  };

  renderJoinClan = () => {
    return (
      <Fragment>
        <StepCSSTransitionGroup level={3}>
          <h2 className={styles.title}>{t('Для участия в морском сражении необходимо состоять в клане')}</h2>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={3}>
          <div className={styles.center}>
            <ButtonPromo isCenter isFlat onClick={this.props.navigateToRecommendations}>
              {t('Хочу в клан')}
            </ButtonPromo>
          </div>
        </StepCSSTransitionGroup>
      </Fragment>
    );
  };

  render() {
    const hasNoClan = this.props.clanId === undefined || this.props.clanId === null;

    return (
      <div className={styles.wrapper}>
        {this.renderShortRules()}

        {this.state.isFullRules ? (
          this.renderFullRules()
        ) : (
          <div className={styles.showFullLink}>
            <StepCSSTransitionGroup level={3}>
              <Link isActionLink onClick={this.showFullRules} arrow={''}>
                {t('Расширенный вариант правил')}
              </Link>
            </StepCSSTransitionGroup>
          </div>
        )}

        {hasNoClan ? this.renderJoinClan() : null}
        <div className={styles.spacer} />
      </div>
    );
  }
}

export default RulesTab;
