import { t } from '~/helpers/localization';

export const currencyNames = {
  gold: t('Дублоны'),
  dublon: t('Дублоны'),
  oil: t('Нефть'),
  coal: t('Уголь'),
  steel: t('Сталь'),
  'silver-star': 'silver-star',
  'gold-star': 'gold-star',
  ribbon: t('Ленты'),
  damage: t('Урон'),
};

export const currencyNamesToWE = {
  gold: 'gold',
  dublon: 'gold',
  oil: 'clan_resource',
  clan_resource: 'clan_resource',
  coal: 'coal',
  steel: 'steel',
};
