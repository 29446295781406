import preloaded from '~/preloaded';
import settings from '~/settings';

const encyclopedia = preloaded.encyclopedia || {};

const getNationData = (nationName: string) => {
  return encyclopedia.nations?.[nationName] || null;
};

const getShipTypeData = (shipType: string) => {
  return encyclopedia.vehiclesTypes?.[shipType] || null;
};

export const getNationUrl = (nationName: string, size?: string) => {
  const nationData = getNationData(nationName);
  let iconUrl = '';

  if (nationData) {
    iconUrl = !size
      ? `${settings.mediaPath}${nationData.icons.default}`
      : `${settings.mediaPath}${nationData.icons[size]}`;
  }
  return iconUrl;
};

export const getShipTypes = () => {
  const shipTypes = Object.entries(encyclopedia.vehiclesTypes).map(([name, vehicle]) => {
    return { ...vehicle, name };
  });
  shipTypes.sort((a, b) => (a['order'] - b['order'] > 0 ? 1 : -1));
  return shipTypes;
};

export const getLocalizationShipTypeName = (shipType: string) => {
  const shipTypeData = getShipTypeData(shipType);

  return (shipTypeData && shipTypeData.mark) || '';
};

export const getShipTypeUrl = (shipType: string, isPremium: boolean, isSpecial: boolean) => {
  const shipTypeData = getShipTypeData(shipType);
  if (!shipTypeData) {
    return '';
  }

  const iconUrls = shipTypeData.icons;
  const mediaPath = settings.mediaPath;
  let icon = iconUrls.default;

  if (isPremium) {
    icon = iconUrls.premium;
  }

  if (isSpecial) {
    icon = iconUrls.special;
  }

  return `${mediaPath}${icon}`;
};

const nationShipsGlossMap: {
  [nation: string]: {
    [shipTypeOrName: string]: Nullable<string>;
  };
} = {};

if (encyclopedia.nations) {
  for (const key in encyclopedia.nations) {
    const nation = encyclopedia.nations[key];
    nationShipsGlossMap[key] = {
      name: nation.mark || null,
    };
  }
}

if (encyclopedia.vehiclesTypes) {
  for (const key in encyclopedia.vehiclesTypes) {
    const vehiclesType = encyclopedia.vehiclesTypes[key];
    for (const k in nationShipsGlossMap) {
      nationShipsGlossMap[k] = {
        ...nationShipsGlossMap[k],
        [key]: `${vehiclesType.mark}`,
      };
    }
  }
}

export const NATIONS_SHIPS_TEXTS_MAP = nationShipsGlossMap;
