import classNames from 'classnames';
import React from 'react';

import { SOCIAL_NETWORKS } from '~/constants';

import SocialLink from './SocialLink';
import styles from './SocialLink.scss';
import SocialLinkEmpty from './SocialLinkEmpty';
import SocialLinkInput from './SocialLinkInput';

export interface ISocialLinkContainer {
  social: (typeof SOCIAL_NETWORKS)[keyof typeof SOCIAL_NETWORKS];
  link?: string;
  isDisabled?: boolean;
  isEditAvailable?: boolean;
  isEditMode?: boolean;
  onEditMode?: () => void;
  isFocusOn?: boolean;
  isFullLink?: boolean;
  onChange?: (data: {
    social: (typeof SOCIAL_NETWORKS)[keyof typeof SOCIAL_NETWORKS];
    link: string;
    error: boolean;
  }) => void;
}

const SocialLinkContainer: React.FC<ISocialLinkContainer> = (props) => {
  const { link, social, isEditAvailable, isEditMode, onEditMode, isDisabled, isFullLink, onChange, isFocusOn } = props;

  if (!Object.values(SOCIAL_NETWORKS)?.includes(social)) {
    return null;
  }

  if (isEditMode) {
    return (
      <div className={styles.container}>
        <SocialLinkInput
          link={link}
          social={social}
          isDisabled={isDisabled}
          onChange={onChange}
          isFocusOn={isFocusOn}
        />
      </div>
    );
  }

  if (!link) {
    if (!isEditAvailable) {
      return null;
    }

    return (
      <div className={classNames(styles.container, styles.containerLink)}>
        <SocialLinkEmpty social={social} onClick={onEditMode} />
      </div>
    );
  }

  return (
    <div className={classNames(styles.container, styles.containerLink)}>
      <SocialLink link={link} social={social} isFullLink={isFullLink} />
    </div>
  );
};

export default React.memo(SocialLinkContainer);
