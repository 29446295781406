import React from 'react';
import { push } from 'react-router-redux';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import { DivTooltip, LineClamp, Tooltip, TooltipBody, TooltipFooter, TooltipHeader } from '@wg/wows-react-uikit';

import settings from '~/settings';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { t } from '~/helpers/localization';
import { useAppDispatch } from '~/store';

import { PURPOSES } from '~/Actions/ActionRecommendations';

import styles from './ClanTitle.scss';

import type { CLANREC_APPLICABLE_PAGES } from '~/Actions/ActionRecommendations';

interface IClanTitleProps {
  clanColor: string;
  clanDescription: string;
  clanId: number;
  clanName: string;
  clanTag: string;
  purpose: PURPOSES | CLANREC_APPLICABLE_PAGES;
}
const ClanTitle: React.FC<IClanTitleProps> = ({ clanColor, clanDescription, clanId, clanName, clanTag, purpose }) => {
  const dispatch = useAppDispatch();

  const openClanBase: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (purpose in PURPOSES) {
      dwhExport.push(DWH_EVENTS.CLANREC[purpose as PURPOSES]?.OPEN_CLAN);
    }

    const leftButtonClicked = event.button === 0;
    const middleButtonClicked = event.button === 1;
    const isInGame = window.navigator.userAgent.indexOf(settings.ingameUserAgent) !== -1;
    const url = `/clan-profile/${clanId}?source=clanrec&purpose=${purpose}`;

    if (!isInGame && middleButtonClicked) {
      window.open(url, '_blank');
    } else if (leftButtonClicked) {
      void playButtonClickSound();
      dispatch(push(url));
    }
  };

  const clanTitle = (
    <div className={styles.title}>
      <h2 className={styles.clanTag} style={{ color: clanColor }}>
        [{clanTag}]
      </h2>
      <h2 className={styles.clanTitle}>{clanName}</h2>
    </div>
  );

  return (
    <DivTooltip
      tooltipBody={
        <Tooltip>
          <TooltipHeader>{clanTitle}</TooltipHeader>
          {clanDescription && (
            <TooltipBody>
              <LineClamp lines={13}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: clanDescription,
                  }}
                />
              </LineClamp>
            </TooltipBody>
          )}
          <TooltipFooter mouseIcon="left">{t('Перейти к клановой базе')}</TooltipFooter>
        </Tooltip>
      }
    >
      <div onMouseDown={openClanBase} className={styles.clanWrapper}>
        <div>{clanTitle}</div>
        {clanDescription ? <div className={styles.clanDescription}>{clanDescription} </div> : null}
      </div>
    </DivTooltip>
  );
};

export default React.memo(ClanTitle);
