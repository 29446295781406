import classNames from 'classnames';
import get from 'lodash/get';
import React from 'react';
import { Interpolate, translate } from 'react-i18next';
import { IconTable, Link } from '@wg/wows-react-uikit';

import { hasCrossRealm } from '~/settings';
import getSeasonIconUrl from '~/helpers/clanIcons';
import { getLeagueNameByNumber } from '~/helpers/ladder';
import { t, x } from '~/helpers/localization';

import styles from './TopWinners.scss';

import type { OwnClan, Winner } from '~/Reducers/ReducerHallFame';

const chineseReal = 'cn';
const redirectToRating = '1';

interface TopWinners_Props {
  season: Array<Winner>;
  ratings?: Array<OwnClan>;
  seasonName: {
    [season: string]: string;
  };
  ownClansTag: string;
  ownClansName: string;
  setSelectedTab: (selectedTab: string) => void;
  onSeasonChange: (selectedTab: number) => void;
  realmsMap: {
    [realm: string]: {
      [name: string]: string;
    };
  };
  isHide?: boolean;
  isShow?: boolean;
}

const TopWinners: React.FC<TopWinners_Props> = ({
  season,
  seasonName,
  ownClansTag,
  ownClansName,
  ratings,
  setSelectedTab,
  realmsMap,
  isShow,
  isHide,
  onSeasonChange,
}) => {
  let rating: undefined | OwnClan;
  let leagueName: undefined | string;
  if (ratings) {
    rating = ratings[0];
    leagueName = getLeagueNameByNumber(rating.league, season[0].season_id);
  }

  const onClick = (numberSeason: number) => {
    setSelectedTab(redirectToRating);
    onSeasonChange(numberSeason);
  };

  const url = season[0] && season[0].season_id ? getSeasonIconUrl(season[0].season_id) : '';

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.isHide]: isHide,
        [styles.isShow]: isShow,
      })}
    >
      <header className={styles.header}>
        <img className={styles.seasonIcon} src={url} />
        <h4 className={styles.title}>
          <Interpolate
            useDangerouslySetInnerHTML={true}
            i18nKey={x('Сезон  %(number)s: %(name)s')}
            number={season[0].season_id}
            name={seasonName[season[0].season_id]}
            t={t}
          />
        </h4>
      </header>
      <ul className={styles.winners}>
        {season.map((winner: Winner) => {
          if (winner.realm !== chineseReal) {
            return (
              <li className={styles.item} key={winner.clan_id}>
                <span className={styles.tag}>[{winner.tag}]</span>
                <span className={styles.name}>{winner.name}</span>
                <p className={styles.position}>
                  {hasCrossRealm && (
                    <span className={styles.realm}>{get(realmsMap, [`${winner.realm}`, 'shortName'])}</span>
                  )}
                  <span className={styles.rating}>
                    <IconTable glyph={'rating'} />
                    {'CR: '}
                    {winner.division_rating}
                  </span>
                </p>
              </li>
            );
          }
        })}
      </ul>
      {ownClansTag && (
        <footer className={styles.footer}>
          <div className={styles.clanPosition}>{t('Позиция моего клана:')}</div>
          <div className={styles.ownClan}>
            <div>
              <span className={styles.ownClansTag}>
                {'['}
                {ownClansTag}
                {'] '}
              </span>
              <span>{ownClansName}</span>
            </div>
            {rating ? (
              <>
                <div className={styles.league}>
                  <IconTable glyph={'rating'} />
                  <span className={styles.info}>
                    {t('Лига: ')}
                    {leagueName}
                    {', '}
                  </span>
                  {rating.league !== 0 && (
                    <span className={styles.info}>
                      {t(' Группа: ')}
                      {rating.division}
                      {', '}
                    </span>
                  )}
                  <span>
                    {' CR: '}
                    {rating.division_rating}
                  </span>
                </div>
                <div>
                  <Link isActionLink={false} arrow="" onClick={() => onClick(season[0].season_id)}>
                    {t('Перейти к позиции моего клана')}
                  </Link>
                </div>
              </>
            ) : (
              <div>{t('Ваш клан отсутствует в рейтинге')}</div>
            )}
          </div>
        </footer>
      )}
    </div>
  );
};

export default translate()(TopWinners);
