import React from 'react';

import { t } from '~/helpers/localization';

import styles from './ViewSupply.scss';

type ICloseDialogButtonProps = {
  onClick: () => void;
};

const CloseDialogButton: React.FC<ICloseDialogButtonProps> = ({ onClick }) => {
  return (
    <div className={styles.closeDialogButtonWrapper}>
      <div className={styles.closeDialogButton} onClick={onClick}>
        <div className={styles.closeDialogButtonIcon} />
        <span className={styles.closeDialogButtonText}>{t('Закрыть')}</span>
      </div>
    </div>
  );
};

export default CloseDialogButton;
