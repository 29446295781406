import settings from '~/settings';

let localeLanguageCode = settings.realm.languageCode;

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const dateFormat = new Intl.DateTimeFormat(localeLanguageCode, dateFormatOptions);

if (localeLanguageCode === 'en') {
  if (settings.realm.code === 'us') {
    localeLanguageCode = 'en-US';
  } else {
    localeLanguageCode = 'en-GB';
  }
}

export const localizedDateTime = (date: Date): string => {
  return dateFormat.format(date);
};
