import filter from 'lodash/filter';
import get from 'lodash/get';
import React from 'react';
import {
  Button,
  ButtonGroup,
  CheckboxWithLabel,
  DialogBody,
  DialogContainer,
  DialogFooter,
  DialogHeader,
  Message,
} from '@wg/wows-react-uikit';

import { LANGUAGES } from '~/constants';
import { t } from '~/helpers/localization';

import styles from './LanguagesDialog.scss';

import type { IDialog } from '~/types/declaration';

type ILanguagesDialog = IDialog<{
  languagesMap: {
    [language: string]: boolean;
  };
  updateLanguages: (languages: Array<string>) => void;
}>;

const LanguagesDialog: React.FC<ILanguagesDialog> = ({ data, hideDialog }) => {
  const { languagesMap, updateLanguages } = data;

  const [localLanguagesMap, setLocalLanguagesMap] = React.useState(languagesMap);
  const [isLanguageNotSelected, setIsLanguageNotSelected] = React.useState(false);

  React.useEffect(() => {
    setLocalLanguagesMap(languagesMap);
  }, [languagesMap]);

  const getSelectedLanguages = () => {
    let languages = Object.entries(localLanguagesMap);
    languages = Object.values(filter(languages, ([, value]) => value === true));
    return languages.map(([languageCode]) => languageCode);
  };

  const languageChanged = (languageCode: string) => () => {
    const updatedLanguagesMap = {
      ...localLanguagesMap,
      [languageCode]: !get(localLanguagesMap, languageCode, false),
    };

    const languageNotSelected = filter(Object.values(updatedLanguagesMap)).length === 0;

    setLocalLanguagesMap(updatedLanguagesMap);
    setIsLanguageNotSelected(languageNotSelected);
  };

  const handleUpdateLanguages = () => {
    updateLanguages(getSelectedLanguages());
    hideDialog();
  };

  const content = Object.entries(languagesMap).map(([languageCode]) => (
    <div key={languageCode} className={styles.language}>
      <CheckboxWithLabel
        labelText={get(LANGUAGES, languageCode, `[ ${languageCode} ]`)}
        isChecked={get(localLanguagesMap, languageCode, false)}
        onChange={languageChanged(languageCode)}
      />
    </div>
  ));

  const languageNotSelectedMessage = isLanguageNotSelected && (
    <Message type="error" style="inline">
      {t('Язык не выбран')}
    </Message>
  );

  return (
    <DialogContainer>
      <DialogHeader>{t('Язык общения')}</DialogHeader>
      <DialogBody>
        <div className={styles.wrapper}>
          {content}
          <div className={styles.error}>{languageNotSelectedMessage}</div>
        </div>
      </DialogBody>
      <DialogFooter>
        <ButtonGroup>
          <Button isJustified isFlat isDisabled={isLanguageNotSelected} onClick={handleUpdateLanguages}>
            {t('Сохранить')}
          </Button>
          <Button isJustified isFlat onClick={hideDialog}>
            {t('Закрыть')}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    </DialogContainer>
  );
};

export default React.memo(LanguagesDialog);
