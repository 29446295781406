import React, { Fragment, PureComponent } from 'react';
import { playTabClickSound } from '@wg/web2clientapi/sound';
import { Divider, ErrorLoad, Icon, Menu, StepCSSTransitionGroup } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { SEASON_TYPES } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { toRoman } from '~/helpers/formatting';
import { getLeagueNameByNumber } from '~/helpers/ladder';
import { t } from '~/helpers/localization';
import { getSeasonTeams } from '~/helpers/seasons';
import { TEAMS_NAMES } from '~/helpers/teams';

import { Ratings } from '~/UIKit';

import styles from './ClanBattlesHistory.scss';
import ClanBattlesTable from '../ClanBattlesTable/ClanBattlesTable';

export type IClanBattlesHistory_Props = {
  clanId: number;
  clanData: any;
  battlesList: any;
  isError: boolean;
  isFetching: boolean;
  selectedBattle: any;
  wowsLadder: any;
  seasonType: string;
  teamNumber: number;
  statistics: any;

  onClickBattle: (battleId: number) => void;
  reload: () => void;
  setSelectedTeamNumber: (teamNumber: number) => void;
};

const DEFAULT_BATTLE = 0;
const CLAN_BATTLES_TYPE = 'cvc';

class ClanBattlesHistory extends PureComponent<IClanBattlesHistory_Props, any> {
  componentDidMount() {
    dwhExport.push(DWH_EVENTS.CLAN_BATTLES.VIEW_CLAN_HISTORY, {
      teamNumber: this.props.teamNumber,
    });
  }

  componentWillUnmount() {
    this.props.onClickBattle(0);
  }

  getCurrentTeam = () => {
    let currentTeam = [];
    if (this.props.wowsLadder) {
      currentTeam = this.props.wowsLadder.ratings.filter((team) => {
        return team.team_number === this.props.teamNumber && team.season_number === settings.ladder.lastSeasonId;
      });
    }
    return currentTeam[0] ? currentTeam[0] : null;
  };

  renderError = () => {
    return (
      <ErrorLoad
        isFlat
        key="error-load"
        message={t('Произошла ошибка. Повторите попытку позже')}
        onReloadClick={this.props.reload}
        t={t}
      />
    );
  };

  renderSignTitleAndText = (statistics: any) => {
    let signTitle = null;
    let signText = null;

    if (this.props.battlesList.length && statistics.stage) {
      const stringParams = {
        league: getLeagueNameByNumber(statistics.stage.target_league, settings.ladder.lastSeasonId),
        division: toRoman(statistics.stage.target_division),
        victoriesRequired: statistics.stage.victories_required,
        battles: statistics.stage.battles,
      };
      switch (statistics.stage.type) {
        case 'qualification':
          signTitle = t('Квалификация');
          break;
        case 'promotion':
          signTitle = t('Игра на повышение');
          signText = t(
            'Для перехода в лигу %(league)s, дивизион %(division)s, необходимо выиграть боев: %(victoriesRequired)s из %(battles)s',
            stringParams,
          );
          break;
        case 'demotion':
          signTitle = t('Игра на понижение');
          signText = t(
            'Что бы избежать перехода в лигу %(league)s, дивизион %(division)s, необходимо выиграть боев: %(victoriesRequired)s из %(battles)s',
            stringParams,
          );
          break;
        default:
          break;
      }
    }

    return signTitle ? (
      <div>
        <div className={styles.signTitle}>{signTitle}</div>
        <div className={styles.signText}>{signText}</div>
      </div>
    ) : null;
  };

  renderTeamsDropdown() {
    const seasonTeams = getSeasonTeams(this.props.wowsLadder.ratings, settings.ladder.lastSeasonId);

    if (seasonTeams.length < 2) {
      return null;
    }

    const teamsMap = seasonTeams.map((team) => {
      return {
        value: team.team_number,
        content: TEAMS_NAMES[team.team_number],
      };
    });

    teamsMap.sort((teamA, teamB) => {
      return teamA.value - teamB.value;
    });

    const dropdownTeamLabel = <span className={styles.dropdownTeamLabel}>{TEAMS_NAMES[this.props.teamNumber]}</span>;
    const isLeadingTeam = this.props.wowsLadder && this.props.wowsLadder.leading_team_number === this.props.teamNumber;

    const teamText = isLeadingTeam ? (
      <span>
        <Icon glyph="leading-team" />
        <span className={styles.firstPText}>{t('Ведущий рейтинг клана')}</span>
      </span>
    ) : null;

    return this.props.wowsLadder.ratings.length > 1 ? (
      <div>
        <div className={styles.dropdownTeamMenuWrap}>
          <Menu
            items={teamsMap}
            itemSelected={this.props.teamNumber}
            onItemChange={(val) => {
              this.props.setSelectedTeamNumber(parseInt(val, 10));
            }}
          >
            {dropdownTeamLabel}
          </Menu>
        </div>
        <div className={styles.teamText}>{teamText}</div>
      </div>
    ) : (
      dropdownTeamLabel
    );
  }

  onClickBattle = (battleId: number) => {
    void playTabClickSound();

    const id = battleId === this.props.selectedBattle ? DEFAULT_BATTLE : battleId;

    this.props.onClickBattle(id);
  };

  render() {
    const currentTeam = this.getCurrentTeam();

    if (!currentTeam) {
      return this.renderError();
    }

    const isBrawl = this.props.seasonType === SEASON_TYPES.BRAWL;

    const statistics = {
      ladderBattlesCount: currentTeam.battles_count,
      ladderWinsPercent: (currentTeam.wins_count / currentTeam.battles_count) * 100,
      ladderWinsCount: currentTeam.wins_count,
      ladderMaxLeague: currentTeam.max_position.league,
      ladderMaxDivision: currentTeam.max_position.division,
      ladderMaxDivisionRating: currentTeam.max_position.division_rating,
      ladderWinStreak: currentTeam.longest_winning_streak,
      ladderLastWinAt: currentTeam.last_win_at,
      currentBattleType: isBrawl ? SEASON_TYPES.BRAWL : CLAN_BATTLES_TYPE,

      totalMembers: this.props.clanData.members_count,
      maxMembersCount: this.props.clanData.maxMembersCount,
      isBrawl: isBrawl,
      withLeagueSign: !isBrawl,
      league: currentTeam.league,
      division: currentTeam.division,
      stage: currentTeam.stage,
      divisionRating: currentTeam.division_rating,
      maxDivisionRating: currentTeam.division_rating_max,
    };

    return (
      <Fragment>
        <StepCSSTransitionGroup level={1}>
          <div className={styles.dropdownTeamWrap}>{this.renderTeamsDropdown()}</div>
        </StepCSSTransitionGroup>

        <StepCSSTransitionGroup level={2}>
          <div className={styles.ratings}>
            <Ratings
              key="ratings"
              clanId={this.props.wowsLadder.id}
              currentSeason={settings.ladder.lastSeasonId}
              membersError={false}
              statistics={statistics}
              isHiddenStatistics={false}
            />
            {this.renderSignTitleAndText(statistics)}
          </div>
        </StepCSSTransitionGroup>

        <StepCSSTransitionGroup level={3}>
          <Divider type="major" />
          <span className={styles.tableTitle}>{t('История боёв')}</span>
          <ClanBattlesTable
            battlesList={this.props.battlesList}
            isFetching={this.props.isFetching}
            stickyContainerId={'clan-battles-history-container'}
            selectedBattle={this.props.selectedBattle}
            isError={this.props.isError}
            errorMessage={''}
            onClickBattle={this.onClickBattle}
            seasonType={this.props.seasonType}
          />
        </StepCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default ClanBattlesHistory;
