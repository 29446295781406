import classNames from 'classnames';
import * as React from 'react';
import { Processing, Sticky, StickyContainer, Tabs } from '@wg/wows-react-uikit';

import settings from '~/settings';

import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { t } from '~/helpers/localization';

import ClansRating from '~/Containers/ContainerBaseClansRating';

import ClanBattlesHistory from './ClanBattlesHistory/ClanBattlesHistory';
import ClanBattlesInfoPage from './ClanBattlesInfoPage/ClanBattlesInfoPage';
import ClanBattlesRulesPage from './ClanBattlesRulesPage/ClanBattlesRulesPage';
import ClansBattlesHallFame from './ClansBattlesHallFame/ClansBattlesHallFame';
import styles from './ViewClanBattles.scss';

export interface ViewClanBattles_Props {
  selectedTab: number;
  clanId: number;
  clanData: any;
  battlesList: any;
  isError: boolean;
  isFetching: boolean;
  selectedBattle: any;
  wowsLadder: any;
  seasonType: string;
  teamNumber: number;
  hasBattles: boolean;
  urlParams: any;

  setSelectedTab: (selectedTab: string) => void;
  navigateToRecommendations: () => void;
  onClickBattle: (battleId: number) => void;
  getClanBattles: () => void;
  setSelectedTeamNumber: (teamNumber: number) => void;
  isEmptyWinners: boolean;
}

class ViewClanBattles extends React.PureComponent<ViewClanBattles_Props, any> {
  componentDidMount() {
    dwhExport.push(DWH_EVENTS.CLAN_BATTLES.OPEN_PAGE);
  }

  renderTab() {
    switch (this.props.selectedTab) {
      case 0: {
        if (this.props.hasBattles) {
          return (
            <ClanBattlesHistory
              clanId={this.props.clanId}
              clanData={this.props.clanData}
              battlesList={this.props.battlesList}
              isError={this.props.isError}
              isFetching={this.props.isFetching}
              selectedBattle={this.props.selectedBattle}
              wowsLadder={this.props.wowsLadder}
              seasonType={this.props.seasonType}
              onClickBattle={this.props.onClickBattle}
              reload={this.props.getClanBattles}
              teamNumber={this.props.teamNumber}
              setSelectedTeamNumber={this.props.setSelectedTeamNumber}
            />
          );
        } else {
          return (
            <ClanBattlesInfoPage
              clanId={this.props.clanId}
              navigateToRecommendations={this.props.navigateToRecommendations}
            />
          );
        }
      }
      case 1: {
        return <ClansRating urlParams={this.props.urlParams} />;
      }
      case 2: {
        return <ClansBattlesHallFame setSelectedTab={this.props.setSelectedTab} />;
      }
      case 3: {
        return <ClanBattlesRulesPage />;
      }
      default: {
        return null;
      }
    }
  }

  setSelectedTab = (selectedTab: string | number) => {
    this.props.setSelectedTab(`${selectedTab}`);
  };

  render() {
    const tabs: Array<any> = [
      { value: '0', content: t('Клановые бои') },
      { value: '1', content: t('Рейтинг кланов') },
    ];

    if (!this.props.isEmptyWinners && settings.ladder.hallOfFameIsEnabled) {
      tabs.push({ value: '2', content: t('Зал славы') });
    }
    if (this.props.clanId) {
      tabs.push({ value: '3', content: t('Правила') });
    }

    const gold = classNames(styles.tabsNavigation, {
      [styles.gold]: tabs.find((tab) => tab.value === '2'),
    });

    return (
      <StickyContainer id={'stickyClanBattles'}>
        <div className={styles.wrapper}>
          <Sticky
            usedStickyContainerId={'stickyClanBattles'}
            isActive={true}
            appContainerId={null}
            scrollContainerId={null}
          >
            <div className={gold}>
              <Tabs
                isUppercase
                smallHeight
                tabSelected={`${this.props.selectedTab}`}
                tabs={tabs}
                onToggleTab={this.setSelectedTab}
              />
            </div>
          </Sticky>
          {this.props.isFetching ? null : this.renderTab()}
          <Processing isFetching={this.props.isFetching} label={''} />
        </div>
      </StickyContainer>
    );
  }
}

export default ViewClanBattles;
