import get from 'lodash/get';

import type { IAttemptsData, IBattle, IStages, IWarModifier, IWarSettings, WarModifiers } from '~/types/declaration';

export const getSelfAttemptsData = (stages: IStages): IAttemptsData => {
  return {
    additionalAttempts: stages.war.additionalAttempts,
    personalAttemptsCount: stages.war.personalAttemptsCount,
    personalAdditionalAttemptsCount: stages.war.personalAdditionalAttemptsCount,
  };
};

export const getPersonalAttemptsData = (stages: IStages, playerId: number | string): IAttemptsData => {
  return get(stages.war.alliesAttempts, playerId, {
    additionalAttempts: {},
    personalAttemptsCount: 0,
    personalAdditionalAttemptsCount: 0,
  });
};

export interface IAttemptsAdditionalData {
  left: number;
  total: number;
  preparationActivity: number;
  personalMaximum: number;
  additional: number;
  forecastFirstDay: number;
  firstDay: Date;
  forecastSecondDay: number;
  secondDay: Date;
}

export const getAttemptsAdditionalData = (
  attemptsData: IAttemptsData,
  warSettings: IWarSettings,
): IAttemptsAdditionalData => {
  const additionalSum = Object.values(attemptsData.additionalAttempts).reduce((sum, key) => sum + key, 0);
  const preparationActivity = get(attemptsData.additionalAttempts, 'preparationActivity', 0);

  const total = warSettings.stages.war.maxPersonalAttempts + additionalSum;
  const personalMaximum = warSettings.stages.war.maxPersonalAttempts + preparationActivity;
  const additional = additionalSum - preparationActivity;
  const left = Math.min(attemptsData.personalAttemptsCount, total);

  const startWarStage = new Date(warSettings.stages.war.startDate);

  const forecastFirstDay = Math.floor(left * 0.2);
  startWarStage.setDate(startWarStage.getDate() + 1);
  const firstDay = new Date(startWarStage);

  const forecastSecondDay = forecastFirstDay + Math.floor(left * 0.25);
  startWarStage.setDate(startWarStage.getDate() + 1);
  const secondDay = new Date(startWarStage);

  return {
    left,
    total,
    preparationActivity,
    personalMaximum,
    additional,
    forecastFirstDay,
    firstDay,
    forecastSecondDay,
    secondDay,
  };
};

export const getModifiersMap = (modifiers: Array<IWarModifier>): Record<string, WarModifiers> => {
  return modifiers.reduce<Record<string, WarModifiers>>((result: Record<string, WarModifiers>, item: IWarModifier) => {
    const key = item.class + '::' + item.nation;
    if (result[key] === undefined) result[key] = [];
    result[key].push(item);
    return result;
  }, {});
};

export const getTotalStarsCount = (reachedBattles: Array<IBattle>, modifiers: Array<IWarModifier>) => {
  const modifiersMap = getModifiersMap(modifiers);
  let starsCount = 0;
  reachedBattles.forEach((battle) => {
    const hasModifier = modifiersMap[battle.class + '::' + battle.nation] !== undefined;
    starsCount += hasModifier ? 2 : 1;
  });
  return starsCount;
};
