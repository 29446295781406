import React, { PureComponent } from 'react';
import Particles from 'react-particles-js';
import { ButtonPromo, StepCSSTransitionGroup } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import { DotList, Title } from '~/UIKit';

import styles from './ClanBattlesInfoPage.scss';
import { circle, polygon } from './images';
import { clanBattlesDescription } from '../promoDescription';

export type IProps = {
  clanId?: number;
  navigateToRecommendations: () => void;
};

const particlesParams = {
  particles: {
    number: {
      value: 6,
      density: {
        enable: true,
        value_area: 800,
      },
    },
    shape: {
      type: ['images'],
      images: [
        {
          src: circle,
          height: 20,
          width: 20,
        },
        {
          src: polygon,
          height: 30,
          width: 30,
        },
      ],
    },
    opacity: {
      value: 0.2,
      random: true,
      anim: {
        enable: false,
      },
    },
    size: {
      value: 30,
      random: true,
      anim: {
        enable: true,
        speed: 5,
        size_min: 24,
        sync: false,
      },
    },
    line_linked: {
      enable: false,
    },
    move: {
      enable: true,
      speed: 0.6,
      direction: 'none',
      random: true,
      straight: false,
      out_mode: 'bounce',
      bounce: false,
    },
  },
};

class ClanBattlesInfoPage extends PureComponent<IProps> {
  render() {
    return (
      <div className={styles.wrapper}>
        <Particles className={styles.particles} params={particlesParams} />
        <div className={styles.inline}>
          <div className={styles.side}>
            <StepCSSTransitionGroup level={2}>
              <div>
                <div className={styles.cvcIcon} />
                <Title>{clanBattlesDescription.title}</Title>
                <DotList>{clanBattlesDescription.items}</DotList>
              </div>
            </StepCSSTransitionGroup>
          </div>
        </div>
        {this.props.clanId ? null : (
          <StepCSSTransitionGroup level={4}>
            <div className={styles.inviteRow}>
              <ButtonPromo isFlat isCenter onClick={this.props.navigateToRecommendations}>
                {t('Хочу в клан')}
              </ButtonPromo>
            </div>
          </StepCSSTransitionGroup>
        )}
      </div>
    );
  }
}

export default ClanBattlesInfoPage;
