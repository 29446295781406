import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { push } from 'react-router-redux';

import settings from '~/settings';

import { BATTLE_TYPES, SEASON_TYPES } from '~/constants';
import { showInviteAcceptDialog, showSendApplicationDialog } from '~/helpers/dialogs';
import { promiseWithSpinner, fetchWrapper as fetch } from '~/helpers/fetch';
import { getLastSeasonOfType } from '~/helpers/ladder';
import { t } from '~/helpers/localization';
import { getCurrentClan } from '~/store/selectors/currentAccountSelector';

import * as web2ClientAPI from '~/web2ClientAPI/web2ClientAPI';

import { actionsApp } from '~/Actions/ActionApp';

import { actionsAccount, syncAccountInfo } from './ActionAccount';
import { actionsRequests } from './ActionRequests';

import type { Meta } from '~/Actions/ActionApp';
import type { ClanInfoType } from '~/Actions/ActionClanProfile';
import type { InferActionsType } from '~/Reducers';
import type { AppThunk, AppAsyncThunk, IAppDispatch } from '~/store';
import type { IApiError } from '~/types/api';

export const INVITES_CHANGE_PAGE = 'INVITES_CHANGE_PAGE';
export const INVITES_CHANGE_ORDER = 'INVITES_CHANGE_ORDER';
export const INVITES_TOGGLE_FETCHING = 'INVITES_TOGGLE_FETCHING';
export const INVITES_UPDATE_INVITES = 'INVITES_UPDATE_INVITES';
export const INVITES_UPDATE_ERROR = 'INVITES_UPDATE_ERROR';
export const INVITES_START_PROCESSING = 'INVITES_START_PROCESSING';
export const INVITES_PROCESSING_FAILED = 'INVITES_PROCESSING_FAILED';
export const INVITES_PROCESSING_SUCCESS = 'INVITES_PROCESSING_SUCCESS';

export type ActionsType = InferActionsType<typeof actionsInvites>;

export type ClanType = {
  color: string;
  id: number;
  is_active: boolean;
  leveling: number;
  members_count: number;
  name: string;
  tag: string;
};

export type Statistic = {
  abd: number;
  abtl: number;
  admg: number;
  aeb: number;
  afb: number;
  awb: number;
  wb?: number;
  btl?: number;
  rank?: number;
  season_id?: number;
  season_rank?: number;
};

export type Sender = {
  id: number;
  role: string;
  name: string;
  clan_id: number;
};
export type Invite = {
  account?: {
    name: string;
    id: number;
  };
  application_leveling?: number;
  clan: ClanInfoType;
  comment: string | null;
  created_at: string;
  expires_at: string;
  game: string;
  id: number;
  is_banned?: boolean;
  is_hidden_statistics: boolean;
  sender: Sender;
  statistics?: Statistic;
  status: string;
  updated_at: string;
};
export type UpdateInviteType = {
  _meta_: Meta;
  invites: Array<Invite>;
};

export const actionsInvites = {
  changePage: (page: number) =>
    ({
      type: INVITES_CHANGE_PAGE,
      page,
    }) as const,
  changeOrder: (order: string, isAsc: boolean) =>
    ({
      type: INVITES_CHANGE_ORDER,
      order,
      isAsc,
    }) as const,
  toggleFetching: () =>
    ({
      type: INVITES_TOGGLE_FETCHING,
    }) as const,
  updateInvites: (data: UpdateInviteType) =>
    ({
      type: INVITES_UPDATE_INVITES,
      data,
    }) as const,
  updateError: (error: IApiError) =>
    ({
      type: INVITES_UPDATE_ERROR,
      error,
    }) as const,
  startProcessing: (id: number) =>
    ({
      type: INVITES_START_PROCESSING,
      id,
    }) as const,
  processingSuccess: (id: number) =>
    ({
      type: INVITES_PROCESSING_SUCCESS,
      id,
    }) as const,
  processingFailed: (id: number) =>
    ({
      type: INVITES_PROCESSING_FAILED,
      id,
    }) as const,
};

const syncForNewClan = (dispatch: IAppDispatch, clanId, root, skipNotification = false) => {
  const messages = skipNotification
    ? {
        joinClan: () => {},
      }
    : {};

  return dispatch(syncAccountInfo(messages)).catch(() => {
    if (clanId) {
      dispatch(
        actionsApp.updateAccountInfo({
          clanId,
          role: 'private',
          activeInvitesCount: 0,
        }),
      );
    }
    dispatch(push(`${root}profile`));
  });
};

const processAcceptError = (dispatch: IAppDispatch, state, json, invite: Invite) => {
  const root = state.urls.root;
  const reason = json?.title?.toLowerCase();

  if (reason === 'account_in_cooldown') {
    web2ClientAPI.sendAcceptInviteYouAreInClanCooldownErrorNotification(
      invite.clan,
      state.currentAccount.inClanCooldownTill,
    );
  } else if (reason === 'account_already_in_clan') {
    web2ClientAPI.sendAcceptInviteYouAreInOtherClanErrorNotification(invite.clan);
    const clanId = json?.additional_data?.clan_id || json?.additional_info?.clan_id || null;
    return syncForNewClan(dispatch, clanId, root);
  } else if (reason === 'invite_is_not_active') {
    const inviteStatus = json?.additional_data?.status || json?.additional_info?.status;
    inviteStatus === 'expired'
      ? web2ClientAPI.sendAcceptInviteWithExpiredStatusNotification(invite.clan)
      : inviteStatus === 'deleted'
        ? web2ClientAPI.sendAcceptInviteDeletedStatusNotification(invite.clan)
        : web2ClientAPI.sendAcceptInviteErrorWithClanInfoNotification(invite.clan);
    dispatch(actionsAccount.decreaseActiveEntriesCount());
    return dispatch(actionsInvites.processingSuccess(invite.id));
  } else if (reason === 'clan_already_disbanded') {
    web2ClientAPI.sendAcceptInviteClanIsDisbandedErrorNotification(invite.clan);
  } else if (reason === 'clan_is_full') {
    web2ClientAPI.sendAcceptInviteClanIsFullErrorNotification(invite.clan);
  } else {
    web2ClientAPI.sendAcceptInviteErrorWithClanInfoNotification(invite.clan);
  }

  return dispatch(actionsInvites.processingFailed(invite.id));
};

export const acceptInvite =
  (invite: { id: number; clan: ClanInfoType }): AppAsyncThunk =>
  (dispatch, getState) => {
    const state = getState();
    const root = state.urls.root;
    if (state.invites.isFetching) {
      return;
    }
    dispatch(actionsInvites.toggleFetching());

    const url = `${state.urls.invites}${invite.id}/`;
    const options = {
      method: 'PATCH',
      body: { status: 'accepted' },
    };

    dispatch(actionsInvites.startProcessing(invite.id));
    return fetch(url, options).then(
      (json) => {
        if (json.title || json.status === 'error') {
          dispatch(actionsInvites.toggleFetching());
          return processAcceptError(dispatch, getState(), json, invite);
        }

        web2ClientAPI.sendAcceptInviteNotification(invite.clan);

        return syncForNewClan(dispatch, invite.clan.id, root, true).then(() => {
          dispatch(actionsInvites.toggleFetching());
        });
      },
      () => {
        dispatch(actionsInvites.toggleFetching());
        return processAcceptError(dispatch, getState(), null, invite);
      },
    );
  };

const processDeclineError = (dispatch: IAppDispatch, json, root, invite) => {
  const reason = json?.additional_info?.reason?.toLowerCase();
  const title = json?.title?.toLowerCase();

  if (reason === 'account_already_in_clan') {
    const clanId = get(json, 'additional_info.clan_id', null);
    return syncForNewClan(dispatch, clanId, root);
  } else if (reason === 'invite_is_not_active' || title === 'invite_is_not_active') {
    dispatch(actionsAccount.decreaseActiveEntriesCount());
    return dispatch(actionsInvites.processingSuccess(invite.id));
  } else {
    web2ClientAPI.sendDeclineInviteErrorNotification();
    return dispatch(actionsInvites.processingFailed(invite.id));
  }
};

export const declineInvite =
  (invite: Invite): AppAsyncThunk =>
  (dispatch, getState) => {
    const state = getState();
    const url = `${state.urls.invites}${invite.id}/`;
    const options = {
      method: 'PATCH',
      body: { status: 'declined' },
    };

    dispatch(actionsInvites.startProcessing(invite.id));
    return fetch(url, options)
      .then((json) => {
        if (json.status === 'error') {
          return processDeclineError(dispatch, json, state.urls.root, invite);
        }

        web2ClientAPI.sendDeclineInviteNotification(invite.clan);

        dispatch(actionsAccount.decreaseActiveEntriesCount());
        return dispatch(actionsInvites.processingSuccess(invite.id));
      })
      .catch(() => {
        return processDeclineError(dispatch, null, state.urls.root, invite);
      });
  };

export const fetchInvites =
  (withGlobalSpinner = false): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    if (state.invites.isFetching) {
      return;
    }

    let currentSeason = state.requests.requestsSeason;
    const battleType = state.requests.requestsBattleType;
    const clanSeasonType =
      battleType === BATTLE_TYPES.REGULAR_CVC
        ? SEASON_TYPES.REGULAR
        : battleType === BATTLE_TYPES.BRAWL_CVC
          ? SEASON_TYPES.BRAWL
          : null;

    dispatch(actionsInvites.toggleFetching());
    if (clanSeasonType && state.requests.requestsSeasonType !== clanSeasonType) {
      dispatch(actionsRequests.changeRequestsSeasonType(clanSeasonType));
      currentSeason = getLastSeasonOfType(clanSeasonType).id;
      dispatch(actionsRequests.changeRequestsSeason(currentSeason));
    }

    const currentAccount = state.currentAccount;
    const url = currentAccount.clanId ? state.urls.invitesClanList : state.urls.invitesAccountList;
    const limit = settings.entries.limit;
    const order = state.invites.sort.order;
    const query = {
      battle_type: clanSeasonType ? 'cvc' : state.requests.requestsBattleType,
      order: state.invites.sort.isAsc ? order : `-${order}`,
      offset: (state.invites.page - 1) * limit,
      season_id: clanSeasonType ? currentSeason : undefined,
      limit,
    };
    const promise = fetch(url, { query })
      .then((json) => {
        if (json.error) {
          return dispatch(actionsInvites.updateError(json));
        }
        const activeInvitesCount = get(json, '_meta_.total', currentAccount.activeInvitesCount);
        dispatch(actionsApp.updateAccountInfo({ activeInvitesCount }));
        return dispatch(actionsInvites.updateInvites(json));
      })
      .catch(() => {
        return dispatch(
          actionsInvites.updateError({
            error: t('Произошла ошибка. Повторите попытку позже'),
          }),
        );
      });

    return withGlobalSpinner ? promiseWithSpinner(dispatch, promise, t('Загружаем приглашения')) : promise;
  };

const processSendError = (dispatch: IAppDispatch, json: JSON, account, currentClan: ClanType, shouldFetchInvites) => {
  const reason = get(json, 'additional_info.reason');
  if (reason === 'account_does_not_meet_requirements') {
    web2ClientAPI.sendSendInviteAlreadySentNotification(account.name, currentClan);
    return shouldFetchInvites && dispatch(fetchInvites());
  } else if (reason === 'account_already_in_clan') {
    web2ClientAPI.sendSendInvitePlayerInOtherClanErrorNotification(account.name);
  } else if (reason === 'too_many_invites') {
    web2ClientAPI.sendSendInviteDailyInvitesLimitReachedErrorNotification(account.name);
  } else if (reason === 'account_banned') {
    web2ClientAPI.sendSendInvitePlayerHasPermanentBanErrorNotification(account.name);
  } else if (reason === 'insufficient_permissions') {
    const additionalInfo = get(json, 'additional_info');
    if (additionalInfo && additionalInfo.role) {
      web2ClientAPI.sendSendInvitePermissionsErrorNotification(account.name);
    } else {
      web2ClientAPI.sendSendInviteYouAreNotInClanNotification(account.name, currentClan);
    }
  } else if (reason === 'clan_is_full') {
    web2ClientAPI.sendSendInviteClanIsFullErrorNotification(account.name, currentClan);
  } else {
    web2ClientAPI.sendSendInviteErrorWithPlayerInfoNotification(account.name);
  }
};

export const sendInvite =
  (account: any, shouldFetchInvites = false): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const url = state.urls.invites;
    const currentClan = getCurrentClan(state);

    return promiseWithSpinner(
      dispatch,
      fetch(url, {
        method: 'POST',
        body: {
          recipient_id: account.id,
          source: 'clans_portal.recruitstation',
        },
      }).then(
        (json) => {
          if (json.status === 'error' || json.error || json.errors) {
            return processSendError(dispatch, json, account, currentClan, shouldFetchInvites);
          } else {
            web2ClientAPI.sendSendInviteNotification(account.name, currentClan);
            return shouldFetchInvites && dispatch(fetchInvites());
          }
        },
        () => {
          processSendError(dispatch, null, account, currentClan, shouldFetchInvites);
        },
      ),
    );
  };

export const cancelInvite =
  (inviteId: number): AppAsyncThunk =>
  (dispatch, getState) => {
    const state = getState();
    const url = `${state.urls.invites}${inviteId}/`;
    const options = {
      method: 'PATCH',
      body: { status: 'deleted' },
    };

    dispatch(actionsApp.startProcessing([inviteId]));
    return fetch(url, options)
      .then((json) => {
        if (json.status === 'error' || json.error || json.errors) {
          web2ClientAPI.sendCancelInviteErrorNotification();
          return dispatch(actionsInvites.processingFailed(inviteId));
        } else {
          web2ClientAPI.sendCancelInviteNotification();
          dispatch(fetchInvites());
          return dispatch(actionsApp.processingSuccess([inviteId]));
        }
      })
      .catch(() => {
        web2ClientAPI.sendCancelInviteErrorNotification();
        return dispatch(actionsInvites.processingFailed(inviteId));
      });
  };

export const joinClan =
  (clan: ClanType, invite: Invite, applicationSource: any): AppAsyncThunk =>
  (dispatch, getState) => {
    const invitePromise = isEmpty(invite)
      ? fetchActiveInviteFromClan(clan.id, getState().urls.activeInvite)
      : Promise.resolve({
          id: invite.id,
          clan,
        });
    return invitePromise.then((invite) => {
      if (invite) {
        return dispatch(showInviteAcceptDialog(invite));
      } else {
        return dispatch(showSendApplicationDialog(clan, applicationSource));
      }
    });
  };

const fetchActiveInviteFromClan = (clanId, url) =>
  fetch(url.replace('{clan_id}', clanId)).then(
    (json) => json._meta_ && json[json._meta_.model],
    () => {},
  );
