import * as React from 'react';
import { useEffect, useState } from 'react';
import { ErrorLoad, Table, TableBody, TableBodyCell, TableRow } from '@wg/wows-react-uikit';

import { scrollToY } from '~/helpers/animate';
import { t } from '~/helpers/localization';
import { getElementOffsetTop } from '~/helpers/position';

import StepCSSTransitionGroup from '~/Components/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import styles from './ClansRatingTable.scss';
import ClansRatingTableHead from './ClansRatingTableHead';
import ClansRatingTableItem from './ClansRatingTableItem';

import type { IClanRatingData } from '~/types/declaration';

const THIRD_CLAN_INDEX = 2;
const SCROLL_DELAY = 550;

type IClansRatingTable = {
  clanId?: number;
  clans: IClanRatingData[];
  isCrossRealmLadder?: boolean;
  stickyContainerId: string;
  currentLeague?: number;
  currentDivision?: number;
  isError?: boolean;
  isFetching?: boolean;
  errorMessage?: string;
  isOldSeason?: boolean;
  seasonType?: string;
  onDivisionChange: (division: number) => void;
  onLeagueChange: (league: number) => void;
  onClanClick: (clanId: number) => void;
};

const ClansRatingTable: React.FC<IClansRatingTable> = ({
  clanId,
  clans,
  currentDivision,
  currentLeague,
  errorMessage,
  isCrossRealmLadder,
  isError,
  isFetching,
  isOldSeason,
  onClanClick,
  onDivisionChange,
  onLeagueChange,
  seasonType,
  stickyContainerId,
}) => {
  const [fetching, setFetching] = useState<boolean>(isFetching || false);

  useEffect(() => {
    scrollToClan();
    setFetching(isError ? false : isFetching || false);
  }, [isError, isFetching]);

  const scrollToClan = () => {
    setTimeout(() => {
      const clanScrollEl = document.getElementById('clanScrollId');
      const appContainerEl = document.getElementById('app-container');
      const appEl = document.getElementById('app');

      if (!clanScrollEl || !appContainerEl || !appEl) {
        return;
      }

      const scrollTo =
        getElementOffsetTop(clanScrollEl) -
        getElementOffsetTop(appEl) -
        appContainerEl.offsetHeight / 2 -
        clanScrollEl.offsetHeight / 2;

      scrollToY(appEl, scrollTo);
    }, SCROLL_DELAY);
  };

  const onReloadClick = () => {
    if (currentLeague !== undefined) {
      onLeagueChange(currentLeague);
    }
    if (currentDivision !== undefined) {
      onDivisionChange(currentDivision);
    }
  };

  const renderEmptyTableRow = (item: number, nextItem: number | null) => {
    if (!nextItem) {
      return null;
    }
    if (item + 1 === nextItem || item === nextItem) {
      return null;
    }

    return (
      <TableRow>
        <TableBodyCell modify="center">
          <span>...</span>
        </TableBodyCell>
      </TableRow>
    );
  };

  const renderClansList = () => {
    if (isError) {
      return (
        <StepCSSTransitionGroup level={1}>
          <ErrorLoad isFlat message={errorMessage || ''} onReloadClick={onReloadClick} />
        </StepCSSTransitionGroup>
      );
    }

    if (!clans.length) {
      return (
        <StepCSSTransitionGroup level={1}>
          <ErrorLoad isFlat message={t('В данном дивизионе нет кланов.')} />
        </StepCSSTransitionGroup>
      );
    }

    const clansRows = clans.map((clan, index) => {
      const nextClan = clans[index + 1] ? clans[index + 1] : null;

      return (
        <StepCSSTransitionGroup key={clan.id} level={index + 1} duration={0.1} delay={0.05}>
          <div id={clanId === clan.id ? 'clanScrollId' : undefined}>
            <ClansRatingTableItem
              battlesCount={clan.battles_count}
              clanId={clan.id}
              isActive={clanId === clan.id}
              isDisbanded={clan.disbanded}
              isOldSeason={isOldSeason}
              lastBattle={clan.last_battle_at}
              lastWin={clan.last_win_at}
              membersCount={clan.members_count}
              name={clan.name}
              rank={clan.rank}
              rating={clan.division_rating}
              realm={clan.realm}
              seasonType={seasonType}
              tag={clan.tag}
              tagColor={clan.color}
              onClick={onClanClick}
            />
          </div>
          <div>{index === THIRD_CLAN_INDEX && nextClan ? renderEmptyTableRow(clan.rank, nextClan.rank) : null}</div>
        </StepCSSTransitionGroup>
      );
    });

    return (
      <StepCSSTransitionGroup level={1}>
        <TableBody key="table-body">{clansRows}</TableBody>
      </StepCSSTransitionGroup>
    );
  };

  return (
    <div className={styles.container}>
      <Table isFetching={fetching} stickyContainerId={stickyContainerId}>
        <ClansRatingTableHead
          isCrossRealmLadder={isCrossRealmLadder}
          isOldSeason={isOldSeason}
          stickyContainerId={stickyContainerId}
          seasonType={seasonType}
        />
        {renderClansList()}
      </Table>
    </div>
  );
};

export default ClansRatingTable;
