import { connect } from 'react-redux';
import { playButtonClickSound } from '@wg/web2clientapi/sound';

import settings from '~/settings';
import { REQUESTS_TABS } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { clanIsFull } from '~/helpers/clan';
import {
  showInviteAcceptDialog,
  showInviteCancelDialog,
  showRecommendationsClanSettingsDialog,
  showRulesDialog,
  showSendApplicationDialog,
} from '~/helpers/dialogs';
import { openAccountProfile, openChatWindow } from '~/web2ClientAPI/base';
import {
  acceptApplication,
  actionsApp,
  declineApplication,
  declineApplications,
  fetchApplications,
} from '~/Actions/ActionApp';
import { fetchClan } from '~/Actions/ActionClanProfile';
import { actionsInvites, declineInvite, fetchInvites, sendInvite } from '~/Actions/ActionInvites';
import { actionsRequests } from '~/Actions/ActionRequests';

import Requests from '~/Components/Requests/Requests';

import type { IApplication } from '~/Actions/ActionApp';
import type { ClanType, Invite } from '~/Actions/ActionInvites';
import type { IAppDispatch, RootState } from '~/store';

const mapStateToProps = (state: RootState) => {
  const currentAccountClanId = state.currentAccount.clanId;
  const currentClan = state.clans.items[currentAccountClanId];
  const clanLoaded = currentAccountClanId in state.clans.items;

  return {
    root: state.urls.root,
    battleTypes: settings.battleTypes,
    requestsBattleType: state.requests.requestsBattleType,
    requestsSeason: state.requests.requestsSeason,
    requestsSeasonType: state.requests.requestsSeasonType,
    currentAccount: state.currentAccount,
    clan: currentClan,

    isCurrentClanFull: currentClan && clanIsFull(currentClan),
    clanLoaded,
    invitesEntries: state.invites,
    applicationsEntries: state.applications,
    currentRequestsTab: state.requests.currentRequestsTab,
    selectedApplicationsPlayersIds: state.applications.selectedApplicationsPlayersIds,
  };
};

const mapDispatchToProps = (dispatch: IAppDispatch) => {
  return {
    onRender: (clanId: number) => dispatch(fetchClan(clanId)),
    fetchEntriesByCurrentState: (withGlobalSpinner: boolean, tab: REQUESTS_TABS) => {
      if (tab === REQUESTS_TABS.INVITES) {
        dispatch(fetchInvites(withGlobalSpinner));
      } else {
        void dispatch(fetchApplications(withGlobalSpinner));
      }
    },
    onReloadClick: () => {
      dispatch(fetchInvites());
      void dispatch(fetchApplications());
    },

    onSendInvite: (account: any) => dispatch(sendInvite(account, true)),
    sendApplication: (clan: ClanType) => {
      const source = 'recruitstation';
      const shouldFetchApplications = true;
      dispatch(showSendApplicationDialog(clan, source, shouldFetchApplications));
    },
    onSeasonChange: (selectedSeason: number, tab: REQUESTS_TABS) => {
      dispatch(actionsRequests.changeRequestsSeason(selectedSeason));
      if (tab === REQUESTS_TABS.INVITES) {
        dispatch(fetchInvites());
      } else {
        void dispatch(fetchApplications());
      }
    },
    onBattleTypeChange: (selectedBattleType: string, tab: REQUESTS_TABS) => {
      dispatch(actionsRequests.changeRequestsBattleType(selectedBattleType));
      if (tab === REQUESTS_TABS.INVITES) {
        dispatch(fetchInvites());
      } else {
        void dispatch(fetchApplications());
      }
    },
    onAcceptedEntryInvites: (invite: Invite) => dispatch(showInviteAcceptDialog(invite)),
    onShowCancelInvite: (invite: Invite) => dispatch(showInviteCancelDialog(invite)),
    onDeclinedEntryInvites: (invite: Invite) => dispatch(declineInvite(invite)),

    onApplicationsPlayerTick: (playerId: number) => dispatch(actionsApp.toggleApplicationsPlayerTick(playerId)),
    onAllApplicationsPlayersTick: (playerIds: Array<number>) =>
      dispatch(actionsApp.toggleAllApplicationsPlayersTick(playerIds)),
    onAcceptedEntryApplications: (application: IApplication) => dispatch(acceptApplication(application)),
    onDeclinedEntryApplication: (application: IApplication) => dispatch(declineApplication(application)),
    onDeclineApplications: () => dispatch(declineApplications()),

    fetchEntriesByPageInvites: (page: number) => {
      dispatch(actionsInvites.changePage(page));
      dispatch(fetchInvites());
    },
    fetchEntriesByPageApplications: (page: number) => {
      dispatch(actionsApp.changePage(page));
      void dispatch(fetchApplications());
      dispatch(actionsApp.toggleAllApplicationsPlayersTick());
    },
    onSortClickInvites: (order: string, isAsc: boolean) => {
      dispatch(actionsInvites.changeOrder(order, isAsc));
      dispatch(fetchInvites());
    },
    onSortClickApplications: (order: string, isAsc: boolean) => {
      dispatch(actionsApp.changeOrder(order, isAsc));
      void dispatch(fetchApplications());
    },

    onRulesDialogClick: () => dispatch(showRulesDialog()),
    openAccountProfile,
    openChatWindow,
    onToggleTab: (value: REQUESTS_TABS, canHandleInvites: boolean) => {
      if (value === REQUESTS_TABS.INVITES) {
        if (canHandleInvites) {
          dwhExport.push(DWH_EVENTS.REQUESTS.CLAN.VIEW_INVITES);
        } else {
          dwhExport.push(DWH_EVENTS.REQUESTS.PLAYER.VIEW_INVITES);
        }
      } else {
        if (canHandleInvites) {
          dwhExport.push(DWH_EVENTS.REQUESTS.CLAN.VIEW_REQUESTS);
        } else {
          dwhExport.push(DWH_EVENTS.REQUESTS.PLAYER.VIEW_REQUESTS);
        }
      }
      dispatch(actionsRequests.changeRequestsTabs(value));
    },
    onRecommendationsClanSettingsClick: () => {
      void playButtonClickSound();
      dispatch(showRecommendationsClanSettingsDialog());
    },
    dropSelection: () => {
      dispatch(actionsApp.dropSelection());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Requests);
