import classnames from 'classnames';
import * as React from 'react';
import { shallowEqual } from 'react-redux';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';
import { playButtonClickSound, playPopupOpenSound } from '@wg/web2clientapi/sound';
import { Dialog } from '@wg/wows-react-uikit';

import { DIALOGS_NAMES, DIALOGS_THEMES } from '~/constants';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { useAppDispatch, useAppSelector } from '~/store';
import { hideDialog } from '~/store/slices/dialogsSlice';
import stylesCommon from '~/stylesheets/globals/_wows-react-uikit.scss';

import CStarsRefinedInfoDialog from '~/pages/ClanStars/CStarsRefinedInfoDialog/CStarsRefinedInfoDialog';
import CStarsRewardsDialog from '~/pages/ClanStars/CStarsRewardsDialog/CStarsRewardsDialog';
import DistributionDialog from '~/pages/Treasury/DistributionDialog/DistributionDialog';
import TransactionDetailDialog from '~/pages/Treasury/TransactionDetailDialog/TransactionDetailDialog';
import ConfirmDialogContent from '~/pages/TreasuryRewards/RegularRewards/ConfirmDialogContent';
import ClanSettingsDialog from '~/Components/RecommendationsCards/ClanSettingsDialog/ClanSettingsDialog';
import LanguagesDialog from '~/Components/RecommendationsCards/LanguagesDialog/LanguagesDialog';
import CWarsAttemptsDialog from '~/Components/ViewClanWars/CWarsAttemptsDialog/CWarsAttemptsDialog';
import CWarsCalendarDialog from '~/Components/ViewClanWars/CWarsCalendarDialog/CWarsCalendarDialog';
import CWarsHistoryDialog from '~/Components/ViewClanWars/CWarsHistoryDialog/CWarsHistoryDialog';
import CWarsStatsDialog from '~/Components/ViewClanWars/CWarsStatsDialog/CWarsStatsDialog';
import ClanInfoDialog from '~/UIKit/ClanInfoDialog/ClanInfoDialog';
import ClanLeaveDialog from '~/UIKit/ClanLeaveDialog/ClanLeaveDialog';
import ClanNameEditDialog from '~/UIKit/ClanNameEditDialog/ClanNameEditDialog';
import ClansInformerDialog from '~/UIKit/ClansInformerDialog/ClansInformerDialog';
import ExternalNavigationDialog from '~/UIKit/ExternalNavigationDialog/ExternalNavigationDialog';
import InviteAcceptDialog from '~/UIKit/InviteAcceptDialog/InviteAcceptDialog';
import InviteCancelDialog from '~/UIKit/InviteCancelDialog/InviteCancelDialog';
import ChangeCommanderOperationDialog from '~/UIKit/MembersOperationDialogs/ChangeCommanderOperationDialog';
import ChangeRoleOperationDialog from '~/UIKit/MembersOperationDialogs/ChangeRoleOperationDialog';
import RemoveMembersOperationDialog from '~/UIKit/MembersOperationDialogs/RemoveMembersOperationDialog';
import MonumentAchievementsDialog from '~/UIKit/MonumentAchievementsDialog/MonumentAchievementsDialog';
import RulesDialog from '~/UIKit/RulesDialog/RulesDialog';
import SendApplicationDialog from '~/UIKit/SendApplicationDialog/SendApplicationDialog';

import type { AppStateType as RootState } from '~/Reducers';

const DIALOGS_COMPONENTS = {
  [DIALOGS_NAMES.CLAN_INFO_DIALOG]: ClanInfoDialog,
  [DIALOGS_NAMES.CLAN_NAME_EDIT_DIALOG]: ClanNameEditDialog,
  [DIALOGS_NAMES.CLAN_LEAVE_DIALOG]: ClanLeaveDialog,
  [DIALOGS_NAMES.CLANS_INFORMER_DIALOG]: ClansInformerDialog,
  [DIALOGS_NAMES.RULES_DIALOG]: RulesDialog,
  [DIALOGS_NAMES.EXTERNAL_NAVIGATION_DIALOG]: ExternalNavigationDialog,
  [DIALOGS_NAMES.SEND_APPLICATION_DIALOG]: SendApplicationDialog,
  [DIALOGS_NAMES.INVITE_ACCEPT_DIALOG]: InviteAcceptDialog,
  [DIALOGS_NAMES.INVITE_CANCEL_DIALOG]: InviteCancelDialog,
  [DIALOGS_NAMES.MONUMENT_ACHIEVEMENTS_DIALOG]: MonumentAchievementsDialog,
  [DIALOGS_NAMES.RECOMMENDATIONS_CLAN_SETTINGS_DIALOG]: ClanSettingsDialog,
  [DIALOGS_NAMES.LANGUAGES_DIALOG]: LanguagesDialog,
  [DIALOGS_NAMES.MEMBERS_OPERATION_REMOVE]: RemoveMembersOperationDialog,
  [DIALOGS_NAMES.MEMBERS_OPERATION_CHANGE_COMMANDER]: ChangeCommanderOperationDialog,
  [DIALOGS_NAMES.MEMBERS_OPERATION_CHANGE_ROLE]: ChangeRoleOperationDialog,
  [DIALOGS_NAMES.DISTRIBUTION_DIALOG]: DistributionDialog,
  [DIALOGS_NAMES.DISTRIBUTION_RESULT_DIALOG]: TransactionDetailDialog,
  [DIALOGS_NAMES.DISTRIBUTION_REWARD_CONFIRM_DIALOG]: ConfirmDialogContent,
  [DIALOGS_NAMES.CSTARS_REWARDS_DIALOG]: CStarsRewardsDialog,
  [DIALOGS_NAMES.CSTARS_REFINED_INFO_DIALOG]: CStarsRefinedInfoDialog,
  [DIALOGS_NAMES.CWARS_CALENDAR_DIALOG]: CWarsCalendarDialog,
  [DIALOGS_NAMES.CWARS_STATS_DIALOG]: CWarsStatsDialog,
  [DIALOGS_NAMES.CWARS_HISTORY_DIALOG]: CWarsHistoryDialog,
  [DIALOGS_NAMES.CWARS_ATTEMPTS_DIALOG]: CWarsAttemptsDialog,
};

type IDialogsProps = InferFCProps<(typeof DIALOGS_COMPONENTS)[keyof typeof DIALOGS_COMPONENTS]>;
type IDialogsPropsStates = {
  [K in keyof typeof DIALOGS_COMPONENTS]: { name: K } & Pick<
    InferFCProps<(typeof DIALOGS_COMPONENTS)[K]>,
    'data' | 'options'
  >;
};
export type IDialogTypeState = IDialogsPropsStates[keyof IDialogsPropsStates];

interface IContainerDialog {
  dialog: IDialogTypeState;
  isActive: boolean;
  hideDialog: () => void;
}

const ContainerDialog = (props: IContainerDialog) => {
  let content: React.ReactNode;

  const Component = DIALOGS_COMPONENTS[props.dialog.name] as React.FC<IDialogsProps>;
  if (Component) {
    content = <Component data={props.dialog?.data} options={props.dialog?.options} hideDialog={props.hideDialog} />;
  }

  if (!content) {
    return null;
  }

  void playPopupOpenSound();

  return (
    <Dialog
      className={classnames({
        [stylesCommon.dialog]: props.dialog?.options?.theme === DIALOGS_THEMES.BLUE,
      })}
      content={content}
      size={props.dialog?.options?.size || 'overlay'}
      isOverlay={props.dialog?.options?.isOverlay || false}
      isVisible={props.isActive || false}
      hideDialog={() => null}
    />
  );
};

const ContainerDialogMemoized = React.memo(ContainerDialog);

/**
 * DialogsRoot
 * wows-react-uikit requires to provide root element for modals portal
 *  */
const DialogsRoot = React.memo(function DialogsRoot() {
  return (
    <div
      id="modal-root"
      style={{
        zIndex: 999,
        position: 'absolute',
      }}
    />
  );
});

const stateSelector = (state: RootState) => {
  return {
    dialogs: state.dialogs.dialogs,
  };
};

const DialogsManager = () => {
  const { dialogs } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const closeDialog = React.useCallback(() => {
    dispatch(hideDialog());
  }, [dispatch]);

  React.useEffect(() => {
    void updateBrowserControlState(!!dialogs.length);
  }, [dialogs.length]);

  useKeyDown(() => {
    if (!dialogs.length) {
      return;
    }

    void playButtonClickSound();

    window.tooltipProvider?.hide?.();
    closeDialog();
  }, [KEYS_CODE.ESC]);

  return (
    <>
      {dialogs.map((dialog: IDialogTypeState, index: number) => (
        <ContainerDialogMemoized
          key={`dialog_${dialog.name}_${index}`}
          dialog={dialog}
          isActive={index >= dialogs.length - 2}
          hideDialog={closeDialog}
        />
      ))}
      <DialogsRoot />
    </>
  );
};

export default React.memo(DialogsManager);
