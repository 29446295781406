import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { DivTooltip, TooltipBody } from '@wg/wows-react-uikit';

import { thousands } from '~/helpers/formatting';
import { t } from '~/helpers/localization';

import styles from '~/Components/ViewClanWars/ProgressBar/ProgressBar.scss';
import { COMPETITION_METRIC } from '~/UIKit';

export type ProgressBar_Props = {
  breakpoints: Array<any>;
  breakpointRender: (item, index) => any;
  progressTooltip: any;
  total: number;
  maxValue: number;
  totalValue: number;
  myValue: number;
  color?: string;

  mPoints: string;
};

export const getProgressPercent = (value: number, total: number) => {
  const p = (value / total) * 100;
  return p > 100 ? 100 : p;
};

export const getMetric = (competitionMetric: string) => {
  switch (competitionMetric) {
    case COMPETITION_METRIC.EXP: {
      return t('опыта');
    }
    case COMPETITION_METRIC.DAMAGE: {
      return t('урона');
    }
    case COMPETITION_METRIC.RIBBONS: {
      return t('лент');
    }
    default: {
      return t('опыта');
    }
  }
};

class RawProgressBar extends PureComponent<ProgressBar_Props> {
  render() {
    const color = this.props.color ? this.props.color : '#C97D21';
    return (
      <div className={styles.lineWrapper}>
        <div className={styles.topSignWrapper}>
          <span className={styles.topSign}>{this.props.mPoints}</span>
          <div className={styles.rightSign}>
            <span className={styles.topSignTotal} style={{ color }}>
              {thousands(this.props.total)}
            </span>
            <span className={styles.topSign}>/{thousands(this.props.maxValue)}</span>
          </div>
        </div>
        <div className={styles.progressLine}>
          <DivTooltip
            style={{
              width: `${getProgressPercent(this.props.totalValue, this.props.maxValue)}%`,
            }}
            className={styles.totalProgress}
            tooltipBody={<TooltipBody>{this.props.progressTooltip}</TooltipBody>}
          >
            <div className={styles.lineInnerContent} style={{ backgroundColor: color }}>
              {this.props.myValue ? (
                <div
                  className={styles.myProgressLine}
                  style={{
                    width: `${getProgressPercent(this.props.myValue, this.props.totalValue)}%`,
                  }}
                />
              ) : null}
            </div>
          </DivTooltip>
          {this.props.breakpoints && this.props.breakpoints.length > 0
            ? this.props.breakpoints.map((item, index) => {
                const rightOffsetPercent = 100 - getProgressPercent(item.value, this.props.maxValue);
                const isReachedGrade = item.value <= this.props.totalValue;

                return (
                  <div
                    key={`Separator_${index}`}
                    className={`${styles.separator} ${isReachedGrade ? styles.separatorReached : ''}`}
                    style={{ right: `${rightOffsetPercent}%` }}
                  />
                );
              })
            : null}
        </div>
        {this.props.breakpoints && this.props.breakpoints.length > 0 ? (
          <div className={styles.breakpointsWrapper}>{this.props.breakpoints.map(this.props.breakpointRender)}</div>
        ) : null}
      </div>
    );
  }
}

export default translate()(RawProgressBar);
