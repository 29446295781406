import classNames from 'classnames';
import React from 'react';
import { Interpolate } from 'react-i18next';
import { Message } from '@wg/wows-react-uikit';

import { t, x } from '~/helpers/localization';
import { isClanDescriptionOnPreModeration } from '~/helpers/moderation';
import stylesCommon from '~/stylesheets/globals/_wows-react-uikit.scss';

import styles from './ClanDescription.scss';

import type { IClan } from '~/Reducers/ReducerSupply';

interface IClanDescription {
  clan: IClan;
}

const ClanDescriptionPreModeraion: React.FC<IClanDescription> = ({ clan }) => {
  let preModerationMessageTemplate = x(
    'Вы не можете изменить %(pre_moderation_property_name)s, пока профиль вашего клана находится на премодерации.',
  );
  let preModerationMessageProperty = t('описание клана:pre_moderation_property_name');
  if (isClanDescriptionOnPreModeration(clan)) {
    preModerationMessageTemplate = x(
      '%(pre_moderation_property_name)s вашего клана обновится, как только оно будет одобрено модератором.',
    );
    preModerationMessageProperty = t('Описание:pre_moderation_property_name');
  }

  return (
    <div className={classNames(styles.onPremoderationDisclaimer, stylesCommon.dividerTop)}>
      <Message style={'inline'} type="error">
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={preModerationMessageTemplate}
          parent={'p'}
          pre_moderation_property_name={preModerationMessageProperty}
          t={t}
        />
      </Message>
    </div>
  );
};

export default ClanDescriptionPreModeraion;
