import { t } from '~/helpers/localization';

import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';

/*
This permissions are equal to clans.roles permissions.
*/
export enum PERMISSIONS {
  RENAME_CLAN = 'edit_info',
  CHANGE_SETTINGS = 'change_settings',
  CHANGE_ROLE = 'change_role',
  CHANGE_COMMANDER = 'change_commander',
  HANDLE_INVITES = 'handle_invites',
  INVITE = 'invite',
  REMOVE_MEMBER = 'remove_member',
  REMOVE_CLAN = 'remove_clan',
  SUPPLY_VIEW_CLAN_ADMITTANCE = 'supply_view_clan_admittance',
  SUPPLY_APPLY_MODIFIER = 'supply_apply_modifier',
  CWARS_TOGGLE_PARTICIPATION = 'cwars_toggle_participation',
}

export enum ROLE_NAMES {
  COMMANDER = 'commander',
  EXECUTIVE_OFFICER = 'executive_officer',
  RECRUITMENT_OFFICER = 'recruitment_officer',
  COMMISSIONED_OFFICER = 'commissioned_officer',
  OFFICER = 'officer',
  PRIVATE = 'private',
}

const roles = [
  {
    name: ROLE_NAMES.COMMANDER,
    rank: 10,
    localized_name: t('Командующий флотом'),
    order: 0,
    permissions: [
      PERMISSIONS.RENAME_CLAN,
      PERMISSIONS.CHANGE_SETTINGS,
      PERMISSIONS.CHANGE_ROLE,
      PERMISSIONS.CHANGE_COMMANDER,
      PERMISSIONS.HANDLE_INVITES,
      PERMISSIONS.INVITE,
      PERMISSIONS.REMOVE_MEMBER,
      PERMISSIONS.REMOVE_CLAN,
      PERMISSIONS.SUPPLY_VIEW_CLAN_ADMITTANCE,
      PERMISSIONS.SUPPLY_APPLY_MODIFIER,
      PERMISSIONS.CWARS_TOGGLE_PARTICIPATION,
    ],
  },
  {
    name: ROLE_NAMES.EXECUTIVE_OFFICER,
    rank: 9,
    localized_name: t('Заместитель командующего'),
    order: 1,
    permissions: [
      PERMISSIONS.RENAME_CLAN,
      PERMISSIONS.CHANGE_SETTINGS,
      PERMISSIONS.CHANGE_ROLE,
      PERMISSIONS.HANDLE_INVITES,
      PERMISSIONS.INVITE,
      PERMISSIONS.REMOVE_MEMBER,
      PERMISSIONS.SUPPLY_VIEW_CLAN_ADMITTANCE,
      PERMISSIONS.SUPPLY_APPLY_MODIFIER,
      PERMISSIONS.CWARS_TOGGLE_PARTICIPATION,
    ],
  },
  {
    name: ROLE_NAMES.RECRUITMENT_OFFICER,
    rank: 5,
    localized_name: t('Военком'),
    order: 5,
    permissions: [PERMISSIONS.HANDLE_INVITES, PERMISSIONS.INVITE],
  },
  {
    name: ROLE_NAMES.COMMISSIONED_OFFICER,
    rank: 0,
    localized_name: t('Кадровый офицер'),
    order: 8,
    permissions: [],
  },
  {
    name: ROLE_NAMES.OFFICER,
    rank: 0,
    localized_name: t('Офицер'),
    order: 9,
    permissions: [],
  },
  {
    name: ROLE_NAMES.PRIVATE,
    rank: 0,
    localized_name: t('Курсант'),
    order: 10,
    permissions: [],
  },
];

export const getLocalizedName = (roleName: ROLE_NAMES) => {
  const role = roles.find((role) => role.name === roleName);
  return role ? role.localized_name : '';
};

export const iHavePermission = (permission: PERMISSIONS, currentAccount: ICurrentAccountState) => {
  if (currentAccount) {
    return hasPermission(currentAccount.roleName, permission);
  } else {
    return false;
  }
};

export const hasPermission = (roleName: Nullable<ROLE_NAMES>, permission: PERMISSIONS) => {
  if (!roleName) {
    return false;
  }
  return getPermissions(roleName).includes(permission);
};

export const hasAnyPermission = (roleName: ROLE_NAMES, permissions: PERMISSIONS[]) => {
  const rolePermissions = getPermissions(roleName);
  return permissions.some((permission) => rolePermissions.includes(permission));
};

const getPermissions = (roleName: ROLE_NAMES) => {
  const role = roles.find((role) => role.name === roleName);
  return role ? role.permissions : [];
};

export const getRank = (roleName: ROLE_NAMES) => {
  const role = roles.find((role) => role.name === roleName);
  return role ? role.rank : -1;
};

export const getOrder = (roleName: ROLE_NAMES) => {
  const role = roles.find((role) => role.name === roleName);
  return role ? role.order : 100;
};

export const getAssignableRoles = (roleName: ROLE_NAMES) => {
  const currentRoleRank = getRank(roleName);
  return roles.filter((role) => role.rank < currentRoleRank).map((role) => role.name);
};

export const getFirstAssignableRole = (roleName: ROLE_NAMES) => {
  const currentRoleRank = getRank(roleName);
  return roles.find((role) => role.rank < currentRoleRank)?.name;
};
