import { sendCommand } from '@wg/web2clientapi/core/command';

import { t } from '~/helpers/localization';

import { sendNotification } from './base';
import { wrapInP } from './formatting';

import type { ExtraBuildingsDataInterface } from '~/Actions/ActionSupply';

export const sendBuildSuccessNotification = (data: {
  cost: number;
  title: string;
  level: number;
  maxLevel: number;
  message: string;
}) => {
  const header = t('Улучшение %(level)s из %(maxLevel)s построено', {
    level: data.level,
    maxLevel: data.maxLevel,
  });

  sendNotification({
    hasHeader: true,
    header: data.title,
    message: wrapInP(`${header}&#13;`, data.message),
    cost: data.cost,
    currency: 'clan_resource',
  });
};

export const ClanBaseSoundObjectPos = (data: { name: keyof ExtraBuildingsDataInterface; x: string; y: string }[]) => {
  void sendCommand({
    command: 'ClanBaseSoundObjectPos',
    params: data,
  });
};

export const ClanBaseCameraPos = (position: { x: number; y: number; z: number }) => {
  void sendCommand({
    command: 'ClanBaseCameraPos',
    params: position,
  });
};
