import get from 'lodash/get';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DIALOGS_NAMES, DIALOGS_THEMES } from '~/constants';
import {
  closeAllDialogs,
  showClanInfoDialog,
  showClanLeaveDialog,
  showInviteAcceptDialog,
  showNameEditDialog,
  showRecommendationsClanSettingsDialog,
  showRulesDialog,
  showSendApplicationDialog,
} from '~/helpers/dialogs';
import { getLastRegularSeason, getSeasonById } from '~/helpers/ladder';
import { getClanBaseByClanId } from '~/store/selectors/supplySelectors';
import { hideDialog } from '~/store/slices/dialogsSlice';
import { actionsClanProfile, fetchClan, fetchMembers } from '~/Actions/ActionClanProfile';

import ViewClanProfile from '~/Components/ViewClanProfile/ViewClanProfile';

import { clanSelector, clanStatisticsSelector, statisticsSelector } from './selectors/SelectorClanProfile';

import type { ClanType, Invite } from '~/Actions/ActionInvites';
import type { ViewClanProfile_Props } from '~/Components/ViewClanProfile/ViewClanProfile';
import type { AppStateType as State } from '~/Reducers';
import type { IAppDispatch } from '~/store';

interface PropsType extends ViewClanProfile_Props {
  onRender: (clanId: number) => void;
  closeDialogs: () => void;
  clanId: number;
  params: any;
}

class ContainerClanProfile extends PureComponent<PropsType, any> {
  componentDidMount() {
    this.props.onRender(this.props.clanId);
  }

  componentWillUnmount() {
    this.props.closeDialogs();
  }

  render() {
    return (
      <ViewClanProfile
        clan={this.props.clan}
        clanId={this.props.clanId}
        currentAccount={this.props.currentAccount}
        currentSeasonType={this.props.currentSeasonType}
        currentSeason={this.props.currentSeason}
        statistics={this.props.statistics}
        selectedBattleType={this.props.selectedBattleType}
        selectedTeamNumber={this.props.selectedTeamNumber}
        hasBase={this.props.hasBase}
        membersError={this.props.membersError}
        isHiddenStatistics={this.props.isHiddenStatistics}
        isMissingStatistics={this.props.isMissingStatistics}
        isFetchingJoinClan={this.props.isFetchingJoinClan}
        isOwn={this.props.isOwn}
        routeQuery={this.props.routeQuery}
        isSidebarOpened={this.props.isSidebarOpened}
        wowsLadder={this.props.wowsLadder}
        supplySelectedBuilding={this.props.supplySelectedBuilding}
        isMapZoomed={this.props.isMapZoomed}
        externalNavigationLink={this.props.externalNavigationLink}
        clanRulesContent={this.props.clanRulesContent}
        hideLeagueIcon={this.props.hideLeagueIcon}
        accumulativeResource={this.props.accumulativeResource}
        lastRegularSeasonData={this.props.lastRegularSeasonData}
        onNameEditClick={this.props.onNameEditClick}
        onSeasonChange={this.props.onSeasonChange}
        onBattleTypeChange={this.props.onBattleTypeChange}
        onTeamNumberChange={this.props.onTeamNumberChange}
        onMembersReloadClick={this.props.onMembersReloadClick}
        onClanReloadClick={this.props.onClanReloadClick}
        onClanInfoClick={this.props.onClanInfoClick}
        onClanInfoEditClick={this.props.onClanInfoEditClick}
        onAcceptInvite={this.props.onAcceptInvite}
        onSendApplication={this.props.onSendApplication}
        onRulesDialogClick={this.props.onRulesDialogClick}
        openRecommendationsDialog={this.props.openRecommendationsDialog}
        closeDialogs={this.props.closeDialogs}
        onRecommendationsClanSettingsClick={this.props.onRecommendationsClanSettingsClick}
        onLeaveClick={this.props.onLeaveClick}
        openLeaveDialog={this.props.openLeaveDialog}
      />
    );
  }
}

const mapStateToProps = (state: State, ownProps: PropsType) => {
  const extClanId = ownProps.params && ownProps.params.clanId ? parseInt(ownProps.params.clanId, 10) : null;
  const clanId = extClanId ? extClanId : state.currentAccount.clanId;
  const isOwn = !extClanId && state.currentAccount.clanId;
  const clan = clanSelector(state, clanId);
  const currentClanStatistics = clanStatisticsSelector(state, clanId);
  const isMissingStatistics = currentClanStatistics ? currentClanStatistics.isMissingStatistics : false;
  const isHiddenStatistics = currentClanStatistics ? currentClanStatistics.isHiddenStatistics : false;
  const base = getClanBaseByClanId(state, clanId);
  const membersState = state.members.clans[clanId];
  const membersError = membersState ? membersState.error : '';

  let hideLeagueIcon = false;
  const lastRegularSeason = getLastRegularSeason();
  let lastRegularSeasonData = {};

  if (state.clans.wowsLadder[clanId]) {
    state.clans.wowsLadder[clanId].ratings.forEach((season) => {
      const lastRegularSeasonId = lastRegularSeason ? lastRegularSeason.id : null;

      if (season.is_best_season_rating && season.season_number === lastRegularSeasonId) {
        lastRegularSeasonData = season;
        if (
          season.battles_count === 0 &&
          season.public_rating <= state.clans.wowsLadder[clanId].initial_public_rating
        ) {
          hideLeagueIcon = true;
        }
      }
    });
  }

  if (!lastRegularSeason) {
    hideLeagueIcon = true;
  }

  const currentSeason = getSeasonById(state.members.currentSeason) || {};
  const currentClanLadderInfo = state.clans.items[state.currentAccount.clanId];

  return {
    accumulativeResource: currentClanLadderInfo ? currentClanLadderInfo.accumulativeResource : 0,
    currentAccount: state.currentAccount,
    clanId,
    clan,
    isOwn,

    hideLeagueIcon: hideLeagueIcon,
    lastRegularSeasonData: lastRegularSeasonData,

    isFetchingJoinClan: state.applications.isFetchingJoinClan,
    isFetchingMembers: state.members.isFetching[clanId],
    isHiddenStatistics,
    isMissingStatistics,
    isRenameInProcess: state.clanRename.isProcessing,

    statistics: statisticsSelector(state, clanId),
    selectedBattleType: state.members.battleTypes[clanId],
    selectedTeamNumber: state.members.teamNumber[clanId],
    wowsLadder: state.clans.wowsLadder,
    currentSeasonType: currentSeason.kind,
    currentSeason: state.members.currentSeason,

    supplySelectedBuilding: state.ReducerSupply.selectedBuildingId,
    isMapZoomed: state.ReducerSupply.zoom > 6,
    isSidebarOpened: state.ReducerSupply.isSidebarOpened,
    base,
    membersError,
  };
};

const mapDispatchToProps = (dispatch: IAppDispatch, ownProps) => {
  const extClanId = ownProps.params && ownProps.params.clanId ? parseInt(ownProps.params.clanId, 10) : null;

  return {
    onSeasonChange: (season: number, clanId: number) => {
      const currentClanId = clanId ? clanId : ownProps.clanId;
      dispatch(actionsClanProfile.setClanProfileCurrentSeason(season));
      dispatch(fetchMembers(currentClanId));
    },
    onTeamNumberChange: (selectedTeamNumber: { [team: string]: any }, clanId: number) => {
      const currentClanId = clanId ? clanId : ownProps.clanId;
      dispatch(actionsClanProfile.changeClanTeamNumber(currentClanId, selectedTeamNumber));
      dispatch(fetchMembers(currentClanId));
    },
    onSendApplication: (clan: ClanType) => {
      const sourceQuery = get(ownProps, 'location.query.source', null);

      const source = sourceQuery || ownProps.source || 'profile';

      dispatch(showSendApplicationDialog(clan, source));
    },
    onAcceptInvite: (invite: Invite) => {
      dispatch(hideDialog());
      dispatch(showInviteAcceptDialog(invite));
    },
    openLeaveDialog: () => {
      dispatch(showClanLeaveDialog());
    },
    onRecommendationsClanSettingsClick: () => {
      dispatch(showRecommendationsClanSettingsDialog());
    },
    openRecommendationsDialog: () => {
      dispatch(showRecommendationsClanSettingsDialog());
    },
    onLeaveClick: () => {
      dispatch(showClanLeaveDialog());
    },
    onRulesDialogClick: () => {
      dispatch(showRulesDialog());
    },
    onNameEditClick: () => {
      dispatch(showNameEditDialog());
    },
    onClanInfoClick: (clanId: number) => {
      const sourceQuery: Nullable<string> = ownProps?.location?.query.source || null;
      const source: string = sourceQuery || ownProps.source || 'profile';

      dispatch(showClanInfoDialog({ clanId, source, isOpenedFromClanBase: true }));
    },
    onClanInfoEditClick: (clanId: number) => {
      dispatch(showClanInfoDialog({ clanId, isInEditMode: true, isOpenedFromClanBase: true }));
    },
    closeDialogs: () => {
      dispatch(closeAllDialogs());
    },
    onThSortClick: (field: string, isAsc: boolean, hiddenSortingNames: Array<string>, clanId: number) => {
      const currentClanId = clanId ? clanId : ownProps.clanId;
      dispatch(actionsClanProfile.toggleMembersSorting(currentClanId, field, isAsc, hiddenSortingNames));
    },
    onBattleTypeChange: (selectedBattleType: { [battle: string]: any }, clanId: number) => {
      const currentClanId = clanId ? clanId : extClanId;
      dispatch(actionsClanProfile.changeClanBattleType(currentClanId, selectedBattleType));
      dispatch(fetchMembers(currentClanId));
    },
    onMembersReloadClick: (clanId: number) => {
      const currentClanId = clanId ? clanId : ownProps.clanId;
      dispatch(fetchMembers(currentClanId));
    },
    onRender: (clanId: number) => {
      dispatch(fetchClan(clanId));
      dispatch(fetchMembers(clanId, true));
    },
    onClanReloadClick: () => {
      dispatch(fetchClan(ownProps.clanId, false));
      dispatch(fetchMembers(ownProps.clanId));
    },
    onTickMember: (member: number) => {
      dispatch(actionsClanProfile.toggleMemberTick(member));
    },
    onTickAllMembers: (members: Array<number>) => {
      dispatch(actionsClanProfile.toggleAllMembersTick(members));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContainerClanProfile);
