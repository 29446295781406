import type { InferActionsType } from '~/Reducers';

export const INTRODUCTION_IS_SHOWN = 'INTRODUCTION_IS_SHOWN';

export type ActionsType = InferActionsType<typeof actionsIntroduction>;

export const actionsIntroduction = {
  introductionIsShown: () =>
    ({
      type: INTRODUCTION_IS_SHOWN,
    }) as const,
};
