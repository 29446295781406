import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';
import * as React from 'react';
import { Interpolate, translate } from 'react-i18next';
import { Menu } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { SEASON_TYPES } from '~/constants';
import { getLocalizationSafe, t, x } from '~/helpers/localization';

import styles from './LadderSeasonsDropdown.scss';

export type IProps = {
  selectedSeasonId: number;
  selectedSeasonType: string;
  dropdownPosition?: string;
  onSeasonChange: () => void;
};

class LadderSeasonsDropdown extends React.PureComponent<IProps> {
  lastSeasonId = null;

  _getItems() {
    const ladderSettings = settings.ladder;
    const seasons = (ladderSettings && ladderSettings.seasons) || {};
    const seasonsList = [];

    forEach(seasons, (value, key) => {
      const { id: seasonId, kind: seasonKind } = seasons[key];
      // REMOVE AFTER VERIZON CUP! 01.11.2020
      if (seasonKind === this.props.selectedSeasonType) {
        seasonsList.push({
          value: seasonId,
          content:
            seasonId == 301 ? (
              <span>{t('Клановая квалификация')}</span>
            ) : (
              <span>
                <Interpolate
                  i18nKey={x('Сезон %(seasonId)s: ')}
                  seasonId={seasonKind === SEASON_TYPES.REGULAR ? seasonId : seasonId - 200}
                  t={t}
                />
                {getLocalizationSafe(seasons[seasonId], 'title')}
              </span>
            ),
        });
      }
    });

    return sortBy(seasonsList, ['id']).reverse();
  }

  render() {
    const selectedSeasonId = this.props.selectedSeasonId || this.lastSeasonId;
    const seasonsList = this._getItems();

    if (!seasonsList.length || !selectedSeasonId) return null;

    const selectedSeason = seasonsList.find((item) => {
      return item.value === selectedSeasonId;
    });

    const label = <div className={styles.label}>{selectedSeason && selectedSeason.content}</div>;

    const content =
      seasonsList.length > 1 ? (
        <Menu
          items={seasonsList}
          itemSelected={selectedSeasonId}
          onItemChange={this.props.onSeasonChange}
          dropdownPosition={this.props.dropdownPosition}
        >
          {label}
        </Menu>
      ) : (
        label
      );

    return <div className={styles.container}>{content}</div>;
  }
}

export default translate()(LadderSeasonsDropdown);
