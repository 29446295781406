import { t } from '~/helpers/localization';

export default [
  {
    text: t('Клан'),
    isSortable: false,
    name: 'name',
    modify: 'basis',
  },
  {
    text: '',
    isSortable: false,
    name: 'members_count',
    modify: 'small',
    glyph: 'members-count',
    tooltipText: t('Количество игроков в клане.'),
  },
  {
    text: 'aBTL',
    isSortable: true,
    name: 'abtl',
    modify: 'right',
    glyph: 'battles',
    sortDefaultIsAsc: false,
    tooltipText: t('Среднее количество боёв на игрока клана в выбранном типе боев за всё время.:tooltip'),
    tooltipParam: 'aBTL &mdash; average Battles',
  },
  {
    text: 'aW/B',
    isSortable: false,
    name: 'awb',
    modify: 'right',
    glyph: 'wins-per-bettles',
    sortDefaultIsAsc: false,
    tooltipText: t('Средний процент побед на игрока клана в выбранном типе боев за всё время.:tooltip'),
    tooltipParam: 'aW/B &mdash; average Wins per Battles',
  },
  {
    text: 'aE/B',
    isSortable: false,
    name: 'aeb',
    modify: 'right',
    glyph: 'experience-per-battle',
    sortDefaultIsAsc: false,
    tooltipText: t('Средний опыт за бой на игрока клана в выбранном типе боев за всё время.:tooltip'),
    tooltipParam: 'aE/B &mdash; average Experience per Battle',
  },
  {
    text: 'aD/B',
    isSortable: false,
    name: 'admg',
    modify: 'right',
    glyph: 'damage-per-battle',
    sortDefaultIsAsc: false,
    tooltipText: t('Средний урон за бой на игрока клана в выбранном типе боев за всё время.:tooltip'),
    tooltipParam: 'aD/B &mdash; average Damage per Battle',
  },
  {
    text: 'aF/B',
    isSortable: false,
    name: 'afb',
    modify: 'right',
    glyph: 'frags-per-battle',
    sortDefaultIsAsc: false,
    tooltipText: t(
      'Среднее количество потопленных кораблей за бой на игрока клана в выбранном типе боев за всё время.:tooltip',
    ),
    tooltipParam: 'aF/B &mdash; average Frags per Battle',
  },
  {
    text: t('Решение:заголовок таблица приглашений'),
    isSortable: false,
    name: 'action',
    modify: 'buttons',
  },
];
