import styles from '~/stylesheets/core/_settings.media-queries.scss';

const parseCSSSize = (size: string) => {
  if (!size || !size.match(/^\d+px$/)) {
    throw 'can only work with strings containing values in px';
  }
  return parseInt(size, 10);
};

export default {
  smallBottomBound: parseCSSSize(styles.smallBottomBound),
  smallUpperBound: parseCSSSize(styles.smallUpperBound),

  mediumBottomBound: parseCSSSize(styles.mediumBottomBound),
  mediumUpperBound: parseCSSSize(styles.mediumUpperBound),

  largeBottomBound: parseCSSSize(styles.largeBottomBound),
  largeUpperBound: parseCSSSize(styles.largeUpperBound),

  extraBottomBound: parseCSSSize(styles.extraBottomBound),
  extraUpperBound: parseCSSSize(styles.extraUpperBound),
};
