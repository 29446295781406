import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import * as React from 'react';
import { shallowEqual } from 'react-redux';
import {
  Button,
  ButtonGroup,
  ClanTag,
  DialogBody,
  DialogContainer,
  DialogFooter,
  DialogHeader,
  MessageWrap,
  DivTooltip,
  TooltipBody,
  TooltipHeader,
} from '@wg/wows-react-uikit';

import settings from '~/settings';
import { getLocalizationSafe, t } from '~/helpers/localization';
import { useAppSelector } from '~/store';

import styles from './MonumentAchievementsDialog.scss';

import type { AppStateType as RootState } from '~/Reducers';
import type { IClanAchievement } from '~/Reducers/ReducerClanProfile';
import type { IAchievements } from '~/store/slices/settingsSlice';
import type { IDialog } from '~/types/declaration';

const Achievement: React.FC<{
  title: string;
  description: string;
  image: string;
  count: number;
}> = ({ title, description, image, count }) => {
  const [loaded, setLoaded] = React.useState(false);

  return (
    <DivTooltip
      className={styles.achievementWrap}
      tooltipBody={
        <>
          <TooltipHeader isBold>
            <span className={styles.tooltipHeader}>{title}</span>
          </TooltipHeader>
          <TooltipBody>{description}</TooltipBody>
        </>
      }
    >
      <div className={styles.achievement}>
        <img
          className={classNames(styles.achievementImg, { [styles.achievementImgLoaded]: loaded })}
          src={image}
          alt={title}
          width={80}
          height={80}
          loading="lazy"
          onLoad={() => setLoaded(true)}
        />
        <span className={styles.count}>{count > 1 && `x${count}`}</span>
      </div>
    </DivTooltip>
  );
};

const stateSelector = (clanId: number) => (state: RootState) => {
  return {
    achievements: state.clans.achievements[clanId],
    tag: state.clans.items[clanId].tag,
    color: state.clans.items[clanId].color,
  };
};

type IMonumentAchievementsDialog = IDialog<{
  clanId: number;
}>;

const MonumentAchievementsDialog: React.FC<IMonumentAchievementsDialog> = ({ data, hideDialog }) => {
  const { clanId } = data;

  const { achievements, tag, color } = useAppSelector(stateSelector(clanId), shallowEqual);

  const mediaPath = settings.mediaPath;
  const achievementsData = settings.achievements.data;

  const achievementGroupNames = React.useMemo(() => {
    return {
      WinnerClanSeason: t('Зал славы'),
      KingOfTheSea: t('King of the Sea'),
      WarshipMasters: t('Warship Masters'),
      TopLeagueClanSeason: t('Сезоны клановых боев'),
      CLAN_LEAGUE: t('Лиги клановых боев'),
      CvCBrawl: t('Клановый блиц'),
      other: t('Другие достижения'),
    };
  }, []);

  const getGroupAchievements = React.useCallback(
    (achievementsIds: IClanAchievement[], achievementsData: IAchievements) => {
      const groupedAchievements: Map<string, IClanAchievement[]> = new Map();

      if (isEmpty(achievementsIds)) {
        return groupedAchievements;
      }

      for (const group_pattern in achievementGroupNames) {
        groupedAchievements.set(group_pattern, []);
      }

      map(achievementsIds, (achievement) => {
        const group_name = achievementsData[achievement.cd].group_name;
        groupedAchievements.get(group_name)?.push(achievement);
      });

      groupedAchievements.forEach((groupedAchievement) => {
        groupedAchievement.sort((a, b) => {
          const rankA = Number(achievementsData[a.cd]?.grouping?.local_rank) || 0;
          const rankB = Number(achievementsData[b.cd]?.grouping?.local_rank) || 0;
          return rankA - rankB;
        });
      });

      return groupedAchievements;
    },
    [achievementGroupNames],
  );

  const groupedAchievements = getGroupAchievements(achievements, achievementsData);

  const renderAchievements = React.useCallback(
    (achievement_group: IClanAchievement[]) => {
      return map(achievement_group, (achievement) => {
        return achievementsData[achievement.cd] ? (
          <Achievement
            title={getLocalizationSafe(achievementsData[achievement.cd], 'mark')}
            description={getLocalizationSafe(achievementsData[achievement.cd], 'description')}
            image={mediaPath + achievementsData[achievement.cd].icons.normal}
            count={achievement.count}
            key={`achievement-${achievement.cd}`}
          />
        ) : null;
      });
    },
    [achievementsData, mediaPath],
  );

  const renderAchievementGroups = React.useCallback(() => {
    const result: React.ReactNode[] = [];
    if (isEmpty(groupedAchievements) || isEmpty(settings.achievements.data)) {
      return (
        <MessageWrap title={t('Достижения отсутствуют')} message={t('В настоящее время у клана нет достижений')} />
      );
    }
    for (const [group_pattern, achievements_array] of groupedAchievements.entries()) {
      if (!isEmpty(achievements_array)) {
        result.push(
          <div className={styles.achievementGroupText} key={`achievement-text-${group_pattern}`}>
            {achievementGroupNames[group_pattern]}
          </div>,
        );
        result.push(
          <div className={styles.achievementsGroupContainer} key={`achievement-container-${group_pattern}`}>
            {renderAchievements(achievements_array)}
          </div>,
        );
      }
    }
    return result;
  }, [groupedAchievements, achievementGroupNames, renderAchievements]);

  return (
    <DialogContainer>
      <DialogHeader>
        <div className={styles.headerIcon}></div>
        <span className={styles.headerText}>{t('Достижения клана')}</span>
        <ClanTag clanTag={tag} clanColor={color} />
      </DialogHeader>
      <DialogBody>
        <div className={styles.achievementsContainer}>{renderAchievementGroups()}</div>
      </DialogBody>
      <DialogFooter isOverlayContent={true}>
        <ButtonGroup>
          <Button isJustified isFlat onClick={hideDialog}>
            {t('Вернуться на базу')}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    </DialogContainer>
  );
};

export default React.memo(MonumentAchievementsDialog);
