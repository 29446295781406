import * as React from 'react';
import { Interpolate } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { Button, ButtonGroup, DialogBody, DialogContainer, DialogFooter, DialogHeader } from '@wg/wows-react-uikit';

import { promiseWithSpinner } from '~/helpers/fetch';
import { t, x } from '~/helpers/localization';
import { useAppDispatch, useAppSelector } from '~/store';
import { cancelInvite } from '~/Actions/ActionInvites';

import type { Invite } from '~/Actions/ActionInvites';
import type { AppStateType as RootState } from '~/Reducers';
import type { IDialog } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  return {
    idsToProcess: state.invites.idsToProcess,
  };
};

type IInviteCancelDialog = IDialog<{
  invite: Invite;
}>;

const InviteCancelDialog: React.FC<IInviteCancelDialog> = ({ data, hideDialog }) => {
  const { invite } = data;

  const { idsToProcess } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const isFetching = idsToProcess.includes(invite.id);

  const onClick = React.useCallback(() => {
    const action = cancelInvite(invite.id);
    const promise = dispatch(action).then(() => hideDialog());
    promiseWithSpinner(dispatch, promise);
  }, [dispatch, hideDialog, invite]);

  return (
    <DialogContainer>
      <DialogHeader>{t('Отмена приглашения')}</DialogHeader>
      <DialogBody>
        <Interpolate
          i18nKey={x('Вы уверены, что хотите отменить приглашение в клан игрока %(recepientName)s?')}
          recepientName={invite.account?.name}
          t={t}
        />
      </DialogBody>
      <DialogFooter>
        <ButtonGroup isLoading={isFetching}>
          <Button isJustified isFlat onClick={onClick}>
            {t('Да')}
          </Button>
          <Button isJustified isFlat onClick={hideDialog}>
            {t('Нет')}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    </DialogContainer>
  );
};

export default React.memo(InviteCancelDialog);
