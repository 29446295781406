import axios from 'axios';

import { fetchClan } from './ActionClanProfile';

import type { InferActionsType } from '~/Reducers';
import type { AppAsyncThunk, AppThunk } from '~/store';
import type { IClanBattle } from '~/types/declaration';

export const ON_CLICK_BATTLE = 'ON_CLICK_BATTLE';
export const SET_CLAN_BATTLES = 'SET_CLAN_BATTLES';
export const SET_CLAN_BATTLES_FETCHING = 'SET_CLAN_BATTLES_FETCHING';
export const SET_SELECTED_CLAN_BATTLES_TAB = 'SET_SELECTED_CLAN_BATTLES_TAB';
export const SET_SELECTED_TEAM_NUMBER = 'SET_SELECTED_TEAM_NUMBER';
export const TOGGLE_BATTLES_ERROR = 'TOGGLE_BATTLES_ERROR';
export const TOGGLE_CLAN_BATTLES_FETCH = 'TOGGLE_CLAN_BATTLES_FETCH';
export const TOGGLE_SELECTED_BATTLE = 'TOGGLE_SELECTED_BATTLE';

export type ActionsType = InferActionsType<typeof actionsClanBattles>;

export const actionsClanBattles = {
  setSelectedTab: (selectedTab: number) =>
    ({
      type: SET_SELECTED_CLAN_BATTLES_TAB,
      payload: { selectedTab: selectedTab },
    }) as const,

  setSelectedTeamNumber: (teamNumber: number) =>
    ({
      type: SET_SELECTED_TEAM_NUMBER,
      payload: { teamNumber },
    }) as const,

  onClickBattle: (battleId: number) =>
    ({
      type: ON_CLICK_BATTLE,
      payload: { battleId },
    }) as const,

  setClanBattlesFetching: (isFetching: boolean) =>
    ({
      type: SET_CLAN_BATTLES_FETCHING,
      payload: { isFetching },
    }) as const,

  setClanBattles: (battles: IClanBattle[]) =>
    ({
      type: SET_CLAN_BATTLES,
      payload: { battles },
    }) as const,

  toggleClanBattlesFetch: (isFetching: boolean) =>
    ({
      type: TOGGLE_CLAN_BATTLES_FETCH,
      isFetching,
    }) as const,

  toggleBattlesError: (isError: boolean, errorMessage: string) =>
    ({
      type: TOGGLE_BATTLES_ERROR,
      isError,
      errorMessage,
    }) as const,

  toggleSelectedBattle: (selectedBattle: number) =>
    ({
      type: TOGGLE_SELECTED_BATTLE,
      selectedBattle,
    }) as const,
};

export const checkClanBattles = (): AppAsyncThunk => async (dispatch, getState) => {
  let state = getState();

  const executeRequest = (leadingTeam?) => {
    const team = leadingTeam ? leadingTeam : 1;
    dispatch(actionsClanBattles.setSelectedTeamNumber(team));
    const url = `${state.urls.ladderClanBattles}?team=${team}`;
    if (state.currentAccount.clanId) {
      axios
        .get(url)
        .then((r) => {
          const battles = r.data;
          dispatch(actionsClanBattles.setClanBattles(battles));
          dispatch(actionsClanBattles.setClanBattlesFetching(false));
        })
        .catch(() => {
          dispatch(actionsClanBattles.setClanBattlesFetching(false));
        });
    }
  };

  if (state.currentAccount && state.currentAccount.clanId) {
    dispatch(actionsClanBattles.setClanBattlesFetching(true));

    await dispatch(fetchClan(state.currentAccount.clanId, true));

    state = getState();
    const wowsLadder = state.clans.wowsLadder[state.currentAccount.clanId];
    const leadingTeamNumber = wowsLadder ? wowsLadder.leading_team_number : null;
    executeRequest(leadingTeamNumber);
  } else {
    executeRequest();
  }
};

export const getClanBattles = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const url = `${state.urls.ladderClanBattles}?team=${state.ReducerClanBattles.teamNumber}`;
  dispatch(actionsClanBattles.setClanBattlesFetching(true));
  axios
    .get(url)
    .then((r) => {
      dispatch(actionsClanBattles.setClanBattles(r.data));
      dispatch(actionsClanBattles.setClanBattlesFetching(false));
    })
    .catch(() => {
      dispatch(actionsClanBattles.setClanBattlesFetching(false));
    });
};

export const fetchClanData = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  if (state.currentAccount?.clanId) {
    dispatch(fetchClan(state.currentAccount.clanId, true));
  }
};
