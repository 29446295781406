import PropTypes from 'prop-types';
import * as React from 'react';
import { translate } from 'react-i18next';
import { Tabs } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import styles from './DivisionTabs.scss';

const propTypes = {
  items: PropTypes.array.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  onDivisionChange: PropTypes.func,
};

class DivisionTabs extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const tabs = this.props.items.map((tab) => {
      return {
        value: tab.number,
        content: (
          <div>
            <div className={styles.divisionNumber}>{tab.title}</div>
            <div className={styles.smallText}>{t('Дивизион')}</div>
          </div>
        ),
      };
    });

    return (
      <div className={styles.container}>
        <Tabs tabs={tabs} tabSelected={this.props.selected} onToggleTab={this.props.onDivisionChange} />
      </div>
    );
  }
}

DivisionTabs.propTypes = propTypes;

export default translate()(DivisionTabs);
