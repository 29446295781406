import classNames from 'classnames';
import unescape from 'lodash/unescape';
import React from 'react';

import { SOCIAL_NETWORKS } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { showExternalNavigationDialog } from '~/helpers/dialogs';
import { useAppDispatch } from '~/store';

import styles from './SocialLink.scss';

import type { ISocialLinkContainer } from './SocialLinkContainer';

interface ISocialLink {
  link?: ISocialLinkContainer['link'];
  social: ISocialLinkContainer['social'];
  isIcon?: boolean;
  isFullLink: ISocialLinkContainer['isFullLink'];
}

const SocialLink: React.FC<ISocialLink> = (props) => {
  const { link, social, isIcon = false, isFullLink } = props;

  const dispatch = useAppDispatch();

  const onClick = React.useCallback(() => {
    if (!link) {
      return;
    }
    dwhExport.push(DWH_EVENTS.SOCIALS.LINK_CLICK, { social });
    dispatch(showExternalNavigationDialog(link));
  }, [dispatch, link, social]);

  if ((!link && !isIcon) || !Object.values(SOCIAL_NETWORKS)?.includes(social)) {
    return null;
  }

  return (
    <div
      className={classNames(styles.iconProvider, styles[social], {
        [styles.isIcon]: isIcon,
      })}
    >
      {!isIcon && (
        <div
          className={classNames(styles.linkWrapper, styles.external, {
            [styles.isShortLink]: !isFullLink,
          })}
          onClick={onClick}
        >
          {isFullLink ? unescape(link) : social}
        </div>
      )}
    </div>
  );
};

export default React.memo(SocialLink);
