import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';

import styles from './Tooltip.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  mouseIcon: PropTypes.oneOf(['left', 'right']).isRequired,
};

const TooltipFooter = (props) => {
  const classNameFooter = classNames(styles.footer, styles[props.mouseIcon]);

  return <div className={classNameFooter}>{props.children}</div>;
};

TooltipFooter.propTypes = propTypes;

export default TooltipFooter;
