import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { push } from 'react-router-redux';

import { fetchWrapper as fetch } from '~/helpers/fetch';
import { t } from '~/helpers/localization';
import { isPreModerateNameTag } from '~/helpers/moderation';
import { snakeToCamel } from '~/helpers/strings';
import { hasPermission, PERMISSIONS } from '~/roles';
import { ROUTES_MAP } from '~/routes';
import { getCurrentClan } from '~/store/selectors/currentAccountSelector';
import { closeAllDialogs } from '~/store/slices/dialogsSlice';
import * as web2ClientAPI from '~/web2ClientAPI/web2ClientAPI';

import { actionsApp, fetchApplications } from './ActionApp';
import { actionsClanProfile, fetchMembers } from './ActionClanProfile';
import { actionsClanRename } from './ActionClanRename';
import { init as clanWarsInit } from './ActionClanWars';
import { fetchInvites } from './ActionInvites';
import { actionsSupply } from './ActionSupply';

import type { BUILDINGS, IBuildings, IBuildingSupply } from '~/Actions/ActionSupply';
import type { Actions } from '~/Actions/index';
import type { InferActionsType } from '~/Reducers';
import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';
import type { AppAsyncThunk, IAppDispatch, RootState } from '~/store';
import type { IApiAccountSyncResponse } from '~/types/api';

export const DECREASE_ACCOUNT_TOTAL_GOLD = 'DECREASE_ACCOUNT_TOTAL_GOLD';
export const DECREASE_ACTIVE_ENTRIES_COUNT = 'DECREASE_ACTIVE_ENTRIES_COUNT';
export const SYNC_CURRENT_ACCOUNT = 'SYNC_CURRENT_ACCOUNT';

export type DiffObjectType = Partial<
  Pick<
    ICurrentAccountState,
    | 'accumulativeClanResource'
    | 'activeApplicationsCount'
    | 'activeInvitesCount'
    | 'clanId'
    | 'isBonusActivated'
    | 'leveling'
    | 'roleName'
  >
>;

type QueryParamsType = {
  is_fetching_rename_info?: number;
};

export type ActionsType = InferActionsType<typeof actionsAccount>;

export const actionsAccount = {
  decreaseAccountTotalGold: (amount: number) =>
    ({
      type: DECREASE_ACCOUNT_TOTAL_GOLD,
      amount,
    }) as const,

  decreaseActiveEntriesCount: () =>
    ({
      type: DECREASE_ACTIVE_ENTRIES_COUNT,
    }) as const,

  syncCurrentAccount: (json: IApiAccountSyncResponse) => {
    const buildings = json.clan?.buildings || ({} as IBuildings);
    const newBuildings: IBuildingSupply[] = [];

    for (const key in buildings) {
      if (Object.prototype.hasOwnProperty.call(buildings, key)) {
        const building = buildings[key as BUILDINGS];
        newBuildings.push({
          id: building.id,
          level: building.level,
          modifiers: building.modifiers,
          type: building.name,
        });
      }
    }

    return {
      type: SYNC_CURRENT_ACCOUNT,
      payload: {
        buildings: newBuildings,
      },
    } as const;
  },
};

const sendNotificationsByAccountInfoSync = (state: RootState, newAccountInfo, overriddenMessages) => {
  const messages = Object.assign(
    {
      joinClan: () => web2ClientAPI.sendCurrentAccountJoinedClanNotification(newAccountInfo.clan),
      leaveClan: () => web2ClientAPI.sendCurrentAccountRemovedFromClanNotification(getCurrentClan(state)),
      changeRole: () =>
        web2ClientAPI.sendCurrentAccountRoleChangedNotification(newAccountInfo.role_name, getCurrentClan(state)),
    },
    overriddenMessages,
  );
  const currentAccount = state.currentAccount;
  if (currentAccount.clanId !== newAccountInfo.clan_id) {
    if (!currentAccount.clanId) {
      messages.joinClan();
    } else if (!newAccountInfo.clan_id) {
      messages.leaveClan();
    } else {
      messages.joinClan();
      messages.leaveClan();
    }
  } else if (newAccountInfo.role_name && currentAccount.roleName !== newAccountInfo.role_name) {
    messages.changeRole();
  }
};

const getObjectsDiffFields = (
  fields: string[],
  currentObject: Record<string, unknown>,
  newObject: Record<string, unknown>,
) => {
  const diffObject: DiffObjectType = {};
  fields.forEach((field) => {
    const rightValue = newObject[field];
    if (isUndefined(rightValue)) {
      return;
    }

    const snakeCamelField = snakeToCamel(field);
    if (currentObject[snakeCamelField] !== rightValue) {
      diffObject[snakeCamelField] = rightValue;
    }
  });
  return diffObject;
};

const accountDiffFieldsToUpdate = (currentAccount, accountInfo) => {
  const fields = [
    'clan_id',
    'active_invites_count',
    'active_applications_count',
    'accumulative_clan_resource',
    'leveling',
    'is_bonus_activated',
    'in_clan_cooldown_till',
  ];

  const accountInfoToUpdate = getObjectsDiffFields(fields, currentAccount, accountInfo);

  if (accountInfoToUpdate.clanId === null) {
    accountInfoToUpdate.activeApplicationsCount = 0;
    accountInfoToUpdate.accumulativeClanResource = null;
    accountInfoToUpdate.leveling = null;
    accountInfoToUpdate.isBonusActivated = null;
  } else if (accountInfoToUpdate.clanId && !currentAccount.clanId) {
    accountInfoToUpdate.activeInvitesCount = 0;
  }

  if (currentAccount.roleName !== accountInfo.role_name) {
    accountInfoToUpdate.role = accountInfo.role_name;
  }

  return accountInfoToUpdate;
};

const clanDiffFieldsToUpdate = (currentAccountClanInfo, newClanInfo) => {
  const fields = [
    'max_members_count',
    'accumulative_resource',
    'leveling',
    'personal_resource',
    'pre_moderation',
    'name',
    'tag',
    'description',
  ];
  return getObjectsDiffFields(fields, currentAccountClanInfo, newClanInfo);
};

const getFetchUrl = (state: RootState) => {
  const queryParams: QueryParamsType = {};
  const { isProcessing, isCheckingStatus } = state.clanRename;

  if (isProcessing && !isCheckingStatus) {
    queryParams.is_fetching_rename_info = 1;
  }

  return { url: state.urls.accountInfoSync, queryParams };
};

const processUpdateAccountRole = (state: RootState, dispatch: IAppDispatch, accountInfoToUpdate) => {
  const currentAccount = state.currentAccount;
  if (
    currentAccount.clanId &&
    currentAccount.roleName &&
    [`${state.urls.root}${ROUTES_MAP.PROFILE}`, `${state.urls.root}${ROUTES_MAP.MEMBERS}`].includes(
      state.routing.locationBeforeTransitions.pathname,
    )
  ) {
    dispatch(actionsClanProfile.clearMembersOperation());
    dispatch(fetchMembers(currentAccount.clanId));
  }

  if (
    !hasPermission(accountInfoToUpdate.role, PERMISSIONS.HANDLE_INVITES) &&
    state.routing.locationBeforeTransitions.pathname === `${state.urls.root}invites`
  ) {
    dispatch(push(state.urls.root));
  }
  if (
    !hasPermission(accountInfoToUpdate.role, PERMISSIONS.RENAME_CLAN) &&
    state.routing.locationBeforeTransitions.pathname === `${state.urls.root}profile`
  ) {
    dispatch(actionsClanRename.stopRenamingClan());
  }
};

const processRenamingInfo = (state: RootState, dispatch: IAppDispatch, renamingInfo, clanId) => {
  const isOwn = get(renamingInfo, 'is_own');
  const status = get(renamingInfo, 'status');
  if (isOwn) {
    if (status === 'ok') {
      const currentClan = getCurrentClan(state);
      const tagAndName = {
        name: get(renamingInfo, 'name'),
        tag: get(renamingInfo, 'tag'),
      };
      if (isPreModerateNameTag) {
        web2ClientAPI.sendClanRenamePreModerationNotification();
      } else {
        web2ClientAPI.sendClanRenameCompleteNotification(currentClan, tagAndName, renamingInfo.cost);
      }
      dispatch(actionsClanRename.errorRenamingClan(null));
      dispatch(actionsClanProfile.changeClanAttributesSuccessed({ ...tagAndName, clanId }));
    } else if (status === 'error') {
      const reason = get(renamingInfo, 'error') === 'NO_GOLD' ? 'no_gold' : null;
      const error = reason === 'no_gold' ? t('Недостаточно средств') : t('Произошла ошибка. Повторите попытку позже');
      web2ClientAPI.sendClanRenameErrorNotification(reason);
      dispatch(actionsClanRename.errorRenamingClan({ error }));
    }
  } else {
    const actions: Array<Actions> = [actionsClanRename.errorRenamingClan(null)];
    if (status === 'ok') {
      actions.push(
        actionsClanProfile.changeClanAttributesSuccessed({
          name: get(renamingInfo, 'name'),
          tag: get(renamingInfo, 'tag'),
          clanId,
        }),
      );
    }
    actions.forEach((action) => {
      dispatch(action);
    });
  }
};

export const syncAccountInfo =
  (overriddenNotifications = {}): AppAsyncThunk =>
  (dispatch, getState) => {
    const state = getState();
    const { url, queryParams } = getFetchUrl(state);
    if (state.currentAccount.id) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      return fetch(url, { query: queryParams })
        .then((json: IApiAccountSyncResponse) => {
          if (json && !json.error && !json.additional_info) {
            const state = getState();
            const currentAccount = state.currentAccount;

            dispatch(actionsAccount.syncCurrentAccount(json));
            sendNotificationsByAccountInfoSync(state, json, overriddenNotifications);

            const actions = [];
            if (!isEmpty(json.clan) && currentAccount.clanId && currentAccount.clanId === json.clan.id) {
              const currentAccountClan = getCurrentClan(state);
              const clanInfoToUpdate = clanDiffFieldsToUpdate(currentAccountClan, json.clan);
              if (!isEmpty(clanInfoToUpdate)) {
                actions.push(actionsClanProfile.updateClanFromSync(currentAccount.clanId, clanInfoToUpdate));
              }
            }

            actions.push(actionsSupply.syncSupply(json.role_name));

            const accountInfoToUpdate = accountDiffFieldsToUpdate(currentAccount, json);

            if (!isEmpty(accountInfoToUpdate)) {
              actions.push(actionsApp.updateAccountInfo(accountInfoToUpdate));
            }

            if (!isEmpty(actions)) {
              actions.forEach((action) => {
                dispatch(action);
              });
            }

            if (accountInfoToUpdate.roleName) {
              processUpdateAccountRole(state, dispatch, accountInfoToUpdate);
            }

            if (!isUndefined(accountInfoToUpdate.clanId)) {
              dispatch(closeAllDialogs());
              dispatch(clanWarsInit(true));
              dispatch(push(state.urls.root));
            }

            if (!isUndefined(accountInfoToUpdate.activeInvitesCount)) {
              if (
                isUndefined(accountInfoToUpdate.clanId) &&
                currentAccount.activeInvitesCount !== accountInfoToUpdate.activeInvitesCount &&
                state.routing.locationBeforeTransitions.pathname === `${state.urls.root}requests`
              ) {
                dispatch(fetchInvites());
              }
            }

            if (!isUndefined(accountInfoToUpdate.activeApplicationsCount)) {
              if (
                currentAccount.activeApplicationsCount !== accountInfoToUpdate.activeApplicationsCount &&
                state.routing.locationBeforeTransitions.pathname === `${state.urls.root}requests`
              ) {
                void dispatch(fetchApplications());
              }
            }

            if (
              json.is_renaming === false &&
              state.routing.locationBeforeTransitions.pathname === `${state.urls.root}profile`
            ) {
              processRenamingInfo(state, dispatch, json.renaming_info || {}, json.clan_id);
            }
          }
        })
        .catch((err) => {
          console.error('Failed sync account info', err);
          throw err;
        });
    } else {
      return new Promise((resolve) => {
        resolve();
      });
    }
  };
