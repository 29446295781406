import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import { translate } from 'react-i18next';
import { DivTooltip, Menu } from '@wg/wows-react-uikit';

import settings from '~/settings';

import { TooltipBody } from '~/UIKit/components';

import styles from './LeagueSelector.scss';

const propTypes = {
  items: PropTypes.array.isRequired,
  selected: PropTypes.number.isRequired,
  onLeagueChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

class LeagueSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuActive: false,
    };

    this.onItemChange = this.onItemChange.bind(this);
    this.onMenuBlur = this.onMenuBlur.bind(this);
    this.onMenuToggled = this.onMenuToggled.bind(this);
  }

  onItemChange(league) {
    this.setState({
      isMenuActive: false,
    });
    if (league !== this.props.selected) {
      this.props.onLeagueChange(league);
    }
  }

  onMenuBlur() {
    this.setState({
      isMenuActive: false,
    });
  }

  onMenuToggled(isMenuActive) {
    this.setState({
      isMenuActive: isMenuActive,
    });
  }

  render() {
    const classNameLeagueSelector = classNames(styles.leagueSelector, styles[this.props.selected]);
    const leagues = this.props.items;
    const itemSelected = leagues.find((item) => item.value === this.props.selected) || leagues[0];
    const t = this.props.t;

    const leagueContent = (
      <div className={classNameLeagueSelector}>
        <img
          className={styles.leagueImage}
          src={`${settings.mediaPath}${itemSelected ? itemSelected.icons.assembled_small : ''}`}
          alt=""
        />
        <span className={styles.leagueText}>{itemSelected ? itemSelected.content : null}</span>
      </div>
    );

    // TODO: Add disabled parameter to DivTooltip
    return (
      <div className={styles.container}>
        <Menu
          items={leagues}
          itemSelected={itemSelected && itemSelected.value ? itemSelected.value : ''}
          onItemChange={this.onItemChange}
          onMenuBlur={this.onMenuBlur}
          onMenuToggled={this.onMenuToggled}
        >
          {this.state.isMenuActive ? (
            leagueContent
          ) : (
            <DivTooltip
              tooltipBody={<TooltipBody withMouseClick>{t('Выбрать лигу для отображения статистики')}</TooltipBody>}
            >
              {leagueContent}
            </DivTooltip>
          )}
        </Menu>
      </div>
    );
  }
}

LeagueSelector.propTypes = propTypes;

export default translate()(LeagueSelector);
