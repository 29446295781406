import classNames from 'classnames';
import debounce from 'lodash/debounce';
import React, { useState, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import { playButtonClickSound } from '@wg/web2clientapi/sound';

import settings from '~/settings';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { t } from '~/helpers/localization';
import useClickOutside from '~/hooks/useClickOutside';
import useMount from '~/hooks/useMount';
import { useAppDispatch, useAppSelector } from '~/store';
import { playButtonSound, playInputSound } from '~/web2ClientAPI/web2ClientAPI';
import { actionsAutocomplete, fetchAutompleteResult } from '~/Actions/ActionAutocomplete';
import { actionsSearch, fetchSearchResult } from '~/Actions/ActionSearch';

import { ClanSearchAutocomplete } from '~/UIKit/components';

import styles from './ClanSearchInput.scss';

import type { RootState } from '~/store';
import type { IClanData } from '~/types/declaration';

const DEBOUNCE_INTERVAL = 300;

const stateSelector = (state: RootState) => {
  return {
    autocomplete: state.autocomplete,
    currentAccount: state.currentAccount,
    term: state.searchResult?.term,
  };
};

type IClanSearchInput = {
  searchType?: string;
  showIcon?: boolean;
  onSubmit?: () => void;
  onHistoryItemClick: (clan: IClanData) => void;
};

const ClanSearchInput: React.FC<IClanSearchInput> = ({ searchType, showIcon, onSubmit, onHistoryItemClick }) => {
  const { autocomplete, currentAccount, term } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const [isFocused, setIsFocused] = useState(false);
  const [termState, setTermState] = useState(term);
  const [isAutocompleteVisible, setIsAutocompleteVisible] = useState(false);

  const autocompleteAbortController = React.useRef<AbortController>(new AbortController());

  const inputRef = useRef<Nullable<HTMLInputElement>>(null);
  const autocompleteRef = useRef(null);

  useMount(() => {
    return () => {
      autocompleteAbortController.current?.abort();
    };
  });

  useClickOutside([inputRef, autocompleteRef], () => {
    setIsAutocompleteVisible(false);
  });

  React.useEffect(() => {
    setTermState(term);
  }, [term]);

  const onClearClick = React.useCallback(() => {
    void playButtonClickSound();

    setTermState('');
    setIsFocused(true);
    setIsAutocompleteVisible(true);

    inputRef.current?.focus();

    dispatch(actionsAutocomplete.clearAutocompleteState());
    dispatch(actionsSearch.clearSearchState());
  }, [dispatch]);

  const onFocus = () => {
    if (isFocused && isAutocompleteVisible) {
      return;
    }

    dwhExport.push(DWH_EVENTS.SEARCH.INPUT_FOCUS);

    setIsFocused(true);
    setIsAutocompleteVisible(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const isEnoughSymbols = termState && termState.length >= settings.search.minChars;

    if (event.code === 'Enter' && isEnoughSymbols) {
      void playButtonClickSound();
      setIsAutocompleteVisible(false);
      handleSubmit();
    }
  };

  const fetchData = React.useCallback(
    (term: string) => {
      if (autocomplete.isFetching) {
        autocompleteAbortController.current?.abort();
        autocompleteAbortController.current = new AbortController();
      }

      void dispatch(
        fetchAutompleteResult({ term, type: searchType, abortSignal: autocompleteAbortController.current?.signal }),
      );
    },
    [autocomplete.isFetching, dispatch, searchType],
  );

  const onChangePurified = React.useCallback(
    (term: string) => {
      if (term) {
        void fetchData(term);
      }
    },
    [fetchData],
  );

  const onChangeDebounced = React.useMemo(() => debounce(onChangePurified, DEBOUNCE_INTERVAL), [onChangePurified]);

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      const trimmedValue = event.target?.value?.trim() || '';
      const termValue = trimmedValue ? event.target.value : trimmedValue;

      if (!trimmedValue) {
        onClearClick();
      } else {
        setTermState(termValue);
      }

      playInputSound();
      onChangeDebounced(termValue);
    },
    [onChangeDebounced, onClearClick],
  );

  const handleHistoryItemClick = (item: IClanData) => {
    onHistoryItemClick(item);
    setIsAutocompleteVisible(false);
  };

  const handleAutocompleteItemClick = (item: IClanData) => {
    dispatch(actionsSearch.appendTermToHistory(item, currentAccount.id || 0));
  };

  const handleSubmit = () => {
    autocompleteAbortController.current?.abort();
    inputRef.current?.blur();
    setIsAutocompleteVisible(false);

    const isNotEnoughSymbols = (inputRef.current?.value.length || 0) < settings.search.minChars;

    if (termState && !isNotEnoughSymbols) {
      onSubmit?.();
      dispatch(actionsSearch.changeTerm(termState));
      void dispatch(fetchSearchResult({ type: searchType }));
    }
  };

  const onEnterClick = () => {
    playButtonSound();
    handleSubmit();
  };

  const renderAutocomplete = () => {
    if (!isAutocompleteVisible) {
      return null;
    }

    return (
      <div ref={autocompleteRef}>
        <ClanSearchAutocomplete
          isHistoryHidden={searchType === 'clansRating'}
          term={termState || ''}
          onHistoryItemClick={handleHistoryItemClick}
          onItemClick={handleAutocompleteItemClick}
          onSubmit={handleSubmit}
        />
      </div>
    );
  };

  const renderControl = () => {
    if (!termState) {
      return null;
    }

    return (
      <div className={styles.inputButtonsWrap}>
        {termState.length >= 2 ? <div className={styles.button} onClick={onEnterClick} /> : null}
        <div className={styles.clear} onClick={onClearClick} />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <input
        className={classNames(styles.input, {
          [styles.isIconVisible]: showIcon,
        })}
        maxLength={settings.search.maxChars}
        placeholder={isFocused ? '' : t('Введите тег или название клана')}
        ref={inputRef}
        spellCheck={false}
        type="text"
        value={termState}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
      />
      {showIcon && <span className={styles.icon} />}
      {renderAutocomplete()}
      {renderControl()}
    </div>
  );
};

export default React.memo(ClanSearchInput);
