import settings from '~/settings';

export const getRankWrapper = (seasonRank: number) => {
  if (!seasonRank) {
    return 0;
  }
  const parsedSeasonRank = parseInt(`${seasonRank}`, 10);
  if (parsedSeasonRank >= 17) {
    return 4;
  }
  if (parsedSeasonRank < 17 && parsedSeasonRank >= 8) {
    return 3;
  }
  if (parsedSeasonRank < 8 && parsedSeasonRank > 1) {
    return 2;
  }
  if (parsedSeasonRank === 1) {
    return 1;
  }
};

export const hasSeasonRank = (seasonRank: Nullable<number>, seasonId: Nullable<number>) => {
  if (seasonRank === null || seasonId === null) {
    return false;
  }
  return seasonId in settings.seasonNames;
};

export const hasAnyRank = (rank: number | undefined, seasonRank: Nullable<number>, seasonId: Nullable<number>) => {
  return rank !== null || hasSeasonRank(seasonRank, seasonId);
};
