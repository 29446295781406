import React, { PureComponent } from 'react';
import { type Map } from 'react-leaflet';
import { connect } from 'react-redux';

import { addBlur, addMarkerBlur, removeBlur, updateBlurBgMap } from '~/utils/mapUtils';
import { playClanBaseBuilding } from '~/web2ClientAPI/sounds';

import {
  actionsSupply,
  closeSupplySidebarThunk,
  increaseBuildingLevelThunk,
  initSounds,
  navigateToTreasuryThunk,
  openSupplySideBarThunk,
  selectBuildingThunk,
  setBuildingLevelThunk,
  setMapRefThunk,
  setSelectedBuildingThunk,
  updateMinZoomThunk,
  updateSoundPosition,
} from '~/Actions/ActionSupply';

import { IsFromSearchContextConsumer } from '~/Providers/IsFromSearch';

import ViewSupply from '~/Components/ViewSupply';

import type { BUILDINGS } from '~/Actions/ActionSupply';
import type { AppStateType as State } from '~/Reducers';
import type { Point, PointArray } from '~/Reducers/ReducerSupply';
import type { IAppDispatch } from '~/store';

type IContainerSupply = {
  balance: number;
  initialZoom: number;
  zoom: number;
  position: Point;
  initialMaxBounds: PointArray;
  maxBounds: PointArray;
  isMapBlured: boolean;
  buildings: any;
  selectedBuildingId: string | null;
  prevSelectedBuildingId: string | null;
  isSidebarOpened: boolean;
  tempHoverBuildingLevel: {
    level: number;
    url: string;
  };
  tempHoverBuildingId: string | null;
  buildingInProgress: string | null;
  isOwnBase: boolean;
  clanId: number;
  confirmBuildingId: string | null;
  hasBuildPermission: boolean;
  minZoom: number;
  maxZoom: number;
  backgroundUrl: string | null;
  isDevMode: boolean;
  regularRewards: any;
  battlesStats: any;

  SetMapRef: (ref: Map) => void;
  SetMapBlur: (isMapBlured: boolean) => void;
  SetSelectedBuilding: (buildingId: string | null) => void;
  NavigateToBuilding: (buildingId: string | null) => void;
  SetHighlightedBuilding: (buildingId: string | null) => void;
  SetMapZoom: (zoom: number) => void;
  OpenSupplySideBar: () => void;
  CloseSupplySideBar: () => void;
  IncreaseBuildingLevel: (buildingId: string) => void;
  SetBuildingLevel: (buildingId: string, level: number) => void;
  SetTempHoverBuildingLevel: (buildingId: string, level: number) => void;
  DropTempHoverBuildingLevel: (buildingId: string) => void;
  ShowBuildingConfirm: (buildingId: string) => void;
  HideBuildingConfirm: () => void;
  SelectBuilding: (buildingId: string, clanId: number | void, needSavePosition?: boolean) => void;
  ShowAchivements: () => void;
  HideAchivements: () => void;
  UpdateSoundPosition: () => void;
  InitSounds: () => void;
  UpdateMinZoom: () => void;
  DropSync: () => void;
  navigateToTreasury: () => void;
};

window.L_NO_TOUCH = true;
window.L_DISABLE_3D = true;

class ContainerSupply extends PureComponent<IContainerSupply, object> {
  map: any = null;
  highlightTimers = {};
  tooltipTimers = {};
  state = {};
  saveTooltip = false;

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    this.dropSelection();
    this.props.DropSync();
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    updateBlurBgMap(this.props.isSidebarOpened ? 400 : 0, true);
    this.props.UpdateMinZoom();
  };

  onZoomEnd = (e) => {
    this.props.UpdateSoundPosition();
    this.props.SetMapZoom(e.target._zoom);
  };

  dropSelection = () => {
    this.props.SetSelectedBuilding(null);
    this.props.HideBuildingConfirm();
  };

  updateBlur = () => {
    if (this.props.selectedBuildingId) {
      addBlur(this.props.selectedBuildingId);
      addMarkerBlur(this.props.selectedBuildingId);
    } else {
      removeBlur();
    }
  };

  setMapBgRef = (ref) => {
    this.map = ref;
  };

  onMapMove = () => {
    if (window.tooltipProvider) {
      const isTooltipShown = window.tooltipProvider.isVisible();
      if (isTooltipShown && window.tooltipProvider) {
        window.tooltipProvider.hide();
      }
    }
    this.props.UpdateSoundPosition();
  };

  increaseBuildingLevel = (buildingId) => () => {
    playClanBaseBuilding();
    this.props.IncreaseBuildingLevel(buildingId);
  };

  setSelectedBuilding = (buildingId) => () => {
    this.props.SetSelectedBuilding(buildingId);
  };

  setTempHoverLevel = (building: any) => (meta: any, level: number) => {
    this.props.SetTempHoverBuildingLevel(building.name, {
      ...meta,
      level: level,
    });
  };

  dropTempHoverLevel = (building: any) => () => {
    if (this.props.tempHoverBuildingLevel) {
      this.props.DropTempHoverBuildingLevel(building.name);
    }
  };

  showBuildingConfirm = (building: any) => {
    this.props.ShowBuildingConfirm(building.name);
  };

  selectBuilding = (building: any, needSavePosition?: boolean) => {
    this.props.SelectBuilding(building, this.props.clanId, needSavePosition);
  };

  onMoveEnd = () => {};

  render() {
    this.updateBlur();
    return (
      <IsFromSearchContextConsumer>
        {(isFromSearch) => {
          return (
            <ViewSupply
              {...this.props}
              isFromSearch={isFromSearch}
              isOwnBase={this.props.isOwnBase}
              selectBuilding={this.selectBuilding}
              onMapMove={this.onMapMove}
              onMoveEnd={this.onMoveEnd}
              onZoomEnd={this.onZoomEnd}
              dropSelection={this.dropSelection}
              updateBlur={this.updateBlur}
              highlightTimers={this.highlightTimers}
              tooltipTimers={this.tooltipTimers}
              increaseBuildingLevel={this.increaseBuildingLevel}
              setSelectedBuilding={this.setSelectedBuilding}
              setTempHoverLevel={this.setTempHoverLevel}
              dropTempHoverLevel={this.dropTempHoverLevel}
              showBuildingConfirm={this.showBuildingConfirm}
            />
          );
        }}
      </IsFromSearchContextConsumer>
    );
  }
}

const mapStateToProps = (state: State, ownProps) => {
  const currentClanLadderInfo = state.clans.items[state.currentAccount.clanId];
  const activeClanLadderInfo = state.clans.items[ownProps.clanId];

  return {
    battlesStats: activeClanLadderInfo ? activeClanLadderInfo.stats : {},
    accumulativeResource: currentClanLadderInfo ? currentClanLadderInfo.accumulativeResource : 0,
    balance: currentClanLadderInfo ? currentClanLadderInfo.personalResource : 0,
    initialZoom: state.ReducerSupply.initialZoom,
    zoom: state.ReducerSupply.zoom,
    position: state.ReducerSupply.position,
    initialMaxBounds: state.ReducerSupply.initialMaxBounds,
    maxBounds: state.ReducerSupply.maxBounds,
    isMapBlured: state.ReducerSupply.isMapBlured,
    buildings: state.ReducerSupply.buildings,
    selectedBuildingId: state.ReducerSupply.selectedBuildingId,
    prevSelectedBuildingId: state.ReducerSupply.prevSelectedBuildingId,
    highlightedBuildingId: state.ReducerSupply.highlightedBuildingId,
    highlightDelay: state.ReducerSupply.highlightDelay,
    mapHost: state.ReducerSupply.mapHost,
    isSidebarOpened: state.ReducerSupply.isSidebarOpened,
    tempHoverBuildingLevel: state.ReducerSupply.tempHoverBuildingLevel,
    tempHoverBuildingId: state.ReducerSupply.tempHoverBuildingId,
    buildingInProgress: state.ReducerSupply.buildingInProgress,
    isOwnBase: ownProps.clanId === state.currentAccount.clanId,
    confirmBuildingId: state.ReducerSupply.confirmBuildingId,
    hasTooltipConflict: state.ReducerSupply.hasTooltipConflict,
    hasBuildPermission: state.ReducerSupply.hasBuildPermission,
    minZoom: state.ReducerSupply.minZoom,
    maxZoom: state.ReducerSupply.maxZoom,
    backgroundUrl: state.ReducerSupply.backgroundUrl,
    isDevMode: state.ReducerSupply.isDevMode,
    regularRewards: state.ReducerTreasury.regularRewards,
    edgesVisible: state.ReducerSupply.edgesVisible,
  };
};

const mapDispatchToProps = (dispatch: IAppDispatch) => {
  return {
    SetMapRef: (ref: Map) => {
      dispatch(setMapRefThunk(ref));
    },
    SetMapRefBg: (ref: Map) => {
      if (ref && ref.leafletElement) {
        dispatch(actionsSupply.setMapRefBg(ref));
      }
    },
    SetMapBlur: (isMapBlured: boolean) => {
      dispatch(actionsSupply.setMapBlur(isMapBlured));
    },
    SetSelectedBuilding: (buildingId: string | null) => {
      dispatch(setSelectedBuildingThunk(buildingId));
    },
    SetHighlightedBuilding: (buildingId: string | null) => {
      dispatch(actionsSupply.setHighlightedBuilding(buildingId));
    },
    SetMapZoom: (zoom: number) => {
      dispatch(actionsSupply.setMapZoom(zoom));
    },
    OpenSupplySideBar: () => {
      dispatch(openSupplySideBarThunk());
    },
    CloseSupplySideBar: () => {
      dispatch(closeSupplySidebarThunk());
    },
    SetBuildingLevel: (buildingId: BUILDINGS, level: number) => {
      dispatch(setBuildingLevelThunk(buildingId, level));
    },
    IncreaseBuildingLevel: (buildingId: BUILDINGS) => {
      void dispatch(increaseBuildingLevelThunk(buildingId));
    },
    SetTempHoverBuildingLevel: (buildingId: string, level: number) => {
      dispatch(actionsSupply.setTempHoverBuildingLevel(buildingId, level));
    },
    DropTempHoverBuildingLevel: () => {
      dispatch(actionsSupply.dropTempHoverBuildingLevel());
    },
    ShowBuildingConfirm: (buildingId: string) => {
      dispatch(actionsSupply.showBuildingConfirm(buildingId));
    },
    HideBuildingConfirm: () => {
      dispatch(actionsSupply.hideBuildingConfirm());
    },
    SelectBuilding: (buildingId: string, clanId: number, needSavePosition: boolean) => {
      dispatch(selectBuildingThunk(buildingId, clanId, needSavePosition));
    },
    UpdateSoundPosition: () => {
      dispatch(updateSoundPosition());
    },
    InitSounds: () => {
      dispatch(initSounds());
    },
    UpdateMinZoom: () => {
      dispatch(updateMinZoomThunk());
    },
    DropSync: () => {
      dispatch(actionsSupply.dropSync());
    },
    navigateToTreasury: () => {
      dispatch(navigateToTreasuryThunk());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContainerSupply);
