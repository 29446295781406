import { useEffect, useState } from 'react';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const useCountdown = (targetDate: number) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, SECOND);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
  const days = Math.max(Math.floor(countDown / DAY), 0);
  const hours = Math.max(Math.floor((countDown % DAY) / HOUR), 0);
  const minutes = Math.max(Math.floor((countDown % HOUR) / MINUTE), 0);
  const seconds = Math.max(Math.floor((countDown % MINUTE) / SECOND), 0);

  return { days, hours, minutes, seconds };
};

export default useCountdown;
