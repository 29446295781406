import find from 'lodash/find';

import { COMPETITION_METRIC, RESULTS } from '~/Components/UIKit';

import { t } from './localization';

import type { IHistoryRound, IHistoryRoundClan, IHistoryRoundPlayer, WarSettingsLeague } from '~/types/declaration';

export const ROUND_TYPE = {
  GROUP: 'group',
  PAIR: 'pair',
};

export const MEDALS_MAP = {
  [1]: {
    name: t('Лучший экипаж'), // High Crew
  },
  [2]: {
    name: t('Морские волки'), // Sea Dogs
  },
  [3]: {
    name: t('Ветераны'), // Veterans
  },
  [4]: {
    name: t('Старая гвардия'), // Old Guard
  },
  [5]: {
    name: t('Последний рубеж'), // The Last Stand
  },
};

export const getLeagueData = (leagues: Array<WarSettingsLeague>, leagueNumber: number): WarSettingsLeague => {
  const league = find(leagues, (league) => league.number === leagueNumber);
  return (
    league || {
      icons: {
        default: '',
      },
      number: 0,
      points: [],
      title: '',
    }
  );
};

export const getCompetitionMetricPoints = (competitionMetric: string) => {
  switch (competitionMetric) {
    case COMPETITION_METRIC.EXP: {
      return t('Заработанный чистый опыт');
    }

    case COMPETITION_METRIC.DAMAGE: {
      return t('Нанесенный урон');
    }

    case COMPETITION_METRIC.RIBBONS: {
      return t('Количество лент');
    }

    default: {
      return t('Заработанный чистый опыт');
    }
  }
};

const getClan = (roundData: IHistoryRound, clanId: number): IHistoryRoundClan | undefined => {
  return roundData.clans.find((clan) => clan.id === clanId);
};

export const getOwnClan = (roundData: IHistoryRound): IHistoryRoundClan | undefined => {
  return getClan(roundData, roundData.ownClanId);
};

const getResult = (roundData: IHistoryRound, clanId: number): string => {
  const clan = getClan(roundData, clanId);
  if (roundData.preparationProgress < roundData.maxPreparationProgress || clan === undefined || clan.result === null) {
    return RESULTS.FAILED_PREPARATION;
  }

  switch (clan.result) {
    case 'victory': {
      return RESULTS.WIN;
    }
    case 'big_victory': {
      return RESULTS.WIN;
    }
    case 'draw': {
      return RESULTS.DRAW;
    }
    default: {
      return RESULTS.DEFEAT;
    }
  }
};

export const getOwnResult = (roundData: IHistoryRound): string => {
  return getResult(roundData, roundData.ownClanId);
};

export const getEnemyClans = (roundData: IHistoryRound): Array<IHistoryRoundClan> => {
  return roundData.clans.filter((clan) => clan.id !== roundData.ownClanId);
};

export const getOwnPlayers = (roundData: IHistoryRound): IHistoryRoundPlayer[] => {
  return getOwnClan(roundData)?.players || [];
};

export const getVersusTitle = (result: string) => {
  switch (result) {
    case RESULTS.WIN: {
      return t('Победа');
    }

    case RESULTS.DEFEAT: {
      return t('Поражение');
    }

    case RESULTS.DRAW: {
      return t('Ничья');
    }

    default: {
      return '';
    }
  }
};
