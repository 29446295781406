import PropTypes from 'prop-types';
import * as React from 'react';
import { translate } from 'react-i18next';
import { Input } from '@wg/wows-react-uikit';

import settings from '~/settings';

const propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  isValidating: PropTypes.bool,
  isFocusOn: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const ClanNameInput = (props) => {
  const t = props.t;
  const value = props.value;
  const onChange = props.onChange;

  const handleChange = (event) => {
    const eventValue = event.target.value;
    if (eventValue === value) {
      return;
    }

    let error = null;
    const clanName = eventValue.trim();

    if (clanName.length === 0) {
      error = t('Введите название клана');
    } else if (clanName.length < settings.clanCreate.nameMinLength) {
      error = t('Минимальное количество символов: %(minLength)s', {
        minLength: settings.clanCreate.nameMinLength,
      });
    } else if (eventValue.length > settings.clanCreate.nameMaxLength) {
      error = t('Максимальное количество символов: %(maxLength)s', {
        maxLength: settings.clanCreate.nameMaxLength,
      });
    }
    onChange(eventValue, error);
  };

  return (
    <Input
      label={t('Название')}
      value={value}
      width="100%"
      maxLength={settings.clanCreate.nameMaxLength}
      error={props.error}
      isDisabled={props.isDisabled}
      isValidating={props.isValidating}
      isCounted
      isFocusOn={props.isFocusOn}
      onChange={handleChange}
      t={t}
    />
  );
};

ClanNameInput.propTypes = propTypes;

export default translate()(ClanNameInput);
