import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import Link from 'react-router/lib/Link';
import { ProgressBar, Guidingtip, GUIDINGTIP_POSITIONS, GUIDINGTIP_THEMES } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { ONBOARDING_GUIDES_CONFIG } from '~/const/onboarding';
import { sawClanStars } from '~/constants';
import { thousands } from '~/helpers/formatting';
import { getValueFromLocalStorage } from '~/helpers/localStorage';
import { isChinaRealm } from '~/helpers/realm';
import { iHavePermission, PERMISSIONS, type ROLE_NAMES } from '~/roles';
import routes, { ROUTES_MAP } from '~/routes';
import { removeOldLocalStorageObjects } from '~/utils/removeOldLocalStorageObjects';
import { playButtonSound } from '~/web2ClientAPI/sounds';
import { actionsAutocomplete } from '~/Actions/ActionAutocomplete';
import { actionsSearch } from '~/Actions/ActionSearch';

import ParticleIcon from '~/Components/ParticleIcon/ParticleIcon';

import styles from './Nav.scss';

import type { IRoute } from '~/types/router';

type PropsType = {
  currentAccount: {
    clanId?: number;
    id?: number;
    roleName: ROLE_NAMES;
    activeInvitesCount: number;
    activeApplicationsCount: number;
    leveling?: number;
    accumulativeClanResource?: number;
    isBonusActivated?: boolean;
  };
  root: string;
  // used for rerender component after route path changed
  routePath?: string;
  isBlured?: boolean;
  t: () => void;
  finishedTaskCount?: number;
  clanstarsNewRewards?: number;
  state: any;
};

class Nav extends React.PureComponent<PropsType> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    removeOldLocalStorageObjects();
  }

  onRouteClick(_: React.MouseEvent, route: IRoute) {
    playButtonSound();

    // @TODO: react-router upgrade
    // Refactor after upgrade react-router. Use navigation hooks instead
    if (this.props.routePath === `${this.props.root}${route.path}` && route.path === ROUTES_MAP.SEARCH) {
      this.props.dispatch(actionsAutocomplete.clearAutocompleteState());
      this.props.dispatch(actionsSearch.clearSearchState());
    }
  }

  render() {
    const { root, currentAccount, state } = this.props;
    const linkItems = routes
      .filter((route) => !route.display || route.display(currentAccount, state))
      .map((route, index) => {
        let badgeValue: Nullable<number | string> = null;
        let renderBadge: React.ReactNode = null;
        let badgeClassNames = styles.badge;
        const isLinkActive = window.location.pathname.includes(`${root}${route.path}`);

        if (route.badge === 'requests' && currentAccount.activeInvitesCount && !currentAccount.clanId) {
          badgeValue = currentAccount.activeInvitesCount;
        } else if (route.badge === 'requests' && currentAccount.activeApplicationsCount && currentAccount.clanId) {
          badgeValue = currentAccount.activeApplicationsCount;
        }
        if (route.badge === 'clanstars' && this.props.clanstarsNewRewards && this.props.clanstarsNewRewards > 0) {
          badgeValue = this.props.clanstarsNewRewards;
        }
        if (Number(badgeValue) > 99) {
          badgeClassNames = classNames(styles.badge, styles.badgeBigNumber);
          badgeValue = '99+';
        }

        renderBadge = badgeValue ? (
          <span id={`${route.badge}-badge`} className={badgeClassNames}>
            {badgeValue}
          </span>
        ) : null;

        const showRecommendationBadge = moment(settings.releaseFeaturesDates.recommendations)
          .add(2, 'weeks')
          .isAfter(moment());
        const sawRecommendations = getValueFromLocalStorage('sawRecommendations');

        const label = isChinaRealm() ? '新' : 'NEW';

        if (route.badge === 'recommendations' && showRecommendationBadge && !sawRecommendations[currentAccount.id]) {
          renderBadge = (
            <span id={`${route.badge}-badge`} className={styles.badgeNew}>
              {label}
            </span>
          );
        }

        const showClanRec2Badge = moment(settings.releaseFeaturesDates.clanrec2).add(2, 'weeks').isAfter(moment());
        const sawClanRec2 = getValueFromLocalStorage('sawClanRec2');

        if (route.badge === 'new-recommendations' && showClanRec2Badge && !sawClanRec2[currentAccount.id]) {
          renderBadge = (
            <span id={`${route.badge}-badge`} className={styles.badgeNew}>
              {label}
            </span>
          );
        }

        const showClanTasksBadge = moment(settings.releaseFeaturesDates.darwin).add(4, 'weeks').isAfter(moment());
        const showClanTasks = getValueFromLocalStorage('sawClanTasks');

        if (route.badge === 'tasks' && showClanTasksBadge && !showClanTasks[currentAccount.id]) {
          renderBadge = (
            <span id={`${route.badge}-badge`} className={styles.badgeNew}>
              {label}
            </span>
          );
        }

        if (route.badge === 'clanstars' && !sawClanStars[currentAccount.id]) {
          renderBadge = (
            <span id={`${route.badge}-badge`} className={styles.badgeNew}>
              {label}
            </span>
          );
        }

        const canClaimRewards = iHavePermission(PERMISSIONS.CHANGE_SETTINGS, currentAccount);
        const hasFinishedTasks = route.badge === 'tasks' && this.props.finishedTaskCount > 0;

        if (hasFinishedTasks && canClaimRewards) {
          renderBadge = (
            <span className={styles.badgeTasksIcon}>
              <ParticleIcon />
            </span>
          );
        }

        if (settings.supply.isEnabled && route.path === 'profile' && !currentAccount.isBonusActivated) {
          const needResource = settings.supply.clanLevelingToAccountLeveling[currentAccount.leveling];
          const progressCompleted = currentAccount.accumulativeClanResource / needResource;

          return (
            <Link
              to={`${root}${route.path}`}
              key={index}
              className={classNames(styles.link, styles.link__progressLink, {
                [styles.linkCurrent]: isLinkActive,
                [styles.active]: hasFinishedTasks,
              })}
              onClick={(e: React.MouseEvent) => this.onRouteClick.bind(this)(e, route)}
            >
              <div>
                <div className={styles.title}>{route.title()}</div>
                <ProgressBar size="small" completed={progressCompleted} isNavProgress />
                <div className={styles.progressText}>
                  <div className={styles.navClanResource}>{thousands(currentAccount.accumulativeClanResource)}</div>
                  &nbsp;/&nbsp;
                  <div className={styles.navClanResource}>{thousands(needResource)}</div>
                </div>
              </div>
            </Link>
          );
        }

        let renderContent: React.ReactNode = (
          <>
            {route.title?.()}
            {renderBadge}
          </>
        );

        if (route.path === (ROUTES_MAP.CSTARS as string)) {
          const guidingTip = ONBOARDING_GUIDES_CONFIG.CLANSTARS_REFINED;
          renderContent = (
            <Guidingtip
              className={styles.guidingtip}
              name={guidingTip.name}
              header={guidingTip.header}
              content={guidingTip.content}
              position={GUIDINGTIP_POSITIONS.RIGHT}
              theme={GUIDINGTIP_THEMES.CLIENT}
              withBeacon={true}
              delay={1000}
            >
              {route.title?.()}
              {renderBadge}
            </Guidingtip>
          );
        }

        return (
          <Link
            to={`${root}${route.path}`}
            key={index}
            className={classNames(styles.link, {
              [styles.linkSpacer]: !!route.separator,
              [styles.linkCurrent]: isLinkActive,
              [styles.active]: hasFinishedTasks && canClaimRewards,
            })}
            onClick={(e: React.MouseEvent) => this.onRouteClick.bind(this)(e, route)}
            data-staff-name={route.badge}
          >
            {renderContent}
          </Link>
        );
      });

    return (
      <nav className={`${styles.nav} ${styles.blurable} ${this.props.isBlured ? styles.isBlured : ''}`}>
        {linkItems}
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isBlured: state.ReducerClanWars.isFromPort,
    finishedTaskCount: state.ReducerClanTasks.tasks.filter((task) => task.isFinished && !task.isClaimed).length,
    clanstarsNewRewards: state.ReducerClanStars.accountRewards.filter(
      (reward) => reward.status === 'New' || reward.status === 'Failed',
    ).length,
    state: { ...state },
  };
};

export default connect(mapStateToProps)(Nav);
