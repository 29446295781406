import PropTypes from 'prop-types';
import * as React from 'react';
import { IconTable, TableHead, TableHeadCell } from '@wg/wows-react-uikit';

import headers from './headers.settings';

class ClansRatingSearchResultTableHead extends React.PureComponent {
  static propTypes = {
    stickyContainerId: PropTypes.string.isRequired,
  };

  onClick(e) {
    e.preventDefault();
  }

  render() {
    const { stickyContainerId } = this.props;

    const headersCell = headers().map((item, index) => {
      if (item.isHidden) return null;

      return (
        <TableHeadCell
          key={index}
          modify={item.modify}
          isSortable={false}
          isActive={false}
          onClick={this.onClick}
          isHidden={item.isHidden}
        >
          {item.glyph ? <IconTable glyph={item.glyph} /> : null}
          {item.text}
        </TableHeadCell>
      );
    });

    return <TableHead stickyContainerId={stickyContainerId}>{headersCell}</TableHead>;
  }
}

export default ClansRatingSearchResultTableHead;
