import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import { playCardClickSound } from '@wg/web2clientapi/sound';
import { Checkbox, IconTable, TableHead } from '@wg/wows-react-uikit';

import { isChinese } from '~/helpers/realm';

import styles from './MembersTableHead.scss';
import headers from './headers.settings';
import TableHeadCell from '../Table/TableHeadCell';

const HIDDEN_SORTING_NAMES = headers()
  .filter((item) => item.isHiddenSortingEnabled)
  .map((item) => item.name);

const CLAN_BATTLES_TYPE = 'cvc';

class MembersTableHead extends React.PureComponent {
  static propTypes = {
    allMembersIsChecked: PropTypes.bool.isRequired,
    sort: PropTypes.shape({
      field: PropTypes.string.isRequired,
      isAsc: PropTypes.bool.isRequired,
    }).isRequired,
    stickyContainerId: PropTypes.string.isRequired,

    stickyAppContainerId: PropTypes.string,
    stickyScrollContainerId: PropTypes.string,

    isActiveSticky: PropTypes.bool,
    displayCheckboxes: PropTypes.bool,
    isMember: PropTypes.bool,
    currentBattleType: PropTypes.string,

    onThSortClick: PropTypes.func.isRequired,
    onTickAllMembers: PropTypes.func.isRequired,
  };

  onClick(e, item) {
    e.preventDefault();
    if (item.isSortable) {
      void playCardClickSound();
      const isAsc = item.name === this.props.sort.field ? !this.props.sort.isAsc : item.sortDefaultIsAsc;
      this.props.onThSortClick(item.name, isAsc, HIDDEN_SORTING_NAMES);
    }
  }

  render() {
    let tableCellWithCheckboxes = null;
    if (this.props.displayCheckboxes) {
      tableCellWithCheckboxes = (
        <TableHeadCell modify="check">
          <Checkbox onChange={this.props.onTickAllMembers} isChecked={this.props.allMembersIsChecked} />
        </TableHeadCell>
      );
    }

    return (
      <TableHead
        stickyContainerId={this.props.stickyContainerId}
        stickyAppContainerId={this.props.stickyAppContainerId}
        stickyScrollContainerId={this.props.stickyScrollContainerId}
        isActiveSticky={this.props.isActiveSticky}
      >
        {tableCellWithCheckboxes}
        {headers(this.props.currentBattleType).map((item, index) => {
          if (item.isHidden || (item.forOwnClan && !this.props.isMember)) {
            return null;
          }

          const isHidden =
            this.props.currentBattleType === CLAN_BATTLES_TYPE &&
            ['fragsPerBattle', 'damagePerBattle', 'expPerBattle'].includes(item.name);

          const itemText =
            item.text && (!item.glyph || !isChinese()) ? (
              <span className={item.glyph ? styles.itemText : ''}>{item.text}</span>
            ) : null;

          return (
            <TableHeadCell
              key={index}
              modify={item.modify}
              isSortable={item.isSortable}
              isActive={item.name === this.props.sort.field}
              isHidden={isHidden}
              onClick={(e) => {
                this.onClick(e, item);
              }}
              tooltipText={item.tooltipText}
              tooltipParam={!isChinese() ? item.tooltipParam : ''}
            >
              {item.glyph ? (
                <IconTable
                  className={classNames(styles.itemIcon, {
                    [styles.itemIconOnly]: !itemText,
                  })}
                  glyph={item.glyph}
                />
              ) : null}
              {itemText}
            </TableHeadCell>
          );
        })}
      </TableHead>
    );
  }
}

export default MembersTableHead;
