import React from 'react';
import { Button } from '@wg/wows-react-uikit';

import styles from '~/Containers/DevMenu/DevMenu.scss';

import type { BUILDINGS } from '~/Actions/ActionSupply';
import type { ISupplyBuildings } from '~/store/slices/settingsSlice';

type PropsType = {
  SetBuildingLevel: (buildingId: BUILDINGS, level: number) => void;
  buildings: Nullable<ISupplyBuildings>;
  edgesVisible: Record<BUILDINGS, boolean>;
  hideEdges: (buildingId: BUILDINGS) => void;
  showEdges: (buildingId: BUILDINGS) => void;
};

const Items: React.FC<PropsType> = ({ buildings, edgesVisible, SetBuildingLevel, showEdges, hideEdges }) => {
  const items: React.ReactNode[] = [];

  for (const key in buildings) {
    if (Object.prototype.hasOwnProperty.call(buildings, key)) {
      const buildingId = key as BUILDINGS;
      const item = buildings[buildingId];
      const edgesVisibleKey = edgesVisible[buildingId];

      items.push(
        <li className={styles.devPanelItem} key={`building-${buildingId}`}>
          <div className={styles.devPanelButton}>
            <Button
              isDisabled={item.level === 0}
              onClick={() => {
                let newLevel = (item.level || 0) - 1;

                if (newLevel < 0) {
                  newLevel = 0;
                }

                SetBuildingLevel(buildingId, newLevel);
              }}
            >
              {'-'}
            </Button>
          </div>
          <span className={styles.devPanelItemName}>{`${item.name} - ${item.level}`}</span>
          <div className={styles.devPanelButton}>
            <Button
              isDisabled={item.level === item.maxLevel}
              onClick={() => {
                let newLevel = (item.level || 0) + 1;

                if (newLevel > item.maxLevel) {
                  newLevel = item.maxLevel;
                }

                SetBuildingLevel(buildingId, newLevel);
              }}
            >
              {'+'}
            </Button>
          </div>
          <div className={styles.devPanelButton}>
            {edgesVisibleKey ? (
              <Button
                onClick={() => {
                  hideEdges(buildingId);
                }}
              >
                {'✕'}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  showEdges(buildingId);
                }}
              >
                {'✓'}
              </Button>
            )}
          </div>
        </li>,
      );
    }
  }

  return <>{items}</>;
};

export default Items;
