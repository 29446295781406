import * as React from 'react';
import { ClanName, Realm, TableBodyCell, TableRow } from '@wg/wows-react-uikit';

import settings, { hasCrossRealm } from '~/settings';
import { SEASON_TYPES } from '~/constants';
import { isoToFormattedLocalDateTime } from '~/helpers/datetime';
import { thousands } from '~/helpers/formatting';
import { t } from '~/helpers/localization';

import { StatisticsValue } from '~/UIKit';
import { Tooltip, TooltipBody } from '~/UIKit/components';

import styles from './ClansRatingTable.scss';

type IClansRatingTableItem = {
  battlesCount: number;
  clanId: number;
  isActive: boolean;
  isDisbanded: boolean;
  isOldSeason?: boolean;
  lastBattle: string;
  lastWin?: string;
  membersCount: number;
  name: string;
  rank: number;
  rating: number;
  realm?: string;
  seasonType?: string;
  tag: string;
  tagColor: string;
  onClick: (clanId: number) => void;
};

const ClansRatingTableItem: React.FC<IClansRatingTableItem> = ({
  battlesCount,
  clanId,
  isActive,
  isDisbanded,
  isOldSeason,
  lastBattle,
  lastWin,
  membersCount,
  name,
  rank,
  rating,
  realm,
  seasonType,
  tag,
  tagColor,
  onClick,
}) => {
  const onRowClick = () => {
    if (realm !== settings.realm.code) return;
    onClick(clanId);
  };

  const renderMembersCount = () => {
    return isOldSeason || seasonType === SEASON_TYPES.BRAWL ? null : (
      <TableBodyCell modify="right">{membersCount}</TableBodyCell>
    );
  };

  const renderWINS = () => {
    return seasonType === SEASON_TYPES.BRAWL ? <TableBodyCell modify="right">{rating}</TableBodyCell> : null;
  };

  const renderLBT = () => {
    return isOldSeason || seasonType === SEASON_TYPES.BRAWL ? null : (
      <TableBodyCell modify="right">{lastBattle ? isoToFormattedLocalDateTime(lastBattle) : '-'}</TableBodyCell>
    );
  };

  const renderLWT = () => {
    return seasonType === SEASON_TYPES.BRAWL ? (
      <TableBodyCell modify="right">{lastWin ? isoToFormattedLocalDateTime(lastWin) : '-'}</TableBodyCell>
    ) : null;
  };

  const renderDisbandedTooltip = (tooltipId: string) => {
    return (
      <Tooltip id={tooltipId}>
        <TooltipBody>{t('Клан распущен')}</TooltipBody>
      </Tooltip>
    );
  };

  const renderOtherRealmTooltipId = (tooltipId: string) => {
    return (
      <Tooltip id={tooltipId}>
        <TooltipBody>{t('Невозможно открыть профиль клана находящегося не в вашем регионе')}</TooltipBody>
      </Tooltip>
    );
  };

  const renderRealm = () => {
    return hasCrossRealm ? (
      <TableBodyCell modify="small">
        <Realm realm={realm as string} realmMap={settings.realmsMap} />
      </TableBodyCell>
    ) : null;
  };

  const renderBattlesCount = () => {
    return seasonType !== SEASON_TYPES.BRAWL ? (
      <TableBodyCell modify="right">
        <StatisticsValue value={thousands(battlesCount)} />
      </TableBodyCell>
    ) : null;
  };

  const renderRating = () => {
    return seasonType !== SEASON_TYPES.BRAWL ? (
      <TableBodyCell modify="right">
        <StatisticsValue value={thousands(rating)} />
      </TableBodyCell>
    ) : null;
  };

  const tooltipId = `item-clan-disbanded-tooltip-${clanId}`;
  const otherRealmTooltipId = realm !== settings.realm.code ? `item-clan-other-realm-tooltip-${clanId}` : null;

  return (
    <div data-tip data-for={tooltipId}>
      <TableRow
        isHovering={!isDisbanded}
        isActive={isActive && !isDisbanded}
        isDisabled={isDisbanded}
        onClick={isDisbanded ? () => {} : onRowClick}
      >
        <TableBodyCell modify="small">
          <StatisticsValue className={styles[`rank-${rank}`]} value={thousands(rank)} />
        </TableBodyCell>
        {renderRealm()}
        <TableBodyCell modify="basis">
          <div data-tip data-for={otherRealmTooltipId} className={styles.clanNameWrap}>
            <ClanName clanName={name} clanTag={tag} clanColor={tagColor} />
            {otherRealmTooltipId && renderOtherRealmTooltipId(otherRealmTooltipId)}
          </div>
        </TableBodyCell>

        {renderRating()}
        {renderBattlesCount()}
        {renderWINS()}
        {renderLBT()}
        {renderLWT()}
        {renderMembersCount()}
      </TableRow>
      {isDisbanded && renderDisbandedTooltip(tooltipId)}
    </div>
  );
};

export default ClansRatingTableItem;
