import React, { Fragment, PureComponent } from 'react';
import {
  ButtonPromo,
  CheckboxWithLabel,
  Divider,
  DivTooltip,
  StepCSSTransitionGroup,
  TooltipBody,
} from '@wg/wows-react-uikit';

import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { t } from '~/helpers/localization';
import { log } from '~/helpers/logging';
import { iHavePermission, PERMISSIONS } from '~/roles';

import styles from './ClanWarsWelcome.scss';

import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';

type PropsTypes = {
  clanId: number | null;
  currentAccount: ICurrentAccountState;
  autoParticipation: boolean;
  toggleAutoParticipation: () => void;
  setIsParticipant: (value: boolean) => void;
  navigateToRecommendations: () => void;
};

class ClanWarsWelcome extends PureComponent<PropsTypes> {
  setIsParticipant = () => {
    this.props.setIsParticipant(true);
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.headImage} />
        <div className={styles.content}>
          <StepCSSTransitionGroup level={1}>
            <h2 className={styles.title}>{t('Морское сражение начинается')}</h2>
          </StepCSSTransitionGroup>
          <StepCSSTransitionGroup level={2}>
            <div className={styles.textBlock}>
              <p>- {t('Нет фиксированного прайм-тайма, играйте когда вам удобно')}</p>
              <p>- {t('Участвуют и получают награды кланы с любой активностью')}</p>
            </div>

            <div className={styles.textBlock}>
              <p>{t('Участвуйте в боях и зарабатывайте награды для себя и вашего клана')}</p>
            </div>
          </StepCSSTransitionGroup>
          {this.props.clanId ? (
            <StepCSSTransitionGroup level={3}>
              <div className={styles.center}>
                <DivTooltip
                  tooltipBody={
                    iHavePermission(PERMISSIONS.CWARS_TOGGLE_PARTICIPATION, this.props.currentAccount) ? null : (
                      <TooltipBody>
                        {t(
                          'Только Командующий вашего клана или Заместитель командующего могут зарегистрировать клан для участия в Морском сражении',
                        )}
                      </TooltipBody>
                    )
                  }
                >
                  <ButtonPromo
                    isCenter
                    isFlat
                    onClick={() => {
                      dwhExport.push(DWH_EVENTS.NAVAL_BATTLES.SET_IS_PARTICIPANT);
                      log('naval_battles.set_is_participant');
                      this.setIsParticipant();
                    }}
                    isDisabled={!iHavePermission(PERMISSIONS.CWARS_TOGGLE_PARTICIPATION, this.props.currentAccount)}
                  >
                    {t('Участвовать')}
                  </ButtonPromo>
                </DivTooltip>
              </div>
            </StepCSSTransitionGroup>
          ) : (
            <Fragment>
              <StepCSSTransitionGroup level={3}>
                <h2 className={styles.title}>{t('Для участия в морском сражении необходимо состоять в клане')}</h2>
              </StepCSSTransitionGroup>
              <StepCSSTransitionGroup level={3}>
                <div className={styles.center}>
                  <ButtonPromo isCenter isFlat onClick={this.props.navigateToRecommendations}>
                    {t('Хочу в клан')}
                  </ButtonPromo>
                </div>
              </StepCSSTransitionGroup>
            </Fragment>
          )}

          {this.props.clanId && (
            <StepCSSTransitionGroup level={4}>
              <div className={styles.center}>
                <DivTooltip
                  tooltipBody={
                    iHavePermission(PERMISSIONS.CWARS_TOGGLE_PARTICIPATION, this.props.currentAccount) ? null : (
                      <TooltipBody>
                        {t(
                          'Только Командующий вашего клана или Заместитель командующего могут зарегистрировать клан для участия в Морском сражении',
                        )}
                      </TooltipBody>
                    )
                  }
                >
                  <div className={styles.checkboxParticipate}>
                    <CheckboxWithLabel
                      labelText={t('Участвовать каждую неделю')}
                      isChecked={this.props.autoParticipation}
                      isDisabled={!iHavePermission(PERMISSIONS.CWARS_TOGGLE_PARTICIPATION, this.props.currentAccount)}
                      onChange={this.props.toggleAutoParticipation}
                    />
                  </div>
                </DivTooltip>
              </div>
            </StepCSSTransitionGroup>
          )}

          <StepCSSTransitionGroup level={5}>
            <div className={styles.divider}>
              <Divider type={'major'} />
            </div>
            <h2 className={styles.title}>{t('Этапы соревнования')}</h2>
            <div className={styles.stages}>
              <div>
                <h3 className={styles.subTitle}>{t('Подготовка')}</h3>
                <p>
                  {t(
                    'Во время подготовительного этапа клан должен выполнить задачу для участия в битве. За выполнение задачи клан так же получает награду.',
                  )}
                </p>
                <p>{t('Начать подготовку можно в любой день до начала битвы.')}</p>
              </div>
              <div>
                <h3 className={styles.subTitle}>{t('Противостояние кланов')}</h3>
                <p>{t('Битва занимает %(battleDelay)s дня после этапа Подготовки.', { battleDelay: 3 })}</p>
                <p>{t('В начале этого этапа мы вам подберем в противники схожий по активности клан. ')}</p>
              </div>
            </div>
          </StepCSSTransitionGroup>
        </div>
        <div style={{ height: '100px' }} />
      </div>
    );
  }
}

export default ClanWarsWelcome;
