import classNames from 'classnames';
import * as React from 'react';

import styles from './ButtonScroll.scss';

interface IButtonToTop {
  onClick: () => void;
  isHideIconScroll: boolean;
}

const ButtonScrollToBottom: React.FC<IButtonToTop> = ({ isHideIconScroll, onClick }) => {
  return (
    <>
      <div
        className={classNames(styles.iconMouse, {
          [styles.isHide]: isHideIconScroll,
        })}
        onClick={onClick}
      />
      <div
        className={classNames(styles.iconArrow, {
          [styles.isHide]: isHideIconScroll,
        })}
        onClick={onClick}
      />
    </>
  );
};

export default ButtonScrollToBottom;
