import classNames from 'classnames';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { OnboardingProvider } from '@wg/wows-react-uikit/features/onboarding/provider/onboardingProvider';

import { fetchOnboardingCompletedSteps, fetchOnboardingCompleteSteps } from '~/api/onboarding';
import { onboardingStepsList } from '~/const/onboarding';

import { isLoggedIn } from '~/utils/auth';

import { IsFromSearchContextProvider } from '~/Providers/IsFromSearch';
import DialogsManager from '~/Components/DialogsManager/DialogsManager';

import { Back, ClansInformerButton, Nav, NavClanProfile } from '~/UIKit';

import styles from './Layout.scss';

import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';

interface ILayoutProps {
  children: React.ReactNode;
  root: string;
  needShowOverlay?: boolean;
  currentAccount: ICurrentAccountState;
  location?: Record<string, any>;
  routePath?: string;
  params?: Record<string, any>;
}

interface ILayoutState {
  showShadowTop: boolean;
  showShadowBottom: boolean;
  shadowPositionLeft: number;
  shadowWidth: number;
  prevScrollPosition: number;
  onboardingCompletedSteps: Nullable<string[]>;
}

const headerWithTabsUrls = ['/clan-wars', '/clan-battles'];

class Layout extends React.PureComponent<ILayoutProps, ILayoutState> {
  private appContainer: HTMLElement;
  private _section: HTMLElement | null;

  constructor(props) {
    super(props);

    this.state = {
      showShadowTop: false,
      showShadowBottom: false,
      shadowPositionLeft: 0,
      shadowWidth: 0,
      prevScrollPosition: 0,
      onboardingCompletedSteps: null,
    };

    this.updateShadows = this.updateShadows.bind(this);
    this.completeSteps = this.completeSteps.bind(this);
  }

  componentDidMount() {
    this.appContainer = document.getElementById('app');
    this.updateShadows();

    if (isLoggedIn()) {
      void fetchOnboardingCompletedSteps().then((steps) => {
        if (!steps) {
          return;
        }
        this.setState({
          onboardingCompletedSteps: steps,
        });
      });
    }

    this.appContainer.addEventListener('scroll', this.updateShadows);
    window.addEventListener('resize', this.updateShadows);
  }

  completeSteps(stepsNames: string[]) {
    if (!isLoggedIn()) {
      return;
    }
    void fetchOnboardingCompleteSteps(stepsNames);
  }

  componentDidUpdate() {
    this.updateShadows();
  }

  componentWillUnmount() {
    this.appContainer.removeEventListener('scroll', this.updateShadows);
    window.removeEventListener('resize', this.updateShadows);
  }

  updateShadows() {
    const scrollPosition = this.appContainer.scrollTop;
    const scrollHeight = this.appContainer.scrollHeight - this.appContainer.clientHeight;

    this.setState({
      showShadowTop: scrollPosition > 0,
      showShadowBottom: scrollPosition < scrollHeight,
      shadowPositionLeft: this._section?.getBoundingClientRect().left || 0,
      shadowWidth: this._section?.getBoundingClientRect().width || 0,
      prevScrollPosition: scrollPosition > 0 ? scrollPosition : this.state.prevScrollPosition,
    });
  }

  renderBack() {
    return (
      <div className={styles.back}>
        <Back />
      </div>
    );
  }

  renderSideBar() {
    return this.props.routePath.includes('clan-profile') ? (
      <NavClanProfile root={this.props.root} clanId={this.props.params.clanId} />
    ) : (
      <Nav root={this.props.root} currentAccount={this.props.currentAccount} routePath={this.props.routePath} />
    );
  }

  renderStatisticHelper() {
    const source = this.props.location?.query?.source || this.props.source || 'profile';

    return <ClansInformerButton routePath={this.props.routePath} clanId={this.props.params.clanId} source={source} />;
  }

  render() {
    const classNameMain = classNames(styles.main, {
      overlay: this.props.needShowOverlay,
    });

    const classNameShadowTop = classNames(styles.shadowTop, {
      [styles.isVisible]: this.state.showShadowTop,
    });

    const classNameShadowBottom = classNames(styles.shadowBottom, {
      [styles.isVisible]: this.state.showShadowBottom,
    });

    const bottomStyle = {
      left: this.state.shadowPositionLeft,
      width: this.state.shadowWidth,
    };

    const topStyle = {
      left: this.state.shadowPositionLeft,
      width: this.state.shadowWidth,
    };

    let needUpdateHeader = false;

    headerWithTabsUrls.forEach((url) => {
      if (!needUpdateHeader && this.props.routePath.indexOf(url) !== -1) {
        needUpdateHeader = true;
      }
    });

    if (
      this.appContainer &&
      this.state.prevScrollPosition > 0 &&
      this.appContainer.scrollTop === 0 &&
      window.holdScrollPosition
    ) {
      this.appContainer.scrollTop = this.state.prevScrollPosition;
    }

    const isOpenedFromSearch = this.props.routePath.includes('clan-profile');

    return (
      <OnboardingProvider
        steps={onboardingStepsList}
        completedSteps={this.state.onboardingCompletedSteps}
        completeSteps={this.completeSteps}
      >
        <IsFromSearchContextProvider isFromSearch={isOpenedFromSearch}>
          <main className={classNameMain}>
            {this.props.routePath.includes('clan-profile') && this.renderBack()}
            {this.renderStatisticHelper()}
            {createPortal(
              <aside className={styles.aside}>{this.renderSideBar()}</aside>,
              document.querySelector('body'),
            )}
            <section className={styles.section} ref={(c) => (this._section = c)}>
              <div className={classNameShadowTop} style={topStyle} />
              {this.props.children}
              <div className={classNameShadowBottom} style={bottomStyle} />
            </section>
          </main>
          <DialogsManager />
        </IsFromSearchContextProvider>
      </OnboardingProvider>
    );
  }
}

export default Layout;
