import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import settings from '~/settings';

import { TYPE_GLOBAL_SEARCH } from '~/constants';
import { getLastSeason, isGlobalSearchEnabled } from '~/helpers/ladder';
import { getLocalizationSafe } from '~/helpers/localization';
import { getSeasonInfo } from '~/helpers/seasons';

import { actionsAutocomplete, fetchAutompleteResult } from '~/Actions/ActionAutocomplete';
import { fetchClan } from '~/Actions/ActionClanProfile';
import {
  actionsClanRating,
  getLeagueDivisionRating,
  toggleCrossRealmLadder,
  toggleCurrentRealm,
  toggleCurrentSeason,
  toggleCurrentSeasonType,
  toggleDivision,
  toggleLeague,
} from '~/Actions/ActionClanRating';
import { actionsSearch, fetchSearchResult } from '~/Actions/ActionSearch';

import { ClansRating } from '~/UIKit';

import type { SEASON_TYPES } from '~/constants';
import type { IAppDispatch, RootState } from '~/store';
import type { IClanData } from '~/types/declaration';

const getRatingRealm = (id, currentSeason, state) => {
  if (!state.clans.wowsLadder[id]) {
    return null;
  }
  const ratingArray = state.clans.wowsLadder[id].ratings.filter((rating) => rating.season_number === currentSeason);
  return ratingArray.length > 0 ? ratingArray[0].realm : null;
};

const mapStateToProps = (state: RootState) => {
  const ladder = getSeasonInfo(state.clansRating.currentSeason);
  const hasError = isEmpty(ladder) || !ladder;
  const currentSeason = parseInt(state.clansRating.currentSeason, 10);
  const lastSeason = getLastSeason() || {};
  const clanHomeRealm = settings.realm.code;
  const currentRealm = state.clansRating.currentRealm || (isGlobalSearchEnabled ? TYPE_GLOBAL_SEARCH : clanHomeRealm);
  return {
    hasError: hasError,
    autocomplete: state.autocomplete,
    currentAccount: state.currentAccount,
    clanInfo: state.clans.items[state.currentAccount.clanId],
    clanId: state.currentAccount.clanId,
    currentClanId: state.clansRating.currentClanId,
    leagues: ladder.leagues,
    seasonType: ladder.kind,
    currentRealm,
    seasonInfo: {
      icons: ladder.icons,
      name: getLocalizationSafe(ladder, 'title'),
    },
    currentLeague: state.clansRating.currentLeague,
    currentDivision: state.clansRating.currentDivision,
    isCrossRealmLadder: state.clansRating.isCrossRealmLadder,
    currentSeasonType: state.clansRating.currentSeasonType || lastSeason.kind,
    currentSeason: currentSeason,
    ratingLists: state.clansRating.ratingLists,
    isError: state.clansRating.isError,
    isFetching: state.clansRating.isFetching,
    errorMessage: state.clansRating.errorMessage,
    wowsLadder: state.clans.wowsLadder[state.currentAccount.clanId],
    searchResult: state.searchResult,
    root: state.urls.root,

    // TODO: Define clanHomeRealm by Ladder's settings (after ladder fixes)
    // clanHomeRealm: state.currentAccount.clanId ? state.clans.wowsLadder[state.currentAccount.clanId].realm : null,
    clanHomeRealm,
    clanBattleRealm: state.currentAccount.clanId
      ? getRatingRealm(state.currentAccount.clanId, currentSeason, state)
      : null,
  };
};

const mapDispatchToProps = (dispatch: IAppDispatch) => {
  return {
    onDivisionChange: (division: number, ladderIsUpdate?: boolean) => {
      dispatch(toggleDivision(division, ladderIsUpdate));
    },
    onLeagueChange: (league: number, ladderIsUpdate?: boolean) => {
      dispatch(toggleLeague(league, ladderIsUpdate));
    },
    onLeagueAndDivisionChange: ({ league, division, clanId, withGlobalSpinner, ownClan }) => {
      void dispatch(getLeagueDivisionRating({ league, division, clanId }, withGlobalSpinner, ownClan));
    },
    onCrossRealmChange: (isCrossRealm: boolean) => {
      dispatch(toggleCrossRealmLadder(isCrossRealm));
    },
    onSeasonTypeChange: (seasonType: SEASON_TYPES) => {
      dispatch(actionsSearch.changeTerm(''));
      dispatch(toggleCurrentSeasonType(seasonType));
      dispatch(actionsAutocomplete.clearAutocompleteState());
      dispatch(actionsSearch.clearSearchState());
    },
    onSeasonChange: (season: number) => {
      dispatch(actionsSearch.changeTerm(''));
      dispatch(toggleCurrentSeason(season));
      dispatch(actionsAutocomplete.clearAutocompleteState());
      dispatch(actionsSearch.clearSearchState());
    },
    onRealmChange: (realm: string) => {
      dispatch(actionsSearch.changeTerm(''));
      dispatch(toggleCurrentRealm(realm));
      dispatch(actionsAutocomplete.clearAutocompleteState());
      dispatch(actionsSearch.clearSearchState());
    },
    onRender: (clanId: number) => {
      void dispatch(fetchClan(clanId));
    },
    toggleRatingFetch: (isFetching: boolean) => {
      dispatch(actionsClanRating.toggleRatingFetch(isFetching));
    },
    setCurrentClanId(clanId: number) {
      dispatch(actionsClanRating.setCurrentClanId(clanId));
    },
    clearState: () => {
      dispatch(actionsAutocomplete.clearAutocompleteState());
      dispatch(actionsSearch.clearSearchState());
    },
    onAutocompleteItemClick: (item: IClanData, accountId: number) => {
      dispatch(actionsSearch.appendTermToHistory(item, accountId));
    },
    onSearchInputChange: (term: string) => {
      return dispatch(fetchAutompleteResult({ term, type: 'clansRating' }));
    },
    onSubmit: (term: string) => {
      dispatch(actionsSearch.changeTerm(term));
      return dispatch(fetchSearchResult({ type: 'clansRating' }));
    },
    onReloadClick: () => {
      return dispatch(fetchSearchResult({ type: 'clansRating' }));
    },
    onPageChanged: (page: number) => {
      dispatch(actionsSearch.changePage(page));
      return dispatch(fetchSearchResult({ type: 'clansRating' }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClansRating);
