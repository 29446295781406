import classNames from 'classnames';
import React, { PureComponent } from 'react';
import { EntityTypes } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { DivTooltip, Highlighter, Tooltip, TooltipBody } from '@wg/wows-react-uikit';

import { currencyNamesToWE } from '~/UIKit/Currency/Currency';

import styles from './ResultsHeader.scss';
import Medal from '../Medal/Medal';

const needMedal = false;

export interface ResultsHeaderProps {
  medalData: any;
  params: any;
  withMedal?: boolean;
}

class ResultsHeader extends PureComponent<ResultsHeaderProps> {
  render() {
    return (
      <div className={styles.container}>
        {this.props.medalData && needMedal && (
          <div className={styles.medal}>
            <Medal size="medium" league={this.props.medalData.number} />
          </div>
        )}
        {this.props.params &&
          this.props.params.length &&
          this.props.params.map((param) => {
            return (
              <div className={styles.headerParam} key={`HeaderParam_${param.type}_${param.label}`}>
                {param.rawValue ? (
                  param.rawValue
                ) : !param.isCurrency ? (
                  <DivTooltip
                    className={classNames(
                      styles.headerParamValue,
                      param.type && styles.type,
                      param.type && styles[param.type],
                    )}
                    tooltipBody={
                      <Tooltip>
                        <TooltipBody>{param.tooltipBody}</TooltipBody>
                      </Tooltip>
                    }
                  >
                    <div>
                      <Highlighter className={styles.headerParamValue} value={param.value} />
                    </div>
                  </DivTooltip>
                ) : (
                  <WoWSEntity
                    className={classNames(
                      styles.headerParamValue,
                      param.type && styles.type,
                      param.type && styles[param.type],
                    )}
                    type={EntityTypes.CURRENCY}
                    id={currencyNamesToWE[param.type]}
                    amount={param.amount || param.amount === 0 ? param.amount : param.value}
                    presentation={{
                      bindTooltip: true,
                      withTooltip: true,
                      hideReceiveText: true,
                      hideSpendText: true,
                    }}
                    customisation={{
                      beforeFooterTooltipSlot(node) {
                        node.innerHTML = param.tooltipBody;
                      },
                    }}
                  >
                    <div>
                      <Highlighter className={styles.headerParamValue} value={param.value} />
                    </div>
                  </WoWSEntity>
                )}
                <span className={styles.headerParamLabel}>{`${param.label}`}</span>
              </div>
            );
          })}
      </div>
    );
  }
}

export default ResultsHeader;
