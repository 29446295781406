import { fetchClan } from './ActionClanProfile';

import type React from 'react';
import type { InferActionsType } from '~/Reducers';
import type { AppThunk } from '~/store';

export const NAVIGATE_TO_SLIDE = 'NAVIGATE_TO_SLIDE';
export const SET_SLIDER_REF = 'SET_SLIDER_REF';
export const GET_PROMO_DATA = 'GET_PROMO_DATA';

export type ActionsType = InferActionsType<typeof actionsPromo>;

export const actionsPromo = {
  getPromoDate: () =>
    ({
      type: GET_PROMO_DATA,
    }) as const,
  setSliderRef: (ref: React.Ref<React.ReactElement>) =>
    ({
      type: SET_SLIDER_REF,
      promoSlider: ref ? ref : null,
    }) as const,
  navigateToSlide: (index: number) =>
    ({
      type: NAVIGATE_TO_SLIDE,
      payload: { index },
    }) as const,
};

export const getPromoDataThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  if (state.currentAccount && state.currentAccount.clanId) {
    dispatch(fetchClan(state.currentAccount.clanId));
  }
  dispatch(actionsPromo.getPromoDate());
};

export const navigateToSlideThunk =
  (index: number): AppThunk =>
  (dispatch, getState) => {
    const promoSlider = getState().ReducerPromo.promoSlider;
    if (promoSlider) {
      promoSlider.slickGoTo(index);
    }
    dispatch(actionsPromo.navigateToSlide(index));
  };
