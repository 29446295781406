import PropTypes from 'prop-types';
import * as React from 'react';
import { Interpolate } from 'react-i18next';
import { ClanResource, ProgressBar } from '@wg/wows-react-uikit';

import { t, x } from '~/helpers/localization';

import { TooltipBody } from '~/UIKit/components';

import styles from './Tooltips.scss';

export const AccumulativeClanResourceTooltipContent = (props) => {
  const progressCompleted = props.gainedResource / props.needResource;

  return (
    <TooltipBody>
      <div className={styles.message}>
        <div className={styles.boldMessage}>{t('Нет допуска к клановым бонусам.:tooltip')}</div>
        <Interpolate
          i18nKey={x('Чтобы получить клановые бонусы, необходимо заработать %(needResource)s.:tooltip')}
          needResource={<ClanResource isFlat amount={props.needResource} />}
          t={t}
        />
      </div>
      <ProgressBar size="small" completed={progressCompleted} isNavProgress />
      <div className={styles.progressText}>
        <ClanResource isFlat amount={props.gainedResource} isMuted />
        &nbsp;/&nbsp;
        <ClanResource isFlat amount={props.needResource} isMuted />
      </div>
    </TooltipBody>
  );
};
AccumulativeClanResourceTooltipContent.propTypes = {
  gainedResource: PropTypes.number,
  needResource: PropTypes.number,
};
