import React from 'react';
import { shallowEqual } from 'react-redux';
import { Button, DialogBody, DialogContainer, DialogFooter, DialogHeader } from '@wg/wows-react-uikit';

import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { t } from '~/helpers/localization';
import { useAppDispatch, useAppSelector } from '~/store';
import { claimReward } from '~/Actions/ActionClanStars';

import styles from './CStarsRewardsDialog.scss';
import PromoReward from './PromoReward/PromoReward';

import type { IClanStarsReward } from '~/Reducers/ReducerClanStars';
import type { RootState } from '~/store';
import type { IDialog } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  return {
    rewardsInfo: state.ReducerClanStars.rewardsInfo,
  };
};

type ICStarsRewardsDialog = IDialog<{
  newRewards: IClanStarsReward[];
}>;

const CStarsRewardsDialog: React.FC<ICStarsRewardsDialog> = ({ data, hideDialog }) => {
  const { newRewards } = data;

  const { rewardsInfo } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const handleClaimReward = React.useCallback(() => {
    dwhExport.push(DWH_EVENTS.CLANSTARS.CLAIM_REWARDS);
    void dispatch(claimReward());
    hideDialog();
  }, [dispatch, hideDialog]);

  return (
    <DialogContainer>
      <DialogHeader>{t('Боевые Успехи')}</DialogHeader>
      <DialogBody>
        <div className={styles.wrapper}>
          {t('Награда за полученные звёзды:')}
          <div className={styles.rewardsContainer}>
            {newRewards.map((reward, i) => (
              <PromoReward
                rewardGoal={reward.clanstarsCount}
                items={rewardsInfo[reward.clanstarsCount]}
                key={`promo-reward-${i}`}
              />
            ))}
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button isFlat onClick={handleClaimReward}>
          {t('Забрать')}
        </Button>
      </DialogFooter>
    </DialogContainer>
  );
};

export default React.memo(CStarsRewardsDialog);
