import moment from 'moment';

import settings from '~/settings';

const THAI_LANGUAGE_CODE = 'th';
const THAI_YEAR_OFFSET = 543;

const shiftThaiYear = (momentObj: moment.Moment) => {
  return settings.realm.languageCode === THAI_LANGUAGE_CODE
    ? moment(momentObj).add(THAI_YEAR_OFFSET, 'year')
    : momentObj;
};

export const isoToFormattedLocalDateTime = (isoString: string) => {
  return isoString ? shiftThaiYear(moment.utc(isoString)).local().format(settings.formats.datetime) : '—';
};

export const isoToFormattedLocalDate = (isoString: string) => {
  return shiftThaiYear(moment.utc(isoString)).local().format(settings.formats.date);
};

export const isoToFormattedLocalTime = (isoString: string) => {
  return moment.utc(isoString).local().format(settings.formats.time);
};

export const unixToFormattedLocalDate = (timestamp: number) => {
  return shiftThaiYear(moment.unix(timestamp)).local().format(settings.formats.date);
};

export const unixToFormattedLocalTime = (timestamp: number) => {
  return moment.unix(timestamp).local().format(settings.formats.time);
};

export const getShiftedIsoString = (seconds: number) => {
  return seconds ? moment().add(seconds, 'seconds').toISOString() : null;
};

export const addLeadingZero = (value: number) => {
  return String(value).padStart(2, '0');
};
