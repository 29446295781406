import settings from '~/settings';
import { t } from '~/helpers/localization';

export default function () {
  return [
    {
      modify: 'left',
      name: 'date',
      text: t('Дата и время'),
    },
    {
      modify: 'center',
      name: 'result',
      text: t('Результат'),
    },
    {
      modify: 'left',
      name: 'map',
      text: t('Карта'),
    },
    {
      modify: 'left',
      isHidden: !settings.ladder.isCrossRealmLadderEnabled,
      name: 'realm',
      text: t('Реалм'),
    },
    {
      modify: 'basis',
      name: 'enemy',
      text: t('Противник'),
    },
  ];
}
