import forEach from 'lodash/forEach';
import moment from 'moment';

import { CWARS_RESTRICTIONS } from '~/constants';

import type { IClanWarsState } from '~/Reducers/ReducerClanWars';
import type { IBattle, ICWarsRoundResult, IGroupedBattles } from '~/types/declaration';

const TWO_DAYS_HOURS = 48;

export const groupBattles = (state: IClanWarsState, payloadBattles: IBattle[]) => {
  const groupedBattles: IGroupedBattles = {};
  let battles: IBattle[] = [];

  if (!payloadBattles) {
    battles = state.stages.war.battles;
  } else {
    const newBattles = payloadBattles.map((battle) => {
      const ship = (battle.shipCd && state.ships[battle.shipCd]) || {};
      return <IBattle>{
        ...battle,
        ship: ship,
      };
    });

    battles = mergeBattles(state.stages.war.battles, newBattles);
  }

  // Group battles by nation and class
  forEach(battles, (battle) => {
    if (!groupedBattles[battle.nation]) {
      groupedBattles[battle.nation] = {};
    }

    if (!groupedBattles[battle.nation][battle.class]) {
      groupedBattles[battle.nation][battle.class] = [];
    }

    groupedBattles[battle.nation][battle.class].push(battle);
  });

  return { groupedBattles, battles };
};

export const getResctrictionReason = (restrictions: CWARS_RESTRICTIONS[]) => {
  let reason: Nullable<number> = null;
  let isSet = false;

  if (restrictions && restrictions.length > 0) {
    restrictions.forEach((item) => {
      if (!isSet) {
        switch (item) {
          case CWARS_RESTRICTIONS.ACCOUNT_NOT_IN_CLAN: {
            reason = 0;
            isSet = true;
            break;
          }
          case CWARS_RESTRICTIONS.CLAN_DOES_NOT_PARTICIPATE: {
            reason = 4;
            isSet = true;
            break;
          }
          case CWARS_RESTRICTIONS.ACCOUNT_MISSED_PREPARATION: {
            reason = 1;
            break;
          }
          case CWARS_RESTRICTIONS.CLAN_MISSED_PREPARATION: {
            reason = 2;
            isSet = true;
            break;
          }
          case CWARS_RESTRICTIONS.CLAN_FAILED_PREPARATION: {
            reason = 3;
            isSet = true;
            break;
          }

          default: {
            return;
          }
        }
      } else {
        return;
      }
    });
  }

  return reason;
};

const mergeBattles = (stateBattles: IBattle[], newBattles: IBattle[]) => {
  let battles = [...stateBattles, ...newBattles];

  const idsSet = new Set();

  // собираем массив завершенных боев
  battles.forEach((item) => {
    if (item.finishedAt) {
      const selector = `${item.arenaId}_${item.clusterId}`;
      idsSet.add(selector);
    }
  });

  // отфильтровываем незаконченные бои если они уже завершились
  battles = battles.filter((b) => {
    if (b.finishedAt) {
      return true;
    }

    const selector = `${b.arenaId}_${b.clusterId}`;
    return !idsSet.has(selector);
  });

  return battles;
};

export const getNextWeekStart = () => {
  const currentDayIndex = moment().day();

  const weekStartDate = moment()
    .utcOffset(0)
    .add(7 - currentDayIndex + 1, 'days');
  weekStartDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  weekStartDate.toISOString();
  weekStartDate.format();

  return weekStartDate;
};

export const checklastRoundResultVisibility = (currentRound?: number) => {
  const round = currentRound ? currentRound : 0;
  const localStorageDataString = localStorage.getItem(`clanWarsRound_${round}`);
  if (localStorageDataString) {
    const localStorageData = JSON.parse(localStorageDataString) as ICWarsRoundResult;
    if (localStorageData.lastRoundResultIsShows) {
      return true;
    }
  }

  return false;
};

export const checkIfToLateToShowLastRound = (endDateStr: string) => {
  if (!endDateStr) {
    return false;
  }

  const now = moment();
  const endDate = moment(endDateStr);
  const msDiff = now.diff(endDate);
  const hDiff = msDiff / 1000 / 60 / 60;
  if (hDiff < TWO_DAYS_HOURS) {
    return false;
  }

  return true;
};
