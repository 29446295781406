import { gql } from '@apollo/client';

import type { BATTLE_TYPES } from '~/constants';

const battleTypes = gql`
  query Achievements($id: String, $lang: String) {
    achievements(achievementId: $id, lang: $lang) {
      id
      battleTypes {
        name
      }
    }
  }
`;

export default battleTypes;

export type IAchievementsResponse = {
  achievements: Array<{
    id: string;
    battleTypes: Array<{
      name: BATTLE_TYPES;
    }>;
  }>;
};
