import React, { Fragment } from 'react';
import { Interpolate } from 'react-i18next';
import { Link } from '@wg/wows-react-uikit';

import { t, x } from '~/helpers/localization';

import styles from './DistributionDialog.scss';

type PropsType = {
  distributionList: Array<number>;
  members: Array<any>;
  toggleShowUsers: () => void;
  truncate: boolean;
};

const UserNames: React.FC<PropsType> = ({ distributionList, members, truncate, toggleShowUsers }) => {
  if (distributionList.length === 1) {
    const name = members
      .filter((m) => {
        return distributionList.indexOf(m.id) !== -1;
      })
      .map((m) => m.name)
      .join('');

    return <Interpolate i18nKey={x('Начислить ресурсы игроку: %(name)s')} name={name} parent={'p'} t={t} />;
  } else {
    const names = members
      .filter((m) => {
        return distributionList.indexOf(m.id) !== -1;
      })
      .map((m) => m.name)
      .join(', ');

    return (
      <Interpolate
        i18nKey={x('%(title)s %(names)s %(more)s')}
        title={<span className={styles.betweenTitle}>{t('Распределить ресурсы между игроками:')}</span>}
        names={names}
        more={
          truncate ? null : (
            <Fragment>
              &nbsp;
              <Link isActionLink={false} arrow={''} onClick={toggleShowUsers}>
                {t('скрыть')}
              </Link>
            </Fragment>
          )
        }
        parent={'p'}
        t={t}
      />
    );
  }
};

export default UserNames;
