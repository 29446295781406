import PropTypes from 'prop-types';
import * as React from 'react';
import { translate } from 'react-i18next';
import { Input } from '@wg/wows-react-uikit';

import settings from '~/settings';

const propTypes = {
  value: PropTypes.string.isRequired,

  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  isValidating: PropTypes.bool,
  isFocusOn: PropTypes.bool,

  onChange: PropTypes.func.isRequired,

  t: PropTypes.func.isRequired,
};

const ClanTagInput = (props) => {
  const t = props.t;
  const value = props.value;
  const onChange = props.onChange;

  const handleChange = (event) => {
    const eventValue = event.target.value.toUpperCase();
    if (eventValue === value) {
      return;
    }

    let error = null;
    const TAG_REG_EXP = new RegExp(settings.clanCreate.tagPattern);
    const clanTag = eventValue.trim();

    if (clanTag.length === 0) {
      error = t('Введите тег клана');
    } else if (clanTag.trim().length < settings.clanCreate.tagMinLength) {
      error = t('Минимальное количество символов: %(minLength)s', {
        minLength: settings.clanCreate.tagMinLength,
      });
    } else if (!TAG_REG_EXP.test(eventValue)) {
      error = t('Содержит недопустимые символы.');
    } else if (eventValue.length > settings.clanCreate.tagMaxLength) {
      error = t('Максимальное количество символов: %(maxLength)s', {
        maxLength: settings.clanCreate.tagMaxLength,
      });
    }

    onChange(eventValue, error);
  };

  return (
    <Input
      noimesupport
      label={t('Тeг')}
      value={value}
      width="110px"
      maxLength={settings.clanCreate.tagMaxLength}
      error={props.error}
      info={t(
        'Сокращённое название клана (от 2 до 5 символов: цифр, латинских букв, «_» или «-»), которое вы будете видеть в игре.',
      )}
      isDisabled={props.isDisabled}
      isValidating={props.isValidating}
      isFocusOn={props.isFocusOn}
      onChange={handleChange}
      t={t}
    />
  );
};

ClanTagInput.propTypes = propTypes;

export default translate()(ClanTagInput);
