import { combineReducers } from '@reduxjs/toolkit';
import { routerReducer as routing } from 'react-router-redux';

import preloaded from '~/preloaded';
import dialogsSlice from '~/store/slices/dialogsSlice';
import settingsSlice from '~/store/slices/settingsSlice';

import { applications } from './ReducerApp';
import { autocomplete } from './ReducerAutocomplete';
import { supply } from './ReducerBaseSupply';
import { clanBattles } from './ReducerBattles';
import { ReducerClanBattles } from './ReducerClanBattles';
import { clanCreate } from './ReducerClanCreate';
import { clanEdit } from './ReducerClanEdit';
import { clanLeave } from './ReducerClanLeave';
import { members } from './ReducerClanMembers';
import { membersOperation } from './ReducerClanMembersOperation';
import { clans } from './ReducerClanProfile';
import { clanRename } from './ReducerClanRename';
import { ReducerClanStars } from './ReducerClanStars';
import { ReducerClanTasks } from './ReducerClanTasks';
import { ReducerClanWars } from './ReducerClanWars';
import { currentAccount } from './ReducerCurrentAccount';
import { ReducerHallFame } from './ReducerHallFame';
import { introduction } from './ReducerIntroduction';
import { invites } from './ReducerInvites';
import { fetchingCounter } from './ReducerProcessing';
import { ReducerPromo } from './ReducerPromo';
import { clansRating } from './ReducerRatings';
import { ReducerRecommendations } from './ReducerRecommendations';
import { requests } from './ReducerRequests';
import { searchResult } from './ReducerSearch';
import { searchHistory } from './ReducerSearchHistory';
import { ReducerSupply } from './ReducerSupply';
import { ReducerTreasury } from './ReducerTreasury';
import { bundleImages } from '../../instant-images-preloader';

import type { Preloaded } from '~/types/preloaded';

const clanTasks = (state: Preloaded['clanTasks'] = preloaded.clanTasks || []) => {
  return state;
};

const encyclopedia = (
  state: Preloaded['encyclopedia'] = preloaded.encyclopedia || {
    nations: {},
    vehiclesTypes: {},
  },
) => {
  return state;
};

const urls = (state: Preloaded['urls'] = preloaded.urls || {}) => {
  return state;
};

const rootReducer = combineReducers({
  applications,
  autocomplete,
  bundleImages,
  clanBattles,
  clanCreate,
  clanEdit,
  clanLeave,
  clanRename,
  clanTasks,
  clans,
  clansRating,
  currentAccount,
  dialogs: dialogsSlice,
  encyclopedia,
  fetchingCounter,
  introduction,
  invites,
  members,
  membersOperation,
  requests,
  routing,
  searchHistory,
  searchResult,
  settings: settingsSlice,
  supply,
  urls,

  ReducerClanBattles,
  ReducerClanStars,
  ReducerClanTasks,
  ReducerClanWars,
  ReducerHallFame,
  ReducerPromo,
  ReducerRecommendations,
  ReducerSupply,
  ReducerTreasury,
});

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ActionCreatorsMap = { [key: string]: (...args: any[]) => unknown };
type PropertiesTypes<T> = T extends { [key: string]: infer U } ? U : never;
export type InferActionsType<T extends ActionCreatorsMap> = ReturnType<PropertiesTypes<T>>;

export default rootReducer;
