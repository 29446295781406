import { t } from '~/helpers/localization';

enum ONBOARDING_STEP_TYPES {
  DIALOG = 'dialog',
  GUIDINGTIP = 'guidingtip',
}

export enum ONBOARDING_GUIDES {
  CLANSTARS_REFINED = `clanstars_refined:${ONBOARDING_STEP_TYPES.GUIDINGTIP}`,
  CLAN_ABOUT_DISCORD = `clan_about_discord:${ONBOARDING_STEP_TYPES.GUIDINGTIP}`,
}

export enum ONBOARDING_DIALOGS {
  CLANSTARS_ROUTE_ENTRY = `clanstars_route_entry:${ONBOARDING_STEP_TYPES.DIALOG}`,
}

export const ONBOARDING_GUIDES_CONFIG = {
  CLANSTARS_REFINED: {
    name: ONBOARDING_GUIDES.CLANSTARS_REFINED,
    header: t('Раздел «Звезда отряда» обновлён'),
    content: t('Посмотрите, что изменилось'),
  },
  CLAN_ABOUT_DISCORD_GUEST: {
    name: ONBOARDING_GUIDES.CLAN_ABOUT_DISCORD,
    header: t('Clan description and bonuses'),
    content: t('Learn more about the Clan and its active bonuses.'),
  },
  CLAN_ABOUT_DISCORD_MEMBER: {
    name: ONBOARDING_GUIDES.CLAN_ABOUT_DISCORD,
    header: t('Discord link'),
    content: t("Add link to your Clan's Discord server."),
  },
} as const;

export const onboardingStepsList = [
  // Routes entries
  ONBOARDING_DIALOGS.CLANSTARS_ROUTE_ENTRY,
  // GuidingTips
  ONBOARDING_GUIDES.CLANSTARS_REFINED,
  ONBOARDING_GUIDES.CLAN_ABOUT_DISCORD,
];
