import React, { PureComponent } from 'react';
import { Interpolate, translate } from 'react-i18next';
import { Link } from '@wg/wows-react-uikit';

import preloaded from '~/preloaded';
import { isCIS } from '~/settings';
import { t, x } from '~/helpers/localization';
import { openUrlInExternalBrowser } from '~/web2ClientAPI/base';

export interface I_Props {
  className: string;
}

class Terms extends PureComponent<I_Props, any> {
  static defaultProps: { className: string };
  constructor(props) {
    super(props);
    this.openTerms = this.openTerms.bind(this);
  }

  openTerms() {
    if (!preloaded.urls?.privacy) {
      return;
    }
    openUrlInExternalBrowser(preloaded.urls.privacy);
  }

  render() {
    const linkToTerms = (
      <Link arrow="" isActionLink onClick={this.openTerms}>
        {isCIS(t('Политикой конфиденциальности Lesta Games'), t('Политикой конфиденциальности Wargaming'))}
      </Link>
    );

    return (
      <span className={this.props.className}>
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x(
            'В целях безопасности не указывайте здесь личную информацию о себе или о ком-либо другом, так как мы не сможем защитить её в соответствии с %(linkToTerms)s.',
          )}
          linkToTerms={linkToTerms}
          parent={'p'}
          t={t}
        />
      </span>
    );
  }
}

Terms.defaultProps = {
  className: '',
};

export default translate()(Terms);
