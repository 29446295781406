import { viewport } from './index';

export const addMarkerBlur = (buildingId: string) => {
  const elems = document.querySelectorAll('.markerIcon');
  if (elems && elems.length > 0) {
    for (let key = 0; key < elems.length; key++) {
      const item = elems[key];
      if (item.className.indexOf(buildingId) === -1) {
        item.className += ' isBlured';
      }
    }
  }
};

export const addBlur = (buildingId: string) => {
  removeBlur();
  const elems = document.querySelectorAll('.blurable');

  if (elems && elems.length > 0) {
    for (let key = 0; key < elems.length; key++) {
      const item = elems[key];
      if (!item.className.trim().split(' ').includes(`tile_${buildingId}`)) {
        item.className += ' isBlured';
      }
    }
  }
};

export const removeBlur = () => {
  const elems = document.querySelectorAll('.isBlured');
  if (elems && elems.length > 0) {
    for (let key = 0; key < elems.length; key++) {
      if (elems[key].className) {
        elems[key].className = elems[key].className.replace(' isBlured', '');
      }
    }
  }
};

export const updateBlurBgMap = (sidebarWidth: number, noAnimation = false) => {
  const width = viewport().width - sidebarWidth;
  const container = document.getElementById('blurBgMap');

  if (container) {
    if (noAnimation) {
      container.style.transition = 'clip-path 0s linear';
      container.style.clipPath = `inset(0px 0px 0px ${width}px)`;
    } else {
      container.style.transition = 'clip-path .3s linear';
      container.style.clipPath = `inset(0px 0px 0px ${width}px)`;
    }

    if (sidebarWidth === 0) {
      container.style.opacity = '0';
    } else {
      container.style.opacity = '1';
    }
  }
};

export const hideRealLayer = (buildingId) => {
  const layerClass = `.tile_${buildingId}`;
  const layer = document.querySelector(layerClass);
  if (layer && layer.className.indexOf('LevelHoverHidden') === -1) {
    layer.className += ' LevelHoverHidden';
  }
};
export const showRealLayer = () => {
  const elems = document.querySelectorAll('.LevelHoverHidden');
  if (elems && elems.length > 0) {
    for (let key = 0; key < elems.length; key++) {
      if (elems[key].className) {
        elems[key].className = elems[key].className.replace(' LevelHoverHidden', '');
      }
    }
  }
};
