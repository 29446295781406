import PropTypes from 'prop-types';
import * as React from 'react';
import HeightReporter from 'react-height';
import { Motion, spring } from 'react-motion';

const stringHeight = (height) => Math.max(0, parseFloat(height)).toFixed(1);

const propTypes = {
  isOpened: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,

  defaultHeight: PropTypes.number,

  onRest: PropTypes.func,
};

const defaultProps = {
  defaultHeight: 0,
};

class Collapse extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      height: -1,
      isOpenedChanged: false,
    };

    this.onHeightReady = this.onHeightReady.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.height = stringHeight(this.props.defaultHeight);
    this.renderStatic = true;
  }

  UNSAFE_componentWillReceiveProps({ isOpened }) {
    this.setState({
      isOpenedChanged: isOpened !== this.props.isOpened,
    });
  }

  onHeightReady(height) {
    if (this.renderStatic && this.props.isOpened) {
      this.height = stringHeight(height);
    }

    this.setState({
      height: this.props.isOpened || !this.renderStatic ? height : Math.min(height, this.props.defaultHeight),
    });
  }

  getMotionHeight(height) {
    const newHeight = this.props.isOpened
      ? Math.max(0, parseFloat(height)).toFixed(1)
      : stringHeight(Math.min(height, this.props.defaultHeight));

    // No need to animate if content is closed and it was closed previously
    // Also no need to animate if height did not change
    const skipAnimation = (!this.state.isOpenedChanged && !this.props.isOpened) || this.height === newHeight;

    const springHeight = spring(
      this.props.isOpened ? Math.max(0, height) : Math.min(height, this.props.defaultHeight),
      {
        precision: 0.5,
        damping: 26,
        stiffness: 200,
      },
    );
    const instantHeight = this.props.isOpened ? Math.max(0, height) : Math.min(height, this.props.defaultHeight);
    return skipAnimation ? instantHeight : springHeight;
  }

  render() {
    const { isOpened, onRest, defaultHeight } = this.props;

    const renderStatic = this.renderStatic;
    const { height } = this.state;
    const currentStringHeight = parseFloat(height).toFixed(1);

    if (height > -1 && renderStatic) {
      this.renderStatic = false;
    }

    const content = <HeightReporter onHeightReady={this.onHeightReady}>{this.props.children}</HeightReporter>;

    if (renderStatic) {
      const style = isOpened ? { height: '100%' } : { overflow: 'hidden', height: defaultHeight };

      if (!isOpened && height > -1) {
        return <div style={{ height: height, overflow: 'hidden' }}>{content}</div>;
      }

      // <Motion> to prevent loosing input after causing this component to rerender
      return (
        <Motion defaultStyle={{ height: Math.max(0, height) }} style={{ height: Math.max(0, height) }} onRest={onRest}>
          {() => <div style={style}>{content}</div>}
        </Motion>
      );
    }

    return (
      <Motion
        defaultStyle={{ height: Math.max(defaultHeight, height) }}
        onRest={onRest}
        style={{ height: this.getMotionHeight(height) }}
      >
        {(state) => {
          this.height = stringHeight(state.height);

          const style =
            isOpened && this.height === currentStringHeight
              ? { height: '100%' }
              : {
                  height: state.height,
                  overflow: 'hidden',
                };

          return <div style={style}>{content}</div>;
        }}
      </Motion>
    );
  }
}

Collapse.propTypes = propTypes;
Collapse.defaultProps = defaultProps;

export default Collapse;
