import moment from 'moment';
import React, { PureComponent } from 'react';
import { Interpolate } from 'react-i18next';
import { Button } from '@wg/wows-react-uikit';

import settings from '~/settings';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { ROUND_TYPE } from '~/helpers/cwars';
import { t, x } from '~/helpers/localization';
import { log } from '~/helpers/logging';

import Round from '~/Components/ViewClanWars/CWarsHistoryDialog/Round';

import styles from './LastRoundResults.scss';

import type { ICommonInfo, IHistoryRound, IWarSettings } from '~/types/declaration';

export type IProps = {
  data: IHistoryRound;
  warSettings: IWarSettings;
  commonInfo: ICommonInfo;
  hide: () => void;
};

class LastRoundResults extends PureComponent<IProps> {
  render() {
    return (
      <div className={styles.wrapper}>
        {this.props.data.type === ROUND_TYPE.PAIR ? (
          <Interpolate
            className={styles.date}
            i18nKey={x('Морское сражение %(startDate)s - %(endDate)s')}
            startDate={moment(this.props.data.startDate).format(
              settings.formats.date ? settings.formats.date : 'DD.MM',
            )}
            endDate={moment(this.props.data.endDate).format(settings.formats.date ? settings.formats.date : 'DD.MM')}
            t={t}
          />
        ) : (
          <Interpolate
            className={styles.date}
            i18nKey={x('Групповой этап %(startDate)s - %(endDate)s')}
            startDate={moment(this.props.data.startDate).format(
              settings.formats.date ? settings.formats.date : 'DD.MM',
            )}
            endDate={moment(this.props.data.endDate).format(settings.formats.date ? settings.formats.date : 'DD.MM')}
            t={t}
          />
        )}
        <Round
          warSettings={this.props.warSettings}
          commonInfo={this.props.commonInfo}
          data={this.props.data}
          isVisible={true}
          withMedal={true}
          hideAdditionalInfo={true}
        />
        <div className={styles.bottomButton}>
          <Button
            isFlat
            onClick={() => {
              dwhExport.push(DWH_EVENTS.NAVAL_BATTLES.CLOSE_LAST_ROUND_RESULTS, { last_round_id: this.props.data.id });
              log('naval_battles.close_last_round_results', {
                last_round_id: this.props.data.id,
              });
              this.props.hide();
            }}
          >
            {t('Закрыть')}
          </Button>
        </div>
      </div>
    );
  }
}

export default LastRoundResults;
