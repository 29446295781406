import settings from '~/settings';
import { t } from '~/helpers/localization';

export default function (battleType) {
  return [
    {
      glyph: 'roles',
      isHiddenSortingEnabled: true,
      isSortable: true,
      modify: 'role',
      name: 'role',
      sortDefaultIsAsc: true,
      text: '',
      tooltipText: t('Должность:tooltip'),
    },
    {
      isHiddenSortingEnabled: true,
      isSortable: true,
      modify: 'basis',
      name: 'name',
      sortDefaultIsAsc: true,
      text: t('Игрок'),
    },
    {
      isHidden: !settings.supply.isEnabled,
      glyph: 'clan-resource',
      isHiddenSortingEnabled: true,
      isSortable: true,
      modify: 'gainedResource',
      name: 'accumulativeClanResource',
      sortDefaultIsAsc: false,
      forOwnClan: true,
      text: '',
      tooltipText: t('Количество баррелей нефти, добытых в составе клана:tooltip'),
    },
    {
      glyph: 'battles',
      isHiddenSortingEnabled: false,
      isSortable: true,
      modify: 'right',
      name: 'battlesCount',
      sortDefaultIsAsc: false,
      text: 'BTL',
      tooltipParam: 'BTL &mdash; Battles',
      tooltipText:
        battleType !== 'cvc'
          ? t('Количество боёв в выбранном типе боев за всё время:tooltip')
          : t('Количество боёв в выбранном типе боев за сезон:tooltip'),
    },
    {
      glyph: 'wins-per-bettles',
      isHiddenSortingEnabled: false,
      isSortable: true,
      modify: 'right',
      name: 'winsPercentage',
      sortDefaultIsAsc: false,
      text: 'W/B',
      tooltipParam: 'W/B &mdash; Wins per Battles',
      tooltipText:
        battleType !== 'cvc'
          ? t('Процент побед в выбранном типе боев за всё время:tooltip')
          : t('Процент побед в выбранном типе боев за сезон:tooltip'),
    },
    {
      glyph: 'experience-per-battle',
      isHiddenSortingEnabled: false,
      isSortable: true,
      modify: 'right',
      name: 'expPerBattle',
      sortDefaultIsAsc: false,
      text: 'E/B',
      tooltipParam: 'E/B &mdash; Experience per Battle',
      tooltipText:
        battleType !== 'cvc'
          ? t('Средний опыт за бой в выбранном типе боев за всё время:tooltip')
          : t('Средний опыт за бой в выбранном типе боев за сезон:tooltip'),
    },
    {
      glyph: 'damage-per-battle',
      isHiddenSortingEnabled: false,
      isSortable: true,
      modify: 'right',
      name: 'damagePerBattle',
      sortDefaultIsAsc: false,
      text: 'D/B',
      tooltipParam: 'D/B &mdash; Damage per Battle',
      tooltipText:
        battleType !== 'cvc'
          ? t('Средний урон за бой в выбранном типе боев за всё время:tooltip')
          : t('Средний урон за бой в выбранном типе боев за сезон:tooltip'),
    },
    {
      glyph: 'frags-per-battle',
      isHiddenSortingEnabled: false,
      isSortable: true,
      modify: 'right',
      name: 'fragsPerBattle',
      sortDefaultIsAsc: false,
      text: 'F/B',
      tooltipParam: 'F/B &mdash; Frags per Battle',
      tooltipText:
        battleType !== 'cvc'
          ? t('Среднее количество уничтоженных кораблей за бой в выбранном типе боев за всё время:tooltip')
          : t('Среднее количество уничтоженных кораблей за бой в выбранном типе боев за сезон:tooltip'),
    },
    {
      glyph: 'days-in-clan',
      isHiddenSortingEnabled: true,
      isSortable: true,
      modify: 'right',
      name: 'daysInClan',
      sortDefaultIsAsc: false,
      text: t('Дни'),
      tooltipText: t('Количество дней в клане:tooltip'),
    },
    {
      glyph: 'last-battle-time',
      isHiddenSortingEnabled: false,
      isSortable: true,
      modify: 'right',
      name: 'lastBattleTime',
      sortDefaultIsAsc: false,
      text: 'LBT',
      tooltipParam: 'LBT &mdash; Last Battle Time',
      tooltipText: t('Время последнего боя:tooltip'),
    },
  ];
}
