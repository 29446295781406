import classNames from 'classnames';
import React from 'react';
import { playCardClickSound } from '@wg/web2clientapi/sound';

import { isIngame } from '~/commonmenu';

import styles from '~/Components/ViewSupply/ViewSupply.scss';

type PropsType = {
  isSidebarOpened: boolean;
  CloseSupplySideBar: () => void;
  OpenSupplySideBar: () => void;
  className?: string;
};

const OpenSidebarButton: React.FC<PropsType> = ({
  isSidebarOpened,
  OpenSupplySideBar,
  className,
  CloseSupplySideBar,
}) => {
  const openSidebarButton = classNames(styles.openSidebarButton, {
    [styles.closeSidebarButton]: isSidebarOpened,
    [className]: !!className,
    [styles.openSidebarButtonOffset]: !isIngame(),
  });

  const handleClick = () => {
    void playCardClickSound();

    if (isSidebarOpened) {
      CloseSupplySideBar();
    } else {
      OpenSupplySideBar();
    }
  };

  return (
    <div className={openSidebarButton} onClick={handleClick}>
      <div className={isSidebarOpened ? styles.closeSidebarButtonBuilding : styles.openSidebarButtonBuilding} />
      <div className={isSidebarOpened ? styles.closeSidebarButtonArrow : styles.openSidebarButtonArrow} />
    </div>
  );
};

export default OpenSidebarButton;
