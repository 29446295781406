import PropTypes from 'prop-types';
import * as React from 'react';
import { Interpolate, translate } from 'react-i18next';
import {
  ContextMenu,
  DivTooltip,
  Icon,
  MemberName,
  TableBodyCell,
  TableRow,
  Tooltip,
  TooltipBody,
} from '@wg/wows-react-uikit';

import { getPositionY } from '~/helpers/contextMenu';
import { isoToFormattedLocalDate, isoToFormattedLocalTime } from '~/helpers/datetime';
import { floats, percent, thousands } from '~/helpers/formatting';
import { x } from '~/helpers/localization';

import { Clan, StatisticsValue } from '~/UIKit';

import type { IconType } from '@wg/wows-react-uikit/components/Icon/Icon';
import type { ClanType } from '~/Actions/ActionInvites';
import { playButtonClickSound } from '@wg/web2clientapi/sound';

type PropsType = {
  application: {
    clan: ClanType;
    created_at: string;
    is_hidden_statistics: boolean;
    statistics: any;
    status: string;
    expires_at?: string;
    updated_at?: string;
  };
  router?: any;
  currentAccount: {
    inClanCooldownTill?: string;
  };
  hasActiveApplicationForClan: boolean;
  root: string;
  sendApplication: (clan: ClanType) => void;
  t: (arg: string) => void;
};

type StateType = {
  isContextMenuActive: boolean;
  contextMenuContext: Array<any>;
  contextMenuPosition: {
    x: number;
    y: number;
  };
};

const getApplicationStatusTooltipContent = (t, currentAccount, application) => {
  let applicationStatusTooltip = null;
  if (currentAccount.inClanCooldownTill) {
    applicationStatusTooltip = (
      <div>
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Вы отправили заявку на вступление в клан %(date)s&nbsp;в&nbsp;%(time)s.')}
          date={isoToFormattedLocalDate(application.created_at)}
          time={isoToFormattedLocalTime(application.created_at)}
          parent={'p'}
          t={t}
        />
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Вы недавно покинули клан. Вступление в клан станет доступно %(date)s&nbsp;%(time)s.')}
          date={isoToFormattedLocalDate(currentAccount.inClanCooldownTill)}
          time={isoToFormattedLocalTime(currentAccount.inClanCooldownTill)}
          parent={'p'}
          t={t}
        />
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Время для принятия решения по заявке истекает %(date)s&nbsp;в&nbsp;%(time)s.')}
          date={isoToFormattedLocalDate(application.expires_at)}
          time={isoToFormattedLocalTime(application.expires_at)}
          parent={'p'}
          t={t}
        />
      </div>
    );
  } else if (application.status === 'expired') {
    applicationStatusTooltip = (
      <div>
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Время для принятия решения истекло %(date)s&nbsp;в&nbsp;%(time)s.')}
          date={isoToFormattedLocalDate(application.expires_at)}
          time={isoToFormattedLocalTime(application.expires_at)}
          parent={'p'}
          t={t}
        />
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Вы отправили заявку %(date)s&nbsp;в&nbsp;%(time)s.')}
          date={isoToFormattedLocalDate(application.created_at)}
          time={isoToFormattedLocalTime(application.created_at)}
          parent={'p'}
          t={t}
        />
      </div>
    );
  } else if (application.status === 'accepted') {
    const statusChanger = application.status_changer;

    applicationStatusTooltip = (
      <div>
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('%(statusChanger)s одобрил заявку %(date)s&nbsp;в&nbsp;%(time)s')}
          statusChanger={<MemberName role={statusChanger.role} name={statusChanger.name} isInline />}
          date={isoToFormattedLocalDate(application.updated_at)}
          time={isoToFormattedLocalTime(application.updated_at)}
          parent={'p'}
          t={t}
        />
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Вы отправили заявку %(date)s&nbsp;в&nbsp;%(time)s.')}
          date={isoToFormattedLocalDate(application.created_at)}
          time={isoToFormattedLocalTime(application.created_at)}
          parent={'p'}
          t={t}
        />
      </div>
    );
  } else if (application.status === 'declined') {
    const statusChanger = application.status_changer;

    applicationStatusTooltip = (
      <div>
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('%(statusChanger)s отклонил заявку %(date)s&nbsp;в&nbsp;%(time)s')}
          statusChanger={<MemberName role={statusChanger.role} name={statusChanger.name} isInline />}
          date={isoToFormattedLocalDate(application.updated_at)}
          time={isoToFormattedLocalTime(application.updated_at)}
          parent={'p'}
          t={t}
        />
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Вы отправили заявку %(date)s&nbsp;в&nbsp;%(time)s.')}
          date={isoToFormattedLocalDate(application.created_at)}
          time={isoToFormattedLocalTime(application.created_at)}
          parent={'p'}
          t={t}
        />
      </div>
    );
  } else {
    applicationStatusTooltip = (
      <div>
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Вы отправили заявку на вступление в клан %(date)s&nbsp;в&nbsp;%(time)s.')}
          date={isoToFormattedLocalDate(application.created_at)}
          time={isoToFormattedLocalTime(application.created_at)}
          parent={'p'}
          t={t}
        />
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Время для принятия решения по заявке истекает %(date)s&nbsp;в&nbsp;%(time)s.')}
          date={isoToFormattedLocalDate(application.expires_at)}
          time={isoToFormattedLocalTime(application.expires_at)}
          t={t}
        />
      </div>
    );
  }
  return applicationStatusTooltip;
};

class ApplicationsNonClanMemberTableItem extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      isContextMenuActive: false,
      contextMenuContext: [],
      contextMenuPosition: { x: 0, y: 0 },
    };

    this.onContextMenu = this.onContextMenu.bind(this);
    this.onContextMenuItemClick = this.onContextMenuItemClick.bind(this);
    this.onContextMenuStateChange = this.onContextMenuStateChange.bind(this);
    this.onClanClick = this.onClanClick.bind(this);
  }

  onContextMenuStateChange(isContextMenuActive) {
    this.setState({ isContextMenuActive });
  }

  onContextMenu(event) {
    const context = this.getContextMenuContext();
    const clientY = getPositionY(event, context);
    this.setState({
      contextMenuContext: context,
      contextMenuPosition: {
        x: event.clientX,
        y: clientY,
      },
    });
  }

  getContextMenuContext() {
    const { t } = this.props;
    const hasActiveApplication = this.props.hasActiveApplicationForClan;
    return [
      {
        name: null,
        items: [
          {
            name: t('Профиль клана'),
            value: 'CLAN_PROFILE',
          },
          {
            name: t('Отправить заявку'),
            disabled: hasActiveApplication,
            tooltip: hasActiveApplication ? t('Заявка уже отправлена') : null,
            value: 'SEND_APPLICATION',
          },
        ],
      },
    ];
  }

  onContextMenuItemClick(action) {
    const clan = this.props.application.clan;
    switch (action) {
      case 'CLAN_PROFILE':
        this.context.router.push(`${this.props.root}clan-profile/${clan.id}`);
        break;
      case 'SEND_APPLICATION':
        this.props.sendApplication(clan);
        break;
    }
  }

  onClanClick() {
    const clan = this.props.application.clan;
    if (clan.is_active) {
      void playButtonClickSound();
      this.context.router.push(`${this.props.root}clan-profile/${clan.id}`);
    }
  }

  render() {
    const { application, t } = this.props;

    const isHiddenStatistics = application.is_hidden_statistics;
    const applicationStatusTooltipId = `application-status-tooltip-${application.clan.id}`;
    const applicationStatusTooltip = getApplicationStatusTooltipContent(t, this.props.currentAccount, application);
    const glyph = `status-${application.status}` as IconType;
    const tooltipStatus = applicationStatusTooltip && !this.state.isContextMenuActive && (
      <Tooltip id={applicationStatusTooltipId}>
        <TooltipBody>{applicationStatusTooltip}</TooltipBody>
      </Tooltip>
    );

    const clan = application.clan;
    const clanDisbanded = !clan.is_active;
    const statistics = application.statistics;

    const contextMenu = clan.is_active ? (
      <ContextMenu
        context={this.state.contextMenuContext}
        position={this.state.contextMenuPosition}
        onItemClick={this.onContextMenuItemClick}
        onContextMenuStateChange={this.onContextMenuStateChange}
      />
    ) : null;

    return (
      <TableRow isHovering onContextMenu={clan.is_active ? this.onContextMenu : null} onClick={this.onClanClick}>
        {contextMenu}
        <TableBodyCell modify="basis">
          <Clan
            clanTag={clan.tag}
            clanColor={clan.color}
            clanName={clan.name}
            isDisbanded={clanDisbanded}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="small">{clan.members_count}</TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            isClan
            clanDisbanded={clanDisbanded}
            value={thousands(statistics.abtl)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            isClan
            clanDisbanded={clanDisbanded}
            value={percent(statistics.awb, undefined)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            isClan
            clanDisbanded={clanDisbanded}
            value={thousands(statistics.aeb)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            isClan
            clanDisbanded={clanDisbanded}
            value={thousands(statistics.admg)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <StatisticsValue
            isClan
            clanDisbanded={clanDisbanded}
            value={floats(statistics.afb, 2)}
            isHidden={isHiddenStatistics}
            disableTooltip={this.state.isContextMenuActive}
          />
        </TableBodyCell>
        <TableBodyCell modify="right">
          <DivTooltip tooltipBody={tooltipStatus}>
            <Icon glyph={glyph} />
          </DivTooltip>
        </TableBodyCell>
      </TableRow>
    );
  }
}

const contextTypes = {
  router: PropTypes.object.isRequired,
};
ApplicationsNonClanMemberTableItem.contextTypes = contextTypes;

export default translate()(ApplicationsNonClanMemberTableItem);
