import { useQuery } from '@apollo/client';
import React from 'react';
import { shallowEqual } from 'react-redux';

import settings from '~/settings';
import { t } from '~/helpers/localization';

import queryBattleTypes from '~/queries/battleTypes';
import { useAppDispatch, useAppSelector } from '~/store';
import { settingsActions } from '~/store/slices/settingsSlice';
import { sendErrorNotification } from '~/web2ClientAPI/base';

import styles from './CStarsTasks.scss';
import CStarsTasksCard from './CStarsTasksCard';

import type { IBattleTypesResponse } from '~/queries/battleTypes';
import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    battleTypesData: state.settings.battleTypesData,
    seasonQuests: state.ReducerClanStars.seasonQuests,
  };
};

const CStarsTasks: React.FC = () => {
  const { battleTypesData, seasonQuests } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  // Fetching battleTypes query only once per session
  const isBattleTypesDataLoaded = battleTypesData && Object.keys(battleTypesData)?.length > 0;
  const [dataLoaded, setDataLoaded] = React.useState(isBattleTypesDataLoaded);
  const { loading, data, error } = useQuery<IBattleTypesResponse>(queryBattleTypes, {
    variables: {
      lang: settings.realm?.languageCodeGloss,
    },
    skip: dataLoaded,
  });

  React.useEffect(() => {
    if (data) {
      dispatch(settingsActions.setBattleTypes(data.battleTypes));
      setDataLoaded(true);
    }
  }, [data, dispatch]);

  React.useEffect(() => {
    if (error) {
      sendErrorNotification();
    }
  }, [error, dispatch]);

  return (
    <div>
      <h2 className={styles.title}>{t('Выполняйте задачи')}</h2>
      <p className={styles.text}>
        {t(
          'Выполняйте задачи в составе отряда и зарабатывайте звёзды. За каждую выполненную задачу вы получите одну звезду.',
        )}
      </p>
      <section className={styles.cards}>
        {seasonQuests.map((quest) => {
          return <CStarsTasksCard task={quest} isBattleTypesLoading={loading || !!error} key={`quest_${quest.name}`} />;
        })}
      </section>
    </div>
  );
};

export default React.memo(CStarsTasks);
