import classNames from 'classnames';
import React from 'react';
import { translate } from 'react-i18next';
import { playDropdownClickSound } from '@wg/web2clientapi/sound';

import { t } from '~/helpers/localization';

import styles from './SocialLink.scss';

import type { ISocialLinkContainer } from './SocialLinkContainer';

interface ISocialLinkEmpty {
  social: ISocialLinkContainer['social'];
  onClick: ISocialLinkContainer['onEditMode'];
}

const SocialLinkEmpty: React.FC<ISocialLinkEmpty> = ({ social, onClick }) => {
  if (!social) {
    return null;
  }

  const onClickWithSound = () => {
    void playDropdownClickSound();

    onClick?.();
  };

  return (
    <div className={classNames(styles.iconProvider, styles[social])}>
      <div className={styles.linkWrapper} onClick={onClickWithSound}>
        <p>{t('Add link')}</p>
      </div>
    </div>
  );
};

export default React.memo(translate()(SocialLinkEmpty));
