const easingEquations = {
  linear: (p: number) => p,
  swing: (p: number) => 0.5 - Math.cos(p * Math.PI) / 2,
};

/**
 * @param elem — HTML Element
 * @param scrollTargetY — the target scrollY property of the elem
 * @param speed — time in pixels per second
 * @param easing — easing equation to use
 */
export const scrollToY = (
  elem: HTMLElement,
  scrollTargetY: number = 0,
  speed: number = 1000,
  easing: keyof typeof easingEquations = 'swing',
) => {
  let currentTime = 0;
  const scrollTop = elem.scrollTop;
  const time = Math.max(0.1, Math.min(Math.abs(scrollTop - scrollTargetY) / speed, 0.8));

  function tick() {
    currentTime += 1 / 60;
    const p = currentTime / time;
    const t = easingEquations[easing](p);
    if (p < 1) {
      window.requestAnimationFrame(tick);
      elem.scrollTop = scrollTop + (scrollTargetY - scrollTop) * t;
    } else {
      elem.scrollTop = scrollTargetY;
    }
  }
  tick();
};

export type IScrollToY = typeof scrollToY;

window.scrollToY = scrollToY;
