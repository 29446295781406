import escape from 'lodash/escape';
import unionBy from 'lodash/unionBy';

import preloaded from '~/preloaded';

import { getDirectClanField, isClanFieldInPreModeration } from '~/helpers/moderation';
import {
  APPLICATIONS_UPDATE_CLAN_ACTIVE_APPLICATIONS,
  CHANGE_CLAN_ATTRIBUTES_SUCCESS,
  DECREASE_CLAN_MEMBERS,
  DECREASE_CLAN_RESOURCE,
  INCREASE_CLAN_MEMBERS,
  INCREASE_MAX_CLAN_SIZE,
  START_UPDATE_CLAN,
  UPDATE_ACHIEVEMENTS,
  UPDATE_CLAN,
  UPDATE_CLAN_FROM_SYNC,
  UPDATE_WOWS_LADDER,
} from '~/Actions/ActionClanProfile';

import type { ActionsType, WowsLadderType, ClanInfoType } from '~/Actions/ActionClanProfile';
import type { PreModerationField } from '~/types/declaration';

export type IClanAchievement = {
  count: number;
  cd: number;
};

export type IClansState = {
  wowsLadder: {
    [clanId: string]: WowsLadderType;
  };
  isFetching: {
    [clanId: string]: boolean;
  };
  items: {
    [clanId: string]: ClanInfoType;
  };
  achievements: {
    [clanId: string]: IClanAchievement[];
  };
};

const clansInitialState: IClansState = {
  wowsLadder: preloaded.clans?.wowsLadder || {},
  isFetching: preloaded.clans?.isFetching || {},
  items: preloaded.clans?.items || {},
  achievements: preloaded.clans?.achievements || {},
};

export const clans = (state: IClansState = clansInitialState, action: ActionsType): IClansState => {
  switch (action.type) {
    case START_UPDATE_CLAN:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          [action.clanId]: true,
        },
      };

    case UPDATE_CLAN: {
      const clan = action.clan;
      return {
        ...state,
        items: {
          ...state.items,
          [clan.id]: clan,
        },
        isFetching: {
          ...state.isFetching,
          [clan.id]: false,
        },
      };
    }

    case UPDATE_CLAN_FROM_SYNC: {
      const clan = state.items[action.clanId];
      if (!clan) {
        return state;
      }

      return {
        ...state,
        items: {
          ...state.items,
          [action.clanId]: {
            ...clan,
            ...action.clanInfo,
          },
        },
      };
    }

    case UPDATE_WOWS_LADDER: {
      return {
        ...state,
        wowsLadder: {
          ...state.wowsLadder,
          [action.clanId]: action.wowsLadder,
        },
      };
    }

    case UPDATE_ACHIEVEMENTS: {
      return {
        ...state,
        achievements: {
          ...state.achievements,
          [action.clanId]: action.achievements,
        },
      };
    }

    case INCREASE_MAX_CLAN_SIZE: {
      const clan = state.items[action.clanId];
      if (!clan) {
        return state;
      }

      return {
        ...state,
        items: {
          ...state.items,
          [action.clanId]: {
            ...clan,
            maxMembersCount: clan.maxMembersCount + action.extraMembers,
          },
        },
      };
    }

    case CHANGE_CLAN_ATTRIBUTES_SUCCESS: {
      const data = { ...action.data };

      if (Object.prototype.hasOwnProperty.call(data, 'rawDescription')) {
        data.rawDescription = escape(data.rawDescription);
      }

      const newData = { preModeration: [] as PreModerationField[] };

      Object.entries(data).map(([fieldName, fieldValue]) => {
        const directField = getDirectClanField(fieldName);
        if (isClanFieldInPreModeration(directField)) {
          newData.preModeration.push(directField);
        } else {
          newData[fieldName] = fieldValue;
        }
      });

      const clans = {
        ...state.items,
        [data.clanId]: {
          ...state.items[data.clanId],
          ...newData,
        },
      };

      return {
        ...state,
        items: clans,
      };
    }

    case APPLICATIONS_UPDATE_CLAN_ACTIVE_APPLICATIONS: {
      const clan = state.items[action.clanId];
      if (!clan) {
        return state;
      }

      return {
        ...state,
        items: {
          ...state.items,
          [action.clanId]: {
            ...clan,
            active_applications: unionBy(clan.active_applications, [{ id: action.applicationId }], 'id'),
          },
        },
      };
    }

    case DECREASE_CLAN_MEMBERS: {
      const clan = state.items[action.clanId];
      if (!clan) {
        return state;
      }

      return {
        ...state,
        items: {
          ...state.items,
          [clan.id]: {
            ...clan,
            members_count: clan.members_count - action.count,
          },
        },
      };
    }

    case INCREASE_CLAN_MEMBERS: {
      const clan = state.items[action.clanId];
      if (!clan) {
        return state;
      }

      return {
        ...state,
        items: {
          ...state.items,
          [clan.id]: {
            ...clan,
            members_count: clan.members_count + action.count,
          },
        },
      };
    }

    case DECREASE_CLAN_RESOURCE: {
      const clan = state.items[action.clanId];
      if (!clan) {
        return state;
      }

      return {
        ...state,
        items: {
          ...state.items,
          [action.clanId]: {
            ...clan,
            personalResource: clan.personalResource - action.resource,
          },
        },
      };
    }

    default:
      return state;
  }
};
