import '~/styles/leaflet.scss';

import { Component, default as React } from 'react';
import { ImageOverlay, Map } from 'react-leaflet';

import { subdomains } from './ViewSupply';
import styles from './ViewSupply.scss';

type PropsType = {
  isVisible: boolean;
  mapHost: string;
  SetMapRefBg: (r: any) => void;
  maxBounds: Array<any>;
};

class BackgroundMap extends Component<PropsType> {
  shouldComponentUpdate(nextProps) {
    return this.props.isVisible !== nextProps.isVisible;
  }

  render() {
    const imgUrl = this.props.mapHost.replace('{s}', subdomains[0] ? subdomains[0] : 'alpha');
    return (
      <Map
        ref={this.props.SetMapRefBg}
        className={styles.backgroundMap}
        maxBoundsViscosity={1}
        inertia={false}
        touchZoom={false}
        bounceAtZoomLimits={false}
        scrollWheelZoom={true}
        doubleClickZoom={false}
        maxBounds={this.props.maxBounds}
        style={{ opacity: this.props.isVisible ? 1 : 0 }}
      >
        <ImageOverlay
          url={`${imgUrl}/background.jpg`}
          bounds={[
            [27, 48],
            [-27, -48],
          ]}
        />
      </Map>
    );
  }
}

export default BackgroundMap;
