import isEmpty from 'lodash/isEmpty';

import settings from '~/settings';
import { sawClanStars, TREASURY_TABS } from '~/constants';
import { shouldHideTasksNav } from '~/helpers/clanTasks';
import { clanstarsIsEnabled, clanstarsSeasonVersion, darwinIsEnabled } from '~/helpers/common';
import { getLastBrawlSeason, getLastRegularSeason } from '~/helpers/ladder';
import { getValueFromLocalStorage, setValueToLocalStorage } from '~/helpers/localStorage';
import { t } from '~/helpers/localization';
import { lastSeason } from '~/helpers/seasons';
import { hasPermission, PERMISSIONS } from '~/roles';
import { store } from '~/store';
import { searchToObject } from '~/utils';

import { CLAN_TASK_NAMES } from '~/Components/ViewClanTasks/constants';

import { actionsClanBattles } from './Actions/ActionClanBattles';
import { actionsClanWars } from './Actions/ActionClanWars';
import { isLoggedIn } from './utils/auth';

import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';
import type { IRoute } from '~/types/router';

const searchParams = searchToObject();

const IS_DEV_MODE = Object.keys(searchParams).indexOf('devmode') !== -1 && searchParams.devmode !== false;

const checkTreasury = (account: ICurrentAccountState) => {
  let isTreasuryBuilt = false;

  if (account.supplyBuildings && account.supplyBuildings.length) {
    account.supplyBuildings.forEach((item) => {
      if (item && item.type === 'treasury') {
        if (item.level > 0) {
          isTreasuryBuilt = true;
        }
      }
    });
  }

  return isTreasuryBuilt;
};

export enum ROUTES_MAP {
  ABOUT = 'about',
  CBATTLES = 'clan-battles',
  CBATTLES_HALL = `${CBATTLES}/hall`,
  CBATTLES_HISTORY = `${CBATTLES}/history`,
  CBATTLES_RATING = `${CBATTLES}/rating`,
  CBATTLES_RATING_REALM = `${CBATTLES_RATING}/:seasonType/:season/:realm`,
  CBATTLES_RULES = `${CBATTLES}/rules`,
  CLAN_CREATE = 'create',
  CLAN_PROFILE = 'clan-profile/:clanId',
  CLAN_PROFILE_MEMBERS = `${CLAN_PROFILE}/members`,
  CSTARS = 'clanstars',
  CWARS = 'clan-wars',
  CWARS_RATING = `${CWARS}/rating`,
  CWARS_RULES = `${CWARS}/rules`,
  CWARS_WAR = `${CWARS}/war`,
  MEMBERS = 'members',
  PROFILE = 'profile',
  REQUESTS = 'requests',
  SEARCH = 'search',
  SEARCH_RECOMMENDATIONS = `${SEARCH}/:purpose/:currentSlide`,
  TASKS = 'tasks',
  TREASURY = 'treasury',
  TREASURY_DISTRIBUTION = `${TREASURY}/${TREASURY_TABS.DISTRIBUTION}`,
  TREASURY_EXPENSES = `${TREASURY}/${TREASURY_TABS.EXPENSES}`,
  TREASURY_INCOME = `${TREASURY}/${TREASURY_TABS.INCOME}`,
  TREASURY_REWARDS = `${TREASURY}/${TREASURY_TABS.REWARDS}`,
}

const routes: IRoute[] = [
  {
    path: ROUTES_MAP.ABOUT,
    title: () => t('Кланы'),
    componentName: 'About',
    bodyClass: () => 'about',
    beforeEnter: (replace, root, account) => {
      if (account.clanId) {
        replace({ pathname: `${root}${ROUTES_MAP.SEARCH}` });
      }
    },
    display: (account) => !account.clanId,
  },
  {
    path: ROUTES_MAP.PROFILE,
    title: () => t('Военная база'),
    badge: 'profile',
    componentName: 'ContainerClanProfile',
    bodyClass: () => (settings.supply.isEnabled ? 'navalBase' : 'profile'),
    beforeEnter: (replace, root, account) => {
      if (!account.clanId) {
        replace({ pathname: `${root}${ROUTES_MAP.SEARCH}` });
      }
    },
    display: (account) => !!account.clanId,
  },
  {
    path: ROUTES_MAP.TASKS,
    title: () => t('Задачи клана'),
    badge: 'tasks',
    componentName: 'ContainerClanTasks',
    bodyClass: () => 'tasks',
    beforeEnter: (replace, root, account, state) => {
      const sawClanTasks: Record<string, boolean> = getValueFromLocalStorage('sawClanTasks');
      if (
        !darwinIsEnabled ||
        !account.clanId ||
        shouldHideTasksNav(state.ReducerClanTasks.tasks, CLAN_TASK_NAMES.length)
      ) {
        replace({ pathname: `${root}` });
      } else if (account.id && !sawClanTasks[account.id]) {
        sawClanTasks[account.id] = true;
        setValueToLocalStorage('sawClanTasks', sawClanTasks);
      }
    },
    display: (account, state) => {
      return (
        darwinIsEnabled && !!account.clanId && !shouldHideTasksNav(state.ReducerClanTasks.tasks, CLAN_TASK_NAMES.length)
      );
    },
  },
  {
    path: ROUTES_MAP.CLAN_PROFILE,
    componentName: 'ContainerClanProfile',
    bodyClass: () => (settings.supply.isEnabled ? 'navalBase' : 'profile'),
    display: () => false,
  },
  {
    path: ROUTES_MAP.MEMBERS,
    title: () => t('Состав клана'),
    badge: 'members',
    componentName: 'MembersRoute',
    bodyClass: () => 'members',
    beforeEnter: (replace, root, account) => {
      if (!account.clanId) {
        replace({ pathname: `${root}${ROUTES_MAP.SEARCH}` });
      }
    },
    display: (account) => !!account.clanId,
  },
  {
    path: ROUTES_MAP.CLAN_PROFILE_MEMBERS,
    componentName: 'MembersRoute',
    bodyClass: () => 'members',
    display: () => false,
  },
  {
    path: ROUTES_MAP.SEARCH,
    title: () => t('Поиск клана'),
    badge: 'search',
    componentName: 'Search',
    bodyClass: () => 'search',
  },
  {
    path: ROUTES_MAP.SEARCH_RECOMMENDATIONS,
    title: () => t('Рекомендации'),
    badge: 'new-recommendations',
    componentName: 'Recommendations',
    beforeEnter: (replace, root, account) => {
      if (!isLoggedIn() || (account.clanId && !settings.clanrec2?.showRecommendationsForClansMembers)) {
        replace({ pathname: `${root}` });
      }
    },
    bodyClass: () => 'new-recommendations',
    display: () => false,
  },
  {
    path: ROUTES_MAP.TREASURY,
    componentName: 'Treasury',
    bodyClass: () => 'treasury',
    title: () => t('Казна клана'),
    beforeEnter: (replace, root, account) => {
      if (IS_DEV_MODE) {
        return;
      }

      const isTreasuryBuilt = checkTreasury(account);
      if (!account.clanId || !isTreasuryBuilt) {
        replace({ pathname: `${root}${ROUTES_MAP.PROFILE}` });
      }
    },
    display: (account) => {
      if (IS_DEV_MODE) {
        return true;
      }

      const isTreasuryBuilt = checkTreasury(account);
      return !!account.clanId && isTreasuryBuilt;
    },
    indexRoute: {
      onEnter: (_, replace) => replace({ pathname: ROUTES_MAP.TREASURY_EXPENSES }),
    },
    childRoutes: [
      {
        path: TREASURY_TABS.EXPENSES,
        componentName: 'TreasuryHistory',
        bodyClass: () => 'treasury',
        title: () => t('Казна клана - Расходы'),
        display: () => false,
      },
      {
        path: TREASURY_TABS.INCOME,
        componentName: 'TreasuryHistory',
        bodyClass: () => 'treasury',
        title: () => t('Казна клана - Доходы'),
        display: () => false,
      },
      {
        path: TREASURY_TABS.DISTRIBUTION,
        componentName: 'TreasuryDistribution',
        bodyClass: () => 'treasury',
        title: () => t('Казна клана - Распределение'),
        display: () => false,
      },
      {
        path: TREASURY_TABS.REWARDS,
        componentName: 'TreasuryRewards',
        bodyClass: () => 'treasury',
        title: () => t('Казна клана - Регулярные награды'),
        display: () => false,
      },
    ],
  },
  {
    path: ROUTES_MAP.REQUESTS,
    title: () => t('Запросы'),
    badge: 'requests',
    componentName: 'Requests',
    bodyClass: () => 'requests',
    beforeEnter: (replace, root, account) => {
      if (
        !isLoggedIn() ||
        (account.clanId && account.roleName && !hasPermission(account.roleName, PERMISSIONS.HANDLE_INVITES))
      ) {
        replace({ pathname: `${root}${ROUTES_MAP.PROFILE}` });
      }
    },
    display: (account) =>
      (isLoggedIn() && !account.clanId) ||
      Boolean(account.roleName && hasPermission(account.roleName, PERMISSIONS.HANDLE_INVITES)),
  },
  {
    path: ROUTES_MAP.CLAN_CREATE,
    title: () => t('Создать клан'),
    badge: 'create',
    componentName: 'ClanCreate',
    bodyClass: () => 'create',
    beforeEnter: (replace, root, account) => {
      if (account.clanId) {
        replace({ pathname: `${root}${ROUTES_MAP.PROFILE}` });
      }
    },
    display: (account) => isLoggedIn() && !account.clanId,
  },
  {
    separator: true,
    path: ROUTES_MAP.CWARS,
    title: () => t('Морское сражение'),
    badge: 'profile',
    componentName: 'ClanWars',
    bodyClass: () => 'clan-wars',
    beforeEnter: (replace, root, account) => {
      if (!(!!account.id && settings.cwars.isEnabled)) {
        replace({ pathname: `${root}${ROUTES_MAP.SEARCH}` });
      } else {
        replace({ pathname: `${root}${ROUTES_MAP.CWARS_WAR}` });
      }
    },
    display: (account) => {
      return !!account.id && settings.cwars.isEnabled;
    },
  },
  {
    separator: true,
    path: ROUTES_MAP.CWARS_WAR,
    title: () => t('Морское сражение - Война'),
    badge: 'profile',
    componentName: 'ClanWars',
    bodyClass: () => 'clan-wars',
    beforeEnter: (replace, root, account) => {
      if (!(!!account.id && settings.cwars.isEnabled)) {
        replace({ pathname: `${root}${ROUTES_MAP.SEARCH}` });
      }
      store.dispatch(actionsClanWars.setSelectedTab(0));
    },
    display: () => {
      return false;
    },
  },
  {
    separator: true,
    path: ROUTES_MAP.CWARS_RATING,
    title: () => t('Морское сражение - Рейтинг'),
    badge: 'profile',
    componentName: 'ClanWars',
    bodyClass: () => 'clan-wars',
    beforeEnter: (replace, root, account) => {
      if (!(!!account.id && settings.cwars.isEnabled)) {
        replace({ pathname: `${root}${ROUTES_MAP.SEARCH}` });
      }
      store.dispatch(actionsClanWars.setSelectedTab(1));
    },
    display: () => {
      return false;
    },
  },
  {
    separator: true,
    path: ROUTES_MAP.CWARS_RULES,
    title: () => t('Морское сражение - Правила'),
    badge: 'profile',
    componentName: 'ClanWars',
    bodyClass: () => 'clan-wars',
    beforeEnter: (replace, root, account) => {
      if (!(!!account.id && settings.cwars.isEnabled)) {
        replace({ pathname: `${root}${ROUTES_MAP.SEARCH}` });
      }
      store.dispatch(actionsClanWars.setSelectedTab(2));
    },
    display: () => {
      return false;
    },
  },
  {
    path: ROUTES_MAP.CBATTLES,
    title: () => t('Клановые бои'),
    badge: 'clan-battles',
    componentName: 'ContainerClanBattles',
    bodyClass: () => 'clan-wars',
    beforeEnter: (replace, root, account) => {
      if (!account.clanId && isEmpty(lastSeason)) {
        replace({ pathname: root });
      } else {
        replace({ pathname: `${root}${ROUTES_MAP.CBATTLES_HISTORY}` });
      }
    },
    display: () => {
      return !!(getLastRegularSeason() || getLastBrawlSeason());
    },
  },
  {
    path: ROUTES_MAP.CBATTLES_HISTORY,
    title: () => t('Клановые бои - История'),
    badge: 'clan-battles',
    componentName: 'ContainerClanBattles',
    bodyClass: () => 'clan-wars',
    beforeEnter: (replace, root, account) => {
      if (!account.clanId && isEmpty(lastSeason)) {
        replace({ pathname: root });
      }
      store.dispatch(actionsClanBattles.setSelectedTab(0));
    },
    display: () => {
      return false;
    },
  },
  {
    path: ROUTES_MAP.CBATTLES_RATING,
    title: () => t('Клановые бои - Рейтинги'),
    badge: 'clan-battles',
    componentName: 'ContainerClanBattles',
    bodyClass: () => 'clan-wars',
    beforeEnter: (replace, root, account) => {
      if (!account.clanId && isEmpty(lastSeason)) {
        replace({ pathname: root });
      }
      store.dispatch(actionsClanBattles.setSelectedTab(1));
    },
    display: () => {
      return false;
    },
  },
  {
    path: ROUTES_MAP.CBATTLES_RATING_REALM,
    title: () => t('Клановые бои - Рейтинги'),
    badge: 'clan-battles',
    componentName: 'ContainerClanBattles',
    bodyClass: () => 'clan-wars',
    beforeEnter: (replace, root, account) => {
      if (!account.clanId && isEmpty(lastSeason)) {
        replace({ pathname: root });
      }
      store.dispatch(actionsClanBattles.setSelectedTab(1));
    },
    display: () => {
      return false;
    },
  },
  {
    path: ROUTES_MAP.CBATTLES_RULES,
    title: () => t('Клановые бои - Правила'),
    badge: 'clan-battles',
    componentName: 'ContainerClanBattles',
    bodyClass: () => 'clan-wars',
    beforeEnter: (replace, root, account) => {
      if (!account.clanId && isEmpty(lastSeason)) {
        replace({ pathname: root });
      }
      store.dispatch(actionsClanBattles.setSelectedTab(3));
    },
    display: () => {
      return false;
    },
  },
  {
    path: ROUTES_MAP.CBATTLES_HALL,
    title: () => t('Клановые бои - Зал славы'),
    badge: 'clan-battles',
    componentName: 'ContainerClanBattles',
    bodyClass: () => 'clan-wars',
    beforeEnter: (replace, root, account) => {
      if (!settings.ladder.hallOfFameIsEnabled || (!account.clanId && isEmpty(lastSeason))) {
        replace({ pathname: root });
      }
      store.dispatch(actionsClanBattles.setSelectedTab(2));
    },
    display: () => {
      return false;
    },
  },
  {
    path: ROUTES_MAP.CSTARS,
    title: () => t('Звезда отряда'),
    badge: 'clanstars',
    componentName: 'ClanStars',
    bodyClass: () => 'clanstars',
    beforeEnter: (replace, root, account) => {
      if (!clanstarsIsEnabled || !account.clanId) {
        replace({ pathname: `${root}` });
      } else if (account.id && !sawClanStars[account.id]) {
        sawClanStars[account.id] = true;
        const keyLocalStorage = `sawClanStars_${clanstarsSeasonVersion}`;
        setValueToLocalStorage(keyLocalStorage, sawClanStars);
      }
    },
    display: (account) => clanstarsIsEnabled && !!account.clanId,
  },
];

export default routes;
