import './BoundaryCanvas';

import L from 'leaflet';
import { TileLayer } from 'react-leaflet';

import { addBlur, addMarkerBlur } from '~/utils/mapUtils';

class MaskedTileLayer extends TileLayer {
  maskReady = false;
  defaultLeafletLayer = null;
  maskedLeafletLayer = null;
  map = null;
  wasSelected = false;

  componentDidMount() {
    super.componentDidMount();
    if (this.props.contour) {
      const contour = {
        type: 'Polygon',
        coordinates: [
          this.props.contour.map((item) => {
            return [item[1], item[0]];
          }),
        ],
      };

      const options = {
        ...this.props,
        boundary: contour,
      };
      delete options.url;

      this.defaultLeafletLayer = this.leafletElement;
      this.maskedLeafletLayer = new L.TileLayer.BoundaryCanvas(this.props.url, options);
      this.map = this.leafletElement._map;
      this.maskReady = true;

      if (this.props.isSelected) {
        this.updateMask(this.props.isSelected);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);

    if (this.maskReady && nextProps.isSelected !== this.props.isSelected) {
      this.updateMask(nextProps.isSelected);
    }
    this.setBlur(nextProps);
  }

  updateMask = (isSelected) => {
    if (isSelected) {
      this.map.addLayer(this.maskedLeafletLayer);
      this.map.removeLayer(this.leafletElement);
      this.leafletElement = this.maskedLeafletLayer;
      this.wasSelected = true;
    } else {
      if (this.wasSelected) {
        this.map.addLayer(this.defaultLeafletLayer);
        this.map.removeLayer(this.leafletElement);
        this.leafletElement = this.defaultLeafletLayer;
        this.wasSelected = false;
      }
    }
  };

  setBlur = (nextProps) => {
    if (nextProps.id && nextProps.selectBuildingId && this.props.selectBuildingId === nextProps.id) {
      addBlur(nextProps.selectBuildingId);
      addMarkerBlur(nextProps.selectBuildingId);
    }
  };
}

export default MaskedTileLayer;
