import { playSound } from '@wg/web2clientapi/core/play';

export const playDropdownSound = () => {
  void playSound({
    soundsetName: 'dropmenu_button',
    action: 'click',
  });
};

export const playOpenDialogSound = () => {
  void playSound({
    soundsetName: 'default_window',
    action: 'open',
  });
};

export const playCloseDialogSound = () => {
  void playSound({
    soundsetName: 'default_window',
    action: 'close',
  });
};

export const playInputSound = () => {
  void playSound({
    soundsetName: 'default_textinput',
    action: 'change',
  });
};

export function playTabClickSound() {
  return void playSound({
    soundsetName: 'button_tab',
    action: 'click',
  });
}

export const playCheckboxSound = () => {
  void playSound({
    soundsetName: 'default_checkbox',
    action: 'click',
  });
};

export const playButtonSound = () => {
  void playSound({
    soundsetName: 'default_button',
    action: 'press',
  });
};

export const playScreenChangeSound = () => {
  void playSound({
    soundsetName: 'default_screen',
    action: 'change',
  });
};

export const playClanBaseAmbient = () => {
  void playSound({
    soundsetName: 'Clan_Base_Ambient',
    action: 'visible',
  });
};

export const stopClanBaseAmbient = () => {
  void playSound({
    soundsetName: 'Stop_Clan_Base_Ambient',
    action: 'invisible',
  });
};

export const playClanBaseOver = () => {
  void playSound({
    soundsetName: 'Clan_Base_Over',
    action: 'over',
  });
};

export const stopClanBaseOver = () => {
  void playSound({
    soundsetName: 'Stop_Clan_Base_Over',
    action: 'out',
  });
};

export const playClanBaseBuilding = () => {
  void playSound({
    soundsetName: 'Clan_Base_Building',
    action: 'click',
  });
};

export const playClanBaseSelect = () => {
  void playSound({
    soundsetName: 'Clan_Base_Select',
    action: 'click',
  });
};
