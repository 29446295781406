import classNames from 'classnames';
import * as React from 'react';

import styles from './Select.scss';

export interface SelectItem {
  name: string;
  value: string;
}

export interface SelectProps {
  items: Array<SelectItem>;
  itemValueSelected: string;
  onItemChange: (val: string) => void;
}

export interface SelectState {
  isActive: boolean;
  itemSelected: SelectItem;
}

class Select extends React.Component<SelectProps, SelectState> {
  constructor(props) {
    super(props);

    const itemSelected = this.props.items.find((obj) => obj.value === this.props.itemValueSelected);

    this.state = {
      isActive: false,
      itemSelected: {
        name: itemSelected.name,
        value: itemSelected.value,
      },
    };

    this.onToggle = this.onToggle.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onEscape = this.onEscape.bind(this);
    this.onItemChange = this.onItemChange.bind(this);
  }

  hide() {
    this.setState({ isActive: false });
  }

  onToggle() {
    this.setState({ isActive: !this.state.isActive });
  }

  onBlur() {
    this.hide();
  }

  onEscape(e) {
    if (e.keyCode === 27 && this.state.isActive) {
      e.preventDefault();
      this.hide();
    }
  }

  onItemChange(itemSelected: SelectItem) {
    this.setState({ itemSelected });
    this.props.onItemChange(itemSelected.value);
    this.hide();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onEscape, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const itemSelected = nextProps.items.find((obj) => obj.value === nextProps.itemValueSelected);
    this.setState({ itemSelected });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEscape, true);
  }

  render() {
    const classNameSelect = classNames(styles.select, {
      [styles.isActive]: this.state.isActive,
    });

    const classNameDropdown = classNames(styles.dropdown, {
      [styles.isDropped]: this.state.isActive,
    });

    const selectItems = this.props.items.map((item, index) => {
      const classNameItem = classNames(styles.item, {
        [styles.isSelected]: this.state.itemSelected.value === item.value,
      });

      return (
        <li
          className={classNameItem}
          key={index}
          onClick={() => {
            this.onItemChange(item);
          }}
        >
          {item.name}
        </li>
      );
    });

    return (
      <div className={styles.container} tabIndex={1} onBlur={this.onBlur}>
        <div className={classNameSelect} onClick={this.onToggle}>
          {this.state.itemSelected.name}
        </div>
        <menu className={classNameDropdown}>{selectItems}</menu>
      </div>
    );
  }
}

export default Select;
