import './calendar.scss';

import React from 'react';
import { shallowEqual } from 'react-redux';

import { push } from 'react-router-redux';
import { playButtonSound } from '@wg/web2clientapi/sound';
import { Tabs } from '@wg/wows-react-uikit';

import preloaded from '~/preloaded';
import settings from '~/settings';
import { PERMISSIONS, TREASURY_TABS } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { t } from '~/helpers/localization';
import useMount from '~/hooks/useMount';
import { ROUTES_MAP } from '~/routes';
import { useAppDispatch, useAppSelector } from '~/store';
import { syncAccountInfo } from '~/Actions/ActionAccount';
import { init, setSelectedTabThunk } from '~/Actions/ActionTreasury';

import StepCSSTransitionGroup from '~/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import ClanBalance from './ClanBalance/ClanBalance';
import styles from './Treasury.scss';

import type { RouteComponentProps } from 'react-router';
import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    permissions: state.currentAccount.permissions,
    clan: state.currentAccount.clanId ? state.clans.items[state.currentAccount.clanId] : null,
    totalBalanceList: state.ReducerTreasury.totalBalanceList,
    selectedTab: state.ReducerTreasury.selectedTab,
  };
};

type ITreasuryProps = {
  children: React.ReactNode;
};
type ITreasuryRouteProps = ITreasuryProps & RouteComponentProps<ITreasuryProps, unknown>;

const Treasury: React.FC<ITreasuryRouteProps> = ({ children, routes }) => {
  const { clan, permissions, totalBalanceList, selectedTab } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const childrenAnimationLevel = React.useRef(3);

  const personalResource = clan && clan.personalResource ? clan.personalResource : 0;
  const balance = React.useMemo(() => {
    totalBalanceList
      .filter((resource) => resource.type !== 'oil')
      .push({ type: 'oil', amount: personalResource, canDistribute: false });
    return totalBalanceList;
  }, [personalResource, totalBalanceList]);

  const currentTab = routes[routes.length - 1].path as TREASURY_TABS;

  useMount(() => {
    dwhExport.push(DWH_EVENTS.TREASURY.OPEN_PAGE);

    void dispatch(init());
    void dispatch(syncAccountInfo());
  });

  React.useEffect(() => {
    dispatch(setSelectedTabThunk(currentTab));
    if (selectedTab !== currentTab) {
      childrenAnimationLevel.current = 0;
    }
  }, [dispatch, currentTab, selectedTab]);

  const navigateToTab = React.useCallback(
    (tab: TREASURY_TABS, playSound: boolean = false) => {
      if (playSound) {
        void playButtonSound();
      }
      dispatch(push(`${preloaded.urls.root}${ROUTES_MAP.TREASURY}/${tab}`));
    },
    [dispatch],
  );

  const canDistribute = permissions && permissions.indexOf(PERMISSIONS.DIVIDE_PROFITS) !== -1;
  const bundlesEnabled = Boolean(settings.treasury?.bundlesEnabled);
  const isDistributionTab = currentTab === TREASURY_TABS.DISTRIBUTION;
  const isRegularRewardsTab = currentTab === TREASURY_TABS.REWARDS;

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        // @ts-ignore
        animationLevelNested: 2 * childrenAnimationLevel.current,
      });
    }
    return child;
  });

  return (
    <div className={styles.wrapper}>
      <StepCSSTransitionGroup level={1} duration={0.2} delay={0.05}>
        <span className={styles.pageTitle}>{t('Ресурсы клана')}</span>
        <div className={styles.clanBalance}>
          <ClanBalance totalBalanceList={balance} />
        </div>
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup level={2} className={styles.actionsRow} duration={0.2} delay={0.05}>
        <Tabs
          isUppercase
          smallHeight
          tabSelected={currentTab}
          tabs={[
            { value: TREASURY_TABS.EXPENSES, content: t('Расходы') },
            { value: TREASURY_TABS.INCOME, content: t('Доход') },
          ]}
          onToggleTab={navigateToTab as (tab: string) => void}
        />

        <div className={styles.separator} />

        {canDistribute && (
          <div className={`${isDistributionTab ? styles.distributeActive : ''} ${styles.distribute}`}>
            <span onClick={() => navigateToTab(TREASURY_TABS.DISTRIBUTION, true)}>{t('Распределить ресурсы')}</span>
          </div>
        )}

        {bundlesEnabled && (
          <div className={`${isRegularRewardsTab ? styles.regularRewardsActive : ''} ${styles.regularRewards}`}>
            <span onClick={() => navigateToTab(TREASURY_TABS.REWARDS, true)}>{t('Контейнеры для клана')}</span>
          </div>
        )}
      </StepCSSTransitionGroup>
      <div className={styles.tabContent}>
        <div className={styles.tabContentInner}>{childrenWithProps}</div>
      </div>
    </div>
  );
};

export default React.memo(Treasury);
