import { t } from '~/helpers/localization';

import type { IconTableTypes } from '@wg/wows-react-uikit/components/IconTable/IconTable';

type IHeaderCell = {
  text?: string;
  modify?: 'check' | 'small' | 'middle' | 'basis' | 'left' | 'right' | 'center' | 'buttons' | 'role';
  glyph?: IconTableTypes;
  tooltipText?: string;
  tooltipParam?: string;
  isHidden?: boolean;
};

const headersSettings: IHeaderCell[] = [
  {
    text: t('Клан'),
    modify: 'basis',
  },
  {
    modify: 'small',
    glyph: 'members-count',
    tooltipText: t('Количество игроков в клане.:tooltip'),
  },
  {
    text: 'aBTL',
    modify: 'right',
    glyph: 'battles',
    tooltipText: t('Среднее количество боёв на игрока клана в выбранном типе боев за всё время.:tooltip'),
    tooltipParam: 'aBTL &mdash; average Battles',
  },
  {
    text: 'aW/B',
    modify: 'right',
    glyph: 'wins-per-bettles',
    tooltipText: t('Средний процент побед на игрока клана в выбранном типе боев за всё время.:tooltip'),
    tooltipParam: 'aW/B &mdash; average Wins per Battles',
  },
  {
    text: 'aE/B',
    modify: 'right',
    glyph: 'experience-per-battle',
    tooltipText: t('Средний опыт за бой на игрока клана в выбранном типе боев за всё время.:tooltip'),
    tooltipParam: 'aE/B &mdash; average Experience per Battle',
  },
  {
    text: 'aD/B',
    modify: 'right',
    glyph: 'damage-per-battle',
    tooltipText: t('Средний урон за бой на игрока клана в выбранном типе боев за всё время.:tooltip'),
    tooltipParam: 'aD/B &mdash; average Damage per Battle',
  },
  {
    text: 'aF/B',
    modify: 'right',
    glyph: 'frags-per-battle',
    tooltipText: t(
      'Среднее количество потопленных кораблей за бой на игрока клана в выбранном типе боев за всё время.:tooltip',
    ),
    tooltipParam: 'aF/B &mdash; average Frags per Battle',
  },
];

export default headersSettings;
