import React from 'react';
import Divider from '@wg/wows-react-uikit/components/Divider/Divider';

import preloaded from '~/preloaded';

import StepCSSTransitionGroup from '~/Components/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';
import EventCountdown from '~/UIKit/EventCountdown/EventCountdown';

import CStarsMembers from './CStarsMembers/CStarsMembers';
import CStarsProgress from './CStarsProgress/CStarsProgress';
import CStarsTasks from './CStarsTasks/CStarsTasks';

import styles from './ClanStarsDivisions.scss';

const ClanStarsDivisions: React.FC = () => {
  const seasonEndsTimestamp = preloaded.clanStarsSeasonSettings?.seasonDateFinish;

  let transitionIndex = 0;

  return (
    <div className={styles.wrapper}>
      {seasonEndsTimestamp ? (
        <StepCSSTransitionGroup level={++transitionIndex}>
          <EventCountdown date={seasonEndsTimestamp * 1000} />
        </StepCSSTransitionGroup>
      ) : null}

      <StepCSSTransitionGroup level={++transitionIndex}>
        <CStarsTasks />
      </StepCSSTransitionGroup>

      <StepCSSTransitionGroup level={++transitionIndex}>
        <Divider type={'major_light'} className={styles.divider} />
        <CStarsProgress />
      </StepCSSTransitionGroup>

      <StepCSSTransitionGroup level={++transitionIndex}>
        <Divider type={'major_light'} className={styles.divider} />
        <CStarsMembers />
      </StepCSSTransitionGroup>
    </div>
  );
};

export default React.memo(ClanStarsDivisions);
