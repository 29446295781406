import { Currency } from '@wg/wows-react-uikit';

import { TREASURY_TABS } from '~/constants';
import { t } from '~/helpers/localization';
import {
  TRANSACTIONS_STATUSES_MAP,
  APPEND_TRANSACTIONS,
  DISTRIBUTE_RESOURCES,
  FILTER_TRANSACTIONS,
  GET_TOTAL_BALANCE,
  GET_TOTAL_TRANSACTIONS_SUM,
  GET_TRANSACTIONS,
  GET_TREASURY_CONFIG,
  LISTEN_TRANSACTIONS_STATUS_UPDATES,
  SELECT_DISTRIBUTION_CURRENCY,
  SET_DETAIL_VIEW_TRANSACTION,
  SET_DISTRIBUTION_FILTER,
  SET_DISTRIBUTION_SUM,
  SET_FILTER_DATES,
  SET_IS_TRANSACTION_LIST_UPDATING,
  SET_SELECTED_TAB,
  SET_SORT_FIELD,
  SET_TRANSACTION_SUM_UPDATING,
  TOGGLE_DISTRIBUTION_MEMBERS,
  TOGGLE_IS_DISTRIBUTION_FILTER_VISIBLE,
  UNSET_DETAIL_VIEW_TRANSACTION,
  UPDATE_TREASURY_BOXES_COOLDOWN,
} from '~/Actions/ActionTreasury';

import type { Moment } from 'moment';
import type { ActionsType } from '~/Actions/ActionTreasury';
import type {
  Balance,
  CurrencyType,
  DayTransactions,
  IClanMember,
  Transaction,
  Transactions,
  TransactionUpdatingStatus,
} from '~/types/declaration';

const regularRewards = {
  offer_1: {
    title: t('Больше угля для всех'),
    description: t('1 Контейнер «Больше угля» с вероятностью выпадения суперконтейнера каждому участнику клана.'),
  },
  offer_2: {
    title: t('Испытать удачу для всех'),
    description: t(
      '5 «Малых контейнеров» с повышенной вероятностью выпадения суперконтейнера каждому участнику клана.',
    ),
  },
  offer_3: {
    title: t('Суперконтейнеры для всех'),
    description: t('1 суперконтейнер каждому участнику клана.'),
  },
  offer_4: {
    title: t('Дополнительный уголь для всех'),
    description: t('1 Контейнер «Больше угля» с вероятностью выпадения суперконтейнера каждому участнику клана.'),
  },
  offer_5: {
    title: t('Дополнительно испытать удачу для всех'),
    description: t(
      '5 «Малых контейнеров» с повышенной вероятностью выпадения суперконтейнера каждому участнику клана.',
    ),
  },
  offer_6: {
    title: t('Дополнительные суперконтейнеры для всех'),
    description: t('1 суперконтейнер каждому участнику клана.'),
  },
} as const;

type IRegularRewardsKeys = keyof typeof regularRewards;

export interface RegularReward {
  classifier: number;
  delay: number;
  description: string;
  interval: number;
  lastReceive: Nullable<string>;
  offer_id?: string | number;
  price: number;
  requiredLevel: number;
  requirements: {
    delay: number;
    modifiers: number[];
    oil: number;
  };
  title: string;
}

type RegularRewardsOfferData = {
  classifier: number;
  delay: number;
  lastReceive: Nullable<string>;
};

export type RegularRewardsOffersCooldown = {
  [key in IRegularRewardsKeys]?: RegularRewardsOfferData;
};

export type RegularRewardsType = {
  availableRewardOffers: RegularReward[];
  bundlesEnabled: boolean;
  isEnabled: boolean;
  minDaysInClan: number;
  minDistributionSum: number;
} & RegularRewardsOffersCooldown;

type ITreasuryState = {
  cooldownUpdated: boolean;
  detailViewTransaction: Transaction | null;
  distributionList: Array<number>;
  distributionPlayerFilter: string;
  distributionSum: number;
  filterEndDate: Moment | null;
  filterStartDate: Moment | null;
  isAppending: boolean;
  isDistributionFilterInputVisible: boolean;
  isTotalTransactionsSumUpdating: boolean;
  isTransactionListUpdating: boolean;
  isTreasuryConfigReady: boolean;
  lastOffet: number | null;
  regularRewards: RegularRewardsType;
  selectedDistributionCurrency: CurrencyType;
  selectedTab: TREASURY_TABS;
  sortDirection: null | 'ask' | 'desc';
  sortField: Nullable<keyof IClanMember>;
  totalBalanceList: Balance;
  totalTransactionsSum: Balance;
  transactions: Transactions;
  transactionsOffset: number;
  transactionsRaw: Transactions;
};

const initialState: ITreasuryState = {
  cooldownUpdated: false,
  detailViewTransaction: null,
  distributionList: [],
  distributionPlayerFilter: '',
  distributionSum: 0,
  filterEndDate: null,
  filterStartDate: null,
  isAppending: false,
  isDistributionFilterInputVisible: false,
  isTotalTransactionsSumUpdating: false,
  isTransactionListUpdating: false,
  isTreasuryConfigReady: false,
  lastOffet: null,
  regularRewards: {
    bundlesEnabled: false,
    availableRewardOffers: [],
    isEnabled: false,
    minDaysInClan: 0,
    minDistributionSum: 0,
  },
  selectedDistributionCurrency: 'steel',
  selectedTab: TREASURY_TABS.EXPENSES,
  sortDirection: 'ask',
  sortField: 'roleName', // null,
  totalBalanceList: [],
  totalTransactionsSum: [],
  transactions: [],
  transactionsOffset: 0,
  transactionsRaw: [],
};

export const ReducerTreasury = (state: ITreasuryState = initialState, action: ActionsType): ITreasuryState => {
  switch (action.type) {
    case UPDATE_TREASURY_BOXES_COOLDOWN: {
      return {
        ...state,
        cooldownUpdated: true,
        regularRewards: {
          ...state.regularRewards,
          availableRewardOffers: state.regularRewards.availableRewardOffers.map((reward) => {
            let updatedReward: RegularReward;
            const rewardData = reward.offer_id
              ? (action.payload[reward.offer_id] as RegularRewardsOfferData) || null
              : null;

            if (rewardData) {
              updatedReward = {
                ...reward,
                ...rewardData,
              };
            } else {
              updatedReward = {
                ...reward,
                classifier: 0,
                delay: 0,
                lastReceive: null,
              };
            }

            return updatedReward;
          }),
        },
      };
    }

    case GET_TREASURY_CONFIG: {
      return {
        ...state,
        isTreasuryConfigReady: true,
        regularRewards: {
          ...state.regularRewards,
          ...action.payload,
          availableRewardOffers: action.payload.availableRewardOffers.map((reward, index) => {
            let updatedReward = {
              ...state.regularRewards.availableRewardOffers[index],
              ...reward,
            };
            if (reward.offer_id && regularRewards[reward.offer_id]) {
              updatedReward = {
                ...updatedReward,
                ...(regularRewards[reward.offer_id] as Partial<RegularReward>),
              };
            }
            return updatedReward;
          }),
        },
      };
    }

    case SET_SORT_FIELD: {
      const newSortDirection = state.sortDirection === 'ask' ? 'desc' : 'ask';
      return {
        ...state,
        sortField: action.payload.sortField || null,
        sortDirection: newSortDirection,
      };
    }

    case TOGGLE_IS_DISTRIBUTION_FILTER_VISIBLE: {
      return {
        ...state,
        isDistributionFilterInputVisible: !state.isDistributionFilterInputVisible,
      };
    }

    case SET_DISTRIBUTION_FILTER: {
      return {
        ...state,
        distributionPlayerFilter: action.payload.value,
      };
    }

    case APPEND_TRANSACTIONS: {
      return {
        ...state,
        isAppending: action.payload.isAppending,
      };
    }

    case LISTEN_TRANSACTIONS_STATUS_UPDATES: {
      const transactionUpdates: Record<number, TransactionUpdatingStatus> = {};
      action.payload.data.forEach((item) => {
        transactionUpdates[item.id] = item;
      });

      const newTransactions = state.transactions.map((dayTransactions) => {
        return {
          ...dayTransactions,
          transactions: dayTransactions.transactions.map((singleTransaction) => {
            const gtId = singleTransaction.data.gameTransactionId ? singleTransaction.data.gameTransactionId : 0;
            if (transactionUpdates[gtId]) {
              const newsSingleTransaction: Transaction = {
                ...singleTransaction,
                status: TRANSACTIONS_STATUSES_MAP[transactionUpdates[gtId].status],
                data: {
                  ...singleTransaction.data,
                  failedReceivers: transactionUpdates[gtId].failedReceivers,
                },
              };
              return newsSingleTransaction;
            } else if (singleTransaction.childTransactions && singleTransaction.childTransactions.length) {
              return {
                ...singleTransaction,
                childTransactions: singleTransaction.childTransactions.map((childTransaction) => {
                  const childGtId = childTransaction.data.gameTransactionId
                    ? childTransaction.data.gameTransactionId
                    : 0;
                  if (transactionUpdates[childGtId]) {
                    const newsSingleChildTransaction = {
                      ...childTransaction,
                      status: TRANSACTIONS_STATUSES_MAP[transactionUpdates[childGtId].status],
                      data: {
                        ...childTransaction.data,
                        failedReceivers: transactionUpdates[childGtId].failedReceivers,
                      },
                    };
                    return newsSingleChildTransaction;
                  } else {
                    return childTransaction;
                  }
                }),
              };
            } else {
              return singleTransaction;
            }
          }),
        };
      });

      return {
        ...state,
        transactions: newTransactions,
      };
    }

    case SET_DETAIL_VIEW_TRANSACTION: {
      return {
        ...state,
        detailViewTransaction: action.payload.transaction,
      };
    }

    case UNSET_DETAIL_VIEW_TRANSACTION: {
      return {
        ...state,
        detailViewTransaction: null,
      };
    }

    case DISTRIBUTE_RESOURCES: {
      return {
        ...state,
        distributionList: [],
        distributionSum: 0,
      };
    }

    case SET_IS_TRANSACTION_LIST_UPDATING: {
      return {
        ...state,
        isTransactionListUpdating: action.payload.isUpdating,
        isAppending: false,
      };
    }

    case SET_TRANSACTION_SUM_UPDATING: {
      return {
        ...state,
        isTotalTransactionsSumUpdating: true,
      };
    }

    case GET_TOTAL_TRANSACTIONS_SUM: {
      return {
        ...state,
        totalTransactionsSum: action.payload.totalTransactionsSum,
        isTotalTransactionsSumUpdating: false,
      };
    }

    case SELECT_DISTRIBUTION_CURRENCY: {
      return {
        ...state,
        selectedDistributionCurrency: action.payload.type,
      };
    }

    case SET_DISTRIBUTION_SUM: {
      return {
        ...state,
        distributionSum: action.payload.sum,
      };
    }

    case TOGGLE_DISTRIBUTION_MEMBERS: {
      let newDistributionList = state.distributionList.slice();

      if (action.payload.toggleAll) {
        if (action.payload.members.length === 0 || action.payload.members.length === newDistributionList.length) {
          newDistributionList = [];
        } else {
          newDistributionList = action.payload.members;
        }
      } else {
        action.payload.members.forEach((id) => {
          if (newDistributionList.indexOf(id) !== -1) {
            newDistributionList = newDistributionList.filter((item) => item !== id);
          } else {
            newDistributionList.push(id);
          }
        });
      }

      return {
        ...state,
        distributionList: newDistributionList,
      };
    }

    case GET_TOTAL_BALANCE: {
      const defaultCurrency = action.payload.totalBalanceList.filter((currency) => {
        return currency.canDistribute === true && currency.amount > 0;
      })[0];
      const steel: CurrencyType = 'steel';

      return {
        ...state,
        totalBalanceList: action.payload.totalBalanceList,
        selectedDistributionCurrency: defaultCurrency ? defaultCurrency.type : (Currency.types[steel] as CurrencyType),
      };
    }

    case SET_FILTER_DATES: {
      return {
        ...state,
        filterStartDate: action.payload.start,
        filterEndDate: action.payload.end,
      };
    }

    case SET_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: action.payload.index,
        isAppending: false,
        transactionsOffset: 0,
        lastOffet: null,
      };
    }

    case FILTER_TRANSACTIONS: {
      return {
        ...state,
        transactions: action.payload.transactions,
      };
    }

    case GET_TRANSACTIONS: {
      if (state.isAppending) {
        const mergedTransactions: DayTransactions[] = [];
        const newTransactions = action.payload.transactions;

        state.transactions.forEach((dayTransaction) => {
          const dayToMerge: DayTransactions | null = haveThisDay(newTransactions, dayTransaction.date);
          if (dayToMerge !== null) {
            mergedTransactions.push({
              ...dayTransaction,
              transactions: dayTransaction.transactions.concat(dayToMerge.transactions),
            });
          } else {
            mergedTransactions.push(dayTransaction);
          }
        });

        newTransactions.forEach((dayTransaction) => {
          const dayToMerge = haveThisDay(state.transactions, dayTransaction.date);
          if (dayToMerge === null) {
            mergedTransactions.push(dayTransaction);
          }
        });

        return {
          ...state,
          transactions: mergedTransactions,
          isAppending: false,
          transactionsOffset: getTransactionCount(mergedTransactions),
          lastOffet: action.payload.lastOffet,
        };
      } else {
        return {
          ...state,
          transactions: action.payload.transactions,
          isAppending: false,
          transactionsOffset: getTransactionCount(action.payload.transactions),
          lastOffet: action.payload.lastOffet,
        };
      }
    }

    default:
      return state;
  }
};

const getTransactionCount = (transactions: DayTransactions[]) => {
  let count = 0;

  if (transactions && transactions.length) {
    transactions.forEach((dayTransactions) => {
      if (dayTransactions && dayTransactions.transactions) {
        count += dayTransactions.transactions.length;
      }
    });
  }

  return count;
};

const haveThisDay = (array: DayTransactions[], date: string) => {
  return array.find((item) => item.date === date) || null;
};
