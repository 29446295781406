import React from 'react';
import { Currency, Spinner } from '@wg/wows-react-uikit';

import styles from './TotalBalance.scss';

import type { Balance } from '~/types/declaration';

type ITotalBalanceProps = {
  currencyList: Balance;
  isUpdating: boolean;
};

const TotalBalance: React.FC<ITotalBalanceProps> = (props) => {
  if (props.isUpdating) {
    return (
      <div className={styles.preloader}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {props.currencyList.length > 0 ? (
        props.currencyList
          .sort((a, b) => Currency.order[b.type] - Currency.order[a.type])
          .map((item, index) => {
            return (
              <div key={`TotalCurrnecy${index}`}>
                <Currency isFlat type={item.type} amount={item.amount} isBold />
              </div>
            );
          })
      ) : (
        <div className={styles.preloader} />
      )}
    </div>
  );
};

export default React.memo(TotalBalance);
