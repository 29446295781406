import {
  ON_CLICK_BATTLE,
  SET_CLAN_BATTLES,
  SET_CLAN_BATTLES_FETCHING,
  SET_SELECTED_CLAN_BATTLES_TAB,
  SET_SELECTED_TEAM_NUMBER,
} from '~/Actions/ActionClanBattles';

import type { ActionsType } from '~/Actions/ActionClanBattles';
import type { IClanBattle } from '~/types/declaration';

type StateType = {
  battlesList: IClanBattle[];
  isError: boolean;
  isFetching: boolean;
  selectedBattle: number;
  selectedTab: number;
  teamNumber: number;
};

const initialState = {
  battlesList: [],
  isError: false,
  isFetching: false,
  selectedBattle: 0,
  selectedTab: 0,
  teamNumber: 1,
};

export const ReducerClanBattles = (state: StateType = initialState, action: ActionsType): StateType => {
  switch (action.type) {
    case ON_CLICK_BATTLE: {
      const newSelectedBattle = action.payload.battleId === state.selectedBattle ? 0 : action.payload.battleId;
      return {
        ...state,
        selectedBattle: newSelectedBattle,
      };
    }

    case SET_CLAN_BATTLES: {
      return {
        ...state,
        battlesList: action.payload.battles,
      };
    }

    case SET_CLAN_BATTLES_FETCHING: {
      return {
        ...state,
        isFetching: action.payload.isFetching,
      };
    }

    case SET_SELECTED_TEAM_NUMBER: {
      return {
        ...state,
        teamNumber: action.payload.teamNumber,
      };
    }

    case SET_SELECTED_CLAN_BATTLES_TAB: {
      return {
        ...state,
        selectedTab: action.payload.selectedTab,
      };
    }

    default:
      return state;
  }
};
