import React, { PureComponent } from 'react';
import {
  ClanRole,
  Currency,
  DivTooltip,
  IconTable,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
  TooltipBody,
} from '@wg/wows-react-uikit';

import { isoToFormattedLocalDateTime } from '~/helpers/datetime';
import { thousands } from '~/helpers/formatting';

import { header } from './header';

import type { IHistoryRoundPlayer } from '~/types/declaration';

export type HistoryRatingTable_Props = {
  data: Array<IHistoryRoundPlayer>;
  maxPersonalAttempts: number;
};

class HistoryRatingTable extends PureComponent<HistoryRatingTable_Props> {
  renderTableHead() {
    return header.map((cell, key) => {
      return (
        <TableHeadCell key={`head-cell-${key}`} modify={cell.modify}>
          {cell.tooltipBody ? (
            <DivTooltip tooltipBody={<TooltipBody>{cell.tooltipBody}</TooltipBody>}>
              {cell.type === 'role' ? <IconTable glyph="roles" /> : cell.text}
            </DivTooltip>
          ) : cell.type === 'role' ? (
            <IconTable glyph="roles" />
          ) : (
            cell.text
          )}
        </TableHeadCell>
      );
    });
  }

  renderTableBody = () => {
    return this.props.data
      .sort((a, b) => b.stars - a.stars)
      .map((player, index) => {
        return (
          <TableRow isHovering key={`war-table-row-${index}`}>
            <TableBodyCell modify="small" isHovering={false}>
              <ClanRole role={player.role} />
            </TableBodyCell>
            <TableBodyCell modify="basis" isHovering={false}>
              {player.nick}
            </TableBodyCell>
            <TableBodyCell modify="center" isHovering={false}>
              <Currency isFlat type="cwars-star" amount={player.stars} />
            </TableBodyCell>
            <TableBodyCell modify="right" isHovering={false}>
              {thousands(player.bestGrade)}
            </TableBodyCell>
            <TableBodyCell modify="right" isHovering={false}>
              {`${player.attempts}/${player.maxAttempts}`}
            </TableBodyCell>
            <TableBodyCell modify="right" isHovering={false}>
              {isoToFormattedLocalDateTime(player.lastWarBattleAt)}
            </TableBodyCell>
          </TableRow>
        );
      });
  };

  render() {
    return (
      <Table>
        <TableHead>{this.renderTableHead()}</TableHead>
        <TableBody>{this.renderTableBody()}</TableBody>
      </Table>
    );
  }
}

export default HistoryRatingTable;
