import * as React from 'react';

import styles from './Paragraph.scss';
import { generateMarkup } from '../../helpers/generateMarkup';

type IProps = {
  content: {
    title: string;
    text: string;
  };
};

class Paragraph extends React.PureComponent<IProps> {
  render() {
    const content = this.props.content;

    return content ? (
      <div className={styles.paragraphInfoBlockWrapper}>
        <div className={styles.blockTitle}>{generateMarkup(content.title)}</div>
        {generateMarkup(content.text)}
      </div>
    ) : null;
  }
}

export default Paragraph;
