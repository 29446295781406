import settings from '~/settings';
import i18n from '~/i18n';

export const t: (string: string, data?: Record<string, string | number>) => string = i18n.getFixedT(
  settings.realm?.languageCode,
);

export const x = (string: string) => string;

export const ngettext = window.ngettext || x;

export const getLocalizationSafe = (
  object: Record<string, unknown> & { localization?: Record<string, Record<string, string>> },
  string: string,
) => {
  const lString = object.localization?.[string];
  return lString?.[settings.realm?.languageCode] || lString?.[settings.realm?.languageCodeGloss] || '';
};
