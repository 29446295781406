import { t } from '~/helpers/localization';

export interface TaskDescription {
  title: string;
  description?: string;
  total?: number;
  binary?: boolean;
}

export const TASK_DESCRIPTIONS = {
  change_description: {
    title: t('Измените описание клана'),
    description: t('Изменить описание клана можно из контекстного меню названия клана на Военной базе клана.'),
    binary: true,
  },
  change_recommendation_settings: {
    title: t('Измените требования клана'),
    description: t('Изменить требования можно из контекстного меню названия клана на Военной базе клана.'),
    binary: true,
  },
  send_invite: {
    title: t('Отправьте приглашение в клан'),
    description: t(
      'Пригласить игрока можно из контекстного меню имени игрока, либо в профиле игрока на официальном портале.',
    ),
    binary: true,
  },
  accept_application: {
    title: t('Примите игрока в клан'),
    description: t('Принять игрока можно подтвердив заявку на вступление в разделе Запросы.'),
    binary: true,
  },
  appoint_a_general: {
    title: t('Назначьте военкома'),
    description: t('Изменить должность можно из контекстного меню имени игрока, либо в разделе Состав клана.'),
    binary: true,
  },
  collect_1000_oil: {
    title: t('Получите 1000 нефти'),
    description:
      t(
        'Нефть начисляется на счет клана при получении контейнеров игроками клана, за участие в Клановых боях и Морском сражении, за выполнение специальных боевых задач.',
      ) +
      t(
        'Учитывается общее количество полученной нефти, в том числе и нефть потраченная на приобретение улучшений Военной базы клана.',
      ),
    total: 1000,
    binary: false,
  },
  build_a_structure: {
    title: t('Приобретите улучшение на Военной базе клана'),
    binary: true,
  },
  join_to_naval_battles: {
    title: t('Примите участие в Морском сражении'),
    description: t(
      'Получить дополнительную информацию о Морском сражении и зарегистрировать клан для участия в нем, можно в разделе Морское сражение.',
    ),
    binary: true,
  },
  complete_preparation_at_naval_battles: {
    title: t('Пройдите этап подготовки в Морском сражении'),
    description: t(
      'Получить дополнительную информацию о Морском сражении и зарегистрировать клан для участия в нем, можно в разделе Морское сражение.',
    ),
    binary: true,
  },
  win_a_battle_at_naval_battles: {
    title: t('Победите в Морском сражении'),
    description: t(
      'Получить дополнительную информацию о Морском сражении и зарегистрировать клан для участия в нем, можно в разделе Морское сражение.',
    ),
    binary: true,
  },
  get_500_rating_points: {
    title: t('Наберите 500 очков рейтинга в Морском сражении'),
    description: t(
      'Получить дополнительную информацию о Морском сражении и зарегистрировать клан для участия в нем, можно в разделе Морское сражение.',
    ),
    total: 500,
    binary: false,
  },
  ten_active_players: {
    title: t('Соберите в клане не менее 10 игроков '),
    description: t('Учитываются игроки, которые провели не менее 20 боев за последний месяц.'),
    total: 10,
    binary: false,
  },
  seven_active_players_per_one_day: {
    title: t('Соберите в клане не менее 7 активных игроков '),
    description: t('Активным считается игрок, который провел хотя бы 1 бой за последний день.'),
    total: 7,
    binary: false,
  },
  pve_for_5_stars: {
    title: t('Пройдите любую Операцию отрядом из 4 или более игроков из вашего клана, выполнив 5 дополнительных задач'),
    description: t(
      'Операции — это особый тип боя, один из видов сценарных боев, для участия в котором необходимо выбрать соответствующий тип боя в порту.',
    ),
    binary: true,
  },
  clan_battle: {
    title: t('Сыграйте бой в Клановых боях'),
    description: t(
      'Клановые бои — это особый тип боя, в котором команда игроков клана сражается против команды из другого клана. Для участия в Клановых боях необходимо выбрать соответствующий тип боя в порту. Правила и рейтинг кланов доступны в разделе Клановые бои.',
    ),
    binary: true,
  },
  win_clan_battle: {
    title: t('Победите в Клановых боях'),
    description: t(
      'Клановые бои — это особый тип боя, в котором команда игроков клана сражается против команды из другого клана. Для участия в Клановых боях необходимо выбрать соответствующий тип боя в порту. Правила и рейтинг кланов доступны в разделе Клановые бои.',
    ),
    binary: true,
  },
  clan_battle_in_gale_league: {
    title: t('Сыграйте бой в Клановых боях в лиге Буря'),
    description: t(
      'Клановые бои — это особый тип боя, в котором команда игроков клана сражается против команды из другого клана. Для участия в Клановых боях необходимо выбрать соответствующий тип боя в порту. Правила и рейтинг кланов доступны в разделе Клановые бои.',
    ),
    binary: true,
  },
  collect_50000_oil: {
    title: t('Получите 50000 нефти'),
    description: t(
      'Нефть начисляется на счет клана при получении контейнеров игроками клана, за участие в Клановых боях и Морском сражении, за выполнение специальных боевых задач. Учитывается общее количество полученной нефти, в том числе и нефть потраченная на приобретение улучшений Военной базы клана.',
    ),
    total: 50000,
    binary: false,
  },
};

export const CLAN_TASK_NAMES = Object.keys(TASK_DESCRIPTIONS);
