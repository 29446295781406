import { GET_PROMO_DATA, NAVIGATE_TO_SLIDE, SET_SLIDER_REF } from '~/Actions/ActionPromo';

import type { ActionsType } from '~/Actions/ActionPromo';

type IPromoState = {
  promoInitialSlide: number;
  promoSlider: React.Ref<React.ReactElement>;
};

const initialState: IPromoState = {
  promoInitialSlide: 0,
  promoSlider: null,
};

export const ReducerPromo = (state: IPromoState = initialState, action: ActionsType): IPromoState => {
  switch (action.type) {
    case NAVIGATE_TO_SLIDE: {
      return {
        ...state,
        promoInitialSlide: action.payload.index,
      };
    }

    case GET_PROMO_DATA: {
      return {
        ...state,
      };
    }

    case SET_SLIDER_REF: {
      return {
        ...state,
        promoSlider: action.promoSlider,
      };
    }

    default:
      return state;
  }
};
