import get from 'lodash/get';
import zipObject from 'lodash/zipObject';

import settings from '~/settings';

export const getLanguagesMap = (languages: Array<string>) => {
  const allowedLanguages = get(settings, 'clanrec2.languages', []);
  return zipObject<boolean>(
    allowedLanguages,
    allowedLanguages.map((x) => languages && languages.indexOf(x) > -1),
  );
};
