import React from 'react';

const { Provider, Consumer } = React.createContext(null);

type IIsFromSearchContextProvider = {
  isFromSearch: boolean;
  children?: React.ReactNode;
};

const IsFromSearchContextProvider: React.FC<IIsFromSearchContextProvider> = ({ isFromSearch, children }) => {
  return <Provider value={isFromSearch}>{children}</Provider>;
};

export { IsFromSearchContextProvider, Consumer as IsFromSearchContextConsumer };
