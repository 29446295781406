import isEmpty from 'lodash/isEmpty';
import maxBy from 'lodash/maxBy';
import * as React from 'react';
import { Interpolate } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { Button, ButtonGroup, Currency, DialogBody, DialogFooter, DialogHeader } from '@wg/wows-react-uikit';

import { t, x } from '~/helpers/localization';
import { useAppSelector } from '~/store';

import AttemptTable from '~/Components/ViewClanWars/AttemptTable/AttemptTable';

import AttemptsBadge from '~/Components/ViewClanWars/AttemptsBadge/AttemptsBadge';

import { getModifiersMap, getPersonalAttemptsData } from '~/Components/ViewClanWars/helpers';

import styles from './CWarsAttemptsDialog.scss';
import ResultsHeader from '../ResultsHeader/ResultsHeader';

import type { AppStateType as RootState } from '~/Reducers';
import type { IDialog, IBattle } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  return {
    currentAccount: state.currentAccount,
    ships: state.ReducerClanWars.ships,
    stages: state.ReducerClanWars.stages,
    warSettings: state.ReducerClanWars.warSettings,
  };
};

type ICWarsAttemptsDialog = IDialog<{
  selectedAttemptsUserId: number;
}>;

const CWarsAttemptsDialog: React.FC<ICWarsAttemptsDialog> = ({ data, hideDialog }) => {
  const { selectedAttemptsUserId } = data;
  const { currentAccount, stages, ships, warSettings } = useAppSelector(stateSelector, shallowEqual);

  const userBattles = stages.war.battles.filter((battle) => {
    return battle.spaId === Number(selectedAttemptsUserId);
  });

  const reachedBattles = userBattles.filter((battle) => {
    return battle.isReachedGrade;
  });

  const maxReachedGradeItem = maxBy(reachedBattles, (battle) => {
    return battle.claimedGrade;
  });

  let maxReachedGrade = 0;
  if (maxReachedGradeItem) {
    maxReachedGrade = maxReachedGradeItem.claimedGrade;
  }

  let userNickname = '';

  if (!isEmpty(userBattles)) {
    userNickname =
      currentAccount.id === Number(selectedAttemptsUserId) ? currentAccount.nickname || '' : userBattles[0].nick;
  }

  const getTotalStarsCount = (reachedBattles: IBattle[]) => {
    const modifiersMap = getModifiersMap(stages.war.modifiers);
    let starsCount = 0;
    reachedBattles.forEach((battle) => {
      const hasModifier = modifiersMap[battle.class + '::' + battle.nation] !== undefined;
      starsCount += hasModifier ? 2 : 1;
    });
    return starsCount;
  };

  return (
    <>
      <DialogHeader>{t('История попыток')}</DialogHeader>
      <DialogBody>
        {userBattles && userBattles.length > 0 ? (
          <>
            <h2 className={styles.dialogHeader}>
              <Interpolate i18nKey={x('Личный результат игрока %(nick)s')} nick={userNickname} t={t} />
            </h2>
            <ResultsHeader
              medalData={null}
              withMedal={false}
              params={[
                {
                  value: getTotalStarsCount(reachedBattles),
                  label: t('Результат'),
                  type: 'cwars-star',
                  tooltipBody: t('Количество взятых планок'),
                },
                {
                  label: t('Попытки'),
                  rawValue: (
                    <AttemptsBadge
                      attemptsData={getPersonalAttemptsData(stages, Number(selectedAttemptsUserId))}
                      warSettings={warSettings}
                      showTitle={false}
                    />
                  ),
                },
                {
                  value: maxReachedGrade,
                  label: t('Лучшая планка'),
                  type: Currency.metricIconName[warSettings.stages.preparation.competitionMetric],
                  tooltipBody: t('Наивысшая взятая планка'),
                },
              ]}
            />

            <AttemptTable
              battles={userBattles}
              ships={ships}
              modifiers={stages.war.modifiers}
              competitionMetric={warSettings.stages.preparation.competitionMetric}
            />
          </>
        ) : (
          <span className={styles.onAttemptsMessage}>{t('Вы еще не тратили свои попытки')}</span>
        )}
      </DialogBody>
      <DialogFooter>
        <ButtonGroup>
          <Button isFlat onClick={hideDialog}>
            {t('Закрыть')}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    </>
  );
};

export default React.memo(CWarsAttemptsDialog);
