import { PureComponent } from 'react';
import { Shadows } from '@wg/wows-react-uikit';

import { searchToObject } from '~/utils';

import ClanBattlesRulesPage from '../ViewClanBattles/ClanBattlesRulesPage/ClanBattlesRulesPage';

const PAGE_TYPES = {
  CLAN_BATTLES: 'clan-battles',
};

class StaticPage extends PureComponent {
  renderPage = () => {
    const searchParams = searchToObject();

    switch (searchParams.page) {
      case PAGE_TYPES.CLAN_BATTLES: {
        return <ClanBattlesRulesPage />;
      }

      default: {
        return <div></div>;
      }
    }
  };

  render() {
    return <Shadows>{this.renderPage()}</Shadows>;
  }
}

export default StaticPage;
