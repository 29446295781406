import { t } from '~/helpers/localization';

export const header: Array<{
  modify: 'small' | 'basis' | 'center' | 'right' | 'check' | 'middle' | 'left' | 'buttons' | 'role';
  type: string;
  text: string;
  tooltipBody?: string;
}> = [
  {
    modify: 'small',
    type: 'place',
    text: t('Место'),
    tooltipBody: t('Место'),
  },
  {
    modify: 'basis',
    type: 'name',
    text: t('Клан'),
  },
  {
    modify: 'center',
    type: 'result',
    text: t('Результат'),
    tooltipBody: t('Количество взятых планок'),
  },
  {
    modify: 'right',
    type: 'lastWarBattleAt',
    text: t('Дата боя'),
    tooltipBody: t('Дата последнего боя с попыткой взятия планки'),
  },
  {
    modify: 'right',
    type: 'memberCount',
    text: t('Игроки'),
    tooltipBody: t('Количество игроков в клане'),
  },
];
