import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { shallowEqual } from 'react-redux';

import { isChatDenied } from '~/helpers/account';
import { t } from '~/helpers/localization';
import { useAppSelector } from '~/store';

import styles from './CStarsMembers.scss';
import ClanMember from './ClanMember/ClanMember';

import type { IClanStar } from '~/Reducers/ReducerClanStars';
import type { RootState } from '~/store';

const questsGenerator = (memberId: number, clanStars: IClanStar[]) => {
  const quests: string[] = [];
  for (const clanStar of clanStars) {
    if (memberId === clanStar.spaId) {
      quests.push(clanStar.questId);
    }
  }
  return quests;
};

const stateSelector = createSelector(
  [
    (state: RootState) => state.currentAccount,
    (state: RootState) => state.members.clans,
    (state: RootState) => state.ReducerClanStars,
  ],
  (currentAccount, members, ReducerClanStars) => {
    const clanMembers = currentAccount.clanId ? members[currentAccount.clanId]?.members || [] : [];

    return {
      accountClanstars: ReducerClanStars.accountClanstars,
      currentAccount: currentAccount,
      members: clanMembers,
      seasonQuests: ReducerClanStars.seasonQuests,
    };
  },
);

const CStarsMembers: React.FC = () => {
  const { accountClanstars, currentAccount, members, seasonQuests } = useAppSelector(stateSelector, shallowEqual);

  const spaIdToClanStars = React.useMemo(() => {
    return accountClanstars.reduce((acc, clanStar) => {
      acc[clanStar.spaId] = clanStar;
      return acc;
    }, {});
  }, [accountClanstars]);

  const membersList = React.useMemo(
    () =>
      members
        .filter((member) => {
          return member.id !== currentAccount.id;
        })
        .sort((memberA, memberB) => {
          return Number(!(memberA.id in spaIdToClanStars)) - Number(!(memberB.id in spaIdToClanStars));
        }),
    [currentAccount.id, members, spaIdToClanStars],
  );

  return (
    <div>
      <h2 className={styles.title}>{t('Собирайте друзей')}</h2>
      {members?.length > 1 ? (
        <>
          <div className={styles.text}>
            {t(
              'Начните чат с участником вашего клана, чтобы пригласить его в отряд. C каждым игроком вы можете получить три звезды.',
            )}
            {members?.length < 3 ? (
              <div>{t('Для получения большего количества звёзд вам нужно больше игроков в клане.')}</div>
            ) : null}
          </div>
          {members?.length > 1 ? (
            <div className={styles.clanMembersWrapper}>
              <div className={styles.clanMembers}>
                {membersList.map((member) => {
                  return (
                    <ClanMember
                      id={member.id}
                      isChatDenied={isChatDenied(currentAccount, member)}
                      username={member.name}
                      quests={questsGenerator(member.id, accountClanstars)}
                      seasonQuests={seasonQuests}
                      key={`cstars_member_${member.id}`}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <div className={styles.text}>{t('Для участия в активности вам нужно больше участников клана.')}</div>
      )}
    </div>
  );
};

export default React.memo(CStarsMembers);
