import axios from 'axios';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import settings from '~/settings';

import WssService, { WSS_ACTIONS } from '~/services/WssService';
import { searchToObject } from '~/utils/index';
import { sendCountingCWarsAttempts } from '~/web2ClientAPI/base';

import { actionsProcessing } from './ActionProcessing';

import type { InferActionsType } from '~/Reducers';
import type { IClanWarsState } from '~/Reducers/ReducerClanWars';
import type { ISocketError, ISocketResponse } from '~/services/WssService';
import type { AppThunk } from '~/store';
import type { Ship } from '~/types/declaration';

const GET_RATINGS = 'GET_RATINGS';
const RATINGS_AUTOCOMPLETE = 'RATINGS_AUTOCOMPLETE';
const RATINGS_SEARCH = 'RATINGS_SEARCH';
export const CHECK_FROM_PARAM = 'CHECK_FROM_PARAM';
export const CHECK_READY = 'CHECK_READY';
export const CLEAR_CLAN_WARS_DATA = 'CLEAR_CLAN_WARS_DATA';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const GET_SHIPS = 'GET_SHIPS';
export const HIDE_LAST_ROUND_RESULT = 'HIDE_LAST_ROUND_RESULT';
export const INIT = 'INIT';
export const SEARCH_INPUT_CHANGE = 'SEARCH_INPUT_CHANGE';
export const SELECT_RATING_LEAGUE = 'SELECT_RATING_LEAGUE';
export const SET_ERROR = 'SET_ERROR';
export const SET_GRADES_TABLE_TAB = 'SET_GRADES_TABLE_TAB';
export const SET_HISTORY_IS_FETCHING = 'SET_HISTORY_IS_FETCHING';
export const SET_OFFLINE = 'SET_OFFLINE';
export const SET_ONLINE = 'SET_ONLINE';
export const SET_PREPARE_STAGE_TAB = 'SET_PREPARE_STAGE_TAB';
export const SET_SELECTED_CLAN_ID = 'SET_SELECTED_CLAN_ID';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const SET_USE_ATTEMPTS = 'SET_USE_ATTEMPTS';
export const SHOW_LAST_ROUND_RESULT = 'SHOW_LAST_ROUND_RESULT';
export const SLIDE_HISTORY_MODAL = 'SLIDE_HISTORY_MODAL';
export const TOGGLE_AUTO_PARTICIPATION = 'TOGGLE_AUTO_PARTICIPATION';
export const TOGGLE_PLAYERS_COUNT_IN_RATING = 'TOGGLE_PLAYERS_COUNT_IN_RATING';
export const UNSET_HISTORY_MODAL_DATA = 'UNSET_HISTORY_MODAL_DATA';

const UPDATE_STAGES_AFTER_ADDITIONAL_ATTEMPTS_DELAY = 7500;
const UPDATE_STAGES_AFTER_WAR_STARTS_DELAY = 5000;
const UPDATE_LAST_ROUND_AFTER_PREPARATION_STARTS_DELAY = 5000;

export type ActionsType = InferActionsType<typeof actionsClanWars>;

type ActionsOnMessage =
  | {
      readonly type: WSS_ACTIONS.CWARS_UPDATE_SETTINGS;
      readonly payload: IClanWarsState['warSettings'] & {
        currentRound: number;
        roundType: IClanWarsState['roundType'];
        roundGroupSize: IClanWarsState['roundGroupSize'];
      };
    }
  | {
      readonly type: WSS_ACTIONS.CWARS_UPDATE_LAST_ROUND_RESULTS;
      readonly payload: IClanWarsState['lastRoundResults'];
    }
  | {
      readonly type: WSS_ACTIONS.CWARS_UPDATE_STAGES;
      readonly payload: {
        errors: unknown;
        stages: IClanWarsState['stages'];
        currentStage: IClanWarsState['stages']['currentStage'];
        restrictions: IClanWarsState['stages']['restrictions'];
      };
    }
  | {
      readonly type: WSS_ACTIONS.CWARS_UPDATE_RATINGS;
      readonly payload: IClanWarsState['clanRatingList'];
    }
  | {
      readonly type: WSS_ACTIONS.CWARS_UPDATE_ADDITIONAL_ATTEMPTS;
      readonly payload: IClanWarsState;
    }
  | {
      readonly type: WSS_ACTIONS.CWARS_UPDATE_RATINGS_SEARCH;
      readonly payload: IClanWarsState['clanRatingSearchList'];
    }
  | {
      readonly type: WSS_ACTIONS.CWARS_UPDATE_RATINGS_SEARCH_AUTOCOMPLETE;
      readonly payload: IClanWarsState['clanRatingAutocomplete'];
    }
  | {
      readonly type: WSS_ACTIONS.CWARS_UPDATE_COMMON_INFO;
      readonly payload: IClanWarsState['commonInfo'];
    }
  | {
      readonly type: WSS_ACTIONS.CWARS_UPDATE_ENEMY_GRADES;
      readonly payload: IClanWarsState['enemyGrades'];
    }
  | {
      readonly type: WSS_ACTIONS.CWARS_UPDATE_ALLIES_ATTEMPTS;
      readonly payload: IClanWarsState['stages']['war']['alliesAttempts'];
    }
  | {
      readonly type: WSS_ACTIONS.CWARS_STAGE_START;
      readonly payload: {
        currentStage: string;
        roundType: IClanWarsState['roundType'];
        groupSize: IClanWarsState['roundGroupSize'];
      };
    }
  | {
      readonly type: WSS_ACTIONS.CWARS_UPDATE_ROUNDS_RESULTS_HISTORY;
      readonly payload: {
        items: IClanWarsState['roundsHistory'];
        count: IClanWarsState['roundsHistoryCount'];
        offset: IClanWarsState['roundsHistoryOffset'];
        limit: IClanWarsState['roundsHistoryLimit'];
      };
    };

export const actionsClanWars = {
  setWssData: (type: unknown, payload: unknown) =>
    ({
      type: type,
      payload,
    }) as ActionsOnMessage,

  setOnline: () =>
    ({
      type: SET_ONLINE,
    }) as const,

  toggleAutoParticipation: () =>
    ({
      type: TOGGLE_AUTO_PARTICIPATION,
    }) as const,

  setUseAttempts: (val: boolean) =>
    ({
      type: SET_USE_ATTEMPTS,
      payload: { val },
    }) as const,

  clearClanWarsData: () =>
    ({
      type: CLEAR_CLAN_WARS_DATA,
    }) as const,

  setOffline: () =>
    ({
      type: SET_OFFLINE,
    }) as const,

  setSelectedTab: (selectedTab: number) =>
    ({
      type: SET_SELECTED_TAB,
      payload: { selectedTab },
    }) as const,

  togglePlayersCountInRating: () =>
    ({
      type: TOGGLE_PLAYERS_COUNT_IN_RATING,
    }) as const,

  setSelectedClanId: (clanId: Nullable<number> = null) =>
    ({
      type: SET_SELECTED_CLAN_ID,
      payload: { clanId: clanId },
    }) as const,

  slideHistoryModal: (direction: 'left' | 'right') =>
    ({
      type: SLIDE_HISTORY_MODAL,
      payload: { direction },
    }) as const,

  showLastRoundResults: () =>
    ({
      type: SHOW_LAST_ROUND_RESULT,
    }) as const,

  unsetHistoryModalData: () =>
    ({
      type: UNSET_HISTORY_MODAL_DATA,
    }) as const,

  setHistoryIsFetching: (isFetching: boolean) =>
    ({
      type: SET_HISTORY_IS_FETCHING,
      payload: { isFetching },
    }) as const,

  init: () =>
    ({
      type: INIT,
    }) as const,

  getShip: (ships: Record<string, Ship>) =>
    ({
      type: GET_SHIPS,
      payload: { ships },
    }) as const,

  setError: (error: ISocketError | Error) =>
    ({
      type: SET_ERROR,
      payload: { error },
    }) as const,

  checkFromParam: (isFromPort: boolean) =>
    ({
      type: CHECK_FROM_PARAM,
      payload: { isFromPort },
    }) as const,

  checkReady: (isReady: boolean) =>
    ({
      type: CHECK_READY,
      payload: { isReady },
    }) as const,

  clearSearch: () =>
    ({
      type: CLEAR_SEARCH,
    }) as const,

  getRating: () =>
    ({
      type: GET_RATINGS,
    }) as const,

  selectRatingLeague: (league: Nullable<number> = null) =>
    ({
      type: SELECT_RATING_LEAGUE,
      payload: { league },
    }) as const,

  searchInputChange: (value: string) =>
    ({
      type: SEARCH_INPUT_CHANGE,
      payload: { value },
    }) as const,

  ratingsSearch: (query: string, offset?: number, limit?: number) => {
    WssService.ratingsSearch(query, offset, limit);
    return {
      type: RATINGS_SEARCH,
    } as const;
  },

  ratingsAutocomplete: (query: string) => {
    debouncedAutocomplete(query);
    return {
      type: RATINGS_AUTOCOMPLETE,
    } as const;
  },

  hideLastRoundResults: () =>
    ({
      type: HIDE_LAST_ROUND_RESULT,
    }) as const,

  setPrepareStageTab: (prepageStageTabIndex: number) =>
    ({
      type: SET_PREPARE_STAGE_TAB,
      payload: { prepageStageTabIndex },
    }) as const,

  setGradesTableTab: (gradesTableTabIndex: number) => {
    if (gradesTableTabIndex > 0) {
      WssService.getEnemyGrades();
    }
    return {
      type: SET_GRADES_TABLE_TAB,
      payload: {
        gradesTableTabIndex,
      },
    } as const;
  },
};

export const loadHistoryModalData = (): AppThunk => (dispatch) => {
  WssService.getRoundsResultsHistory();
  dispatch(actionsClanWars.setHistoryIsFetching(true));
};

export const hideLastRoundResultsThunk = (): AppThunk => (dispatch, getState) => {
  const currentRound = get(getState(), ['ReducerClanWars', 'warSettings', 'currentRound'], 0);
  localStorage.setItem(`clanWarsRound_${currentRound}`, JSON.stringify({ lastRoundResultIsShows: true }));
  dispatch(actionsClanWars.hideLastRoundResults());
};

export const clearSearchThunk = (): AppThunk => (dispatch) => {
  dispatch(actionsClanWars.clearSearch());
  dispatch(getRatings());
};

export const getRatings =
  (clanId?: number, league?: number): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const currentLeague = league || state.ReducerClanWars.selectedClanRatingLeague || undefined;
    dispatch(actionsClanWars.getRating());
    dispatch(actionsClanWars.setSelectedClanId(clanId));
    WssService.getRatings(clanId, currentLeague);
  };

export const init =
  (isReload: boolean): AppThunk =>
  (dispatch, getState) => {
    if (!settings.cwars.isEnabled) {
      return;
    }

    const state = getState();

    dispatch(checkReady());
    dispatch(actionsClanWars.init());
    dispatch(getShips());
    dispatch(checkFromParam());

    const onMessage = (e: MessageEvent<string>) => {
      const data = JSON.parse(e.data) as ISocketResponse<Record<string, unknown>>;
      dispatch(actionsClanWars.setWssData(data.actionType, data.payload));
      console.log(data.actionType, data.payload);

      const onWarStarts = data.actionType === WSS_ACTIONS.CWARS_STAGE_START && data.payload.currentStage === 'war';
      const onPreparationStarts =
        data.actionType === WSS_ACTIONS.CWARS_STAGE_START && data.payload.currentStage === 'preparation';
      const onUpdateStagesInPreparation =
        data.actionType === WSS_ACTIONS.CWARS_UPDATE_STAGES &&
        (data.payload.currentStage === 'preparation' || data.payload.currentStage === 'lull');
      const onUpdateAdditionalAttempts = data.actionType === WSS_ACTIONS.CWARS_UPDATE_ADDITIONAL_ATTEMPTS;

      if (onUpdateStagesInPreparation && state.currentAccount.clanId) {
        WssService.getCommonInfo();
        WssService.getLastRoundResults(state.currentAccount.clanId);
      }

      if (onUpdateAdditionalAttempts) {
        // Random timeout to pass peak load
        setTimeout(() => WssService.getStagesInfo(), Math.random() * UPDATE_STAGES_AFTER_ADDITIONAL_ATTEMPTS_DELAY);
      }

      if (onWarStarts) {
        // Random timeout to pass peak load
        setTimeout(() => WssService.getStagesInfo(), Math.random() * UPDATE_STAGES_AFTER_WAR_STARTS_DELAY);
      }

      if (onPreparationStarts) {
        // Random timeout to pass peak load
        setTimeout(() => {
          if (state.currentAccount.clanId) {
            WssService.getLastRoundResults(state.currentAccount.clanId);
          }
        }, Math.random() * UPDATE_LAST_ROUND_AFTER_PREPARATION_STARTS_DELAY);
      }
    };

    const onError = (err: ISocketError) => {
      console.log(`*** ERROR ${err.request.actionType} ***`, err);
      dispatch(actionsProcessing.stopFetching());
      dispatch(actionsClanWars.setError(err));
    };

    const onOpen = () => {
      dispatch(actionsClanWars.clearClanWarsData());
      dispatch(actionsClanWars.setOnline());
      WssService.getInitData();
    };

    const onClose = () => {
      dispatch(actionsClanWars.setOffline());
    };

    const onFailConnection = () => {
      dispatch(actionsProcessing.stopFetching());
      dispatch(actionsClanWars.setError(new Error()));
    };

    WssService.init({
      onMessage: onMessage,
      onOpen: onOpen,
      onClose: onClose,
      onError: onError,
      onFailConnection: onFailConnection,
      url: state.urls.ws,
    });

    if (!state.ReducerClanWars.isReady) {
      dispatch(actionsProcessing.startFetching());
    }

    if (isReload) {
      WssService.getInitData();
    }
  };

const getShips = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  if (!state.urls?.vehiclesInfo) {
    console.error('no vehiclesInfo url');
    return;
  }

  type IApiResponse = Record<string, Ship>;

  void axios
    .get<IApiResponse>(state.urls.vehiclesInfo)
    .then((resp) => {
      dispatch(actionsClanWars.getShip(resp.data));
    })
    .catch((err) => {
      console.error(err);
    });
};

const checkFromParam = (): AppThunk => (dispatch) => {
  const searchParams = searchToObject();
  const isFromPort = searchParams && searchParams.source === 'port';
  dispatch(actionsClanWars.checkFromParam(isFromPort));

  if (isFromPort) {
    setTimeout(() => {
      dispatch(actionsClanWars.checkFromParam(false));
    }, 3000);
  }
};

const checkReady = (): AppThunk => (dispatch, getState) => {
  const state = getState();

  const canSetReady =
    !state.ReducerClanWars.isReady &&
    state.ReducerClanWars.commonInfoReady &&
    state.ReducerClanWars.stageReady &&
    state.ReducerClanWars.warSettingsReady &&
    state.ReducerClanWars.shipsLoaded;

  if (canSetReady) {
    dispatch(actionsClanWars.checkReady(true));
    dispatch(actionsProcessing.stopFetching());
  } else {
    setTimeout(() => {
      dispatch(checkReady());
    }, 300);
  }
};

export const setUseAttemptsThunk =
  (isAttemptsCount: boolean): AppThunk =>
  (_, getState) => {
    const state = getState();
    if (state.currentAccount.id) {
      sendCountingCWarsAttempts(state.currentAccount.id, isAttemptsCount);
      WssService.toggleAttemtsCount(isAttemptsCount);
    }
  };

export const toggleAutoParticipationThunk = (): AppThunk => (_, getState) => {
  const state = getState();
  const joinRoundAutomatically = state.ReducerClanWars.commonInfo.clan.settings.joinRoundAutomatically;
  WssService.changeJoinRoundAutomatically(!joinRoundAutomatically);
};

export const refreshHistoryModal = (): AppThunk => (dispatch) => {
  dispatch(actionsClanWars.setHistoryIsFetching(true));
  WssService.getRoundsResultsHistory();
};

export const setIsParticipant =
  (value: boolean): AppThunk =>
  () => {
    WssService.changeIsParticipating(value);
    WssService.getStagesInfo();
  };

const debouncedAutocomplete = debounce((q: string) => {
  WssService.ratingsAutocomplete(q);
}, 500);

export const showOwnClanPosition = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const clanId = state.currentAccount?.clanId || null;
  if (clanId) {
    dispatch(getRatings(clanId));
  }
};

export const showSeasonLeaders = () => {
  WssService.getRatingLeaders();
};

export const loadHistory =
  (limit = 10, offset = 0): AppThunk =>
  (dispatch) => {
    WssService.getRoundsResultsHistory(limit, offset);
    dispatch(actionsClanWars.setHistoryIsFetching(true));
  };
