import type { ClanTask } from '~/Actions/ActionClanTasks';

const WEEK = 7 * 24 * 60 * 60 * 1000;

export const getClaimedTasks = (tasks: ClanTask[]) => {
  return tasks.filter((task: ClanTask) => task.isClaimed);
};

export const areAllTasksClaimed = (tasks: ClanTask[]) => {
  return tasks.every((task: ClanTask) => task.isClaimed);
};

export const lastTaskClaimedAt = (tasks: ClanTask[]) => {
  if (!tasks || !tasks.length) {
    return;
  }

  const sorted = tasks.sort((a, b) => {
    return new Date(a.claimedAt || 0).getTime() - new Date(b.claimedAt || 0).getTime();
  });

  return new Date(sorted[sorted.length - 1]?.claimedAt || 0).getTime();
};

export const shouldHideTasksNav = (tasks: ClanTask[], length: number | null = null) => {
  if (!tasks || !tasks.length) {
    return true;
  }

  const necessaryCondition = areAllTasksClaimed(tasks);
  const claimedTasksCount = !length || getClaimedTasks(tasks).length === length;
  const sufficientCondition = claimedTasksCount && new Date().getTime() - (lastTaskClaimedAt(tasks) || 0) > WEEK;

  return necessaryCondition && sufficientCondition;
};
