import get from 'lodash/get';
import React from 'react';
import { Interpolate, translate } from 'react-i18next';
import {
  ButtonDublon,
  Divider,
  DivTooltip,
  Gold,
  Icon,
  Spinner,
  StepCSSTransitionGroup,
  Toggle,
  TooltipBody,
} from '@wg/wows-react-uikit';

import settings from '~/settings';
import { sendEventToGTM } from '~/helpers/analytics';
import { clanCost } from '~/helpers/clan';
import { isoToFormattedLocalDate, isoToFormattedLocalTime } from '~/helpers/datetime';
import { x } from '~/helpers/localization';
import { isPreModerateNameTag } from '~/helpers/moderation';

import { Center, ClanInputWrapper, ErrorDublon } from '~/UIKit';

import styles from './ClanCreate.scss';
import { ATTRIBUTES_VALIDATION_STATE } from './Constants';

type PropsType = {
  tag: {
    value: string;
    isValidating: boolean;
    available: string;
    error?: string;
  };
  name: {
    value: string;
    isValidating: boolean;
    available: string;
    error?: string;
  };
  currentAccount: {
    totalGold: number;
    inClanCooldownTill?: string;
  };
  isProcessing: boolean;
  url: string;
  clanRulesContent?: any;
  error?: any;
  onHideDialog: () => void;
  onShowRulesDialog: () => void;
  onUpdateFieldValue: () => void;
  onValidateOnServer: () => void;
  onCreateClanClick: () => void;
  t: (text: string, settings?: any) => string;
};

const ClanCreate: React.FC<PropsType> = (props) => {
  const {
    onShowRulesDialog,
    t,
    currentAccount,
    error,
    isProcessing,
    tag,
    name,
    onUpdateFieldValue,
    onValidateOnServer,
    onCreateClanClick,
  } = props;

  const onClickClansRules = () => {
    sendEventToGTM({
      category: 'Create page',
      action: 'Click: Show rules dialog',
      label: 'Clan rules action link',
    });

    onShowRulesDialog();
  };

  const onCreateClan = () => {
    sendEventToGTM({
      category: 'Create page',
      action: 'Click: Create clan button',
      label: 'Create clan button in create page',
    });

    onCreateClanClick();
  };

  const renderFooter = () => {
    const account = currentAccount;
    const errorObj = error || {};

    const cost = clanCost();
    const isDiscount = settings.clanCreate.cost > settings.clanCreate.cost - settings.clanCreate.discount;

    let footer = null;
    if (isProcessing) {
      footer = <Spinner />;
    } else if (account.inClanCooldownTill) {
      const tooltipContent = t('Вы недавно покинули клан. Создать свой клан можно не ранее %(date)s, %(time)s', {
        date: isoToFormattedLocalDate(account.inClanCooldownTill),
        time: isoToFormattedLocalTime(account.inClanCooldownTill),
      });
      footer = (
        <DivTooltip tooltipBody={<TooltipBody>{tooltipContent}</TooltipBody>}>
          <ButtonDublon isFlat caption={t('Создать клан:кнопка создания клана')} amount={cost} isDisabled isDiscount />
        </DivTooltip>
      );
    } else {
      let needGold = 0;
      if (errorObj.additional_info?.reason === 'not_enough_gold') {
        needGold = errorObj.additional_info.amount;
      }

      if (needGold === 0) {
        let tooltipContent = null;
        let isDisabled =
          tag.available !== ATTRIBUTES_VALIDATION_STATE.AVAILABLE ||
          name.available !== ATTRIBUTES_VALIDATION_STATE.AVAILABLE;

        const anyValidationError = tag.error || name.error;
        if (anyValidationError || !tag.value || !name.value) {
          isDisabled = true;
          tooltipContent = (
            <TooltipBody>{t('Введите уникальные тег и название, удовлетворяющие Правилам кланов.')}</TooltipBody>
          );
        } else if (
          tag.available === ATTRIBUTES_VALIDATION_STATE.NOT_AVAILABLE ||
          name.available === ATTRIBUTES_VALIDATION_STATE.NOT_AVAILABLE
        ) {
          tooltipContent = <TooltipBody>{t('Клан с таким тегом или названием уже зарегистрирован.')}</TooltipBody>;
        } else if (isDiscount) {
          tooltipContent = (
            <TooltipBody>
              <div className={styles.discountTooltip}>
                <div className={styles.discountTooltipHead}>
                  <Icon glyph="discount" />
                  {t('Акция:tooltip')}
                </div>
                <div>
                  <span className={styles.discountTooltipPriceText}>{t('Стоимость покупки:tooltip')}</span>
                  <Gold amount={cost} />
                </div>
                <div className={styles.discountTooltipPriceCostWrap}>
                  <span className={styles.discountTooltipPriceCost}>{settings.clanCreate.cost}</span>
                </div>
              </div>
            </TooltipBody>
          );
        }

        footer = (
          <div data-staff-name="create_create-clan">
            <DivTooltip tooltipBody={tooltipContent}>
              <ButtonDublon
                isFlat
                caption={t('Создать клан:кнопка создания клана')}
                amount={cost}
                isDisabled={isDisabled}
                isDiscount={isDiscount}
                onClick={onCreateClan}
              />
            </DivTooltip>
          </div>
        );
      } else {
        footer = <ErrorDublon caption={t('Стоимость создания')} amount={cost} deficit={needGold} />;
      }
    }
    return footer;
  };

  const inputsErrors = {
    tag: (error?.errors?.tag || [])[0] || null,
    name: (error?.errors?.name || [])[0] || null,
  };

  const clanRulesComponent = (
    <span data-staff-name="create_clan-rules">
      <Toggle caption={t('Правилами кланов')} onClick={onClickClansRules} />
    </span>
  );

  const footer = renderFooter();

  return (
    <div className={styles.clanCreate}>
      <StepCSSTransitionGroup level={1}>
        <div key="input-tag" className={styles.inputTag}>
          <ClanInputWrapper
            isFocusOn
            field="tag"
            value={tag.value}
            isDisabled={isProcessing}
            isValidating={tag.isValidating}
            error={inputsErrors.tag || tag.error}
            onChange={onUpdateFieldValue}
            onValidateOnServer={onValidateOnServer}
          />
        </div>
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup level={2}>
        <div key="input-name" className={styles.inputName}>
          <ClanInputWrapper
            field="name"
            value={name.value}
            isDisabled={isProcessing}
            isValidating={name.isValidating}
            error={inputsErrors.name || name.error}
            onChange={onUpdateFieldValue}
            onValidateOnServer={onValidateOnServer}
          />
        </div>
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup level={3}>
        <Divider key="divider-top" type="major" />
        <div key="message" className={styles.message}>
          {t('Вы сможете изменять название после создания клана, нажав на тег и название на экране «Мой клан».')}
          &ensp;
          {t('Изменение тега и названия осуществляется за дублоны.')}
          {isPreModerateNameTag && (
            <>
              &ensp;
              {t('Название и тег вашего клана обновятся, как только они будут одобрены модератором.')}
            </>
          )}
        </div>
        <Divider key="divider-bottom" type="major" />
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup level={4}>
        <div key="rules" className={styles.rules}>
          <Interpolate
            i18nKey={x('Создавая клан, вы соглашаетесь с %(clanRules)s')}
            clanRules={clanRulesComponent}
            t={t}
          />
        </div>
        <Center key="footer">{footer}</Center>
      </StepCSSTransitionGroup>
    </div>
  );
};

export default translate()(ClanCreate);
