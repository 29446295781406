import classnames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import { Interpolate } from 'react-i18next';
import { Button, CountDown, Currency, DivTooltip, Message, TooltipBody } from '@wg/wows-react-uikit';

import { t, x } from '~/helpers/localization';

import StepCSSTransitionGroup from '~/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import styles from './RegularReward.scss';

type PropsType = {
  isAvailable: boolean;
  haveEnoughMoney: boolean;
  isPending: boolean;
  lastReceive: string | number;
  needOil: number;
  members: Array<any>;
  title: string;
  description: string;
  price: number;
  onBuy: () => void;
  navigateToTreasuryBuilding: () => void;
  index: number;
  offerId: number;
  canDistribute: boolean;
  canBuyNextLevel: boolean;
  requirements: any;
};

export default class RegularReward extends Component<PropsType> {
  timer: ReturnType<typeof setTimeout> | null;

  constructor(props) {
    super(props);
    this.state = {
      cooldownDays: -1,
    };
    this.timer = null;
  }

  static secToDays = (sec: number): number => {
    return sec / 60 / 60 / 24;
  };

  static getNewPlayerNames = (members) => {
    return members.filter((member) => member.daysInClan < 14).map((member) => member.name);
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isAvailable !== nextProps.isAvailable ||
      this.props.haveEnoughMoney !== nextProps.haveEnoughMoney ||
      this.props.isPending !== nextProps.isPending ||
      this.props.lastReceive !== nextProps.lastReceive ||
      this.props.needOil !== nextProps.needOil ||
      this.props.members.length !== nextProps.members.length ||
      this.state.cooldownDays !== nextState.cooldownDays
    );
  }

  getCooldown = (interval) => {
    const lastReceive = moment.utc(this.props.lastReceive);
    const now = moment.utc();
    const msFromReceive = now.diff(lastReceive);
    const daysFromReceive = now.diff(lastReceive, 'days');
    const cooldown_ms = interval * 24 * 60 * 60 * 1000 - msFromReceive;
    const cooldown_days = interval - daysFromReceive;

    return [cooldown_days, cooldown_ms];
  };

  renderBuyButtonTooltip = (cooldownMs, cooldownDays, haveEnoughMoney, needOil) => {
    const hasCooldown = cooldownMs > 0;

    if (!haveEnoughMoney) {
      return (
        <TooltipBody>
          <Message type="danger" style="inline">
            <div className={styles.noMoneyError}>
              <Interpolate
                i18nKey={x('Не хватает: %(oil)s')}
                t={t}
                oil={<Currency isFlat isBold type="oil" amount={needOil} />}
              />
            </div>
          </Message>
        </TooltipBody>
      );
    } else if (hasCooldown) {
      return (
        <TooltipBody>
          <Message type="danger" style="inline">
            {cooldownMs <= 1 ? (
              <CountDown date={new Date().getTime() + cooldownMs} />
            ) : (
              <div className={styles.noMoneyError}>
                <Interpolate i18nKey={x('Доступен через %(days)s д.')} days={cooldownDays} t={t} />
              </div>
            )}
          </Message>
        </TooltipBody>
      );
    }

    return null;
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const {
      title,
      description,
      price,
      isAvailable,
      haveEnoughMoney,
      onBuy,
      isPending,
      index,
      needOil,
      offerId,
      canDistribute,
    } = this.props;

    const interval = RegularReward.secToDays(this.props.requirements.delay);
    let cooldown = 0,
      cooldownMs = 0;
    const fiveMinutesForExample = 300000;

    if (this.props.lastReceive) {
      [cooldown, cooldownMs] = this.getCooldown(interval);

      if (cooldownMs > 0 && cooldownMs < fiveMinutesForExample) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.setState({
            cooldownDays: cooldown,
          });
        }, cooldownMs);
      }
    }

    const hasCooldown = cooldownMs > 0;

    return (
      <StepCSSTransitionGroup className={styles.wrapper} level={index}>
        <div className={styles.innerWrapper}>
          <div className={`${styles.header} ${isAvailable && !hasCooldown ? '' : ''}`}>
            <div
              className={classnames(styles[`headerBackground-${offerId}`], {
                [styles.headerBackgroundDisabled]: !(isAvailable && !hasCooldown),
              })}
            />
            <div className={styles.title}>{title}</div>

            {interval ? (
              <div className={styles.interval}>
                <Interpolate i18nKey={x('Доступно 1 раз в %(interval)s дней')} interval={interval} t={t} />
              </div>
            ) : (
              <div className={styles.interval}>{t('Доступно любое количество')}</div>
            )}

            {hasCooldown ? (
              <div className={styles.cooldown}>
                {cooldown <= 1 ? (
                  <CountDown date={new Date().getTime() + cooldownMs} />
                ) : (
                  <Interpolate i18nKey={x('Через %(cooldown)s д.')} cooldown={cooldown} t={t} />
                )}
              </div>
            ) : null}
          </div>
          <div className={styles.descr}>{description}</div>
          <div className={styles.price}>
            {t('Стоимость')}
            <Currency isFlat amount={price} color="#fff" isBold type="oil" />
          </div>
        </div>
        {isAvailable && canDistribute ? (
          <div className={styles.buttonRow}>
            <div className={styles.notForAllWrapper}>
              <DivTooltip tooltipBody={this.renderBuyButtonTooltip(cooldownMs, cooldown, haveEnoughMoney, needOil)}>
                <Button isFlat isDisabled={!haveEnoughMoney || isPending || hasCooldown} onClick={onBuy}>
                  {t('Приобрести')}
                </Button>
              </DivTooltip>
            </div>
          </div>
        ) : canDistribute ? (
          <div className={styles.warningRow}>
            <Message style="inline" type="error">
              {t('Необходимо улучшить Казначейство')}
            </Message>
            <div className={styles.buttonPlaceholder}>
              {this.props.canBuyNextLevel ? (
                <DivTooltip
                  tooltipBody={
                    <TooltipBody>{t('Достаточно средств для постройки улучшения. Перейти к постройке.')}</TooltipBody>
                  }
                >
                  <div className={styles.upgradeTreasuryButton} onClick={this.props.navigateToTreasuryBuilding} />
                </DivTooltip>
              ) : null}
            </div>
          </div>
        ) : (
          <div className={classnames(styles.warningRow, styles.infoRow)}>
            <Message style="inline" type="none">
              {t('Купить контейнеры могут только командующий и его заместители')}
            </Message>
          </div>
        )}
      </StepCSSTransitionGroup>
    );
  }
}
