import { SET_OWNCLAN, SET_WINNERS, TOGGLE_WINNERS_ERROR, TOGGLE_WINNERS_FETCH } from '~/Actions/ActionHallFame';

import type { ActionsType } from '~/Actions/ActionHallFame';

export interface Winner {
  clan_id: number;
  division_rating: number;
  league: number;
  name: string;
  public_rating: number;
  realm: string;
  season_id: number;
  tag: string;
}

export interface OwnClan extends Winner {
  rank: number;
  division: number;
}

interface IHallOfFameState {
  selectedTab: number;
  winnersList: Array<Winner>;
  ratings: Array<OwnClan>;
  isError: boolean;
  isFetching: boolean;
  errorMessage: string;
}

const initialState: IHallOfFameState = {
  selectedTab: 0,
  winnersList: [],
  isError: false,
  isFetching: false,
  errorMessage: '',
  ratings: [],
};

export const ReducerHallFame = (state: IHallOfFameState = initialState, action: ActionsType): IHallOfFameState => {
  switch (action.type) {
    case SET_WINNERS: {
      return {
        ...state,
        winnersList: action.winners,
      };
    }

    case SET_OWNCLAN: {
      return {
        ...state,
        ratings: action.ratings,
      };
    }

    case TOGGLE_WINNERS_ERROR: {
      return {
        ...state,
        isError: action.isError,
        errorMessage: action.errorMessage,
      };
    }

    case TOGGLE_WINNERS_FETCH: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }

    default:
      return state;
  }
};
