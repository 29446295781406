import { connect } from 'react-redux';

import { showRulesDialog } from '~/helpers/dialogs';
import { hideDialog } from '~/store/slices/dialogsSlice';
import { actionsClanCreate, checkNameOrTagAvailability, creationClan } from '~/Actions/ActionClanCreate';

import { ClanCreate } from '~/UIKit';

import type { IAppDispatch, RootState } from '~/store';

const mapStateToProps = (state: RootState) => {
  return {
    tag: state.clanCreate.tag,
    name: state.clanCreate.name,
    currentAccount: state.currentAccount,
    error: state.clanCreate.error,
    isProcessing: state.clanCreate.isProcessing,
    url: state.urls.checkClanNameTagAvability,
  };
};

const mapDispatchToProps = (dispatch: IAppDispatch) => {
  return {
    onValidateOnServer: (field: string, value: string) => {
      dispatch(actionsClanCreate.startCheckAvailabilityCreationClan(field));
      return dispatch(checkNameOrTagAvailability(field, value, actionsClanCreate.finishCheckAvailabilityCreationClan));
    },
    onCreateClanClick: () => dispatch(creationClan()),
    onUpdateFieldValue: (field, value, error) =>
      dispatch(actionsClanCreate.updateFieldValueCreationClan(field, value, error)),
    onShowRulesDialog: () => dispatch(showRulesDialog()),
    onHideDialog: () => dispatch(hideDialog()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClanCreate);
