import moment from 'moment';

import React, { PureComponent } from 'react';
import { Interpolate } from 'react-i18next';

import { isoToFormattedLocalDate, isoToFormattedLocalDateTime, isoToFormattedLocalTime } from '~/helpers/datetime';
import { t, x } from '~/helpers/localization';

import styles from './Calendar.scss';
import { Progress } from './Parts';

import type { ReactNode } from 'react';
import type { WarSettingsStages } from '~/types/declaration';

export type IProps = {
  currentWeek: number;
  weeksCount: number;
  stages: WarSettingsStages;
  currentStage: string;
  currentRound: number;
};

export type IState = {
  progressWidth: number;
};

export type IDays = Array<string>;

export type IMonth = Array<string>;

class Calendar extends PureComponent<IProps, IState> {
  static getDayOffset = (weekIndex: number, dayIndex: number, currentWeek: number) => {
    // let currentDayOffset = moment.utc().isoWeekday();
    const currentDayOffset = moment().isoWeekday();

    if (weekIndex === currentWeek) {
      return -currentDayOffset + dayIndex;
    } else {
      if (weekIndex < currentWeek) {
        return -currentDayOffset + dayIndex - 7 * (currentWeek - weekIndex);
      } else {
        return -currentDayOffset + dayIndex + 7 * (weekIndex - currentWeek);
      }
    }
  };

  static getDateDayNumber = (offset: number) => {
    // const m = moment.utc();
    const m = moment();
    if (offset === 0) {
      return m;
    } else {
      if (offset < 0) {
        return m.subtract(-offset, 'days');
      } else {
        return m.add(offset, 'days');
      }
    }
  };
  state = {
    progressWidth: 0,
  };

  stagesOrder: Array<string> = ['preparation', 'war', 'matchmaking', 'rewarding'];
  days: IDays = [
    t('понедельник'),
    t('вторник'),
    t('среда'),
    t('четверг'),
    t('пятница'),
    t('суббота'),
    t('воскресенье'),
  ];
  month: IMonth = [
    t('Январь'),
    t('Февраль'),
    t('Март'),
    t('Апрель'),
    t('Май'),
    t('Июнь'),
    t('Июль'),
    t('Август'),
    t('Сентябрь'),
    t('Октябрь'),
    t('Ноябрь'),
    t('Декабрь'),
  ];

  renderDaysTitles = (): Array<ReactNode> => {
    return this.days.map((item: string, index: number): any => {
      const needRightBorder = index < 6;
      const isWeekend = index > 4;

      return (
        <div className={styles.cell} key={`dayTitle${index}`}>
          <div
            className={`${styles.dayTitle} ${needRightBorder ? styles.rightBorder : ''} ${
              isWeekend ? styles.dayTitleWeekend : ''
            }`}
          >{`${item}`}</div>
        </div>
      );
    });
  };
  renderDays = (weekIndex: number) => {
    const days = [];
    for (let index = 0; index < 7; index++) {
      const dayIndex = index + 1;
      const needRightBorder = dayIndex < 7;
      const dayOffset = Calendar.getDayOffset(weekIndex, dayIndex, this.props.currentWeek);
      const date = Calendar.getDateDayNumber(dayOffset);
      const dateNum = date.format('D');
      const isActiveDay = weekIndex >= this.props.currentWeek;

      days.push(
        <div className={styles.cell} key={`day${index}`}>
          <div
            className={`${styles.day} ${needRightBorder ? styles.rightBorder : ''} ${
              isActiveDay ? styles.activeDay : ''
            }`}
          >
            {this.renderStages(date)}
            {`${dateNum}`}
          </div>
        </div>,
      );
    }

    return days;
  };

  getStageDuration = (stage) => {
    const startDate = this.props.stages[stage].startDate;
    const endDate = this.props.stages[stage].endDate;

    const inOneDay = isoToFormattedLocalDate(startDate) === isoToFormattedLocalDate(endDate);
    const startDateComponent = isoToFormattedLocalDateTime(startDate);
    let endDateComponent = isoToFormattedLocalDateTime(endDate);

    if (inOneDay) {
      endDateComponent = isoToFormattedLocalTime(endDate);
    }

    return {
      startDateComponent,
      endDateComponent,
    };
  };

  renderStageDuration = (stage: string) => {
    const interval = this.getStageDuration(stage);

    return (
      <span>
        {interval.startDateComponent} — {interval.endDateComponent}
      </span>
    );
  };

  getOverlayOffset = (date: any, isRight: boolean) => {
    const dayStart = moment.utc(date).local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const msDiff = date.diff(dayStart);
    const msDay = 24 * 60 * 60 * 1000;
    const percentDiff = (msDiff / msDay) * 100;
    if (isRight) {
      return 100 - percentDiff;
    } else {
      return percentDiff;
    }
  };

  getInnerOverlay = (key: string, inCalendar?: boolean) => {
    switch (key) {
      case 'preparation': {
        return <div className={styles.preparationInnerOverlay} />;
      }
      case 'matchmaking': {
        if (this.props.currentStage === 'war' && inCalendar === true) {
          return <div className={styles.matchmakingInnerOverlayInWar} />;
        }
        return <div className={styles.matchmakingInnerOverlay} />;
      }
      case 'war': {
        return <div className={styles.warInnerOverlay} />;
      }
      case 'rewarding': {
        if (inCalendar === true) {
          return <div className={styles.rewardingInnerOverlayInWar} />;
        }
        return <div className={styles.rewardingInnerOverlay} />;
      }
      // case 'lull': {
      //     return (
      //         <div className={styles.lullInnerOverlay} />
      //     )
      // }

      default: {
        return null;
      }
    }
  };

  renderStages = (date: any) => {
    const stages = [];

    this.stagesOrder.forEach((stageName) => {
      if (Object.prototype.hasOwnProperty.call(this.props.stages, stageName)) {
        const stage = this.props.stages[stageName];

        if (stage.startDate && stage.endDate) {
          const stageStartDate = moment.utc(stage.startDate).local();
          const stageEndDate = moment.utc(stage.endDate).local();
          const stageStartDateDayIndex = parseInt(stageStartDate.format('DDDD'), 10);
          const stageEndDateDayIndex = parseInt(stageEndDate.format('DDDD'), 10);
          const dateDayIndex = parseInt(date.format('DDDD'), 10);

          if (dateDayIndex === stageStartDateDayIndex && dateDayIndex === stageEndDateDayIndex) {
            stages.push(
              <div
                key={`PrepOverlay_${dateDayIndex}_${stageName}`}
                className={styles.stageOverlay}
                style={{
                  left: `${this.getOverlayOffset(stageStartDate, false)}%`,
                  right: `${this.getOverlayOffset(stageEndDate, true)}%`,
                  zIndex: -2,
                }}
              >
                {this.getInnerOverlay(stageName, true)}
              </div>,
            );
          } else if (dateDayIndex === stageStartDateDayIndex) {
            stages.push(
              <div
                key={`PrepOverlay_${dateDayIndex}_${stageName}`}
                className={styles.stageOverlay}
                style={{
                  left: `${this.getOverlayOffset(stageStartDate, false)}%`,
                }}
              >
                {this.getInnerOverlay(stageName, true)}
              </div>,
            );
          } else if (dateDayIndex === stageEndDateDayIndex) {
            stages.push(
              <div
                key={`PrepOverlay_${dateDayIndex}_${stageName}`}
                className={styles.stageOverlay}
                style={{
                  right: `${this.getOverlayOffset(stageEndDate, true)}%`,
                }}
              >
                {this.getInnerOverlay(stageName, true)}
              </div>,
            );
          } else if (dateDayIndex > stageStartDateDayIndex && dateDayIndex < stageEndDateDayIndex) {
            stages.push(
              <div key={`PrepOverlay_${dateDayIndex}_${stageName}`} className={styles.stageOverlay}>
                {this.getInnerOverlay(stageName, true)}
              </div>,
            );
          }
        }
      }
    });

    return stages;
  };

  renderWeeks = () => {
    const weeks = [];

    for (let index = 0; index < this.props.weeksCount; index++) {
      const weekIndex = index + 1;
      const isCurrentWeek = this.props.currentWeek === weekIndex;

      weeks.push(
        <div key={`Week_${weekIndex}`} className={`${styles.week} ${isCurrentWeek ? styles.currentWeek : ''}`}>
          {this.renderDays(weekIndex)}
          {isCurrentWeek ? <Progress /> : null}
        </div>,
      );
    }

    return weeks;
  };

  getStageName = (key: string) => {
    switch (key) {
      case 'preparation': {
        return t('Подготовка');
      }
      case 'matchmaking': {
        return t('Подбор соперников');
      }
      case 'war': {
        return t('Битва');
      }
      case 'rewarding': {
        return t('Подведение итогов');
      }

      default: {
        return '';
      }
    }
  };

  renderLegend() {
    const stages = [];

    this.stagesOrder.forEach((stageName) => {
      if (Object.prototype.hasOwnProperty.call(this.props.stages, stageName)) {
        if (
          stageName === 'preparation' ||
          stageName === 'matchmaking' ||
          stageName === 'war' ||
          stageName === 'rewarding'
        ) {
          stages.push(
            <div className={styles.legendStageWrapper} key={`LegendStage_${stageName}`}>
              <div className={styles.legendStage}>
                <div
                  className={`${styles.stageBox} ${
                    stageName === 'matchmaking' || stageName === 'rewarding' ? styles.topBottomBorder : ''
                  }`}
                >
                  {this.getInnerOverlay(stageName)}
                </div>
                <div className={styles.stageDescription}>
                  <span className={styles.stageName}>{this.getStageName(stageName)}</span>
                  <span className={styles.stageDuration}>{this.renderStageDuration(stageName)}</span>
                </div>
              </div>
            </div>,
          );
        }
      }
    });

    return stages;
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <Interpolate
          useDangerouslySetInnerHTML={true}
          i18nKey={x('Текущий раунд %(currentRound)s')}
          currentRound={this.props.currentRound}
          parent={'span'}
          className={styles.seasonTitle}
          t={t}
        />
        <div className={styles.innerWrapper}>
          <div className={styles.dayTitleRow}>{this.renderDaysTitles()}</div>
          {this.renderWeeks()}
        </div>

        <div className={styles.legend}>
          {this.renderLegend()}
          <span className={styles.legendDescription}>
            {t('Все временные интервалы в календаре указаны исходя из вашего часового пояса')}
          </span>
        </div>
      </div>
    );
  }
}

export default Calendar;
