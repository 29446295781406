import React from 'react';
import { translate } from 'react-i18next';
import { EntityTypes } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { Spinner } from '@wg/wows-react-uikit';

import { thousands } from '~/helpers/formatting';
import { t } from '~/helpers/localization';

import { currencyNames, currencyNamesToWE } from '~/UIKit/Currency/Currency';

import styles from './ClanBalance.scss';

import type { Balance } from '~/types/declaration';

type IClanBalanceProps = {
  totalBalanceList: Balance;
};

const ClanBalance: React.FC<IClanBalanceProps> = ({ totalBalanceList }) => {
  return (
    <div className={styles.wrapper}>
      {totalBalanceList.length > 0 ? (
        totalBalanceList.map((item) => {
          if (currencyNames[item.type]) {
            return (
              <WoWSEntity
                type={EntityTypes.CURRENCY}
                id={currencyNamesToWE[item.type] as string}
                amount={item.amount}
                presentation={{
                  isOwned: true,
                  bindTooltip: true,
                  withTooltip: true,
                  hideReceiveText: true,
                  hideSpendText: true,
                  renderWithoutFade: true,
                  classNames: {
                    tooltipContainer: styles.tooltip,
                  },
                }}
                key={`Balance_${item.type}`}
              >
                <div className={styles.currency}>
                  <div className={styles.amount}>
                    <div className={styles.value}>{thousands(item.amount)}</div>
                    <div className={`${styles.icon} ${styles[item.type]}`} />
                  </div>
                  <div className={styles.label}>{currencyNames[item.type]}</div>
                </div>
              </WoWSEntity>
            );
          } else {
            return null;
          }
        })
      ) : (
        <div className={styles.spinnerWrapper}>
          <Spinner label={t('Идет загрузка баланса')} />
        </div>
      )}
    </div>
  );
};

// @TODO: update i18n
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export default React.memo(translate()(ClanBalance) as React.FC<IClanBalanceProps>);
