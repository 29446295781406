import React from 'react';
import { translate, Interpolate } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { Button, ButtonGroup, Currency, DialogBody, DialogFooter, DialogHeader, Link } from '@wg/wows-react-uikit';

import { t, x } from '~/helpers/localization';
import useMount from '~/hooks/useMount';
import { useAppDispatch, useAppSelector } from '~/store';
import {
  actionsTreasury,
  repeatFailedDistribution,
  repeatTreasuryBoxTransaction,
  TRANSACTIONS_STATUSES,
} from '~/Actions/ActionTreasury';

import { membersSelector } from '~/Containers/selectors/SelectorClanProfile';

import styles from './TransactionDetailDialog.scss';

import type { CurrencyType } from '@wg/wows-react-uikit/components/Currency/Currency';
import type { AppStateType as RootState } from '~/Reducers';
import type { IClanMember, IDialog, Receiver, Transaction } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  return {
    members: state.currentAccount.clanId ? membersSelector(state, state.currentAccount.clanId) : [],
    regularRewards: state.ReducerTreasury.regularRewards,
  };
};

type ITransactionDetailDialog = IDialog<{
  transaction: Transaction;
}>;

const TransactionDetailDialog: React.FC<ITransactionDetailDialog> = ({ data, hideDialog }) => {
  const { transaction } = data;

  const { members, regularRewards } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const offerId = transaction?.data?.offer;
  const reward = offerId ? regularRewards.availableRewardOffers.filter((r) => r.offer_id === offerId)[0] : undefined;
  const rewardTitle = reward?.title || '';

  let localTransaction = { ...transaction };
  let isChildTransaction = false;
  if (transaction.childTransactions && transaction.childTransactions.length > 0) {
    localTransaction = transaction.childTransactions[0];
    isChildTransaction = true;
  }
  const isRewardsDistribution = localTransaction.operation === 'rewards_distribution';
  const isFailed = localTransaction.status === TRANSACTIONS_STATUSES.FAILED;
  const isSuccess = localTransaction.status === TRANSACTIONS_STATUSES.COMPLETED;
  const canRepeat =
    localTransaction.data.failedReceivers && localTransaction.data.failedReceivers.length > 0 && !isFailed;

  const [showAll, setShowAll] = React.useState(false);

  useMount(() => {
    dispatch(actionsTreasury.setDetailViewTransaction(transaction));

    return () => {
      dispatch(actionsTreasury.unsetDetailViewTransaction());
    };
  });

  const handleRepeatTreasuryBoxTransaction = (transaction: Transaction) => () => {
    dispatch(repeatTreasuryBoxTransaction(transaction.id));
    hideDialog();
  };

  const handleRepeatFailedDistribution = () => {
    dispatch(repeatFailedDistribution());
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const getUserNames = () => {
    const names: string[] = [];
    if (transaction.data.receivers) {
      transaction.data.receivers.forEach((receiver: Receiver) => {
        names.push(receiver.player.name);
      });
    }
    if (showAll) {
      return names.join(', ');
    } else {
      return names.slice(0, 3).join(', ');
    }
  };

  const renderDistributionSumm = () => {
    const firstDelta =
      transaction.data.receivers && transaction.data.receivers[0]
        ? transaction.data.receivers[0].delta
        : { type: 'oil' as CurrencyType, amount: 0 };
    const isManyReceivers = transaction.data.receivers ? transaction.data.receivers.length > 3 : false;
    const moreThanOne = transaction.data.receivers ? transaction.data.receivers.length > 1 : false;

    return (
      <Interpolate
        i18nKey={x('%(title)s %(amount)s %(players)s %(names)s %(showAll)s')}
        title={moreThanOne ? <span>{t('Начисление по ')}</span> : <span>{t('Начисление ')}</span>}
        amount={<Currency isFlat amount={firstDelta.amount} isBold={true} type={firstDelta.type} />}
        players={moreThanOne ? <span>{t(' игрокам: ')}</span> : <span>{t(' игроку: ')}</span>}
        names={getUserNames()}
        showAll={
          isManyReceivers ? (
            showAll ? (
              <Link isActionLink={false} arrow={''} onClick={toggleShowAll}>
                {t(' скрыть')}
              </Link>
            ) : (
              <Link isActionLink={false} arrow={''} onClick={toggleShowAll}>
                {t(' показать всех ')}
              </Link>
            )
          ) : null
        }
        className={styles.distributionCell}
        t={t}
      />
    );
  };

  const renderTransactionStatusMessage = () => {
    switch (transaction.status) {
      case TRANSACTIONS_STATUSES.FAILED: {
        return (
          <div className={styles.statusMessage}>
            {t('Распределение ресурсов не прошло. Все распределяемые ресурсы возвращены в казну клана.')}
          </div>
        );
      }
      case TRANSACTIONS_STATUSES.PENDING: {
        return <div className={styles.statusMessage}>{t('Выполняется распределение ресурсов. ')}</div>;
      }
      case TRANSACTIONS_STATUSES.COMPLETED: {
        const failedReceivers = transaction.data.failedReceivers;
        if (failedReceivers && failedReceivers.length > 0) {
          return (
            <>
              <div className={styles.statusMessage}>
                {t('Распределение ресурсов частично прошло успешно, но следующие игроки не получили ресурсы:')}
              </div>
              {renderFailedReceivers()}
              <span className={styles.failedReceiversMessage}>
                {t('Предназначавшиеся им ресурсы возвращены в казну клана.')}
              </span>
            </>
          );
        } else {
          return <div className={styles.statusMessage}>{t('Распределение ресурсов прошло успешно.')}</div>;
        }
      }
      default: {
        return null;
      }
    }
  };

  const renderFailedReceivers = () => {
    const failedReceivers = transaction.data.failedReceivers;

    if (failedReceivers && failedReceivers.length > 0) {
      return (
        <div className={styles.failedReceivers}>
          {failedReceivers.map((item, index) => {
            const member: IClanMember = members.filter((m) => m.id === item)[0];
            const name = member?.name || '';

            return (
              <span className={styles.failedReceiverName} key={`failedReceiver${index}`}>
                {`${name},`}
              </span>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const getFailedPlayerNames = (transaction: Transaction, isChildTransaction: boolean) => {
    const failedReceivers: number[] = transaction?.data?.failedReceivers || [];
    const initiallyFailedReceivers: Transaction['data']['initiallyFailedReceivers'] =
      transaction?.data?.initiallyFailedReceivers || [];

    if (initiallyFailedReceivers) {
      const failedReceiverNames = members
        .filter((member) => failedReceivers.includes(member.id))
        .map((member) => member.name);
      const initiallyFailedReceiverNames = initiallyFailedReceivers.map((member) => member.name);
      return [...failedReceiverNames, ...initiallyFailedReceiverNames];
    }

    if (isChildTransaction || failedReceivers.length > 0) {
      return members.filter((member) => failedReceivers.includes(member.id)).map((member) => member.name);
    } else {
      const successReceivers: number[] = transaction.data.receivers?.map((receiver) => receiver.player.id) || [];
      return members.filter((member) => !successReceivers.includes(member.id)).map((member) => member.name);
    }
  };
  const playerNames = getFailedPlayerNames(localTransaction, isChildTransaction);

  return (
    <>
      <DialogHeader>{isRewardsDistribution ? t('Приобретение награды') : t('Распределение ресурсов')}</DialogHeader>
      {isRewardsDistribution ? (
        <DialogBody>
          {isSuccess && (
            <div className={styles.grey}>
              <Interpolate
                i18nKey={x('Приобретение награды «%(reward)s» выполнено успешно')}
                reward={rewardTitle}
                t={t}
              />
            </div>
          )}
          <div className={styles.grey}>
            {playerNames.length > 0 && (
              <>
                {t('Контейнеры не были начислены игрокам: ')}
                {playerNames.map((name, index) => {
                  const isLast = index === playerNames.length - 1;
                  return (
                    <div
                      style={index === 0 ? { marginLeft: '6px' } : {}}
                      className={styles.white}
                      key={`Player-${name}`}
                    >
                      {`${name}`}
                      {isLast ? null : ','}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </DialogBody>
      ) : (
        <DialogBody>
          {renderDistributionSumm()}
          {renderTransactionStatusMessage()}
        </DialogBody>
      )}
      <DialogFooter>
        <ButtonGroup>
          <Button isFlat onClick={hideDialog}>
            {t('Закрыть')}
          </Button>
          {canRepeat && (
            <Button
              isFlat
              onClick={
                isRewardsDistribution
                  ? handleRepeatTreasuryBoxTransaction(localTransaction)
                  : handleRepeatFailedDistribution
              }
            >
              {t('Распределить еще раз')}
            </Button>
          )}
        </ButtonGroup>
      </DialogFooter>
    </>
  );
};

// @TODO: update i18n
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export default React.memo(translate()(TransactionDetailDialog) as React.FC<ITransactionDetailDialog>);
