import React from 'react';
import { Button, DialogBody, DialogContainer } from '@wg/wows-react-uikit';
import useOnboarding from '@wg/wows-react-uikit/features/onboarding/hooks/useOnboarding';

import { ONBOARDING_DIALOGS, ONBOARDING_GUIDES } from '~/const/onboarding';
import { t } from '~/helpers/localization';
import { isChinaRealm } from '~/helpers/realm';
import useMount from '~/hooks/useMount';

import styles from './CStarsRefinedInfoDialog.scss';
import ImageClanStars from './images/clanstars.png';
import ImageClanStarsCn360 from './images/clanstars_cn360.png';

import type { IDialog } from '~/types/declaration';

type ICStarsRefinedInfoDialog = IDialog;

const CStarsRefinedInfoDialog: React.FC<ICStarsRefinedInfoDialog> = ({ hideDialog }) => {
  const { completeSteps } = useOnboarding({ stepName: ONBOARDING_DIALOGS.CLANSTARS_ROUTE_ENTRY });

  useMount(() => {
    return () => {
      completeSteps([ONBOARDING_DIALOGS.CLANSTARS_ROUTE_ENTRY, ONBOARDING_GUIDES.CLANSTARS_REFINED]);
    };
  });

  return (
    <DialogContainer>
      <DialogBody>
        <div className={styles.wrapper}>
          <div className={styles.image}>
            <img
              className={styles.img}
              src={isChinaRealm() ? ImageClanStarsCn360 : ImageClanStars}
              width={1200}
              height={1200}
            />
          </div>
          <div className={styles.header}>{t('Играйте в отряде с участниками вашего клана и получайте награды!')}</div>
          <div className={styles.text}>
            {t(
              'Создавайте отряды и выполняйте боевые задачи, чтобы получать звёзды. Чем больше звёзд, тем больше наград!',
            )}
          </div>
          <div className={styles.actions}>
            <Button isFlat onClick={hideDialog}>
              {t('Закрыть')}
            </Button>
          </div>
        </div>
      </DialogBody>
    </DialogContainer>
  );
};

export default React.memo(CStarsRefinedInfoDialog);
