import './NotificationManager.scss';

import React, { Component } from 'react';
import Alert from 'react-s-alert';

import NotificationTemplate from './NotificationTemplate';

const MESSAGE_TIMEOUT = 1000 * 10;

class NotificationManager extends Component {
  render() {
    return (
      <Alert
        key={'AlertProvider'}
        html={true}
        stack={{ limit: 5, spacing: 10 }}
        contentTemplate={NotificationTemplate}
        timeout={MESSAGE_TIMEOUT}
        position={'bottom-right'}
        effect={'stackslide'}
        offset={20}
      />
    );
  }
}

export default NotificationManager;
