import React, { Component } from 'react';
import { connect } from 'react-redux';

import { claimClanTasksThunk, claimClanTaskThunk, type ClanTask, getClanTasksThunk } from '~/Actions/ActionClanTasks';

import ViewClanTasks from '~/Components/ViewClanTasks/ViewClanTasks';

import type { AppStateType } from '~/Reducers';

type ContainerClanTasksType = {
  router?: any;
  isFetching: boolean;
  isClaiming: boolean;
  tasks: Array<ClanTask>;
  claimed: Array<ClanTask>;
  currentAccount?: any;
  getClanTasks: () => void;
  claimClanTask: (taskId: number) => void;
  claimClanTasks: () => void;
};

const mapStateToProps = (state: AppStateType) => {
  return {
    isFetching: state.ReducerClanTasks.isFetching,
    isClaiming: state.ReducerClanTasks.isClaiming,
    tasks: state.ReducerClanTasks.tasks,
    claimed: state.ReducerClanTasks.claimed,

    currentAccount: state.currentAccount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClanTasks: () => {
      dispatch(getClanTasksThunk());
    },
    claimClanTask: (clanId: number) => {
      dispatch(claimClanTaskThunk(clanId));
    },
    claimClanTasks: () => {
      dispatch(claimClanTasksThunk());
    },
  };
};

class ContainerClanTasks extends Component<ContainerClanTasksType> {
  constructor(props) {
    super(props);

    this.getClanTasks = this.getClanTasks.bind(this);
    this.claimClanTasks = this.claimClanTasks.bind(this);
    this.claimClanTask = this.claimClanTask.bind(this);
  }

  componentDidMount() {
    this.props.getClanTasks();
  }

  getClanTasks() {
    this.props.getClanTasks();
  }

  claimClanTask(taskId: number) {
    this.props.claimClanTask(taskId);
  }

  claimClanTasks() {
    this.props.claimClanTasks();
  }

  render() {
    return (
      <ViewClanTasks
        isFetching={this.props.isFetching}
        isClaiming={this.props.isClaiming}
        tasks={this.props.tasks}
        claimClanTasks={this.claimClanTasks}
        claimClanTask={this.claimClanTask}
        claimed={this.props.claimed}
        currentAccount={this.props.currentAccount}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerClanTasks);
