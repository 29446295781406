import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import * as React from 'react';

import { Tooltip, TooltipBody } from '~/UIKit/components';

import styles from './Table.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  modify: PropTypes.string.isRequired,
  isSortable: PropTypes.bool,
  isActive: PropTypes.bool,
  isHidden: PropTypes.bool,

  tooltipParam: PropTypes.string,
  tooltipText: PropTypes.string,

  onClick: PropTypes.func,
};

class TableHeadCell extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tooltipId: uniqueId('table_head_cell'),
    };
  }

  render() {
    const classNameTh = classNames(styles.th, styles[this.props.modify], {
      [styles.hide]: this.props.isHidden,
    });

    const classNameSorter = classNames(styles.sorter, {
      [styles.isSortable]: this.props.isSortable,
      [styles.isActive]: this.props.isActive,
    });

    const tooltipId = this.state.tooltipId;
    const renderTooltip = () => {
      const renderTooltipContent = () => (
        <TooltipBody>
          {this.props.tooltipText ? <p dangerouslySetInnerHTML={{ __html: this.props.tooltipText }} /> : null}
          {this.props.tooltipParam ? <p dangerouslySetInnerHTML={{ __html: this.props.tooltipParam }} /> : null}
        </TooltipBody>
      );

      if (!this.props.tooltipText && !this.props.tooltipParam) {
        return null;
      }

      return <Tooltip id={tooltipId}>{renderTooltipContent()}</Tooltip>;
    };

    return (
      <div className={classNameTh}>
        <div className={classNameSorter} onClick={this.props.onClick} data-tip data-for={tooltipId}>
          {this.props.children}
        </div>
        {renderTooltip()}
      </div>
    );
  }
}

TableHeadCell.propTypes = propTypes;

export default TableHeadCell;
