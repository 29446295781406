import * as React from 'react';
import { StepCSSTransitionGroup } from '@wg/wows-react-uikit';

import styles from '~/UIKit/ClansRating/ClansRating.scss';
import DivisionTabs from '~/UIKit/DivisionTabs/DivisionTabs';
import LeagueSelector from '~/UIKit/LeagueSelector/LeagueSelector';

type PropsType = {
  currentLeague?: number;
  currentDivision?: number;
  leagues: Array<any>;
  onLeagueChange: (league: number) => void;
  onDivisionChange: (division: number) => void;
};

const RatingTableNav = (props: PropsType) => {
  const { currentDivision, currentLeague, leagues, onLeagueChange, onDivisionChange } = props;

  const selected = currentLeague;
  const league = leagues.find((league) => league.value == selected);
  const divisions = (league && league.divisions) || [];

  return (
    <div>
      <StepCSSTransitionGroup level={2}>
        <div>
          <StepCSSTransitionGroup level={1}>
            <div className={styles.tableMenu}>
              <LeagueSelector
                key="league-selector"
                items={leagues}
                selected={selected}
                onLeagueChange={onLeagueChange}
              />
              <DivisionTabs
                key="division-tabs"
                items={divisions}
                selected={currentDivision}
                onDivisionChange={onDivisionChange}
              />
            </div>
          </StepCSSTransitionGroup>
        </div>
      </StepCSSTransitionGroup>
    </div>
  );
};

export default RatingTableNav;
