import PropTypes from 'prop-types';
import * as React from 'react';
import { Interpolate } from 'react-i18next';

import { t } from '~/helpers/localization';

export const InterpolateWithComponent = (props) => {
  const { i18nKey, componentName, ...translationProps } = props;
  const format = t(i18nKey, {
    interpolation: { prefix: '#$?', suffix: '?$#' },
  });
  const Component = translationProps[componentName];
  delete translationProps[componentName];
  const finalI18nKey = format.replace(
    new RegExp(`%\\(${componentName}Start\\)s(.*?)%\\(${componentName}End\\)s`, 'g'),
    (_, componentContents, offset) => {
      translationProps[`${componentName}${offset}`] = componentContents ? (
        <Interpolate {...translationProps} i18nKey={componentContents} options={{ lng: 'zu' }} parent={Component} />
      ) : null;
      return `%(${componentName}${offset})s`;
    },
  );

  return <Interpolate i18nKey={finalI18nKey} {...translationProps} />;
};
InterpolateWithComponent.propTypes = {
  i18nKey: PropTypes.string.isRequired,
  componentName: PropTypes.string.isRequired,
};

export default InterpolateWithComponent;
