import React from 'react';

import styles from './ParameterGroup.scss';

type PropsType = {
  children: React.ReactNode;
  text: React.ReactNode;
  languageNotSelectedMessage?: React.ReactNode;
};

const ParameterGroup: React.FC<PropsType> = ({ children, text, languageNotSelectedMessage }) => {
  return (
    <div className={styles.parameterGroup}>
      <div className={styles.parameterGroupHeader}>
        <span className={styles.parameterGroupTitle}>{text}</span>
        <span>{languageNotSelectedMessage}</span>
      </div>
      {children}
    </div>
  );
};

export default ParameterGroup;
