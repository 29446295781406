import Raven from 'raven-js';

import type { Middleware } from '@reduxjs/toolkit';

const ravenMiddleware: Middleware = () => (next) => (action) => {
  try {
    return next(action);
  } catch (err) {
    console.error('[Processing exception to Sentry]', err);
    // Untyped dependency
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    Raven.captureException(err, {
      extra: {
        action,
      },
    });
    throw err;
  }
};

export default ravenMiddleware;
