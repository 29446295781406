import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { ErrorLoad, MessageWrap, Paginator, StepCSSTransitionGroup, Table, TableBody } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { REQUESTS_TABS } from '~/constants';
import { isChatDenied } from '~/helpers/account';
import { t } from '~/helpers/localization';
import { getMemberTooltipContent } from '~/helpers/tooltip';
import {
  ClanIsDisbandedTooltip,
  ClanMembersInfoMissingTooltip,
  MemberInfoMissingTooltip,
  MemberIsBannedTooltip,
  RankTooltipContent,
} from '~/tooltips';

import { EntriesTableHead } from '~/UIKit';
import { Tooltip } from '~/UIKit/components';

import ApplicationsNonClanMemberTableItem from '../../Requests/Applications/ApplicationsNonClanMemberTableItem';
import InvitesClanMemberTableItem from '../../Requests/Invites/InvitesClanMemberTableItem';

import type { IApplication } from '~/Actions/ActionApp';
import type { Invite } from '~/Actions/ActionInvites';
import type { IApiError } from '~/types/api';

type PropsType = {
  currentAccount: {
    id: number;
    clanId?: number;
  };
  entries: {
    sort: {
      order: string;
      isAsc: boolean;
    };
    isFetching: boolean;
    invites: Array<Invite>;
    applications: IApplication[];
    page: number;
    meta: any;
    error: IApiError;
  };
  entryType: REQUESTS_TABS;
  t: (message: string, settings?: any) => string;
  fetchEntriesByCurrentState: (withGlobalSpinner: boolean) => void;
  fetchEntriesByPage: () => void;
  headers: Array<{
    text: string;
    isSortable: boolean;
    name: string;
    modify: string;
    glyph?: string;
  }>;
  isCurrentClanFull?: boolean;
  openAccountProfile?: () => void;
  openChatWindow?: () => void;
  root: string;
  sendApplication?: () => void;
  onReloadClick: () => void;
  onSendInvite?: () => void;
  onShowCancelInvite?: () => void;
  onSortClick: () => void;
};

type StateType = {
  fetchByNewPage: boolean;
};

class NonOperationalEntries extends React.Component<PropsType, StateType> {
  private app = document.getElementById('app');
  constructor(props) {
    super(props);

    this.state = {
      fetchByNewPage: false,
    };

    this.getMemberTooltipContent = this.getMemberTooltipContent.bind(this);
    this.getRankTooltipContent = this.getRankTooltipContent.bind(this);
  }

  componentDidMount() {
    this.props.fetchEntriesByCurrentState(this.withGlobalSpinner());
    this.app.scrollTop = 0;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // eslint-disable-line
    this.setState({
      fetchByNewPage: this.props.entries.page !== nextProps.entries.page,
    });
  }

  UNSAFE_componentWillUpdate(nextProps) {
    // eslint-disable-line
    const isScrollAfterLocalFetching =
      this.props.entries.isFetching && !nextProps.entries.isFetching && this.props.entries.error === null;
    if (isScrollAfterLocalFetching) {
      this.app.scrollTop = 0;
    }
  }

  renderError() {
    const { entryType } = this.props;
    const message =
      entryType === REQUESTS_TABS.INVITES
        ? t('Не удалось получить информацию по приглашениям.')
        : t('Не удалось получить информацию по заявкам.');

    return <ErrorLoad isFlat message={message} onReloadClick={this.props.onReloadClick} />;
  }

  renderEntries() {
    const { entryType } = this.props;
    const entries = this.props.entries[entryType];
    let entriesList = null;

    if (entryType === REQUESTS_TABS.INVITES) {
      entriesList = entries.map((invite) => (
        <InvitesClanMemberTableItem
          key={invite.id}
          invite={invite}
          isChatDenied={isChatDenied(this.props.currentAccount, invite.account)}
          isCurrentClanFull={this.props.isCurrentClanFull}
          openAccountProfile={this.props.openAccountProfile}
          openChatWindow={this.props.openChatWindow}
          onSendInvite={this.props.onSendInvite}
          onShowCancelInvite={this.props.onShowCancelInvite}
          hasActiveInviteForAccount={
            invite.status === 'active' ||
            entries.some((item) => item.account.id === invite.account.id && item.status === 'active')
          }
        />
      ));
    } else {
      entriesList = entries.map((application) => (
        <ApplicationsNonClanMemberTableItem
          key={application.id}
          application={application}
          currentAccount={this.props.currentAccount}
          sendApplication={this.props.sendApplication}
          hasActiveApplicationForClan={
            application.status === 'active' ||
            entries.some((item) => item.clan.id === application.clan.id && item.status === 'active')
          }
          root={this.props.root}
        />
      ));
    }

    return <TableBody>{entriesList}</TableBody>;
  }

  renderPaginator() {
    if (this.props.entries.meta) {
      return (
        <Paginator
          key="paginator"
          page={this.props.entries.page}
          total={this.props.entries.meta.total}
          limit={settings.entries.limit}
          isFetching={this.state.fetchByNewPage}
          isDisabled={this.props.entries.isFetching}
          onSelectedPage={this.props.fetchEntriesByPage}
        />
      );
    }

    return null;
  }

  isGlobalFetching() {
    return this.props.entries.isFetching;
  }

  withGlobalSpinner() {
    return (
      this.props.entries.error === null &&
      isEmpty(this.props.entries[this.props.entryType]) &&
      isEmpty(this.props.entries.meta)
    );
  }

  getMemberTooltipContent(tooltipTip) {
    if (!tooltipTip) return null;

    const memberId = Number(tooltipTip);
    const members = {};
    this.props.entries[this.props.entryType].forEach((entry) => {
      return (members[entry.account.id] = entry.account);
    });

    return getMemberTooltipContent(members[memberId], this.props.currentAccount, true);
  }

  getRankTooltipContent(tooltipTip) {
    if (!tooltipTip) return null;

    const memberId = Number(tooltipTip);
    const entries = this.props.entries[this.props.entryType];
    const entry = entries.find((entry) => entry.account.id === memberId);

    return (
      <RankTooltipContent
        rank={entry.statistics.rank}
        seasonId={entry.statistics.season_id}
        seasonRank={entry.statistics.season_rank}
      />
    );
  }

  render() {
    if (this.isGlobalFetching()) return null;

    const { entryType } = this.props;
    const total = get(this.props, 'entries.meta.total', 0);
    const isErrored = this.props.entries.error !== null;
    const inviteDelay = 28;

    if (total === 0 && !isErrored && !this.props.entries.isFetching) {
      let title = t('Нет отправленных заявок');
      let message = t('Последние %(inviteDelay)s дней вы не отправляли заявки на вступление в клан.', {
        inviteDelay: inviteDelay,
      });

      if (entryType === REQUESTS_TABS.INVITES) {
        title = t('Нет отправленных приглашений');
        message = t('Последние %(inviteDelay)s дней ваш клан не отправлял приглашения игрокам.', {
          inviteDelay: inviteDelay,
        });
      }

      return (
        <StepCSSTransitionGroup level={1}>
          <MessageWrap title={title} message={message} key="nonoperational-message-empty" />
        </StepCSSTransitionGroup>
      );
    }

    return (
      <StepCSSTransitionGroup level={1}>
        <Table
          key="table"
          isFetching={this.props.entries.isFetching}
          isErrored={isErrored}
          stickyContainerId="non-operational-entries-sticky-container"
        >
          <EntriesTableHead
            sort={this.props.entries.sort}
            headers={this.props.headers}
            stickyContainerId="non-operational-entries-sticky-container"
            onSortClick={this.props.onSortClick}
          />
          {isErrored ? this.renderError() : this.renderEntries()}
        </Table>
        {this.renderPaginator()}

        <ClanIsDisbandedTooltip />
        <ClanMembersInfoMissingTooltip />
        <MemberIsBannedTooltip />
        <MemberInfoMissingTooltip />
        <Tooltip id="member-tooltip" getContent={this.getMemberTooltipContent} />
        <Tooltip id="rank-tooltip" getContent={this.getRankTooltipContent} />
      </StepCSSTransitionGroup>
    );
  }
}

export default React.memo(NonOperationalEntries);
