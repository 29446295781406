import '~/styles/leaflet.scss';

import classNames from 'classnames';
import React, { PureComponent } from 'react';
import Highlighter from 'react-highlight-words';
import { Button, ButtonGroup, DialogFooter, DialogHeader, Message } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { t } from '~/helpers/localization';

import { Center } from '~/UIKit';

import styles from './ViewSupply.scss';

export type BonusType = string;

type PropsType = {
  isOwnBase: boolean;
  selectedBuildingId: string | null;
  confirmBuildingId: string | null;
  dropSelection: () => void;
  navigateToTreasury: () => void;
  bonuses: Array<any> | null;
  tempHoverBuildingId: string;
  level: number;
};

class Footer extends PureComponent<PropsType> {
  getTitle = () => {
    if (this.props.level === 0) {
      return null;
    }
    if (this.props.confirmBuildingId || this.props.tempHoverBuildingId) {
      if (!this.props.bonuses || this.props.bonuses === []) {
        return null;
      }
      if (this.props.bonuses.length === 1) {
        return <DialogHeader noUnderline>{t('Улучшение дает следующий бонус')}</DialogHeader>;
      }
      return <DialogHeader noUnderline>{t('Улучшение дает следующие бонусы')}</DialogHeader>;
    } else {
      if (!this.props.bonuses || this.props.bonuses === []) {
        return null;
      } else {
        return <DialogHeader noUnderline>{t('Активные бонусы строения')}</DialogHeader>;
      }
    }
  };

  render() {
    const isTreasurySelected = this.props.selectedBuildingId === 'treasury';
    const bundlesEnabled = settings.treasury.bundlesEnabled;
    return (
      <div className={classNames(styles.buildingBottomInfo, this.props.selectedBuildingId && styles.isShow)}>
        {this.getTitle()}

        <Center>
          {this.props.bonuses.map((bonus: BonusType, index: number) => {
            return (
              <Message key={`bonus-${index}`} style={'fullsize'} type={'none'}>
                <div className={styles.paddingWrapper}>
                  <Highlighter
                    highlightClassName="HighlightPercent"
                    searchWords={window.localSettings.highlighterPercentRegexp}
                    textToHighlight={t(bonus)}
                  />
                </div>
              </Message>
            );
          })}
          <span className={styles.topWrapper}></span>
        </Center>
        {this.props.level === 0 && (
          <Center>
            <span className={styles.notBuiltFooterStatus}>{t('Здание не построено')}</span>
          </Center>
        )}

        <DialogFooter>
          <ButtonGroup>
            <Button isFlat onClick={this.props.dropSelection}>
              {t('Вернуться на базу')}
            </Button>
            {isTreasurySelected &&
              !this.props.tempHoverBuildingId &&
              this.props.level > 0 &&
              this.props.isOwnBase &&
              bundlesEnabled && (
                <Button isFlat onClick={this.props.navigateToTreasury}>
                  {t('Перейти в казну')}
                </Button>
              )}
          </ButtonGroup>
        </DialogFooter>
      </div>
    );
  }
}

export default Footer;
