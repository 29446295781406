import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import settings from '~/settings';
import { ROUTES_MAP } from '~/routes';
import { isLoggedIn } from '~/utils/auth';
import { openUrlInExternalBrowser } from '~/web2ClientAPI/base';
import { actionsClanBattles, checkClanBattles, fetchClanData, getClanBattles } from '~/Actions/ActionClanBattles';
import { getWinners } from '~/Actions/ActionHallFame';

import ViewClanBattles from '~/Components/ViewClanBattles/ViewClanBattles';

import type { AppStateType as State } from '~/Reducers';
import type { Winner } from '~/Reducers/ReducerHallFame';

type ContainerClanBattles_Props = {
  selectedTab: number;
  clanId: number;
  root: string;
  router?: any;
  clanData: any;
  battlesList: any;
  isError: boolean;
  isFetching: boolean;
  selectedBattle: any;
  wowsLadder: any;
  seasonType: string;
  teamNumber: number;
  hasBattles: boolean;

  setSelectedTab: (selectedTab: string) => void;
  getClanBattles: () => void;
  checkClanBattles: () => void;
  params: any;
  fetchClanData: () => void;
  getWinners: () => void;
  onClickBattle: (battleId: number) => void;
  onBattlePageInfoClick: (url: string) => void;
  setSelectedTeamNumber: (teamNumber: number) => void;
  winners: {
    [numberSeason: string]: Array<Winner>;
  };
};

export interface ContainerClanBattles_State {}

class ContainerClanBattles extends PureComponent<ContainerClanBattles_Props, ContainerClanBattles_State> {
  componentDidMount() {
    if (isLoggedIn()) {
      this.props.checkClanBattles();
    }
    this.props.getWinners();
  }

  navigateToRecommendations = () => {
    this.props.router.push(this.props.root + ROUTES_MAP.SEARCH);
  };

  setSelectedTab = (selectedTab: string) => {
    switch (parseInt(selectedTab, 10)) {
      case 0: {
        this.props.router.push(`${this.props.root}clan-battles/history`);
        break;
      }
      case 1: {
        this.props.router.push(`${this.props.root}clan-battles/rating`);
        break;
      }
      case 2: {
        this.props.router.push(`${this.props.root}clan-battles/hall`);
        break;
      }
      case 3: {
        this.props.router.push(`${this.props.root}clan-battles/rules`);
        break;
      }
      default: {
        break;
      }
    }
  };

  render() {
    return (
      <ViewClanBattles
        selectedTab={this.props.selectedTab}
        clanId={this.props.clanId}
        clanData={this.props.clanData}
        battlesList={this.props.battlesList}
        isError={this.props.isError}
        isFetching={this.props.isFetching}
        selectedBattle={this.props.selectedBattle}
        wowsLadder={this.props.wowsLadder}
        seasonType={this.props.seasonType}
        teamNumber={this.props.teamNumber}
        hasBattles={this.props.hasBattles}
        setSelectedTab={this.setSelectedTab}
        navigateToRecommendations={this.navigateToRecommendations}
        getClanBattles={this.props.getClanBattles}
        onClickBattle={this.props.onClickBattle}
        setSelectedTeamNumber={this.props.setSelectedTeamNumber}
        urlParams={this.props.params}
        isEmptyWinners={isEmpty(this.props.winners)}
      />
    );
  }
}

const mapStateToProps = (state: State) => {
  const wowsLadder = state.clans.wowsLadder[state.currentAccount.clanId];
  const lastSeasonId = get(settings, ['ladder', 'lastSeasonId'], null);
  const seasonType = get(settings, ['ladder', 'seasons', lastSeasonId, 'kind'], null);

  const totalBattles = wowsLadder
    ? wowsLadder.ratings
        .filter((item) => item.season_number === lastSeasonId)
        .map((item) => item.battles_count)
        .reduce((a, b) => a + b, 0)
    : 0;

  return {
    selectedTab: state.ReducerClanBattles.selectedTab,
    clanId: state.currentAccount.clanId,
    root: state.urls.root,

    clanData: state.clans.items[state.currentAccount.clanId],
    battlesList: state.ReducerClanBattles.battlesList,
    isError: state.ReducerClanBattles.isError,
    isFetching: state.ReducerClanBattles.isFetching,
    selectedBattle: state.ReducerClanBattles.selectedBattle,
    wowsLadder: wowsLadder,
    seasonType: seasonType,
    teamNumber: state.ReducerClanBattles.teamNumber,
    hasBattles: totalBattles > 0,
    winners: get(state, ['ReducerHallFame', 'winnersList']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedTab: (selectedTab: number) => {
      dispatch(actionsClanBattles.setSelectedTab(selectedTab));
    },
    getClanBattles: () => {
      dispatch(getClanBattles());
    },
    fetchClanData: () => {
      dispatch(fetchClanData());
    },
    onClickBattle: (battleId: number) => {
      dispatch(actionsClanBattles.onClickBattle(battleId));
    },
    onBattlePageInfoClick: (url) => {
      openUrlInExternalBrowser(url);
    },
    setSelectedTeamNumber: (teamNumber: number) => {
      dispatch(actionsClanBattles.setSelectedTeamNumber(teamNumber));
      dispatch(getClanBattles());
    },
    checkClanBattles: () => {
      dispatch(checkClanBattles());
    },
    getWinners: () => {
      dispatch(getWinners());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContainerClanBattles);
