import preloaded from '~/preloaded';

const USER_ID = preloaded.currentAccount?.id;

export const logPageView = () => {
  if (window.dataLayer)
    window.dataLayer.push({
      event: 'eventTracking',
      userId: USER_ID,
      category: 'Change page',
      action: `Current page: ${window.location.pathname}`,
      label: window.location.href + window.location.pathname,
    });
};

type ISendEventToGTM = (data: {
  category: string;
  action: string;
  label: string;
  value?: string;
  clanId?: number;
}) => void;

export const sendEventToGTM: ISendEventToGTM = ({ category, action, label, value = undefined, clanId = undefined }) => {
  if (window.dataLayer)
    window.dataLayer.push({
      event: 'eventTracking',
      userId: USER_ID,
      category,
      action,
      label,
      value,
      clanId,
    });
};
