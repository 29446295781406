import * as React from 'react';

import { t } from '~/helpers/localization';

const RolesDescription: React.FC = () => {
  return (
    <div>
      <p>
        <dt>{t('Командующий флотом')}</dt>
        <dd>
          {t(
            'Полный набор полномочий. Управление кланом, его ресурсами и составом, постройка зданий на клановой базе.',
          )}
        </dd>
      </p>
      <p>
        <dt>{t('Заместитель командующего')}</dt>
        <dd>
          {t('Полномочия как у командующего, исключая роспуск клана.:описание прав заместителя командующего в тултипе')}
        </dd>
      </p>
      <p>
        <dt>{t('Военком')}</dt>
        <dd>{t('Приглашение и принятие в клан.:описание прав военкома в тултипе')}</dd>
      </p>
      <p>
        <dt>{t('Кадровый офицер')}</dt>
        <dd>{t('Без полномочий.:описание прав кадрового офицера в тултипе')}</dd>
      </p>
      <p>
        <dt>{t('Офицер')}</dt>
        <dd>{t('Без полномочий.:описание прав офицера в тултипе')}</dd>
      </p>
      <p>
        <dt>{t('Курсант')}</dt>
        <dd>{t('Без полномочий.:описание прав курсанта в тултипе')}</dd>
      </p>
    </div>
  );
};

export default React.memo(RolesDescription);
