import classNames from 'classnames';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { Input } from '@wg/wows-react-uikit';

import { SOCIAL_NETWORKS_LINKS_FORMATS, SOCIAL_NETWORKS_LINKS_VALIDATION } from '~/constants';
import { t } from '~/helpers/localization';
import { useAppSelector } from '~/store';

import styles from './SocialLink.scss';

import type { ISocialLinkContainer } from './SocialLinkContainer';
import type { AppStateType as RootState } from '~/Reducers';

const stateSelector = (state: RootState) => {
  return {
    editDescriptionState: state.clanEdit?.clanDescription,
  };
};

interface ISocialLinkInput {
  link?: ISocialLinkContainer['link'];
  social: ISocialLinkContainer['social'];
  isDisabled?: ISocialLinkContainer['isDisabled'];
  onChange?: ISocialLinkContainer['onChange'];
  isFocusOn?: ISocialLinkContainer['isFocusOn'];
}

const SocialLinkInput: React.FC<ISocialLinkInput> = ({ link, social, isDisabled, onChange, isFocusOn }) => {
  const { editDescriptionState } = useAppSelector(stateSelector, shallowEqual);

  const [error, setError] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (editDescriptionState?.error?.socials_error?.[social]) {
      setError(
        t('Correct link format must be: %(linkFormat)s', {
          linkFormat: SOCIAL_NETWORKS_LINKS_FORMATS[social],
        }),
      );
    }
  }, [editDescriptionState, social]);

  const onInputChange = React.useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      const link = (e.target as HTMLInputElement).value || '';
      const isValid = !link || SOCIAL_NETWORKS_LINKS_VALIDATION[social]?.test(link);

      if (!isValid) {
        setError(
          t('Correct link format must be: %(linkFormat)s', {
            linkFormat: SOCIAL_NETWORKS_LINKS_FORMATS[social],
          }),
        );
      } else if (error) {
        setError(undefined);
      }

      onChange?.({ social, link, error: !isValid });
    },
    [social, error, onChange],
  );

  return (
    <div className={styles.inputWrapper}>
      <div className={classNames(styles.iconProvider, styles[social], styles.label)}>{social}</div>
      <Input
        className={styles.input}
        type={'text'}
        value={link || ''}
        width={'100%'}
        isDisabled={isDisabled}
        placeholder={''}
        error={error}
        isFocusOn={isFocusOn}
        onChange={onChange ? onInputChange : undefined}
      />
    </div>
  );
};

export default React.memo(SocialLinkInput);
