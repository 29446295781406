import preloaded from '~/preloaded';
import {
  ERROR_RENAMING_CLAN,
  FINISH_CHECK_AVAILABILITY_CLAN_RENAMING,
  FINISH_CHECK_CLAN_RENAMING_STATUS,
  SET_INITIAL_STATE_RENAMING_CLAN,
  SET_STATUS_URL_RENAMING_CLAN,
  START_CHECK_AVAILABILITY_CLAN_RENAMING,
  START_CHECK_CLAN_RENAMING_STATUS,
  START_RENAMING_CLAN,
  STOP_RENAMING_CLAN,
  UPDATE_RENAMING_CLAN_FIELD_VALUE,
} from '~/Actions/ActionClanRename';

import { ATTRIBUTES_VALIDATION_STATE } from '~/UIKit/ClanCreate/Constants';

import type { ActionsType, IClanRenameErrorsCommon } from '~/Actions/ActionClanRename';
import type { IApiError } from '~/types/api';

export type IClanRenameStateFields = {
  tag: {
    value: Nullable<string>;
    available: ATTRIBUTES_VALIDATION_STATE;
    isValidating: boolean;
    error: IApiError;
  };
  name: {
    value: Nullable<string>;
    available: ATTRIBUTES_VALIDATION_STATE;
    isValidating: boolean;
    error: IApiError;
  };
};

type IClanRenameState = IClanRenameStateFields & {
  error:
    | IApiError
    | IClanRenameErrorsCommon
    | {
        errors: string[];
      }
    | {
        status: string;
        status_url: string;
      };
  isCheckingStatus: boolean;
  isProcessing: boolean;
  statusUrl: string | null;
};

const initialState: IClanRenameState = {
  tag: {
    value: null,
    available: ATTRIBUTES_VALIDATION_STATE.AVAILABLE,
    isValidating: false,
    error: null,
  },
  name: {
    value: null,
    available: ATTRIBUTES_VALIDATION_STATE.AVAILABLE,
    isValidating: false,
    error: null,
  },
  error: null,
  isCheckingStatus: false,
  isProcessing: false,
  statusUrl: preloaded.urls.clanCheckStatus,
};

export const clanRename = (state: IClanRenameState = initialState, action: ActionsType): IClanRenameState => {
  switch (action.type) {
    case START_RENAMING_CLAN:
      return {
        ...state,
        isProcessing: true,
        error: null,
      };

    case STOP_RENAMING_CLAN:
      return {
        ...state,
        isProcessing: false,
      };

    case UPDATE_RENAMING_CLAN_FIELD_VALUE: {
      const fieldData = {
        ...initialState[action.field],
        value: action.value,
        error: action.error,
      };

      let errorData: IClanRenameState['error'] = null;
      if (state.error && typeof state.error !== 'string' && 'errors' in state.error) {
        errorData = { ...state.error };
        delete errorData.errors[action.field];
      }

      return {
        ...state,
        [action.field]: fieldData,
        error: errorData || state.error,
      };
    }

    case START_CHECK_AVAILABILITY_CLAN_RENAMING:
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          isValidating: true,
        },
      };

    case FINISH_CHECK_AVAILABILITY_CLAN_RENAMING: {
      const fieldData = {
        ...initialState[action.data.field],
        value: action.data.value,
        available: action.data.available,
        error: action.data.error,
      };
      return {
        ...state,
        [action.data.field]: fieldData,
      };
    }

    case ERROR_RENAMING_CLAN:
      return {
        ...state,
        isProcessing: false,
        isCheckingStatus: false,
        error: action.error,
      };

    case SET_INITIAL_STATE_RENAMING_CLAN:
      return { ...initialState };

    case SET_STATUS_URL_RENAMING_CLAN:
      return {
        ...state,
        statusUrl: action.url,
      };

    case START_CHECK_CLAN_RENAMING_STATUS:
      return {
        ...state,
        isCheckingStatus: true,
      };

    case FINISH_CHECK_CLAN_RENAMING_STATUS:
      return {
        ...state,
        isCheckingStatus: false,
      };

    default:
      return state;
  }
};
