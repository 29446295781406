import {
  configureStore,
  type StoreEnhancer,
  type Action,
  type ThunkAction,
  type ThunkDispatch,
} from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import persistState from 'redux-localstorage';

import middlewares from '~/middlewares';
import rootReducer from '~/Reducers';

type IPersistState = (args: undefined | string | string[]) => StoreEnhancer;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares);
  },
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat((persistState as IPersistState)(['introduction', 'searchHistory'])),
});

export type RootState = ReturnType<typeof store.getState>;

export type IAppStore = typeof store;
export type IAppDispatch = ThunkDispatch<RootState, unknown, Action>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;
export type AppAsyncThunk<ReturnType = void> = ThunkAction<Promise<ReturnType>, RootState, unknown, Action>;

export const useAppDispatch = useDispatch.withTypes<IAppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
