import classNames from 'classnames';
import * as React from 'react';
import { DivTooltip, Menu, TooltipBody } from '@wg/wows-react-uikit';

import { BATTLE_TYPES } from '~/constants';
import { t } from '~/helpers/localization';
import useMount from '~/hooks/useMount';

import styles from './BattleSelector.scss';

export type BattleType = string | number;

export type Item = {
  value: BattleType;
  content: string | React.ReactNode;
  isDisabled?: boolean;
};

type IBattleSelectorProps = {
  items: Item[];
  selected: BATTLE_TYPES;
  dropdownPosition: string;
  onBattleTypeChange: (arg0: BATTLE_TYPES) => void;
};

const BattleSelector: React.FC<IBattleSelectorProps> = ({
  items,
  selected,
  dropdownPosition = 'bottom',
  onBattleTypeChange,
}) => {
  const [currentDropdownPosition, setCurrentDropdownPosition] = React.useState(dropdownPosition);
  const appContainerRef = React.useRef<Nullable<HTMLElement>>(null);

  const selectedType = selected ? selected : BATTLE_TYPES.PVP;
  const selectedItem = items.find((item) => item.value === selectedType);

  useMount(() => {
    appContainerRef.current = document.getElementById('app');
    if (appContainerRef.current) appContainerRef.current.addEventListener('scroll', handleScroll);

    return () => {
      if (appContainerRef.current) appContainerRef.current.removeEventListener('scroll', handleScroll);
    };
  });

  const handleScroll: EventListener = React.useCallback(
    (e) => {
      const scrollPosition = (e.target as HTMLElement)?.scrollTop;
      const newDropdownPosition = scrollPosition > 100 ? 'bottom' : dropdownPosition;
      if (newDropdownPosition !== currentDropdownPosition) {
        setCurrentDropdownPosition(newDropdownPosition);
      }
    },
    [currentDropdownPosition, dropdownPosition],
  );

  const handleItemChange = React.useCallback(
    (battleType: BATTLE_TYPES) => {
      if (battleType !== selected) {
        onBattleTypeChange(battleType);
      }
    },
    [onBattleTypeChange, selected],
  );

  if (!selectedItem) {
    return null;
  }

  return (
    <div className={styles.container}>
      <DivTooltip
        tooltipBody={<TooltipBody withMouseClick>{t('Выбрать тип боя для отображения статистики')}</TooltipBody>}
      >
        <Menu
          items={items}
          itemSelected={selectedItem.value}
          onItemChange={handleItemChange}
          dropdownPosition={currentDropdownPosition}
        >
          <div className={classNames(styles.battleSelector, styles[selectedType])}>{selectedItem.content}</div>
        </Menu>
      </DivTooltip>
    </div>
  );
};

export default React.memo(BattleSelector);
