import preloaded from '~/preloaded';
import settings from '~/settings';
import { REQUESTS_TABS, SEASON_TYPES } from '~/constants';
import {
  REQUESTS_CHANGE_BATTLE_TYPE,
  REQUESTS_CHANGE_SEASON,
  REQUESTS_CHANGE_SEASON_TYPE,
  REQUESTS_CHANGE_TABS,
} from '~/Actions/ActionRequests';

import type { ActionsType } from '~/Actions/ActionRequests';
import type { BATTLE_TYPES } from '~/constants';

const CLAN_ID = preloaded.currentAccount.clanId;

type IRequestsState = {
  requestsBattleType: BATTLE_TYPES;
  requestsSeason: number;
  currentRequestsTab: REQUESTS_TABS;
  requestsSeasonType: SEASON_TYPES;
};

const initialState: IRequestsState = {
  requestsBattleType: settings.defaultBattleType,
  requestsSeason: settings.ladder.lastSeasonId,
  currentRequestsTab: CLAN_ID ? REQUESTS_TABS.APPLICATION : REQUESTS_TABS.INVITES,
  requestsSeasonType: SEASON_TYPES.REGULAR,
};

export const requests = (state: IRequestsState = initialState, action: ActionsType): IRequestsState => {
  switch (action.type) {
    case REQUESTS_CHANGE_BATTLE_TYPE: {
      return {
        ...state,
        requestsBattleType: action.requestsBattleType,
      };
    }

    case REQUESTS_CHANGE_SEASON: {
      return {
        ...state,
        requestsSeason: action.requestsSeason,
      };
    }

    case REQUESTS_CHANGE_SEASON_TYPE: {
      return {
        ...state,
        requestsSeasonType: action.requestsSeasonType,
      };
    }

    case REQUESTS_CHANGE_TABS: {
      return {
        ...state,
        currentRequestsTab: action.currentRequestsTab,
      };
    }

    default:
      return state;
  }
};
