import React from 'react';
import { Interpolate, translate } from 'react-i18next';

import { EntityTypes } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';

import settings from '~/settings';
import { CLANSTARS_QUEST_TYPES } from '~/constants';
import { t, x } from '~/helpers/localization';

import type { IClanStarsQuest } from '~/Reducers/ReducerClanStars';

type IClanStarsQuestTitleProps = {
  quest: IClanStarsQuest;
  withEntities?: boolean;
  classNameWrapper?: string;
  classNameAchievement?: string;
};

const ClanStarsQuestTitle: React.FC<IClanStarsQuestTitleProps> = ({
  quest,
  withEntities = false,
  classNameWrapper,
  classNameAchievement,
}) => {
  let content: React.ReactNode = '';

  switch (quest.type) {
    case CLANSTARS_QUEST_TYPES.WIN_BATTLE: {
      content = t('Победить в бою');
      break;
    }
    case CLANSTARS_QUEST_TYPES.EARN_ACHIEVEMENT: {
      if (withEntities) {
        content = (
          <Interpolate
            i18nKey={x('Получить достижение  "%(maxClanProgress)s"')}
            maxClanProgress={
              <span className={classNameAchievement}>
                <WoWSEntity
                  type={EntityTypes.ACHIEVEMENT}
                  id={quest.data.achievement}
                  presentation={{
                    withTooltip: true,
                    renderWithoutFade: true,
                  }}
                />
              </span>
            }
            t={t}
          />
        );
      } else {
        const localization = settings.realm?.languageCodeGloss;
        content = (
          <Interpolate
            i18nKey={x('Получить достижение  "%(maxClanProgress)s"')}
            maxClanProgress={settings.achievements.data[quest.data.achievement].localization.mark[localization]}
            t={t}
          />
        );
      }
      break;
    }
  }

  return <div className={classNameWrapper}>{content}</div>;
};

// @TODO: update i18n
// eslint-disable-next-line import/no-unused-modules, @typescript-eslint/no-unsafe-call
export default React.memo(translate()(ClanStarsQuestTitle) as typeof ClanStarsQuestTitle);
