import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import settings from '~/settings';
import { getLastRegularSeason, getLastSeason, isClanBattleType } from '~/helpers/ladder';
import { needShowIntroduction } from '~/helpers/supply';
import { getRank } from '~/roles';
import { getClanBaseByClanId } from '~/store/selectors/supplySelectors';

const defaultMembers = Object.freeze([]);
const defaultDisabledIds = Object.freeze([]);
const defaultSort = Object.freeze({
  field: 'name',
  isAsc: true,
});

const currentAccountSelector = (state) => state.currentAccount;
const clanSelector = (state, clanId) => state.clans.items[clanId];
const membersSelector = (state, clanId) => {
  const membersState = state.members.clans[clanId];
  return membersState ? membersState.members : defaultMembers;
};
const clanStatisticsSelector = (state, clanId) => state.members.statistics[clanId];
const clanLadderSelector = (state, clanId) => state.clans.wowsLadder[clanId];
const clanCurrentBattleTypeSelector = (state, clanId) => {
  return state.members.battleTypes[clanId] || settings.defaultBattleType;
};

const disabledIdsSelector = createSelector(membersSelector, currentAccountSelector, (members, currentAccount) =>
  members.filter((member) => getRank(member.roleName) >= getRank(currentAccount.roleName)).map((member) => member.id),
);

const reduceByField = (items, key) => items.map((item) => item[key]).reduce((prev, curr) => prev + curr);

const statisticsSelector = createSelector(
  clanSelector,
  clanStatisticsSelector,
  clanLadderSelector,
  membersSelector,
  clanCurrentBattleTypeSelector,
  (clan, currentClanStatistics, currentLadderStatistics, members, currentBattleType) => {
    const clanStatistics = currentClanStatistics ? currentClanStatistics.statistics : null;
    const isHiddenStatistics = currentClanStatistics ? currentClanStatistics.isHiddenStatistics : false;

    const isClanBattleWithStatistics = isClanBattleType(currentBattleType) && !isEmpty(clanStatistics);
    const ladderMaxPosition = isClanBattleWithStatistics ? clanStatistics.max_position : null;

    const statistics = {
      currentBattleType,
      totalMembers: clan ? clan.members_count : null,
      maxMembersCount: clan ? clan.maxMembersCount : null,
      averageBattlesCounts: null,
      averageExperiencePerBattle: null,
      averageDamagePerBattle: null,
      averageWinsPercentage: null,
      leadingTeamNumber: isClanBattleWithStatistics ? clanStatistics.leading_team_number : null,
      ratings: isClanBattleWithStatistics ? clanStatistics.ratings : null,
      ladderBattlesCount: isClanBattleWithStatistics ? clanStatistics.battles_count : null,
      ladderWinsPercent:
        isClanBattleWithStatistics && currentLadderStatistics
          ? (clanStatistics.wins_count / clanStatistics.battles_count) * 100
          : null,
      ladderMaxLeague: ladderMaxPosition ? ladderMaxPosition.league : null,
      ladderMaxDivision: ladderMaxPosition ? ladderMaxPosition.division : null,
      ladderMaxDivisionRating: ladderMaxPosition ? ladderMaxPosition.division_rating : null,
      ladderWinStreak: isClanBattleWithStatistics ? clanStatistics.longest_winning_streak : null,
      ladderWinsCount: isClanBattleWithStatistics ? clanStatistics.wins_count : null,
    };

    if (!isHiddenStatistics) {
      if (clanStatistics) {
        statistics.averageBattlesCounts = clanStatistics.battles_count;
        statistics.averageExperiencePerBattle = clanStatistics.exp_per_battle;
        statistics.averageDamagePerBattle = clanStatistics.damage_per_battle;
        statistics.averageWinsPercentage = clanStatistics.wins_percentage;
      } else if (members.length > 0) {
        const battlesCount = reduceByField(members, 'battlesCount');
        if (battlesCount > 0) {
          const wins = members.reduce((prev, item) => prev + (item.battlesCount * item.winsPercentage) / 100, 0);
          const totalDamage = members.reduce((prev, item) => prev + item.damagePerBattle * item.battlesCount, 0);
          const totalExperience = members.reduce((prev, item) => prev + item.expPerBattle * item.battlesCount, 0);

          statistics.averageBattlesCounts = battlesCount / members.length;
          statistics.averageWinsPercentage = (wins / battlesCount) * 100;
          statistics.averageExperiencePerBattle = totalExperience / battlesCount;
          statistics.averageDamagePerBattle = totalDamage / battlesCount;
        }
      }
    }

    return statistics;
  },
);

const mapStateToProps = (state, ownProps) => {
  const currentAccount = currentAccountSelector(state);
  const clanId = ownProps.clanId || currentAccount.clanId;
  const clan = clanSelector(state, clanId);
  const currentClanStatistics = clanStatisticsSelector(state, clanId);
  const isMissingStatistics = currentClanStatistics ? currentClanStatistics.isMissingStatistics : false;
  const isHiddenStatistics = currentClanStatistics ? currentClanStatistics.isHiddenStatistics : false;
  const membersState = state.members.clans[clanId];
  const sort = membersState ? membersState.sort : defaultSort;
  const membersError = membersState ? membersState.error : '';
  const base = getClanBaseByClanId(state, clanId);

  let hideLeagueIcon = false;
  const lastRegularSeason = getLastRegularSeason();
  let lastRegularSeasonData = {};
  if (state.clans.wowsLadder[clanId]) {
    state.clans.wowsLadder[clanId].ratings.forEach((season) => {
      const lastRegularSeasonId = lastRegularSeason ? lastRegularSeason.id : null;

      if (season.is_best_season_rating && season.season_number === lastRegularSeasonId) {
        lastRegularSeasonData = season;
        if (
          season.battles_count === 0 &&
          season.public_rating <= state.clans.wowsLadder[clanId].initial_public_rating
        ) {
          hideLeagueIcon = true;
        }
      }
    });
  }

  if (!lastRegularSeason) {
    hideLeagueIcon = true;
  }

  const allBonusesActivated = false;
  let disabledIds = defaultDisabledIds;
  if (currentAccount.clanId === clanId) {
    disabledIds = disabledIdsSelector(state, clanId);
  }

  return {
    base,
    clan,
    clanId,
    currentAccount,
    disabledIds,
    isFetchingJoinClan: state.applications.isFetchingJoinClan,
    isFetchingMembers: state.members.isFetching[clanId],
    isHiddenStatistics,
    isMissingStatistics,
    isRenameInProcess: state.clanRename.isProcessing,
    members: membersSelector(state, clanId),
    membersError,
    selectedMemberIds: state.membersOperation.selectedIds,
    selectedBattleType: state.members.battleTypes[clanId],
    selectedTeamNumber: state.members.teamNumber[clanId],
    sort,
    statistics: statisticsSelector(state, clanId),
    allBonusesActivated,
    hasBase: settings.supply.isEnabled ? !!get(state.supply, [clanId, 'base'], null) : true,
    needShowIntroduction: needShowIntroduction(state),
    wowsLadder: state.clans.wowsLadder,
    // @ts-ignore
    currentSeasonType: state.members.currentSeasonType || (getLastSeason() || {}).kind,
    currentSeason: state.members.currentSeason,
    hideLeagueIcon,
    lastRegularSeasonData,

    // for new supply
    supplySelectedBuilding: state.ReducerSupply.selectedBuildingId,
    isMapZoomed: state.ReducerSupply.zoom > 6,
    isSidebarOpened: state.ReducerSupply.isSidebarOpened,
  };
};

export default mapStateToProps;
