import settings from '~/settings';
import {
  SET_CROSS_REALM_LADDER,
  SET_CURRENT_CLAN_ID,
  SET_CURRENT_REALM,
  SET_CURRENT_SEASON,
  SET_CURRENT_SEASON_TYPE,
  TOGGLE_LEAGUE_AND_DIVISION,
  TOGGLE_RATING_ERROR,
  TOGGLE_RATING_FETCH,
  TOGGLE_RATING_LIST,
} from '~/Actions/ActionClanRating';

import type { ActionsType, LeagueAndDivisionType, ToggleRatingListType } from '~/Actions/ActionClanRating';
import type { SEASON_TYPES } from '~/constants';

type IRatingsState = {
  currentClanId: number;
  currentDivision: number;
  currentLeague: number;
  currentRealm: string;
  currentSeason: number;
  currentSeasonType: Nullable<SEASON_TYPES>;
  errorMessage: string;
  isCrossRealmLadder: boolean;
  isError: boolean;
  isFetching: boolean;
  ratingLists: ToggleRatingListType;
};

const initialState: IRatingsState = {
  currentClanId: 0,
  currentDivision: 0,
  currentLeague: 0,
  currentRealm: '',
  currentSeason: settings.ladder.lastSeasonId,
  currentSeasonType: null,
  errorMessage: '',
  isCrossRealmLadder: settings.ladder.isCrossRealmLadderByDefault,
  isError: false,
  isFetching: true,
  ratingLists: {
    league: 0,
    division: 0,
  },
};

export const clansRating = (state: IRatingsState = initialState, action: ActionsType): IRatingsState => {
  let rating: LeagueAndDivisionType[] = [];
  let divisions: { [divisions: number]: unknown[] } = [];

  switch (action.type) {
    case SET_CROSS_REALM_LADDER:
      return {
        ...state,
        isCrossRealmLadder: action.isCrossRealmLadder,
      };

    case TOGGLE_LEAGUE_AND_DIVISION:
      return {
        ...state,
        currentLeague: action.league,
        currentDivision: action.division,
      };

    case TOGGLE_RATING_LIST:
      if (action.ratingList) {
        rating = action.ratingList;
      }
      if (state.ratingLists[action.league]) {
        divisions = state.ratingLists[action.league];
      }
      return {
        ...state,
        ratingLists: {
          ...state.ratingLists,
          [action.league]: {
            ...divisions,
            [action.division]: rating,
          },
        },
      };

    case SET_CURRENT_CLAN_ID:
      return {
        ...state,
        currentClanId: action.currentClanId,
      };

    case TOGGLE_RATING_FETCH:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case TOGGLE_RATING_ERROR:
      return {
        ...state,
        isError: action.isError,
        errorMessage: action.errorMessage,
      };

    case SET_CURRENT_SEASON_TYPE:
      return {
        ...state,
        currentSeasonType: action.currentSeasonType,
      };

    case SET_CURRENT_SEASON:
      return {
        ...state,
        currentSeason: action.currentSeason,
      };

    case SET_CURRENT_REALM:
      return {
        ...state,
        currentRealm: action.currentRealm,
      };

    default:
      return state;
  }
};
