import axios from 'axios';

import dwhExport, { DWH_EVENTS } from '~/dwhExport';

import type { InferActionsType } from '~/Reducers';
import type { AppThunk } from '~/store';

export const CLAIM_CLAN_TASK = 'CLAIM_CLAN_TASK';
export const CLAIM_CLAN_TASK_RESPONSE = 'CLAIM_CLAN_TASK_RESPONSE';
export const GET_CLAN_TASKS = 'GET_CLAN_TASKS';
export const GET_CLAN_TASKS_RESPONSE = 'GET_CLAN_TASKS_RESPONSE';
export const CLAIM_CLAN_TASKS = 'CLAIM_CLAN_TASKS';
export const CLAIM_CLAN_TASKS_RESPONSE = 'CLAIM_CLAN_TASKS_RESPONSE';

export interface ClanTaskReward {
  oil?: number;
  coal?: number;
}

export interface ClanTask {
  taskId: number;
  clanId: number;
  name: string;
  order: number;
  claimedAt?: string;
  finishedTill?: string;
  progress: number;
  isFinished: boolean;
  isClaimed: boolean;
  reward?: ClanTaskReward;
}

type IClaimClanTasks = {
  unchanged: ClanTask[];
  changed: ClanTask[];
  reward?: ClanTaskReward;
};

export type ActionsType = InferActionsType<typeof actionsClanTask>;

export const actionsClanTask = {
  claimClanTask: () =>
    ({
      type: CLAIM_CLAN_TASK,
    }) as const,
  getClanTasks: () =>
    ({
      type: GET_CLAN_TASKS,
    }) as const,
  claimClanTasks: () =>
    ({
      type: CLAIM_CLAN_TASKS,
    }) as const,
  getClanTaskResponse: (tasks: ClanTask[]) =>
    ({
      type: GET_CLAN_TASKS_RESPONSE,
      payload: tasks,
    }) as const,
  claimTaskResponse: (data: ClanTask) =>
    ({
      type: CLAIM_CLAN_TASK_RESPONSE,
      payload: data,
    }) as const,
  claimTasksResponse: (data: IClaimClanTasks) =>
    ({
      type: CLAIM_CLAN_TASKS_RESPONSE,
      payload: data,
    }) as const,
};

export const getClanTasksThunk = (): AppThunk => (dispatch, getState) => {
  dispatch(actionsClanTask.getClanTasks());
  const state = getState();
  axios.get(state.urls.darwinGetTasks).then((response) => {
    if (response.status === 200 && response.data) {
      dispatch(actionsClanTask.getClanTaskResponse(response.data.items));
    }
  });
};

export const claimClanTaskThunk =
  (taskId: number): AppThunk =>
  (dispatch, getState) => {
    dispatch(actionsClanTask.claimClanTask());
    dwhExport.push(DWH_EVENTS.TASKS.CLAIM_TASK, { task_id: taskId });
    const state = getState();
    axios
      .patch(state.urls.darwinClaimTask.replace('{task_id}', String(taskId)))
      .then((response) => {
        if (response.status === 200 && response.data) {
          dispatch(actionsClanTask.claimTaskResponse(response.data));
        }
      })
      .catch(() => {
        dispatch(getClanTasksThunk());
      });
  };

export const claimClanTasksThunk = (): AppThunk => (dispatch, getState) => {
  dispatch(actionsClanTask.claimClanTasks());
  const state = getState();
  dwhExport.push(DWH_EVENTS.TASKS.CLAIM_ALL);
  axios
    .patch(state.urls.darwinClaimAllTasks)
    .then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(actionsClanTask.claimTasksResponse(response.data));
      }
    })
    .catch(() => {
      dispatch(getClanTasksThunk());
    });
};
