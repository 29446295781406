import isNaN from 'lodash/isNaN';
import isUndefined from 'lodash/isUndefined';

import settings from '~/settings';
import { SUPER_SHIP_ICON } from '~/constants';

const ROMANS = ['N', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII'];

export const DEFAULT_ERROR = '—';

const round = (value: number, ndigits: number) => {
  const shift = Math.pow(10, ndigits);
  return Math.round(value * shift) / shift;
};

const getCompactNumber = (number: number) => {
  let suffix = '';

  if (number >= 1e6) {
    number /= 1e6;
    suffix = ' M';
  } else if (number >= 1e5) {
    number /= 1e3;
    suffix = ' K';
  }
  let result = String(number);
  if (suffix) {
    result = number.toFixed(2) + suffix;
  }
  return result;
};

const wgsdkThousands = (number: number, reduce = false, startFrom?: number) => {
  if (reduce && startFrom !== undefined && startFrom <= number) {
    return getCompactNumber(number);
  }

  const value = number.toString();
  const dotPosition = value.indexOf('.');

  let integerPart = value;
  let decimalPart = '';

  if (dotPosition > -1) {
    const decimalSeparator = settings.formats ? settings.formats.decimal || '.' : ',';
    decimalPart = decimalSeparator + value.slice(dotPosition + 1);
    integerPart = value.slice(0, dotPosition);
  }

  let sign = '';
  if (integerPart[0] === '-') {
    integerPart = integerPart.slice(1);
    sign = '-';
  }

  const thousandSeparator = settings.formats ? settings.formats.thousand || ' ' : ' ';
  const len = integerPart.length;

  let result = '';
  for (let i = 0; i < len; ++i) {
    if (i !== 0 && len - i !== 0 && (len - i) % 3 === 0) {
      result += thousandSeparator;
    }
    result += integerPart.charAt(i);
  }
  return sign + result + decimalPart;
};

export const thousands = (value: string | number, reduceStartFrom?: number) => {
  const val = Math.round(typeof value === 'number' ? value : parseFloat(value));

  if (isNaN(val)) {
    return DEFAULT_ERROR;
  }

  return wgsdkThousands(val, !!reduceStartFrom, reduceStartFrom);
};

export const floats = function (value: string, digits?: number) {
  const val = parseFloat(value);

  if (isNaN(val)) {
    return DEFAULT_ERROR;
  }

  return wgsdkThousands(round(val, digits ?? 1));
};

export const percent = function (value: string, fixed?: number) {
  const val = parseFloat(value);

  if (isNaN(val)) {
    return DEFAULT_ERROR;
  }

  if (isUndefined(fixed)) {
    fixed = 2;
  }

  let text = '0%';
  if (val >= 100) {
    text = '100%';
  } else if (val > 0) {
    text = wgsdkThousands(Number(val.toFixed(fixed))) + '%';
  }

  return text;
};

export const toRoman = (value: number) => {
  return ROMANS[value];
};

export const toShipLevel = (value: number | string): string => {
  if (typeof value === 'string') {
    value = parseInt(value, 10);
  }
  if (!value) {
    return '';
  }
  return value <= 10 ? toRoman(value) : SUPER_SHIP_ICON;
};
