import classNames from 'classnames';
import React from 'react';
import DivTooltip from '@wg/wows-react-uikit/components/DivTooltip/DivTooltip';

import { t } from '~/helpers/localization';

import styles from './ProgressBar.scss';
import ProgressReward from '../ProgressReward/ProgressReward';

import type { IClanStarsReward, IClanStarsRewardsInfo } from '~/Reducers/ReducerClanStars';

type IProgressBar = {
  accountRewards: IClanStarsReward[];
  clanstarsCount: number;
  rewardsInfo: IClanStarsRewardsInfo;
};

const ProgressBar: React.FC<IProgressBar> = ({ accountRewards, clanstarsCount, rewardsInfo }) => {
  const milestones = React.useMemo(() => Object.keys(rewardsInfo).map(Number), [rewardsInfo]);

  const getProgressbarSectionProgress = React.useCallback(
    (milestone: number, index: number) => {
      if (milestone <= clanstarsCount) {
        return 100;
      }

      const milestonePrev = Number(milestones[index - 1]) || 0;
      if (clanstarsCount <= milestonePrev) {
        return 0;
      }

      return ((clanstarsCount - milestonePrev) / (milestone - milestonePrev)) * 100;
    },
    [clanstarsCount, milestones],
  );

  return (
    <div className={styles.progressbar}>
      {milestones.map((milestone, index) => {
        const isObtained = milestone <= clanstarsCount;

        return (
          <div className={styles.section} key={`progress_section_${milestone}`}>
            <ProgressReward
              className={styles.reward}
              accountRewards={accountRewards}
              clanstarsCount={clanstarsCount}
              isObtained={isObtained}
              items={rewardsInfo[milestone]}
              rewardGoal={milestone}
            />
            <DivTooltip
              tooltipBody={
                <div className={styles.tooltip}>
                  <span>{t('Получено')}:</span>
                  <b className={styles.clanstarsValue}>{clanstarsCount}</b>
                </div>
              }
            >
              <div className={styles.subProgressbar}>
                <span
                  className={styles.progress}
                  style={{ width: `${getProgressbarSectionProgress(milestone, index)}%` }}
                />
              </div>
              <div className={styles.labels}>
                <div
                  className={classNames(styles.milestone, {
                    [styles.isObtained]: isObtained,
                  })}
                >
                  {milestone}
                </div>
              </div>
            </DivTooltip>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(ProgressBar);
