import classNames from 'classnames';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Rank, RankWrapper } from '@wg/wows-react-uikit';

import { getRankWrapper } from '~/helpers/rank';

import styles from './TooltipMember.scss';

export interface TooltipMemberProps {
  memberAchievement: any;
  memberRank: any;
  hasSeason: boolean;
  memberName: string;
}

class TooltipMember extends React.PureComponent<TooltipMemberProps> {
  static propTypes = {
    memberName: PropTypes.string.isRequired,
    memberRank: PropTypes.number,
    memberAchievement: PropTypes.string,
    hasSeason: PropTypes.bool,
  };

  render() {
    const classNameContainer = classNames(styles.container, {
      [styles.isAchievement]: this.props.memberAchievement,
    });

    let rank;

    if (this.props.memberRank === null || isUndefined(this.props.memberRank)) {
      rank = null;
    } else if (this.props.hasSeason) {
      const rankWrapper = getRankWrapper(this.props.memberRank);
      rank = (
        <div className={styles.rank}>
          <RankWrapper level={rankWrapper}>
            <div className={styles.memberRank}>
              <Rank level={this.props.memberRank} />
            </div>
          </RankWrapper>
        </div>
      );
    } else {
      rank = (
        <div className={styles.rank}>
          <RankWrapper level={0}>{this.props.memberRank}</RankWrapper>
        </div>
      );
    }

    return (
      <div className={classNameContainer}>
        {rank}
        <div className={styles.member}>
          <div className={styles.memberName}>{this.props.memberName}</div>
          <div className={styles.memberAchievement}>{this.props.memberAchievement}</div>
        </div>
      </div>
    );
  }
}

export default TooltipMember;
