import 'slick-carousel/slick/slick.scss';

import moment from 'moment';
import React from 'react';
import { Interpolate } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import Slider from 'react-slick';
import {
  Button,
  ButtonGroup,
  DialogBody,
  DialogFooter,
  DialogHeader,
  StepCSSTransitionGroup,
} from '@wg/wows-react-uikit';

import { t, x } from '~/helpers/localization';
import { useAppDispatch, useAppSelector } from '~/store';
import { actionsClanWars, loadHistory } from '~/Actions/ActionClanWars';

import styles from './CWarsHistoryDialog.scss';
import Round from './Round';

import type { AppStateType as RootState } from '~/Reducers';
import type { IDialog } from '~/types/declaration';

const SLIDER_CHANGE_SPEED = 250;

type IArrow = {
  disabled: boolean;
  onClick: () => void;
};

const NextArrow: React.FC<IArrow> = ({ disabled, onClick }) => {
  return <div className={`slick-next ${styles.nextArrow} ${disabled ? styles.disabled : ''}`} onClick={onClick} />;
};

const PrevArrow: React.FC<IArrow> = ({ disabled, onClick }) => {
  return <div className={`slick-prev ${styles.prevArrow} ${disabled ? styles.disabled : ''}`} onClick={onClick} />;
};

const stateSelector = (state: RootState) => {
  return {
    isRoundsHistoryLoading: state.ReducerClanWars.isRoundsHistoryLoading,
    roundsHistory: state.ReducerClanWars.roundsHistory,
    roundsHistoryLimit: state.ReducerClanWars.roundsHistoryLimit,
    roundsHistoryCount: state.ReducerClanWars.roundsHistoryCount,
    roundsHistoryOffset: state.ReducerClanWars.roundsHistoryOffset,
    commonInfo: state.ReducerClanWars.commonInfo,
    warSettings: state.ReducerClanWars.warSettings,
    historyModalSlide: state.ReducerClanWars.historyModalSlide,
  };
};

type ICWarsHistoryDialog = IDialog<{
  id: string;
}>;

const CWarsHistoryDialog: React.FC<ICWarsHistoryDialog> = ({ data, hideDialog }) => {
  const { id } = data;

  const {
    roundsHistory,
    roundsHistoryCount,
    roundsHistoryOffset,
    isRoundsHistoryLoading,
    commonInfo,
    warSettings,
    roundsHistoryLimit,
    historyModalSlide,
  } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const sliderRef = React.useRef(null);
  const [lastSlideChangeTimestamp, setLastSlideChangeTimestamp] = React.useState(Date.now());

  const showPrev = () => {
    const now = Date.now();
    const canChange = now - lastSlideChangeTimestamp > SLIDER_CHANGE_SPEED * 3;

    if (sliderRef.current && canChange) {
      setLastSlideChangeTimestamp(now);
      if (historyModalSlide === 0) {
        loadHistory(10, roundsHistoryOffset + roundsHistory.length);
      } else {
        sliderRef.current.slickPrev();
      }
    }
  };

  const showNext = () => {
    const now = Date.now();
    const canChange = now - lastSlideChangeTimestamp > SLIDER_CHANGE_SPEED * 3;

    if (sliderRef.current && canChange) {
      setLastSlideChangeTimestamp(now);
      if (historyModalSlide === roundsHistory.length - 1) {
        loadHistory(10, Math.max(roundsHistoryOffset - roundsHistoryLimit, 0));
      } else {
        sliderRef.current.slickNext();
      }
    }
  };

  const afterChange = (index: number) => {
    dispatch(actionsClanWars.slideHistoryModal(historyModalSlide < index ? 'right' : 'left'));
  };

  const getStatusContent = () => {
    if (isRoundsHistoryLoading) {
      return <div className={styles.battlesNotFound}>{t('Идёт загрузка истории боёв')}</div>;
    }

    if (!roundsHistory.length) {
      return <div className={styles.battlesNotFound}>{t('Ваш клан не участвовал ни в одном Морском Сражении')}</div>;
    }

    return null;
  };

  const renderFooter = React.useMemo(() => {
    return (
      <DialogFooter>
        <ButtonGroup>
          <Button isFlat onClick={hideDialog}>
            {t('Закрыть')}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    );
  }, [hideDialog]);

  const round = roundsHistory[historyModalSlide];
  const isEmptyBattlesHistory = !roundsHistory.length;

  const prevDisabled = historyModalSlide === 0 && roundsHistoryOffset + roundsHistory.length === roundsHistoryCount;
  const nextDisabled = historyModalSlide === roundsHistory.length - 1 + roundsHistoryOffset;

  const statusContent = getStatusContent();

  return (
    <div className={styles.wrapper}>
      <DialogHeader>
        {round ? (
          <Interpolate
            useDangerouslySetInnerHTML={true}
            i18nKey={x('Морское сражение: %(startDate)s - %(endDate)s')}
            number={round.number}
            startDate={moment(round.startDate).format('DD.MM.YY')}
            endDate={moment(round.endDate).format('DD.MM.YY')}
            parent={'span'}
            t={t}
          />
        ) : (
          t('История')
        )}
      </DialogHeader>
      <StepCSSTransitionGroup level={2} id={id} className={styles.sliderAnimationWrapper}>
        <DialogBody className={styles.sliderWrapper}>
          {statusContent ? (
            statusContent
          ) : (
            <Slider
              key={`Rounds${roundsHistory.length}${isRoundsHistoryLoading ? '_loading' : ''}`}
              className={styles.slider}
              ref={sliderRef}
              dots={false}
              swipe={false}
              fade={false}
              arrows={false}
              initialSlide={historyModalSlide}
              infinite={false}
              focusOnSelect={false}
              speed={SLIDER_CHANGE_SPEED}
              afterChange={afterChange}
            >
              {roundsHistory.map((round, index) => (
                <Round
                  data={round}
                  isVisible={index === historyModalSlide}
                  key={`Round${index}`}
                  commonInfo={commonInfo}
                  warSettings={warSettings}
                />
              ))}
            </Slider>
          )}
        </DialogBody>
      </StepCSSTransitionGroup>
      {renderFooter}
      {!isEmptyBattlesHistory && (
        <>
          <PrevArrow onClick={showPrev} disabled={isRoundsHistoryLoading || prevDisabled} />
          <NextArrow onClick={showNext} disabled={isRoundsHistoryLoading || nextDisabled} />
        </>
      )}
    </div>
  );
};

export default React.memo(CWarsHistoryDialog);
