import get from 'lodash/get';
import moment from 'moment';
import * as React from 'react';
import { Interpolate } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import {
  Button,
  ButtonDublon,
  ButtonGroup,
  DialogBody,
  DialogContainer,
  DialogFooter,
  DialogHeader,
  Divider,
  Gold,
  Message,
  Toggle,
} from '@wg/wows-react-uikit';

import settings from '~/settings';
import { isoToFormattedLocalDate, isoToFormattedLocalTime } from '~/helpers/datetime';
import { showRulesDialog } from '~/helpers/dialogs';
import { t, x } from '~/helpers/localization';
import { isClanOnPreModeration } from '~/helpers/moderation';
import { useAppDispatch, useAppSelector } from '~/store';

import { checkNameOrTagAvailability } from '~/Actions/ActionClanCreate';
import { actionsClanRename, renameClan, singleCheckRenamingClanStatus } from '~/Actions/ActionClanRename';

import ClanInputWrapper from '~/UIKit/ClanCreate/ClanInputWrapper';
import { ATTRIBUTES_VALIDATION_STATE } from '~/UIKit/ClanCreate/Constants';
import ErrorDublon from '~/UIKit/ErrorDublon/ErrorDublon';
import TooltipBody from '~/UIKit/components/Tooltip/TooltipBody';

import styles from './ClanNameEditDialog.scss';

import type { AppStateType as RootState } from '~/Reducers';
import type { IApiError } from '~/types/api';
import type { IDialog } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  return {
    currentAccount: state.currentAccount,
    clan: state.clans.items[state.currentAccount.clanId],
    name: state.clanRename.name,
    tag: state.clanRename.tag,
    isProcessing: state.clanRename.isProcessing,
    error: state.clanRename.error,
  };
};

export type IClanNameEditDialog = IDialog;

const ClanNameEditDialog: React.FC<IClanNameEditDialog> = ({ hideDialog }) => {
  const { currentAccount, clan, name, tag, error, isProcessing } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const onPreModeration = isClanOnPreModeration(clan);

  const checkRenamingClanStatus = React.useCallback(() => {
    return dispatch(singleCheckRenamingClanStatus());
  }, [dispatch]);

  const onRenameClanSubmit = React.useCallback(() => {
    return dispatch(renameClan()).then((isSuccess: boolean) => {
      if (isSuccess) {
        hideDialog();
      }
    });
  }, [dispatch, hideDialog]);

  const onUpdateFieldValue = React.useCallback(
    (field: string, value: string, error: IApiError) => {
      return dispatch(actionsClanRename.updateFieldValueRenamingClan(field, value, error));
    },
    [dispatch],
  );

  const onValidateOnServer = React.useCallback(
    (field: string, value: string) => {
      dispatch(actionsClanRename.startCheckAvailabilityRenamingClan(field));
      return dispatch(checkNameOrTagAvailability(field, value, actionsClanRename.finishCheckAvailabilityRenamingClan));
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (isProcessing) {
      checkRenamingClanStatus();
    }
  }, [isProcessing, checkRenamingClanStatus]);

  const renderHeaderMessage = (account) => {
    if (settings.clanRename.renameCooldown) {
      if (account.nextRenameAvailableAt && !account.isFreeRenameAvailable) {
        return (
          <Message type="error" style={null}>
            {t('Тег и название были недавно изменены. Повторное изменение станет доступно %(date)s в %(time)s', {
              date: isoToFormattedLocalDate(account.nextRenameAvailableAt),
              time: isoToFormattedLocalTime(account.nextRenameAvailableAt),
            })}
          </Message>
        );
      }
      return (
        <Message type="error" style={null}>
          {t('Изменение тега и названия возможно один раз в %(count)s дней.', {
            count: moment.duration(settings.clanRename.renameCooldown, 'seconds').days(),
          })}
        </Message>
      );
    }
    return null;
  };

  const renderFooterMessage = (account, clanRulesToggle) => {
    if (account.isFreeRenameAvailable) {
      const message =
        account.roleName === 'commander'
          ? x(
              'Как командующий флотом, вы можете 1 раз бесплатно изменить тег и название клана. В следующий раз стоимость переименования составит %(dublons)s',
            )
          : x(
              'Командующий флотом может 1 раз бесплатно изменить тег и название клана. Как заместитель командующего, вы можете изменить тег и название за %(dublons)s',
            );

      return (
        <div>
          <div className={styles.rules}>
            <Interpolate
              i18nKey={x('Администрация игры изменила тег и название вашего клана, так как они нарушали %(rules)s')}
              rules={clanRulesToggle}
              t={t}
            />
          </div>
          <div className={styles.info}>
            <Interpolate i18nKey={message} dublons={<Gold amount={settings.clanRename.cost} />} t={t} />
          </div>
        </div>
      );
    }
    return null;
  };

  const renderDialogHeader = (currentAccount) => {
    const headerMessage = renderHeaderMessage(currentAccount);
    return (
      headerMessage && (
        <div>
          <div className={styles.headerMessage}>{headerMessage}</div>
          <Divider type="major" />
        </div>
      )
    );
  };

  const renderDialogFooter = (currentAccount, needGold, isButtonDisabled, tooltipContent) => {
    const hasFreeRenameAccess = currentAccount.isFreeRenameAvailable && currentAccount.roleName === 'commander';
    const cost = hasFreeRenameAccess ? 0 : settings.clanRename.cost;

    const onClickClansRules = () => {
      dispatch(showRulesDialog());
    };

    const clanRulesToggle = <Toggle caption={t('Правила кланов')} onClick={onClickClansRules} />;
    const footerMessage = renderFooterMessage(currentAccount, clanRulesToggle);

    const clanRules = footerMessage ? null : (
      <div className={styles.rules}>
        <Interpolate
          i18nKey={x('При изменении тега и названия соблюдайте %(clanRules)s')}
          clanRules={clanRulesToggle}
          t={t}
        />
      </div>
    );
    const renameMessage = <div className={styles.info}>{t('Переименование может занять некоторое время')}</div>;

    if (needGold === 0 || !cost) {
      return (
        <div>
          {clanRules}
          {footerMessage}
          {renameMessage}
          <ButtonGroup isLoading={isProcessing} spinnerLabel={t('Переименовываем клан')}>
            <ButtonDublon
              caption={t('Сохранить')}
              amount={cost}
              isDisabled={isButtonDisabled}
              isJustified
              isHighlighted
              isFlat
              onClick={onRenameClanSubmit}
              tooltipContent={tooltipContent && <TooltipBody>{tooltipContent}</TooltipBody>}
            />
            <Button isJustified isFlat onClick={hideDialog}>
              {t('Отменить')}
            </Button>
          </ButtonGroup>
        </div>
      );
    }

    return (
      <div>
        {clanRules}
        {renameMessage}
        <ErrorDublon caption={t('Стоимость переименования')} amount={settings.clanRename.cost} deficit={needGold} />
      </div>
    );
  };

  const checkButtonDisabled = (account) => {
    let tooltipContent = null;

    // rename cooldown – button disabled permanently if free rename is not available
    if (account.nextRenameAvailableAt && !account.isFreeRenameAvailable) {
      tooltipContent = t(
        'Тег и название были недавно изменены. Повторное изменение станет доступно %(date)s в %(time)s',
        {
          date: isoToFormattedLocalDate(account.nextRenameAvailableAt),
          time: isoToFormattedLocalTime(account.nextRenameAvailableAt),
        },
      );
      return { isButtonDisabled: true, tooltipContent };
    }

    const anyFieldError = tag.error || name.error;
    const allFieldsAvailable =
      tag.available === ATTRIBUTES_VALIDATION_STATE.AVAILABLE &&
      name.available === ATTRIBUTES_VALIDATION_STATE.AVAILABLE;
    const anyFieldChanged = (tag.value && tag.value !== clan.tag) || (name.value && name.value !== clan.name);

    if (anyFieldError) {
      tooltipContent = t('Введите корректные тег и название');
    } else if (
      tag.available === ATTRIBUTES_VALIDATION_STATE.NOT_AVAILABLE ||
      name.available === ATTRIBUTES_VALIDATION_STATE.NOT_AVAILABLE
    ) {
      tooltipContent = t('Клан с таким тегом или названием уже зарегистрирован');
    }

    if (!anyFieldChanged) {
      tooltipContent = t('Измените тег или название');
    }

    return {
      isButtonDisabled: !(allFieldsAvailable && !anyFieldError && anyFieldChanged),
      tooltipContent,
    };
  };

  const renderPreModerationHelp = () => {
    return (
      <DialogContainer>
        <DialogHeader>{t('Тег и название')}</DialogHeader>
        <DialogBody>
          <div className={styles.preModerationHelp}>
            <Message style={'inline'} type="error">
              <Interpolate
                useDangerouslySetInnerHTML={true}
                i18nKey={x(
                  'Вы не можете изменить %(pre_moderation_property_name)s, пока профиль вашего клана находится на премодерации.',
                )}
                parent={'p'}
                pre_moderation_property_name={t('название и тег клана:pre_moderation_property_name')}
                t={t}
              />
            </Message>
          </div>
        </DialogBody>
        <DialogFooter>
          <ButtonGroup>
            <Button isJustified isFlat onClick={hideDialog}>
              {t('Закрыть')}
            </Button>
          </ButtonGroup>
        </DialogFooter>
      </DialogContainer>
    );
  };

  const renderModel = () => {
    const inputsErrors = {
      tag: get(error, 'errors.tag', [])[0] || null,
      name: get(error, 'errors.name', [])[0] || null,
    };

    let { isButtonDisabled, tooltipContent } = checkButtonDisabled(currentAccount);

    if (inputsErrors.tag || inputsErrors.name) {
      isButtonDisabled = true;
      tooltipContent = t('Введите корректные тег и название');
    }

    let needGold = 0;
    if (typeof error === 'object' && error && 'message' in error && error?.message) {
      isButtonDisabled = true;
      if (error.message === 'NO_GOLD') {
        needGold = settings.clanRename.cost - currentAccount.totalGold;
      }
    }

    const tagValue = tag.value === null ? clan.tag : tag.value;
    const nameValue = name.value === null ? clan.name : name.value;
    const footer = renderDialogFooter(currentAccount, needGold, isButtonDisabled, tooltipContent);
    return (
      <DialogContainer>
        <DialogHeader>{t('Тег и название')}</DialogHeader>
        <DialogBody>
          {renderDialogHeader(currentAccount)}
          <div className={styles.clanRename}>
            <div className={styles.inputTag}>
              <ClanInputWrapper
                isFocusOn
                field="tag"
                value={tagValue}
                isDisabled={isProcessing}
                isValidating={tag.isValidating}
                error={inputsErrors.tag || tag.error}
                onChange={onUpdateFieldValue}
                onValidateOnServer={onValidateOnServer}
              />
            </div>
            <ClanInputWrapper
              field="name"
              value={nameValue}
              isDisabled={isProcessing}
              isValidating={name.isValidating}
              error={inputsErrors.name || name.error}
              onChange={onUpdateFieldValue}
              onValidateOnServer={onValidateOnServer}
            />
          </div>
        </DialogBody>
        <DialogFooter>{footer}</DialogFooter>
      </DialogContainer>
    );
  };

  if (onPreModeration) {
    return renderPreModerationHelp();
  }

  return renderModel();
};

export default React.memo(ClanNameEditDialog);
