import get from 'lodash/get';
import { push } from 'react-router-redux';

import { promiseWithSpinner, fetchWrapper as fetch } from '~/helpers/fetch';
import { getCurrentClan } from '~/store/selectors/currentAccountSelector';
import * as web2ClientAPI from '~/web2ClientAPI/web2ClientAPI';
import { actionsApp } from '~/Actions/ActionApp';

import { syncAccountInfo } from './ActionAccount';

import type { InferActionsType } from '~/Reducers';
import type { AppAsyncThunk } from '~/store';

export const CLAN_LEAVE_TOGGLE_FETCHING = 'CLAN_LEAVE_TOGGLE_FETCHING';
export const SELECT_COMMANDER = 'SELECT_COMMANDER';
export const SET_INITITAL_STATE = 'SET_INITITAL_STATE';

export type ActionsType = InferActionsType<typeof actionsClanLeave>;

export const actionsClanLeave = {
  toggleFetching: () =>
    ({
      type: CLAN_LEAVE_TOGGLE_FETCHING,
    }) as const,

  selectCommander: (newCommanderId: number) =>
    ({
      type: SELECT_COMMANDER,
      newCommanderId,
    }) as const,

  setInitialState: () =>
    ({
      type: SET_INITITAL_STATE,
    }) as const,
};

export const forceAccountSync =
  (isLeave: boolean): AppAsyncThunk =>
  (dispatch, getState) => {
    const showLeaveClanNotification = () => {
      if (isLeave) {
        web2ClientAPI.sendClanLeaveNotification(getCurrentClan(getState()));
      } else {
        web2ClientAPI.sendClanDisbandNotification(getCurrentClan(getState()));
      }
    };
    const promise = dispatch(syncAccountInfo({ leaveClan: showLeaveClanNotification })).catch(() => {
      showLeaveClanNotification();
      dispatch(
        actionsApp.updateAccountInfo({
          clanId: null,
          roleName: null,
          activeInvitesCount: 0,
          activeApplicationsCount: 0,
          accumulativeClanResource: null,
          leveling: null,
          isBonusActivated: null,
        }),
      );
      const state = getState();
      dispatch(push(state.urls.root));
      dispatch(actionsClanLeave.setInitialState());
    });
    return promiseWithSpinner(dispatch, promise);
  };

export const clanLeave = (): AppAsyncThunk<boolean> => (dispatch, getState) => {
  const state = getState();

  dispatch(actionsClanLeave.toggleFetching());

  const url = state.urls.clanLeave;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
  })
    .then((json) => {
      const hasError = json.status === 'error' || !!json.error;
      if (hasError) {
        dispatch(actionsClanLeave.toggleFetching());
        const reason = get(json, 'additional_info.reason');
        if (reason === 'lead_cannot_leave_clan') {
          web2ClientAPI.sendClanLeaveErrorNotification(reason);
        } else {
          web2ClientAPI.sendClanLeaveErrorNotification();
        }
      }
      return !hasError;
    })
    .catch(() => {
      dispatch(actionsClanLeave.toggleFetching());
      web2ClientAPI.sendClanLeaveErrorNotification();
      return false;
    });
};

export const clanDisband = (): AppAsyncThunk<boolean> => (dispatch, getState) => {
  const state = getState();

  dispatch(actionsClanLeave.toggleFetching());

  const url = state.urls.clanDisband;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
  })
    .then((json) => {
      dispatch(actionsClanLeave.toggleFetching());
      const hasError = json.status === 'error' || !!json.error;
      if (hasError) {
        web2ClientAPI.sendClanLeaveErrorNotification();
      }
      return !hasError;
    })
    .catch(() => {
      dispatch(actionsClanLeave.toggleFetching());
      web2ClientAPI.sendClanLeaveErrorNotification();
      return false;
    });
};
