import * as React from 'react';
import { HiddenStatisticsIcon } from '@wg/wows-react-uikit';

import { DEFAULT_ERROR } from '~/helpers/formatting';
import { ClanIsDisbandedTooltip, ClanMembersInfoMissingTooltip, MemberInfoMissingTooltip } from '~/tooltips';

type StatisticsValueType = {
  value: string;
  isHidden?: boolean;
  isClan?: boolean;
  clanDisbanded?: boolean;
  disableTooltip?: boolean;
  className?: string;
};

const StatisticsValue: React.FC<StatisticsValueType> = ({
  isHidden,
  isClan,
  className,
  clanDisbanded,
  value,
  disableTooltip,
}) => {
  if (isHidden) {
    return <HiddenStatisticsIcon isClan={isClan} disableTooltip={disableTooltip} />;
  } else {
    const needTooltip = value === DEFAULT_ERROR && !disableTooltip;

    let dataTip = null;
    let tooltipId = null;
    if (needTooltip) {
      dataTip = true;
      tooltipId = isClan
        ? clanDisbanded
          ? ClanIsDisbandedTooltip.id
          : ClanMembersInfoMissingTooltip.id
        : MemberInfoMissingTooltip.id;
    }

    return (
      <div className={className}>
        <div data-tip={dataTip} data-for={tooltipId}>
          {value}
        </div>
      </div>
    );
  }
};

export default StatisticsValue;
