import React from 'react';
import { connect } from 'react-redux';

import { IsFromSearchContextConsumer } from '~/Providers/IsFromSearch';

import Members from '~/Components/Members/Members';
import mapDispatchToProps from '~/Components/ViewClanProfile/MapDispatchToProps';
import mapStateToProps from '~/Components/ViewClanProfile/MapStateToProps';

import type { MembersPropsType } from '~/Components/Members/Members';

interface PropsType extends MembersPropsType {}

const MembersContainer: React.FC<PropsType> = (props) => {
  return (
    <IsFromSearchContextConsumer>
      {(isFromSearch) => {
        return <Members {...props} isFromSearch={isFromSearch} />;
      }}
    </IsFromSearchContextConsumer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MembersContainer);
