import { LOCATION_CHANGE } from 'react-router-redux';

import type { Action, Middleware } from '@reduxjs/toolkit';
import type { RouterAction } from 'react-router-redux';

import type { InferActionsType } from '~/Reducers';

export const PUSH_PREV_PATH = 'PUSH_PREV_PATH';
export const CLEAR_PREV_PATH = 'CLEAR_PREV_PATH';

export type ActionsType = InferActionsType<typeof actionsPrevMiddleware>;

type CustomRouterAction = RouterAction & {
  payload: {
    pathname: string;
  };
};

const actionsPrevMiddleware = {
  clearPrevPath: () => ({ type: CLEAR_PREV_PATH }) as const,
  pushPrevPath: (pathName: string) => ({ type: PUSH_PREV_PATH, payload: { pathName } }) as const,
};

const prevPath: Middleware = () => (next) => (action) => {
  if ((action as Action).type === LOCATION_CHANGE) {
    if ((action as CustomRouterAction).payload.pathname.indexOf('/clan-profile') === -1) {
      next(action);
      return next(actionsPrevMiddleware.clearPrevPath());
    } else {
      next(action);
      return next(actionsPrevMiddleware.pushPrevPath((action as CustomRouterAction).payload.pathname));
    }
  } else {
    return next(action);
  }
};

export default prevPath;
