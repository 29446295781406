import uniqueId from 'lodash/uniqueId';
import * as React from 'react';
import {
  Button,
  ButtonGroup,
  DialogBody,
  DialogContainer,
  DialogFooter,
  DialogHeader,
  DivTooltip,
  LineClamp,
  MemberName,
  TooltipBody,
} from '@wg/wows-react-uikit';

import styles from './MembersOperationDialog.scss';

import type { IClanMember } from '~/types/declaration';

const TOOLTIP_LINES_COUNT = 20;

export interface MembersOperationDialogProps {
  buttonActionTitle: string;
  buttonCloseTitle: string;
  controlElement: React.ReactNode;
  membersForOperate: Array<IClanMember>;
  spinnerLabel: string;
  title: string;
  textAfterMembers?: string;
  textBeforeMembers?: string;

  buttonActionDisabled?: boolean;

  onActionClick: () => void;
  onCloseClick: () => void;
}

const MembersOperationDialog: React.FC<MembersOperationDialogProps> = ({
  buttonActionTitle,
  buttonCloseTitle,
  controlElement,
  membersForOperate,
  spinnerLabel,
  textAfterMembers,
  textBeforeMembers,
  title,
  buttonActionDisabled,
  onActionClick,
  onCloseClick,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const uniquePrefix = uniqueId('members_operation_dialog_');

  const onActionClickHandler = () => {
    setIsLoading(true);
    Promise.resolve(onActionClick()).then(stopLoading, stopLoading);
  };

  const stopLoading = () => {
    setIsLoading(false);
  };

  const renderMembers = () => {
    const members = membersForOperate.map((member) => (
      <MemberName key={member.id} role={member.roleName} name={member.name} />
    ));

    const needTooltip = membersForOperate.length > 10;
    const tooltip = needTooltip ? (
      <TooltipBody>
        <LineClamp lines={TOOLTIP_LINES_COUNT}>{members}</LineClamp>
      </TooltipBody>
    ) : null;

    const membersHtml = needTooltip ? null : (
      <div className={styles.members} key={`${uniquePrefix}_members_html`}>
        {members}
      </div>
    );

    let textBeforeMembersElement = null;

    if (textBeforeMembers || needTooltip) {
      textBeforeMembersElement = (
        <div className={styles.title} key={`${uniquePrefix}_text_before_members`}>
          {textBeforeMembers}
          {needTooltip ? ` ${membersForOperate.length}` : null}
        </div>
      );
    }

    return (
      <DivTooltip tooltipBody={tooltip}>
        {textBeforeMembersElement}
        {membersHtml}
      </DivTooltip>
    );
  };

  return (
    <DialogContainer>
      <DialogHeader>{title}</DialogHeader>
      <DialogBody>
        <div className={styles.center}>
          {renderMembers()}
          {textAfterMembers && <div className={styles.title}>{textAfterMembers}</div>}
          {controlElement && <div className={styles.select}>{controlElement}</div>}
        </div>
      </DialogBody>
      <DialogFooter>
        <ButtonGroup isLoading={isLoading} spinnerLabel={isLoading ? spinnerLabel : null}>
          <Button
            key="members-operation-dialog-action-button"
            isJustified
            isHighlighted
            isFlat
            onClick={onActionClickHandler}
            isDisabled={!!buttonActionDisabled}
          >
            {buttonActionTitle}
          </Button>
          <Button key="members-operation-dialog-close-button" isJustified isFlat onClick={onCloseClick}>
            {buttonCloseTitle}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    </DialogContainer>
  );
};

export default MembersOperationDialog;
