import classNames from 'classnames';
import * as React from 'react';

import styles from './Sticky.scss';

export interface StickyProps {
  appContainerId: string;
  scrollContainerId: string;
  usedStickyContainerId: string;
  children: React.ReactNode;

  breakpointOffset: number;
  isActive: boolean;
  forBottom: boolean;

  onStickyStateChange: (isSticky?: boolean) => void;
}

export interface StickyState {
  isSticky: boolean;
  top: number;
  left: number;
  width: number;
}

class Sticky extends React.Component<StickyProps, StickyState> {
  placeholder: any;
  children: any;
  appContainer: any;
  stickyContainer: any;
  scrollContainer: any;

  static defaultProps = {
    appContainerId: 'app-container',
    scrollContainerId: 'app',

    breakpointOffset: 0,
    isActive: true,
    forBottom: false,

    onStickyStateChange: () => {},
  };

  state: StickyState = {
    isSticky: false,
    top: 0,
    left: 0,
    width: 0,
  };

  componentDidMount() {
    this.appContainer = document.getElementById(this.props.appContainerId);
    this.stickyContainer = document.getElementById(this.props.usedStickyContainerId);
    this.scrollContainer = document.getElementById(this.props.scrollContainerId);
    this.on();
    this.recomputeState();
  }

  UNSAFE_componentWillReceiveProps(nextProps: StickyProps) {
    let containerChanged = false;

    if (this.props.usedStickyContainerId !== nextProps.usedStickyContainerId) {
      containerChanged = true;
      this.stickyContainer = document.getElementById(nextProps.usedStickyContainerId);
    }

    if (this.props.appContainerId !== nextProps.appContainerId) {
      containerChanged = true;
      this.appContainer = document.getElementById(nextProps.appContainerId);
    }

    if (this.props.scrollContainerId !== nextProps.scrollContainerId) {
      containerChanged = true;
      this.scrollContainer = document.getElementById(nextProps.scrollContainerId);
      this.off().on();
    }

    if (containerChanged || this.props.isActive !== nextProps.isActive) {
      this.recomputeState(nextProps.isActive);
    }
  }

  componentWillUnmount() {
    this.off();
    this.onStickyStateChange(false);
  }

  shouldComponentUpdate(newProps, newState) {
    const propNames = Object.keys(this.props);
    if (Object.keys(newProps).length !== propNames.length) return true;

    const valuesMatch = propNames.every((key) => {
      return Object.prototype.hasOwnProperty.call(newProps, key) && newProps[key] === this.props[key];
    });
    if (!valuesMatch) return true;

    if (this.state.isSticky) {
      if (newState.top !== this.state.top) return true;
      if (newState.left !== this.state.left) return true;
      if (newState.width !== this.state.width) return true;
    }
    return newState.isSticky !== this.state.isSticky;
  }

  onStickyStateChange = (isSticky: boolean) => {
    if (this.props.isActive && !this.props.forBottom && this.children) {
      const height = this.children.getBoundingClientRect().height;
      this.appContainer.style.paddingTop = isSticky ? `${height}px` : 0;
    }
    if (this.props.onStickyStateChange) {
      this.props.onStickyStateChange(isSticky);
    }
  };

  getDistanceFromTop = () => {
    if (this.stickyContainer && this.scrollContainer) {
      return this.stickyContainer.getBoundingClientRect().top - this.scrollContainer.getBoundingClientRect().top;
    }
    return 0;
  };

  getDistanceFromBottom = () => {
    if (this.stickyContainer && this.scrollContainer) {
      return this.stickyContainer.getBoundingClientRect().bottom - this.scrollContainer.getBoundingClientRect().bottom;
    }
    return 0;
  };

  isSticky = () => {
    if (this.props.forBottom) {
      const topBreakpoint = this.stickyContainer.getBoundingClientRect().top;
      const bodyStyle = window.getComputedStyle(document.body, null);
      const offset =
        parseInt(bodyStyle.paddingBottom, 10) +
        parseInt(bodyStyle.paddingTop, 10) +
        this.children.getBoundingClientRect().height;
      return (
        this.getDistanceFromBottom() > 0 &&
        this.scrollContainer.scrollTop + this.scrollContainer.getBoundingClientRect().bottom - offset > topBreakpoint
      );
    } else {
      const bottomBreakpoint = this.scrollContainer.getBoundingClientRect().top;
      return this.getDistanceFromTop() < 0 && this.stickyContainer.getBoundingClientRect().bottom > bottomBreakpoint;
    }
  };

  recomputeState = (isActive?: boolean) => {
    const isSticky = (isActive || this.props.forBottom) && this.isSticky();
    if (isSticky !== this.state.isSticky) {
      this.onStickyStateChange(isSticky);
    }
    const width = this.placeholder.getBoundingClientRect().width;
    const left = this.placeholder.getBoundingClientRect().left;
    const newState = { isSticky, width, left };
    if (!this.props.forBottom) {
      // @ts-ignore
      newState.top = this.scrollContainer.getBoundingClientRect().top - this.children.getBoundingClientRect().height;
    }
    this.setState(newState);
  };

  onSmthChanged = () => {
    this.recomputeState(this.props.isActive);
  };

  on = () => {
    if (this.scrollContainer) {
      this.scrollContainer.addEventListener('scroll', this.onSmthChanged);
      window.addEventListener('resize', this.onSmthChanged);
    }
    return this;
  };

  off = () => {
    if (this.scrollContainer) {
      this.scrollContainer.removeEventListener('scroll', this.onSmthChanged);
      window.removeEventListener('resize', this.onSmthChanged);
    }
    return this;
  };

  render() {
    let style,
      placeholderStyle = {};

    if (this.state.isSticky) {
      style = {
        left: this.state.left,
        width: this.state.width,
      };

      if (this.props.forBottom) {
        placeholderStyle = {
          height: this.children.getBoundingClientRect().height,
        };
      } else {
        style.top = this.state.top;
      }
    }

    const classNameSticky = classNames(styles.sticky, {
      [styles.isSticky]: this.state.isSticky,
      [styles.forBottom]: this.props.forBottom,
      [styles.isActive]: this.props.forBottom && this.props.isActive,
    });

    const classNameDecor = classNames(styles.decor, {
      [styles.isActive]: this.state.isSticky && this.props.forBottom,
    });

    return (
      <div>
        <div ref={(placeholder) => (this.placeholder = placeholder)} style={placeholderStyle} />
        <div className={classNameSticky} ref={(children) => (this.children = children)} style={style}>
          <div className={classNameDecor} />
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Sticky;
