import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import * as React from 'react';
import { translate } from 'react-i18next';

import { t } from '~/helpers/localization';

import ClanNameInput from './ClanNameInput';
import ClanTagInput from './ClanTagInput';
import { VALIDATION_DEBOUNCE_TIME } from './Constants';

const propTypes = {
  field: PropTypes.oneOf(['tag', 'name']).isRequired,

  error: PropTypes.string,
  isFocusOn: PropTypes.bool,
  isValidating: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.string,

  onChange: PropTypes.func.isRequired,
  onValidateOnServer: PropTypes.func.isRequired,
};

class ClanInputWrapper extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      error: props.error,
      localError: false,
      request: null,
    };

    this.onChange = this.onChange.bind(this);
    this.onChangeDebounced = debounce(this.onChangePurified.bind(this), 0);
    this.validateOnServerDebounce = debounce(this.onValidateOnServer.bind(this), VALIDATION_DEBOUNCE_TIME);
  }

  UNSAFE_componentWillReceiveProps({ error }) {
    // eslint-disable-line
    if (error !== this.state.error && !this.state.localError) {
      this.setState({ error });
    }
  }

  componentWillUnmount() {
    this.validateOnServerDebounce.cancel();
  }

  onChange(value, error) {
    this.validateOnServerDebounce.cancel();
    this.setState({
      value,
      error,
      localError: !!error,
    });

    this.onChangeDebounced(value, error);
    if (error === null) {
      this.validateOnServerDebounce(value);
    }
  }

  onChangePurified(value, error) {
    this.props.onChange(this.props.field, value, error);
  }

  onValidateOnServer(value) {
    this.state.request && this.state.request.abort();
    this.setState({
      request: this.props.onValidateOnServer(this.props.field, value),
    });
  }

  render() {
    const inputProps = {
      isDisabled: this.props.isDisabled,
      isFocusOn: false,
      isValidating: this.props.isValidating,
      error: this.state.error,
      value: this.state.value,
      onChange: this.onChange,
      t: t,
    };
    const component =
      this.props.field === 'name' ? <ClanNameInput {...inputProps} /> : <ClanTagInput {...inputProps} />;

    return <div>{component}</div>;
  }
}

ClanInputWrapper.propTypes = propTypes;

export default translate()(ClanInputWrapper);
