import PropTypes from 'prop-types';
import { Interpolate } from 'react-i18next';

import { unixToFormattedLocalDate, unixToFormattedLocalTime } from '~/helpers/datetime';
import { t, x } from '~/helpers/localization';

import { Tooltip, TooltipBody } from '~/Components/UIKit/components';
import { TooltipRank } from '~/UIKit';

export const ClanIsDisbandedTooltip = () => (
  <Tooltip id={ClanIsDisbandedTooltip.id}>
    <TooltipBody>{t('Клан распущен')}</TooltipBody>
  </Tooltip>
);
ClanIsDisbandedTooltip.id = 'clan-is-dissolved-tooltip';

export const ClanMembersInfoMissingTooltip = () => (
  <Tooltip id={ClanMembersInfoMissingTooltip.id}>
    <TooltipBody>{t('Не удалось получить информацию об игроках клана')}</TooltipBody>
  </Tooltip>
);
ClanMembersInfoMissingTooltip.id = 'clan-members-info-missing-tooltip';

export const MemberInfoMissingTooltip = () => (
  <Tooltip id={MemberInfoMissingTooltip.id}>
    <TooltipBody>{t('Не удалось получить информацию об игроке')}</TooltipBody>
  </Tooltip>
);
MemberInfoMissingTooltip.id = 'member-info-missing-tooltip';

export const MemberIsBannedTooltip = () => (
  <Tooltip id={MemberIsBannedTooltip.id}>
    <TooltipBody>{t('Аккаунт игрока заблокирован')}</TooltipBody>
  </Tooltip>
);
MemberIsBannedTooltip.id = 'member-is-banned-tooltip';

export const LastBattleTimeTooltipContent = (date: number) => (
  <TooltipBody>
    <Interpolate
      useDangerouslySetInnerHTML={true}
      i18nKey={x('Последний бой: %(date)s&nbsp;в&nbsp;%(time)s')}
      date={unixToFormattedLocalDate(date)}
      time={unixToFormattedLocalTime(date)}
      t={t}
    />
  </TooltipBody>
);

export const RankTooltipContent = (props: { rank: number; seasonId: number; seasonRank: number }) => (
  <TooltipBody>
    <TooltipRank rank={props.rank} seasonId={props.seasonId} seasonRank={props.seasonRank} />
  </TooltipBody>
);
RankTooltipContent.propTypes = {
  rank: PropTypes.number,
  seasonId: PropTypes.number,
  seasonRank: PropTypes.number,
};
