import classNames from 'classnames';
import get from 'lodash/get';
import React from 'react';
import { DivTooltip, TooltipBody } from '@wg/wows-react-uikit';

import { LANGUAGES } from '~/constants';

import styles from './Flag.scss';

interface IFlagProps {
  language: string;
  isTooltipEnabled?: boolean;
  isTitleEnabled?: boolean;
  onClick?: (e?: React.MouseEvent<Element>) => void;
}

const Flag: React.FC<IFlagProps> = ({
  language = 'en',
  isTooltipEnabled = true,
  isTitleEnabled = false,
  onClick = undefined,
}) => {
  const languageTitle = get(LANGUAGES, language, `[ ${language} ]`);
  const isClickable = onClick !== null;

  const icon = (
    <div key={`${language}_icon`} className={classNames(styles.language, styles[language], `_lang-${language}`)} />
  );
  const title = (
    <span className={styles.languageTitle} key={`${language}_title`}>
      {languageTitle}
    </span>
  );

  const content = isTitleEnabled ? [icon, title] : [icon];

  const flag = isTooltipEnabled ? (
    <DivTooltip
      tooltipBody={<TooltipBody>{languageTitle}</TooltipBody>}
      className={classNames(styles.languageWrapper, {
        [styles.languageWrapperClickable]: isClickable,
      })}
      onClick={onClick}
    >
      {content}
    </DivTooltip>
  ) : (
    content
  );

  return <>{flag}</>;
};

export default Flag;
