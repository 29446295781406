import i18n from 'i18next';
import each from 'lodash/each';
import { reactI18nextModule } from 'react-i18next';

import settings from './settings';

const language = settings.realm.languageCode;
const catalog = window.i18n?.catalog || {};

type II18nCatalog = Record<string, string>;

const i18nextTranslations: II18nCatalog = {};
each(catalog, (translations, key) => {
  const pluralizable = Array.isArray(translations);

  if (pluralizable) {
    each(translations, (translation: string, index: number) => {
      i18nextTranslations[`${key}_${index}`] = translation;
    });
    if (translations.length >= 1) {
      i18nextTranslations[`${key}`] = translations[0];
      i18nextTranslations[`${key}_plural`] = translations[1];
    }
  } else {
    i18nextTranslations[key] = translations;
  }
});

const i18nextResources = {
  [language]: {
    wows: i18nextTranslations,
  },
};

void i18n.use(reactI18nextModule).init({
  nsSeparator: false,
  keySeparator: false,
  fallbackLng: false,
  lowerCaseLng: true,

  lng: language,

  resources: i18nextResources,
  ns: ['wows'],
  defaultNS: 'wows',

  debug: false,

  interpolation: {
    prefix: '%(',
    suffix: ')s',
    escapeValue: false,
  },
});

export default i18n;
