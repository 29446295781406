import React from 'react';

type ScrollToBottomHookProps = {
  ref: React.MutableRefObject<Nullish<HTMLElement>> | React.RefObject<Nullish<HTMLElement>>;
  onScrollToBottom: () => void;
  threshold?: number;
  debounceTimeout?: number;
};

const useScrolledToBottom = ({
  ref,
  onScrollToBottom,
  threshold = 100,
  debounceTimeout = 300,
}: ScrollToBottomHookProps) => {
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    const container = ref.current;
    if (!container) {
      return;
    }

    const handleScroll = () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        if (container.scrollHeight - container.scrollTop <= container.clientHeight + threshold) {
          onScrollToBottom();
        }
      }, debounceTimeout);
    };

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [onScrollToBottom, threshold, debounceTimeout, ref]);
};

export default useScrolledToBottom;
