import { t } from '~/helpers/localization';

export default [
  {
    text: t('Игрок'),
    isSortable: false,
    name: 'name',
    modify: 'basis',
    glyph: '',
  },
  {
    text: '',
    isSortable: false,
    name: 'comment',
    modify: 'small',
    glyph: '',
  },
  {
    text: t('Ранг'),
    isSortable: false,
    name: 'rank',
    modify: 'small',
    glyph: '',
  },
  {
    text: 'BTL',
    isSortable: false,
    name: 'btl',
    modify: 'right',
    glyph: 'battles',
    sortDefaultIsAsc: false,
    tooltipText: t('Количество боёв в выбранном типе боев за всё время.:tooltip'),
    tooltipParam: 'BTL &mdash; Battles',
  },
  {
    text: 'W/B',
    isSortable: false,
    name: 'wb',
    modify: 'right',
    glyph: 'wins-per-bettles',
    sortDefaultIsAsc: false,
    tooltipText: t('Процент побед в выбранном типе боев за всё время.:tooltip'),
    tooltipParam: 'W/B &mdash; Wins per Battles',
  },
  {
    text: 'E/B',
    isSortable: false,
    name: 'aeb',
    modify: 'right',
    glyph: 'experience-per-battle',
    sortDefaultIsAsc: false,
    tooltipText: t('Средний опыт за бой в выбранном типе боев за всё время.:tooltip'),
    tooltipParam: 'E/B &mdash; Experience per Battle',
  },
  {
    text: 'D/B',
    isSortable: false,
    name: 'admg',
    modify: 'right',
    glyph: 'damage-per-battle',
    sortDefaultIsAsc: false,
    tooltipText: t('Средний урон за бой в выбранном типе боев за всё время.:tooltip'),
    tooltipParam: 'D/B &mdash; Damage per Battle',
  },
  {
    text: 'F/B',
    isSortable: false,
    name: 'afb',
    modify: 'right',
    glyph: 'frags-per-battle',
    sortDefaultIsAsc: false,
    tooltipText: t('Среднее количество уничтоженных кораблей за бой в выбранном типе боев за всё время.:tooltip'),
    tooltipParam: 'F/B &mdash; Frags per Battle',
  },
  {
    text: t('Решение:заголовок таблица приглашений'),
    isSortable: false,
    name: 'action',
    modify: 'right',
    glyph: '',
  },
];
