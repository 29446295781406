import { gql } from '@apollo/client';

const battleTypes = gql`
  query BattleType($lang: String) {
    battleTypes(lang: $lang) {
      id
      name
      title
      description
      icons {
        default
      }
    }
  }
`;

export default battleTypes;

export type IBattleTypeData = {
  id: string;
  name: string;
  title: string;
  description: string;
  icons: {
    default: string;
  };
};

export type IBattleTypesResponse = {
  battleTypes: IBattleTypeData[];
};
