import classNames from 'classnames';
import isUndefined from 'lodash/isUndefined';
import * as React from 'react';
import { translate } from 'react-i18next';
import { StepCSSTransitionGroup, StickyContainer, Toggle } from '@wg/wows-react-uikit';

import settings from '~/settings';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { scrollToY } from '~/helpers/animate';
import { isClanBattleType } from '~/helpers/ladder';
import { t } from '~/helpers/localization';
import { getElementOffsetTop } from '~/helpers/position';
import { hasAnyPermission, type ROLE_NAMES, PERMISSIONS } from '~/roles';

import {
  BattleSelector,
  LadderSeasonsDropdown,
  LadderTeamsDropdown,
  MembersControlBlock,
  MembersTable,
  Ratings,
} from '~/UIKit';
import Sticky from '~/UIKit/Sticky/Sticky';

import styles from './Members.scss';

export interface MembersPropsType {
  currentAccount: {
    id: number;
    clanId: number;
    roleName: ROLE_NAMES;
  };
  selectedMemberIds?: Array<number>;
  onRemoveMembersClick: (members: Array<number>) => void;
  onChangeCommanderClick: (members: Array<number>) => void;
  onChangeRoleClick: () => void;
  onMembersReloadClick: (clanId: number) => void;
  openAccountProfile: () => void;
  openChatWindow: () => void;
  onTickMember: () => void;
  onTickAllMembers: () => void;
  isMissingStatistics?: boolean;
  isHiddenStatistics?: boolean;
  statistics?: {
    totalMembers?: number;
    maxMembersCount?: number;
    averageBattlesCounts?: number;
    averageWinsPercentage?: number;
    averageExperiencePerBattle?: number;
    averageDamagePerBattle?: number;
    currentBattleType?: string;
    ladderWinsCount?: number;
  };
  sort?: {
    field: string;
    isAsc: boolean;
  };
  currentSeasonType?: string;
  currentSeason?: number;
  selectedTeamNumber?: number;
  onSeasonChange: (season: number, clanId: number) => void;
  onThSortClick: (field: string, isAsc: boolean, hiddenSortingNames: Array<string>, clanId: number) => void;
  onBattleTypeChange: (selectedBattleType: string | number, clanId: number) => void;
  onTeamNumberChange: (selectedTeamNumber: number, clanId: number) => void;
  selectedBattleType: string;
  members: Array<any>;
  clan?: {
    id?: number;
    tag?: string;
    name?: string;
    leveling?: number;
    rawDescription?: string;
    color?: string;
    error?: string;
    createdAt?: string;

    accumulativeResource?: number;
    personalResource?: number;
    isPreloadedData?: boolean;
  };
  clanId?: number;
  onRender: (clanId?: number) => void;
  disabledIds: Array<number>;
  isFetchingMembers?: boolean;
  membersError?: string;
  isFromSearch: boolean;
}

class Members extends React.Component<MembersPropsType> {
  static defaultProps = {
    selectedBattleType: settings.defaultBattleType,
  };
  private profileRatings: HTMLDivElement = null;

  constructor(props: MembersPropsType) {
    super(props);
  }

  componentDidMount() {
    if (this.props.currentAccount.clanId === this.props.clanId && !this.props.isFromSearch) {
      dwhExport.push(DWH_EVENTS.MEMBERS.OPEN_PAGE);
    }
    const clanId = this.props.clanId;
    if (clanId) {
      this.props.onRender(clanId);
    }
  }

  onThSortClick = (field, isAsc, hiddenSortingNames) => {
    this.props.onThSortClick(field, isAsc, hiddenSortingNames, this.props.clanId);
  };

  onBattleTypeChange = (selectedBattleType) => {
    if (this.props.currentAccount.clanId === this.props.clanId) {
      let battleTypeForDwh = selectedBattleType;
      if (selectedBattleType === 'pve') {
        battleTypeForDwh = 'coop';
      }
      dwhExport.push(DWH_EVENTS.MEMBERS.SELECT_BATTLE_TYPE, {
        battle_type: battleTypeForDwh,
      });
    }
    this.props.onBattleTypeChange(selectedBattleType, this.props.clanId);
  };

  onTeamNumberChange = (selectedTeamNumber) => {
    if (this.props.currentAccount.clanId === this.props.clanId) {
      dwhExport.push(DWH_EVENTS.MEMBERS.SELECT_TEAM_NUMBER, {
        teamNumber: selectedTeamNumber,
      });
    }
    this.props.onTeamNumberChange(selectedTeamNumber, this.props.clanId);
  };

  onMembersReloadClick = () => {
    this.props.onMembersReloadClick(this.props.clanId);
  };

  scrollToOwn(event) {
    event.preventDefault();
    const ownScrollEl = document.getElementById('ownScrollId');
    const appContainerEl = document.getElementById('app-container');
    const appEl = document.getElementById('app');
    const scrollTo =
      getElementOffsetTop(ownScrollEl) -
      getElementOffsetTop(appEl) - // position of ownScrollEl relative to appEl
      appContainerEl.offsetHeight / 2 -
      ownScrollEl.offsetHeight / 2; // shift ownScrollEl to the middle of a viewport (appContainerEl)
    scrollToY(appEl, scrollTo);
  }

  onRemoveMembersClick = () => {
    this.props.onRemoveMembersClick(this.props.members.filter((m) => this.props.selectedMemberIds.includes(m.id)));
  };

  onChangeCommanderClick = () => {
    if (this.props.selectedMemberIds.length === 1) {
      this.props.onChangeCommanderClick(this.props.members.find((m) => m.id === this.props.selectedMemberIds[0]));
    }
  };

  onSeasonChange = (season) => {
    if (this.props.currentAccount.clanId === this.props.clanId) {
      dwhExport.push(DWH_EVENTS.MEMBERS.SELECT_SEASON, {
        season: season,
      });
    }
    this.props.onSeasonChange(season, this.props.clanId);
  };

  renderTeamsMenu() {
    return (
      <div className={styles.seasonsMenu}>
        <LadderTeamsDropdown
          selectedTeamNumber={this.props.selectedTeamNumber}
          season={this.props.currentSeason}
          statistics={this.props.statistics}
          onTeamNumberChange={this.onTeamNumberChange}
        />
      </div>
    );
  }

  renderSeasonsMenu() {
    if (this.props.currentSeason === null) {
      return null;
    }

    return (
      <div className={styles.seasonsMenu}>
        <LadderSeasonsDropdown
          selectedSeasonId={this.props.currentSeason}
          selectedSeasonType={this.props.currentSeasonType}
          onSeasonChange={this.onSeasonChange}
          dropdownPosition="bottom"
        />
      </div>
    );
  }

  hasAnyClanManagementPermissions() {
    return hasAnyPermission(this.props.currentAccount.roleName, [
      PERMISSIONS.CHANGE_ROLE,
      PERMISSIONS.CHANGE_COMMANDER,
      PERMISSIONS.REMOVE_MEMBER,
    ]);
  }

  renderMembersControlBlock() {
    let membersControlBlock = null;
    if (this.hasAnyClanManagementPermissions() && !this.props.membersError) {
      membersControlBlock = (
        <Sticky
          usedStickyContainerId="members-table-control-block-sticky-container"
          isActive={this.props.selectedMemberIds.length > 0}
          forBottom
        >
          <div className={styles.membersControlBlock}>
            <MembersControlBlock
              clanId={this.props.clanId}
              isDisabled={this.props.isFetchingMembers}
              roleName={this.props.currentAccount.roleName}
              selectedMembersLength={this.props.selectedMemberIds.length}
              onRemoveMembersClick={this.onRemoveMembersClick}
              onChangeCommanderClick={this.onChangeCommanderClick}
              onChangeRoleClick={this.props.onChangeRoleClick}
              t={t}
            />
          </div>
        </Sticky>
      );
    }

    return membersControlBlock;
  }

  renderMembersTable() {
    if (isUndefined(this.props.isFetchingMembers) || this.props.isFetchingMembers) {
      return null;
    }

    let ownScroll = null;

    if (this.props.membersError) {
      ownScroll = <div className={styles.ownScrollEmpty} />;
    } else {
      const classNameOwnScroll = classNames(styles.ownScroll, {
        [styles.isDisabled]: this.props.isFetchingMembers,
      });

      ownScroll = (
        <div key="own-scroll" className={classNameOwnScroll}>
          <Toggle caption={t('Перейти к моей позиции')} onClick={this.scrollToOwn} />
        </div>
      );
    }

    return this.props.currentAccount.clanId === this.props.clanId ? (
      <StepCSSTransitionGroup level={3}>
        <StickyContainer key="members-table" id="members-table-control-block-sticky-container">
          {ownScroll}
          <MembersTable
            clanId={this.props.clanId}
            currentAccount={this.props.currentAccount}
            disabledIds={this.props.disabledIds}
            error={this.props.membersError}
            members={this.props.members}
            selectedMemberIds={this.props.selectedMemberIds}
            sort={this.props.sort}
            displayCheckboxes={this.hasAnyClanManagementPermissions()}
            isContextMenuEnabled
            isFetching={this.props.isFetchingMembers}
            currentBattleType={this.props.statistics ? this.props.statistics.currentBattleType : ''}
            stickyContainerId="members-table-sticky-container"
            onChangeCommanderClick={this.props.onChangeCommanderClick}
            onChangeRoleClick={this.props.onChangeRoleClick}
            onReloadClick={this.onMembersReloadClick}
            onRemoveMembersClick={this.props.onRemoveMembersClick}
            onThSortClick={this.onThSortClick}
            onTickAllMembers={this.props.onTickAllMembers}
            onTickMember={this.props.onTickMember}
            openAccountProfile={this.props.openAccountProfile}
            openChatWindow={this.props.openChatWindow}
          />
          {this.renderMembersControlBlock()}
        </StickyContainer>
      </StepCSSTransitionGroup>
    ) : (
      <StepCSSTransitionGroup level={3}>
        <StickyContainer key="members-table" id="members-table-control-block-sticky-container">
          <MembersTable
            key="members-table"
            clanId={this.props.clanId}
            currentAccount={this.props.currentAccount}
            error={this.props.membersError}
            members={this.props.members}
            sort={this.props.sort}
            isFetching={this.props.isFetchingMembers}
            currentBattleType={this.props.statistics ? this.props.statistics.currentBattleType : ''}
            stickyContainerId="members-table-sticky-container"
            onReloadClick={this.props.onMembersReloadClick}
            onThSortClick={this.props.onThSortClick}
            openAccountProfile={this.props.openAccountProfile}
            openChatWindow={this.props.openChatWindow}
          />
        </StickyContainer>
      </StepCSSTransitionGroup>
    );
  }

  render() {
    const battleTypes = [];

    settings.battleTypes.forEach((e) => {
      battleTypes.push(e);
    });

    return (
      <div className={styles.container}>
        <div id="profileRatingsScrollId" className={styles.profileRatings} ref={(c) => (this.profileRatings = c)}>
          <StepCSSTransitionGroup level={1}>
            <div className={styles.battleSelectorWrap}>
              <BattleSelector
                key="battle-selector"
                items={battleTypes}
                selected={this.props.selectedBattleType}
                onBattleTypeChange={this.onBattleTypeChange}
              />
            </div>
            {isClanBattleType(this.props.selectedBattleType) ? this.renderSeasonsMenu() : null}
            {isClanBattleType(this.props.selectedBattleType) ? this.renderTeamsMenu() : null}
          </StepCSSTransitionGroup>
          {!this.props.isFetchingMembers && (
            <StepCSSTransitionGroup level={2}>
              <Ratings
                key="ratings"
                clanId={this.props.clanId}
                currentSeason={this.props.currentSeason}
                membersError={!!this.props.membersError || this.props.isMissingStatistics}
                statistics={this.props.statistics}
                isHiddenStatistics={this.props.isHiddenStatistics}
              />
            </StepCSSTransitionGroup>
          )}
        </div>
        {this.renderMembersTable()}
      </div>
    );
  }
}

export default translate()(Members);
