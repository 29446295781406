import PropTypes from 'prop-types';
import * as React from 'react';
import { translate } from 'react-i18next';

import styles from './OldBrowserStub.scss';

const propTypes = {
  t: PropTypes.func.isRequired,
};

const OldBrowserStub = ({ t }) => (
  <div className={styles.container}>
    <div className={styles.title}>{t('Невозможно отобразить информацию в браузере, который вы используете')}</div>
  </div>
);

OldBrowserStub.propTypes = propTypes;

export default translate()(OldBrowserStub);
