import '@wg/wows-css-uikit/index.css';
import '@wg/wows-entities/index.css';
import '~/stylesheets/style.scss';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider, type ConnectedComponent } from 'react-redux';
import Router from 'react-router/lib/Router';
import browserHistory from 'react-router/lib/browserHistory';
import { syncHistoryWithStore } from 'react-router-redux';
import { wowsEntities } from '@wg/wows-entities';
import { TooltipProvider } from '@wg/wows-react-uikit';

import preloaded from '~/preloaded';
import { initCommonmenu } from '~/commonmenu';
import { VORTEX_ENDPOINT } from '~/constants';
import dwhExport from '~/dwhExport';
import { logPageView } from '~/helpers/analytics';
import * as utils from '~/helpers/apputils';
import { needShowIntroduction } from '~/helpers/supply';
import i18n from '~/i18n';
import oldBrowserApplication from '~/oldBrowserApplication';
import defaultRoutes, { ROUTES_MAP } from '~/routes';

import { store } from '~/store';
import { actionsProcessing } from '~/Actions/ActionProcessing';

import * as containers from '~/Containers';
import GlobalProcessing from '~/Components/GlobalProcessing/GlobalProcessing';
import CookieButton from '~/Components/UIKit/CookieButton/CookieButton';
import NotificationManager from '~/UIKit/NotificationManager/NotificationManager';

import { preloadBundleImages } from '../instant-images-preloader/dist/bundle';

import type { IAppStore } from '~/store';
import type { IRoute } from '~/types/router';

if (window) {
  if (!window.localSettings) {
    window.localSettings = {
      highlighterPercentRegexp: [
        /([+\-\u2013\u2212](\s?|&nbsp;)*((%(\s?|&nbsp;)*[\d,.]{1,3})|([\d,.]{1,3}(\s?|&nbsp;)*%)))/,
      ],
    };
  }
}

dwhExport.start();

const prepareRoute = (route: IRoute) => {
  route.onEnter = (_, replace) => {
    const state = store.getState();
    if (route.bodyClass) {
      document.body.className = route.bodyClass();
    }
    const currentAccount = state.currentAccount;
    if (route.beforeEnter) {
      route.beforeEnter(replace, preloaded.urls.root, currentAccount, state);
    }
  };

  // @TODO: get rid of componentName string in config, use react component instead
  if (route.componentName) {
    // @TODO: refactor containers
    // eslint-disable-next-line import/namespace, @typescript-eslint/no-explicit-any
    route.component = containers[route.componentName] as ConnectedComponent<any, any>;
  }

  if (route.childRoutes) {
    route.childRoutes.map((route) => prepareRoute(route));
  }

  return route;
};

const modernBrowserApplication = (store: IAppStore) => {
  const { AccountInfoSyncManager, Layout } = containers;
  const state = store.getState();
  const root = preloaded.urls.root;

  const childRoutes = defaultRoutes.map((route) => prepareRoute(route));

  const routes: IRoute[] = [
    {
      path: root,
      component: Layout,
      indexRoute: {
        onEnter: (_, replace) => {
          const { currentAccount } = store.getState();
          let replaceTo = ROUTES_MAP.PROFILE;
          if (!currentAccount.clanId) {
            replaceTo = currentAccount.activeInvitesCount ? ROUTES_MAP.REQUESTS : ROUTES_MAP.ABOUT;
          }

          replace({ pathname: `${root}${replaceTo}` });
        },
      },
      childRoutes: childRoutes,
    },
    {
      path: '*',
      component: null,
      indexRoute: {
        onEnter: (_, replace) => {
          replace({ pathname: root });
        },
      },
    },
  ];

  const isEnabledPreloadImages = state.settings.isEnabledPreloadImages;
  if (isEnabledPreloadImages) {
    window.preloaderBundleImages = {
      all: [],
    };

    if (needShowIntroduction(state)) {
      window.preloaderBundleImages.all = [...window.preloaderBundleImages.all];
    }

    const staticURL = preloaded.urls.static;
    window.preloaderBundleImages.all = window.preloaderBundleImages.all.map((imgSrc) => {
      return `${staticURL}${imgSrc}`;
    });

    store.dispatch(actionsProcessing.startFetching());
    store.dispatch(preloadBundleImages());
  }

  // @TODO: migrate to modern typed router
  // @ts-ignore
  const history = syncHistoryWithStore(browserHistory, store);

  window.store = store;

  const client = new ApolloClient({
    uri: preloaded.urls.vortexHost + VORTEX_ENDPOINT.glossary,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <TooltipProvider>
            <>
              {/* @TODO: migrate to modern typed router */}
              {/* @ts-ignore */}
              <Router history={history} routes={routes} onUpdate={logPageView} />

              <AccountInfoSyncManager />
              <NotificationManager />

              <GlobalProcessing />
              <CookieButton />
            </>
          </TooltipProvider>
        </I18nextProvider>
      </Provider>
    </ApolloProvider>
  );
};

const application = utils.isBrowserSupportsFeatures() ? modernBrowserApplication : oldBrowserApplication;

utils.checkReduxStorage();

void wowsEntities
  .init({
    vortexBaseUrl: preloaded.urls?.vortexHost,
    languageCode: preloaded.settings?.realm?.languageCode,
  })
  .finally(() => {
    const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);
    root.render(application(store));
  });

utils.initEventListeners();

try {
  initCommonmenu();
} catch (e) {
  console.error('Failed to init common menu', e);
}
