import classNames from 'classnames';
import React from 'react';
import { translate } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import {
  ClanName,
  DivTooltip,
  Menu,
  Spinner,
  Tooltip,
  TooltipBody,
  TooltipClan,
  TooltipFooter,
} from '@wg/wows-react-uikit';

import settings from '~/settings';
import { ClanRec2IsEnabled } from '~/helpers/common';
import { renameSpinnerAnimationEnterDuration, renameSpinnerAnimationLeaveDuration } from '~/helpers/csstimes';
import { t } from '~/helpers/localization';
import { hasPermission, PERMISSIONS } from '~/roles';

import styles from './OwnClanTitle.scss';

type IProps = {
  clanName: string;
  hasClanDescription: boolean;
  clanColor: string;
  clanTag: string;
  needMenu: boolean;

  accumulativeClanResource: number;
  allBonusesActivated: boolean;
  clanLevel: number;
  roleName: string;
  isRenameInProcess: boolean;

  onClanInfoEditClick: () => void;
  onNameEditClick: () => void;
  onRecommendationsClanSettingsClick: () => void;
  onLeaveClick: () => void;
  onRulesDialogClick: () => void;
  clanRulesContent: any;
};

export type IState = {
  isMenuActive: boolean;
};

class OwnClanTitle extends React.PureComponent<IProps, IState> {
  state = {
    isMenuActive: false,
  };

  getMenuCallbacks = (arg) => {
    const menuCallbacks = {
      descriptionEdit: this.props.onClanInfoEditClick,
      nameEdit: this.props.onNameEditClick,
      clanSettings: this.props.onRecommendationsClanSettingsClick,
      leaveClan: this.props.onLeaveClick,
      rulesDialog: this.onRulesDialogClick,
    };

    menuCallbacks[arg]();
  };

  getMenuItems() {
    let items = [
      {
        value: 'nameEdit',
        content: t('Изменить тег и название'),
        permissionRequired: PERMISSIONS.RENAME_CLAN,
        isDisabled: !settings.clanRename.allowed,
      },
    ];

    if (ClanRec2IsEnabled) {
      items.push({
        value: 'clanSettings',
        content: t('Изменить требования клана'),
        permissionRequired: PERMISSIONS.CHANGE_SETTINGS,
        isDisabled: !settings.clanrec2?.isEnabled,
      });
    }

    items = items.concat([
      {
        value: 'descriptionEdit',
        content: this.props.hasClanDescription ? t('Изменить описание') : t('Добавить описание'),
        permissionRequired: PERMISSIONS.CHANGE_SETTINGS,
        isDisabled: !settings.editClanDescription.allowed,
      },
      {
        value: 'rulesDialog',
        content: t('Правила кланов'),
        isDisabled: false,
      },
      {
        value: 'leaveClan',
        content: t('Покинуть клан'),
        isDisabled: false,
      },
    ]);
    return items;
  }

  onRulesDialogClick = () => {
    this.props.onRulesDialogClick();
  };

  onMenuBlur = () => {
    this.setState({
      isMenuActive: false,
    });
  };

  onMenuToggled = (isMenuActive) => {
    this.setState({
      isMenuActive: isMenuActive,
    });
  };

  renderMenuWrapper(onItemChange, child) {
    const menuItems = this.getMenuItems().filter((item) => {
      return (
        !item.permissionRequired || (hasPermission(this.props.roleName, item.permissionRequired) && !item.isDisabled)
      );
    });
    return (
      <Menu
        itemSelected={''}
        items={menuItems}
        onItemChange={onItemChange}
        onMenuBlur={this.onMenuBlur}
        onMenuToggled={this.onMenuToggled}
      >
        {child}
      </Menu>
    );
  }

  renderTitleElement() {
    const clanNameTooltipId = `clan-name-tooltip-${this.props.clanTag}`;
    const clanNameTooltipContent =
      this.props.needMenu && !this.state.isMenuActive ? (
        <Tooltip id={clanNameTooltipId}>
          <TooltipBody>
            <TooltipClan
              clanTag={this.props.clanTag}
              clanColor={this.props.clanColor}
              clanName={this.props.clanName}
              t={t}
            />
          </TooltipBody>
          <TooltipFooter mouseIcon="left">{t('Выбрать действие с кланом')}</TooltipFooter>
        </Tooltip>
      ) : null;

    return (
      <DivTooltip className={styles.title} tooltipBody={clanNameTooltipContent}>
        <ClanName clanColor={this.props.clanColor} clanTag={this.props.clanTag} clanName={this.props.clanName} />
      </DivTooltip>
    );
  }

  render() {
    let displayElement = this.renderTitleElement();
    if (this.props.needMenu) {
      displayElement = this.renderMenuWrapper(this.getMenuCallbacks, displayElement);
    }

    const spinner = this.props.isRenameInProcess ? (
      <CSSTransition
        component="div"
        transitionName="rename-spinner-fade"
        enter={true}
        apear={false}
        timeout={{
          enter: renameSpinnerAnimationEnterDuration,
          exit: renameSpinnerAnimationLeaveDuration,
        }}
      >
        <div className={styles.spinner}>
          <Spinner label={t('Переименовываем клан')} />
        </div>
      </CSSTransition>
    ) : null;

    // const clanLevel = settings.supply.isEnabled ? (
    //   <OwnClanLevelIcon
    //     clanLevel={this.props.clanLevel}
    //     accumulativeClanResource={this.props.accumulativeClanResource}
    //     allBonusesActivated={this.props.allBonusesActivated}
    //   />
    // ) : null;

    const classNameBase = classNames(styles.base, {
      [styles.isNavalBaseEnabled]: settings.supply.isEnabled,
    });

    return (
      <div className={classNameBase}>
        <div className={styles.clanName}>{displayElement}</div>
        {spinner}
      </div>
    );
  }
}

export default translate()(OwnClanTitle);
