import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import * as React from 'react';
import { translate } from 'react-i18next';
import { ButtonIcon, ClanLeagueSignInline, DivTooltip, Tooltip, TooltipBody } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { toRoman } from '~/helpers/formatting';
import { getLastRegularSeason, getLeagueNameByNumber, isLastSeasonRegular } from '~/helpers/ladder';
import { t } from '~/helpers/localization';
import { TEAMS_NAMES } from '~/helpers/teams';
import { getTypeOfViewportSize } from '~/helpers/viewport';

import styles from './ClanLeagueSign.scss';

const progressCircleRadius = 50;
const lastSeason = getLastRegularSeason();
const lastSeasonId = lastSeason ? lastSeason.id : 0;

const propTypes = {
  leadingTeamNumber: PropTypes.number,
  teamsRating: PropTypes.array,
  clanDivisionRating: PropTypes.number.isRequired,
  clanMaxDivisionRating: PropTypes.number.isRequired,
  clanDivision: PropTypes.number,
  clanLeague: PropTypes.number,
  clanStage: PropTypes.object,
  isFullSize: PropTypes.bool,
  fewTeams: PropTypes.bool,

  t: PropTypes.func.isRequired,
};

const TEAMS_TOOLTIP_TEXT = {
  leadingTeam: t('Ведущий рейтинг клана'),
  ratingInCurrentSeason: t('Рейтинг клана в текущем сезоне клановых боев.'),
  ratingInLastRegularSeason: t('Рейтинг клана в последнем сезоне клановых боев.'),
};

class ClanLeagueSign extends React.PureComponent {
  constructor(props) {
    super(props);

    this.seasonInfo = lastSeason;
    this.mediaPath = settings.mediaPath || '';
    this.tooltipId = uniqueId('clan-league-sign_');
    this.clanLeagueIndex = null;
    this.divisionIndex = null;

    if (isNumber(this.props.clanLeague) && !isEmpty(this.seasonInfo)) {
      if (this.seasonInfo.leagues[this.props.clanLeague]) {
        const divisions = this.seasonInfo.leagues[this.props.clanLeague].divisions;
        const numberOfDivisions = divisions ? divisions.length : 0;

        this.divisionIndex = this.props.clanDivision ? numberOfDivisions - this.props.clanDivision : null;
      }
    }

    this.onResize = this.onResize.bind(this);

    this.state = {
      leagueIcon: 'default',
      ribbon: 'ribbon',
      ribbonWide: 'ribbon_wide',
      divisionIcon: 'default',
      progressStub: 'progress_stub',
      stars: '',
      viewportSizeType: '',
    };
  }

  UNSAFE_componentWillMount() {
    // eslint-disable-line
    if (this.props.isFullSize) return;

    window.addEventListener('resize', this.onResize);
    this.setImagesSize();
  }

  componentWillUnmount() {
    if (this.props.isFullSize) return;

    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    this.setImagesSize();
  }

  setImagesSize() {
    const viewportSizeType = getTypeOfViewportSize();
    if (viewportSizeType === this.state.viewportSizeType) return;

    if (viewportSizeType === 'small' || viewportSizeType === 'medium') {
      this.setState({
        leagueIcon: 'small',
        ribbon: 'ribbon_small',
        ribbonWide: 'ribbon_wide_small',
        divisionIcon: 'small',
        progressStub: 'progress_stub_small',
        stars: '_small',
        viewportSizeType,
      });
    } else if (viewportSizeType === 'large' || viewportSizeType === 'extra') {
      this.setState({
        leagueIcon: 'default',
        ribbon: 'ribbon',
        ribbonWide: 'ribbon_wide',
        divisionIcon: 'default',
        progressStub: 'progress_stub',
        stars: '',
        viewportSizeType,
      });
    }
  }

  renderInfoBox() {
    const clanStage = this.getClanStage();
    const isPromotion = clanStage !== 'progress';

    let clanDivisionRating = this.props.clanDivisionRating;

    if (clanDivisionRating < 0) {
      clanDivisionRating = 0;
    }

    const valueClassName = this.props.isFullSize
      ? classNames(styles.infoBoxValue, styles.infoBoxValueFull)
      : styles.infoBoxValue;

    const classNameInfoBox = this.props.isFullSize
      ? classNames(styles.clanInfoBoxWrapper, styles.clanInfoBoxWrapperFull)
      : styles.clanInfoBoxWrapper;

    const league = this.seasonInfo.leagues[this.props.clanLeague];
    const ribbonType = clanStage === 'progress' ? league.icons[this.state.ribbon] : league.icons[this.state.ribbonWide];
    const ribbonBackground = this.getImagePath(ribbonType);

    const ribbonStyle = {
      backgroundImage: `url('${ribbonBackground}')`,
    };

    return isPromotion ? (
      <div className={classNameInfoBox} style={ribbonStyle}>
        {this.renderQualificationProgress()}
      </div>
    ) : (
      <div className={classNameInfoBox} style={ribbonStyle}>
        <div className={valueClassName}>{clanDivisionRating}</div>
      </div>
    );
  }

  renderQualificationProgress() {
    const victoriesRequired = this.props.clanStage.battles;
    const battlesProgress = this.props.clanStage.progress || [];

    if (!victoriesRequired || !battlesProgress) {
      return null;
    }

    const amountOfUnknownResults = victoriesRequired - battlesProgress.length;
    const unknownResults = amountOfUnknownResults > 0 ? new Array(amountOfUnknownResults).fill('empty') : [];

    const fullBattlesProgress = battlesProgress.concat(unknownResults);

    const starClassNames = this.props.isFullSize ? classNames(styles.star, styles.starFullSize) : styles.star;

    const infoBoxStarsClassNames = this.props.isFullSize
      ? classNames(styles.infoBoxStars, styles.infoBoxStarsFullSize)
      : styles.infoBoxStars;

    return (
      <ul className={infoBoxStarsClassNames}>
        {fullBattlesProgress.map((result, index) => (
          <li key={index} className={starClassNames} style={this.getPromotionStarIconStyle(result)}></li>
        ))}
      </ul>
    );
  }

  getPromotionStarIconStyle(result) {
    const additionalIcons = this.seasonInfo['additional_icons'] ? this.seasonInfo['additional_icons'] : {};
    const starIcons = additionalIcons.stars ? additionalIcons.stars : {};
    const currentStar = `${result}${this.state.stars}`;

    return {
      backgroundImage: `url("${this.getImagePath(starIcons[currentStar])}")`,
    };
  }

  renderProgressBar() {
    const stage = this.getClanStage();
    let firstLeague = {};

    if (lastSeason.id == 301) {
      // REMOVE AFTER VERIZON CUP! 01.11.2020
      firstLeague = lastSeason.leagues[4];
    } else {
      firstLeague = lastSeason.leagues[0] || lastSeason.leagues[1];
    }

    if (stage === 'qualification') {
      return null;
    }

    if (
      firstLeague &&
      firstLeague.number === this.props.clanLeague &&
      firstLeague.divisions[0].number === this.props.clanDivision
    ) {
      return this.renderProgressStub();
    }

    const maxRating = this.props.clanMaxDivisionRating;
    const clanRating = this.props.clanDivisionRating;

    if (!(typeof maxRating === 'number') || !(typeof clanRating === 'number')) {
      return null;
    }

    const clanLeague = this.seasonInfo.leagues[this.props.clanLeague];
    let clanColor = clanLeague['progress_color'];

    if (!clanColor) {
      clanColor = clanLeague.color || '#fff';
    }

    const progressBarLength = 2 * Math.PI * progressCircleRadius;
    const clanProgress = clanRating / maxRating;
    const progressBarOffset = progressBarLength * (1 - clanProgress);

    const progressStyle = {
      strokeDasharray: progressBarLength,
      strokeDashoffset: progressBarOffset,
      stroke: clanColor,
    };

    return (
      <div className={styles.progressChart}>
        <svg className={styles.progress} version="1.1" xmlns="http://www.w3.org/2000/svg">
          <circle className={styles.progressValue} style={progressStyle} cx="50%" cy="50%" r="calc(50% - 3px)" />
        </svg>
      </div>
    );
  }

  renderProgressStub() {
    const league = this.seasonInfo.leagues[this.props.clanLeague];
    const leagueIcons = league.icons ? league.icons : {};
    const progressBarStubIcon = leagueIcons[this.state.progressStub];

    const progressBarStubStyle = {
      backgroundImage: `url('${this.getImagePath(progressBarStubIcon)}')`,
    };

    const progressClassName = this.props.isFullSize
      ? classNames(styles.progressStub, styles.progressStubSizeFull)
      : styles.progressStub;

    return <div style={progressBarStubStyle} className={progressClassName}></div>;
  }

  renderDivisionNumber() {
    const clanDivision = this.props.clanDivision;
    const league = this.seasonInfo.leagues[this.props.clanLeague];
    const divisionData = league.divisions[this.divisionIndex] ? league.divisions[this.divisionIndex] : {};
    const divisionIcon = divisionData.icons ? divisionData.icons : {};

    const divisionNumberStyle = {
      backgroundImage: `url("${this.getImagePath(divisionIcon[this.state.divisionIcon])}")`,
    };

    return divisionIcon ? (
      <div
        style={divisionNumberStyle}
        className={classNames(styles.clanDivisionNumberIcon, {
          [styles.fullSize]: this.props.isFullSize,
        })}
      ></div>
    ) : (
      <div className={styles.clanDivisionNumber}>{toRoman(clanDivision)}</div>
    );
  }

  getClanStage() {
    if (!this.props.clanStage) {
      return 'progress';
    }

    return this.props.clanStage.type;
  }

  getImagePath(url) {
    if (!url) {
      return '';
    }

    return `${this.mediaPath}${url}`;
  }

  renderTooltip() {
    const t = this.props.t;
    const leagueName = getLeagueNameByNumber(this.props.clanLeague, lastSeasonId);
    const division = this.props.clanDivision;
    const l10nLeague = t('Лига:');
    const l10nDivision = t('Дивизион:');
    const leagueDivisionText = `${l10nLeague} ${leagueName}, ${l10nDivision} ${toRoman(division)}`;
    const seasonText = isLastSeasonRegular()
      ? TEAMS_TOOLTIP_TEXT.ratingInCurrentSeason
      : TEAMS_TOOLTIP_TEXT.ratingInLastRegularSeason;

    if (!this.props.isFullSize) {
      if (!this.props.teamsRating || !this.props.teamsRating.length) {
        return (
          <Tooltip>
            <TooltipBody>
              <div className={styles.tooltipTeam}>
                <div className={styles.tooltipHeader}>{leagueDivisionText}</div>
                <div>{seasonText}</div>
              </div>
            </TooltipBody>
          </Tooltip>
        );
      }

      const teams = this.props.teamsRating.sort((teamA, teamB) => {
        if (teamA.public_rating > teamB.public_rating) {
          return -1;
        }
        if (teamA.public_rating < teamB.public_rating) {
          return 1;
        }
        return 0;
      });

      const tooltipContent = teams.map((team) => {
        const isLeadingTeam = this.props.leadingTeamNumber === team.team_number;

        let teamText = null;

        if (this.props.fewTeams || teams.length > 1) {
          teamText = isLeadingTeam ? TEAMS_TOOLTIP_TEXT.leadingTeam : null;
        } else {
          teamText = seasonText;
        }

        const currentTeamLeague = lastSeason.leagues[team.league];
        const teamRatingText = `${l10nLeague} ${getLeagueNameByNumber(
          team.league,
          lastSeasonId,
        )}, ${l10nDivision} ${toRoman(team.division)}`;

        return (
          <div
            key={team.id}
            className={classNames({
              [styles.leadingTeam]: isLeadingTeam && (this.props.fewTeams || teams.length > 1),
            })}
          >
            <TooltipBody>
              <div className={styles.TooltipLeagueSign}>
                <ClanLeagueSignInline color={currentTeamLeague.color} title={toRoman(team.division)} />
              </div>
              <div className={styles.tooltipTeam}>
                <div className={styles.tooltipHeader}>{TEAMS_NAMES[team.team_number]}</div>
                <div className={styles.tooltipLeagueDivisionText}>{teamRatingText}</div>
                <div>{teamText}</div>
              </div>
            </TooltipBody>
          </div>
        );
      });

      return <Tooltip>{tooltipContent}</Tooltip>;
    } else {
      return (
        <Tooltip>
          <TooltipBody>
            <div className={styles.tooltipHeader}>{leagueDivisionText}</div>
            <div>{seasonText}</div>
          </TooltipBody>
        </Tooltip>
      );
    }
  }

  render() {
    if (isEmpty(this.seasonInfo)) {
      return null;
    }

    const clanStage = this.getClanStage();

    let background = '';

    if (clanStage !== 'qualification') {
      const league = this.seasonInfo.leagues[this.props.clanLeague];

      if (!league) {
        return null;
      }
      background = this.getImagePath(league.icons[this.state.leagueIcon]);
    } else {
      return null;
    }

    const backgroundIcon = {
      backgroundImage: `url('${background}')`,
    };

    const isShowProgressBar = this.props.isFullSize && clanStage === 'progress';
    const signSizeClass = this.props.isFullSize ? styles.fullSize : styles.normalSize;
    const classNameBase = classNames(styles.base, signSizeClass);

    return (
      <DivTooltip tooltipBody={this.renderTooltip()}>
        <div style={backgroundIcon} className={classNameBase} data-tip data-for={this.tooltipId}>
          {isShowProgressBar ? this.renderProgressBar() : this.renderProgressStub()}
          {this.renderInfoBox()}
          {this.renderDivisionNumber()}
        </div>
      </DivTooltip>
    );
  }
}

ClanLeagueSign.propTypes = propTypes;

export default translate()(ClanLeagueSign);
