import { INTRODUCTION_IS_SHOWN } from '~/Actions/ActionIntroduction';

import type { ActionsType } from '~/Actions/ActionIntroduction';

type IIntroductionState = {
  isShown: boolean;
};

const initialState: IIntroductionState = {
  isShown: false,
};

export const introduction = (state: IIntroductionState = initialState, action: ActionsType): IIntroductionState => {
  switch (action.type) {
    case INTRODUCTION_IS_SHOWN:
      return {
        ...state,
        isShown: true,
      };

    default:
      return state;
  }
};
