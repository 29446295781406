import classNames from 'classnames';
import React from 'react';

import styles from './StepCSSTransitionGroup.scss';

type IStepCSSTransitionGroup = {
  children: React.ReactNode;
  className?: string;
  delay?: number;
  distance?: number;
  duration?: number;
  level?: number;
  isDisabled?: boolean;
  onClick?: (...args: unknown[]) => void;
};

const StepCSSTransitionGroup: React.FC<IStepCSSTransitionGroup> = ({
  children,
  className,
  onClick,
  delay = 0.1,
  distance = 4,
  duration = 0.2,
  level = 0,
  isDisabled = false,
}) => {
  const animationStyles = isDisabled
    ? undefined
    : ({
        '--animation-delay': `${(100 * level * delay) / 100}s`,
        '--animation-distance': `${distance}px`,
        '--animation-duration': `${duration}s`,
      } as React.CSSProperties);

  return (
    <div className={classNames(styles.appear, className)} onClick={onClick} style={animationStyles}>
      {children}
    </div>
  );
};

export default React.memo(StepCSSTransitionGroup);
