import React, { Component } from 'react';

import styles from './MagnifyingGlassStyles.scss';

const w = 324;
const h = 230;

const getPercent = (val: number, max: number) => {
  return Math.floor((val / max) * 100);
};

export interface MagnifyingGlassProps {}
export interface MagnifyingGlassState {
  width: number;
  height: number;
  x: number;
  y: number;
  animationFixed: boolean;
}

class MagnifyingGlass extends Component<MagnifyingGlassProps, MagnifyingGlassState> {
  state: MagnifyingGlassState = {
    width: w,
    height: h,
    x: getPercent(w / 2, w),
    y: getPercent(h / 2, h),
    animationFixed: false,
  };

  angle = 0;

  mouseMove = (e: React.MouseEvent) => {
    this.setState({
      x: getPercent(e.nativeEvent.offsetX, w),
      y: getPercent(e.nativeEvent.offsetY, h),
      animationFixed: true,
    });
  };

  mouseLeave = () => {
    this.setState({
      x: getPercent(w / 2, w),
      y: getPercent(h / 2, h),
      animationFixed: false,
    });
  };

  render() {
    return (
      <div
        className={styles.MagnifyingGlassWrapper}
        onMouseMove={this.mouseMove}
        onMouseLeave={this.mouseLeave}
        style={{ width: w, height: h }}
      >
        <div
          style={{
            clipPath: `circle(36px at ${this.state.x}% ${this.state.y}%)`,
          }}
          className={styles.MagnifyingGlassWrapperFocus}
        />
        <div style={{ left: `${this.state.x}%`, top: `${this.state.y}%` }} className={styles.GlassWrapper}>
          <div className={styles.GlassWrapperInner} />
        </div>
      </div>
    );
  }
}

export default MagnifyingGlass;
