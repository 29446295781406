import filter from 'lodash/filter';

import preloaded from '~/preloaded';

import {
  CLAIM_CLAN_TASK,
  CLAIM_CLAN_TASK_RESPONSE,
  CLAIM_CLAN_TASKS,
  CLAIM_CLAN_TASKS_RESPONSE,
  GET_CLAN_TASKS,
  GET_CLAN_TASKS_RESPONSE,
} from '~/Actions/ActionClanTasks';

import type { ActionsType, ClanTask, ClanTaskReward } from '~/Actions/ActionClanTasks';

type IClanTasksState = {
  isFetching: boolean;
  isClaiming: boolean;
  tasks: ClanTask[];
  claimed: ClanTask[];
  lastReward?: Nullable<ClanTaskReward>;
};

const initialState: IClanTasksState = {
  isFetching: false,
  isClaiming: false,
  tasks: preloaded.clanTasks || [],
  claimed: [],
  lastReward: null,
};

export const ReducerClanTasks = (state: IClanTasksState = initialState, action: ActionsType): IClanTasksState => {
  switch (action.type) {
    case GET_CLAN_TASKS: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case GET_CLAN_TASKS_RESPONSE: {
      const tasks = action.payload.sort((a, b) => (a.order > b.order ? 1 : -1));
      return {
        ...state,
        isFetching: false,
        isClaiming: false,
        tasks,
      };
    }

    case CLAIM_CLAN_TASK: {
      return {
        ...state,
        isClaiming: true,
      };
    }

    case CLAIM_CLAN_TASK_RESPONSE: {
      let tasks = filter(state.tasks, (task: ClanTask) => task.name !== action.payload.name);
      tasks.push(action.payload);
      tasks = tasks.sort((a, b) => (a.order > b.order ? 1 : -1));
      return {
        ...state,
        isClaiming: false,
        tasks,
      };
    }

    case CLAIM_CLAN_TASKS: {
      return {
        ...state,
        isClaiming: true,
      };
    }

    case CLAIM_CLAN_TASKS_RESPONSE: {
      const tasks = [...action.payload.unchanged, ...action.payload.changed].sort((a, b) =>
        a.order > b.order ? 1 : -1,
      );
      return {
        ...state,
        claimed: action.payload.changed,
        isClaiming: false,
        lastReward: action.payload.reward,
        tasks,
      };
    }

    default:
      return state;
  }
};
