import PropTypes from 'prop-types';
import * as React from 'react';

import styles from './Tooltip.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const TooltipInfo = (props) => <div className={styles.info} dangerouslySetInnerHTML={{ __html: props.children }} />;

TooltipInfo.propTypes = propTypes;

export default TooltipInfo;
