import PropTypes from 'prop-types';
import * as React from 'react';
import { translate } from 'react-i18next';
import { ErrorLoad, StepCSSTransitionGroup, Table, TableBody } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { t } from '~/helpers/localization';
import { getMemberTooltipContent } from '~/helpers/tooltip';

import {
  ClanIsDisbandedTooltip,
  ClanMembersInfoMissingTooltip,
  LastBattleTimeTooltipContent,
  MemberInfoMissingTooltip,
  MemberIsBannedTooltip,
  RankTooltipContent,
} from '~/tooltips';

import { Tooltip } from '~/UIKit/components';

import MembersTableHead from './MembersTableHead';
import MembersTableItem from './MembersTableItem';
import { AccumulativeClanResourceTooltipContent } from './Tooltips';

class MembersTable extends React.Component {
  static propTypes = {
    clanId: PropTypes.number.isRequired,
    currentAccount: PropTypes.object.isRequired,
    error: PropTypes.string.isRequired,
    members: PropTypes.array.isRequired,
    sort: PropTypes.object.isRequired,
    stickyContainerId: PropTypes.string.isRequired,

    isFetching: PropTypes.bool,
    stickyAppContainerId: PropTypes.string,
    stickyScrollContainerId: PropTypes.string,

    disabledIds: PropTypes.arrayOf(PropTypes.number),
    displayCheckboxes: PropTypes.bool,
    isContextMenuEnabled: PropTypes.bool,
    selectedMemberIds: PropTypes.arrayOf(PropTypes.number),
    isActiveSticky: PropTypes.bool,
    currentBattleType: PropTypes.string,

    onReloadClick: PropTypes.func.isRequired,
    onThSortClick: PropTypes.func.isRequired,
    openAccountProfile: PropTypes.func.isRequired,
    openChatWindow: PropTypes.func.isRequired,

    onTickAllMembers: PropTypes.func,
    onTickMember: PropTypes.func,
    onChangeCommanderClick: PropTypes.func,
    onChangeRoleClick: PropTypes.func,
    onRemoveMembersClick: PropTypes.func,

    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabledIds: [],
    selectedMemberIds: [],
    isActiveSticky: true,
    isContextMenuEnabled: true,

    onTickMember: () => {},
    onTickAllMembers: () => {},
  };

  constructor(props) {
    super(props);

    this.onTickAllMembers = this.onTickAllMembers.bind(this);
    this.getLastBattleTimeTooltipContent = this.getLastBattleTimeTooltipContent.bind(this);
    this.getMemberTooltipContent = this.getMemberTooltipContent.bind(this);
    this.getRankTooltipContent = this.getRankTooltipContent.bind(this);
    this.getAccumulativeClanResourceTooltipContent = this.getAccumulativeClanResourceTooltipContent.bind(this);
  }

  isMember() {
    return this.props.clanId === this.props.currentAccount.clanId;
  }

  getMemberById(id) {
    return this.props.members.find((member) => member.id === id);
  }

  onTickAllMembers() {
    const allMembersAvailableToSelectIds = this.props.members
      .filter((m) => !this.props.disabledIds.includes(m.id))
      .map((m) => m.id);
    this.props.onTickAllMembers(allMembersAvailableToSelectIds);
  }

  getLastBattleTimeTooltipContent(tooltipTip) {
    return tooltipTip ? LastBattleTimeTooltipContent(+tooltipTip) : null;
  }

  getMemberTooltipContent(tooltipTip) {
    if (!tooltipTip) return null;

    const memberId = Number(tooltipTip);
    const member = this.getMemberById(memberId);

    return getMemberTooltipContent(
      member,
      this.props.currentAccount,
      this.props.isContextMenuEnabled,
      this.props.displayCheckboxes,
      this.props.disabledIds,
    );
  }

  getRankTooltipContent(tooltipTip) {
    if (!tooltipTip) return null;

    const memberId = Number(tooltipTip);
    const member = this.getMemberById(memberId);

    return <RankTooltipContent rank={member.rank} seasonId={member.seasonId} seasonRank={member.seasonRank} />;
  }

  getAccumulativeClanResourceTooltipContent(tooltipTip) {
    if (!tooltipTip) return null;

    const memberId = Number(tooltipTip);
    const member = this.getMemberById(memberId);

    let leveling = member.leveling;
    let gainedResource = member.accumulativeClanResource;
    if (memberId === this.props.currentAccount.id) {
      leveling = this.props.currentAccount.leveling;
      gainedResource = this.props.currentAccount.accumulativeClanResource;
    }
    const needResource = settings.supply.clanLevelingToAccountLeveling[leveling];

    return <AccumulativeClanResourceTooltipContent needResource={needResource} gainedResource={gainedResource} />;
  }

  renderMembersList() {
    const isErrored = this.props.error.length !== 0;
    if (isErrored) {
      return <ErrorLoad isFlat message={this.props.error} onReloadClick={this.props.onReloadClick} t={t} />;
    } else {
      const members = this.props.members.map((member) => (
        <MembersTableItem
          key={member.id}
          member={member}
          clanId={this.props.clanId}
          currentAccount={this.props.currentAccount}
          disabledCheckbox={this.props.displayCheckboxes && this.props.disabledIds.indexOf(member.id) !== -1}
          displayCheckboxes={this.props.displayCheckboxes}
          isMember={this.isMember()}
          isSelectedMember={this.props.displayCheckboxes && this.props.selectedMemberIds.includes(member.id)}
          isContextMenuEnabled={this.props.isContextMenuEnabled}
          currentBattleType={this.props.currentBattleType}
          onChangeCommanderClick={this.props.onChangeCommanderClick}
          onChangeRoleClick={this.props.onChangeRoleClick}
          onRemoveMembersClick={this.props.onRemoveMembersClick}
          openAccountProfile={this.props.openAccountProfile}
          openChatWindow={this.props.openChatWindow}
          onTickMember={this.props.onTickMember}
        />
      ));

      return (
        <StepCSSTransitionGroup level={3}>
          <TableBody key="table-body">{members}</TableBody>
        </StepCSSTransitionGroup>
      );
    }
  }

  render() {
    const allMembersIsChecked =
      this.props.members.length === this.props.disabledIds.length + this.props.selectedMemberIds.length;
    const isErrored = this.props.error.length !== 0;
    return (
      <div>
        <Table
          isFetching={this.props.isFetching}
          isErrored={isErrored}
          stickyContainerId={this.props.stickyContainerId}
        >
          <MembersTableHead
            sort={this.props.sort}
            displayCheckboxes={this.props.displayCheckboxes}
            isMember={this.isMember()}
            onThSortClick={this.props.onThSortClick}
            onTickAllMembers={this.onTickAllMembers}
            allMembersIsChecked={allMembersIsChecked}
            currentBattleType={this.props.currentBattleType}
            stickyContainerId={this.props.stickyContainerId}
            stickyAppContainerId={this.props.stickyAppContainerId}
            stickyScrollContainerId={this.props.stickyScrollContainerId}
            isActiveSticky={this.props.isActiveSticky && !isErrored}
          />
          {this.renderMembersList()}
        </Table>

        <ClanIsDisbandedTooltip />
        <ClanMembersInfoMissingTooltip />
        <MemberIsBannedTooltip />
        <MemberInfoMissingTooltip />
        <Tooltip id="last-battle-time-tooltip" getContent={this.getLastBattleTimeTooltipContent} />
        <Tooltip id="member-tooltip" getContent={this.getMemberTooltipContent} />
        <Tooltip id="rank-tooltip" getContent={this.getRankTooltipContent} />
        <Tooltip id="accumulative-clan-resource-tooltip" getContent={this.getAccumulativeClanResourceTooltipContent} />
      </div>
    );
  }
}

export default translate()(MembersTable);
