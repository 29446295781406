import {
  ERROR_CREATION_CLAN,
  FINISH_CHECK_AVAILABILITY_CLAN_CREATION,
  SET_INITIAL_STATE_CREATION_CLAN,
  START_CHECK_AVAILABILITY_CLAN_CREATION,
  START_CREATION_CLAN,
  UPDATE_CREATION_CLAN_FIELD_VALUE,
} from '~/Actions/ActionClanCreate';

import { ATTRIBUTES_VALIDATION_STATE } from '~/UIKit/ClanCreate/Constants';

import type { ActionsType, ICreateErrorsCommon } from '~/Actions/ActionClanCreate';
import type { IApiError } from '~/types/api';

export type IClanCreateStateFields = {
  tag: {
    value: string;
    available: ATTRIBUTES_VALIDATION_STATE;
    isValidating: boolean;
    error: IApiError;
  };
  name: {
    value: string;
    available: ATTRIBUTES_VALIDATION_STATE;
    isValidating: boolean;
    error: IApiError;
  };
};

type IClanCreateState = IClanCreateStateFields & {
  isProcessing: boolean;
  error: IApiError | ICreateErrorsCommon;
};

const initialState: IClanCreateState = {
  tag: {
    value: '',
    available: ATTRIBUTES_VALIDATION_STATE.UNDEFINED,
    isValidating: false,
    error: null,
  },
  name: {
    value: '',
    available: ATTRIBUTES_VALIDATION_STATE.UNDEFINED,
    isValidating: false,
    error: null,
  },
  isProcessing: false,
  error: null,
};

export const clanCreate = (state: IClanCreateState = initialState, action: ActionsType): IClanCreateState => {
  switch (action.type) {
    case START_CREATION_CLAN:
      return {
        ...state,
        isProcessing: true,
        error: null,
      };

    case UPDATE_CREATION_CLAN_FIELD_VALUE: {
      const fieldData = {
        ...state[action.field],
        value: action.value,
        error: action.error,
      };

      let errorData: IClanCreateState['error'] = null;
      if (state.error && typeof state.error !== 'string' && 'errors' in state.error) {
        errorData = { ...state.error };
        delete errorData.errors[action.field];
      }

      return {
        ...state,
        [action.field]: fieldData,
        error: errorData || state.error,
      };
    }

    case START_CHECK_AVAILABILITY_CLAN_CREATION:
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          isValidating: true,
        },
      };

    case FINISH_CHECK_AVAILABILITY_CLAN_CREATION: {
      const fieldData = {
        ...state[action.data.field],
        value: action.data.value,
        available: action.data.available,
        error: action.data.error,
        isValidating: false,
      };
      return {
        ...state,
        [action.data.field]: fieldData,
      };
    }

    case ERROR_CREATION_CLAN:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
      };

    case SET_INITIAL_STATE_CREATION_CLAN:
      return { ...state };

    default:
      return state;
  }
};
